export enum TotalHaValue {
  "<100" = "<100",
  "100-500" = "100-500",
  "500-1000" = "500-1000",
  "1000-5000" = "1000-5000",
  "5000-10000" = "5000-10000",
  ">10000" = ">10000",
}

export const TOTAL_HA_VALUES = {
  [TotalHaValue["<100"]]: [{ operator: "max", value: 100 }],
  [TotalHaValue["100-500"]]: [
    { operator: "min", value: 100 },
    { operator: "max", value: 500 },
  ],
  [TotalHaValue["500-1000"]]: [
    { operator: "min", value: 500 },
    { operator: "max", value: 1000 },
  ],
  [TotalHaValue["1000-5000"]]: [
    { operator: "min", value: 1000 },
    { operator: "max", value: 5000 },
  ],
  [TotalHaValue["5000-10000"]]: [
    { operator: "min", value: 5000 },
    { operator: "max", value: 10000 },
  ],
  [TotalHaValue[">10000"]]: [{ operator: "min", value: 10000 }],
};

// A list of country codes, so the most used can get sorted to the top.
// This list should be changed/reordered to fit CS needs in the future.
export const MOST_USED_COUNTRY_CODES = [
  "DK",
  "GB",
  "FR",
  "DE",
  "LT",
  "PL",
  "RO",
  "SK",
  "ES",
  "UA",
  "EE",
  "BG",
  "CZ",
  "HU",
  "FI",
  "LV",
];
