import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import { CommonPaginatedResponse, MetaArgs } from "@ag/utils/types";

import { getRequest } from "~lib/axios";

import { getFieldDefinitionStatus } from "./helpers";
import {
  FieldDefinitionStatus,
  FieldDetails,
  FieldsOverviewsQueryKeyArgs,
  UseFieldDetailsQueryOptions,
  UserFieldsOverviewsResponse,
  UserFieldsQueryKeyArgs,
} from "./types";

export const fieldKeys = {
  all: ["fields"] as const,

  listAll: () => [...fieldKeys.all, "list"] as const,
  list: ({ filters, metaArgs }: FieldsOverviewsQueryKeyArgs) =>
    [...fieldKeys.listAll(), { filters, metaArgs }] as const,

  userFieldsAll: () => [...fieldKeys.all, "user-fields"] as const,
  userFields: (args: UserFieldsQueryKeyArgs) =>
    [...fieldKeys.listAll(), args] as const,

  detailsAll: () => [...fieldKeys.all, "details"] as const,
  details: (fieldId: number | undefined) =>
    [...fieldKeys.detailsAll(), fieldId] as const,
};

/**
 * CUSTOM QUERY FUNCTIONS
 */
export const fetchUserFields = async (userId: number, metaArgs?: MetaArgs) => {
  const response = await axios.get<
    CommonPaginatedResponse<UserFieldsOverviewsResponse>
  >("/api/v1/carbon-fields", {
    params: {
      sort: {
        id: "desc",
      },
      filters: {
        userId,
      },
      page: metaArgs?.page ?? 1,
      limit: metaArgs?.limit ?? DEFAULT_LIMIT,
      includeFieldDetails: true,
      includeMeta: true,
    },
    apiSource: "node-carbon",
  });

  const { items, meta } = response.data.success!.data;

  return {
    data: items,
    meta,
  };
};

const fetchFieldDetails = async (fieldId: number): Promise<FieldDetails> => {
  const response = await getRequest({
    path: `/api/farmer/carbon/v1/admin/carbon_fields/${fieldId}`,
  });

  return response.data.result.data;
};

/**
 * QUERY HOOKS
 */
export const useFieldDetailsQuery = <T = FieldDetails>(
  fieldId: number | undefined,
  options?: UseFieldDetailsQueryOptions<T>,
) =>
  useQuery(
    fieldKeys.details(fieldId),
    () => {
      if (!fieldId) return Promise.resolve(undefined);

      return fetchFieldDetails(fieldId);
    },
    {
      enabled: Boolean(fieldId),
      ...options,
    },
  );

/**
 * QUERY SELECT HOOKS
 */
export const useFieldDefinitionStatus = (fieldId: number) =>
  useFieldDetailsQuery<FieldDefinitionStatus>(fieldId, {
    select: useCallback<
      (FieldDetails: FieldDetails | undefined) => FieldDefinitionStatus
    >(fieldDefinition => getFieldDefinitionStatus(fieldDefinition), []),
  });
