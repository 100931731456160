import { Helmet } from "react-helmet";

import { createChildValidator } from "@ag/utils/helpers";

import * as styles from "./details-layout.css";

/* -------------------------------------------------------------------------------------------------
 * DetailsLayout
 * -----------------------------------------------------------------------------------------------*/
const layoutChildValidator = createChildValidator("DetailsLayout", {
  Sidebar: DetailsLayoutSidebar,
  TopBar: DetailsLayoutTopBar,
  Content: DetailsLayoutContent,
});

type DetailsLayoutProps = {
  children: React.ReactElement<
    typeof DetailsLayoutTopBar | typeof DetailsLayoutContent
  >[];
};

function DetailsLayout({ children }: DetailsLayoutProps) {
  const childError = layoutChildValidator(children);
  if (childError) throw new Error(childError);

  return <div className={styles.root}>{children}</div>;
}

/* -------------------------------------------------------------------------------------------------
 * DetailsLayoutTopBar
 * -----------------------------------------------------------------------------------------------*/
type DetailsLayoutTopBarProps = React.PropsWithChildren<unknown>;

function DetailsLayoutTopBar({ children }: DetailsLayoutTopBarProps) {
  return <div className={styles.topBar}>{children}</div>;
}

/* -------------------------------------------------------------------------------------------------
 * DetailsLayoutTopBarTitle
 * -----------------------------------------------------------------------------------------------*/
function DetailsLayoutTopBarTitle({
  children,
}: React.PropsWithChildrenRequired) {
  return (
    <>
      <h4 className={styles.title}>{children}</h4>
      <Helmet>{<title>{`${children} - Agreena`}</title>}</Helmet>
    </>
  );
}

/* -------------------------------------------------------------------------------------------------
 * DetailsLayoutContent
 * -----------------------------------------------------------------------------------------------*/
function DetailsLayoutContent({
  children,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & React.PropsWithChildrenRequired) {
  return (
    <main className={styles.content} {...rest}>
      {children}
    </main>
  );
}

/* -------------------------------------------------------------------------------------------------
 * DetailsLayoutSidebar
 * -----------------------------------------------------------------------------------------------*/
function DetailsLayoutSidebar({ children }: React.PropsWithChildrenRequired) {
  return <section className={styles.sidebar}>{children}</section>;
}

const Root = DetailsLayout;
const Sidebar = DetailsLayoutSidebar;
const TopBar = DetailsLayoutTopBar;
const TopBarTitle = DetailsLayoutTopBarTitle;
const Content = DetailsLayoutContent;

export { Root, TopBar, TopBarTitle, Sidebar, Content };
