import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { Icon } from "@ag/design-system/assets";
import { Button, IconButton, InfoBox, Label } from "@ag/design-system/atoms";
import { Modal, Table } from "@ag/design-system/organisms";
import { InputField } from "@ag/form-fields";

import { queryClient } from "~config";
import {
  generateUpdateRequestSummaryQueryKey,
  useCertificatesValidationTable,
  useCopyRangeErrors,
  useUpdateRequestQuery,
  useUpdateRequestStore,
  useUpdateRequestSummaryQuery,
  useValidateCertificatesMutation,
  validateCertificateIds,
} from "~features/certificate";
import { trimAndDispatchInput } from "~helpers/input";

type Props = {
  onGoNextStep: () => void;
};

const validateCertificatesFormSchema = () =>
  z.object({
    certificateIds: z
      .string()
      .nonempty({
        message:
          "Please provide the ID in a correct format (e.g. 1;2-10;50;1000-2000)",
      })
      .refine(value => validateCertificateIds(value, /[,;]/), {
        message:
          "Please provide the ID in a correct format (e.g. 1;2-10;50;1000-2000)",
      }),
  });

export type ValidateCertificatesFormValues = z.infer<
  ReturnType<typeof validateCertificatesFormSchema>
>;

const EnterIdsStep = ({ onGoNextStep }: Props) => {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const { validationResult, setValidationResult } = useUpdateRequestStore();

  const { requestId } = useParams<{ requestId: string }>();

  const { data: updateRequest } = useUpdateRequestQuery(requestId);
  const { data: summary } = useUpdateRequestSummaryQuery(requestId);
  const validateCertificatesMutation = useValidateCertificatesMutation();
  const copyErrors = useCopyRangeErrors(validationResult?.certificateIds);

  const {
    formState: { errors },
    reset,
    control,
    handleSubmit,
  } = useForm<ValidateCertificatesFormValues>({
    resolver: zodResolver(validateCertificatesFormSchema()),
  });

  const handleAddCertificates = async (
    values: ValidateCertificatesFormValues,
  ) => {
    if (!requestId) return;

    const currentRanges =
      validationResult?.certificateIds.map(range => range.query) || [];

    // Split the input by semicolon or comma
    const newRanges = values.certificateIds.split(/[;,]/);

    const result = await validateCertificatesMutation.mutateAsync({
      certificateIds: [...currentRanges, ...newRanges],
      updateRequestId: requestId,
    });

    await queryClient.invalidateQueries(
      generateUpdateRequestSummaryQueryKey(requestId),
    );

    setValidationResult(result.data.success!.data);

    reset({ certificateIds: "" });
  };

  const handleDeleteRange = async (index: number) => {
    if (!validationResult || !requestId) return;

    const newRanges = validationResult.certificateIds.filter(
      (_, i) => i !== index,
    );

    if (newRanges.length > 0) {
      const result = await validateCertificatesMutation.mutateAsync({
        certificateIds: newRanges.map(range => range.query),
        updateRequestId: requestId,
      });

      setValidationResult(result.data.success!.data);
    } else {
      setValidationResult(null);
    }

    await queryClient.invalidateQueries(
      generateUpdateRequestSummaryQueryKey(requestId),
    );
  };

  const handleDeleteAll = () => {
    setValidationResult(null);
  };

  const table = useCertificatesValidationTable(
    validationResult?.certificateIds,
    {
      handleDeleteAll,
      handleDeleteRange,
    },
  );

  const hasErrors = validationResult?.certificateIds.some(
    range => !range.valid,
  );

  return (
    <>
      <div className="mx-auto flex w-full max-w-[820px] flex-auto flex-col gap-8">
        <h2 className="pt-4 text-center text-h2 font-bold">
          Enter the Agreena Certificate IDs you would like to{" "}
          {updateRequest?.action === "transfer" ? "transfer" : "retire"}
        </h2>

        <div className="flex flex-col gap-8">
          <form
            className="flex items-start gap-2"
            onSubmit={handleSubmit(handleAddCertificates)}
          >
            <div className="flex-1">
              <Controller
                name="certificateIds"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    label="Ranges"
                    placeholder="e.g. 1-2;50-100"
                    {...field}
                    {...fieldState}
                    onChange={e =>
                      trimAndDispatchInput(e.target.value, field.onChange)
                    }
                    error={errors.certificateIds}
                  />
                )}
              />
            </div>

            <Button
              type="submit"
              icon="plus"
              variant="secondary"
              className="mt-7"
              isLoading={validateCertificatesMutation.isLoading}
            >
              Add
            </Button>
          </form>

          {hasErrors && (
            <InfoBox icon="warning" variant="warning">
              <span>
                The ranges marked in red have errors. Click on the red tooltip
                to review details. Delete, and if applicable, replace with the
                correct ranges. To see all errors{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={() => setIsErrorModalOpen(true)}
                >
                  click here
                </span>
                .
              </span>
            </InfoBox>
          )}
        </div>

        {validationResult?.certificateIds &&
          validationResult.certificateIds.length > 0 && (
            <div>
              <Label>Entered group IDs</Label>

              <Table instance={table} className="w-full" />
            </div>
          )}
      </div>

      <footer className="flex h-[104px] items-center justify-between gap-12 px-8 shadow-[0_-4px_8px_rgba(0,0,0,0.05)]">
        <div className="flex-1" />

        <div className="flex flex-col items-center gap-1">
          <div className="bg-white flex items-center justify-center gap-2 rounded border border-grey-300 bg-white-100 px-20 py-4">
            <span className="inline-flex items-center gap-1 text-p2 text-grey-600">
              Total certificates added
              <Icon name="info-circle" />
            </span>
            <span className="text-h2 font-bold">{summary?.quantity ?? 0}</span>
          </div>
        </div>

        <div className="flex flex-1 justify-end">
          <Button
            icon="chevron-right"
            iconPosition="after"
            onClick={onGoNextStep}
            disabled={!validationResult?.valid}
          >
            Next
          </Button>
        </div>
      </footer>

      <Modal.Root
        size="large"
        isOpen={isErrorModalOpen}
        onRequestClose={() => setIsErrorModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Errors</Modal.Title>
        </Modal.Header>

        <Modal.Content>
          <InfoBox icon="warning" variant="warning">
            The following is a list of IDs with conflicts: row # `[range start,
            range end]. Refer to the documentation to see possible causes of
            errors.
          </InfoBox>

          <IconButton
            size="small"
            className="ml-auto"
            variant="secondary"
            onClick={copyErrors}
            icon="copy"
            label="Copy IDs to clipboard"
          />

          <div className="flex flex-col gap-4">
            {validationResult?.certificateIds.map((range, index) => {
              if (range.valid) return null;

              const [rangeStart, rangeEnd] = range.query.split("-");

              return (
                <div key={index} className="flex flex-col gap-1">
                  <div className="flex items-center gap-2 border-b border-grey-300 text-p3 text-grey-900">
                    <span className="min-w-[50px] text-red-700">
                      {index + 1}
                    </span>
                    <div className="h-10 min-w-[100px] p-2.5">{rangeStart}</div>
                    <div className="h-10 min-w-[100px] p-2.5">{rangeEnd}</div>
                  </div>

                  <div>{range.reason}</div>
                </div>
              );
            })}
          </div>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default EnterIdsStep;
