import { FieldStepTabTitle } from "@ag/carbon/components";
import { Tab, Tabs } from "@ag/components/Tabs";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";
import { FieldStep } from "@ag/utils/types";

import { FieldStepTabId } from "~pages/_legacy/carbon/types";
import { FieldActualsStatus } from "~queries/carbon/field-actuals";
import {
  FieldDefinitionStatus,
  FieldDetails,
  useFieldDefinitionStatus,
} from "~queries/carbon/fields";

import FieldDefinition from "./steps/FieldDefinition";

type Props = {
  field: FieldDetails;
};

const FieldContent = ({ field }: Props) => {
  const {
    data: fieldDefinitionStatus,
    isLoading: isFieldDefinitionStatusLoading,
  } = useFieldDefinitionStatus(field.id);

  return (
    <Tabs
      defaultSelectedTab={FieldStepTabId.FieldDefinition}
      onDisabledTabEnter={() =>
        ToastNotification.error(I18n.t("js.shared.no_access_to_tab"))
      }
    >
      <Tab
        key={FieldStepTabId.FieldDefinition}
        id={FieldStepTabId.FieldDefinition}
        title={
          <FieldStepTabTitle
            status={fieldDefinitionStatus || FieldDefinitionStatus.NotStarted}
            step={FieldStep.Definition}
            title={I18n.t("js.field_details.tabs.field_definition")}
            isStatusLoading={isFieldDefinitionStatusLoading}
          />
        }
        component={<FieldDefinition field={field} />}
      />

      <Tab
        key={FieldStepTabId.FieldActuals}
        isDisabled
        id={FieldStepTabId.FieldActuals}
        title={
          <FieldStepTabTitle
            status={FieldActualsStatus.NotStarted}
            step={FieldStep.Actuals}
            title={I18n.t("js.field_details.tabs.field_actuals")}
            isStatusLoading={false}
          />
        }
        component={<div />}
      />
    </Tabs>
  );
};

export default FieldContent;
