import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

const syncHubspotCarbonStats = async (userIds: string[]) => {
  await axios.post(
    `/api/v1/hubspot-carbon-stats/sync`,
    { userIds },
    { apiSource: "node-carbon" },
  );
};

export const useSyncHubspotCarbonStatsMutation = () =>
  useMutation(syncHubspotCarbonStats, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
