import { RowSelectionState, SortingState } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";

import { parseSorting } from "@ag/design-system/organisms";

import Table from "~components/table";
import { useFieldsQuery, useFieldsTable } from "~features/field";
import { FieldOverview } from "~features/field/entities/field-overview";
import {
  CarbonResourceClass,
  useCarbonPermissions,
} from "~features/permission";

import { Contract, ContractStatus } from "../entities/contract";

type Props = {
  contract: Contract;
  fieldsToDisplay: "contract-fields" | "unlinked-fields";
  onSelectedFieldsUpdate?: (selectedFields: FieldOverview[]) => void;
};
export const ContractFields = ({
  contract,
  fieldsToDisplay,
  onSelectedFieldsUpdate,
}: Props) => {
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });

  const updatePagination = (value: { limit?: number; page?: number }) => {
    setPagination({ ...pagination, ...value });
  };

  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);

  const { data: carbonPermissions } = useCarbonPermissions();
  const hasRemoveFieldsFromContractPermission =
    carbonPermissions?.update?.includes(CarbonResourceClass.CarbonContract) &&
    carbonPermissions?.unassign?.includes(CarbonResourceClass.CarbonContract);

  // HACK: This is a workaround to avoid the issue with too many fieldIds breaking the API request
  // Pagination is handled on the client side
  const paginatedFieldsIds = useMemo(() => {
    const startIndex = (pagination.page - 1) * pagination.limit;
    const endIndex = startIndex + pagination.limit;

    if (fieldsToDisplay === "contract-fields") {
      return contract.carbonFields
        .slice(startIndex, endIndex)
        .map(field => field.id);
    } else {
      return contract.uncontractedFields
        .slice(startIndex, endIndex)
        .map(field => field.carbonFieldId);
    }
  }, [
    contract.carbonFields,
    contract.uncontractedFields,
    fieldsToDisplay,
    pagination.page,
    pagination.limit,
  ]);

  const { data: fieldsData, isLoading } = useFieldsQuery(
    {
      filters: {
        id: paginatedFieldsIds,
      },
      sort: parseSorting(sorting),
    },
    { enabled: paginatedFieldsIds.length > 0 },
  );

  const [selectedFieldsState, setSelectedFieldsState] =
    useState<RowSelectionState>({});

  useEffect(() => {
    if (onSelectedFieldsUpdate) {
      const selectedFields = fieldsData?.items.filter(
        field => selectedFieldsState[field.id],
      );
      onSelectedFieldsUpdate(selectedFields ?? []);
    }
  }, [selectedFieldsState, fieldsData, onSelectedFieldsUpdate]);

  const table = useFieldsTable(fieldsData?.items, {
    sorting,
    setSorting,
    selection: selectedFieldsState,
    setSelection: setSelectedFieldsState,
    isSelectionEnabled:
      hasRemoveFieldsFromContractPermission &&
      fieldsToDisplay === "contract-fields" &&
      (contract.status === ContractStatus.Signed ||
        contract.status === ContractStatus.Sent),
    showContractAmendmentStatus: true,
  });

  return (
    <Table
      instance={table}
      // HACK: Pagination Hack
      meta={{
        currentPage: pagination.page || 1,
        itemCount: contract.carbonFields.length,
        totalItems: contract.carbonFields.length,
        itemsPerPage: pagination.limit || 10,
        totalPages: Math.ceil(
          contract.carbonFields.length / (pagination.limit || 10),
        ),
      }}
      pagination={pagination}
      isLoading={isLoading}
      onPaginationChange={updatePagination}
    />
  );
};
