import Skeleton from "react-skeleton-loader";

import { colors } from "@ag/design-system/tokens";
import { grid, stack } from "@ag/design-system/utils";

export const UserOverviewSkeleton = () => (
  <div className={stack()} style={{ fontSize: 0 }}>
    <div className={grid()}>
      <Skeleton
        color={colors.grey[200]}
        width="100%"
        height="442px"
        widthRandomness={0}
        heightRandomness={0}
      />
      <Skeleton
        color={colors.grey[200]}
        width="100%"
        height="442px"
        widthRandomness={0}
        heightRandomness={0}
      />
      <Skeleton
        color={colors.grey[200]}
        width="100%"
        height="442px"
        widthRandomness={0}
        heightRandomness={0}
      />
    </div>

    <div>
      <Skeleton
        color={colors.grey[200]}
        width="100%"
        height="107px"
        widthRandomness={0}
        heightRandomness={0}
      />
    </div>
  </div>
);
