
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/tokens/typography.css.ts", "@ag/design-system");
      import { style } from "@vanilla-extract/css";
export const typographyObj = {
  h1: {
    fontSize: "32px",
    lineHeight: "48px",
    fontWeight: 700
  },
  h2: {
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: 700
  },
  h3: {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 700
  },
  h4: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 700
  },
  h5: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 700
  },
  h6: {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 700
  },
  h7: {
    fontSize: "11px",
    lineHeight: "16px",
    fontWeight: 700
  },
  h8: {
    fontSize: "10px",
    lineHeight: "14px",
    fontWeight: 700
  },
  p1: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400
  },
  p2: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 400
  },
  p3: {
    fontSize: "12px",
    lineHeight: "20px",
    fontWeight: 400
  },
  input: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: 600
  }
};
export const h1 = style(typographyObj.h1, "h1");
export const h2 = style(typographyObj.h2, "h2");
export const h3 = style(typographyObj.h3, "h3");
export const h4 = style(typographyObj.h4, "h4");
export const h5 = style(typographyObj.h5, "h5");
export const h6 = style(typographyObj.h6, "h6");
export const h7 = style(typographyObj.h7, "h7");
export const h8 = style(typographyObj.h8, "h8");
export const p1 = style(typographyObj.p1, "p1");
export const p2 = style(typographyObj.p2, "p2");
export const p3 = style(typographyObj.p3, "p3");
export const input = style(typographyObj.input, "input");
      endFileScope();
      
    