
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/utils/cluster.css.ts", "@ag/design-system");
      import { recipe } from "@vanilla-extract/recipes";
import { DEFAULT_GAP } from "./constants";
import { alignVariants, gapVariants, justifyVariants, rowGapVariants } from "./flex-variants";
export const cluster = recipe({
  base: {
    display: "flex",
    flexWrap: "wrap"
  },
  variants: {
    gap: gapVariants,
    rowGap: rowGapVariants,
    align: alignVariants,
    justify: justifyVariants
  },
  defaultVariants: {
    gap: DEFAULT_GAP
  }
}, "cluster");
      endFileScope();
      
    