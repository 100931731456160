import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";

import { cn } from "~utils";

import {
  ICONS_LIGHT,
  ICONS_REGULAR,
  ICONS_SOLID,
  ICONS_THIN,
} from "./constants";
import { IconConfig } from "./types";

export type IconProps = IconConfig &
  // Use these types up from fortawesome so cases like Tooltip can forward events etc.
  Omit<
    React.SVGAttributes<SVGSVGElement>,
    "children" | "mask" | "transform"
  > & {
    className?: string;
  };

export const Icon = forwardRef(
  (
    { name, className, variant = "regular", ...rest }: IconProps,
    ref: React.Ref<SVGSVGElement>,
  ) => {
    let icon: IconDefinition;

    if (variant === "regular") {
      // a bit of a type mismatch in these, adding type casting for now.
      icon = ICONS_REGULAR[
        name as keyof typeof ICONS_REGULAR
      ] as IconDefinition;
    } else if (variant === "solid") {
      icon = ICONS_SOLID[name as keyof typeof ICONS_SOLID] as IconDefinition;
    } else if (variant === "thin") {
      icon = ICONS_THIN[name as keyof typeof ICONS_THIN] as IconDefinition;
    } else {
      icon = ICONS_LIGHT[name as keyof typeof ICONS_LIGHT] as IconDefinition;
    }

    const style = { "--fa-fw-width": "1em" } as React.CSSProperties;

    return (
      <FontAwesomeIcon
        icon={icon}
        fixedWidth
        className={cn("p-px", className)}
        style={style}
        ref={ref}
        {...rest}
      />
    );
  },
);
Icon.displayName = "Icon";
