import { Variant } from "@amplitude/experiment-js-client";
import { Navigate, useLocation } from "react-router-dom";

import { ToastNotification } from "@ag/utils/services";

import { useIsFlagActive } from "~lib/ampli";

export function withFeatureFlagAccess<T extends object>(
  name: string,
  fallback?: string | Variant | undefined,
  options?: { redirectPath?: string },
) {
  return (WrappedComponent: () => JSX.Element) => {
    const WithFeatureFlagAccessComponent = (props: T) => {
      const location = useLocation();
      const isFeatureFlagActive = useIsFlagActive(name, fallback);

      if (!isFeatureFlagActive) {
        if (!options?.redirectPath) {
          ToastNotification.error("You don't have access to this page");
        }

        return (
          <Navigate
            to={options?.redirectPath ?? "/"}
            state={{ from: location }}
            replace
          />
        );
      }

      return <WrappedComponent {...props} />;
    };

    return WithFeatureFlagAccessComponent;
  };
}
