import { Provider } from "jotai";

export function withProvider<T extends object>(
  Component: React.ComponentType<T>,
) {
  return function WithProvider({ ...props }: T) {
    return (
      <Provider>
        <Component {...props} />
      </Provider>
    );
  };
}
