import { z } from "zod";

import { GeoJSONWithoutPropertiesSchema } from "@ag/map/types";

import { FieldBoundariesTypes } from "~features/field/entities/field-boundaries";

export enum IssueSeverityFlagTypes {
  RED = "red",
  YELLOW = "yellow",
  NONE = "none",
}

export enum BoundarySourceTypes {
  CARBON_FIELD_MRV_BOUNDARY = "CarbonFieldMrvBoundary",
  CARBON_FIELD_LON_LAT = "CarbonFieldLonlat",
  CARBON_HUMMINGBIRD_2022_LOCATION_DATA = "CarbonHummingbirdFieldV2022LocationData",
}

export enum BoundaryStatuses {
  HAS_FARMER_AND_MRV_BOUNDARIES = "has_both",
  HAS_FARMER_BOUNDARY_ONLY = "has_farmer_bd_only",
  HAS_MRV_BOUNDARY_ONLY = "has_mrv_bd_only",
  INCOMPLETE_CHECK_RESULTS = "incomplete_check_results",
  REQUIRES_FARMER_FOLLOW_UP = "awaits_farmer_input",
  FLAG_DETECTED = "flag_detected",
  NO_FLAG_DETECTED = "no_flag_detected",
  FLAG_DETECTED_BUT_OVERRIDDEN = "flag_detected_override",
  FIELD_INELIGIBLE = "field_ineligible",
}

export const IOUCheckResultSchema = z.object({
  carbonFieldId: z.string(),
  carbonFieldMrvBoundaryId: z.string(),
  createdAt: z.string(),
  flag: z.nativeEnum(IssueSeverityFlagTypes),
  id: z.string(),
  result: z.object({
    iou: z.number(),
    sizeRatio: z.number(),
  }),
  type: z.string(),
  updatedAt: z.string(),
});

export const OverlapCheckResultSchema = z.object({
  flag: z.nativeEnum(IssueSeverityFlagTypes),
  overlap: z.number(),
  overlappingFieldId: z.string(),
  sizeHa: z.string(),
  sourceType: z.nativeEnum(BoundarySourceTypes),
});

export const OverlapCheckResultsSchema = z.object({
  carbonFieldId: z.string(),
  carbonFieldMrvBoundaryId: z.string(),
  flag: z.nativeEnum(IssueSeverityFlagTypes),
  id: z.string(),
  result: z.array(OverlapCheckResultSchema),
});

export const CheckResultsSchema = z.object({
  overlap: OverlapCheckResultsSchema.optional(),
  iou: IOUCheckResultSchema.optional(),
});

export const FieldBoundariesWithChecksResultsSchema = z.object({
  id: z.string(),
  carbonFieldId: z.string(),
  boundaries: GeoJSONWithoutPropertiesSchema,
  type: z.nativeEnum(FieldBoundariesTypes),
  createdAt: z.string(),
  updatedAt: z.string(),
  activeAt: z.string().nullable(),
  isActive: z.boolean(),
  checkResults: CheckResultsSchema.optional(),
  sizeHa: z.string(),
});

export const BoundaryStatusSchema = z.object({
  carbonFieldId: z.string(),
  status: z.nativeEnum(BoundaryStatuses),
  metadata: z.object({
    comment: z.string().optional(),
  }),
});

export const FieldWithAllBoundariesSchema = z.object({
  boundaries: z.array(FieldBoundariesWithChecksResultsSchema),
  boundaryStatus: BoundaryStatusSchema.nullable(),
  carbonFieldId: z.string(),
  editable: z.boolean(),
  harvestYear: z.number(),
});

export type FieldWithAllBoundaries = z.infer<
  typeof FieldWithAllBoundariesSchema
>;

export type OverlapCheckResult = z.infer<typeof OverlapCheckResultSchema>;
export type IOUCheckResult = z.infer<typeof IOUCheckResultSchema>;
export type CheckResults = z.infer<typeof CheckResultsSchema>;
export type BoundaryStatus = z.infer<typeof BoundaryStatusSchema>;

export type FieldBoundariesWithChecksResults = z.infer<
  typeof FieldBoundariesWithChecksResultsSchema
>;
