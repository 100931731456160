import { zodResolver } from "@hookform/resolvers/zod";
import { isValid } from "date-fns";
import { useAtom } from "jotai";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Spinner } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { useCreateCropCountryPropertiesMutation } from "../api/create-crop-country-properties";
import { useCropCountryDetailsByHarvestYearQuery } from "../api/get-crop-country-harvest-year";
import { useUpdateCropCountryPropertiesMutation } from "../api/update-crop-country-properties";
import { cropCountriesAtom } from "../atoms/crop-countries";
import {
  DATE_FORMAT,
  formatCropCountryProperties,
  reverseFormatCropCountryProperties,
} from "../helpers";

const DATE_FORMAT_MESSAGE = `Please provide a valid date in the format ${DATE_FORMAT}`;

const verifyDate = (value: string) =>
  /^\d{4}-\d{2}-\d{2}$/.test(value) && isValid(new Date(value));

const CropCountryPropertiesFormSchema = z.object({
  seeding_period_start: z
    .string()
    .refine(verifyDate, { message: DATE_FORMAT_MESSAGE }),
  seeding_period_end: z
    .string()
    .refine(verifyDate, { message: DATE_FORMAT_MESSAGE }),
  harvest_period_start: z
    .string()
    .refine(verifyDate, { message: DATE_FORMAT_MESSAGE }),
  harvest_period_end: z
    .string()
    .refine(verifyDate, { message: DATE_FORMAT_MESSAGE }),
  yield_avg: z.number().min(0),
  yield_max: z.number().min(0),
  yield_min: z.number().min(0),
});

export type CropCountryPropertiesFormData = z.infer<
  typeof CropCountryPropertiesFormSchema
>;

const CropCountryPropertiesForm = ({
  id,
  harvestYear,
  country,
  disabled,
}: {
  id: string;
  harvestYear: string;
  country: string;
  disabled: boolean;
}) => {
  const { isLoading, data } = useCropCountryDetailsByHarvestYearQuery(
    id,
    harvestYear,
    country,
  );
  const createCropCountryProperties = useCreateCropCountryPropertiesMutation();
  const updateCropCountryProperties = useUpdateCropCountryPropertiesMutation();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<CropCountryPropertiesFormData>({
    resolver: zodResolver(CropCountryPropertiesFormSchema),
  });

  // reset form if editing is cancelled
  useEffect(() => {
    if (disabled && isDirty && data?.properties)
      reset(formatCropCountryProperties(data.properties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  // Set form values once data is fetched
  useEffect(() => {
    if (data?.properties) reset(formatCropCountryProperties(data.properties));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSuccess = () =>
    ToastNotification.success(
      `Crop properties for ${capitalize(country)} updated successfully`,
    );

  const handleUpdateCropCountryProperites = (
    formData: CropCountryPropertiesFormData,
  ) => {
    const properties = reverseFormatCropCountryProperties(formData);
    // If data === null, resource was not found
    if (data === null) {
      createCropCountryProperties.mutate(
        {
          id,
          harvestYear,
          country,
          properties,
        },
        { onSuccess: handleSuccess },
      );
    } else {
      updateCropCountryProperties.mutate(
        {
          id,
          harvestYear,
          country,
          properties,
        },
        { onSuccess: handleSuccess },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdateCropCountryProperites)}>
      <h3 className="mb-2 text-h3">{capitalize(country)}</h3>
      {isLoading ? (
        <Spinner className="h-5" />
      ) : (
        <section className="mb-8 grid grid-cols-2 items-end gap-x-8 gap-y-4">
          <InputField
            {...register("seeding_period_start")}
            label="Seeding Period Start Date"
            isDisabled={disabled}
            error={errors.seeding_period_start}
          />

          <InputField
            {...register("seeding_period_end")}
            label="Seeding Period End Date"
            isDisabled={disabled}
            error={errors.seeding_period_end}
          />

          <InputField
            {...register("harvest_period_start")}
            label="Harvest Period Start Date"
            isDisabled={disabled}
            error={errors.harvest_period_start}
          />

          <InputField
            {...register("harvest_period_end")}
            label="Harvest Period End Date"
            isDisabled={disabled}
            error={errors.harvest_period_end}
          />

          <InputField
            {...register("yield_max", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            label="Maximum Yield"
            type="number"
            step="0.0001"
            isDisabled={disabled}
            error={errors.yield_max}
          />

          <InputField
            {...register("yield_min", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            label="Minimum Yield"
            type="number"
            step="0.0001"
            isDisabled={disabled}
            error={errors.yield_min}
          />

          <InputField
            {...register("yield_avg", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            label="Average Yield"
            type="number"
            step="0.0001"
            isDisabled={disabled}
            error={errors.yield_avg}
          />

          {!disabled && (
            <Button type="submit" className="w-fit">
              Update
            </Button>
          )}
        </section>
      )}
    </form>
  );
};

const CropCountriesPropertiesForm = ({
  id,
  harvestYear,
}: {
  id: string;
  harvestYear: string;
}) => {
  const [cropCountries] = useAtom(cropCountriesAtom);
  const [editing, setEditing] = useState(false);

  return (
    <div>
      <div className="flex justify-between">
        <h2 className="text-h2">Crop Country Properties</h2>
        <div>
          <Button
            onClick={() => setEditing(!editing)}
            variant={editing ? "secondary" : "primary"}
          >
            {editing ? "Cancel" : "Edit"}
          </Button>
        </div>
      </div>
      <section className="flex flex-col gap-8">
        {cropCountries.map((country, index) => (
          <CropCountryPropertiesForm
            key={`country-${index}`}
            id={id}
            harvestYear={harvestYear}
            country={country}
            disabled={!editing}
          />
        ))}
      </section>
    </div>
  );
};

export default CropCountriesPropertiesForm;
