
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/utils/grid.css.ts", "@ag/design-system");
      import { recipe } from "@vanilla-extract/recipes";
import { DEFAULT_GAP } from "./constants";
import { alignVariants, gapVariants, justifyVariants } from "./flex-variants";
export const grid = recipe({
  base: {
    display: "grid",
    gridAutoColumns: "minmax(0, 1fr)",
    gridAutoFlow: "column"
  },
  variants: {
    gap: gapVariants,
    align: alignVariants,
    justify: justifyVariants,
    autoFlow: {
      row: {
        gridAutoFlow: "row"
      },
      column: {
        gridAutoFlow: "column"
      }
    }
  },
  defaultVariants: {
    gap: DEFAULT_GAP
  }
}, "grid");
      endFileScope();
      
    