import { z } from "zod";

import { AdditionalInformationV2021DataSchema } from "./schemas";

export enum AdditionalInformationAttribute {
  Comment = "otherInformationComment",
}

export type AdditionalInformationV2021Data = z.infer<
  typeof AdditionalInformationV2021DataSchema
>;

export enum AdditionalInformationOptionalAttribute {
  Comment = AdditionalInformationAttribute.Comment,
}
