import type { Config } from "tailwindcss";
import animatePlugin from "tailwindcss-animate";
import plugin from "tailwindcss/plugin";

import { colorsObject } from "../tokens/colors";
import keyframes from "./theme/keyframes";

// KEEP THIS FILE IN SYNC with extendedTWMerge for custom css classes that follow a similar pattern to tailwinds classes
export const tailwindConfig = {
  content: [],
  theme: {
    extend: {
      zIndex: {
        low: "1",
        banner: "1000",
        drawer: "2000",
        modal: "3000",
        notifications: "4000",
      },
    },
    colors: {
      ...colorsObject,
      transparent: "transparent",
      current: "currentColor",
      positive: {
        foreground: colorsObject.messaging.success[900],
        background: colorsObject.messaging.success[100],
        hover: colorsObject.messaging.success[300],
      },
      warning: {
        foreground: colorsObject.messaging.warning[900],
        background: colorsObject.messaging.warning[100],
        hover: colorsObject.messaging.warning[300],
      },
      info: {
        foreground: colorsObject.accent[200],
        background: colorsObject.messaging.info[100],
        hover: colorsObject.messaging.info[300],
      },
      danger: {
        foreground: colorsObject.messaging.error[700],
        background: colorsObject.messaging.error[100],
        hover: colorsObject.messaging.error[300],
      },
      neutral: {
        foreground: colorsObject.grey[900],
        background: colorsObject.grey[200],
        hover: colorsObject.grey[300],
      },
    },
    fontSize: {
      // Headings
      h1: [
        "2rem", // 32px
        {
          lineHeight: "1.5",
          fontWeight: "500",
        },
      ],
      h2: [
        "1.5rem", // 24px
        {
          lineHeight: "1.5",
          fontWeight: "500",
        },
      ],
      h3: [
        "1.25rem", // 20px
        {
          lineHeight: "1.5",
          fontWeight: "500",
        },
      ],

      p1: [
        "1rem", // 16px
        {
          lineHeight: "1.5",
          fontWeight: "400",
        },
      ],
      p2: [
        "0.875rem", // 14px
        {
          lineHeight: "1.5",
          fontWeight: "400",
        },
      ],
      p3: [
        "0.75rem", // 12px
        {
          lineHeight: "1.5",
          fontWeight: "400",
        },
      ],

      inherit: "inherit",
    },
    fontWeight: {
      regular: "400",
      medium: "500",
      bold: "700",
    },

    boxShadow: {
      100: "0px 4px 8px rgba(0, 0, 0, 0.05)",
      200: "0px 8px 8px rgba(0, 0, 0, 0.1)",
    },

    keyframes,
  },
  plugins: [
    animatePlugin,
    plugin(function ({ addVariant }) {
      addVariant("group-state-open", ":merge(.group)[data-state=open] &");
    }),
  ],
} satisfies Config;
