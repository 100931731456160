import { useLocation, useNavigate } from "react-router-dom";
import { useDidUpdate } from "rooks";

import { Button } from "@ag/design-system/atoms";

import ImgAgreenaLogo from "~assets/images/logo/agreena.svg";

import ImgPest from "../assets/icon-pest.svg";

type Props = {
  onErrorReset: () => void;
};

const GeneralError = ({ onErrorReset }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useDidUpdate(() => {
    onErrorReset();
  }, [pathname]);

  return (
    <section className="mx-auto flex h-screen w-96 flex-col items-center justify-center">
      <img className="w-[120px]" src={ImgAgreenaLogo} />

      <img className="w-22 my-8 inline-block" src={ImgPest} />

      <h2 className="mb-4 text-h2">Oops, sorry about that!</h2>

      <div className="mb-4 text-center">
        <p>There was an unexpected error.</p>
        <p>Please go back and try again.</p>
      </div>

      <Button className="mb-4" onClick={() => navigate(-1)}>
        Go back
      </Button>
    </section>
  );
};

export default GeneralError;
