import { z } from "zod";

import { FuelConsumptionV2022DataSchema } from "./schemas";

export enum FuelConsumptionAttribute {
  EnergySource = "energyConsumptionEnergySource",
  AmountHa = "energyConsumptionAmountHa",
  AmountUnit = "energyConsumptionAmountUnit",
}

export enum FuelConsumptionOptionalAttribute {
  AmountUnit = FuelConsumptionAttribute.AmountUnit,
}

export type FuelConsumptionV2022Data = z.infer<
  typeof FuelConsumptionV2022DataSchema
>;
