import styled from "@emotion/styled";

import { colorsObject } from "@ag/design-system/tokens";

export const SFertiliser = styled.section`
  padding-bottom: 24px;
  border-bottom: 1px solid ${colorsObject.grey[200]};
`;

export const SRow = styled.div`
  display: flex;
  align-items: flex-start;

  & + & {
    margin-top: 16px;
  }
`;

export const SSmallCol = styled.div`
  width: 240px;
`;

export const SMediumCol = styled.div`
  width: 320px;
`;

export const SLargeCol = styled.div`
  width: 368px;
`;
