import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config";

import { generateFieldsQueryKey } from "./get-fields";

export type CarbonFieldUpdate = {
  id: string;
  name?: string;
  expired?: boolean;
};

type UpdateFieldDetailsArgs = {
  carbonFields: CarbonFieldUpdate[];
};

type RejectedFieldUpdate = {
  payload: CarbonFieldUpdate;
  message: string;
  name: string;
};

type FieldUpdateResult =
  | {
      success: string[];
      errors?: never;
    }
  | {
      success?: never;
      errors: RejectedFieldUpdate[];
    };

export const updateFieldsDetails = async ({
  carbonFields,
}: UpdateFieldDetailsArgs) => {
  const response = await axios.put<CommonResponse<FieldUpdateResult>>(
    `/api/v1/carbon-fields/bulk`,
    {
      carbonFields,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useUpdateFieldsDetailsMutation = () =>
  useMutation(updateFieldsDetails, {
    onSuccess: response => {
      if (response.errors?.length) {
        const errMessage = response.errors
          .map(err => `${err.payload.name} ${err.payload.name}: ${err.message}`)
          .join(", ");

        ToastNotification.error(errMessage);
      } else {
        const fieldsUpdated = response.success?.length;
        ToastNotification.success(
          fieldsUpdated
            ? `${response.success?.length} field name${
                fieldsUpdated !== 1 ? "s" : ""
              } updated successfully`
            : "Field names updated successfully",
        );
      }
      queryClient.invalidateQueries(generateFieldsQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });

export const useToggleFieldsExpiredStatusMutation = () =>
  useMutation(updateFieldsDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateFieldsQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
