import pick from "lodash/pick";

import { FuelConsumptionAttribute } from "@ag/carbon/forms/inputs-v2022";

import { FieldActualsV2022Input } from "~features/field";
import { FieldActualsInputV2022 } from "~queries/carbon/field-actuals";

export const getInitialValues = (fieldActualsInput: FieldActualsV2022Input) =>
  pick<FieldActualsInputV2022, FuelConsumptionAttribute>(
    fieldActualsInput,
    Object.values(FuelConsumptionAttribute),
  );
