import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { Admin, AdminSchema } from "../entities/admin";

export const generateAdminQueryKey = (adminId: string) => ["admin", adminId];

const getAdmin = async (adminId: string) => {
  const response = await axios.get<CommonResponse<Admin>>(
    `/api/v1/admins/${adminId}`,
    {
      apiSource: "node-auth",
      schema: AdminSchema,
    },
  );

  return response.data.success!.data;
};

export const useAdminQuery = (adminId: string) =>
  useQuery(generateAdminQueryKey(adminId), () => getAdmin(adminId), {
    keepPreviousData: true,
  });
