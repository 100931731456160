import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

import { Country, CountrySchema } from "../entities/country";

export const generateCountriesKey = () => ["countries"];

const getCountries = async () => {
  const response = await axios.get<CommonResponse<Country[]>>(
    "/api/v1/countries",
    {
      apiSource: "node-user-mgt",
      schema: z.array(CountrySchema),
    },
  );

  return response.data.success!.data;
};

export const useCountries = (options?: Pick<UseQueryOptions, "enabled">) =>
  useQuery(generateCountriesKey(), () => getCountries(), options);
