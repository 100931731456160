import { InfoWindow } from "@react-google-maps/api";

import { cn } from "@ag/design-system/utils";

import "./field-label.css";

export const FieldLabel = ({
  fieldLabel,
  position,
  isSelectedField,
  isCentrePointOnly,
}: {
  fieldLabel: string;
  position: google.maps.LatLngLiteral;
  isSelectedField: boolean;
  isCentrePointOnly: boolean;
}) => {
  return (
    <InfoWindow position={position}>
      <div
        className={cn(
          "flex flex-col items-center",
          isSelectedField ? "selected-field-label" : "",
          isCentrePointOnly ? "centre-point-only" : "",
        )}
      >
        <p className="text-p3">{fieldLabel}</p>
        {isCentrePointOnly && <p>no boundary found</p>}
      </div>
    </InfoWindow>
  );
};
