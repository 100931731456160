import { z } from "zod";

import { FertiliserDataSchema, FertiliserV2022DataSchema } from "./schemas";

// Partial attributes coming from API
export enum FertiliserAttribute {
  Fertilisers = "fertilisers",
  FertilisersCount = "fertilisersCount",
}

// View only attributes - not sent to the API
export enum FertiliserViewAttribute {
  IsFertilisersUsed = "isFertilisersUsed",
}

// Attributes for the fertiliser data
export enum FertiliserDataAttribute {
  ApplicationRate = "applicationRate",
  CarbonFertiliserId = "carbonFertiliserId",
  EmissionsInhibitors = "emissionsInhibitors",
  NitrificationApplicationRate = "nitrificationApplicationRate",
  NitrogenApplicationRate = "nitrogenApplicationRate",
  Mode = "mode",
  PhosphorusApplicationRate = "phosphorusApplicationRate",
  PhosphorusType = "phosphorusType",
  PotassiumApplicationRate = "potassiumApplicationRate",
  PotassiumType = "potassiumType",
}

export type FertiliserV2022Data = z.infer<typeof FertiliserV2022DataSchema>;

export type FertiliserData = z.infer<typeof FertiliserDataSchema>;
