import { css } from "@emotion/core";

import { colors } from "@ag/design-system/tokens";

// TODO: refactor usages and remove
export const reactTooltipStyles = css`
  .ct-tooltip {
    && {
      fontSize: 0.75rem,
      lineHeight: 1.5,
      fontWeight: 400,
      max-width: 304px;
      z-index: 9999;
      background-color: ${colors.accent[100]};
    }

    &&& {
      &.place-top::after {
        border-top-color: ${colors.accent[100]};
      }

      &.place-bottom::after {
        border-bottom-color: ${colors.accent[100]};
      }

      &.place-left::after {
        border-left-color: ${colors.accent[100]};
      }

      &.place-right::after {
        border-right-color: ${colors.accent[100]};
      }
    }

    &.wide {
      max-width: 400px;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-left: 20px;
    }
  }
`;
