export const CertificateVcuType = {
  VERRA: "verra",
  ISO: "iso",
} as const;

export enum CertificateGroupStandard {
  VERRA = "verra",
  ISO = "iso",
}

export enum CertificateGroupMethodology {
  ACM0008 = "ACM0008",
  ISO140642 = "ISO 14064-2",
}

export enum CertificateGroupType {
  REDUCTION = "reduction",
  REMOVAL = "removal",
}

export enum CertificateGroupStatus {
  LOCKED = "locked",
  RETIRED = "retired",
  TRANSFERRED = "transferred",
  AVAILABLE_FOR_SALE = "available-for-sale",
}
