import { z } from "zod";

export const CropSchema = z.object({
  cool_farm_name: z.string().nullable(),
  created_at: z.string(),
  crop_science_id: z.number().nullable(),
  id: z.string(),
  legacy_agreena_crop_id: z.string().nullable(),
  name: z.string(),
});

export type Crop = z.infer<typeof CropSchema>;
