import { Modal, Table } from "@ag/design-system/organisms";

import { useFieldActualsChangesQuery } from "~features/field";

import { Field } from "../entities/field";
import { useFieldActualsChangesTable } from "../hooks/use-field-actuals-changes-table";

type Props = {
  field: Field | null;
  isOpen: boolean;
  onRequestClose: () => void;
};

// TODO: move to fields feature
export const FieldActualsChangesModal = ({
  field,
  isOpen,
  onRequestClose,
}: Props) => {
  const { data, isLoading } = useFieldActualsChangesQuery(
    {
      fieldId: field?.id,
      fieldActualsId: field?.carbonFieldActual.id,
    },
    {
      enabled: Boolean(isOpen && field?.carbonFieldActual.id),
    },
  );

  const table = useFieldActualsChangesTable(data);

  return (
    <Modal.Root
      size="extra-large"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Modal.Header>
        <Modal.Title>Field actuals changes</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <Table instance={table} isLoading={isLoading} />
      </Modal.Content>
    </Modal.Root>
  );
};
