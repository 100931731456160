import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";
import { generateFieldsQueryKey } from "~features/field/api/get-fields";

import { RemovalReason } from "../entities/contract";
import { generateContractQueryKey } from "./get-contract";

export type CarbonFieldToUnlink = {
  id: string;
  removalReason: RemovalReason;
  churnedAt?: string;
};

export type UnlinkFieldsFromContractArgs = {
  carbonContractId: string;
  carbonFields: CarbonFieldToUnlink[];
};

const unlinkFieldsFromContract = async ({
  carbonContractId,
  carbonFields,
}: UnlinkFieldsFromContractArgs) => {
  const response = await axios.post(
    `/api/v1/carbon-fields/unlink-active-contract`,
    {
      carbonContractId,
      carbonFields,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!;
};

export const useUnlinkFieldsFromContractMutation = () =>
  useMutation(unlinkFieldsFromContract, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(generateFieldsQueryKey());
      queryClient.invalidateQueries(
        generateContractQueryKey({
          contractId: Number(variables.carbonContractId),
        }),
      );
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
