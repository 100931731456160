import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";

import { useUpdateRequestStore } from "../store/update-request-store";

type Props = {
  onRejectTransfer: () => void;
};

export const RejectModal = ({ onRejectTransfer }: Props) => {
  const { setIsRejectModalVisible } = useUpdateRequestStore();

  const handleOnClose = () => {
    setIsRejectModalVisible(false);
  };

  return (
    <Modal.Root
      onRequestClose={handleOnClose}
      className="bg-gray-700"
      size="small"
      isCentered
    >
      <Modal.Content>
        <div className="flex min-h-[400px] flex-col items-center justify-center gap-6 p-10">
          <div className="flex gap-2">
            <Icon
              name="shield-check"
              variant="light"
              fontSize={56}
              className="text-red-700"
            />
            <Icon name="minus" fontSize={56} className="text-red-700" />
            <Icon
              name="shield-x-mark"
              variant="solid"
              fontSize={56}
              className="text-red-700"
            />
          </div>

          <div className="flex flex-col items-center justify-center gap-4">
            <span className="text-h3 font-bold">Reject transfer process!</span>
            <p className="max-w-[300px] text-center text-p2">
              This action will permanently reject and delete this pre-transfer
              request. A new pre-transfer can always be started from scratch.
            </p>
          </div>

          <div className="flex flex-col gap-6">
            <Button variant="primary" isDanger onClick={onRejectTransfer}>
              Reject Transfer
            </Button>
            <Button variant="text" onClick={handleOnClose}>
              Let me ask first
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
