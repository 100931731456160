import { FieldDetails } from "~queries/carbon/fields";

export type FieldPartialProps = {
  field: FieldDetails;
};

export enum FieldDefinitionPartial {
  SoilCharacteristics = "SOIL_CHARACTERISTICS",
  HistoryOfDeforestation = "HISTORY_OF_DEFORESTATION",
  SoilCultivationPractice = "SOIL_CULTIVATION_PRACTICE",
  AdditionalInformation = "ADDITIONAL_INFORMATION",
}

export enum FieldPartialStatus {
  NotStarted = "NOT_STARTED",
  InProgress = "IN_PROGRESS",
  Done = "DONE",
}
