import { useSessionContext } from "~features/auth";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";

const LandingPage = () => {
  const { currentAdmin } = useSessionContext();

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <DetailsLayout.TopBarTitle>Admin</DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>
      <DetailsLayout.Content className="self-flex-start m-4">
        <div className="text-h3">Welcome, {currentAdmin?.email}</div>
        <div>
          <span className="mr-1 text-p1">
            Read more about Admin and how to use it
          </span>
          <a
            className="text-bold text-underlined text-accent-300"
            href="https://www.notion.so/agreena/Intro-to-Admin-6c86ce6144bf491da007075d5baaf978"
            rel="noreferrer"
            target="_blank"
          >
            here
          </a>
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default LandingPage;
