import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  ImportStatus,
  InventoryImport,
  InventoryImportSchema,
} from "../entities/inventory-import";

type InventoryImportsParams = MetaArgs & {
  filters?: {
    adminId?: string;
    status?: ImportStatus[];
  };
  sort?: {
    createdAt?: SortingDirection;
  };
};

export const generateInventoryImportsQueryKey = (keyVariables?: {
  params?: InventoryImportsParams | undefined;
}) => ["inventory-imports", keyVariables].filter(Boolean);

const getInventoryImports = async (
  params: InventoryImportsParams | undefined,
) => {
  const response = await axios.get<CommonPaginatedResponse<InventoryImport>>(
    "/api/v1/inventory/imports",
    {
      params: {
        ...params,
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(InventoryImportSchema),
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useInventoryImportsQuery = (params?: InventoryImportsParams) =>
  useQuery(
    generateInventoryImportsQueryKey({ params }),
    () => getInventoryImports(params),
    {
      keepPreviousData: true,
    },
  );
