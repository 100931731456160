import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icon } from "@ag/design-system/assets";
import { Button, FileRejection, InfoBox } from "@ag/design-system/atoms";
import { DropzoneField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import {
  useDistributeVcuMutation,
  useImportIsoVcuStore,
} from "~features/import-vcu";

type Props = {
  onGoPrevStep: () => void;
  unblockRoute: () => void;
};

const SelectIsoDistribution = ({ onGoPrevStep, unblockRoute }: Props) => {
  const [fileErrors, setFileErrors] = useState<string[] | undefined>(undefined);

  const navigate = useNavigate();

  const { distributionFile, setDistributionFile, resetState } =
    useImportIsoVcuStore();

  const distributeVcuMutation = useDistributeVcuMutation();

  const handleFileDropped = (
    acceptedFile: File | undefined,
    fileRejection: FileRejection | undefined,
  ) => {
    setFileErrors(undefined);

    if (fileRejection) {
      const errors = fileRejection.errors.map(error => {
        if (error.code === "file-too-large") {
          return "File is too large, please upload a file smaller than 500KB";
        }
        if (error.code === "file-invalid-type") {
          return "Invalid file type, please upload a .csv file";
        }

        return error.message;
      });

      setFileErrors(errors);

      return;
    }

    setDistributionFile(acceptedFile);
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!distributionFile) return;

    distributeVcuMutation.mutate(distributionFile, {
      onSuccess: () => {
        unblockRoute();
        navigate("/certificates-management");
        ToastNotification.success("Certificates successfully imported");
        resetState();
      },
    });
  };

  return (
    <form
      onSubmit={onSubmit}
      className="flex w-full flex-auto flex-col gap-8 bg-grey-100 pt-14"
    >
      <div className="flex w-full flex-col items-center justify-start gap-8">
        <h2 className="text-h2 font-bold">Select the distribution file</h2>

        {fileErrors?.map(fileError => (
          <InfoBox key={fileError} variant="danger" icon="triangle-exclamation">
            {fileError}
          </InfoBox>
        ))}

        {distributionFile ? (
          <div className="flex h-[320px] w-[1000px] flex-col items-center justify-center gap-2 rounded-md border border-dashed border-accent-200 bg-grey-100 p-12">
            <Icon name="check" fontSize={32} />
            <h5 className="text-p1">Upload complete</h5>
            <div className="flex gap-2">
              <h5
                className="text-p2 text-grey-700"
                data-testid="distribution-file-name"
              >
                {distributionFile?.name}
              </h5>
              <Button
                type="button"
                size="x-small"
                variant="text"
                onClick={() => setDistributionFile(undefined)}
                isDanger
              >
                Remove
              </Button>
            </div>
          </div>
        ) : (
          <div className="h-[320px] w-[1000px]">
            <DropzoneField
              onChange={handleFileDropped}
              label=""
              acceptType={[".csv"]}
              maxSize={0.5}
              isMultiple={false}
              value={distributionFile}
              dropText=""
            >
              <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-12">
                <Icon name="chart-network" className="text-h1 text-grey-700" />
                <h5 className="text-p1">Drag and drop distribution file</h5>
                <h6 className="text-p2 text-grey-700">.CSV, max 500KB</h6>

                <span className="text-p1">or</span>

                <Button
                  type="button"
                  size="small"
                  variant="text"
                  onClick={() => null}
                >
                  Select from folder
                </Button>
              </div>
            </DropzoneField>
          </div>
        )}
      </div>
      <footer className="mt-auto flex h-[104px] w-full items-center p-8 shadow-[0_-4px_8px_rgba(0,0,0,0.05)]">
        <div className="mr-auto">
          <Button
            type="button"
            icon="chevron-left"
            iconPosition="before"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className="ml-auto">
          <Button
            type="submit"
            icon="chevron-right"
            iconPosition="after"
            disabled={!distributionFile}
            isLoading={distributeVcuMutation.isLoading}
          >
            Distribute
          </Button>
        </div>
      </footer>
    </form>
  );
};

export default SelectIsoDistribution;
