export enum FieldActualsInputType {
  V2021 = "CarbonFieldActualV2021Input",
  V2022 = "CarbonFieldActualV2022Input",
}

export enum FieldDefinitionStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
  Submitted = "submitted",
}

export enum FieldActualsStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
  Submitted = "submitted",
}

export enum CoverCrops {
  None = "none",
  Catch = "catch",
  Spring = "spring",
  Winter = "winter",
}

export enum TillingPracticeValue {
  NoTill = 0,
  ReducedTillage = 0.5,
  Conventional = 1,
  DontKnow = -1,
}

export enum CropResidueManagement {
  Mulched = "mulched",
  Burned = "burned",
  Removed = "removed",
}

export enum MethodologyVersion {
  V1 = "1",
  V2 = "2",
}

export enum BaselineVersion {
  V2 = "2",
}

export enum BaselineStandard {
  ISO = "ISO",
  VERRA = "VERRA",
}

export enum FertiliserAddingMode {
  ChemicalSpecification = "CHEMICAL_SPECIFICATION",
  List = "LIST",
}

export enum FertiliserMode {
  Organic = "organic",
  Synthetic = "synthetic",
}

export enum FertiliserEmissionsInhibitors {
  Nitrification = "Nitrification",
  None = "None",
}
