import { To } from "react-router-dom";

import { OptionalObject } from "@ag/utils/types";

import { Button, ButtonIconProps, ButtonProps } from "~atoms";

type ActionProps = { onClick: React.MouseEventHandler<HTMLButtonElement> };
type LinkProps = { to: To };
type ExternalLinkProps = { href: string };

export type ButtonCellValue =
  | (Pick<ButtonProps, "variant" | "isDanger"> & {
      children: string;
    } & OptionalObject<ButtonIconProps> &
      OptionalObject<ActionProps | LinkProps | ExternalLinkProps>)
  | null;

type ButtonCellProps<TValue extends ButtonCellValue> = {
  getValue: () => TValue;
};

export const ButtonCell = <TValue extends ButtonCellValue>({
  getValue,
}: ButtonCellProps<TValue>) => {
  const value = getValue();

  if (!value) return "-";

  const isDisabled =
    (value as ActionProps).onClick == null &&
    (value as LinkProps).to == null &&
    (value as ExternalLinkProps).href == null;

  // This seems to be the only way to set default values while keeping TS happy.
  value.variant = value.variant ?? "secondary";

  return <Button size="x-small" disabled={isDisabled} {...value} />;
};
