import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config/react-query";
import { generateInventoryImportsQueryKey } from "~features/inventory/api/get-inventory-imports";

export const generateProgressPollingQueryKey = (id: string) => [
  "progress-polling",
  id,
];

export type ProgressStatus = {
  importId: string;
  percentage: number | null;
  current: number;
  total: number;
  hasFailed?: boolean;
};

const getPollingProgress = async (id: string) => {
  try {
    const response = await axios.get<CommonResponse<ProgressStatus>>(
      `/api/v1/inventory/imports/${id}/processing-progress`,
      { apiSource: "node-markets" },
    );
    const data = response.data.success?.data;

    if (isPollingDone(data)) {
      queryClient.invalidateQueries(generateInventoryImportsQueryKey());
    }

    return data;
  } catch (e) {
    queryClient.invalidateQueries(generateInventoryImportsQueryKey());
    return {
      importId: id,
      percentage: 0,
      current: 0,
      total: 0,
      hasFailed: true,
    };
  }
};

const isPollingDone = (data?: ProgressStatus) =>
  data && (data.percentage === 100 || data.hasFailed);

export const useProgressPollingQuery = (
  id: string,
  options: Pick<UseQueryOptions, "enabled">,
) => {
  return useQuery(
    generateProgressPollingQueryKey(id),
    () => getPollingProgress(id),
    {
      refetchInterval: data => (isPollingDone(data) ? false : 2000),
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};
