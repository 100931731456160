import I18n from "i18n-js";

import { Icon } from "@ag/design-system/assets";
import { Modal } from "@ag/design-system/organisms";

import { AdminCommentForm } from "./admin-comment-form/admin-comment-form";
import * as styles from "./comment-modal.css";

type Props = {
  isOpen: boolean;
  isSubmitting: boolean;
  onSubmit: (comment: string) => void;
  onClose: () => void;
};

const CommentModal = ({ isOpen, isSubmitting, onClose, onSubmit }: Props) => (
  <Modal.Root size="small" isLocked isOpen={isOpen}>
    <Modal.Content>
      <div className={styles.warningMessage}>
        <Icon name="warning" variant="regular" />

        {I18n.t("js.admin.field_details.admin_edit_field_warning_message")}
      </div>

      <AdminCommentForm
        isSubmitting={isSubmitting}
        onCancel={onClose}
        onSubmit={({ comment }) => onSubmit(comment)}
      />
    </Modal.Content>
  </Modal.Root>
);

export default CommentModal;
