import { useCallback, useMemo, useState } from "react";

import { Field } from "../entities/field";

export const useFieldsBulkModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [fields, setFields] = useState<Field[] | null>(null);

  const close = useCallback(() => {
    setFields(null);
    setIsOpen(false);
  }, []);

  const open = useCallback((fields: Field[] | null) => {
    setFields(fields);
    setIsOpen(true);
  }, []);

  return useMemo(
    () => ({
      fields,
      isOpen,

      open,
      close,
    }),
    [fields, isOpen, open, close],
  );
};
