import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import ReactTooltip from "react-tooltip";

import { style } from "@ag/ui";
import {
  FieldActualsStatus,
  FieldDefinitionStatus,
  FieldStep,
} from "@ag/utils/types";

import {
  SFieldStepTabTitle,
  SSpinner,
  SStatusIcon,
  STitleWrapper,
} from "./styled";

type Props = {
  status: FieldDefinitionStatus | FieldActualsStatus;
  title: string;
  step: FieldStep;
  tip?: string | null;
  isStatusLoading?: boolean;
  isFieldLoading?: boolean;
};

export const FieldStepTabTitle = ({
  status,
  title,
  tip,
  step,
  isStatusLoading,
  isFieldLoading,
}: Props) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tip]);

  if (isFieldLoading) {
    return (
      <SFieldStepTabTitle>
        <Skeleton baseColor={style.color.grey[200]} />
      </SFieldStepTabTitle>
    );
  }

  return (
    <SFieldStepTabTitle
      data-tip={tip}
      data-for="carbon-tooltip"
      data-place="top"
      data-class="ct-tooltip"
      data-offset="{ 'top': -18 }"
    >
      <STitleWrapper>
        {isStatusLoading ? (
          <SSpinner />
        ) : (
          <SStatusIcon status={status} step={step} />
        )}

        <h3 className="text-h3">{title}</h3>
      </STitleWrapper>
    </SFieldStepTabTitle>
  );
};
