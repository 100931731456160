import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { Contract, ContractSchema } from "../entities/contract";

export const generateContractQueryKey = (keyVariables?: {
  contractId: number;
}) => ["contract", keyVariables].filter(Boolean);

type GetContractParams = {
  forceRefresh?: boolean;
};

export const getContract = async (
  contractId: number,
  params?: GetContractParams,
) => {
  const response = await axios.get<CommonResponse<Contract>>(
    `/api/v1/contracts/${contractId}`,
    {
      params,
      schema: ContractSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useContractQuery = (
  contractId: number,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateContractQueryKey({ contractId }),
    () => getContract(contractId),
    {
      keepPreviousData: true,
      ...options,
    },
  );
