import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { generateContractQueryKey } from "./get-contract";

export type VoidContractPayload = {
  contractId: string;
  reason: TerminationReason;
  reasonComment: string;
};

export enum TerminationReason {
  OTHER = "other",
  NOVATED = "novated",
  BREACH = "breach",
  NON_PARTICIPATION = "non-participation",
  TERMINATED_BY_FARMER = "terminated by farmer",
}

const voidSignedContract = async ({
  contractId,
  reasonComment,
  reason,
}: VoidContractPayload) => {
  return axios.delete(`/api/v1/contracts/terminate/${contractId}`, {
    params: {
      reason,
      reasonComment,
    },
    apiSource: "node-carbon",
  });
};
export const useVoidSignedContractMutation = () =>
  useMutation(voidSignedContract, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateContractQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
