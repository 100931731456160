import Skeleton from "react-skeleton-loader";

import { colors } from "@ag/design-system/tokens";
import { box } from "@ag/design-system/utils";

export const UserActionsSkeleton = () => (
  <div className={box({ gap: 4 })}>
    <Skeleton color={colors.grey[200]} height="24px" heightRandomness={0} />
    <Skeleton color={colors.grey[200]} height="24px" heightRandomness={0} />
    <Skeleton color={colors.grey[200]} height="24px" heightRandomness={0} />
  </div>
);
