import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export const UserCreationConsentModal = ({
  isOpen,
  onClose,
  onSubmit,
}: Props) => {
  return (
    <Modal.Root size="medium" isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header>
        <Modal.Title>
          {I18n.t("js.admin.create_user.gdpr_consent.modal_title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <p>{I18n.t("js.admin.create_user.gdpr_consent.modal_content")}</p>
      </Modal.Content>

      <Modal.Footer
        render={onClose => (
          <>
            <Button variant="secondary" onClick={onClose}>
              {I18n.t("js.admin.create_user.gdpr_consent.back_button")}
            </Button>

            <Button variant="primary" onClick={onSubmit}>
              {I18n.t(
                "js.admin.create_user.gdpr_consent.confirm_consent_button",
              )}
            </Button>
          </>
        )}
      />
    </Modal.Root>
  );
};
