import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import { DateCell, DateCellValue } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { useTable } from "~components/table";

import { UserActivity } from "../entities/user-activity";

type TableData = {
  id: string;
  key: string;
  data: Record<string, unknown> | null;
  createdAt: DateCellValue;
};

export const useUserActivitiesTable = (
  activities: UserActivity[] | undefined,
) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(() => getData(activities), [activities]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => String(original.id),
    getCoreRowModel: getCoreRowModel(),
  });
};

function getData(activities: UserActivity[] | undefined): TableData[] {
  if (!activities) return [];

  return activities.map(activity => ({
    ...activity,
    createdAt: {
      value: activity.createdAt,
      format: "dd-MM-yyyy HH:mm",
    },
  }));
}

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: I18n.t("js.users.id"),
    }),
    columnHelper.accessor("key", {
      header: I18n.t("js.admin.key"),
    }),
    columnHelper.accessor("data", {
      header: I18n.t("js.user.activity_params"),
      cell: ActivityDataCell,
    }),
    columnHelper.accessor("createdAt", {
      header: I18n.t("js.users.created_at"),
      cell: DateCell,
    }),
  ];
}

type ActivityDataCellProps = {
  getValue: () => Record<string, unknown> | null;
};

function ActivityDataCell({ getValue }: ActivityDataCellProps) {
  const value = getValue();

  if (!value) return null;

  return (
    <pre style={{ fontSize: 10, margin: 0 }}>
      {JSON.stringify(value, null, 4)}
    </pre>
  );
}
