import {
  faArrowDownToLine as faArrowDownToLineLight,
  faChartLine as faChartLineLight,
  faCheck as faCheckLight,
  faCircleCheck as faCircleCheckLight,
  faCircle as faCircleLight,
  faFarm as faFarmLight,
  faFilePdf as faFilePdfLight,
  faFileSpreadsheet as faFileSpreadsheetLight,
  faLaptopFile as faLaptopFileLight,
  faMap as faMapLight,
  faMoneyBill as faMoneyBillLight,
  faPaperPlane as faPaperPlaneLight,
  faRulerTriangle as faRulerTriangleLight,
  faSeedling as faSeedlingLight,
  faShieldCheck as faShieldCheckLight,
  faTractor as faTractorLight,
  faTriangleExclamation as faTriangleExclamationLight,
  faWeightHanging as faWeightHangingLight,
  faWheat as faWheatLight,
} from "@fortawesome/pro-light-svg-icons";
import {
  faArrowCircleRight,
  faArrowDownToLine,
  faArrowLeft,
  faArrowRight,
  faArrowRotateBack,
  faArrowUpFromLine,
  faArrowUpRightFromSquare,
  faBadgeCheck,
  faBars,
  faBarsSort,
  faBomb,
  faBuildings,
  faCalculator,
  faCalendarCheck,
  faChartNetwork,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronsDown,
  faChevronsLeft,
  faChevronsRight,
  faChevronsUp,
  faCircle,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
  faClose,
  faCloud,
  faCog,
  faComment,
  faCompress,
  faCompressArrows,
  faCompressWide,
  faCopy,
  faCrosshairsSimple,
  faDash,
  faDatabase,
  faDownload,
  faEllipsis,
  faEmptySet,
  faEnvelopeOpenText,
  faExpand,
  faExpandArrows,
  faExpandWide,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFaceMonocle,
  faFarm,
  faFile,
  faFileArrowDown,
  faFileArrowUp,
  faFileContract,
  faFileExport,
  faFilePdf,
  faFileSignature,
  faFillDrip,
  faFilter,
  faFlag,
  faFlowerDaffodil,
  faGlobe,
  faHandsClapping,
  faHeadset,
  faHorizontalRule,
  faHourglass,
  faInfoCircle,
  faLayerGroup,
  faLeaf,
  faLightbulbExclamation,
  faListUl,
  faLoader,
  faLocationDot,
  faLock,
  faMap,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faMoneyBill,
  faMoneyBill1,
  faPaperPlane,
  faPenToSquare,
  faPennant,
  faPlus,
  faPowerOff,
  faQuestion,
  faRectangleHistory,
  faRectangleList,
  faRotateLeft,
  faRulerTriangle,
  faScissors,
  faSearch,
  faSeedling,
  faShieldCheck,
  faSliders,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faSprinkler,
  faTractor,
  faTrash,
  faTrashCan,
  faTriangleExclamation,
  faUnlock,
  faUpload,
  faUser,
  faUserChart,
  faVectorSquare,
  faWarning,
  faWeightHanging,
  faWheat,
  faWifiSlash,
  faWindowMaximize,
  faXmark,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCircleCheck as faCircleCheckSolid,
  faCircleExclamation as faCircleExclamationSolid,
  faCircle as faCircleSolid,
  faEllipsisH,
  faFilter as faFilterSolid,
  faFolderArrowUp,
  faInfoCircle as faInfoCircleSolid,
  faListCheck,
  faLocationCrosshairs as faLocationCrosshairsSolid,
  faMinus as faMinusSolid,
  faPenLine,
  faPlus as faPlusSolid,
  faShieldCheck as faShieldCheckSolid,
  faShieldXmark as faShieldXmarkSolid,
  faSortDown as faSortDownSolid,
  faSort as faSortSolid,
  faSortUp as faSortUpSolid,
  faUser as faUserSolid,
  faWeightHanging as faWeightHangingSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { faFileSignature as faFileSignatureThin } from "@fortawesome/pro-thin-svg-icons";

export const ICONS_REGULAR = {
  arrowUpFromLine: faArrowUpFromLine,
  arrowDownToLine: faArrowDownToLine,
  "arrow-circle-right": faArrowCircleRight,
  "arrow-left": faArrowLeft,
  "arrow-right": faArrowRight,
  "arrows-expand": faExpandArrows,
  "arrows-compress": faCompressArrows,
  "arrow-rotate-back": faArrowRotateBack,
  "badge-check": faBadgeCheck,
  "arrow-up-right-from-square": faArrowUpRightFromSquare,
  bars: faBars,
  "bars-sort": faBarsSort,
  bomb: faBomb,
  buildings: faBuildings,
  calculator: faCalculator,
  "calendar-check": faCalendarCheck,
  "chart-network": faChartNetwork,
  check: faCheck,
  circle: faCircle,
  "chevron-down": faChevronDown,
  "chevron-left": faChevronLeft,
  "chevron-right": faChevronRight,
  "chevron-up": faChevronUp,
  "circle-check": faCircleCheck,
  "circle-info": faCircleInfo,
  "circle-xmark": faCircleXmark,
  "chevrons-down": faChevronsDown,
  "chevrons-left": faChevronsLeft,
  "chevrons-right": faChevronsRight,
  "chevrons-up": faChevronsUp,
  "circle-exclamation": faCircleExclamation,
  copy: faCopy,
  "info-circle": faInfoCircle,
  cloud: faCloud,
  close: faClose,
  comment: faComment,
  compress: faCompress,
  "compress-arrows": faCompressArrows,
  "compress-wide": faCompressWide,
  cog: faCog,
  crosshairs: faCrosshairsSimple,
  dash: faDash,
  database: faDatabase,
  download: faDownload,
  ellipsis: faEllipsis,
  "empty-set": faEmptySet,
  "envelope-open-text": faEnvelopeOpenText,
  expand: faExpand,
  "expand-arrows": faExpandArrows,
  "expand-wide": faExpandWide,
  "external-link": faExternalLink,
  eye: faEye,
  "eye-slash": faEyeSlash,
  "face-monocle": faFaceMonocle,
  globe: faGlobe,
  hourglass: faHourglass,
  plus: faPlus,
  farm: faFarm,
  file: faFile,
  "file-contract": faFileContract,
  "file-export": faFileExport,
  "file-pdf": faFilePdf,
  fileArrowDown: faFileArrowDown,
  "file-arrow-up": faFileArrowUp,
  "fill-drip": faFillDrip,
  "file-signature": faFileSignature,
  filter: faFilter,
  "flower-daffodil": faFlowerDaffodil,
  "hands-clapping": faHandsClapping,
  headset: faHeadset,
  "horizontal-rule": faHorizontalRule,
  "layer-group": faLayerGroup,
  leaf: faLeaf,
  "list-ul": faListUl,
  loader: faLoader,
  lock: faLock,
  map: faMap,
  "map-marker-alt": faMapMarkerAlt,
  minus: faMinus,
  "paper-plane": faPaperPlane,
  pennant: faPennant,
  "money-bill": faMoneyBill,
  "money-bill1": faMoneyBill1,
  "power-off": faPowerOff,
  question: faQuestion,
  "rectangle-list": faRectangleList,
  "rectangle-history": faRectangleHistory,
  "ruler-triangle": faRulerTriangle,
  search: faSearch,
  "shield-check": faShieldCheck,
  spinner: faSpinner,
  sprinkler: faSprinkler,
  "triangle-exclamation": faTriangleExclamation,
  upload: faUpload,
  "user-chart": faUserChart,
  warning: faWarning,
  "window-maximize": faWindowMaximize,
  "pen-to-square": faPenToSquare,
  scissors: faScissors,
  seedling: faSeedling,
  sliders: faSliders,
  sort: faSort,
  "sort-down": faSortDown,
  "sort-up": faSortUp,
  flag: faFlag,
  "lightbulb-exclamation": faLightbulbExclamation,
  "rotate-left": faRotateLeft,
  tractor: faTractor,
  trash: faTrash,
  "trash-can": faTrashCan,
  unlock: faUnlock,
  user: faUser,
  "x-mark": faXmark,
  "weight-hanging": faWeightHanging,
  "vector-square": faVectorSquare,
  wheat: faWheat,
  "wifi-slash": faWifiSlash,
  "map-market-alt": faMapMarkedAlt,
} as const;

export const ICONS_SOLID = {
  circle: faCircleSolid,
  "circle-exclamation": faCircleExclamationSolid,
  "circle-check": faCircleCheckSolid,
  "ellipsis-h": faEllipsisH,
  "folder-arrow-up": faFolderArrowUp,
  "list-check": faListCheck,
  "info-circle": faInfoCircleSolid,
  "location-dot": faLocationDot,
  "location-crosshairs": faLocationCrosshairsSolid,
  minus: faMinusSolid,
  "pen-line": faPenLine,
  plus: faPlusSolid,
  sort: faSortSolid,
  "shield-check": faShieldCheckSolid,
  "sort-down": faSortDownSolid,
  "sort-up": faSortUpSolid,
  filter: faFilterSolid,
  user: faUserSolid,
  "shield-x-mark": faShieldXmarkSolid,
  "weight-hanging": faWeightHangingSolid,
};

export const ICONS_LIGHT = {
  "chart-line": faChartLineLight,
  check: faCheckLight,
  circle: faCircleLight,
  "circle-check": faCircleCheckLight,
  "arrow-down-to-line": faArrowDownToLineLight,
  "file-pdf": faFilePdfLight,
  farm: faFarmLight,
  "laptop-file": faLaptopFileLight,
  map: faMapLight,
  "money-bill": faMoneyBillLight,
  "paper-plane": faPaperPlaneLight,
  wheat: faWheatLight,
  "ruler-triangle": faRulerTriangleLight,
  tractor: faTractorLight,
  "triangle-exclamation": faTriangleExclamationLight,
  "file-spreadsheet": faFileSpreadsheetLight,
  seedling: faSeedlingLight,
  "shield-check": faShieldCheckLight,
  "weight-hanging": faWeightHangingLight,
};

export const ICONS_THIN = {
  "file-signature": faFileSignatureThin,
};
