import React, { ReactNode } from "react";

import { cn } from "@ag/design-system/utils";

type Props = {
  title: string;
  children: ReactNode;
  className?: string;
};

export const UpdateRequestSummaryCard = ({
  title,
  className,
  children,
}: Props) => {
  return (
    <div
      className={cn(
        "mx-0.5 my-1 grid grid-cols-2 items-center rounded-md border border-sky-200 bg-white-100 p-4",
        className,
      )}
      data-testid="summary-card"
    >
      <h3 className="text-h3 font-bold">{title}</h3>
      <div className="flex flex-col gap-2 text-p1">
        {React.Children.map(children, (child, _) => (
          <div className="flex justify-between gap-6">{child}</div>
        ))}
      </div>
    </div>
  );
};
