import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { queryClient } from "~config";

import { generateValidationRequestQueryKey } from "./get-validation-request";
import { generateValidationRequestsQueryKey } from "./get-validation-requests";

type ResolveValidationRequestFieldsArgs = {
  validationRequestId: string;
} & {
  rejectIds?: string[];
  approveIds?: string[];
};

const resolveValidationRequestFields = async ({
  validationRequestId,
  rejectIds,
  approveIds,
}: ResolveValidationRequestFieldsArgs) =>
  axios.post(
    `/api/v1/carbon-validation-requests/${validationRequestId}/resolve-fields`,
    {
      rejectIds,
      approveIds,
    },
    {
      apiSource: "node-carbon",
    },
  );

export const useResolveValidationRequestFieldsMutation = () =>
  useMutation(resolveValidationRequestFields, {
    onSuccess: (_data, variables) => {
      queryClient.invalidateQueries(
        generateValidationRequestQueryKey(variables.validationRequestId),
      );
      queryClient.invalidateQueries(generateValidationRequestsQueryKey());
    },
  });
