import React from "react";
import { Controller, useForm } from "react-hook-form";

import { Button, Textarea } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { AdminCommentAttribute } from "./types";

export type CommentData = {
  comment: string;
};

type Props = {
  isSubmitting: boolean;
  onSubmit: (data: CommentData) => void;
  onCancel: () => void;
};

export const AdminCommentForm = ({
  isSubmitting,
  onCancel,
  onSubmit,
}: Props) => {
  const { handleSubmit, control } = useForm<CommentData>({
    defaultValues: {
      comment: "",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <Controller
        name={AdminCommentAttribute.Comment}
        control={control}
        render={({ field }) => (
          <Textarea
            {...field}
            placeholder={I18n.t("js.carbon.field.comment")}
          />
        )}
      />

      <Modal.Footer
        render={() => (
          <>
            <Button variant="text" onClick={onCancel}>
              {I18n.t("js.shared.cancel")}
            </Button>

            <Button isLoading={isSubmitting} type="submit">
              {I18n.t("js.shared.save")}
            </Button>
          </>
        )}
      />
    </form>
  );
};
