import { useState } from "react";
import { useLayer } from "react-laag";

import { IconButton } from "@ag/design-system/atoms";
import { typography } from "@ag/design-system/tokens";
import { box } from "@ag/design-system/utils";

import * as styles from "./field-map-legend.css";

type Props = {
  showMRVBoundaries?: boolean;
};

const FieldMapLegend = ({ showMRVBoundaries }: Props) => {
  const [showLegend, setShowLegend] = useState(true);

  const { layerProps, triggerProps, renderLayer } = useLayer({
    container: () => document.body,
    ResizeObserver,
    placement: "bottom-start",
    triggerOffset: 8,
    auto: true,
    snap: true,
    isOpen: showLegend,
    onOutsideClick: (): void => setShowLegend(false),
  });

  return (
    <div className={styles.legend}>
      <IconButton
        {...triggerProps}
        variant="primary"
        size="small"
        icon="info-circle"
        theme="dark"
        onClick={() => setShowLegend(prev => !prev)}
      />

      {showLegend &&
        renderLayer(
          <ul className={styles.legendList} {...layerProps}>
            {showMRVBoundaries && (
              <li className={box({ gap: 4 })}>
                <div className={styles.colorBlock({ color: "orange" })} />
                <p className={typography.p2}>MRV boundary</p>
              </li>
            )}

            <li className={box({ gap: 4 })}>
              <div className={styles.colorBlock({ color: "blue" })} />
              <p className={typography.p2}>Farmer boundary</p>
            </li>
          </ul>,
        )}
    </div>
  );
};

export default FieldMapLegend;
