import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CropDetails, CropDetailsSchema } from "../entities/crop-details";

export const generateCropByHarvestYearQueryKey = (
  id: string,
  harvestYear: string,
) => ["crop-details-harvest-year", id, harvestYear].filter(Boolean);

export const getCropByHarvestYear = async (id: string, harvestYear: string) => {
  const response = await axios.get<CropDetails>(
    `/v1/crops/${id}/harvest_year/${harvestYear}`,
    {
      schema: CropDetailsSchema,
      apiSource: "crops-fertilisers",
    },
  );

  return response.data;
};

export const useCropByHarvestYearQuery = (
  id: string,
  harvestYear: string,
  options?: { enabled?: boolean },
) =>
  useQuery(
    generateCropByHarvestYearQueryKey(id, harvestYear),
    () => getCropByHarvestYear(id, harvestYear),
    options,
  );
