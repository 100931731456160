import type { ThemeConfig } from "tailwindcss/types/config";

const keyframes: ThemeConfig["keyframes"] = {
  slideInRight: {
    from: {
      transform: "translateX(-100%)",
    },
  },
  slideOutLeft: {
    to: {
      transform: "translateX(-100%)",
    },
  },
  slideInLeft: {
    from: {
      transform: "translateX(100%)",
    },
  },
  slideOutRight: {
    to: {
      transform: "translateX(100%)",
    },
  },
  fadeIn: {
    from: {
      opacity: "0",
    },
    to: {
      opacity: "1",
    },
  },
  fadeOut: {
    from: {
      opacity: "1",
    },
    to: {
      opacity: "0",
    },
  },

  shakeX: {
    "0%, 100%": {
      transform: "translateX(0%)",
    },
    "20%": {
      transform: "translateX(-5%)",
    },
    "40%": {
      transform: "translateX(5%)",
    },
    "60%": {
      transform: "translateX(-5%)",
    },
    "80%": {
      transform: "translateX(5%)",
    },
  },
};

export default keyframes;
