import { FieldRenderProps } from "react-final-form";

import { Input, InputType } from "~inputs/Input";

type Props = FieldRenderProps<string | number | undefined>;

export const FormInput = ({
  input,
  meta,
  forceShowError,
  onChangeCustom,
  ...rest
}: Props) => (
  <Input
    value={input.value}
    type={input.type as InputType}
    error={meta.touched || forceShowError ? meta.error : null}
    onChange={value => {
      input.onChange(value);
      onChangeCustom && onChangeCustom(value);
    }}
    {...rest}
  />
);
