import { useState } from "react";
import { useDocumentEventListener, useWindowEventListener } from "rooks";

/**
 * Hook for checking if the document has focus based on the `visibilitychange`, `focus` and `blur` events
 */
export const useIsDocumentFocused = () => {
  const [isFocused, setIsFocused] = useState(document.hasFocus());

  useDocumentEventListener("visibilitychange", () => {
    setIsFocused(document.visibilityState === "visible");
  });

  useDocumentEventListener("focus", () => {
    setIsFocused(true);
  });

  useDocumentEventListener("blur", () => {
    setIsFocused(false);
  });

  useWindowEventListener("focus", () => {
    setIsFocused(true);
  });

  useWindowEventListener("blur", () => {
    setIsFocused(false);
  });

  return isFocused;
};
