import { ToastNotification } from "@ag/utils/services";

import {
  CertificateIdRangeInvalid,
  CertificateIdRangeValid,
} from "../entities/certificates-validation";

function isInvalid(
  range: CertificateIdRangeInvalid | CertificateIdRangeValid,
): range is CertificateIdRangeInvalid {
  return range.valid === false;
}

function formatRangeErrors(
  certificateValidations: (
    | CertificateIdRangeInvalid
    | CertificateIdRangeValid
  )[],
): string {
  return certificateValidations
    .filter(isInvalid)
    .map(range => {
      const [rangeStart, rangeEnd] = range.query.split("-");

      return `${rangeStart}${rangeEnd ? ` ${rangeEnd}` : ""}\n${range.reason!}`;
    })
    .join("\n\n");
}

export function useCopyRangeErrors(
  certificateValidations: (
    | CertificateIdRangeInvalid
    | CertificateIdRangeValid
  )[] = [],
) {
  const copyToClipboard = () => {
    const textToCopy = formatRangeErrors(certificateValidations);
    navigator.clipboard.writeText(textToCopy);
    ToastNotification.success("Copied errors to clipboard");
  };

  return copyToClipboard;
}
