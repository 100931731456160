/**
 * Service used to fetch and save user preferences to a different sources
 *
 * Warning:
 * In the components you should most probably use useUserPreferences hook instead of this service to get the newest data and the state
 */
class UserPreferencesService {
  // Key used to store the user preferences in the local storage
  private LOCAL_STORAGE_KEY = "ag-preferences";

  // Whole user preferences object
  private data: Record<string, Record<string, unknown>> = {};

  /**
   * Getter for the given page preferences based on the page key
   * @param key Page key
   * @returns Page preferences
   */
  getFromLocalStorage(key: string) {
    const data = window.localStorage.getItem(this.LOCAL_STORAGE_KEY);

    try {
      this.data = JSON.parse(data || "{}");

      return this.data[key];
    } catch (error) {
      console.error(
        "[UserPreferencesService] Error when parsing the user preferences:",
        error,
      );

      throw error;
    }
  }

  /**
   * Setter for the given page preferences based on the page key
   * @param key Page key
   * @param value Page preferences
   * @returns Page preferences
   */
  saveToLocalStorage<T extends Record<string, unknown>>(key: string, value: T) {
    if (!this.data) {
      this.getFromLocalStorage(key);
    }

    const dataToSave = {
      ...this.data,
      [key]: value,
    };

    try {
      window.localStorage.setItem(
        this.LOCAL_STORAGE_KEY,
        JSON.stringify(dataToSave),
      );

      this.data = dataToSave;
    } catch (error) {
      console.error(
        "[UserPreferencesService] Error when saving the user preferences:",
        error,
      );

      throw error;
    }

    return this.data[key] as T;
  }
}

export default new UserPreferencesService();
