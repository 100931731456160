import { useCallback, useState } from "react";

import { usePagination } from "@ag/utils/hooks";
import { Meta } from "@ag/utils/types";

import Table, { TableSortingState } from "~components/table";
import { useSessionContext } from "~features/auth";
import {
  ImportErrorsModal,
  useDiscardInventoryImportMutation,
} from "~features/inventory";

import { InventoryImport } from "../entities/inventory-import";
import { useActiveInventoryImportsTable } from "../hooks/use-active-inventory-imports-table";
import EmptyState from "./empty-state";

type Props = {
  items?: InventoryImport[];
  meta?: Meta;
  isLoading: boolean;
  hasAdminAccess: boolean;
  handleImportModalOpen: () => void;
  sorting: TableSortingState;
};

const ActiveInventoryImportsTable = ({
  items,
  meta,
  isLoading,
  hasAdminAccess,
  handleImportModalOpen,
  sorting,
}: Props) => {
  const [pagination, updatePagination] = usePagination();

  const { currentAdmin } = useSessionContext();

  const isCurrentAdmin = useCallback(
    (adminId: string) => currentAdmin?.id === adminId,
    [currentAdmin],
  );

  const discardInventoryImport = useDiscardInventoryImportMutation();
  const [discardedImport, setDiscardedImport] = useState<
    { id: string; errors: string[]; canDiscardImport: boolean } | undefined
  >();

  const handleOnClickViewErrors = useCallback(
    (items: { id: string; errors: string[] }, canDiscardImport: boolean) => {
      setDiscardedImport({ ...items, canDiscardImport });
    },
    [],
  );

  const handleDiscardImport = useCallback((id: string) => {
    discardInventoryImport.mutate(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDiscardAndRestartImport = useCallback(() => {
    if (discardedImport?.id) {
      handleDiscardImport(discardedImport.id);
      handleImportModalOpen();
    }
    setDiscardedImport(undefined);
  }, [discardedImport, handleDiscardImport, handleImportModalOpen]);

  const table = useActiveInventoryImportsTable(
    items,
    sorting,
    hasAdminAccess,
    isCurrentAdmin,
    handleOnClickViewErrors,
    handleDiscardImport,
  );

  return !isLoading && items?.length === 0 ? (
    <EmptyState />
  ) : (
    <>
      <ImportErrorsModal
        isOpen={!!discardedImport}
        errors={discardedImport?.errors || []}
        onClose={() => setDiscardedImport(undefined)}
        onDiscardImport={
          discardedImport?.canDiscardImport
            ? handleDiscardAndRestartImport
            : undefined
        }
      />
      <Table
        instance={table}
        isLoading={isLoading}
        meta={meta}
        pagination={pagination}
        onPaginationChange={updatePagination}
      />
    </>
  );
};

export default ActiveInventoryImportsTable;
