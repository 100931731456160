import { useCallback, useRef } from "react";

export const useDebounceAsyncFunction = <T extends string | undefined>(
  func: (...args: any[]) => Promise<T>,
  debounceTime: number,
) => {
  const clearTimeoutRef = useRef<() => void | undefined>();

  const debouncedFunction = useCallback(
    (...args: unknown[]) =>
      new Promise(resolve => {
        if (clearTimeoutRef.current) clearTimeoutRef.current();

        const timerId = setTimeout(() => {
          resolve(func(...args));
        }, debounceTime);

        clearTimeoutRef.current = () => {
          clearTimeout(timerId);
          resolve(undefined);
        };
      }),
    [debounceTime, func],
  );

  return debouncedFunction;
};
