import { useCallback, useMemo } from "react";
import { NumberParam, UrlUpdateType, useQueryParams } from "use-query-params";

type UsePaginationOptions<
  TPageKey extends string = "page",
  TLimitKey extends string = "limit",
> = {
  pageKey?: TPageKey;
  limitKey?: TLimitKey;
  defaultLimit?: 10 | 20 | 50 | 100;
};

export const usePagination = <
  TPageKey extends string = "page",
  TLimitKey extends string = "limit",
>(
  options: UsePaginationOptions<TPageKey, TLimitKey> = {},
) => {
  /**
   * Memoize every needed property of options to prevent unnecessary re-renders when the consumer rerender
   */
  const pageKey = useMemo(() => options.pageKey ?? "page", [options.pageKey]);
  const limitKey = useMemo(
    () => options.limitKey ?? "limit",
    [options.limitKey],
  );
  const defaultLimit = useMemo(
    () => options.defaultLimit,
    [options.defaultLimit],
  );

  const config = {
    [pageKey]: NumberParam,
    [limitKey]: NumberParam,
  };

  const [query, setQuery] = useQueryParams(config);

  const setPaginationHandler = useCallback(
    (values: { page?: number; limit?: number }, options?: UrlUpdateType) => {
      setQuery(
        {
          [pageKey]: values.page ?? query[pageKey],
          [limitKey]: values.limit ?? query[limitKey],
        },
        options,
      );
    },
    [limitKey, pageKey, query, setQuery],
  );

  const resetPagination = useCallback(() => {
    setQuery({
      [pageKey]: undefined,
      [limitKey]: undefined,
    });
  }, [pageKey, limitKey, setQuery]);

  const pagination = useMemo(
    () => ({
      page: query[pageKey] ?? undefined,
      limit: query[limitKey] ?? defaultLimit ?? undefined,
    }),
    [query, pageKey, limitKey, defaultLimit],
  );

  return [pagination, setPaginationHandler, resetPagination] as const;
};
