import { z } from "zod";

import { BuyoutContractStatus } from "./buyout-agreement";

export const BuyoutAgreementsFiltersSchema = z
  .object({
    userId: z.string(),
    status: z.nativeEnum(BuyoutContractStatus).or(z.literal("")),
    year: z.string().refine(value => value === "" || /^\d{4}$/.test(value), {
      message: "Please provide a valid year",
    }),
  })
  .partial();

export type BuyoutAgreementsFilters = z.infer<
  typeof BuyoutAgreementsFiltersSchema
>;
