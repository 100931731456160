import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config";

import {
  QualityControlCategory,
  QualityControlCheckKey,
  QualityControlComment,
} from "../entities/quality-control";
import { generateFieldsQueryKey } from "./get-fields";

type QualityControlCommentArgs = {
  carbonFieldActualIds: string[];
  actionPayload: {
    category: QualityControlCategory;
    checks?: QualityControlCheckKey;
    text: string;
  };
};

type FieldCommentResult = {
  meta: {
    carbonFieldId: string;
  };
} & (
  | {
      success: QualityControlComment;
      error?: never;
    }
  | {
      success?: never;
      error: {
        message: string;
      };
    }
);

export const addFieldQualityControlComments = async ({
  carbonFieldActualIds,
  actionPayload,
}: QualityControlCommentArgs) => {
  const response = await axios.post<CommonResponse<FieldCommentResult[]>>(
    `/api/v1/carbon-fields/carbon-quality-control-comments`,
    {
      carbonFieldActualIds,
      actionPayload,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useAddFieldCommentMutation = () =>
  useMutation(addFieldQualityControlComments, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateFieldsQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
