import { Helmet } from "react-helmet";

import * as styles from "./list-layout.css";

/* -------------------------------------------------------------------------------------------------
 * ListLayout
 * -----------------------------------------------------------------------------------------------*/
function ListLayout({ children }: React.PropsWithChildrenRequired) {
  return <div className={styles.root}>{children}</div>;
}

/* -------------------------------------------------------------------------------------------------
 * ListLayoutTopBar
 * -----------------------------------------------------------------------------------------------*/
function ListLayoutTopBar({ children }: React.PropsWithChildrenRequired) {
  return <div className={styles.topBar}>{children}</div>;
}

/* -------------------------------------------------------------------------------------------------
 * ListLayoutTopBarTitle
 * -----------------------------------------------------------------------------------------------*/
type ListLayoutTopBarTitleProps =
  | {
      children: React.ReactNode;
      title?: string;
    }
  | React.PropsWithChildrenRequired<{
      title: string;
    }>;

function ListLayoutTopBarTitle({
  children,
  title,
}: ListLayoutTopBarTitleProps) {
  return (
    <>
      <h4 className={styles.title}>{children}</h4>
      <Helmet>{<title>{`${title ?? children} - Agreena`}</title>}</Helmet>
    </>
  );
}

/* -------------------------------------------------------------------------------------------------
 * ListLayoutTopBarAction
 * -----------------------------------------------------------------------------------------------*/
function ListLayoutTopBarActions({
  children,
}: React.PropsWithChildrenRequired) {
  return <span className={styles.actions}>{children}</span>;
}

/* -------------------------------------------------------------------------------------------------
 * ListLayoutContent
 * -----------------------------------------------------------------------------------------------*/
function ListLayoutContent({ children }: React.PropsWithChildrenRequired) {
  return <main className={styles.content}>{children}</main>;
}

/* -------------------------------------------------------------------------------------------------
 * ListLayoutSidebar
 * -----------------------------------------------------------------------------------------------*/
function ListLayoutSidebar({ children }: React.PropsWithChildrenRequired) {
  return <section className={styles.sidebar}>{children}</section>;
}

/* -------------------------------------------------------------------------------------------------
 * ListLayoutHeader
 * -----------------------------------------------------------------------------------------------*/
type ListLayoutHeaderProps = React.PropsWithChildrenRequired<{
  variant?: "slim";
}>;

export const ListLayoutHeader = ({
  children,
  variant,
}: ListLayoutHeaderProps) => (
  <header className={styles.header({ variant })}>{children}</header>
);

/* -------------------------------------------------------------------------------------------------
 * ListLayoutFilters
 * -----------------------------------------------------------------------------------------------*/
export const ListLayoutFilters = ({
  children,
}: React.PropsWithChildrenRequired) => (
  <form className={styles.headerFilters}>{children}</form>
);

/* -------------------------------------------------------------------------------------------------
 * ListLayoutSettings
 * -----------------------------------------------------------------------------------------------*/
export const ListLayoutSettings = ({
  children,
}: React.PropsWithChildrenRequired) => (
  <form className={styles.headerSettings}>{children}</form>
);

/* -------------------------------------------------------------------------------------------------
 * ListLayoutActions
 * -----------------------------------------------------------------------------------------------*/
export const ListLayoutActions = ({
  children,
}: React.PropsWithChildrenRequired) => (
  <section className={styles.headerActions}>{children}</section>
);

const Root = ListLayout;
const Sidebar = ListLayoutSidebar;
const TopBar = ListLayoutTopBar;
const TopBarTitle = ListLayoutTopBarTitle;
const TopBarActions = ListLayoutTopBarActions;
const Content = ListLayoutContent;
const Header = ListLayoutHeader;
const Filters = ListLayoutFilters;
const Settings = ListLayoutSettings;
const Actions = ListLayoutActions;

export {
  Root,
  TopBar,
  TopBarTitle,
  TopBarActions,
  Sidebar,
  Content,
  Header,
  Filters,
  Settings,
  Actions,
};
