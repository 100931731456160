import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { User, UserSchema } from "../entities/user";

export const generateUserQueryKey = (userId?: string) => {
  return ["user", userId];
};

const getUser = async (userId: string) => {
  const response = await axios.get<CommonResponse<User>>(
    `/api/v1/admins/user-management/${userId}`,
    {
      schema: UserSchema,
      apiSource: "node-user-mgt",
    },
  );

  return response.data.success!.data;
};

export const useUserQuery = (userId?: string) =>
  useQuery(
    generateUserQueryKey(userId),
    () => {
      if (!userId) return Promise.resolve(undefined);

      return getUser(userId);
    },
    {
      enabled: Boolean(userId),
    },
  );
