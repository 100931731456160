import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

import { AdminRoleData, AdminRoleDataSchema } from "../entities/admin";

const fetchRoles = async () => {
  const response = await axios.get<CommonResponse<AdminRoleData[]>>(
    "/api/v1/roles",
    {
      schema: z.array(AdminRoleDataSchema),
      apiSource: "node-auth",
    },
  );

  return response.data.success!.data;
};

export const generateAdminRolesQueryKey = () => ["admin-roles"];

export const useAdminRolesQuery = () =>
  useQuery(generateAdminRolesQueryKey(), fetchRoles, {
    staleTime: Infinity,
  });
