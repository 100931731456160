export function getInheritedZIndex(el: HTMLElement | null) {
  let cur = el;

  while (cur) {
    const style = getComputedStyle(cur);

    if (style.zIndex && style.zIndex !== "auto") {
      return style.zIndex;
    }

    cur = cur.parentElement;
  }
}
