import {
  OnChangeFn,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo } from "react";

import {
  BooleanCell,
  BooleanCellValue,
  LinkCell,
  LinkCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { useTable } from "~components/table";
import { MethodologyVersion } from "~queries/carbon/types";

import { Fertiliser } from "../entities/fertiliser";

type TableData = {
  id: LinkCellValue;
  name: string;
  harvestYear: number;
  methodologyVersion: MethodologyVersion;
  coolfarmName: string;
  coolfarmIdentifier: number;
  organicCarbon: number;
  nitrogenTotal: number;
  isOrganic: BooleanCellValue;

  // We need to keep a reference to the fertiliser for bulk duplicate.
  fertiliser: Fertiliser;
};

type UseFertilisersTableState = {
  sorting: SortingState;
  onSortingChange: OnChangeFn<SortingState>;
};

export const useFertilisersTable = (
  data: Fertiliser[] | undefined,
  state: UseFertilisersTableState,
) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => original.fertiliser.id.toString(),
    state: {
      sorting: state.sorting,
    },
    onSortingChange: state.onSortingChange,
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      cell: LinkCell,
      enableSorting: true,
      header: I18n.t("js.admin.id"),
    }),
    columnHelper.accessor("name", {
      header: I18n.t("js.carbon.fertilisers.attribute.name"),
    }),
    columnHelper.accessor("harvestYear", {
      header: I18n.t("js.carbon.fertilisers.table.column.harvest_year"),
    }),
    columnHelper.accessor("methodologyVersion", {
      header: I18n.t("js.carbon.fertilisers.table.column.methodology_version"),
    }),
    columnHelper.accessor("coolfarmName", {
      header: I18n.t("js.carbon.fertilisers.coolfarm_name"),
    }),
    columnHelper.accessor("coolfarmIdentifier", {
      header: I18n.t("js.admin.coolfarm_identifier"),
    }),
    columnHelper.accessor("organicCarbon", {
      header: I18n.t("js.carbon.fertilisers.organic_carbon"),
    }),
    columnHelper.accessor("nitrogenTotal", {
      header: I18n.t(
        "js.carbon.fertilisers.attribute.nitrogen_total_with_unit",
      ),
    }),
    columnHelper.accessor("isOrganic", {
      header: I18n.t("js.carbon.fertilisers.organic"),
      cell: BooleanCell,
    }),
  ];
}

function getRowData(fertilisers: Fertiliser[] | undefined): TableData[] {
  if (!fertilisers) return [];

  return fertilisers.map(fertiliser => {
    return {
      id: {
        url: `/carbon/fertilisers/${fertiliser.id}/edit`,
        title: fertiliser.id,
      },
      name: fertiliser.name,
      harvestYear: fertiliser.harvestYear,
      methodologyVersion: fertiliser.methodologyVersion,
      coolfarmName: fertiliser.coolfarmName,
      coolfarmIdentifier: fertiliser.coolfarmIdentifier,
      organicCarbon: fertiliser.organicCarbon,
      nitrogenTotal: fertiliser.nitrogenTotal,
      isOrganic: {
        value: fertiliser.organic,
        theme: "neutral",
      },

      fertiliser,
    };
  });
}
