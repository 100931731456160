import { AdminRole } from "~features/admin";
import { useSessionContext } from "~features/auth";

const useHasAdminRole = (selectedRole: AdminRole): boolean => {
  const { currentAdmin } = useSessionContext();

  const hadAdminRole = currentAdmin?.adminRoles.some(
    role => role.name === selectedRole,
  );

  return Boolean(hadAdminRole);
};

export default useHasAdminRole;
