import { GoogleMap, Marker, Polygon } from "@react-google-maps/api";
import { useRef } from "react";

import { getGeojsonGeometryGoogleMapsLatLngCoordinates } from "@ag/map/helpers";
import { GeoJSONGeometry, GeometryType } from "@ag/map/types";

import {
  FARMER_REPORTED_OR_LEGACY_DETECTED_FIELD_POLYGON_OPTIONS,
  MRV_REPORTED_FIELD_POLYGON_OPTIONS,
} from "~config/google-maps";
import { Field } from "~features/field";

import { FIELD_MAP_OPTIONS } from "./constants";

type Props = {
  field: Field;
  fieldMRVBoundaries?: GeoJSONGeometry;
  className?: string;
};

export const FieldMap = ({ field, fieldMRVBoundaries, className }: Props) => {
  const mapInstanceRef = useRef<google.maps.Map | undefined>();
  const polygonBounds = useRef<google.maps.LatLngBounds>(
    new google.maps.LatLngBounds(),
  );

  const handleMapLoaded = (map: google.maps.Map) => {
    mapInstanceRef.current = map;

    if (!field.boundaries?.coordinates) {
      map.panTo(field.position);
    }
  };

  const handlePolygonLoaded = (polygon: google.maps.Polygon) => {
    polygon.getPath().forEach(latLng => {
      polygonBounds.current.extend(latLng);
    });

    if (mapInstanceRef.current) {
      mapInstanceRef.current.fitBounds(polygonBounds.current);
    }
  };

  const {
    carbonHbFieldV2022LocationData: hummingbirdData,
    boundaries: inputBoundaries,
  } = field;

  return (
    <GoogleMap
      mapContainerClassName={className}
      zoom={14}
      options={FIELD_MAP_OPTIONS}
      onLoad={handleMapLoaded}
    >
      <Marker position={field.position} />

      {/* Farmer's input */}
      {/* Polygon */}
      {inputBoundaries?.type === "Polygon" && (
        <Polygon
          paths={
            getGeojsonGeometryGoogleMapsLatLngCoordinates(
              inputBoundaries,
            ) as google.maps.LatLng[][]
          }
          options={FARMER_REPORTED_OR_LEGACY_DETECTED_FIELD_POLYGON_OPTIONS}
          onLoad={handlePolygonLoaded}
        />
      )}

      {/* MultiPolygon */}
      {inputBoundaries?.type === "MultiPolygon" &&
        inputBoundaries.coordinates.map((coordinates, index) => (
          <Polygon
            key={index}
            paths={
              getGeojsonGeometryGoogleMapsLatLngCoordinates({
                coordinates: coordinates,
                type: GeometryType.Polygon,
              }) as google.maps.LatLng[][]
            }
            options={FARMER_REPORTED_OR_LEGACY_DETECTED_FIELD_POLYGON_OPTIONS}
            onLoad={handlePolygonLoaded}
          />
        ))}

      {/* Legacy Hummingbird data */}
      {/* Polygon */}
      {hummingbirdData?.[0]?.boundaries?.type === "Polygon" && (
        <Polygon
          paths={
            getGeojsonGeometryGoogleMapsLatLngCoordinates(
              hummingbirdData[0].boundaries,
            ) as google.maps.LatLng[][]
          }
          options={FARMER_REPORTED_OR_LEGACY_DETECTED_FIELD_POLYGON_OPTIONS}
          onLoad={handlePolygonLoaded}
        />
      )}

      {/* MultiPolygon */}
      {hummingbirdData?.[0]?.boundaries?.type === "MultiPolygon" &&
        hummingbirdData?.[0]?.boundaries.coordinates.map(
          (coordinates, index) => (
            <Polygon
              key={index}
              paths={
                getGeojsonGeometryGoogleMapsLatLngCoordinates({
                  coordinates: coordinates,
                  type: GeometryType.Polygon,
                }) as google.maps.LatLng[][]
              }
              options={FARMER_REPORTED_OR_LEGACY_DETECTED_FIELD_POLYGON_OPTIONS}
              onLoad={handlePolygonLoaded}
            />
          ),
        )}

      {/* MRV Reported data */}
      {/* Polygon */}
      {fieldMRVBoundaries?.type === "Polygon" && (
        <Polygon
          paths={
            getGeojsonGeometryGoogleMapsLatLngCoordinates(
              fieldMRVBoundaries,
            ) as google.maps.LatLng[][]
          }
          options={MRV_REPORTED_FIELD_POLYGON_OPTIONS}
          onLoad={handlePolygonLoaded}
        />
      )}

      {/* MultiPolygon */}
      {fieldMRVBoundaries?.type === "MultiPolygon" &&
        fieldMRVBoundaries.coordinates.map((coordinates, index) => (
          <Polygon
            key={index}
            paths={
              getGeojsonGeometryGoogleMapsLatLngCoordinates({
                coordinates: coordinates,
                type: GeometryType.Polygon,
              }) as google.maps.LatLng[][]
            }
            options={MRV_REPORTED_FIELD_POLYGON_OPTIONS}
            onLoad={handlePolygonLoaded}
          />
        ))}
    </GoogleMap>
  );
};
