import { format } from "date-fns";

import { Chip, ChipVariant } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import DataCard from "~components/data-card";

import { Contract, ContractStatus } from "../entities/contract";
import {
  getContractStatusLabel,
  getContractTypeLabel,
  getLockInPeriodLabel,
  getPayoutsLabel,
  getSanctionsLabel,
} from "../helpers/get-labels";
import * as styles from "./contract-overview.css";

type ContractOverviewProps = {
  contract: Contract;
};

export const ContractOverview = ({ contract }: ContractOverviewProps) => (
  <section className={styles.root}>
    <DataCard.Root>
      <DataCard.Title>
        {I18n.t("js.contract.overview.overview_title")}
      </DataCard.Title>

      <DataCard.Row highlight>
        <DataCard.Label>
          {I18n.t("js.contract.overview.id_label")}
        </DataCard.Label>
        <DataCard.Value>{contract.id}</DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.name_label")}
        </DataCard.Label>
        <DataCard.Value>{contract.name}</DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.contract_type_label")}
        </DataCard.Label>
        <DataCard.Value>
          {getContractTypeLabel(contract.contractType)}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.status_label")}
        </DataCard.Label>
        <DataCard.Value>
          <Chip variant={getContractStatusChipVariant(contract.status)}>
            {contract.status ? getContractStatusLabel(contract.status) : "-"}
          </Chip>
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.field_size_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.totalFieldsSize ? `${contract.totalFieldsSize} ha` : "-"}
        </DataCard.Value>
      </DataCard.Row>
    </DataCard.Root>

    <DataCard.Root>
      <DataCard.Title>
        {I18n.t("js.contract.overview.signer_title")}
      </DataCard.Title>

      <DataCard.Row highlight>
        <DataCard.Label>
          {I18n.t("js.contract.overview.user_id_label")}
        </DataCard.Label>
        <DataCard.Value>
          <a
            href={`/users/${contract.userId}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {contract.userId}
          </a>
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.company_vat_label")}
        </DataCard.Label>
        <DataCard.Value>{contract.companyVatNumber}</DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.signer_email_label")}
        </DataCard.Label>
        <DataCard.Value>{contract.signerEmail}</DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.signer_phone_number_label")}
        </DataCard.Label>

        <DataCard.Value>{contract.signerPhoneNumber}</DataCard.Value>
      </DataCard.Row>
    </DataCard.Root>

    <DataCard.Root>
      <DataCard.Title>
        {I18n.t("js.contract.overview.details_title")}
      </DataCard.Title>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.crediting_period_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.creditingPeriod
            ? I18n.t("js.admin.contracts.crediting_period.years", {
                years: contract.creditingPeriod,
              })
            : "-"}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.lock_in_period")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.lockInPeriod
            ? getLockInPeriodLabel(contract.lockInPeriod)
            : "-"}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.payouts_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.payouts ? getPayoutsLabel(contract.payouts) : "-"}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.sanctions_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.sanctions ? getSanctionsLabel(contract.sanctions) : "-"}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.issuance_fee_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.issuanceFee ? `${contract.issuanceFee}%` : "-"}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.buffer_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.buffer ? `${contract.buffer}%` : "-"}
        </DataCard.Value>
      </DataCard.Row>
    </DataCard.Root>

    <DataCard.Root>
      <DataCard.Title>
        {I18n.t("js.contract.overview.events_title")}
      </DataCard.Title>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.created_at_label")}
        </DataCard.Label>
        <DataCard.Value>
          {format(new Date(contract.createdAt), "yyyy-MM-dd HH:mm:ss")}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.updated_at_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.updatedAt
            ? format(new Date(contract.updatedAt), "yyyy-MM-dd HH:mm:ss")
            : "-"}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.expires_at_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.expiresAt
            ? format(new Date(contract.expiresAt), "yyyy-MM-dd HH:mm:ss")
            : "-"}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.sent_at_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.sentAt
            ? format(new Date(contract.sentAt), "yyyy-MM-dd HH:mm:ss")
            : "-"}
        </DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.signed_at_label")}
        </DataCard.Label>
        <DataCard.Value>
          {contract.signedAt
            ? format(new Date(contract.signedAt), "yyyy-MM-dd HH:mm:ss")
            : "-"}
        </DataCard.Value>
      </DataCard.Row>
    </DataCard.Root>

    <DataCard.Root className={styles.additionalDetails}>
      <DataCard.Title>
        {I18n.t("js.contract.overview.additional_details_title")}
      </DataCard.Title>
      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.group_id_label")}
        </DataCard.Label>
        <DataCard.Value>{contract.carbonContractGroupId}</DataCard.Value>
      </DataCard.Row>

      <DataCard.Row>
        <DataCard.Label>
          {I18n.t("js.contract.overview.docusign_id_label")}
        </DataCard.Label>
        <DataCard.Value>{contract.docusignEnvelopeId}</DataCard.Value>
      </DataCard.Row>
    </DataCard.Root>
  </section>
);

function getContractStatusChipVariant(status: ContractStatus | null) {
  if (!status) return undefined;

  const lookup: Record<ContractStatus, ChipVariant> = {
    expired: "warning",
    signed: "success",
    sent: "info",
    withdrawn: "danger",
    terminated: "danger",
  };

  return lookup[status];
}
