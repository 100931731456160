import { Experiment, Variant } from "@amplitude/experiment-js-client";
import { isEmpty } from "lodash";
import { createContext, useCallback, useContext, useMemo } from "react";

import { env } from "~config";

import { type IdentifyProperties, ampli } from "./generated";

if (!env.REACT_APP_AMPLITUDE_API_KEY) {
  throw new Error("REACT_APP_AMPLITUDE_API_KEY is not set");
}

ampli.load({
  client: {
    apiKey: env.REACT_APP_AMPLITUDE_API_KEY,
    configuration: {
      defaultTracking: true,
      transport: "beacon",
    },
  },
});

const experiment = Experiment.initializeWithAmplitudeAnalytics(
  env.REACT_APP_AMPLITUDE_API_KEY,
  { serverZone: "eu" },
);

const AmplitudeContext = createContext<
  | {
      updateUserProperties: (properties: IdentifyProperties) => void;
      identifyUser: (...args: Parameters<typeof ampli.identify>) => void;
    }
  | undefined
>(undefined);

const AmplitudeProvider = ({ children }: { children: React.ReactNode }) => {
  const identifyUser = useCallback(
    (...args: Parameters<typeof ampli.identify>) => {
      ampli.identify(...args);
    },
    [],
  );

  const updateUserProperties = useCallback((properties: IdentifyProperties) => {
    ampli.identify(ampli.client.getUserId(), properties);
  }, []);

  const value = useMemo(
    () => ({ updateUserProperties, identifyUser }),
    [updateUserProperties, identifyUser],
  );

  return (
    <AmplitudeContext.Provider value={value}>
      {children}
    </AmplitudeContext.Provider>
  );
};

const useAmplitudeContext = () => {
  const context = useContext(AmplitudeContext);

  if (context === undefined) {
    throw new Error(
      "useAmplitudeContext must be used within a AmplitudeProvider",
    );
  }

  return context;
};

const useIsFlagActive = (
  name: string,
  fallback?: string | Variant | undefined,
) => {
  let variant = experiment.variant(name);

  if (isEmpty(variant)) {
    variant = experiment.variant(name, fallback);
  }

  return variant.value === "on";
};

export {
  ampli,
  experiment,
  useAmplitudeContext,
  useIsFlagActive,
  AmplitudeProvider,
};
