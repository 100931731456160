import I18n from "i18n-js";
import log from "loglevel";

export const addPluralization = (instance: typeof I18n) => {
  instance.pluralization["en"] = function (count: number) {
    const key = count == 1 ? "one" : "other";

    return [key];
  };

  instance.pluralization["da"] = function (count: number) {
    const key = count == 1 ? "one" : "other";

    return [key];
  };

  instance.pluralization["pl"] = function (count: number) {
    let key = count == 1 && "one";

    if (
      [2, 3, 4].indexOf(count % 10) >= 0 &&
      [12, 13, 14].indexOf(count % 100) < 0
    ) {
      key = "few";
    }

    if (
      (count != 1 && [0, 1].indexOf(count % 10) >= 0) ||
      [5, 6, 7, 8, 9].indexOf(count % 10) >= 0 ||
      [12, 13, 14].indexOf(count % 100) >= 0
    ) {
      key = "many";
    }

    if (!key) {
      log.warn("[I18n] Plural Key does not exist", key);
      key = "other";
    }

    return [key];
  };

  instance.pluralization["ro"] = function (count: number) {
    let key = count == 1 && "one";

    if (count == 0) {
      key = "few";
    }

    if (!key) {
      key = "other";
    }

    return [key];
  };
};
