import pick from "lodash/pick";

import {
  FertiliserAttribute,
  FertiliserV2022Data,
} from "@ag/carbon/forms/inputs-v2022";

import { FieldActualsV2022Input } from "~features/field";
import { FieldActualsInputV2022 } from "~queries/carbon/field-actuals";

export const getInitialValues = (fieldActualsInput: FieldActualsV2022Input) => {
  const initialValues: FertiliserV2022Data | undefined = {
    // Values from the API
    ...pick<FieldActualsInputV2022, FertiliserAttribute>(
      fieldActualsInput,
      Object.values(FertiliserAttribute),
    ),

    // View only values
    isFertilisersUsed: null,
  };

  // Set view only attributes
  if (initialValues) {
    const { fertilisersCount } = initialValues || {};

    if (fertilisersCount === 0) {
      initialValues.isFertilisersUsed = false;
    }

    if (fertilisersCount && fertilisersCount > 0) {
      initialValues.isFertilisersUsed = true;
    }
  }

  return initialValues;
};
