export enum AdminRole {
  SystemAdmin = "system_admin",
  CSTrader = "cs_trader",
  CSCarbon = "cs_carbon",
  CSUsers = "cs_users",
  CSRegistry = "cs_registry",
  LegacyAdmin = "legacy_admin",
  DataMRV = "data_mrv",
  ProgrammeMRV = "programme_mrv",
  ProgrammeAgronomist = "programme_agronomist",
  KeyAccountManager = "key_account_manager",
  DataEditor = "data_editor",
  FinanceManager = "finance_manager",
  ClientManager = "client_manager",
  ClientViewer = "client_viewer",
}
