import { useCallback } from "react";

import {
  getIsPathPointsInsidePolygon,
  getIsVertexIntersecting,
  getPathType,
  getVertexUpdateType,
} from "@ag/map/helpers";

import { HistoryStack } from "~hooks/use-polygon-paths-history";

import { usePolygonBoundariesStore } from "./polygonStore";

type PolygonHandlersArgs = {
  history: HistoryStack;
  onError: (error: string) => void;
};

export const usePolygonHandlers = ({
  history,
  onError,
}: PolygonHandlersArgs) => {
  const {
    activeVertex,
    activeVertices,
    outerPath,
    innerPaths,
    mapInstance,
    polygonInstance,
    selectMode,
    setOuterPath,
    setInnerPaths,
    setEditAction,
    setActiveVertex,
    setActiveVertices,
    setPolygonInstance,
  } = usePolygonBoundariesStore();
  /**
   * Handling path update when the outer vertex has been updated
   */
  const handleOuterPathUpdate = useCallback(() => {
    if (!polygonInstance) return;

    const newOuterPath: google.maps.LatLng[] = polygonInstance
      .getPath()
      .getArray();

    setOuterPath(newOuterPath);

    history.push({
      outerPath,
      innerPaths,
    });
  }, [history, innerPaths, outerPath, polygonInstance, setOuterPath]);

  /**
   * Handling path update when the inner vertex has been updated
   */
  const handleInnerPathUpdate = useCallback(
    (path: number) => {
      if (!polygonInstance) return;

      // Get concrete inner path based on Path index
      const newInnerPath: google.maps.LatLng[] = polygonInstance
        .getPaths()
        .getAt(path)
        .getArray();

      // Check if inner path is inside outer polygon
      const isPathsPointsInsideOuterPolygon = getIsPathPointsInsidePolygon(
        newInnerPath,
        new google.maps.Polygon({
          paths: outerPath,
        }),
      );

      // If updated path is not inside outer path, revert changes
      if (!isPathsPointsInsideOuterPolygon) {
        const newInnerPaths = [...innerPaths];
        // -1 because first path is outer path and it's not included in inner paths but path count it
        newInnerPaths[path - 1] = innerPaths[path - 1];
        setInnerPaths(newInnerPaths);

        history.push({
          outerPath,
          innerPaths,
        });

        // On every inner polygon update the drawing mode is reset to edit, so we need to update our state
        setEditAction("edit");

        onError("Inner path must be inside outer path");

        return;
      }

      // update only inner path that was edited
      const newInnerPaths = [...innerPaths];
      // -1 because first path is outer path and it's not included in inner paths but path count it
      newInnerPaths[path - 1] = newInnerPath;

      setInnerPaths(newInnerPaths);
      history.push({
        outerPath,
        innerPaths,
      });

      return;
    },
    [
      polygonInstance,
      outerPath,
      innerPaths,
      setInnerPaths,
      history,
      setEditAction,
      onError,
    ],
  );

  /**
   * Handle mouse up event - both clicking on the polygon, on the vertex on moving it
   */
  const handleMouseUp = useCallback(
    (event: google.maps.PolyMouseEvent) => {
      const { vertex, path, edge } = event;

      const isVertexClicked = vertex !== undefined;
      const isVertexAdded = edge !== undefined;

      const vertexUpdateType = getVertexUpdateType(event);

      if (!vertexUpdateType || path === undefined || !polygonInstance) return;

      // Case 1: Add new vertex
      if (isVertexAdded) {
        const isVertexIntersecting = getIsVertexIntersecting(polygonInstance, {
          path,
          vertex: edge + 1,
        });

        if (isVertexIntersecting) {
          onError("Path is intersecting");

          // Reset the polygon to previous state
          setOuterPath(outerPath);

          return;
        }

        if (vertexUpdateType === "outer") {
          handleOuterPathUpdate();
        }

        if (vertexUpdateType === "inner") {
          handleInnerPathUpdate(path);
        }

        // Reset the current active vertex
        setActiveVertex(undefined);

        return;
      }

      // Case 2: Update existing outer vertex
      if (isVertexClicked && vertexUpdateType === "outer") {
        const previousVertexLatLng = outerPath[vertex];

        // We need to check if previous vertex position exists because it might be a new one
        const hasVertexPositionChanged =
          previousVertexLatLng && !previousVertexLatLng.equals(event.latLng);

        if (hasVertexPositionChanged) {
          // Case 2a: if the position of the vertex has changed, update the outer path
          const isVertexIntersecting = getIsVertexIntersecting(
            polygonInstance,
            {
              path,
              vertex,
            },
          );

          if (isVertexIntersecting) {
            onError("Path is intersecting");

            // Reset the polygon to previous state
            setOuterPath(outerPath);

            return;
          }

          handleOuterPathUpdate();

          // If inactive vertex were moved - reset the active one
          if (activeVertex?.path !== path || activeVertex?.vertex !== vertex) {
            setActiveVertex(undefined);
          }
        } else {
          // Case 2b: if the position of the vertex has not changed, mark vertex as active one
          setActiveVertex({ path, vertex });
        }

        return;
      }

      // Case 3: Update existing inner vertex
      if (isVertexClicked && vertexUpdateType === "inner") {
        const previousVertexLatLng = innerPaths[path - 1]?.[vertex];

        const hasVertexPositionChanged =
          previousVertexLatLng && !previousVertexLatLng.equals(event.latLng);

        if (hasVertexPositionChanged) {
          // Case 4a: if the position of the vertex has changed, update the inner path

          const isVertexIntersecting = getIsVertexIntersecting(
            polygonInstance,
            {
              path,
              vertex,
            },
          );

          if (isVertexIntersecting) {
            onError("Path is intersecting");

            // Reset the polygon to previous state
            setInnerPaths(innerPaths);

            return;
          }

          handleInnerPathUpdate(path);

          // If inactive vertex were moved - reset the active one
          if (activeVertex?.path !== path || activeVertex?.vertex !== vertex) {
            setActiveVertex(undefined);
          }
        } else {
          // Case 3b: if the position of the vertex has not changed, mark vertex as active one
          if (selectMode === "single") {
            setActiveVertex({ path, vertex });
          } else {
            setActiveVertices([...activeVertices, { path, vertex }]);
          }
        }

        return;
      }
    },
    [
      activeVertices,
      activeVertex?.path,
      activeVertex?.vertex,
      selectMode,
      handleInnerPathUpdate,
      handleOuterPathUpdate,
      innerPaths,
      onError,
      outerPath,
      polygonInstance,
      setActiveVertex,
      setActiveVertices,
      setInnerPaths,
      setOuterPath,
    ],
  );

  /**
   * Handling path update on different type of events
   */
  const handlePathUpdate = useCallback(
    (path: number) => {
      const pathPlacement = getPathType(path);

      const updates: Record<"inner" | "outer", () => void> = {
        outer: handleOuterPathUpdate,
        inner: () => handleInnerPathUpdate(path),
      };

      updates[pathPlacement]();
    },
    [handleOuterPathUpdate, handleInnerPathUpdate],
  );

  /**
   * Center the map to fit polygon
   */
  const handleLoad = useCallback(
    (polygon: google.maps.Polygon) => {
      setPolygonInstance(polygon);

      // Center map to fit polygon
      const bounds = new google.maps.LatLngBounds();
      polygon.getPath().forEach(latLng => {
        bounds.extend(latLng);
      });
      mapInstance?.fitBounds(bounds);
    },
    [setPolygonInstance, mapInstance],
  );

  /**
   * Cleanup refs
   */
  const handleUnmount = useCallback(() => {
    setPolygonInstance(undefined);
  }, [setPolygonInstance]);

  return {
    handleLoad,
    handleUnmount,
    handleMouseUp,
    handlePathUpdate,
  };
};
