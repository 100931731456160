import { ChipVariant } from "@ag/design-system/atoms";

import {
  BuyoutContractStatus,
  BuyoutPaymentStatus,
  DocusignStatus,
} from "../entities/buyout-agreement";

export function getDocusignStatusChipVariant(status: DocusignStatus) {
  const lookup: Record<DocusignStatus, ChipVariant> = {
    [DocusignStatus.Sent]: "info",
    [DocusignStatus.Delivered]: "info",
    [DocusignStatus.Completed]: "success",
    [DocusignStatus.Declined]: "warning",
    [DocusignStatus.Voided]: "danger",
  };

  return lookup[status];
}

export function getDocusignStatusText(docusignStatus: DocusignStatus) {
  const lookup: Record<DocusignStatus, string> = {
    [DocusignStatus.Sent]: "Sent",
    [DocusignStatus.Completed]: "Completed",
    [DocusignStatus.Declined]: "Declined",
    [DocusignStatus.Delivered]: "Delivered",
    [DocusignStatus.Voided]: "Voided",
  };

  return lookup[docusignStatus] || docusignStatus;
}

export function getContractStatusChipVariant(status: BuyoutContractStatus) {
  const lookup: Record<BuyoutContractStatus, ChipVariant> = {
    [BuyoutContractStatus.Sent]: "info",
    [BuyoutContractStatus.Signed]: "success",
    [BuyoutContractStatus.Withdrawn]: "neutral",
    [BuyoutContractStatus.Terminated]: "neutral",
    [BuyoutContractStatus.Created]: "info",
  };

  return lookup[status];
}

export function getContractStatusChipText(status: BuyoutContractStatus) {
  const lookup: Record<BuyoutContractStatus, string> = {
    [BuyoutContractStatus.Sent]: "Sent",
    [BuyoutContractStatus.Signed]: "Signed",
    [BuyoutContractStatus.Withdrawn]: "Withdrawn",
    [BuyoutContractStatus.Terminated]: "Terminated",
    [BuyoutContractStatus.Created]: "Created",
  };

  return lookup[status];
}

export const getPaymentStatusChipVariant = (status: BuyoutPaymentStatus) => {
  const lookup: Record<BuyoutPaymentStatus, ChipVariant> = {
    [BuyoutPaymentStatus.Paid]: "success",
    [BuyoutPaymentStatus.Unpaid]: "danger",
  };

  return lookup[status];
};

export const getPaymentStatusChipText = (status: BuyoutPaymentStatus) => {
  const lookup: Record<BuyoutPaymentStatus, string> = {
    [BuyoutPaymentStatus.Paid]: "Paid",
    [BuyoutPaymentStatus.Unpaid]: "Unpaid",
  };

  return lookup[status];
};

export const getContractStatusChip = (
  status: BuyoutContractStatus,
): { variant: ChipVariant; label: string } => ({
  variant: getContractStatusChipVariant(status),
  label: getContractStatusChipText(status),
});

export const getPaymentStatusChip = (
  status: BuyoutPaymentStatus,
): { variant: ChipVariant; label: string } => ({
  variant: getPaymentStatusChipVariant(status),
  label: getPaymentStatusChipText(status),
});
