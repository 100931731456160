import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";
import { generateAllFieldBoundariesQueryKey } from "~features/field/api/get-all-field-boundaries";

import { FieldIneligibilityReason } from "../entities/field-ineligibility-reason";
import { BoundaryStatuses } from "../entities/field-with-all-boundaries";
import { generateNearbyFieldBoundariesQueryKey } from "./get-nearby-field-boundaries";

const updateFieldBoundaryStatus = async ({
  carbonFieldId,
  newStatus,
  comment,
  reason,
}: {
  carbonFieldId: string;
  newStatus: BoundaryStatuses;
  comment?: string;
  reason?: FieldIneligibilityReason;
}) => {
  await axios.patch(
    `/api/v1/field-boundaries/status/${carbonFieldId}`,
    {
      status: newStatus,
      comment,
      reason,
    },
    {
      apiSource: "node-carbon",
    },
  );
};

export const useUpdateFieldBoundaryStatus = () =>
  useMutation(updateFieldBoundaryStatus, {
    onError: error => {
      ToastNotification.error(error);
    },
    onSuccess: (data, params) => {
      const { carbonFieldId } = params;
      queryClient.invalidateQueries(generateNearbyFieldBoundariesQueryKey());
      queryClient.invalidateQueries(
        generateAllFieldBoundariesQueryKey(carbonFieldId),
      );

      ToastNotification.success("Field boundary status has been updated");
    },
  });
