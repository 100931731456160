import { forEach } from "lodash";
import { useCallback, useEffect } from "react";

import {
  getIsPathPointsInsidePolygon,
  getIsVertexIntersecting,
  getPathType,
  getVertexUpdateType,
} from "@ag/map/helpers";

import { HistoryStack } from "~hooks/use-multi-polygon-paths-history";

import { useMultiPolygonBoundariesStore } from "./multiPolygonStore";

type PolygonHandlersArgs = {
  history: HistoryStack;
  onError: (error: string) => void;
};

export const useMultiPolygonHandlers = ({
  history,
  onError,
}: PolygonHandlersArgs) => {
  const {
    activeVertex,
    editAction,
    outerPath,
    innerPaths,
    mapInstance,
    polygonInstance,
    drawingManagerInstance,
    setOuterPath,
    setInnerPaths,
    setEditAction,
    setActiveVertex,
    addPolygonInstance,
    resetPolygonInstance,
  } = useMultiPolygonBoundariesStore();

  /**
   * Center the map to fit multiPolygon when all polygons are loaded
   */
  useEffect(() => {
    const polygonsCount = outerPath.length;
    const polygonsInstancesCount = polygonInstance.length;

    if (
      polygonsInstancesCount > 0 &&
      polygonsInstancesCount === polygonsCount
    ) {
      const bounds = new google.maps.LatLngBounds();

      forEach(polygonInstance, polygon => {
        // Center map to fit polygon
        polygon.getPath().forEach(latLng => {
          bounds.extend(latLng);
        });
      });

      mapInstance?.fitBounds(bounds);
    }
  }, [mapInstance, outerPath.length, polygonInstance]);

  /**
   * Handling path update when the outer vertex has been updated
   */
  const handleOuterPathUpdate = useCallback(
    (polygonId: number) => {
      if (!polygonInstance) return;

      const newOuterPath = polygonInstance[polygonId].getPath().getArray();

      const newAllOuterPaths = [...outerPath];
      newAllOuterPaths[polygonId] = newOuterPath;

      setOuterPath(newAllOuterPaths);

      history.push({
        polygonId,
        outerPath,
        innerPaths,
      });
    },
    [history, innerPaths, outerPath, polygonInstance, setOuterPath],
  );

  /**
   * Handling path update when the inner vertex has been updated
   */
  const handleInnerPathUpdate = useCallback(
    (polygonId: number, path: number) => {
      if (!polygonInstance) return;

      // Get concrete inner path based on Path index
      const newInnerPath: google.maps.LatLng[] = polygonInstance[polygonId]
        .getPaths()
        .getAt(path)
        .getArray();

      // Check if inner path is inside outer polygon
      const isPathsPointsInsideOuterPolygon = getIsPathPointsInsidePolygon(
        newInnerPath,
        new google.maps.Polygon({
          paths: outerPath,
        }),
      );

      // If updated path is not inside outer path, revert changes
      if (!isPathsPointsInsideOuterPolygon) {
        const newInnerPaths = [...innerPaths];
        // -1 because first path is outer path and it's not included in inner paths but path count it
        newInnerPaths[polygonId][path - 1] = innerPaths[polygonId][path - 1];
        setInnerPaths(newInnerPaths);

        history.push({
          polygonId,
          outerPath,
          innerPaths,
        });

        // On every inner polygon update the drawing mode is reset to edit, so we need to update our state
        setEditAction("edit");

        onError("Inner path must be inside outer path");

        return;
      }

      // update only inner path that was edited
      const newInnerPaths = [...innerPaths];
      // -1 because first path is outer path and it's not included in inner paths but path count it
      const polygonInnerPaths = newInnerPaths[polygonId];
      polygonInnerPaths[path - 1] = newInnerPath;

      setInnerPaths(newInnerPaths);

      history.push({
        polygonId,
        outerPath,
        innerPaths,
      });

      return;
    },
    [
      polygonInstance,
      outerPath,
      innerPaths,
      history,
      setInnerPaths,
      setEditAction,
      onError,
    ],
  );

  /**
   * Handle mouse up event - both clicking on the polygon, on the vertex on moving it
   */
  const handleMouseUp = useCallback(
    (polygonId: number, event: google.maps.PolyMouseEvent) => {
      const { vertex, path, edge } = event;

      const isVertexClicked = vertex !== undefined;
      const isVertexAdded = edge !== undefined;

      const shouldEnableCutMode =
        !isVertexClicked &&
        !isVertexAdded &&
        drawingManagerInstance[polygonId]?.getDrawingMode() !==
          google.maps.drawing.OverlayType.POLYGON;

      // Case 1: Click on the polygon - user has enabled cut mode
      if (editAction === "cut" && shouldEnableCutMode) {
        drawingManagerInstance[polygonId]?.setDrawingMode(
          google.maps.drawing.OverlayType.POLYGON,
        );

        // Reset the current active vertex
        setActiveVertex(undefined);

        return;
      }

      const vertexUpdateType = getVertexUpdateType(event);

      if (
        !vertexUpdateType ||
        path === undefined ||
        !polygonInstance[polygonId]
      )
        return;

      // Case 2: Add new vertex
      if (isVertexAdded) {
        const isVertexIntersecting = getIsVertexIntersecting(
          polygonInstance[polygonId],
          {
            path,
            vertex: edge + 1,
          },
        );

        if (isVertexIntersecting) {
          onError("Path is intersecting");

          // Reset the polygon to previous state
          setOuterPath(outerPath);

          return;
        }

        if (vertexUpdateType === "outer") {
          handleOuterPathUpdate(polygonId);
        }

        if (vertexUpdateType === "inner") {
          handleInnerPathUpdate(polygonId, path);
        }

        // Reset the current active vertex
        setActiveVertex(undefined);

        return;
      }

      // Case 3: Update existing outer vertex
      if (isVertexClicked && vertexUpdateType === "outer") {
        const previousVertexLatLng = outerPath[polygonId][vertex];

        // We need to check if previous vertex position exists because it might be a new one
        const hasVertexPositionChanged =
          previousVertexLatLng && !previousVertexLatLng.equals(event.latLng);

        if (hasVertexPositionChanged) {
          // Case 3a: if the position of the vertex has changed, update the outer path
          const isVertexIntersecting = getIsVertexIntersecting(
            polygonInstance[polygonId],
            {
              path,
              vertex,
            },
          );

          if (isVertexIntersecting) {
            onError("Path is intersecting");

            // Reset the polygon to previous state
            setOuterPath(outerPath);

            return;
          }

          handleOuterPathUpdate(polygonId);

          // If inactive vertex were moved - reset the active one
          if (activeVertex?.path !== path || activeVertex?.vertex !== vertex) {
            setActiveVertex(undefined);
          }
        } else {
          // Case 3b: if the position of the vertex has not changed, mark vertex as active one
          setActiveVertex({ polygonId, path, vertex });
        }

        return;
      }

      // Case 4: Update existing inner vertex
      if (isVertexClicked && vertexUpdateType === "inner") {
        const previousVertexLatLng = innerPaths[polygonId][path - 1]?.[vertex];

        const hasVertexPositionChanged =
          previousVertexLatLng && !previousVertexLatLng.equals(event.latLng);

        if (hasVertexPositionChanged) {
          // Case 4a: if the position of the vertex has changed, update the inner path

          const isVertexIntersecting = getIsVertexIntersecting(
            polygonInstance[polygonId],
            {
              path,
              vertex,
            },
          );

          if (isVertexIntersecting) {
            onError("Path is intersecting");

            // Reset the polygon to previous state
            setInnerPaths(innerPaths);

            return;
          }

          handleInnerPathUpdate(polygonId, path);

          // If inactive vertex were moved - reset the active one
          if (activeVertex?.path !== path || activeVertex?.vertex !== vertex) {
            setActiveVertex(undefined);
          }
        } else {
          // Case 4b: if the position of the vertex has not changed, mark vertex as active one
          setActiveVertex({ polygonId, path, vertex });
        }

        return;
      }
    },
    [
      activeVertex?.path,
      activeVertex?.vertex,
      drawingManagerInstance,
      editAction,
      handleInnerPathUpdate,
      handleOuterPathUpdate,
      innerPaths,
      onError,
      outerPath,
      polygonInstance,
      setActiveVertex,
      setInnerPaths,
      setOuterPath,
    ],
  );

  /**
   * Handling path update on different type of events
   */
  const handlePathUpdate = useCallback(
    (polygonId: number, path: number) => {
      const pathPlacement = getPathType(path);

      const updates: Record<"inner" | "outer", () => void> = {
        outer: () => handleOuterPathUpdate(polygonId),
        inner: () => handleInnerPathUpdate(polygonId, path),
      };

      updates[pathPlacement]();
    },
    [handleOuterPathUpdate, handleInnerPathUpdate],
  );

  /**
   * Center the map to fit polygon
   */
  const handleLoad = useCallback(
    (polygonId: number, polygon: google.maps.Polygon) => {
      addPolygonInstance(polygonId, polygon);
    },
    [addPolygonInstance],
  );

  /**
   * Cleanup refs
   */
  const handleUnmount = useCallback(() => {
    resetPolygonInstance();
  }, [resetPolygonInstance]);

  return {
    handleLoad,
    handleUnmount,
    handleMouseUp,
    handlePathUpdate,
  };
};
