import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { Client } from "../entities/client";
import { generateClientsQueryKey } from "./get-clients";

type Payload = {
  id: string;
  name: string;
};

const updateClient = async (payload: Payload): Promise<Client> => {
  const response = await axios.patch(
    `/api/v1/clients/${payload.id}`,
    { name: payload.name },
    {
      apiSource: "node-markets",
    },
  );
  return response.data.success!.data;
};

export const useUpdateClientMutation = () =>
  useMutation(updateClient, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateClientsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
