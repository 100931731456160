
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/tokens/spacing.css.ts", "@ag/design-system");
      import { createGlobalTheme } from "@vanilla-extract/css";
export default createGlobalTheme(":root", {
  xs: "4px",
  sm: "8px",
  md: "16px",
  lg: "24px",
  xl: "32px",
  xxl: "40px"
});
      endFileScope();
      
    