import { startCase } from "lodash";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import I18n from "@ag/i18n";

import { queryClient } from "~config";

import { runFieldsQualityControl } from "..";
import { changeFieldsStatus } from "../api/change-fields-status";
import { generateFieldsQueryKey } from "../api/get-fields";
import { QualityControlStatus } from "../entities/quality-control";

type BulkError = {
  id: number;
  message: string;
};

type Result = {
  id: string;
  message: string;
  type: "success" | "error";
  errors?: BulkError[];
};

interface FieldsBulkActionState {
  results: Result[];
  selection: Record<string, boolean>;

  updateSelection: (selection: Record<string, boolean>) => void;
  clearSelection: () => void;

  startAction: () => void;
  finishAction: () => void;

  addResult: (result: Omit<Result, "id">) => void;
  removeResult: (id: string) => void;
}

export const useFieldsBulkActionsStore = create<FieldsBulkActionState>()(
  immer(set => ({
    results: [] as Result[],
    selection: {},

    startAction: () =>
      set({
        results: [],
      }),
    finishAction: () => {
      set({ selection: {} });
    },

    updateSelection: selection => set({ selection }),
    clearSelection: () => set({ selection: {} }),

    addResult: result =>
      set(state => {
        state.results.push({ ...result, id: uuidv4() });
      }),
    removeResult: resultId =>
      set(state => {
        state.results = state.results.filter(result => result.id !== resultId);
      }),
  })),
);

export const useFieldsBulkActions = () => {
  const store = useFieldsBulkActionsStore();

  const handleRun = useCallback(
    async (year: number) => {
      store.startAction();

      const results = await runFieldsQualityControl({
        year: year,
        fieldIds: getSelectedIds(store.selection),
      });

      const errorResults = results.filter(item => item.error);
      const successResults = results.filter(item => item.success);

      const uniqueErrorFieldCount = new Set(
        errorResults.map(result => result.meta.carbonFieldId),
      ).size;
      const uniqueSuccessFieldCount = new Set(
        successResults.map(result => result.meta.carbonFieldId),
      ).size;

      if (errorResults.length) {
        store.addResult({
          type: "error",
          message: `Quality control for ${errorResults.length} categories on ${uniqueErrorFieldCount} fields did not run`,
          errors: errorResults.map(error => ({
            id: error.meta.carbonFieldId,
            message: error.error?.message ?? "",
          })),
        });
      }

      if (successResults.length) {
        store.addResult({
          type: "success",
          message: `Quality control has been run for ${successResults.length} categories on ${uniqueSuccessFieldCount} fields`,
        });
      }

      queryClient.invalidateQueries(generateFieldsQueryKey());
      store.finishAction();
    },
    [store],
  );

  const handleChangeStatus = useCallback(
    async (status: QualityControlStatus) => {
      store.startAction();

      const results = await changeFieldsStatus({
        fieldIds: getSelectedIds(store.selection),
        payload: {
          newStatus: status,
        },
      });

      const errorResults = results.filter(item => item.error);
      const successResults = results.filter(item => item.success);

      if (errorResults.length) {
        store.addResult({
          type: "error",
          message: I18n.t(
            "js.admin.quality_control.list.change_status_failed",
            {
              count: errorResults.length,
            },
          ),
          errors: errorResults.map(error => ({
            id: error.meta.carbonFieldId,
            message: error.error?.message ?? "",
          })),
        });
      }

      if (successResults.length) {
        store.addResult({
          type: "success",
          message: I18n.t(
            "js.admin.quality_control.list.change_status_success",
            {
              count: successResults.length,
              status: startCase(status),
            },
          ),
        });
      }

      queryClient.invalidateQueries(generateFieldsQueryKey());
      store.finishAction();
    },
    [store],
  );

  return {
    ...store,

    run: handleRun,
    changeStatus: handleChangeStatus,
  };
};

function getSelectedIds(selection: Record<string, boolean>) {
  return Object.entries(selection)
    .filter(([, value]) => value)
    .map(([key]) => key);
}
