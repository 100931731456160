import { z } from "zod";

export enum AssuranceCategory {
  Duplication = "duplication",
  FieldSize = "field_size",
  Deforestation = "deforestation",
  IntersectionOverUnion = "intersection_over_union",
  SoilDisturbance = "soil_disturbance",
  CoverCrop = "cover_crop",
}

export enum AssuranceFlag {
  Blue = "blue",
  Green = "green",
  Yellow = "yellow",
  Orange = "orange",
  Red = "red",
}

export enum AssuranceStatus {
  Processing = "processing",
  NonConformance = "non_conformance",
  NonCompliance = "non_compliance",
  Approved = "approved",
}

export enum AssuranceCheckKey {
  FieldLocation = "field_location",
  FarmSize = "farm_size",
  SoilDisturbanceHB = "soil_disturbance_hb",
  CoverCropHB = "cover_crop_hb",
  IntersectionHectares = "intersection_hectares",
  IntersectionPercentage = "intersection_percentage",
  IntersectionSum = "intersection_sum",
  ConflictingFields = "conflicting_fields",
}

const AssuranceFarmSizeValueSchema = z.object({
  percentage_difference: z.string(),
  reported_field_size: z.string(),
  detected_field_size: z.string(),
});

export type AssuranceFarmSizeValue = z.infer<
  typeof AssuranceFarmSizeValueSchema
>;

const AssuranceConflictingFieldsSchema = z.array(z.number());
export type AssuranceConflictingFields = z.infer<
  typeof AssuranceConflictingFieldsSchema
>;

export const AssuranceCheckSchema = z.object({
  id: z.string(),
  key: z.nativeEnum(AssuranceCheckKey),
  keyName: z.string(),
  value: z.union([
    z.string(),
    z.number(),
    AssuranceConflictingFieldsSchema,
    AssuranceFarmSizeValueSchema,
  ]),
  valueJson: z.string().nullable(),
  unit: z.string().nullable(),
  flag: z.nativeEnum(AssuranceFlag),
  status: z.string(),
  lastActiveAt: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type AssuranceCheck = z.infer<typeof AssuranceCheckSchema>;

const AssuranceFieldSizeValueSchema = z.object({
  percentage_difference: z.string(),
  reported_field_size: z.string(),
  detected_field_size: z.string(),
});

export type AssuranceFieldSizeValue = z.infer<
  typeof AssuranceFieldSizeValueSchema
>;

export const AssuranceSchema = z.object({
  id: z.string(),
  category: z.nativeEnum(AssuranceCategory),
  year: z.number(),
  value: z
    .union([
      z.string(),
      AssuranceFieldSizeValueSchema,
      AssuranceFarmSizeValueSchema,
    ])
    .nullable(),
  valueJson: z.string().nullable(),
  unit: z.string().nullable(),
  flag: z.nativeEnum(AssuranceFlag),
  createdAt: z.string(),
  updatedAt: z.string(),
  checks: z.array(AssuranceCheckSchema),
});

export type Assurance = z.infer<typeof AssuranceSchema>;

export const AssuranceActionSchema = z.object({
  newStatus: z.nativeEnum(AssuranceStatus),
  oldStatus: z.nativeEnum(AssuranceStatus),
  description: z.string(),
  admin: z.object({
    id: z.string(),
    email: z.string(),
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type AssuranceAction = z.infer<typeof AssuranceActionSchema>;

export const AssuranceCommentSchema = z.object({
  id: z.string(),
  category: z.nativeEnum(AssuranceCategory),
  checks: z.nativeEnum(AssuranceCheckKey),
  text: z.string(),
  adminId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type AssuranceComment = z.infer<typeof AssuranceCommentSchema>;
