export const colorsObject = {
  /**
   * LEGACY - DO NOT USE
   * To be removed after rebranding is completed
   */
  green: {
    100: "#CAE4CA",
    200: "#97C79C",
    300: "#65A781",
    400: "#32836C",
    500: "#005C59",
    600: "#005451",
    700: "#004A48",
    800: "#003F3E",
    900: "#003332",
  },
  opal: {
    100: "#DEEFEB",
    200: "#CCE6E1",
    300: "#BCDDD7",
    400: "#ABD3CD",
    500: "#9AC0B8",
    600: "#89B3AA",
    700: "#779D92",
    800: "#65877B",
    900: "#537164",
  },
  tan: {
    100: "#F9F3EB",
    200: "#F3E7D7",
    300: "#ECDBC4",
    400: "#E5CFB2",
    500: "#DDC3A0",
    600: "#C2A98B",
    700: "#A79076",
    800: "#8C7762",
    900: "#715E4E",
  },
  blue: {
    100: "#BEDAED",
    200: "#A4CCE6",
    300: "#8BBEDF",
    400: "#73B0D7",
    500: "#5AA2CF",
    600: "#4689B7",
    700: "#34709D",
    800: "#255881",
    900: "#183F62",
  },
  orange: {
    100: "#FFE0C5",
    200: "#FFD2A9",
    300: "#FFC58D",
    400: "#FFB974",
    500: "#FFAE5B",
    600: "#E28F4E",
    700: "#C67141",
    800: "#A95635",
    900: "#8C3E2A",
  },
  yellow: {
    100: "#FFFAD3",
    200: "#FFF4A7",
    300: "#FFED7B",
    400: "#FFE65A",
    500: "#FFDA24",
    600: "#DBB71A",
    700: "#B79512",
    800: "#93750B",
    900: "#7A5E06",
  },
  red: {
    100: "#FFDCE0",
    200: "#FFA9AE",
    300: "#FF8D92",
    400: "#FF7477",
    500: "#FF5B5B",
    600: "#DB4851",
    700: "#C64157",
    800: "#A93553",
    900: "#8C2A4C",
  },
  white: {
    100: "#FFFFFF",
  },
  black: {
    900: "#000000",
  },
  /**
   * END OF LEGACY
   */

  satellite: {
    100: "#120C56",
    200: "#0E0341",
    300: "#09022B",
  },

  sky: {
    100: "#E3EAF0",
    200: "#C1D3F1",
    300: "#849FE5",
  },

  accent: {
    100: "#6D57FF",
    200: "#4730DB",
    300: "#3014B8",
  },

  grey: {
    100: "#F2F2F2",
    200: "#E5E5E5",
    300: "#CCCCCC",
    400: "#B2B2B2",
    500: "#747474",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0D0D0D",
    white: "#FFFFFF",
    black: "#000000",
  },

  data: {
    purple: {
      100: "#EDEBFF",
      200: "#DAD6FF",
      300: "#C5BEFF",
      400: "#ADA2FF",
      500: "#9281FF",
      600: "#8373E4",
      700: "#7164C6",
      800: "#5C52A1",
      900: "#413A72",
    },

    orange: {
      100: "#FFF5F2",
      200: "#FFEAE4",
      300: "#FFD4C6",
      400: "#FFBBA1",
      500: "#FF9D72",
      600: "#FF7900",
      700: "#E46C00",
      800: "#C65E00",
      900: "#A14D00",
    },

    green: {
      100: "#E7FFF0",
      200: "#D4FFE3",
      300: "#BAFFD3",
      400: "#9BFABD",
      500: "#75E19C",
      600: "#29C17A",
      700: "#1F9F63",
      800: "#157D4D",
      900: "#0D603C",
    },

    pink: {
      100: "#FFEAF1",
      200: "#FAD7E3",
      300: "#F5AEC7",
      400: "#F085AB",
      500: "#EB5C8F",
      600: "#DD335C",
      700: "#C91541",
      800: "#B2012D",
      900: "#840021",
    },

    blue: {
      100: "#E3FAFF",
      200: "#C0F3FF",
      300: "#8BE8FE",
      400: "#71D8F0",
      500: "#4AC6E3",
      600: "#31B3D1",
      700: "#0F99B9",
      800: "#00677F",
      900: "#004E61",
    },
  },

  messaging: {
    error: {
      100: "#FEE2E2",
      300: "#FFACAC",
      500: "#F87171",
      700: "#B91C1C",
      900: "#8E0000",
    },

    warning: {
      100: "#FFF9E0",
      300: "#FFED9D",
      500: "#FBBF24",
      700: "#D97706",
      900: "#662C09",
    },

    info: {
      100: "#D2D9F9",
      300: "#7CA5ED",
      500: "#5D85E8",
      700: "#3E64E3",
      900: "#0A32D6",
    },

    success: {
      100: "#E1F1E2",
      300: "#A6E2AE",
      500: "#58CA81",
      700: "#389D5C",
      900: "#15863D",
    },
  },
};
