import { Locale } from "@ag/i18n";

export enum CropTypeEditAttribute {
  ID = "id",
  Identifier = "identifier",
  HarvestYear = "harvestYear",
  MethodologyVersion = "methodologyVersion",
  NetGross = "netGross",
  CoolfarmIdentifier = "coolfarmIdentifier",
  CoolfarmName = "coolfarmName",
  GrossYieldMax = "grossYieldMax",
  KgNHaMin = "kgNHaMin",
  KgNHaMax = "kgNHaMax",
  DryMatterFraction = "dryMatterFraction",
  Slope = "slope",
  Intercept = "intercept",
  Categories = "categories",
  ShowResidue = "showResidue",
  NameEn = "nameEn",
}

export enum CropTypeEditOptionalAttribute {
  GrossYieldMin = "grossYieldMin",
}

export type CropTypeTranslations = {
  [K in keyof Omit<typeof Locale, "en"> &
    string as `name${Capitalize<K>}`]: `name${Capitalize<(typeof Locale)[K]>}`;
};
