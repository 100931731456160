import { forwardRef, useMemo, useState } from "react";

import { Icon } from "@ag/design-system/assets";

import { InputField, Props } from "../InputField";

export const PasswordInputField = forwardRef<
  HTMLInputElement,
  Omit<Props, "type" | "rightAddon">
>((props, ref) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const PasswordIconElement = useMemo(
    () => (
      <button
        className="pointer-events-auto"
        type="button"
        onClick={event => {
          event.preventDefault();

          setIsPasswordVisible(!isPasswordVisible);
        }}
      >
        <Icon name={isPasswordVisible ? "eye-slash" : "eye"} />
      </button>
    ),
    [isPasswordVisible, setIsPasswordVisible],
  );

  return (
    <InputField
      {...props}
      ref={ref}
      type={isPasswordVisible ? "text" : "password"}
      rightAddon={PasswordIconElement}
    />
  );
});

PasswordInputField.displayName = "PasswordInputField";
