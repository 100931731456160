import { z } from "zod";

import { OfferStatus } from "../types/offer-status";
import { KYCStatus } from "./sales-offer";

export const SalesOffersFiltersSchema = z
  .object({
    userId: z.string(),
    contacted: z.string(),
    status: z.nativeEnum(OfferStatus).or(z.literal("")),
    kycStatus: z.nativeEnum(KYCStatus).or(z.literal("")),
    harvestYear: z
      .string()
      .refine(value => value === "" || /^\d{4}$/.test(value), {
        message: "Please provide a valid year",
      }),
  })
  .partial();

export type SalesOffersFilters = z.infer<typeof SalesOffersFiltersSchema>;
