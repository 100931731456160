import pick from "lodash/pick";

import {
  HistoryOfDeforestationAttribute,
  HistoryOfDeforestationData,
} from "@ag/carbon/forms/baseline/iso";

import { FieldDefinitionDetails } from "~queries/carbon/fields";

export const usePartial = (
  fieldDefinition: FieldDefinitionDetails | undefined,
) => {
  const initialValues: HistoryOfDeforestationData | undefined = fieldDefinition
    ? pick<FieldDefinitionDetails, HistoryOfDeforestationAttribute>(
        fieldDefinition,
        Object.values(HistoryOfDeforestationAttribute),
      )
    : undefined;

  return {
    initialValues,
  };
};
