export const getInitialResources = () => ({
  field: {
    cropProtectionsProtectionType: {
      2021: [
        { label: "Weed treatment", value: "weed_treatment" },
        { label: "Fungal treatment", value: "fungal_treatment" },
        { label: "Pests", value: "pests" },
        { label: "Growth regulation", value: "growth_regulation" },
      ],
      2022: [
        { label: "Pesticide", value: "pesticide" },
        { label: "Herbicide", value: "herbicide" },
        { label: "Fungicide", value: "fungicide" },
        { label: "Insecticide", value: "insecticide" },
      ],
    },
    cropResidueManagement: [
      { label: "Mulched", value: "mulched" },
      { label: "Burned", value: "burned" },
      { label: "Removed", value: "removed" },
    ],
    energyConsumptionEnergySource: [
      { label: "Diesel (L)", value: "Diesel (L)" },
      { label: "Petrol (L)", value: "Petrol (L)" },
      { label: "Bioethanol (L)", value: "Bioethanol (L)" },
    ],
    fertiliserApplicationMode: [
      { label: "Applied / drip hose", value: "Apply in solution" },
      { label: "Broadcasted", value: "Broadcast" },
      { label: "Incorporated", value: "Incorporate" },
    ],
    soilDrainage: [
      { label: "Good", value: "good" },
      { label: "Poor", value: "poor" },
    ],
    soilMoisture: [
      { label: "Moist", value: "moist" },
      { label: "Dry", value: "dry" },
    ],
    soilPhRange: [
      { label: "pH <= 5.5", value: "ph_lteq_5_5" },
      { label: "5.5 < pH <= 7.3", value: "ph_gt_5_5_lteq_7_3" },
      { label: "7.3 < pH <= 8.5", value: "ph_gt_7_3_lteq_8_5" },
      { label: "pH > 8.5", value: "ph_gt_8_5" },
    ],
    soilTexture: [
      { label: "Clay", value: "clay" },
      { label: "Silt", value: "silt" },
      { label: "Sand", value: "sand" },
    ],
  },
});
