import { z } from "zod";

import {
  CropProtectionDetailsDataSchema,
  CropProtectionV2022DataSchema,
} from "./schemas";

export enum CropProtectionAttribute {
  CropProtections = "cropProtections",
  CropProtectionsCount = "cropProtectionsCount",
}

export enum CropProtectionDetailsAttribute {
  Type = "protectionType",
  ApplicationRate = "applicationRate",
  Category = "category",
}

export type CropProtectionV2022Data = z.infer<
  typeof CropProtectionV2022DataSchema
>;

export type CropProtectionDetailsData = z.infer<
  typeof CropProtectionDetailsDataSchema
>;

export enum CropResidueManagement {
  Mulched = "mulched",
  Burned = "burned",
  Removed = "removed",
}

export enum CropProtectionType {
  WeedTreatment = "weed_treatment",
  FungalTreatment = "fungal_treatment",
  Pests = "pests",
  GrowthRegulation = "growth_regulation",
}

export enum CropProtectionCategory {
  PostEmergence = "Post-emergence",
}
