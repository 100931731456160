import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { Farm } from "./types";

export const farmsKeys = {
  all: ["farms"] as const,

  farmAll: () => [...farmsKeys.all, "list"] as const,
  farm: (farmId: number) => [...farmsKeys.farmAll(), { farmId }] as const,
};

/**
 * CUSTOM QUERY FUNCTIONS
 */
export const fetchFarm = async (farmId: number) => {
  const response = await axios.get<CommonResponse<Farm>>(
    `/api/v1/farms/${farmId}`,
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

/**
 * QUERY HOOKS
 */
export const useFarmQuery = (farmId: number) =>
  useQuery(farmsKeys.farm(farmId), () => fetchFarm(farmId));
