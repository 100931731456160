import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";

import { ChipVariant } from "@ag/design-system/atoms";
import {
  ChipCell,
  ChipCellValue,
  LinkCell,
  LinkCellValue,
  TextCell,
} from "@ag/design-system/organisms";

import { useTable } from "~components/table";
import { getCountryWithFlag } from "~helpers/countries";

import { CertificateGroup } from "../entities/certificate-group";
import {
  CertificateGroupMethodology,
  CertificateGroupStandard,
  CertificateGroupStatus,
  CertificateGroupType,
} from "../types/certificate-group";

type TableData = {
  id: string;
  range: string | undefined;
  quantity: number | undefined;
  standard: ChipCellValue;
  methodology: CertificateGroupMethodology;
  vintageYear: number;
  type: ChipCellValue;
  country: string;
  status: ChipCellValue;
  userId: LinkCellValue;
};

export const useUpdateRequestGroupsTable = (
  data: CertificateGroup[] | undefined,
  hasUserPermissions: boolean,
) => {
  const columns = useMemo(
    () => getColumns(hasUserPermissions),
    [hasUserPermissions],
  );
  const tableData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => String(original.id),
  });
};

function getColumns(hasUserPermissions: boolean) {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: "Group ID",
    }),
    columnHelper.accessor("range", {
      header: "Agreena Certificates",
    }),
    columnHelper.accessor("quantity", {
      header: "Quantity",
    }),
    columnHelper.accessor("standard", {
      header: "Standard",
      cell: ChipCell,
    }),
    columnHelper.accessor("methodology", {
      header: "Methodology",
    }),
    columnHelper.accessor("vintageYear", {
      header: "Vintage year",
    }),
    columnHelper.accessor("type", {
      header: "Type",
      cell: ChipCell,
    }),
    columnHelper.accessor("country", {
      header: "Country",
      cell: ({ getValue }) => {
        const country = getValue();
        if (!country) return null;
        const { flag, name } = getCountryWithFlag(country);
        return `${flag} ${name}`;
      },
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("userId", {
      header: "User ID",
      cell: hasUserPermissions ? LinkCell : TextCell,
    }),
  ];
}

function getRowData(data: CertificateGroup[] | undefined): TableData[] {
  if (!data) return [];

  return data.map(group => ({
    id: group.id,
    range: group.chosenRange,
    quantity: group.chosenQuantity,
    standard: {
      label: group.standard,
      variant: (
        {
          verra: "success",
        } as Record<CertificateGroupStandard, ChipVariant>
      )[group.standard],
    },
    methodology: group.methodology,
    vintageYear: group.vintageYear,
    type: {
      label: group.type,
      variant: (
        {
          reduction: "warning",
          removal: "success",
        } as Record<CertificateGroupType, ChipVariant>
      )[group.type],
    },
    country: group.country.code,
    status: {
      label: group.status,
      variant: (
        {
          "available-for-sale": "success",
          locked: "warning",
          retired: "info",
          transferred: "info",
        } as Record<CertificateGroupStatus, ChipVariant>
      )[group.status],
    },
    userId: {
      url: `/users/${group.userId}`,
      isExternal: true,
      title: group.userId,
    },
  }));
}
