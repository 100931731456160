import { cva } from "class-variance-authority";
import { createRef, forwardRef, useEffect } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn } from "~utils";

const radioButtonVariants = cva(
  "relative m-0 h-4 w-4 appearance-none rounded-full border border-accent-100 bg-grey-white after:absolute after:left-1/2 after:top-1/2 after:block after:h-[6px] after:w-[6px] after:-translate-x-2/4 after:-translate-y-2/4 after:rounded-full after:bg-grey-white checked:border-accent-100 checked:bg-accent-100 invalid:border-messaging-error-700 invalid:bg-messaging-error-700 hover:cursor-pointer disabled:border-grey-300 disabled:bg-grey-300 disabled:after:bg-grey-600",
  {
    variants: {
      isInvalid: {
        true: "border-messaging-error-700 bg-messaging-error-700",
        false: "",
      },
    },
  },
);

type OwnProps = {
  isInvalid?: boolean;
};

type Props = OwnProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">;

export const RadioButton = forwardRef<HTMLInputElement, Props>(
  ({ isInvalid, ...rest }: Props, ref) => {
    const inputRef = createRef<HTMLInputElement>();

    useEffect(() => {
      inputRef.current?.setCustomValidity(isInvalid ? "error" : "");
    }, [inputRef, isInvalid]);

    return (
      <input
        ref={mergeRefs([ref, inputRef])}
        type="radio"
        className={cn(
          radioButtonVariants({
            isInvalid: Boolean(isInvalid),
          }),
        )}
        {...rest}
      />
    );
  },
);

RadioButton.displayName = "RadioButton";
