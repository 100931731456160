import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { IsoVcuSchema } from "../entities/iso-vcu";

type ImportIsoPayload = {
  quantity: number;
  vintageYear: number;
  methodology: string;
};

const importIsoVcu = async (payload: ImportIsoPayload) => {
  const response = await axios.post<CommonResponse<IsoVcuSchema>>(
    "/api/v1/verified-credit-units/iso",
    payload,
    {
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useImportIsoVcuMutation = () =>
  useMutation(importIsoVcu, {
    onError: error => ToastNotification.error(error),
  });
