import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { Button } from "@ag/design-system/atoms";
import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField, InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { SFormSubmitButtonContainer } from "~forms/styled";

import { SApplicationRoundTitle, SApplicationWrapper } from "./styled";
import {
  CropProtectionAttribute,
  CropProtectionCategory,
  CropProtectionDetailsAttribute,
  CropProtectionDetailsData,
  CropProtectionV2021Data,
} from "./types";

type Props = {
  protectionTypes: LabelValue<string>[];
  isReadonly?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<CropProtectionV2021Data>) => void;
};

export const CropProtectionForm = ({
  protectionTypes,
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<CropProtectionV2021Data>();
  const { values, pristine } = useFormState<CropProtectionV2021Data>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const handleAddApplicationClicked = (fields: {
    push: (value: CropProtectionDetailsData) => void;
  }) => {
    const emptyCropProtectionDetailsData = {
      [CropProtectionDetailsAttribute.Type]: null,
      [CropProtectionDetailsAttribute.Category]: null,
      [CropProtectionDetailsAttribute.NumberOfDoses]: null,
    };

    fields.push(emptyCropProtectionDetailsData);
  };

  const numberOfApplications =
    values[CropProtectionAttribute.CropProtections].length;
  const hasApplications = numberOfApplications > 0;

  return (
    <>
      <FieldArray name={CropProtectionAttribute.CropProtections}>
        {({ fields }) => (
          <div>
            {fields.map((name, index) => {
              const selectedType =
                fields.value[index][CropProtectionDetailsAttribute.Type];

              return (
                <SApplicationWrapper key={name} className="space-y-4">
                  <div className="items-center justify-between">
                    <SApplicationRoundTitle
                      data-testid={`cropProtection-applicationRoundTitle-${index}`}
                    >
                      {I18n.t("js.carbon.crop_protection_application")}

                      <span className="ml-1">{index + 1}</span>
                    </SApplicationRoundTitle>

                    {!isReadonly && (
                      <Button
                        data-testid={`cropProtection-deleteBtn-${index}`}
                        variant="secondary"
                        size="x-small"
                        isDanger
                        onClick={() => fields.remove(index)}
                      >
                        {I18n.t("js.shared.delete")}
                      </Button>
                    )}
                  </div>

                  <Field
                    name={`${name}.${CropProtectionDetailsAttribute.Type}`}
                    render={({ input, meta }) => (
                      <ButtonSelectField
                        {...input}
                        data-testid={`cropProtection-typeButtonGroup-${index}`}
                        label={I18n.t("js.carbon.type")}
                        error={
                          meta.touched && meta.error
                            ? { message: meta.error }
                            : undefined
                        }
                        tooltip={I18n.t(
                          "js.farmer.carbon.field_details.tilling_practice_tooltip",
                        )}
                        isDisabled={isReadonly}
                        onChange={value => {
                          input.onChange(value);

                          fields.update(index, {
                            [CropProtectionDetailsAttribute.Type]: value,
                            [CropProtectionDetailsAttribute.Category]:
                              CropProtectionCategory.PostEmergence,
                          });
                        }}
                      >
                        {protectionTypes.map(option => (
                          <ButtonSelect.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </ButtonSelect.Option>
                        ))}
                      </ButtonSelectField>
                    )}
                  />

                  <Field
                    name={`${name}.${CropProtectionDetailsAttribute.NumberOfDoses}`}
                    render={({ input, meta }) => (
                      <InputField
                        {...input}
                        data-testid={`cropProtection-numberOfDosesInput-${index}`}
                        type="number"
                        label={I18n.t("js.carbon.number_of_doses")}
                        tooltip={I18n.t("js.carbon.number_of_doses_hint")}
                        isDisabled={isReadonly || !selectedType}
                        error={
                          meta.touched && meta.error
                            ? { message: meta.error }
                            : undefined
                        }
                      />
                    )}
                  />
                </SApplicationWrapper>
              );
            })}

            {!isReadonly && (
              <Button
                data-testid="cropProtection-addApplicationRoundButton"
                variant="text"
                onClick={() => handleAddApplicationClicked(fields)}
              >
                + {I18n.t("js.carbon.add_crop_protection_application")}
              </Button>
            )}
          </div>
        )}
      </FieldArray>

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          {!hasApplications && (
            <Button
              data-testid="cropProtection-notUsingCropProtectionBtn"
              type="submit"
              variant="text"
              isLoading={isSubmitting}
              onClick={form.submit}
            >
              {I18n.t("js.carbon.not_using_crop_protection")}
            </Button>
          )}

          <Button
            data-testid="cropProtection-submitButton"
            type="submit"
            disabled={pristine}
            isLoading={hasApplications && isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </Button>
        </SFormSubmitButtonContainer>
      )}

      {isReadonly && !hasApplications && (
        <div>{I18n.t("js.carbon.no_crop_protections_added")}</div>
      )}
    </>
  );
};
