import omit from "lodash/omit";
import { forwardRef } from "react";
import { Link } from "react-router-dom";

import { OptionalObject } from "@ag/utils/types";

import { Icon, Spinner, getIconConfig } from "~assets";
import { cn } from "~utils";

import { buttonRootVariants, spinnerVariants } from "../helpers";
import {
  ActionButtonProps,
  ButtonIconProps,
  ButtonSize,
  ButtonVariant,
  ExternalLinkProps,
  LinkProps,
  isExternalLinkProps,
  isLinkProps,
} from "../types";

type BaseButtonProps = React.PropsWithChildrenRequired<{
  ref?: React.Ref<HTMLButtonElement>;
  variant?: ButtonVariant;
  iconPosition?: "before" | "after";
  size?: ButtonSize;
  theme?: "light" | "dark";
  isDanger?: boolean;
  isLoading?: boolean;
}> &
  OptionalObject<ButtonIconProps>;

const BASE_PROPS = [
  "ref",
  "children",
  "variant",
  "size",
  "iconPosition",
  "theme",
  "isDanger",
  "isLoading",
] as const;

export type ButtonProps = BaseButtonProps &
  (LinkProps | ExternalLinkProps | ActionButtonProps);

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      iconPosition = "before",
      variant = "primary",
      size = "medium",
      theme = "light",
      isDanger = false,
      isLoading = false,
      children,
    } = props;

    const iconProps = props.icon && getIconConfig(props, "icon", "iconVariant");

    const rootClass = cn(
      buttonRootVariants({
        size,
        theme,
        variant,
        iconPosition,
        isDanger,
        isLoading,
        hasIcon: Boolean(iconProps),
      }),
      props.className,
    );

    if (isLinkProps(props)) {
      const rest = omit(props, BASE_PROPS);

      return (
        <Link {...rest} className={rootClass} aria-disabled={isLoading}>
          {iconProps && <Icon {...iconProps} className="[grid-area:icon]" />}

          {isLoading && (
            <Spinner
              className={spinnerVariants({
                theme,
                size,
              })}
            />
          )}

          <span className={cn("[grid-area:content]", isLoading && "invisible")}>
            {children}
          </span>
        </Link>
      );
    }

    if (isExternalLinkProps(props)) {
      const rest = omit(props, BASE_PROPS);

      return (
        <a
          {...rest}
          className={rootClass}
          target={rest.target ?? "_blank"}
          aria-disabled={isLoading}
        >
          {iconProps && <Icon {...iconProps} className="[grid-area:icon]" />}
          {isLoading && (
            <Spinner
              className={spinnerVariants({
                theme,
                size,
              })}
            />
          )}
          <span className={cn("[grid-area:content]", isLoading && "invisible")}>
            {children}
          </span>
        </a>
      );
    }

    const rest = omit(props, BASE_PROPS);

    return (
      <button
        {...rest}
        ref={ref}
        disabled={rest.disabled || isLoading}
        className={rootClass}
      >
        {iconProps && <Icon {...iconProps} className="[grid-area:icon]" />}
        {isLoading && (
          <Spinner
            className={spinnerVariants({
              theme,
              size,
            })}
          />
        )}
        <span className={cn("[grid-area:content]", isLoading && "invisible")}>
          {children}
        </span>
      </button>
    );
  },
);

Button.displayName = "Button";
