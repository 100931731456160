import { z } from "zod";

import { GeoJSONGeometrySchema } from "@ag/map/types";

import { ContractType } from "~features/contract";

import { QualityAssuranceStatus } from "../types/field-definitions";
import { FieldValidationStatus } from "../types/validation";
import { FieldActualsV2021Schema } from "./field-actuals-v2021";
import { FieldActualsV2022Schema } from "./field-actuals-v2022";
import { FieldDefinitionSchema } from "./field-definition";

export const FieldSchema = z.object({
  activeCarbonContractId: z.string().nullable(),
  carbonHbFieldV2022LocationData: z
    .array(
      z.object({
        id: z.number(),
        boundaries: GeoJSONGeometrySchema.nullable(),
      }),
    )
    .nullable(),
  boundaries: GeoJSONGeometrySchema.nullable(),
  carbonFarmId: z.string(),
  carbonUserId: z.string(),
  completionRate: z.number(),
  contractType: z.nativeEnum(ContractType).optional(),
  coordinates: z
    .array(z.object({ lat: z.number(), lng: z.number() }))
    .optional(),
  createdAt: z.string(),
  dummy: z.boolean(),
  harvestYear: z.number(),
  id: z.string(),
  name: z.string(),
  position: z.object({ lat: z.number(), lng: z.number() }),
  qaStatus: z.nativeEnum(QualityAssuranceStatus).nullable(),
  sizeHa: z.number(),
  updatedAt: z.string(),
  validationStatus: z.nativeEnum(FieldValidationStatus),

  activeCarbonContract: z
    .object({
      id: z.number(),
      contractedYears: z.array(z.number()),
    })
    .optional(),

  carbonFarm: z.object({
    id: z.string(),
    name: z.string(),
  }),

  user: z.object({
    id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    hubspotContactUrl: z.string().nullable(),
    company: z.object({
      id: z.string(),
      name: z.string(),
      vatNumber: z.string().nullable(),
      registrationNumber: z.string().nullable(),
      vatValidatedAt: z.string().nullable(),
    }),
  }),

  carbonFieldDefinition: FieldDefinitionSchema,
  carbonFieldActual: z.array(
    z.discriminatedUnion("carbonFieldActualInputType", [
      FieldActualsV2021Schema,
      FieldActualsV2022Schema,
    ]),
  ),
});

export type Field = z.infer<typeof FieldSchema>;

export const FieldFiltersSchema = z.object({
  userId: z.string().optional(),
  id: z.string().optional(),
  fieldName: z.string().optional(),
  actualSubmitted: z.string().optional(),
  fallowActualsYears: z.string().optional(),
  activeContract: z.string().optional(),
  definitionSubmitted: z.string().optional(),
  expired: z.string().optional(),
});

export type FieldFilters = z.infer<typeof FieldFiltersSchema>;
