import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { generateContractQueryKey } from "./get-contract";

export type VoidNotSignedContractPayload = {
  contractId: string;
  reason: TerminationReason;
};

export enum TerminationReason {
  MISTAKEN = "mistaken request",
  DECLINED = "declined by signatory",
  NOT_SIGNED = "not signed",
  OTHER = "other",
}

const voidNotSignedContract = async ({
  contractId,
  reason,
}: VoidNotSignedContractPayload) => {
  return axios.delete(`/api/v1/contracts/${contractId}`, {
    params: {
      reason,
    },
    apiSource: "node-carbon",
  });
};
export const useVoidNotSignedContractMutation = () =>
  useMutation(voidNotSignedContract, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateContractQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
