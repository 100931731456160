import { ListKeyboardDelegate, useTypeSelect } from "@react-aria/selection";
import { useMemo } from "react";
import { mergeProps, useCollator, useMenuTrigger, usePress } from "react-aria";
import { ListState, useMenuTriggerState } from "react-stately";

type UseMultiSelectionOptions = {
  isDisabled?: boolean;
  delegate?: ListKeyboardDelegate<HTMLElement>;
};

export const useMultiSelect = (
  { delegate, isDisabled }: UseMultiSelectionOptions,
  state: ListState<object>,
  triggerRef: React.RefObject<HTMLElement>,
) => {
  const collator = useCollator({ usage: "search", sensitivity: "base" });

  const keyboardDelegate = useMemo(
    () =>
      delegate ??
      new ListKeyboardDelegate(
        state.collection,
        state.disabledKeys,
        triggerRef,
        collator,
      ),
    [delegate, state.collection, state.disabledKeys, triggerRef, collator],
  );

  // Allows for focusing value in the menu using keyboard, similar to native select.
  const { typeSelectProps } = useTypeSelect({
    keyboardDelegate,
    selectionManager: state.selectionManager,
    onTypeSelect: key => state.selectionManager.setFocusedKey(key),
  });

  typeSelectProps.onKeyDown = typeSelectProps.onKeyDownCapture;
  delete typeSelectProps.onKeyDownCapture;

  const triggerState = useMenuTriggerState({
    onOpenChange: open => {
      state.selectionManager.setFocusedKey(
        open ? keyboardDelegate.getFirstKey() : null,
      );
      state.selectionManager.setFocused(open);
    },
    isOpen: undefined,
    defaultOpen: undefined,
  });

  const { menuProps, menuTriggerProps } = useMenuTrigger(
    {
      type: "listbox",
      isDisabled: isDisabled,
    },
    triggerState,
    triggerRef,
  );

  const { pressProps } = usePress({
    ...mergeProps(menuTriggerProps, typeSelectProps),
    ref: triggerRef,
  });

  const triggerProps = mergeProps(
    typeSelectProps,
    menuTriggerProps,
    pressProps,
  );

  // We don't want to pass react-aria specific press handlers, only native key handlers.
  delete triggerProps.onPress;
  delete triggerProps.onPressStart;

  return {
    menuProps,
    triggerState,
    triggerProps,
  };
};
