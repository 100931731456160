import { useCallback } from "react";

import {
  getIsPathPointsInsidePolygon,
  getIsPolygonIntersectingOtherPolygons,
  isAnyOfPathPointsInsidePolygon,
  isPolygonSelfIntersecting,
  reshapePolygonWithLine,
} from "@ag/map/helpers";
import { GeoJSONGeometry } from "@ag/map/types";

import { HistoryStack } from "~hooks/use-polygon-paths-history";

import { ActiveVertex, usePolygonBoundariesStore } from "./polygonStore";

type DrawingManagerHandlersArgs = {
  history: HistoryStack;
  onError: (error: string) => void;
};

export const useDrawingManagerHandlers = ({
  history,
  onError,
}: DrawingManagerHandlersArgs) => {
  const {
    drawingManagerInstance,
    outerPath,
    innerPaths,
    setDrawingManagerInstance,
    setOuterPath,
    setInnerPaths,
    setEditMode,
    setEditAction,
    setActiveVertices,
  } = usePolygonBoundariesStore();

  const handleLoad = useCallback(
    (drawingManager: google.maps.drawing.DrawingManager) => {
      setDrawingManagerInstance(drawingManager);
    },
    [setDrawingManagerInstance],
  );

  const handlePolygonComplete = useCallback(
    (polygon: google.maps.Polygon) => {
      if (
        !drawingManagerInstance ||
        !outerPath ||
        // Polygon must have at least 3 points to be added
        polygon.getPath().getLength() < 3
      )
        return;

      const newPolygonPath: google.maps.LatLng[] = polygon.getPath().getArray();

      // Check if new polygon is inside outer polygon
      const isNewPolygonPathsPointsInsideOuterPolygon =
        getIsPathPointsInsidePolygon(
          newPolygonPath,
          new google.maps.Polygon({
            paths: outerPath,
          }),
        );

      if (!isNewPolygonPathsPointsInsideOuterPolygon) {
        onError("New polygon must be inside outer polygon");

        // Remove polygon from map
        polygon.setMap(null);

        return;
      }

      // Check if new polygon isn't intersecting with other inner polygons
      const isNewPolygonPathsPointsInsideOtherInnerPolygons = innerPaths?.every(
        innerPath =>
          !isAnyOfPathPointsInsidePolygon(
            newPolygonPath,
            new google.maps.Polygon({
              paths: innerPath,
            }),
          ),
      );

      if (!isNewPolygonPathsPointsInsideOtherInnerPolygons) {
        onError("New polygon must not intersect with other inner polygons");

        // Remove polygon from map
        polygon.setMap(null);

        return;
      }

      // Check if new polygon isn't self intersecting
      const isNewPolygonSelfIntersecting = isPolygonSelfIntersecting(polygon);

      if (isNewPolygonSelfIntersecting) {
        onError("New polygon must not self intersect");

        // Remove polygon from map
        polygon.setMap(null);

        return;
      }

      // Check if new polygon isn't intersecting others polygons
      const isNewPolygonIntersectingOthers =
        getIsPolygonIntersectingOtherPolygons(
          polygon,
          innerPaths.map(
            innerPath =>
              new google.maps.Polygon({
                paths: innerPath,
              }),
          ),
        );

      if (isNewPolygonIntersectingOthers) {
        onError("New polygon must not intersect other polygons");

        // Remove polygon from map
        polygon.setMap(null);

        return;
      }

      // Signed area may be used to determine the orientation of the path
      const outerPolygonSignedArea =
        google.maps.geometry.spherical.computeSignedArea(outerPath);
      const isOuterPolygonClockwise = outerPolygonSignedArea > 0;

      // Signed area may be used to determine the orientation of the path
      const newPolygonSignedArea =
        google.maps.geometry.spherical.computeSignedArea(newPolygonPath);
      const isNewPolygonPathClockwise = newPolygonSignedArea > 0;

      setInnerPaths([
        ...(innerPaths || []),
        // Path has to be in reversed order to outer polygon
        isOuterPolygonClockwise === isNewPolygonPathClockwise
          ? newPolygonPath.reverse()
          : newPolygonPath,
      ]);

      history.push({
        outerPath,
        innerPaths,
      });

      // Remove polygon from map after adding it to inner coordinates
      polygon.setMap(null);

      // Return to default mode
      drawingManagerInstance.setDrawingMode(null);
      setEditMode("editing");
    },
    [
      drawingManagerInstance,
      history,
      innerPaths,
      onError,
      outerPath,
      setEditMode,
      setInnerPaths,
    ],
  );

  const handleRectangleComplete = useCallback(
    (rectangle: google.maps.Rectangle) => {
      const bounds = rectangle.getBounds();
      if (!bounds) return;

      const verticesWithinBounds: ActiveVertex[] = [];

      // Check vertices in the outer path
      outerPath.forEach((latLng, index) => {
        if (bounds.contains(latLng)) {
          verticesWithinBounds.push({ vertex: index, path: 0 });
        }
      });

      // Check vertices in the inner paths
      innerPaths.forEach((path, pathIndex) => {
        path.forEach((latLng, vertexIndex) => {
          if (bounds.contains(latLng)) {
            verticesWithinBounds.push({
              vertex: vertexIndex,
              path: pathIndex + 1,
            });
          }
        });
      });

      setActiveVertices([...verticesWithinBounds]);

      rectangle.setMap(null);
    },
    [innerPaths, outerPath, setActiveVertices],
  );

  const handlePolylineComplete = (
    boundaries: GeoJSONGeometry,
    polyline: google.maps.Polyline,
  ) => {
    const path = polyline
      .getPath()
      .getArray()
      .map(latLng => [latLng.lng(), latLng.lat()]);

    const reshapedPolygon =
      boundaries && reshapePolygonWithLine(boundaries, path);

    reshapedPolygon?.newOuterPath &&
      setOuterPath(reshapedPolygon?.newOuterPath);
    reshapedPolygon?.newInnerPaths &&
      setInnerPaths(reshapedPolygon?.newInnerPaths);

    setEditAction("edit");
    polyline.setMap(null);
  };

  return {
    handleLoad,
    handlePolygonComplete,
    handleRectangleComplete,
    handlePolylineComplete,
  };
};
