import { create } from "zustand";

export type EditMode = "editing" | "drawing";

export type EditAction = "edit" | "cut";

export type ActiveVertex = {
  polygonId: number;
  path: number;
  vertex: number;
};

type MultiPolygonStore = {
  activeVertex: ActiveVertex | undefined;

  editMode: EditMode;
  editAction: EditAction;

  outerPath: google.maps.LatLng[][];
  innerPaths: google.maps.LatLng[][][];

  mapInstance: google.maps.Map | undefined;
  polygonInstance: google.maps.Polygon[];
  drawingManagerInstance: google.maps.drawing.DrawingManager[];

  setActiveVertex: (activeVertex: ActiveVertex | undefined) => void;

  setEditMode: (editMode: EditMode) => void;
  setEditAction: (editAction: EditAction) => void;

  setOuterPath: (outerPath: google.maps.LatLng[][]) => void;
  setInnerPaths: (innerPaths: google.maps.LatLng[][][]) => void;

  setMapInstance: (mapInstance: google.maps.Map) => void;
  addPolygonInstance: (
    polygonId: number,
    polygonInstance: google.maps.Polygon,
  ) => void;
  resetPolygonInstance: () => void;
  addDrawingManagerInstance: (
    polygonId: number,
    drawingManagerInstance: google.maps.drawing.DrawingManager,
  ) => void;
};

export const useMultiPolygonBoundariesStore = create<MultiPolygonStore>()(
  set => ({
    activeVertex: undefined,

    editMode: "editing",
    editAction: "edit",

    outerPath: [],
    innerPaths: [],

    mapInstance: undefined,
    polygonInstance: [],
    drawingManagerInstance: [],

    setActiveVertex: activeVertex => set({ activeVertex }),

    setEditMode: editMode => set({ editMode }),
    setEditAction: editAction => set({ editAction }),

    setOuterPath: outerPath => set({ outerPath }),
    setInnerPaths: innerPaths => set({ innerPaths }),

    setMapInstance: mapInstance => set({ mapInstance }),
    addPolygonInstance: (polygonId, polygonInstance) => {
      return set(state => {
        const polygonInstances = [...state.polygonInstance];
        polygonInstances[polygonId] = polygonInstance;

        return { polygonInstance: polygonInstances };
      });
    },
    resetPolygonInstance: () => {
      return set(() => ({
        polygonInstance: [],
      }));
    },
    addDrawingManagerInstance: (polygonId, drawingManagerInstance) => {
      return set(state => {
        const drawingManagerInstances = [...state.drawingManagerInstance];
        drawingManagerInstances[polygonId] = drawingManagerInstance;

        return { drawingManagerInstance: drawingManagerInstances };
      });
    },
  }),
);
