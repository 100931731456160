import { style } from "@ag/ui";
import {
  FieldActualsStatus,
  FieldDefinitionStatus,
  FieldStep,
  isFieldActualsStatus,
  isFieldDefinitionStatus,
} from "@ag/utils/types";

import { SFontAwesomeFieldStatusIcon, SInProgressIcon } from "./styled";

type GetFieldStatusIconArgs = {
  status: FieldDefinitionStatus | FieldActualsStatus;
  step: FieldStep;
};

type GetIconBackgroundColorArgs = {
  status: FieldDefinitionStatus | FieldActualsStatus;
  step: FieldStep;
};

export const getFieldStatusIcon = ({
  step,
  status,
}: GetFieldStatusIconArgs): React.ReactNode => {
  if (isFieldDefinitionStatus(step, status)) {
    const statusIconByStatus: Record<FieldDefinitionStatus, React.ReactNode> = {
      [FieldDefinitionStatus.NotStarted]: null,
      [FieldDefinitionStatus.InProgress]: (
        <SInProgressIcon data-testid="statusIndicator-inProgressIcon" />
      ),
      [FieldDefinitionStatus.Completed]: (
        <SFontAwesomeFieldStatusIcon
          isCheckIcon
          status={FieldDefinitionStatus.Completed}
          data-testid="statusIndicator-completedIcon"
          name="check"
        />
      ),
      [FieldDefinitionStatus.Submitted]: (
        <SFontAwesomeFieldStatusIcon
          isLockIcon
          status={FieldDefinitionStatus.Submitted}
          name="lock"
        />
      ),
    };

    return statusIconByStatus[status];
  }

  if (isFieldActualsStatus(step, status)) {
    const statusIconByStatus: Record<FieldActualsStatus, React.ReactNode> = {
      [FieldActualsStatus.NotStarted]: null,
      [FieldActualsStatus.InProgress]: (
        <SInProgressIcon data-testid="statusIndicator-inProgressIcon" />
      ),
      [FieldActualsStatus.Completed]: (
        <SFontAwesomeFieldStatusIcon
          status={FieldActualsStatus.Completed}
          data-testid="statusIndicator-completedIcon"
          name="check"
        />
      ),
      [FieldActualsStatus.Submitted]: (
        <SFontAwesomeFieldStatusIcon
          isLockIcon
          status={FieldActualsStatus.Submitted}
          data-testid="statusIndicator-submittedIcon"
          name="lock"
        />
      ),
    };

    return statusIconByStatus[status];
  }

  return null;
};

export const getFieldStatusIconBackgroundColor = ({
  status,
  step,
}: GetIconBackgroundColorArgs): string => {
  if (isFieldDefinitionStatus(step, status)) {
    const backgroundColorByStatus = {
      [FieldDefinitionStatus.NotStarted]: "transparent",
      [FieldDefinitionStatus.InProgress]: style.color.orange[100],
      [FieldDefinitionStatus.Completed]: style.color.opal[100],
      [FieldDefinitionStatus.Submitted]: style.color.opal[100],
    };

    return backgroundColorByStatus[status];
  }

  if (isFieldActualsStatus(step, status)) {
    const backgroundColorByStatus = {
      [FieldActualsStatus.NotStarted]: "transparent",
      [FieldActualsStatus.InProgress]: style.color.orange[100],
      [FieldActualsStatus.Completed]: style.color.opal[100],
      [FieldActualsStatus.Submitted]: style.color.opal[100],
    };

    return backgroundColorByStatus[status];
  }

  return "transparent";
};
