import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { create } from "zustand";

import { ADMIN_AUTH_DATA_STORAGE_KEY } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { useEventListener, useSearchParam } from "@ag/utils/hooks";
import { AuthData } from "@ag/utils/schemas";
import { ToastNotification } from "@ag/utils/services";
import { loadFromLocalStorage } from "@ag/utils/storage";

import { queryCache } from "~config";

import { getCurrentAdmin } from "../api/get-current-admin";
import { signIn as signInRequest } from "../api/sign-in";
import { signOut as signOutRequest } from "../api/sign-out";
import { CurrentAdmin } from "../entities/current-admin";

type TSessionContext = {
  authData: AuthData | null;
  currentAdmin: CurrentAdmin | undefined;

  isLoading: boolean;
  isSignedIn: boolean;

  fetchCurrentAdmin: () => Promise<void>;
  signIn: (data: Parameters<typeof signInRequest>[0]) => Promise<void>;
  signOut: (options?: { hasSessionExpired: boolean }) => Promise<void>;
  setAuthData: (authData: AuthData | null) => void;
};

type SessionStore = {
  currentAdmin: TSessionContext["currentAdmin"];
  authData: TSessionContext["authData"];

  setCurrentAdmin: (currentAdmin: TSessionContext["currentAdmin"]) => void;
  setAuthData: (userAuthData: TSessionContext["authData"]) => void;
  resetSession: () => void;
};

const SessionContext = createContext<TSessionContext | null>(null);

const sessionBroadcastChannel = new BroadcastChannel("session");

const useSessionStore = create<SessionStore>()(set => ({
  currentAdmin: undefined,
  authData: loadFromLocalStorage<AuthData | null>(ADMIN_AUTH_DATA_STORAGE_KEY),

  setCurrentAdmin: currentAdmin => set({ currentAdmin }),
  setAuthData: authData => set({ authData }),
  resetSession: () => set({ currentAdmin: undefined, authData: null }),
}));

export const SessionProvider = ({
  children,
}: React.PropsWithChildrenRequired) => {
  const [redirectSearchParam] = useSearchParam("redirect");

  const { currentAdmin, authData, setCurrentAdmin, setAuthData, resetSession } =
    useSessionStore();

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useRef(useNavigate());

  const fetchCurrentAdmin = useCallback(async () => {
    const newCurrentAdmin = await getCurrentAdmin();

    setCurrentAdmin(newCurrentAdmin);
  }, [setCurrentAdmin]);

  // Sync auth data between windows/tabs after refresh
  sessionBroadcastChannel.onmessage = event => {
    if (event.data === "auth-data:updated") {
      setAuthData(loadFromLocalStorage(ADMIN_AUTH_DATA_STORAGE_KEY));
    }
  };

  /**
   * This event will be triggered from the interceptor when the session is expired (401 response)
   */
  useEventListener("session-expired", () => {
    signOut({ hasSessionExpired: true });
  });

  const signIn = useCallback(
    async (data: Parameters<typeof signInRequest>[0]) => {
      try {
        setIsLoading(true);

        const authData = await signInRequest(data);

        localStorage.setItem(
          ADMIN_AUTH_DATA_STORAGE_KEY,
          JSON.stringify(authData),
        );
        setAuthData(authData);

        await fetchCurrentAdmin();

        navigate.current(redirectSearchParam || "/");
      } catch (error) {
        ToastNotification.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchCurrentAdmin, redirectSearchParam, setAuthData],
  );

  const signOut = useCallback<TSessionContext["signOut"]>(
    async options => {
      const { hasSessionExpired = false } = options || {};

      try {
        if (!hasSessionExpired) {
          await signOutRequest();
        }

        if (!hasSessionExpired) {
          navigate.current("/login");
        } else {
          if (location.pathname === "/login") return;

          navigate.current(
            "/login" + getSearchParams({ redirect: location.pathname }),
          );
        }

        localStorage.removeItem(ADMIN_AUTH_DATA_STORAGE_KEY);
        resetSession();

        queryCache.clear();
      } catch (error) {
        ToastNotification.error(error);
      }
    },
    [resetSession],
  );

  const contextValue = useMemo(
    () => ({
      authData,
      currentAdmin,

      isLoading,
      isSignedIn: Boolean(authData && currentAdmin),

      fetchCurrentAdmin,
      signIn,
      signOut,
      setAuthData,
    }),
    [
      authData,
      currentAdmin,
      fetchCurrentAdmin,
      isLoading,
      setAuthData,
      signIn,
      signOut,
    ],
  );

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("useSessionContext must be used within a SessionProvider");
  }

  return context;
};
