import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";

import { ChipVariant } from "@ag/design-system/atoms";
import {
  ChipCell,
  ChipCellValue,
  DateCell,
  DateCellValue,
  LinkCell,
  LinkCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { TableSortingState, useTable } from "~components/table";

import { Contract, ContractStatus, ContractType } from "../entities/contract";
import {
  getContractStatusLabel,
  getContractTypeLabel,
} from "../helpers/get-labels";

type TableData = {
  id: LinkCellValue;
  userId: LinkCellValue;
  vatNumber: string;
  signerEmail: string;
  signerPhoneNumber: string;
  name: string;
  contractYear: number;
  contractType: ChipCellValue;
  status: ChipCellValue;
  createdAt: DateCellValue;

  contract: Contract;
};

export const useContractsTable = (
  data: Contract[] | undefined,
  state?: TableSortingState,
) => {
  const columns = useMemo(
    () => getColumns({ isSortingEnabled: Boolean(state?.sorting) }),
    [state?.sorting],
  );
  const tableData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => String(original.contract.id),
    state: {
      sorting: state?.sorting,
    },
    onSortingChange: state?.setSorting,
  });
};

type ColumnsOptions = {
  isSortingEnabled?: boolean;
};

function getColumns({ isSortingEnabled }: ColumnsOptions = {}) {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: I18n.t("js.contracts.id"),
      cell: LinkCell,
      enableSorting: isSortingEnabled,
    }),
    columnHelper.accessor("userId", {
      header: I18n.t("js.shared.user_id"),
      cell: LinkCell,
    }),
    columnHelper.accessor("vatNumber", {
      header: I18n.t("js.admin.contracts.company_vat_number"),
    }),
    columnHelper.accessor("signerEmail", {
      header: I18n.t("js.admin.contracts.signer_email"),
    }),
    columnHelper.accessor("signerPhoneNumber", {
      header: I18n.t("js.admin.contracts.signer_phone_number"),
    }),
    columnHelper.accessor("name", {
      header: I18n.t("js.shared.name"),
    }),
    columnHelper.accessor("contractYear", {
      header: I18n.t("js.admin.contracts.contract_year"),
    }),
    columnHelper.accessor("contractType", {
      header: I18n.t("js.admin.contracts.contract_type.title"),
      cell: ChipCell,
    }),
    columnHelper.accessor("status", {
      header: I18n.t("js.admin.contracts.status.title"),
      cell: ChipCell,
    }),
    columnHelper.accessor("createdAt", {
      header: I18n.t("js.shared.created_at"),
      cell: DateCell,
    }),
  ];
}

function getRowData(contracts: Contract[] | undefined): TableData[] {
  if (!contracts) return [];

  const contractStatusVariant: Record<ContractStatus, ChipVariant> = {
    sent: "warning",
    signed: "success",
    withdrawn: "danger",
    terminated: "danger",
    expired: "neutral",
  };

  return contracts.map(contract => ({
    id: {
      url: `/carbon/contracts/${contract.id}`,
      title: contract.id,
    },
    userId: {
      url: `/users/${contract.userId}`,
      title: contract.userId,
    },
    vatNumber: contract.companyVatNumber,
    signerEmail: contract.signerEmail,
    signerPhoneNumber: contract.signerPhoneNumber,
    name: contract.name,
    contractYear:
      contract.carbonContractGroup && contract.carbonContractGroup.year
        ? contract.carbonContractGroup.year
        : 2024,
    contractType: {
      label: getContractTypeLabel(
        contract.contractType ? contract.contractType : ContractType.Fixed,
      ),
      variant: "neutral",
    },
    status: contract.status
      ? {
          label: getContractStatusLabel(contract.status),
          variant: contractStatusVariant[contract.status],
        }
      : undefined,
    createdAt: contract.createdAt,

    contract,
  }));
}
