import { z } from "zod";

import { FieldActualsStatus, MethodologyVersion } from "@ag/carbon/types";

import { QualityControlStatus } from "~features/quality-control/entities/quality-control";

/**
 * Base schema for all version of the field actuals
 * The only differences between versions are the input type and the input schema - base stays the same
 */
export const FieldActualsBaseSchema = z.object({
  completedAt: z.string().nullable(),
  completionRate: z.number(),
  createdAt: z.string(),
  fallow: z.boolean(),
  harvestYear: z.number(),
  id: z.string(),
  methodologyVersion: z.nativeEnum(MethodologyVersion),
  latestCarbonFootprint: z.any().nullable(), // response from CFT tool - JSON
  carbonCertificatesCount: z.number().nullable(),
  certificatesIssuedAt: z.string().nullable(),
  qcStatus: z.nativeEnum(QualityControlStatus).nullable(),
  readonly: z.boolean(),
  status: z.nativeEnum(FieldActualsStatus),
  submittedAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
});
