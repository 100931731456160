import * as Sentry from "@sentry/browser";

export const loadFromLocalStorage = <T>(key: string): T | null => {
  try {
    const serializedState = localStorage.getItem(key);

    return serializedState && JSON.parse(serializedState);
  } catch (err) {
    Sentry.captureException(err);

    return null;
  }
};

export const saveToLocalStorage = (key: string, value: unknown): void => {
  try {
    const serializedState = JSON.stringify(value);

    localStorage.setItem(key, serializedState);
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const removeFromLocalStorage = (key: string): void => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * Manager created for internal caching purposes
 * Saving cache checksums & cached responses to localStorage
 *
 * Example of usage with saga request (pay attention to meta param):
 *
 * ```
 * call(getRequest, {
 *   path: `/api/farmer/carbon/v1/carbon_fertilisers`,
 *   params: {
 *     countryCode,
 *   },
 *   meta: {
 *     useCache: true
 *   }),
 * ```
 */
class RequestCacheManager {
  private static instance: RequestCacheManager;
  private static readonly checksumStorageKey = "cacheChecksums";
  private static readonly responseStorageKey = "cacheResponses";

  constructor() {
    if (!RequestCacheManager.instance) {
      RequestCacheManager.instance = this;
    }

    return RequestCacheManager.instance;
  }

  public readChecksum = (requestUrl: string): string | null => {
    const checksums = loadFromLocalStorage<Record<string, string>>(
      RequestCacheManager.checksumStorageKey,
    );

    if (!checksums) {
      return null;
    }

    return checksums[requestUrl];
  };

  public saveChecksum = (requestUrl: string, checksum: string): void => {
    const currentChecksums = loadFromLocalStorage<Record<string, string>>(
      RequestCacheManager.checksumStorageKey,
    );

    const newChecksums = {
      ...currentChecksums,
      [requestUrl]: checksum,
    };

    saveToLocalStorage(RequestCacheManager.checksumStorageKey, newChecksums);
  };

  public saveResponse = (requestUrl: string, response: string): void => {
    const currentCachedResponses = loadFromLocalStorage<
      Record<string, unknown>
    >(RequestCacheManager.responseStorageKey);
    const newResponses = {
      ...currentCachedResponses,
      [requestUrl]: response,
    };

    saveToLocalStorage(RequestCacheManager.responseStorageKey, newResponses);
  };

  public readResponse = (requestUrl: string) => {
    const cachedResponses = loadFromLocalStorage<Record<string, unknown>>(
      RequestCacheManager.responseStorageKey,
    );

    if (!cachedResponses) {
      return null;
    }

    return cachedResponses[requestUrl];
  };
}

export const RequestCacheManagerInstance = new RequestCacheManager();
