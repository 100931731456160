import { PolygonProps } from "@react-google-maps/api";
import { useMemo } from "react";

import { getGeojsonGeometryLeafletLatLngCoordinates } from "~helpers";
import { GeoJSONGeometry, GeometryType } from "~types";

import { Polygon } from "./polygon";

type Props<F> = {
  field: F;
  onClick?: (field: F, event: google.maps.MapMouseEvent) => void;
};

export const FieldPolygon = <F extends { geometry: GeoJSONGeometry }>({
  field,
  onClick,
  ...restProps
}: Props<F> & Omit<PolygonProps, "onClick">) => {
  const polygonCoordinates = useMemo(
    () => getGeojsonGeometryLeafletLatLngCoordinates(field.geometry),
    [field],
  );

  if (field.geometry?.type === GeometryType.MultiPolygon) {
    return (
      <>
        {polygonCoordinates?.map((coordinates, index) => (
          <Polygon
            {...restProps}
            key={`field-polygon-${index}`}
            paths={coordinates as google.maps.LatLng[][]}
            onClick={e => onClick?.(field, e)}
          />
        ))}
      </>
    );
  }

  return (
    <Polygon
      {...restProps}
      paths={
        polygonCoordinates as google.maps.LatLng[] | google.maps.LatLng[][]
      }
      onClick={e => onClick?.(field, e)}
    />
  );
};
