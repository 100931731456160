import { useEffect, useState } from "react";
import { useDebounce } from "rooks";

import { Button } from "@ag/design-system/atoms";
import { ComboBox } from "@ag/design-system/molecules";

import { useUpdateRequestStore } from "~features/certificate";
import { useClientsQuery } from "~features/clients";

type Props = {
  onGoPrevStep: () => void;
  onGoNextStep: () => void;
};

const SUGGESTED_CLIENT = "client-0";

const ClientsStep = ({ onGoPrevStep, onGoNextStep }: Props) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const { client, setClient } = useUpdateRequestStore();

  const setInputValueDebounced = useDebounce(setInputValue, 300);

  const { data: clients, isLoading } = useClientsQuery(
    {
      filters: {
        name: inputValue,
      },
    },
    {
      enabled: inputValue.length > 0,
    },
  );

  // Update input value once client is selected
  useEffect(() => {
    setInputValue(client?.name ?? "");
    setValue(client?.id ?? "");
  }, [client]);

  const handleSetClient = (id: string) => {
    if (id === SUGGESTED_CLIENT) {
      setValue(SUGGESTED_CLIENT);
    } else {
      const selectedClient = clients?.items.find(client => client.id === id);

      if (selectedClient) {
        setClient({
          id: selectedClient.id,
          name: selectedClient.name,
        });
      }
    }
  };

  return (
    <>
      <div className="mx-auto flex w-[660px] flex-auto flex-col gap-4">
        <h2 className="pt-4 text-center text-h2 font-bold">Select client</h2>

        <ComboBox.Root
          key={value}
          value={value}
          emptyText="No clients found"
          loadingText="Loading clients..."
          isAsync
          isLoading={isLoading}
          onChange={handleSetClient}
          onInput={value => setInputValueDebounced(value)}
          listBoxClassName="max-h-[300px]"
        >
          {clients && clients.items.length > 0 && (
            <ComboBox.Section title="Search suggestions">
              {clients.items.map(client => (
                <ComboBox.Item key={client.id}>{client.name}</ComboBox.Item>
              ))}
            </ComboBox.Section>
          )}
        </ComboBox.Root>
      </div>

      <footer className="flex h-[104px] items-center justify-between gap-12 px-8 shadow-[0_-4px_8px_rgba(0,0,0,0.05)]">
        <div className="flex flex-1 justify-start">
          <Button
            icon="chevron-left"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className="flex flex-1 justify-end">
          <Button
            icon="chevron-right"
            iconPosition="after"
            onClick={onGoNextStep}
            disabled={!client}
          >
            Next
          </Button>
        </div>
      </footer>
    </>
  );
};

export default ClientsStep;
