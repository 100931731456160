import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Modal, Table, useTable } from "@ag/design-system/organisms";
import { TextareaField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { CarbonFieldUpdate } from "~features/field/api/update-field-details";
import { FieldOverview } from "~features/field/entities/field-overview";

import * as styles from "./update-field-names-modal.css";

/* -------------------------------------------------------------------------------------------------
 * UpdateFieldNamesModal
 * -----------------------------------------------------------------------------------------------*/
export type UpdateFieldNamesModalProps = {
  fields: FieldOverview[];
  isOpen: boolean;
  onSaveNames: (
    carbonFieldUpdates: CarbonFieldUpdate[],
    onSuccess: {
      onSuccess: () => void;
    },
  ) => void;
  onRequestClose: () => void;
};

export function UpdateFieldNamesModal({
  fields,
  isOpen,
  onSaveNames,
  onRequestClose,
}: UpdateFieldNamesModalProps) {
  if (!fields.length) return null;

  return (
    <Modal.Root
      size="extra-large"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
    >
      <Modal.Header>
        <Modal.Title>Update field names</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <UpdateFieldNamesForm
          fields={fields}
          onSaveNames={onSaveNames}
          onRequestClose={onRequestClose}
        />
      </Modal.Content>
    </Modal.Root>
  );
}

/* -------------------------------------------------------------------------------------------------
 * UpdateFieldNamesForm
 * -----------------------------------------------------------------------------------------------*/
type UpdateFieldNamesFormProps = {
  fields: FieldOverview[];
  onSaveNames: (
    carbonFieldUpdates: CarbonFieldUpdate[],
    onSuccess: {
      onSuccess: () => void;
    },
  ) => void;
  onRequestClose: () => void;
};

const createUpdateFieldNamesSchema = (fields: FieldOverview[]) =>
  z.object(
    fields.reduce(
      (acc, field) => {
        acc[field.id] = z.string().min(1, I18n.t("js.shared.required"));
        return acc;
      },
      {} as { [key: string]: z.ZodString },
    ),
  );

function UpdateFieldNamesForm({
  fields,
  onSaveNames,
  onRequestClose,
}: UpdateFieldNamesFormProps) {
  const schema = createUpdateFieldNamesSchema(fields);
  const { handleSubmit, control, reset } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: { [key: string]: string }) => {
    const updates = fields.map(
      field =>
        ({
          id: field.id.toString(),
          name: data[field.id],
        }) as CarbonFieldUpdate,
    );

    onSaveNames(updates, {
      onSuccess: () => {
        reset();
        onRequestClose();
      },
    });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Field ID",
        cell: (info: any) => info.getValue(),
      },
      {
        accessorKey: "userId",
        header: "User ID",
        cell: (info: any) => info.row.original.user.id,
      },
      {
        accessorKey: "companyName",
        header: "Company Name",
        cell: info => info.row.original.user.company.name,
      },
      {
        accessorKey: "oldFieldName",
        header: "Current field name",
        cell: info => info.row.original.name,
      },
      {
        accessorKey: "fieldName",
        header: "New field Name",
        cell: info => (
          <Controller
            name={info.row.original.id.toString()}
            control={control}
            render={({ field: formField, fieldState }) => (
              <TextareaField
                {...formField}
                value={formField.value}
                label=""
                error={fieldState.error}
                rows={1}
                cols={40}
              />
            )}
          />
        ),
      },
    ],
    [control],
  );

  const data = useMemo(
    () =>
      fields.map(field => ({
        ...field,
        userId: field.user.id,
        companyName: field.user.company.name,
        fieldName: field.name,
      })),
    [fields],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.tableContainer}>
        <Table instance={tableInstance} />
      </div>
      <div className={styles.buttonsContainer}>
        <Button variant="secondary" onClick={onRequestClose}>
          Discard
        </Button>
        <Button type="submit">Save field names</Button>
      </div>
    </form>
  );
}
