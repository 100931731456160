import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config";

import {
  AssuranceAction,
  AssuranceCategory,
  AssuranceCheckKey,
} from "../entities/assurance";
import { generateFieldsQueryKey } from "./get-fields";

type PerformFieldActionArgs = {
  fieldIds: string[];
  payload: {
    text: string;
    category: AssuranceCategory;
    checks?: AssuranceCheckKey;
  };
};

type FieldActionResult = {
  meta: {
    carbonFieldId: string;
  };
} & (
  | {
      success: AssuranceAction;
      error?: never;
    }
  | {
      success?: never;
      error: {
        message: string;
      };
    }
);
export const addFieldsComment = async ({
  fieldIds,
  payload,
}: PerformFieldActionArgs) => {
  const response = await axios.post<CommonResponse<FieldActionResult[]>>(
    `/api/v1/carbon-fields/carbon-quality-assurance-comments`,
    {
      carbonFieldIds: fieldIds,
      actionPayload: payload,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useAddFieldsCommentMutation = () =>
  useMutation(addFieldsComment, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateFieldsQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
