import { z } from "zod";

export const YearsDataSchema = z.object({
  carbonFieldCreatingYear: z.number(),
  carbonFieldSetupYears: z.array(z.number()),
  carbonFieldActualsSetupYears: z.array(z.number()),
  carbonFieldActualsSubmitYear: z.number(),
  carbonFieldActualsYears: z.array(z.number()),
  carbonFieldValidationYears: z.array(z.number()),
  carbonContractBenefitYears: z.array(z.number()),
  carbonCurrentHarvestYear: z.number(),
  carbonActiveHarvestYears: z.array(z.number()),
  carbonContractYear: z.number(),
  allHarvestYears: z.array(z.number()),
});

export type YearsData = z.infer<typeof YearsDataSchema>;
