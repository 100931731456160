import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { Button, InfoBox } from "@ag/design-system/atoms";
import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField, InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { SFormSubmitButtonContainer } from "~forms/styled";

import {
  ENERGY_WARNING_MAX_AMOUNT,
  ENERGY_WARNING_MIN_AMOUNT,
} from "./constants";
import { FuelConsumptionAttribute, FuelConsumptionV2022Data } from "./types";

type Props = {
  energySource: LabelValue<string>[];
  isReadonly?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<FuelConsumptionV2022Data>) => void;
};

export const FuelConsumptionForm = ({
  energySource,
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<FuelConsumptionV2022Data>();
  const { values, pristine } = useFormState<FuelConsumptionV2022Data>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const energyConsumptionAmountHa = values[FuelConsumptionAttribute.AmountHa];
  let isAmountWarningVisible = false;

  if (
    energyConsumptionAmountHa &&
    (energyConsumptionAmountHa < ENERGY_WARNING_MIN_AMOUNT ||
      energyConsumptionAmountHa > ENERGY_WARNING_MAX_AMOUNT)
  ) {
    isAmountWarningVisible = true;
  }

  return (
    <section className="space-y-4">
      <p className="text-p2">
        {I18n.t("js.carbon.energy_consumption_description")}
      </p>

      <div className="grid grid-cols-2 gap-4">
        <Field
          name={FuelConsumptionAttribute.EnergySource}
          render={({ input, meta }) => (
            <ButtonSelectField
              {...input}
              data-testid="fuelConsumption-energySourceButtonGroup"
              label={I18n.t("js.carbon.energy_source")}
              tooltip={I18n.t(
                "js.carbon.energy_consumption_amount_instruction",
              )}
              error={
                meta.touched && meta.error ? { message: meta.error } : undefined
              }
            >
              {energySource.map(source => (
                <ButtonSelect.Option key={source.value} value={source.value}>
                  {source.label}
                </ButtonSelect.Option>
              ))}
            </ButtonSelectField>
          )}
          disabled={isReadonly}
        />

        <div className="space-y-4">
          <Field
            name={FuelConsumptionAttribute.AmountHa}
            render={({ input, meta }) => (
              <InputField
                {...input}
                data-testid="fuelConsumption-amountInput"
                type="number"
                label={I18n.t(
                  "js.farmer.carbon.fuel_consumption.number_litres_per_ha",
                )}
                tooltip={I18n.t(
                  "js.carbon.energy_consumption_amount_instruction",
                )}
                error={
                  meta.touched && meta.error
                    ? { message: meta.error }
                    : undefined
                }
              />
            )}
            disabled={isReadonly}
          />

          {isAmountWarningVisible && (
            <InfoBox
              icon="triangle-exclamation"
              variant="warning"
              data-testid="fuelConsumption-alertBox"
            >
              <div>
                <strong>
                  {I18n.t("js.farmer.carbon.energy_consumption_warning_title")}
                </strong>

                <br />

                {I18n.t(
                  "js.farmer.carbon.energy_consumption_warning_description",
                )}
              </div>
            </InfoBox>
          )}
        </div>
      </div>

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          <Button
            data-testid="fuelConsumption-submitButton"
            type="submit"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </Button>
        </SFormSubmitButtonContainer>
      )}
    </section>
  );
};
