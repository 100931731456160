import { z } from "zod";

import { FertiliserAttribute, FertiliserDetailsAttribute } from "./types";

export const FertiliserDetailsDataSchema = z.object({
  [FertiliserDetailsAttribute.Id]: z.number().nullable(),
  [FertiliserDetailsAttribute.ApplicationRate]: z.number().nullable(),
  [FertiliserDetailsAttribute.ApplicationMethod]: z.string().nullable(),
  [FertiliserDetailsAttribute.EmissionsInhibitors]: z.string().nullable(),
});

export const FertiliserV2021DataSchema = z.object({
  [FertiliserAttribute.Fertilisers]: z.array(FertiliserDetailsDataSchema),
  [FertiliserAttribute.FertilisersCount]: z.number(),
  [FertiliserAttribute.FertilisersShowChemicalSpecification]: z
    .boolean()
    .nullable(),

  // Following attribute is being used to store parsed chemical specifications data structure
  chemicalSpecification: z
    .object({
      [FertiliserAttribute.Fertilisers]: z.record(
        z.number(),
        z.number().nullable(),
      ),
      [FertiliserDetailsAttribute.ApplicationMethod]: z.string().nullable(),
    })
    .optional(),
});
