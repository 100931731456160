export enum EditFieldAttribute {
  Name = "name",
  SizeHa = "sizeHa",
  Latitude = "latitude",
  Longitude = "longitude",
}

export type EditFieldData = {
  [EditFieldAttribute.Name]: string;
  [EditFieldAttribute.SizeHa]: number;
  [EditFieldAttribute.Latitude]: number;
  [EditFieldAttribute.Longitude]: number;
};
