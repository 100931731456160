import { create } from "zustand";

export type EditMode = "editing" | "drawing";

export type EditAction = "edit" | "cut" | "reshape";

export type SelectMode = "single" | "multi";

export type ActiveVertex = {
  path: number;
  vertex: number;
};

type PolygonStore = {
  activeVertex: ActiveVertex | undefined;
  activeVertices: ActiveVertex[];

  editMode: EditMode;
  editAction: EditAction;
  selectMode: "single" | "multi";

  outerPath: google.maps.LatLng[];
  innerPaths: google.maps.LatLng[][];

  mapInstance: google.maps.Map | undefined;
  polygonInstance: google.maps.Polygon | undefined;
  drawingManagerInstance: google.maps.drawing.DrawingManager | undefined;

  setActiveVertex: (activeVertex: ActiveVertex | undefined) => void;
  setActiveVertices: (activeVertices: ActiveVertex[]) => void;

  setEditMode: (editMode: EditMode) => void;
  setEditAction: (editAction: EditAction) => void;
  setSelectMode: (selectMode: SelectMode) => void;

  setOuterPath: (outerPath: google.maps.LatLng[]) => void;
  setInnerPaths: (innerPaths: google.maps.LatLng[][]) => void;

  setMapInstance: (mapInstance: google.maps.Map) => void;
  setPolygonInstance: (
    polygonInstance: google.maps.Polygon | undefined,
  ) => void;
  setDrawingManagerInstance: (
    drawingManagerInstance: google.maps.drawing.DrawingManager,
  ) => void;
};

export const usePolygonBoundariesStore = create<PolygonStore>()(set => ({
  activeVertex: undefined,
  activeVertices: [],

  editMode: "editing",
  editAction: "edit",
  selectMode: "single",

  outerPath: [],
  innerPaths: [],

  mapInstance: undefined,
  polygonInstance: undefined,
  drawingManagerInstance: undefined,

  setActiveVertex: activeVertex => set({ activeVertex }),
  setActiveVertices: activeVertices => set({ activeVertices }),

  setEditMode: editMode => set({ editMode }),
  setEditAction: editAction => set({ editAction }),
  setSelectMode: selectMode => set({ selectMode }),

  setOuterPath: outerPath => set({ outerPath }),
  setInnerPaths: innerPaths => set({ innerPaths }),

  setMapInstance: mapInstance => set({ mapInstance }),
  setPolygonInstance: polygonInstance => set({ polygonInstance }),
  setDrawingManagerInstance: drawingManagerInstance =>
    set({ drawingManagerInstance }),
}));
