import { EmptyState } from "@ag/design-system/organisms";

type Props = {
  onStartImport: () => void;
};

export const NoCertificatesState = ({ onStartImport }: Props) => {
  return (
    <EmptyState.Root className="h-full flex-1 justify-center">
      <EmptyState.Image />

      <EmptyState.Title>Start a new VCU import</EmptyState.Title>

      <EmptyState.Description>
        Import Verra VCU file to populate the certificates management
      </EmptyState.Description>

      <EmptyState.PrimaryButton onClick={onStartImport}>
        Start new import
      </EmptyState.PrimaryButton>
    </EmptyState.Root>
  );
};
