import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { z } from "zod";

import { InputField } from "@ag/form-fields";
import { SIGN_IN_AS_PARAM } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { usePagination } from "@ag/utils/hooks";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import { env } from "~config";
import { useOwnAssignedUsersQuery } from "~features/admin";
import { AuthorizedSidebar } from "~features/navigation";
import { useSignInAsAssociatedUserMutation } from "~features/user";
import { useOwnAssignedUsersTable } from "~features/user/hooks/use-own-assigned-users-table";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const schema = z.object({
  search: z.string().optional(),
});

type FiltersValues = z.infer<typeof schema>;

const AssignedUsers = () => {
  const [pagination, updatePagination, resetPagination] = usePagination();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [query, setQuery] = useQueryParams({
    search: withDefault(StringParam, undefined),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FiltersValues>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(schema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<FiltersValues>(query, setQuery, resetPagination, () =>
    setIsFiltersOpen(false),
  );

  const { mutate: signInAsAssociatedUser } =
    useSignInAsAssociatedUserMutation();

  const { data: usersData, isLoading } = useOwnAssignedUsersQuery({
    ...pagination,
    filters: {
      ...query,
    },
  });

  const onSignIn = useCallback(
    (userId: string) => {
      signInAsAssociatedUser(userId.toString(), {
        onSuccess: ({ ticket }) => {
          const params = getSearchParams({
            [SIGN_IN_AS_PARAM]: ticket,
          });

          window.open(env.REACT_APP_FARMER_URL + params);
        },
      });
    },
    [signInAsAssociatedUser],
  );

  const renderFilterBarItem = (
    key: keyof FiltersValues,
    value: FiltersValues[keyof FiltersValues],
  ) => {
    const label = (
      {
        search: "Search",
      } as Record<keyof FiltersValues, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const table = useOwnAssignedUsersTable(usersData?.items, onSignIn);

  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Assigned Users</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />

        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField
            {...register("search")}
            label="Search"
            error={errors.search}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={usersData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default AssignedUsers;
