import countryCodes from "country-codes-list";
import omit from "lodash/omit";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import I18n from "@ag/i18n";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import {
  CreateUserForm,
  CreateUserFormValues,
  useCreateUserMutation,
} from "~features/user";
import DetailsLayout from "~layouts/details-layout";

export const getCountryCallingCode = (countryCode: string) => {
  const countryCodeData = countryCodes
    .all()
    .find(countryCodeData => countryCodeData.countryCode === countryCode);

  return countryCodeData?.countryCallingCode;
};

const AddUser = () => {
  const navigate = useNavigate();

  const createUserMutation = useCreateUserMutation();

  const handleFormSubmitted = useCallback(
    (values: CreateUserFormValues) => {
      const countryCallingCode = getCountryCallingCode(values.phoneCountryCode);
      const phoneNumberWithCallingCode = countryCallingCode
        ? `+${countryCallingCode}${values.phoneNumber}`
        : values.phoneNumber;

      // Omit form values that shouldn't be sent to API
      const createUserData = {
        ...omit(values, ["confirmPassword", "phoneCountryCode"]),
        phoneNumber: phoneNumberWithCallingCode,
      };

      createUserMutation.mutate(createUserData, {
        onSuccess: ({ userId }) => {
          navigate(`/users/${userId}`);
        },
      });
    },
    [createUserMutation, navigate],
  );

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>
          {I18n.t("js.admin.create_user.form_title")}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <CreateUserForm onSubmit={handleFormSubmitted} />
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default AddUser;
