import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import {
  BiomassProperties,
  CropDetails,
  IpccProperties,
} from "../entities/crop-details";
import { generateCropByHarvestYearQueryKey } from "./get-crop-harvest-year";

export const updateCropProperties = async ({
  id,
  methodology,
  harvestYear,
  props,
}: {
  id: string;
  harvestYear: string;
} & (
  | {
      methodology: "ipcc";
      props: IpccProperties;
    }
  | {
      methodology: "biomass";
      props: BiomassProperties;
    }
)) => {
  const response = await axios.patch<CropDetails>(
    `/v1/crops/${methodology}/${id}/harvest_year/${harvestYear}`,
    props,
    {
      apiSource: "crops-fertilisers",
    },
  );

  return response.data;
};

export const useUpdateCropPropertiesMutation = () =>
  useMutation(updateCropProperties, {
    onSuccess: (_, { id, harvestYear }) => {
      queryClient.invalidateQueries(
        generateCropByHarvestYearQueryKey(id, harvestYear),
      );
    },
    onError: error => ToastNotification.error(error),
  });
