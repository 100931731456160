import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { Confirmation, Modal } from "@ag/design-system/organisms";
import { InputField, SelectField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import {
  useAdminRolesQuery,
  useAdminsQuery,
  useAdminsTable,
  useDeleteAdminMutation,
} from "~features/admin";
import {
  AdminFilters,
  AdminFiltersSchema,
} from "~features/admin/entities/admin";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const Admins = () => {
  const [adminToDelete, setAdminToDelete] = useState<{
    id: string | null;
    email: string | null;
  }>({
    id: null,
    email: null,
  });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const [query, setQuery] = useQueryParams({
    adminRoles: withDefault(StringParam, undefined),
    email: withDefault(StringParam, undefined),
  });

  const { data: adminRoles } = useAdminRolesQuery();

  const { data: adminsData, isLoading } = useAdminsQuery({
    ...pagination,
    filters: {
      adminRoles: query.adminRoles ? [query.adminRoles] : undefined,
      email: query.email,
    },
  });

  const deleteAdminMutation = useDeleteAdminMutation();

  const renderFilterBarItem = (
    key: keyof AdminFilters,
    value: AdminFilters[keyof AdminFilters],
  ) => {
    const label = (
      {
        adminRoles: "Role",
        email: "Email",
      } as unknown as Record<keyof AdminFilters, string>
    )[key];

    return `${label}: ${value}`;
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AdminFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(AdminFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<AdminFilters>(query, setQuery, resetPagination, () =>
    setIsFiltersOpen(false),
  );
  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  const handleDeleteAdminClicked = useCallback(
    ({ id, email }: { id: string; email: string }) => {
      setAdminToDelete({ id, email });
    },
    [],
  );

  const handleAdminDeletionConfirmed = () => {
    if (!adminToDelete.id) return;

    deleteAdminMutation.mutate(adminToDelete.id, {
      onSuccess: () => {
        ToastNotification.success("The admin account has been deleted");
        setAdminToDelete({ id: null, email: null });
      },
    });
  };

  const table = useAdminsTable({
    data: adminsData?.items,
    onAdminDelete: handleDeleteAdminClicked,
  });

  return (
    <>
      <ListLayout.Root>
        <ListLayout.TopBar>
          <ListLayout.TopBarTitle>Admins</ListLayout.TopBarTitle>

          <ListLayout.TopBarActions>
            <Button to="/admins/add">New</Button>
          </ListLayout.TopBarActions>
        </ListLayout.TopBar>

        <ListLayout.Sidebar>
          <AuthorizedSidebar />
          <Filters.Drawer
            isOpen={isFiltersOpen}
            onSubmit={handleSubmit(handleSubmitFilters)}
            onClose={() => setIsFiltersOpen(false)}
            onClear={handleFormFiltersClear}
          >
            {adminRoles && (
              <Controller
                name="adminRoles"
                control={control}
                render={({ field, fieldState }) => (
                  <SelectField
                    {...field}
                    {...fieldState}
                    value={field.value ?? ""}
                    label="Role"
                    optionsClassName="z-modal"
                  >
                    <>
                      <Select.OptionAll>All</Select.OptionAll>

                      {adminRoles.map(role => (
                        <Select.Option key={role.id} value={role.name}>
                          {role.name}
                        </Select.Option>
                      ))}
                    </>
                  </SelectField>
                )}
              />
            )}

            <InputField
              {...register("email")}
              label="Email"
              error={errors.email}
            />
          </Filters.Drawer>
        </ListLayout.Sidebar>

        <ListLayout.Content>
          <ListLayout.Header>
            <Filters.Bar
              values={query}
              renderItem={renderFilterBarItem}
              onToggleOpen={() => setIsFiltersOpen(value => !value)}
              onClear={handleClearFiltersBar}
            />
          </ListLayout.Header>

          <Table
            instance={table}
            meta={adminsData?.meta}
            pagination={pagination}
            isLoading={isLoading}
            onPaginationChange={updatePagination}
          />
        </ListLayout.Content>
      </ListLayout.Root>

      <Modal.Root
        isOpen={Boolean(adminToDelete.id)}
        size="small"
        onRequestClose={() => setAdminToDelete({ id: null, email: null })}
      >
        <Modal.Content>
          <Confirmation.Root variant="danger">
            <Icon name="warning" fontSize="46" className="text-red-800" />

            <Confirmation.Title key="title">Delete admin</Confirmation.Title>

            <Confirmation.Description key="description">
              Are you sure you want to delete admin &quot;
              {adminToDelete.email || ""}&quot;?
            </Confirmation.Description>

            <Confirmation.Actions key="actions">
              <Confirmation.Cancel
                onClick={() => setAdminToDelete({ id: null, email: null })}
                variant="text"
              >
                Cancel
              </Confirmation.Cancel>
              <Confirmation.Confirm
                onClick={handleAdminDeletionConfirmed}
                isDanger
              >
                Confirm
              </Confirmation.Confirm>
            </Confirmation.Actions>
          </Confirmation.Root>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default Admins;
