export const VALIDATOR = {
  // HTML tags
  HTML: /<(?!br\s*\/?)[^>]+>/gi,

  // Number containing at least 6 digits with optional character between the numbers
  PHONE: /(\d{1}.{0,1}){6,}/g,

  // Email validation as per RFC 5322 Official Standard
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

  // Fake email with spaces and "at", "dot"
  FAKE_EMAIL: /\w+ +(@|at)+ *\w+ *(dot|\.) *\w+/gi,

  // Password with a minimum of 6 characters
  PASSWORD: /.{6,}/gi,

  COORDINATE_LAT: /^-?\d{1,2}\.\d{4,}/,

  COORDINATE_LNG: /^-?\d{1,3}\.\d{4,}/,
};
