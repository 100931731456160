import { useNavigate } from "react-router-dom";

import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import FullPageSpinner from "~components/full-page-spinner";
import {
  Page,
  useAuthPermissions,
  useCarbonPermissions,
  useMarketsPermissions,
  useUserManagementPermissions,
} from "~features/permission";

import { getHasPageAccess } from "../helpers/page-access";

export function withPageAccess<T extends object>(page: Page) {
  return (WrappedComponent: () => JSX.Element | null) => {
    const WithPageAccessComponent = (props: T) => {
      const navigate = useNavigate();

      const {
        data: carbonPermissions,
        isLoading: isCarbonPermissionsLoading,
        isFetched: isCarbonPermissionsFetched,
      } = useCarbonPermissions();

      const {
        data: authPermissions,
        isLoading: isAuthPermissionsLoading,
        isFetched: isAuthPermissionsFetched,
      } = useAuthPermissions();

      const {
        data: userManagementPermissions,
        isLoading: isUserManagementPermissionsLoading,
        isFetched: isUserManagementPermissionsFetched,
      } = useUserManagementPermissions();

      const {
        data: marketsPermissions,
        isLoading: isMarketsPermissionsLoading,
        isFetched: isMarketsPermissionsFetched,
      } = useMarketsPermissions();

      if (
        isCarbonPermissionsLoading ||
        isAuthPermissionsLoading ||
        isUserManagementPermissionsLoading ||
        isMarketsPermissionsLoading
      ) {
        return <FullPageSpinner />;
      }

      if (
        !isCarbonPermissionsFetched ||
        !isAuthPermissionsFetched ||
        !isUserManagementPermissionsFetched ||
        !isMarketsPermissionsFetched
      ) {
        return null;
      }

      const hasPageAccess = getHasPageAccess({
        page,
        userManagementPermissions,
        carbonPermissions,
        authPermissions,
        marketsPermissions,
      });

      if (!hasPageAccess) {
        navigate("/");

        ToastNotification.error(I18n.t("js.errors.no_access"));

        return null;
      }

      return <WrappedComponent {...props} />;
    };

    return WithPageAccessComponent;
  };
}
