import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  Contract,
  ContractSchema,
  ContractStatus,
  ContractType,
} from "../entities/contract";

type GetContractsParams = MetaArgs & {
  filters?: Partial<{
    companyVatNumber: string[];
    contractType: ContractType[];
    creditingPeriod: number[] | string[];
    status: ContractStatus[];
    docusignEnvelopeId: string[];
    signerEmail: string[];
    signerPhoneNumber: string[];
    year: number[];
    userId: string[];
    carbonUserId: string[];
  }>;
  sort?: Partial<{
    id: SortingDirection;
  }>;
};

export const generateContractsQueryKey = (
  params?: GetContractsParams | undefined,
) => ["contracts", params].filter(Boolean);

const getContracts = async (params: GetContractsParams | undefined) => {
  const response = await axios.get<CommonPaginatedResponse<Contract>>(
    "/api/v1/contracts",
    {
      params: {
        ...params,
        // Limit can be set to 0 to get meta only
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(ContractSchema),
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useContractsQuery = (
  params?: GetContractsParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(generateContractsQueryKey(params), () => getContracts(params), {
    keepPreviousData: true,
    ...options,
  });
