import { z } from "zod";

import { CropTypeSchema } from "~entities";
import { CoverCrops } from "~types";

import { CultivationStrategyAttribute } from "./types";

export const CultivationStrategyV2022DataSchema = z.object({
  [CultivationStrategyAttribute.CropLastHarvestYear]: z.number().optional(),
  [CultivationStrategyAttribute.CarbonCropTypeId]: z.string().nullable(),
  [CultivationStrategyAttribute.CarbonCropType]: CropTypeSchema.nullable(),
  [CultivationStrategyAttribute.CropTypeOtherName]: z
    .string()
    .nullable()
    .optional(),
  [CultivationStrategyAttribute.CropGrossYield]: z.number().nullable(),
  [CultivationStrategyAttribute.CoverCropsAdoption]: z
    .nativeEnum(CoverCrops)
    .nullable(),
  [CultivationStrategyAttribute.CropResidueManagement]: z.string().nullable(),
  [CultivationStrategyAttribute.TillingRate]: z.number().nullable(),
});
