import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { Client } from "../entities/client";
import { generateClientsQueryKey } from "./get-clients";

type Payload = {
  name: string;
};

const createClient = async (payload: Payload): Promise<Client> => {
  const response = await axios.post("/api/v1/clients", payload, {
    apiSource: "node-markets",
  });
  return response.data.success!.data;
};

export const useCreateClientMutation = () =>
  useMutation(createClient, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateClientsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
