import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { UpdateRequest } from "../entities/update-request";

type UpdatePayload = {
  action: "transfer" | "retirement";
};

const createDraftUpdateRequest = async (updatePayload: UpdatePayload) => {
  const response = await axios.post<CommonResponse<UpdateRequest>>(
    "/api/v1/certificates/groups/update-requests",
    updatePayload,
    {
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useCreateDraftUpdateRequestMutation = () =>
  useMutation(createDraftUpdateRequest, {
    onError: error => ToastNotification.error(error),
  });
