import format from "date-fns/format";

import I18n from "@ag/i18n";
import { KeyMap } from "@ag/utils/types";

import { FieldValidationStatus } from "~features/field";
import { ValidationRequest } from "~features/validation-request";

export const mapValidationRequestsToCSVData = (
  validationRequests: ValidationRequest[] | undefined,
): KeyMap<string | number | string[]>[] | null => {
  if (!validationRequests) return [];

  const data = validationRequests.map(
    (validationRequest: ValidationRequest) => {
      const allFieldsIds = validationRequest.carbonFields.map(
        field => field.id,
      );
      const validatedFieldIds = validationRequest.carbonFields
        .filter(
          field => field.validationStatus === FieldValidationStatus.Validated,
        )
        .map(field => field.id);
      const rejectedFieldIds = validationRequest.carbonFields
        .filter(
          field => field.validationStatus === FieldValidationStatus.Rejected,
        )
        .map(field => field.id);

      return {
        [I18n.t("js.admin.id")]: validationRequest.id,
        [I18n.t("js.admin.nudging.sender")]: validationRequest.user.id,
        [I18n.t("js.admin.requested_fields")]: allFieldsIds,
        [I18n.t("js.carbon.validated_fields")]: validatedFieldIds,
        [I18n.t("js.admin.rejected_fields")]: rejectedFieldIds,
        [I18n.t("js.admin.nudging.sent_at")]: format(
          new Date(validationRequest.createdAt),
          "dd/MM/yyyy",
        ),
      };
    },
  );

  return data;
};
