import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateUsersQueryKey } from "./get-users";

type UdateCompanyData = {
  userId: string;
  name: string;
  vatNumber: string;
  registrationNumber?: string;
  address: {
    address: string;
    city: string;
    zipCode: string;
  };
  contractSignerFirstName: string;
  contractSignerLastName: string;
  contractSignerEmail: string;
  contractSignerPhoneNumber: string;
  contractSignerCountryCode: string;
};

const updateCompany = async (data: UdateCompanyData) => {
  const { userId, ...companyData } = data;

  await axios.put(
    `/api/v1/admins/user-management/${userId}/company`,
    companyData,
    {
      apiSource: "node-user-mgt",
    },
  );
};

export const useUpdateCompanyMutation = () =>
  useMutation(updateCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateUsersQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
