import { LabelValue } from "./object";

export type Country = LabelValue<string> & {
  code: string;
};

export enum StateHandler {
  InnerState = "INNER_STATE",
  QueryParams = "QUERY_PARAMS",
}

export enum ScrollDirection {
  Up = "UP",
  Down = "DOWN",
}
