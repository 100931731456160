import { z } from "zod";

export const UpdateRequestSummarySchema = z.object({
  quantity: z.number(),
  breakdown: z.object({
    types: z.object({
      removal: z.number(),
      reduction: z.number(),
    }),
    standards: z.object({
      verra: z.number(),
      iso: z.number(),
    }),
    vintageYears: z.record(z.number()),
    countries: z.record(z.number()),
  }),
});

export type UpdateRequestSummary = z.infer<typeof UpdateRequestSummarySchema>;
