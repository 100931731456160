import { z } from "zod";

export enum QualityControlCategory {
  Fertiliser = "fertiliser",
  Crop = "crop",
  SoilDisturbance = "soil_disturbance",
  ResidueManagement = "residue_management",
  CoverCrop = "cover_crop",
  Fuel = "fuel",
}

export enum QualityControlFlag {
  Blue = "blue",
  Green = "green",
  Yellow = "yellow",
  Orange = "orange",
  Red = "red",
  None = "none",
}

export enum QualityControlStatus {
  Processing = "processing",
  NonConformance = "non_conformance",
  NonCompliance = "non_compliance",
  Approved = "qc_approved",
}

export enum QualityControlCheckKey {
  FertiliserSyntheticNitrogenApplication = "synthetic_nitrogen_application",
  FertiliserOrganicNitrogenApplication = "organic_nitrogen_application",
  FertiliserOrganicApplicationRates = "organic_application_rates",
  FertiliserMinMax = "fertiliser_min_max",
  CropYield = "crop_yield",
  CoverCropHbData = "cover_crop_hb_data",
  CoverCropHb = "cover_crop_hb",
  CropTypeCategory = "crop_type_category",
  SoilDisturbanceDetectedTillage = "detected_tillage",
  FuelTillageCategory = "tillage_category_fuel",
}

export const QualityControlCheckSchema = z.object({
  id: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),

  key: z.nativeEnum(QualityControlCheckKey),
  keyName: z.string(),
  value: z.union([z.string(), z.number()]),
  unit: z.string().nullable(),
  flag: z.nativeEnum(QualityControlFlag),
  status: z.string(),
  lastActiveAt: z.string(),
});

export type QualityControlCheck = z.infer<typeof QualityControlCheckSchema>;

export const QualityControlSchema = z.object({
  id: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),

  category: z.nativeEnum(QualityControlCategory),
  checks: z.array(QualityControlCheckSchema),
  flag: z.nativeEnum(QualityControlFlag),

  year: z.number(),
  value: z.string().nullable(),
  unit: z.string().nullable(),
});

export type QualityControl = z.infer<typeof QualityControlSchema>;

export const QualityControlActionSchema = z.object({
  id: z.string(),

  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),

  adminId: z.string(),

  qcNewStatus: z.nativeEnum(QualityControlStatus),
  qcOldStatus: z.nativeEnum(QualityControlStatus).nullable(),
  description: z.string().nullable(),
});

export type QualityControlAction = z.infer<typeof QualityControlActionSchema>;

export const QualityControlCommentSchema = z.object({
  id: z.string(),
  adminId: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),

  carbonFieldActualId: z.string(),

  category: z.nativeEnum(QualityControlCategory),
  checks: z.nativeEnum(QualityControlCheckKey).nullable(),
  text: z.string(),
});

export type QualityControlComment = z.infer<typeof QualityControlCommentSchema>;
