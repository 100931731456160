import { z } from "zod";

import { MethodologyVersion } from "~types";

export const ISOFertiliserSchema = z.object({
  chemicalSpecification: z.boolean(),
  createdAt: z.string(),
  harvestYear: z.number(),
  id: z.number(),
  identifier: z.string(),
  methodologyVersion: z.nativeEnum(MethodologyVersion),
  name: z.string(),
  nitrificationAdded: z.boolean().nullable(),
  nitrogenTotal: z.number(),
  organicCarbon: z.number().nullable(),
  tradeName: z.string().nullable(),
  updatedAt: z.string(),
});

export type ISOFertiliser = z.infer<typeof ISOFertiliserSchema>;
