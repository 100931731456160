import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";

import ImageLogo from "~assets/images/logo/logo-agreena-admin-dark.svg";
import { useSessionContext } from "~features/auth";
import CenteredLayout from "~layouts/centered-layout";

const loginSchema = () =>
  z.object({
    email: z.string().min(1, "Email is required"),
    password: z.string().min(1, "Password is required"),
  });

type LoginFormValues = z.infer<ReturnType<typeof loginSchema>>;

const Login = () => {
  const { signIn, isLoading } = useSessionContext();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema()),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <CenteredLayout.Root>
      <div className="flex w-72 flex-col space-y-8">
        <img src={ImageLogo} className="w-40 self-center" />

        <form
          onSubmit={handleSubmit(signIn)}
          className="flex flex-col space-y-4"
        >
          <InputField
            {...register("email")}
            type="email"
            autoComplete="email"
            label="Email"
            error={errors.email}
          />

          <InputField
            {...register("password")}
            type="password"
            autoComplete="password"
            label="Password"
            error={errors.password}
          />

          <div className="flex flex-col items-end space-y-4">
            <Button to="/reset-password" variant="text">
              Forgot password?
            </Button>

            <p className="text-p3">
              By logging in, you are consenting to cookie tracking for
              analytics.
            </p>

            <Button isLoading={isLoading}>Log in</Button>
          </div>
        </form>
      </div>
    </CenteredLayout.Root>
  );
};

export default Login;
