import { uniqBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import { IconButton } from "@ag/design-system/atoms";
import { ButtonSelect, ComboBox, Select } from "@ag/design-system/molecules";
import { typography } from "@ag/design-system/tokens";
import { box, stack } from "@ag/design-system/utils";
import {
  ButtonSelectField,
  ComboBoxField,
  InputField,
  SelectField,
} from "@ag/form-fields";
import I18n from "@ag/i18n";

import { FlagValue } from "~components/flag-select";
import { FlagSelectField } from "~components/flag-select-field";
import { useCropTypesQuery } from "~features/crop-type";

import { QualityControlFlag } from "../entities/quality-control";
import {
  CheckFilterKey,
  getFiltersConfig,
} from "../helpers/get-filters-config";
import { QualityControlFilterData } from "../types/filters";
import * as styles from "./fields-check-filters.css";

type Props = {
  form: UseFormReturn<QualityControlFilterData>;
  filtersValues: QualityControlFilterData;
  onClear: (name?: keyof QualityControlFilterData) => void;
};

export const FieldsCheckFilters = ({ form, filtersValues, onClear }: Props) => {
  const { control, register } = form;

  const { data: cropTypes } = useCropTypesQuery({ limit: 9999, filters: {} });

  const uniqueCropTypes = useMemo(() => {
    if (!cropTypes?.data) return [];

    return uniqBy(cropTypes.data, item => item.identifier);
  }, [cropTypes]);

  const [newFilter, setNewFilter] = useState<CheckFilterKey | null>(null);
  const [activeFilters, setActiveFilters] = useState<CheckFilterKey[]>([]);

  const filtersConfig = useMemo(getFiltersConfig, []);

  // Don't show filters that are already set when adding a new filter
  const availableFilters = useMemo(
    () =>
      (Object.keys(filtersConfig) as CheckFilterKey[]).filter(
        filterKey => !activeFilters.includes(filterKey),
      ),
    [activeFilters, filtersConfig],
  );

  // active filters are stored in ref, as we only want the initial values to be synced with internal state
  const activeFiltersKeys = useRef(
    Object.keys(filtersValues).filter(
      key => filtersValues[key as keyof QualityControlFilterData] !== undefined,
    ),
  );

  // Sync local active filters to show the correct inputs
  useEffect(() => {
    const newActiveFilters: CheckFilterKey[] = [];

    (Object.keys(filtersConfig) as CheckFilterKey[]).forEach(filterKey => {
      const isFilterSet = activeFiltersKeys.current.some(activeFilterKey =>
        activeFilterKey.includes(filterKey),
      );

      if (isFilterSet) {
        newActiveFilters.push(filterKey as CheckFilterKey);
      }
    });

    setActiveFilters(newActiveFilters);
  }, [filtersConfig]);

  const handleAddNewFilter = () => {
    if (!newFilter) return;

    setActiveFilters([...activeFilters, newFilter]);
    setNewFilter(null);
  };

  const handleRemoveFilter = (filterKey: CheckFilterKey) => {
    setActiveFilters(
      activeFilters.filter(activeFilter => activeFilter !== filterKey),
    );

    const filterConfig = filtersConfig[filterKey];

    // Reset filter values for the given filter type
    if (filterConfig.type === "range") {
      onClear(`${filterKey}.value.min` as keyof QualityControlFilterData);
    }

    if (filterConfig.type === "select" || filterConfig.type === "boolean") {
      onClear(`${filterKey}.value` as keyof QualityControlFilterData);
    }

    if (filterConfig.flag) {
      onClear(`${filterKey}.flag` as keyof QualityControlFilterData);
    }

    // Custom filters
    if (filterConfig.type === "crop") {
      onClear("crop.flag");
      onClear("crop.value.category");
      onClear("crop.value.identifier");
      onClear("crop.value.range.min");
      onClear("crop.value.range.max");
    }

    if (filterConfig.type === "fertiliser") {
      onClear("fertiliser.flag");
      onClear("fertiliser.value.category");
      onClear("fertiliser.value.range.min");
      onClear("fertiliser.value.range.max");
    }

    if (filterConfig.type === "fuel") {
      onClear("fuel.flag");
      onClear("fuel.value.range.min");
      onClear("fuel.value.range.max");
    }
  };

  return (
    <>
      <h3 className={typography.p1}>Checks</h3>

      <fieldset className={stack()}>
        {activeFilters.map(filterKey => {
          const filterConfig = filtersConfig[filterKey];

          return (
            <div key={filterKey} className={styles.filterGroup}>
              <div className={box({ align: "center", justify: "between" })}>
                <h5 className={typography.p2}>{filterConfig.label}</h5>

                <IconButton
                  icon="trash-can"
                  size="x-small"
                  isDanger
                  variant="secondary"
                  onClick={() => handleRemoveFilter(filterKey)}
                />
              </div>

              {filterConfig?.flag && (
                <Controller
                  name={`${filterKey}.flag` as keyof QualityControlFilterData}
                  control={control}
                  render={({ field, fieldState }) => (
                    <FlagSelectField
                      {...field}
                      {...fieldState}
                      value={(field.value as unknown as FlagValue[]) ?? ""}
                      label={I18n.t("js.admin.quality_control.filters.flags")}
                      options={Object.values(QualityControlFlag)}
                      isDisabled={filterConfig.disabledFlags}
                    />
                  )}
                />
              )}

              {filterConfig?.type === "range" && (
                <div className={stack({ gap: 4 })}>
                  <div className={box()}>
                    <InputField
                      {...register(
                        `${filterKey}.value.min` as keyof QualityControlFilterData,
                      )}
                      step={0.01}
                      type="number"
                      label={I18n.t("js.shared.from")}
                    />

                    <InputField
                      {...register(
                        `${filterKey}.value.max` as keyof QualityControlFilterData,
                      )}
                      step={0.01}
                      type="number"
                      label={I18n.t("js.shared.to")}
                    />
                  </div>

                  {filterConfig?.rangeUnit && <em>{filterConfig.rangeUnit}</em>}
                </div>
              )}

              {filterConfig?.type === "select" && (
                <Controller
                  name={`${filterKey}.value` as keyof QualityControlFilterData}
                  control={control}
                  render={({ field, fieldState }) => (
                    <SelectField
                      {...field}
                      {...fieldState}
                      value={(field.value as string) ?? ""}
                      label={I18n.t("js.shared.value")}
                    >
                      <Select.Option key="EMPTY_OPTION" value="">
                        {I18n.t("js.shared.all")}
                      </Select.Option>

                      {filterConfig.options.map(({ value, label }) => (
                        <Select.Option key={value} value={value}>
                          {label}
                        </Select.Option>
                      ))}
                    </SelectField>
                  )}
                />
              )}

              {filterConfig?.type === "boolean" && (
                <Controller
                  name={`${filterKey}.value` as keyof QualityControlFilterData}
                  control={control}
                  render={({ field }) => (
                    <ButtonSelectField
                      label={I18n.t("js.shared.value")}
                      value={field.value}
                      onChange={field.onChange}
                    >
                      <ButtonSelect.Option value={true}>
                        {I18n.t("js.shared.yes")}
                      </ButtonSelect.Option>
                      <ButtonSelect.Option value={false}>
                        {I18n.t("js.shared.no")}
                      </ButtonSelect.Option>
                    </ButtonSelectField>
                  )}
                />
              )}

              {filterConfig?.type === "crop" && (
                <div className={stack({ gap: 4 })}>
                  <Controller
                    name={
                      `${filterKey}.value.category` as keyof QualityControlFilterData
                    }
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectField
                        {...field}
                        {...fieldState}
                        value={(field.value as string) ?? ""}
                        label="Category"
                      >
                        <Select.Option key="EMPTY_OPTION" value="">
                          {I18n.t("js.shared.all")}
                        </Select.Option>

                        {filterConfig.categories.map(({ value, label }) => (
                          <Select.Option key={value} value={value}>
                            {label}
                          </Select.Option>
                        ))}
                      </SelectField>
                    )}
                  />

                  <Controller
                    name={
                      `${filterKey}.value.identifier` as keyof QualityControlFilterData
                    }
                    control={control}
                    render={({ field, fieldState }) => (
                      <ComboBoxField
                        {...field}
                        {...fieldState}
                        value={(field.value as string) ?? ""}
                        label="Identifier"
                        loadingText={I18n.t("js.shared.loading")}
                        emptyText={I18n.t("js.shared.no_matching_results")}
                      >
                        {uniqueCropTypes?.map(({ identifier, name }) => (
                          <ComboBox.Item key={identifier}>{name}</ComboBox.Item>
                        ))}
                      </ComboBoxField>
                    )}
                  />

                  <div className={box()}>
                    <InputField
                      {...register(
                        `${filterKey}.value.range.min` as keyof QualityControlFilterData,
                      )}
                      step={0.01}
                      type="number"
                      label={I18n.t("js.shared.from")}
                    />

                    <InputField
                      {...register(
                        `${filterKey}.value.range.max` as keyof QualityControlFilterData,
                      )}
                      step={0.01}
                      type="number"
                      label={I18n.t("js.shared.to")}
                    />
                  </div>

                  {filterConfig?.rangeUnit && <em>{filterConfig.rangeUnit}</em>}
                </div>
              )}

              {filterConfig?.type === "fertiliser" && (
                <div className={stack({ gap: 4 })}>
                  <Controller
                    name={
                      `${filterKey}.value.category` as keyof QualityControlFilterData
                    }
                    control={control}
                    render={({ field, fieldState }) => (
                      <SelectField
                        {...field}
                        {...fieldState}
                        value={(field.value as string) ?? ""}
                        label={I18n.t("js.shared.value")}
                      >
                        <Select.Option key="EMPTY_OPTION" value="">
                          {I18n.t("js.shared.all")}
                        </Select.Option>

                        {filterConfig.categories.map(({ value, label }) => (
                          <Select.Option key={value} value={value}>
                            {label}
                          </Select.Option>
                        ))}
                      </SelectField>
                    )}
                  />

                  <div className={box()}>
                    <InputField
                      {...register(
                        `${filterKey}.value.range.min` as keyof QualityControlFilterData,
                      )}
                      step={0.01}
                      type="number"
                      label={I18n.t("js.shared.from")}
                    />

                    <InputField
                      {...register(
                        `${filterKey}.value.range.max` as keyof QualityControlFilterData,
                      )}
                      step={0.01}
                      type="number"
                      label={I18n.t("js.shared.to")}
                    />
                  </div>

                  {filterConfig?.rangeUnit && <em>{filterConfig.rangeUnit}</em>}
                </div>
              )}

              {filterConfig?.type === "fuel" && (
                <div className={stack({ gap: 4 })}>
                  <div className={box()}>
                    <InputField
                      {...register(
                        `${filterKey}.value.range.min` as keyof QualityControlFilterData,
                      )}
                      step={0.01}
                      type="number"
                      label={I18n.t("js.shared.from")}
                    />

                    <InputField
                      {...register(
                        `${filterKey}.value.range.max` as keyof QualityControlFilterData,
                      )}
                      step={0.01}
                      type="number"
                      label={I18n.t("js.shared.to")}
                    />
                  </div>

                  {filterConfig?.rangeUnit && <em>{filterConfig.rangeUnit}</em>}
                </div>
              )}
            </div>
          );
        })}

        {availableFilters.length > 0 && (
          <div className={box({ justify: "between", align: "end" })}>
            <div style={{ flex: 1 }}>
              <SelectField
                label={I18n.t("js.shared.column")}
                value={newFilter ?? ""}
                onChange={key => setNewFilter(key as CheckFilterKey)}
              >
                {availableFilters.map(filterKey => (
                  <Select.Option key={filterKey} value={filterKey}>
                    {filtersConfig[filterKey].label}
                  </Select.Option>
                ))}
              </SelectField>
            </div>

            <IconButton
              icon="plus"
              onClick={handleAddNewFilter}
              disabled={!newFilter}
            />
          </div>
        )}
      </fieldset>
    </>
  );
};
