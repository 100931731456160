import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateAdminsQueryKey } from "./get-admins";

type AdminUserAssignmentData = {
  adminId: string;
  userIds: string[];
};

const deleteAdminUserAssignments = async (payload: AdminUserAssignmentData) => {
  await axios.request({
    url: "/api/v1/admins/user-assignments",
    method: "DELETE",
    data: payload,
    apiSource: "node-user-mgt",
  });
};

export const useDeleteAdminUserAssignmentsMutation = () =>
  useMutation(deleteAdminUserAssignments, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateAdminsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
