import BackButton from "~components/BackButton";
import FileUploader from "~components/file-uploader";
import { BoundaryStatuses } from "~features/boundary-verification";
import { useUpdateFieldBoundaryStatus } from "~features/boundary-verification/api/update-field-bounary-status";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

// This is a one time use hacky workaround to update multiple boundary statuses
// (there is no bulk update endpoint)

const BulkBoundaryStatusUpdates = () => {
  const updateFieldBoundaryStatus = useUpdateFieldBoundaryStatus();

  const processBatch = async (batch: string[]) => {
    const promises = batch.map(fieldId =>
      updateFieldBoundaryStatus.mutateAsync({
        carbonFieldId: fieldId,
        newStatus: BoundaryStatuses.FLAG_DETECTED_BUT_OVERRIDDEN,
      }),
    );

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  };

  const onUploadFile = async (file: File) => {
    const reader = new FileReader();

    reader.onload = async event => {
      const content = event.target?.result;
      if (typeof content === "string") {
        const rows = content.split("\n").map(row => row.trim());
        const fieldIds = rows
          .map(row => row.split(",")[0]) // field ids need to be in the first column
          .filter(fieldId => fieldId && !Number.isNaN(Number(fieldId)));

        // csv will contain thousands of IDs so processing in batches
        const batchSize = 50;
        for (let i = 0; i < fieldIds.length; i += batchSize) {
          const batch = fieldIds.slice(i, i + batchSize);
          await processBatch(batch);
        }
      } else {
        alert("Failed to read file content");
      }
    };

    reader.onerror = () => {
      alert("Error reading file");
    };

    reader.readAsText(file);
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle>
          Boundary status - bulk update
        </ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <div className="flex w-full flex-col gap-8">
            <FileUploader
              onFileDropSuccess={onUploadFile}
              fileDropHeaderText={
                "Drag and drop the csv file. The field IDs must be in the first column"
              }
              fileDropInfoText={"File must be csv format"}
              acceptedFileType=".csv"
            />
          </div>
        </ListLayout.Header>
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default BulkBoundaryStatusUpdates;
