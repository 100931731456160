import { z } from "zod";

export const ObjectChangeSchema = z.record(
  z.string(),
  z.tuple([z.unknown(), z.unknown()]),
);

export const FieldDefinitionChangeScema = z.object({
  event: z.string(),
  referer: z.string(),
  whodunnit: z.string(),
  objectChanges: ObjectChangeSchema,
  itemType: z.string(),
  itemId: z.number(),
  transactionId: z.number(),
  createdAt: z.string(),
  admin: z.object({
    id: z.number(),
    email: z.string(),
  }),
  user: z.object({
    id: z.number(),
    email: z.string(),
    name: z.string(),
  }),
});

export type FieldDefinitionChange = z.infer<typeof FieldDefinitionChangeScema>;
