import { CellContext } from "@tanstack/react-table";
import { useState } from "react";

import { OptionalObject } from "@ag/utils/types";

import { Button, ButtonIconProps } from "~atoms";
import { Modal } from "~organisms";

export type LinkCellConfig = {
  url: string;
  title: string | number;
  isExternal?: boolean;
} & OptionalObject<ButtonIconProps>;

export type LinkCellValue =
  | LinkCellConfig
  | LinkCellConfig[]
  | null
  | undefined;

export const LinkCell = <TValue extends LinkCellValue>({
  getValue,
  table,
}: CellContext<any, TValue>) => {
  const [isAllValuesModalOpen, setIsAllValuesModalOpen] = useState(false);

  const rawValue = getValue();
  const texts = table.options.meta?.texts;

  if (!texts?.listOfAllValues || !texts?.more || !texts?.close) {
    throw new Error("Please provide table texts when using LinkCell");
  }

  if (!rawValue) return <>-</>;

  // Make sure to always work with an array
  const value = (
    Array.isArray(rawValue) ? rawValue : [rawValue]
  ) as LinkCellConfig[];

  if (value.length === 0) return <>-</>;

  const hasMore = value.length > 3;

  return (
    <div className="inline-flex content-start items-center gap-2">
      <div className="flex gap-2">
        {value.slice(0, 3).map(({ url, isExternal, title, ...rest }) => {
          if (isExternal) {
            return (
              <Button
                key={url}
                variant="text"
                size="x-small"
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                {...rest}
              >
                {title}
              </Button>
            );
          }

          return (
            <Button key={url} variant="text" size="x-small" to={url} {...rest}>
              {title}
            </Button>
          );
        })}
      </div>

      {hasMore && (
        <Button
          size="x-small"
          variant="text"
          onClick={() => setIsAllValuesModalOpen(true)}
        >
          +{value.length - 3} {texts?.more}
        </Button>
      )}

      <Modal.Root
        isOpen={isAllValuesModalOpen}
        onRequestClose={() => setIsAllValuesModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>{texts?.listOfAllValues}</Modal.Title>
        </Modal.Header>

        <Modal.Content>
          <div className="flex flex-wrap gap-2">
            {value.map(({ url, isExternal, title, ...rest }) => {
              if (isExternal) {
                return (
                  <Button
                    key={url}
                    variant="text"
                    size="x-small"
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    {...rest}
                  >
                    {title}
                  </Button>
                );
              }

              return (
                <Button
                  key={url}
                  variant="text"
                  size="x-small"
                  to={url}
                  {...rest}
                >
                  {title}
                </Button>
              );
            })}
          </div>
        </Modal.Content>

        <Modal.Footer
          render={close => (
            <Button variant="secondary" onClick={close}>
              {texts?.close}
            </Button>
          )}
        />
      </Modal.Root>
    </div>
  );
};
