import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import {
  ValidationRequestDetails,
  ValidationRequestDetailsSchema,
} from "../entities/validation-request-details";

export const generateValidationRequestQueryKey = (id: string) =>
  ["validation-request", id].filter(Boolean);

const getValidationRequest = async (validationRequestId: string) => {
  const response = await axios.get<CommonResponse<ValidationRequestDetails>>(
    `/api/v1/carbon-validation-requests/${validationRequestId}`,
    {
      schema: ValidationRequestDetailsSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useValidationRequestQuery = (id: string) =>
  useQuery(generateValidationRequestQueryKey(id), () =>
    getValidationRequest(id),
  );
