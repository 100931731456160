import { Confirmation, Modal } from "@ag/design-system/organisms";

type DeleteConfirmationProps = {
  isOpen: boolean;
  isDeleting?: boolean;
  userToDelete: {
    email: string;
  } | null;
  onHide: () => void;
  onConfirm: () => void;
};

export const DeleteUserConfirmation = ({
  isOpen,
  userToDelete,
  isDeleting,
  onHide,
  onConfirm,
}: DeleteConfirmationProps) => (
  <Modal.Root className="p-0" isOpen={isOpen} onRequestClose={onHide}>
    <Modal.Content>
      <Confirmation.Root variant="warning">
        <Confirmation.Title>Delete user</Confirmation.Title>

        <Confirmation.Description>
          Are you sure you want to delete
          {""} &quot;{userToDelete?.email ?? ""}&quot;
        </Confirmation.Description>

        <Confirmation.Actions>
          <Confirmation.Cancel onClick={onHide}>Cancel</Confirmation.Cancel>

          <Confirmation.Confirm
            isLoading={isDeleting}
            onClick={() => onConfirm()}
          >
            Delete
          </Confirmation.Confirm>
        </Confirmation.Actions>
      </Confirmation.Root>
    </Modal.Content>
  </Modal.Root>
);
