import { box } from "@ag/design-system/utils";
import I18n from "@ag/i18n";

import DataCard from "~components/data-card";

import { Field } from "../../entities/field";
import * as styles from "./calculations-overview.css";

type Props = {
  field: Field;
};

export const CalculationsOverview = ({ field }: Props) => {
  const { carbonFieldDefinition: fieldDefinition } = field;

  return (
    <section className={box({ align: "start" })}>
      <DataCard.Root className={styles.fieldDefinition}>
        <DataCard.Title>{I18n.t("js.carbon.field_definition")}</DataCard.Title>

        <DataCard.Row>
          <DataCard.Label>{I18n.t("js.carbon.base_line")}</DataCard.Label>
          <DataCard.Value>
            {fieldDefinition.baseline ?? "-"}{" "}
            {I18n.t("js.carbon.tonnes_per_hectare")}
          </DataCard.Value>
        </DataCard.Row>
      </DataCard.Root>
    </section>
  );
};
