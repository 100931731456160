import { z } from "zod";

/**
 * MISC
 */
export enum MethodologyVersion {
  V1 = "1",
  V2 = "2",
}

export const CropProtectionSchema = z.object({
  category: z.string(),
  numberOfDoses: z.number(),
  protectionType: z.string(),
  applicationRate: z.number().nullable(),
  activeIngredient: z.number().nullable(),
});

export const CarbonFertiliserSchema = z.object({
  chemicalSpecification: z.boolean(),
  createdAt: z.string(),
  harvestYear: z.number(),
  id: z.number(),
  identifier: z.string(),
  methodologyVersion: z.nativeEnum(MethodologyVersion),
  name: z.string(),
  nitrificationAdded: z.boolean().nullable(),
  nitrogenTotal: z.number(),
  organicCarbon: z.number().nullable(),
  tradeName: z.string().nullable(),
  updatedAt: z.string(),
});

export const FertiliserSchema = z.object({
  applicationMode: z.string(),
  applicationRate: z.number(),
  emissionsInhibitors: z.string(),
  carbonFertiliserId: z.number(),
  weightOrUnit: z.string(),
  carbonFertiliser: z.optional(CarbonFertiliserSchema),
});

export const CarbonCropTypeSchema = z.object({
  activeOnFieldDefinition: z.boolean().optional(),
  createdAt: z.string(),
  id: z.number(),
  identifier: z.string(),
  coolFarmName: z.string(),
  netGross: z.number(),
  residueAmount: z.number().nullable(),
  showResidue: z.boolean(),
  updatedAt: z.string(),
});

export const PotentialSchema = z.object({
  agronomic: z.number().nullable(),
  footprint: z.number().nullable(),
  agronomicGrossPotentialPerHa: z.number().nullable(),
  agronomicGrossPotentialTotal: z.number().nullable(),
  agronomicNetPotentialPerHa: z.number().nullable(),
  agronomicNetPotentialTotal: z.number().nullable(),
  footprintTotal: z.number().nullable(),
});

export type Potential = z.infer<typeof PotentialSchema>;
export type CropProtection = z.infer<typeof CropProtectionSchema>;
export type Fertiliser = z.infer<typeof FertiliserSchema>;
export type CarbonFertiliser = z.infer<typeof CarbonFertiliserSchema>;
export type CarbonCropType = z.infer<typeof CarbonCropTypeSchema>;

export enum BaselineVersion {
  V1 = "1",
  V2 = "2",
}

export enum FertiliserMode {
  Organic = "organic",
  Synthetic = "synthetic",
}

export enum FertiliserEmissionsInhibitors {
  Nitrification = "Nitrification",
  None = "None",
}
