import { Locale } from "~types";

import { instance } from "./instance";

const LOCALES_SORTED: Record<Locale, Locale> = {
  [Locale.Cs]: Locale.Cs, // Česky
  [Locale.Da]: Locale.Da, // Dansk
  [Locale.De]: Locale.De, // Deutsch
  [Locale.Et]: Locale.Et, // Eesti
  [Locale.En]: Locale.En, // English
  [Locale.Es]: Locale.Es, // Español
  [Locale.Fr]: Locale.Fr, // Français
  [Locale.Lv]: Locale.Lv, // Latviešu
  [Locale.Lt]: Locale.Lt, // Lietuvių
  [Locale.Hu]: Locale.Hu, // Magyar
  [Locale.Pl]: Locale.Pl, // Polski
  [Locale.Ro]: Locale.Ro, // Română
  [Locale.Sk]: Locale.Sk, // Slovenský
  [Locale.Sv]: Locale.Sv, // Swedish
  [Locale.Fi]: Locale.Fi, // Suomalainen
  [Locale.Bg]: Locale.Bg, // Български
  [Locale.Uk]: Locale.Uk, // Українська
  [Locale.Kk]: Locale.Kk, // Қазақша
};

const REGIONS: Record<string, Locale> = {
  BG: Locale.Bg,
  CZ: Locale.Cs,
  DE: Locale.De,
  DK: Locale.Da,
  ES: Locale.Es,
  ET: Locale.Et,
  FI: Locale.Fi,
  FR: Locale.Fr,
  GB: Locale.En,
  HU: Locale.Hu,
  KZ: Locale.Kk,
  LT: Locale.Lt,
  LV: Locale.Lv,
  PL: Locale.Pl,
  RO: Locale.Ro,
  SE: Locale.Sv,
  SK: Locale.Sk,
  UA: Locale.Uk,
};

export const getRegions = () => Object.keys(REGIONS);

export const getRegionLocale = (region: keyof typeof REGIONS) =>
  REGIONS[region];

export const getLocales = () => Object.values(LOCALES_SORTED);

export const getLocalesWithName = (): Record<Locale, string> => {
  const translations = {
    [Locale.Da]: instance.t("js.locales.danish"),
    [Locale.En]: instance.t("js.locales.english"),
    [Locale.Fr]: instance.t("js.locales.french"),
    [Locale.De]: instance.t("js.locales.german"),
    [Locale.Lt]: instance.t("js.locales.lithuanian"),
    [Locale.Pl]: instance.t("js.locales.polish"),
    [Locale.Ro]: instance.t("js.locales.romanian"),
    [Locale.Sk]: instance.t("js.locales.slovak"),
    [Locale.Sv]: instance.t("js.locales.swedish"),
    [Locale.Es]: instance.t("js.locales.spanish"),
    [Locale.Uk]: instance.t("js.locales.ukrainian"),
    [Locale.Et]: instance.t("js.locales.estonian"),
    [Locale.Bg]: instance.t("js.locales.bulgarian"),
    [Locale.Cs]: instance.t("js.locales.czech"),
    [Locale.Hu]: instance.t("js.locales.hungarian"),
    [Locale.Fi]: instance.t("js.locales.finnish"),
    [Locale.Lv]: instance.t("js.locales.latvian"),
    [Locale.Kk]: instance.t("js.locales.kazakh"),
  };

  return getLocales().reduce(
    (lookup, locale) => ({
      ...lookup,
      [locale]: translations[locale as Locale],
    }),
    {} as Record<Locale, string>,
  );
};

export const getLocaleName = (locale: Locale) => getLocalesWithName()[locale];

export const getLangLocale = (lang: string | null | undefined) =>
  Object.values(Locale).find(value => value === lang);
