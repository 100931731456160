import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";
import { Row } from "react-table";

import {
  ChipCell,
  ChipCellValue,
  LinkCell,
  LinkCellConfig,
  LinkCellValue,
  TextCell,
} from "@ag/design-system/organisms";

import { TableSortingState, useTable } from "~components/table";
import { getCountryWithFlag } from "~helpers/countries";

import { ResaleAgreement } from "../entities/resale-agreement";
import { getContractStatusChip } from "../helpers/resale-agreement-statuses";

type TableData = {
  userId: LinkCellValue | string;
  id: LinkCellConfig;
  status: ChipCellValue | null;
  year: number;
  signerName: string;
  company: string;
  country: string;

  resaleAgreement: ResaleAgreement;
};

export type ActionColumnProps = {
  row: Row<TableData>;
};

export const useResaleAgreementsTable = (
  data: ResaleAgreement[] | undefined,
  state: TableSortingState,
  hasUserPermissions: boolean,
) => {
  const columns = useMemo(
    () => getColumns(hasUserPermissions),
    [hasUserPermissions],
  );
  const rowData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: original => String(original.id?.title),
    state: {
      sorting: state.sorting,
    },
    onSortingChange: state.setSorting,
  });
};

function getColumns(hasUserPermissions: boolean) {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: "Resale Agreement ID",
      cell: LinkCell,
      enableSorting: true,
    }),
    columnHelper.accessor("userId", {
      header: "User ID",
      cell: hasUserPermissions ? LinkCell : TextCell,
    }),
    columnHelper.accessor("signerName", {
      header: "Signer Name",
    }),
    columnHelper.accessor("company", {
      header: "Company Name",
    }),
    columnHelper.accessor("status", {
      header: "Contract Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("year", {
      header: "Harvest year",
    }),
    columnHelper.accessor("country", {
      header: "Country",
      cell: ({ getValue }) => {
        const country = getValue();
        if (!country) return null;
        const { flag, name } = getCountryWithFlag(country);
        return `${flag} ${name}`;
      },
    }),
  ];
}

function getRowData(
  resaleAgreements: ResaleAgreement[] | undefined,
): TableData[] {
  if (!resaleAgreements) return [];

  return resaleAgreements.map(resaleAgreement => ({
    userId: {
      title: resaleAgreement.userId,
      url: `/users/${resaleAgreement.userId}`,
    },
    id: {
      title: resaleAgreement.id,
      url: `/carbon/agreements/resale/${resaleAgreement.id}`,
    },
    status: resaleAgreement.status
      ? getContractStatusChip(resaleAgreement.status)
      : null,
    year: resaleAgreement.year,
    signerName: resaleAgreement.signerName ?? "-",
    company: resaleAgreement.companyName ?? "-",
    country: resaleAgreement.countryName ?? "-",
    resaleAgreement,
  }));
}
