import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { ToastNotification } from "@ag/utils/services";

/**
 * Implementation of the API solution for the AWS signed URLs:
 * Signed URL won't be provided in the endpoints, so we'll need to fetch it when requesting a file
 */
const File = () => {
  const [signedUrl, setSignedUrl] = useState<string>();

  const [searchParams] = useSearchParams();

  // URL will be provided in the response of the API
  const url = searchParams.get("url");

  // apiSource will have to be provided manually from the place we are redirecting to this page from
  const apiSource = searchParams.get("apiSource");

  useEffect(() => {
    // Validate the params
    if (
      !url ||
      !apiSource ||
      !["node-auth", "node-carbon", "node-markets"].includes(apiSource)
    ) {
      ToastNotification.error(
        "Invalid file reference. Make sure the URL is correct and try again.",
      );
      return;
    }

    const getSignedUrl = async () => {
      try {
        const response = await axios.get(url, {
          apiSource: apiSource as "node-auth" | "node-carbon" | "node-markets",
        });

        setSignedUrl(response.data.success.data);
      } catch (error) {
        ToastNotification.error(error);
      }
    };

    getSignedUrl();
  }, [apiSource, url]);

  if (!signedUrl) return null;

  return (
    <iframe
      src={signedUrl}
      width="100%"
      allowFullScreen
      className="b-0 h-screen w-full"
    />
  );
};

export default File;
