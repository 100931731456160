import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

import { useSessionContext } from "~features/auth";

import {
  MarketsPermissions,
  MarketsPermissionsSchema,
} from "../entities/markets-permissions";

const getMarketsPermissions = async () => {
  const { data } = await axios.get<
    CommonResponse<{
      permissions: MarketsPermissions;
    }>
  >("/api/v1/admins/me/permissions", {
    schema: z.object({
      permissions: MarketsPermissionsSchema,
    }),
    apiSource: "node-markets",
  });

  return data.success!.data.permissions;
};

export const useMarketsPermissions = () => {
  const { isSignedIn } = useSessionContext();

  return useQuery(["markets-permissions"], getMarketsPermissions, {
    enabled: isSignedIn,
    staleTime: Infinity,
    retry: false,
  });
};
