import { isValidElementType } from "react-is";

export const isComponent = (component: unknown): component is React.FC => {
  if (typeof component === "string") {
    return false;
  }

  return isValidElementType(component);
};

export const hasAllRequiredAttrsDefined = <T extends Record<string, unknown>>(
  data: Record<string, unknown>,
  requiredAttributes: string[],
): data is T =>
  requiredAttributes.every(attribute => data[attribute] !== undefined);
