import { z } from "zod";

import { ResaleContractStatus } from "./resale-agreement";

export const ResaleAgreementFiltersSchema = z
  .object({
    userId: z.string(),
    signerName: z.string(),
    status: z.nativeEnum(ResaleContractStatus).or(z.literal("")),
    year: z.string().refine(value => value === "" || /^\d{4}$/.test(value), {
      message: "Please provide a valid year",
    }),
    countryCode: z.string(),
  })
  .partial();

export type ResaleAgreementFilters = z.infer<
  typeof ResaleAgreementFiltersSchema
>;
