import { useState } from "react";

import { Button } from "@ag/design-system/atoms";

import { AddNewCropModal, useCropsQuery } from "~features/crops";
import { AuthorizedSidebar } from "~features/navigation";
import { withFeatureFlagAccess } from "~hocs/withFeatureFlagAccess";
import ListLayout from "~layouts/list-layout";

export const Crops = () => {
  const [newCropModalOpen, setNewCropModalOpen] = useState(false);
  const { data } = useCropsQuery();

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Crops</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        {/* TODO => Filters */}
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <ListLayout.Actions>
            <Button onClick={() => setNewCropModalOpen(true)}>
              Add new crop
            </Button>
          </ListLayout.Actions>
        </ListLayout.Header>

        <AddNewCropModal
          isOpen={newCropModalOpen}
          onClose={() => setNewCropModalOpen(false)}
        />

        {/* TODO: Crops Table with HY tabs */}
        {data?.map(crop => (
          <div key={crop.id}>
            {crop.name}: <a href={`crops/${crop.id}`}>{crop.id}</a>
          </div>
        ))}
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default withFeatureFlagAccess("crops-v2", "on", {
  redirectPath: "/carbon/crop-types",
})(Crops);
