import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

export const getCertificateFile = async (staticFileId: string) => {
  const response = await axios.get<CommonResponse<string>>(
    `/api/v1/certificates/files/${staticFileId}`,
    {
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};
