import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { Select } from "@ag/design-system/molecules";
import { parseSorting } from "@ag/design-system/organisms";
import { InputField, SelectField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import {
  ContractStatus,
  ContractType,
  CreditingPeriodYears,
  getContractStatusLabel,
  getContractTypeLabel,
  useContractsQuery,
  useContractsTable,
} from "~features/contract";
import {
  ContractFilters,
  ContractFiltersSchema,
} from "~features/contract/entities/contract";
import { useYearsDataQuery } from "~features/initial-resources";
import { AuthorizedSidebar } from "~features/navigation";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const Contracts = () => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const [query, setQuery] = useQueryParams({
    contractType: withDefault(StringParam, undefined),
    creditingPeriod: withDefault(StringParam, undefined),
    status: withDefault(StringParam, undefined),
    year: withDefault(StringParam, undefined),
    docusignEnvelopeId: withDefault(StringParam, undefined),
    companyVatNumber: withDefault(StringParam, undefined),
    signerEmail: withDefault(StringParam, undefined),
    signerPhoneNumber: withDefault(StringParam, undefined),
    userId: withDefault(StringParam, undefined),
  });

  const { data: yearsData } = useYearsDataQuery();

  const { data: contractsData, isLoading } = useContractsQuery({
    ...pagination,
    sort: parseSorting(sorting),
    filters: {
      contractType: query.contractType
        ? [query.contractType as ContractType]
        : undefined,
      creditingPeriod: query.creditingPeriod
        ? [Number(query.creditingPeriod)]
        : undefined,
      status: query.status ? [query.status as ContractStatus] : undefined,
      year: query.year ? [Number(query.year)] : undefined,
      docusignEnvelopeId: query.docusignEnvelopeId
        ? [query.docusignEnvelopeId]
        : undefined,
      companyVatNumber: query.companyVatNumber
        ? [query.companyVatNumber]
        : undefined,
      signerEmail: query.signerEmail ? [query.signerEmail] : undefined,
      signerPhoneNumber: query.signerPhoneNumber
        ? [query.signerPhoneNumber]
        : undefined,
      userId: query.userId ? [query.userId] : undefined,
    },
  });

  const renderFilterBarItem = (
    key: keyof ContractFilters,
    value: ContractFilters[keyof ContractFilters],
  ) => {
    const label = (
      {
        contractType: "Contract type",
        creditingPeriod: "Crediting period in years",
        status: "Status",
        year: "Year",
        docusignEnvelopeId: "DocuSign ID",
        companyVatNumber: "Company VAT number",
        signerEmail: "Signer email",
        signerPhoneNumber: "Signer phone number",
        userId: "User ID",
      } as unknown as Record<keyof ContractFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContractFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(ContractFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<ContractFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );
  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  const table = useContractsTable(contractsData?.items, {
    sorting,
    setSorting,
  });

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Contracts</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField
            {...register("signerEmail")}
            label="Signer email"
            error={errors.signerEmail}
          />

          <Controller
            name="status"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Status"
                optionsClassName="z-modal"
              >
                {Object.values(ContractStatus).map(status => (
                  <Select.Option key={status} value={status}>
                    {getContractStatusLabel(status)}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          {yearsData && (
            <Controller
              name="year"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  {...fieldState}
                  value={field.value ?? ""}
                  label="Year"
                  optionsClassName="z-modal"
                >
                  {yearsData.allHarvestYears.map(year => (
                    <Select.Option key={year} value={String(year)}>
                      {year}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          )}

          <InputField
            {...register("docusignEnvelopeId")}
            label="DocuSign ID"
            error={errors.docusignEnvelopeId}
          />

          <Controller
            name="contractType"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={field.value ?? ""}
                label="Contract type"
                optionsClassName="z-modal"
              >
                <>
                  <Select.OptionAll>All</Select.OptionAll>

                  {Object.values(ContractType).map(type => (
                    <Select.Option key={type} value={type}>
                      {getContractTypeLabel(type)}
                    </Select.Option>
                  ))}
                </>
              </SelectField>
            )}
          />

          <Controller
            name="creditingPeriod"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                value={String(field.value)}
                label="Crediting period"
                optionsClassName="z-modal"
              >
                {CreditingPeriodYears.map(period => (
                  <Select.Option key={period} value={period.toString()}>
                    {period} years
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <InputField {...register("userId")} label="User ID" />

          <InputField
            {...register("companyVatNumber")}
            label="Company VAT number"
            error={errors.companyVatNumber}
          />

          <InputField
            {...register("signerPhoneNumber")}
            label="Signer phone number"
            error={errors.signerPhoneNumber}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={contractsData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Contracts;
