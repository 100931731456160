import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { UpdateRequest } from "../entities/update-request";

const submitUpdateRequest = async (updateRequestId: string) => {
  const response = await axios.post<CommonResponse<UpdateRequest>>(
    `/api/v1/certificates/groups/update-requests/${updateRequestId}/approve`,
    {},
    {
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useSubmitUpdateRequestMutation = () =>
  useMutation(submitUpdateRequest, {
    onError: error => ToastNotification.error(error),
  });
