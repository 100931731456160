import { z } from "zod";

import { Locale } from "@ag/i18n";

import { AdminRole } from "../types/roles";

export const AdminUserAssignmentSchema = z.object({
  createdAt: z.string(),
  user: z.object({
    company: z.object({
      name: z.string(),
      address: z.object({
        countryCode: z.string(),
      }),
      vatNumber: z.string(),
    }),
    profile: z.object({
      firstName: z.string(),
      lastName: z.string(),
    }),
    id: z.string(),
    email: z.string(),
  }),
});

export type AdminUserAssignment = z.infer<typeof AdminUserAssignmentSchema>;

export const AdminRoleDataSchema = z.object({
  id: z.string(),
  name: z.nativeEnum(AdminRole),
});

export const AdminSchema = z.object({
  id: z.string(),
  email: z.string(),
  locale: z.nativeEnum(Locale),
  discardedAt: z.string().datetime().nullable(),
  adminRoles: z.array(AdminRoleDataSchema),
});

export type AdminRoleData = z.infer<typeof AdminRoleDataSchema>;

export type Admin = z.infer<typeof AdminSchema>;

export const AdminFiltersSchema = z.object({
  email: z.string().optional(),
  adminRoles: z.string().optional(),
});

export type AdminFilters = z.infer<typeof AdminFiltersSchema>;
