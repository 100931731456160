import { useCallback, useContext, useMemo } from "react";

import { generateUniqueId } from "~helpers";

import { DialogComponent, DialogContext } from "./context";

export const useDialog = (content: DialogComponent) => {
  const context = useContext(DialogContext);

  if (context === undefined) {
    throw new Error("useDialog must be used within a DialogProvider");
  }

  const key = useMemo(generateUniqueId, []);

  const show = useCallback(
    () => context.show(key, content),
    [content, context, key],
  );
  const hide = useCallback(() => context.hide(key), [context, key]);

  const memoizedDialog = useMemo(
    () => ({
      show,
      hide,
    }),
    [hide, show],
  );

  return memoizedDialog;
};
