import { z } from "zod";

import { ValidationRequestStatus } from "../types";

export const ValidationRequestDetailsSchema = z.object({
  id: z.string(),
  status: z.nativeEnum(ValidationRequestStatus),
  carbonUserId: z.string(),
  carbonFields: z.array(
    z.object({
      id: z.string(),
      carbonFarm: z.object({
        id: z.string(),
        name: z.string(),
      }),
    }),
  ),
  carbonUser: z.object({
    userId: z.string(),
  }),
});

export type ValidationRequestDetails = z.infer<
  typeof ValidationRequestDetailsSchema
>;
