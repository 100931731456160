import I18n from "@ag/i18n";

import { FlagValue } from "~components/flag-select";

export enum CheckFilterKey {
  Fertiliser = "fertiliser",
  Crop = "crop",
  CoverCrop = "cover_crop",
  ResidueManagement = "residue_management",
  SoilDisturbance = "soil_disturbance",
  Fuel = "fuel",
}

type FilterConfig = {
  label: string;
} & (
  | {
      flag: false;
    }
  | {
      flag: true;
      disabledFlags?: FlagValue[];
    }
) &
  (
    | {
        type: "empty";
      }
    | {
        type: "range";
        rangeUnit?: string;
      }
    | {
        type: "boolean";
      }
    | {
        type: "select";
        options: {
          label: string;
          value: string;
        }[];
      }
    | {
        type: "crop";
        categories: {
          label: string;
          value: string;
        }[];
        rangeUnit: string;
      }
    | {
        type: "fertiliser";
        categories: {
          label: string;
          value: string;
        }[];
        rangeUnit: string;
      }
    | {
        type: "fuel";
        rangeUnit?: string;
      }
  );

export const getFiltersConfig = (): Record<CheckFilterKey, FilterConfig> => {
  return {
    [CheckFilterKey.CoverCrop]: {
      label: I18n.t("js.admin.quality_control.category.cover_crop"),
      flag: true,
      type: "select",
      disabledFlags: ["red", "none"],
      options: [
        {
          label: I18n.t("js.admin.assurance.no_cover_crop"),
          value: "No",
        },
        {
          label: I18n.t("js.admin.assurance.cover_crop_spring"),
          value: "Until spring",
        },
        {
          label: I18n.t("js.admin.assurance.cover_crop_winter"),
          value: "Until winter",
        },
        {
          label: I18n.t("js.admin.assurance.catch_crop"),
          value: "Catch crop",
        },
      ],
    },
    [CheckFilterKey.Crop]: {
      label: I18n.t("js.admin.quality_control.category.crop"),
      flag: true,
      type: "crop",
      disabledFlags: ["orange", "red", "none"],
      categories: [
        {
          label: "Perennial",
          value: "perennial",
        },
        {
          label: "Winter",
          value: "winter",
        },
        {
          label: "Spring",
          value: "spring",
        },
      ],
      rangeUnit: "yield in tons pr ha.",
    },
    [CheckFilterKey.Fertiliser]: {
      label: I18n.t("js.admin.quality_control.category.fertiliser"),
      flag: true,
      type: "fertiliser",
      categories: [
        {
          label: "Organic",
          value: "organic",
        },
        {
          label: "Synthetic",
          value: "synthetic",
        },
        {
          label: "Mixed",
          value: "mixed",
        },
      ],
      disabledFlags: ["red", "none"],
      rangeUnit: "kg N/ha",
    },
    [CheckFilterKey.ResidueManagement]: {
      label: I18n.t("js.admin.quality_control.category.residue_management"),
      flag: true,
      type: "select",
      disabledFlags: ["blue", "orange", "none"],
      options: [
        {
          label: "Removed",
          value: "removed",
        },
        {
          label: "Mulched",
          value: "mulched",
        },
        {
          label: "Burned",
          value: "burned",
        },
      ],
    },
    [CheckFilterKey.SoilDisturbance]: {
      label: I18n.t("js.admin.quality_control.category.soil_disturbance"),
      flag: true,
      type: "select",
      disabledFlags: ["orange", "red", "none"],
      options: [
        {
          label: "No-till",
          value: "No tillage",
        },
        {
          label: "Reduced",
          value: "Reduced tillage",
        },
        {
          label: "Conventional",
          value: "Conventional tillage",
        },
      ],
    },
    [CheckFilterKey.Fuel]: {
      label: "Fuel",
      flag: true,
      type: "fuel",
      disabledFlags: ["red", "none"],
      rangeUnit: "l/ha",
    },
  };
};
