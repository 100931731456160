import { z } from "zod";

import { Locale } from "@ag/i18n";

import { COUNTRY_CODE } from "~constants/misc";

import { CompanySchema } from "./company";

export const CreateUserDataSchema = z.object({
  email: z.string().email(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  acceptedTerms: z.boolean(),
  acceptedNewsletter: z.boolean().optional(),
  locale: z.nativeEnum(Locale),
  preferredCurrency: z.string().optional(),
  countryCode: z.nativeEnum(COUNTRY_CODE),
});

export type CreateUserData = z.infer<typeof CreateUserDataSchema>;

export const CreateUserResponseSchema = z.object({
  id: z.string(),
  email: z.string(),
  validationState: z.string(),
  startupPage: z.string().nullish(),
  locale: z.string(),
  hubspotContactId: z.number().nullish(),
  confirmedAt: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  preferredCurrency: z.string().nullish(),
  company: CompanySchema.nullable(),
  roles: z.array(z.string()).or(z.tuple([])),
});
export type CreateUserResponse = z.infer<typeof CreateUserResponseSchema>;
