import { z } from "zod";

export const CropTypeSchema = z.object({
  id: z.union([z.string(), z.number()]),
  coolfarmIdentifier: z.number(),
  name: z.string(),
  identifier: z.string(),
  harvestYear: z.number(),
  netGross: z.number().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  showResidue: z.boolean().nullable(),
  residueAmount: z.number().nullable(),
});

export type CropType = z.infer<typeof CropTypeSchema>;
