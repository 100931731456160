import { Params, useParams } from "react-router-dom";

export type WithRouteParamsProps = {
  params?: Params;
};

function withRouteParams<T>(WrappedComponent: React.ComponentType<T>) {
  const WithRouteParamsComponent = ({ ...props }: T) => {
    const params = useParams();

    return <WrappedComponent {...props} params={params} />;
  };

  return WithRouteParamsComponent;
}

export default withRouteParams;
