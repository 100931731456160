import gsap, { Power1 } from "gsap";
import { useLayoutEffect, useRef } from "react";

type SpinnerProps = {
  className?: string;
};

export const Spinner = ({ className }: SpinnerProps) => {
  // create a ref for the root level element (for scoping)
  const rootRef = useRef<SVGSVGElement | null>(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const leaves = rootRef.current?.childNodes ?? [];
      if (!leaves.length) return;

      gsap.to(leaves, {
        rotation: 360,
        // custom gsap property "svgOrigin".
        // makes sure all translations are relative to center,
        svgOrigin: "40 40",

        duration: 0.75,
        // start each leaf animation 125ms apart
        stagger: 0.125,
        // Infinity
        repeat: -1,

        // relatively flat ease,
        // speeds up until halfway, then slows down equally until done.
        ease: Power1.easeInOut,
      });
    }, rootRef);

    return () => ctx.revert();
  }, []);

  return (
    <svg
      ref={rootRef}
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {new Array(3).fill(0).map((_, index) => (
        <path
          key={index}
          fill="currentColor"
          d=" M-0.008999999612569809,-11.932000160217285 C-3.0139999389648438,-8.848999977111816 -4.86899995803833,-4.640999794006348 -4.866000175476074,0.004000000189989805 C-4.861999988555908,4.64900016784668 -3,8.854000091552734 0.009999999776482582,11.932999610900879 C3.0139999389648438,8.850000381469727 4.869999885559082,4.642000198364258 4.867000102996826,-0.003000000026077032 C4.86299991607666,-4.64900016784668 3,-8.852999687194824 -0.008999999612569809,-11.932000160217285z"
          // Offset the path 23 px up from center,
          // so the leaves rotate around a 23px radius circle
          transform="translate(40, 17)"
        />
      ))}
    </svg>
  );
};
