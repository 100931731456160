import { FormApi } from "final-form";
import { memo, useCallback, useContext } from "react";
import { withTypes } from "react-final-form";
import { Element } from "react-scroll";

import { PartialBox } from "@ag/carbon/components";
import {
  AdditionalInformationData,
  AdditionalInformationForm,
} from "@ag/carbon/forms/baseline/iso";
import I18n from "@ag/i18n";

import {
  FieldDefinitionPartial,
  FieldPartialProps,
} from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/steps/FieldDefinition/FieldPartials/types";
import FieldDefinitionContext from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/steps/FieldDefinition/context";

import { usePartial } from "./hooks";

const { Form: FinalForm } = withTypes<AdditionalInformationData>();

type Props = FieldPartialProps;

const AdditionalInformation = ({ field }: Props) => {
  const fieldDefinition = field.carbonFieldDefinition;

  const { setPartialFormAPI } = useContext(FieldDefinitionContext);

  const { initialValues } = usePartial(fieldDefinition);

  const handleFormInitialized = useCallback(
    (form: FormApi<AdditionalInformationData>) => {
      setPartialFormAPI({
        partialName: FieldDefinitionPartial.AdditionalInformation,
        formAPI: form,
      });
    },
    [setPartialFormAPI],
  );

  return (
    <Element name={FieldDefinitionPartial.AdditionalInformation}>
      <FinalForm
        initialValues={initialValues}
        render={() => (
          <PartialBox
            title={I18n.t("js.carbon.additional_information")}
            headerTestTag="additionalInformation-collapseBox"
            isOpen
          >
            <AdditionalInformationForm
              isSubmitButtonVisible={false}
              onFormInitialize={handleFormInitialized}
            />
          </PartialBox>
        )}
        onSubmit={() => undefined}
      />
    </Element>
  );
};

export default memo(AdditionalInformation);
