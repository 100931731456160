import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Select } from "@ag/design-system/molecules";
import { SelectField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { AdminRole } from "~features/admin";
import { transformEnumToLabels } from "~helpers";
import useHasAdminRole from "~hooks/useHasAdminRole";

import { useUpdateBuyoutAgreementPayment } from "../api/update-buyout-agreement-payment";
import {
  BuyoutContractStatus,
  BuyoutPaymentStatus,
} from "../entities/buyout-agreement";

const buyoutPaymentStatusSchema = z.object({
  paymentStatus: z.nativeEnum(BuyoutPaymentStatus),
});

export const PaymentStatusDropdown = ({
  id,
  paymentStatus,
  status,
}: {
  id: string;
  paymentStatus: BuyoutPaymentStatus;
  status: BuyoutContractStatus;
}) => {
  const paymentStatusLabel = transformEnumToLabels(BuyoutPaymentStatus);
  const isFinanceManagerAdmin = useHasAdminRole(AdminRole.FinanceManager);
  const updatePaymentStatusMutation = useUpdateBuyoutAgreementPayment();

  const { control, handleSubmit } = useForm<{
    paymentStatus: BuyoutPaymentStatus;
  }>({
    values: {
      paymentStatus: paymentStatus,
    },
    resolver: zodResolver(buyoutPaymentStatusSchema),
  });

  const onSubmit = (data: { paymentStatus: BuyoutPaymentStatus }) => {
    updatePaymentStatusMutation.mutate(
      { id, ...data },
      {
        onSuccess: () => {
          ToastNotification.success("Payment status updated");
        },
      },
    );
  };

  if (!isFinanceManagerAdmin && status !== BuyoutContractStatus.Signed)
    return null;

  return (
    <div className="w-1/5 pb-8">
      <Controller
        name="paymentStatus"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label="Payment Status"
            optionsClassName="z-modal"
            onChange={e => {
              field.onChange(e);
              handleSubmit(onSubmit)();
            }}
          >
            {Object.values(BuyoutPaymentStatus).map(type => (
              <Select.Option key={type} value={type}>
                {paymentStatusLabel[type]}
              </Select.Option>
            ))}
          </SelectField>
        )}
      />
    </div>
  );
};
