import styled from "@emotion/styled";

import { colorsObject } from "@ag/design-system/tokens";

const size = {
  amountColumnWidth: 170,
};

export const SFertiliserRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
  border-radius: 4px;

  &:nth-of-type(odd) {
    background: ${colorsObject.grey[100]};
  }
`;

export const SInputWrapper = styled.div`
  padding-left: 4px;
  width: ${size.amountColumnWidth}px;
`;

export const SApplicationMethodWrapper = styled.div`
  padding-top: 16px;
`;

export const SChemicalSpecification = styled.div`
  margin: 0 auto;
`;
