import { Card } from "@ag/design-system/organisms";

import { OfferDetails } from "../entities/sales-offer";
import { formatCurrency, formatEstimationsValue } from "../helpers/formatters";
import SalesOfferDetailsTable from "./details-table";

const BuyoutCalculationsCard = ({
  totalPurchaseAmount,
  totalPayableAmount,
  amountWitheldForPremiumPool,
  pricePerRemovalCertificate,
  pricePerReductionCertificate,
  estimatedReductionCertificates,
  estimatedRemovalCertificates,
}: OfferDetails) => (
  <Card className="w-full" testid="buyout-pricing-card">
    <p className="mb-2 text-p2">Buyout pricing & calculations</p>
    <div className="mt-4 flex flex-wrap gap-4 md:grid md:grid-cols-3">
      <div className="col-span-2">
        <SalesOfferDetailsTable
          alignment="1/3"
          rows={[
            {
              label: "Est. farmer net ISO removals",
              value: formatEstimationsValue(estimatedRemovalCertificates),
            },
            {
              label: "Est. farmer net ISO reductions",
              value: formatEstimationsValue(estimatedReductionCertificates),
            },
            {
              label: "Price p/Removal",
              value: `${formatCurrency(pricePerRemovalCertificate)}`,
            },
            {
              label: "Price p/Reduction",
              value: `${formatCurrency(pricePerReductionCertificate)}`,
            },
          ]}
        />
      </div>
      <div className="col-span-1">
        <SalesOfferDetailsTable
          rows={[
            {
              label: "Total purchase amount",
              value: formatCurrency(totalPurchaseAmount),
            },
            {
              label: "Withheld amount",
              value: formatCurrency(amountWitheldForPremiumPool),
            },
            {
              label: "Total payable amount",
              value: formatCurrency(totalPayableAmount),
            },
          ]}
        />
      </div>
    </div>
  </Card>
);

export default BuyoutCalculationsCard;
