import { z } from "zod";

import { GeoJSONGeometrySchema } from "@ag/map/types";

export enum FieldBoundariesTypes {
  MRV = "mrv",
  FARMER = "farmer",
  MANUAL = "manual",
}

export const FieldBoundariesSchema = z.object({
  id: z.string(),
  carbonFieldId: z.string(),
  boundaries: GeoJSONGeometrySchema.nullable(),
  createdAt: z.string(),
  isActive: z.boolean(),
  updatedAt: z.string(),
  sizeHa: z.string(),
  type: z.nativeEnum(FieldBoundariesTypes),
});

export type FieldBoundaries = z.infer<typeof FieldBoundariesSchema>;
