import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import {
  InitialResources,
  InitialResourcesSchema,
} from "../entities/initial-resources";

const getInitialResources = async () => {
  const response = await axios.get<CommonResponse<InitialResources>>(
    "/api/v1/initial-resources",
    {
      schema: InitialResourcesSchema,
      apiSource: "node-user-mgt",
    },
  );

  return response.data.success!.data;
};

export const useInitialResourcesQuery = () =>
  useQuery(["initial-resources"], getInitialResources, {
    staleTime: Infinity,
  });
