import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { Button } from "@ag/design-system/atoms";
import { TextareaField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { SFormSubmitButtonContainer } from "~forms/styled";

import {
  AdditionalInformationAttribute,
  AdditionalInformationV2022Data,
} from "./types";

type Props = {
  isReadonly?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<AdditionalInformationV2022Data>) => void;
};

export const AdditionalInformationForm = ({
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<AdditionalInformationV2022Data>();
  const { pristine } = useFormState<AdditionalInformationV2022Data>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  return (
    <>
      <Field
        name={AdditionalInformationAttribute.Comment}
        render={({ input, meta }) => (
          <TextareaField
            {...input}
            data-testid="additionalInformation-commentInput"
            label={I18n.t("js.carbon.other_feedback")}
            error={
              meta.touched && meta.error ? { message: meta.error } : undefined
            }
            isDisabled={isReadonly}
          />
        )}
        parse={value => value ?? null}
      />

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          <Button
            data-testid="additionalInformation-submitButton"
            type="submit"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </Button>
        </SFormSubmitButtonContainer>
      )}
    </>
  );
};
