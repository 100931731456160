import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";

import { Tabs, useTabs } from "@ag/design-system/organisms";
import { usePagination } from "@ag/utils/hooks";

import BackButton from "~components/BackButton";
import Table from "~components/table";
import { useContractsQuery, useContractsTable } from "~features/contract";
import {
  GenerateCsvButton,
  useFieldsQuery,
  useFieldsTable,
} from "~features/field";
import { AuthorizedSidebar } from "~features/navigation";
import {
  Page,
  getHasPageAccess,
  useAuthPermissions,
  useCarbonPermissions,
  useUserManagementPermissions,
} from "~features/permission";
import {
  UserActions,
  UserOverview,
  useUserActivitiesQuery,
  useUserActivitiesTable,
  useUserQuery,
} from "~features/user";
import {
  mapValidationRequestsToCSVData,
  useValidationRequestsQuery,
  useValidationRequestsTable,
} from "~features/validation-request";
import DetailsLayout from "~layouts/details-layout";

const FieldDetails = () => {
  const params = useParams<{ userId: string }>();

  const { data: carbonPermissions } = useCarbonPermissions();
  const { data: authPermissions } = useAuthPermissions();
  const { data: userManagementPermissions } = useUserManagementPermissions();

  const hasValidationRequestsPermission = getHasPageAccess({
    authPermissions,
    carbonPermissions,
    userManagementPermissions,
    page: Page.ValidationRequests,
  });
  const hasFieldsPermission = getHasPageAccess({
    authPermissions,
    carbonPermissions,
    userManagementPermissions,
    page: Page.Fields,
  });
  const hasContractsPermission = getHasPageAccess({
    authPermissions,
    carbonPermissions,
    userManagementPermissions,
    page: Page.CarbonContracts,
  });

  const [fieldsPagination, updateFieldsPagination] = usePagination({
    pageKey: "fields-page",
    limitKey: "fields-limit",
  });
  const [validationRequestsPagination, updateValidationRequestsPagination] =
    usePagination({
      pageKey: "validation-requests-page",
      limitKey: "validation-requests-limit",
    });
  const [contractsPagination, updateContractsPagination] = usePagination({
    pageKey: "contracts-page",
    limitKey: "contracts-limit",
  });
  const [activitiesPagination, updateActivitiesPagination] = usePagination({
    pageKey: "activities-page",
    limitKey: "activities-limit",
  });

  const { activeTab, onChange: onTabChange } = useTabs({
    initialValue: "fields",
    tabParamName: "data",
    replace: true,
  });

  const { data: user, isLoading: isUserLoading } = useUserQuery(params.userId);

  // Queries for data in the tabs enabled only if the tab is active
  const { data: fields, isLoading: isFieldsLoading } = useFieldsQuery(
    {
      ...fieldsPagination,
      filters: {
        userId: Number(params.userId!),
      },
    },
    { enabled: activeTab === "fields" && hasFieldsPermission },
  );
  const { data: validationRequests, isLoading: isValidationRequestLoading } =
    useValidationRequestsQuery(
      {
        ...validationRequestsPagination,
        filters: {
          userId: params.userId!,
        },
      },
      {
        enabled:
          activeTab === "validation-requests" &&
          hasValidationRequestsPermission,
      },
    );
  const { data: contracts, isLoading: isContractsLoading } = useContractsQuery(
    {
      ...contractsPagination,
      filters: {
        userId: [params.userId!],
      },
    },
    { enabled: activeTab === "contracts" && hasContractsPermission },
  );
  const { data: activities, isLoading: isActivitiesLoading } =
    useUserActivitiesQuery(
      {
        ...activitiesPagination,
        userId: params.userId!,
      },
      { enabled: activeTab === "activities" },
    );

  // Metadata only queries for the badges in the tabs
  const { data: fieldsMeta } = useFieldsQuery(
    {
      limit: 1,
      filters: {
        userId: Number(params.userId!),
      },
    },
    {
      enabled: hasFieldsPermission,
    },
  );
  const { data: validationRequestsMeta } = useValidationRequestsQuery(
    {
      limit: 1,
      filters: {
        userId: params.userId!,
      },
    },
    {
      enabled: hasValidationRequestsPermission,
    },
  );
  const { data: contractsMeta } = useContractsQuery(
    {
      limit: 1,
      filters: {
        userId: [params.userId!],
      },
    },
    {
      enabled: hasContractsPermission,
    },
  );

  const fieldsTable = useFieldsTable(fields?.items);
  const contractsTable = useContractsTable(contracts?.items);
  const validationRequestsTable = useValidationRequestsTable(
    validationRequests?.items,
  );
  const activitiesTable = useUserActivitiesTable(activities?.items);

  const validationRequestsCSVData = mapValidationRequestsToCSVData(
    validationRequests?.items,
  );

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        {user && (
          <DetailsLayout.TopBarTitle>
            User email: {user.email}
          </DetailsLayout.TopBarTitle>
        )}
      </DetailsLayout.TopBar>

      <DetailsLayout.Content style={{ paddingBottom: 0 }}>
        <main className="flex flex-col space-y-4">
          <UserActions user={user} isLoading={isUserLoading} />

          <UserOverview user={user} isLoading={isUserLoading} />

          <Tabs.Root value={activeTab} onChange={onTabChange}>
            <Tabs.List>
              {hasFieldsPermission && (
                <Tabs.Trigger value="fields">
                  <Tabs.TriggerTitle>Fields</Tabs.TriggerTitle>
                  <Tabs.TriggerBadge
                    maxValue={9999}
                    value={fieldsMeta?.meta.totalItems}
                  />
                </Tabs.Trigger>
              )}

              {hasValidationRequestsPermission && (
                <Tabs.Trigger value="validation-requests">
                  <Tabs.TriggerTitle>Validation requests</Tabs.TriggerTitle>
                  <Tabs.TriggerBadge
                    value={validationRequestsMeta?.meta.totalItems}
                  />
                </Tabs.Trigger>
              )}

              {hasContractsPermission && (
                <Tabs.Trigger value="contracts">
                  <Tabs.TriggerTitle>Contracts</Tabs.TriggerTitle>
                  <Tabs.TriggerBadge value={contractsMeta?.meta.totalItems} />
                </Tabs.Trigger>
              )}

              <Tabs.Trigger value="activities">
                <Tabs.TriggerTitle>Activities</Tabs.TriggerTitle>
              </Tabs.Trigger>
            </Tabs.List>

            {hasFieldsPermission && (
              <Tabs.Content value="fields">
                <div className="flex flex-col space-y-4">
                  <div className="flex items-center justify-end space-x-4">
                    <GenerateCsvButton userId={Number(params.userId!)} />
                  </div>

                  <div>
                    <Table
                      instance={fieldsTable}
                      meta={fields?.meta}
                      pagination={fieldsPagination}
                      footerClassName="relative"
                      isLoading={isFieldsLoading}
                      onPaginationChange={updateFieldsPagination}
                    />
                  </div>
                </div>
              </Tabs.Content>
            )}

            {hasValidationRequestsPermission && (
              <Tabs.Content value="validation-requests">
                <div className="flex flex-col space-y-4">
                  <div className="flex items-center justify-end space-x-4">
                    {validationRequestsCSVData && (
                      <CSVLink
                        data={validationRequestsCSVData}
                        filename="carbon-validation-requests.csv"
                        className="rounded border border-accent-200 px-6 py-1.5 text-p2 text-accent-200 no-underline hover:bg-sky-100 hover:text-satellite-100"
                      >
                        Download
                      </CSVLink>
                    )}
                  </div>

                  <div>
                    <Table
                      instance={validationRequestsTable}
                      meta={validationRequests?.meta}
                      pagination={validationRequestsPagination}
                      footerClassName="relative"
                      isLoading={isValidationRequestLoading}
                      onPaginationChange={updateValidationRequestsPagination}
                    />
                  </div>
                </div>
              </Tabs.Content>
            )}

            {hasContractsPermission && (
              <Tabs.Content value="contracts">
                <Table
                  instance={contractsTable}
                  meta={contracts?.meta}
                  pagination={contractsPagination}
                  footerClassName="relative"
                  isLoading={isContractsLoading}
                  onPaginationChange={updateContractsPagination}
                />
              </Tabs.Content>
            )}

            <Tabs.Content value="activities">
              <Table
                instance={activitiesTable}
                meta={activities?.meta}
                pagination={activitiesPagination}
                footerClassName="relative"
                isLoading={isActivitiesLoading}
                onPaginationChange={updateActivitiesPagination}
              />
            </Tabs.Content>
          </Tabs.Root>
        </main>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default FieldDetails;
