import { useEffect } from "react";
import { FieldRenderProps, useForm, useFormState } from "react-final-form";

import {
  Questionnaire,
  QuestionnaireCommonProps,
  QuestionnaireValue,
} from "~Questionnaire";

import { useMapFormQuestionnaireState } from "./hooks";

type Props = FieldRenderProps<QuestionnaireValue> & QuestionnaireCommonProps;

export const FormQuestionnaire = ({ questions, meta, ...rest }: Props) => {
  const form = useForm();
  const {
    values: formValues,
    errors: formErrors,
    dirtyFieldsSinceLastSubmit,
  } = useFormState();
  const [questionnaireValue, questionnaireErrors] =
    useMapFormQuestionnaireState({
      questions,
      formValues,
      formErrors,
      dirtyFieldsSinceLastSubmit,
    });

  // Register all questions from the config as separate fields
  useEffect(() => {
    questions.forEach(question => {
      // Second and third argument are the subscriber and subscriptions config
      form.registerField(question.key, () => null, {});
    });
  }, [form, questions]);

  // Set the values directly on the root form level - don't use field's name nesting
  const handleQuestionnaireValueChanged = (
    key: string,
    value: boolean | string | number | null,
  ) => {
    form.change(key, value);
  };

  return (
    <Questionnaire
      value={questionnaireValue}
      errors={meta.touched ? questionnaireErrors : null}
      questions={questions}
      onChange={handleQuestionnaireValueChanged}
      {...rest}
    />
  );
};
