
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/tokens/animations.css.ts", "@ag/design-system");
      import { keyframes } from "@vanilla-extract/css";
export const slideInRight = keyframes({
  from: {
    transform: "translateX(-100%)"
  }
}, "slideInRight");
export const slideOutLeft = keyframes({
  to: {
    transform: "translateX(-100%)"
  }
}, "slideOutLeft");
export const slideInLeft = keyframes({
  from: {
    transform: "translateX(100%)"
  }
}, "slideInLeft");
export const slideOutRight = keyframes({
  to: {
    transform: "translateX(100%)"
  }
}, "slideOutRight");
export const fadeIn = keyframes({
  from: {
    opacity: "0"
  },
  to: {
    opacity: "1"
  }
}, "fadeIn");
export const fadeOut = keyframes({
  from: {
    opacity: "1"
  },
  to: {
    opacity: "0"
  }
}, "fadeOut");
      endFileScope();
      
    