import { Marker as GoogleMarker } from "@react-google-maps/api";
import { useForm, useFormState } from "react-final-form";

import { EditFieldAttribute, EditFieldData } from "../types";

const Marker = () => {
  const form = useForm<EditFieldData>();
  const { values } = useFormState<EditFieldData>();

  return (
    <GoogleMarker
      draggable
      clickable={false}
      position={{
        lat: Number(values.latitude),
        lng: Number(values.longitude),
      }}
      onDragEnd={event => {
        if (!event.latLng) return;

        form.change(EditFieldAttribute.Latitude, event.latLng.lat());
        form.change(EditFieldAttribute.Longitude, event.latLng.lng());
      }}
    />
  );
};

export default Marker;
