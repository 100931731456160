import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateAdminsQueryKey } from "./get-admins";

type AdminData = {
  email: string;
  password: string;
  passwordConfirmation: string;
  roles: string[];
};

const createAdmin = async (admin: AdminData) => {
  await axios.post(
    "/api/v1/admins",
    {
      ...admin,
      // There is no locale chooser on the UI - all the admins should have "en" locale
      locale: "en",
    },
    {
      apiSource: "node-auth",
    },
  );
};

export const useCreateAdminMutation = () =>
  useMutation(createAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateAdminsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
