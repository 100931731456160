import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { CropCountryProperties } from "../entities/crop-country-details";
import { generateCropCountryDetailsByHarvestYearQueryKey } from "./get-crop-country-harvest-year";

export const createCropCountryProperties = async ({
  id,
  harvestYear,
  country,
  properties,
}: {
  id: string;
  country: string;
  harvestYear: string;
  properties: CropCountryProperties;
}) => {
  await axios.post(
    `/v1/crops/${id}/harvest_year/${harvestYear}/country/${country}`,
    properties,
    {
      apiSource: "crops-fertilisers",
    },
  );
};

export const useCreateCropCountryPropertiesMutation = () =>
  useMutation(createCropCountryProperties, {
    onSuccess: (_, { id, harvestYear, country }) => {
      queryClient.invalidateQueries(
        generateCropCountryDetailsByHarvestYearQueryKey(
          id,
          harvestYear,
          country,
        ),
      );
    },
    onError: error => ToastNotification.error(error),
  });
