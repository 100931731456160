import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import { ButtonCell, ButtonCellValue } from "@ag/design-system/organisms";

import { useTable } from "~components/table";
import { AdminUserAssignment } from "~features/admin/entities/admin";

type TableData = {
  signIn: ButtonCellValue;
  id: string;
  email: string;
  company: string;
  name: string;
};

export const useOwnAssignedUsersTable = (
  data: AdminUserAssignment[] | undefined,
  onSignIn: (userId: string) => void | undefined,
) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(() => getRowData(data, onSignIn), [data, onSignIn]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => String(original.id),
    getCoreRowModel: getCoreRowModel(),
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("signIn", {
      header: "Sign in as user",
      cell: ButtonCell,
    }),
    columnHelper.accessor("id", {
      header: "ID",
    }),
    columnHelper.accessor("email", {
      header: "Email",
    }),
    columnHelper.accessor("name", {
      header: "Name",
    }),
    columnHelper.accessor("company", {
      header: "Company",
    }),
  ];
}

function getRowData(
  users?: AdminUserAssignment[],
  onSignIn?: (userId: string) => void,
): TableData[] {
  if (!users) return [];

  return users.map(assignment => ({
    signIn: {
      onClick: () => onSignIn && onSignIn(assignment.user.id),
      children: "Sign in",
      variant: "primary",
    },
    id: assignment.user.id,
    email: assignment.user.email,
    name: `${assignment.user.profile.firstName} ${assignment.user.profile.lastName}`,
    company: assignment.user.company.name,
  }));
}
