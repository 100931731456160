import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";

function useConditionalNavigationBlock(
  isNavigationAllowed: () => boolean,
  isBlockingActive: boolean = true,
) {
  const { navigator } = useContext(NavigationContext);
  const [blockedRoute, setBlockedRoute] = useState<string | null>(null);
  const pushRef = useRef(navigator.push);

  const pushRefCurrent = pushRef.current;

  useEffect(() => {
    navigator.push = (route, params) => {
      if (route.search) {
        setBlockedRoute(route as string);
        pushRef.current(route, params);
      } else if (isNavigationAllowed()) {
        pushRef.current(route, params);
      } else {
        setBlockedRoute(route as string);
      }
    };

    return () => {
      navigator.push = pushRefCurrent;
    };
  }, [isNavigationAllowed, navigator, isBlockingActive, pushRefCurrent]);

  const unblockRoute = () => {
    setBlockedRoute(null);
    navigator.push = pushRefCurrent;
  };

  return { blockedRoute, unblockRoute };
}

export function usePrompt(
  isBlockingActive: boolean = true,
  showModal: (show: boolean) => void,
) {
  const [isNavigationAllowed, setIsNavigationAllowed] = useState<() => boolean>(
    () => () => isBlockingActive,
  );

  const confirmAndCloseModal = useCallback(() => {
    if (isBlockingActive) {
      showModal(true);
      return false;
    }
    showModal(false);
    return true;
  }, [showModal, isBlockingActive]);

  useEffect(() => {
    setIsNavigationAllowed(() => confirmAndCloseModal);
  }, [confirmAndCloseModal]);

  const { blockedRoute, unblockRoute } =
    useConditionalNavigationBlock(isNavigationAllowed);

  useEffect(() => {
    if (isBlockingActive) {
      window.onbeforeunload = function (event) {
        event.preventDefault();

        event.returnValue = true;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [isBlockingActive]);

  return { blockedRoute, unblockRoute, confirmAndCloseModal };
}
