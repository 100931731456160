import {
  FieldActualsStatus,
  FieldDefinitionStatus,
  FieldStep,
} from "@ag/utils/types";

import {
  getFieldStatusIcon,
  getFieldStatusIconBackgroundColor,
} from "./helpers";
import { SIcon, SWrapper } from "./styled";

type Props = {
  status: FieldDefinitionStatus | FieldActualsStatus;
  step: FieldStep;
};

const StatusIcon = ({ status, step }: Props) => {
  const statusIcon = getFieldStatusIcon({
    status,
    step,
  });
  const backgroundColor = getFieldStatusIconBackgroundColor({
    step,
    status,
  });

  return (
    <SWrapper>
      <SIcon
        data-testid={`statusIcon-${status}`}
        backgroundColor={backgroundColor}
      >
        {statusIcon}
      </SIcon>
    </SWrapper>
  );
};

export default StatusIcon;
