import { AxiosError } from "axios";

// TODO: do we have a type for this already somewhere?
type ErrorMessage = {
  message: {
    response: {
      data: {
        errors: string[];
      };
    };
  };
};

type ErrorLike =
  | Error
  | {
      message: string;
    };

type ErrorUnknownObject = object;

type AxiosErrorData = {
  errors: string[];
};

export type NodeApiError = {
  response: {
    data: {
      error: {
        statusCode: number;
        name: string;
        messages: string[];
      };
    };
  };
};

export const getErrorMessagesString = (error: unknown): string => {
  let errorDetails = "";

  // String
  if (typeof error === "string") {
    errorDetails = error;
    // Node error message
  } else if ((error as any)?.response?.data?.error?.messages) {
    errorDetails = (error as any)?.response?.data?.error?.messages.join(" ");
  } else if ((error as ErrorMessage)?.message?.response?.data?.errors) {
    errorDetails = (error as ErrorMessage).message.response.data.errors.join(
      " ",
    );
    // Axios Error
  } else if ((error as AxiosError<AxiosErrorData>)?.response?.data?.errors) {
    errorDetails =
      (error as AxiosError<AxiosErrorData>).response?.data?.errors.join(" ") ||
      "";
    // Error
  } else if (error instanceof Error || (error as ErrorLike).message) {
    errorDetails = (error as ErrorLike).message;
    // Unknown object
  } else {
    console.error(
      `Unable to get error details from ${
        (error as ErrorUnknownObject).constructor.name
      }`,
      JSON.stringify(error),
    );
  }

  return errorDetails;
};

export const getErrorMessagesArray = (error: unknown): string[] => {
  let errorMessages: string[] = [];

  // String
  if (typeof error === "string") {
    errorMessages = [error];
    // Node error message
  } else if ((error as NodeApiError)?.response?.data?.error?.messages) {
    errorMessages = (error as NodeApiError)?.response?.data?.error?.messages;
  } else if ((error as ErrorMessage)?.message?.response?.data?.errors) {
    errorMessages = (error as ErrorMessage).message.response.data.errors;
    // Error
  } else if (error instanceof Error || (error as ErrorLike).message) {
    errorMessages = [(error as ErrorLike).message];
    // Unknown object
  } else {
    console.error(
      `Unable to get error details from ${
        (error as ErrorUnknownObject).constructor.name
      }`,
      JSON.stringify(error),
    );
  }

  return errorMessages;
};
