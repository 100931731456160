import { Button } from "@ag/design-system/atoms";
import { FloatingMenu } from "@ag/design-system/molecules";
import I18n from "@ag/i18n";

import { useActiveHarvestYears } from "~features/initial-resources";

import { FieldsBulkActionType } from "../types/bulk-actions";

type Props = {
  bulkActionAccess: FieldsBulkActionType[];
  hasSelection: boolean;
  onDownloadCSV: () => void;
  onBulkUnlockDefinitions: () => void;
  onBulkUnlockActuals: (harvestYear: number) => void;
  onBulkDeleteFields: () => void;
  onBulkEditFieldNames: () => void;
  onBulkExpireFields: () => void;
  onBulkUnexpireFields: () => void;
};

export function FieldsActions({
  bulkActionAccess,
  hasSelection,
  onDownloadCSV,
  onBulkDeleteFields,
  onBulkUnlockDefinitions,
  onBulkUnlockActuals,
  onBulkEditFieldNames,
  onBulkExpireFields,
  onBulkUnexpireFields,
}: Props) {
  const { data: activeHarvestYears } = useActiveHarvestYears();

  return (
    <>
      {bulkActionAccess.length ? (
        <FloatingMenu.Root
          title={I18n.t("js.shared.bulk_actions")}
          triggerSize="small"
          placement="start"
          isDisabled={!hasSelection}
        >
          {bulkActionAccess.includes("unlock-definition") && (
            <FloatingMenu.Option
              key="unlock-definition"
              isDisabled={!hasSelection}
              onClick={onBulkUnlockDefinitions}
            >
              {I18n.t("js.carbon.fields.action.unlock_definition")}
            </FloatingMenu.Option>
          )}

          {bulkActionAccess.includes("unlock-actuals") &&
            activeHarvestYears?.length &&
            (activeHarvestYears || []).map(year => (
              <FloatingMenu.Option
                key={`unlock-actuals-${year}`}
                isDisabled={!hasSelection}
                onClick={() => onBulkUnlockActuals(year)}
              >
                {I18n.t("js.carbon.fields.action.unlock_actuals") +
                  ` (${year})`}
              </FloatingMenu.Option>
            ))}

          {bulkActionAccess.includes("delete") && (
            <FloatingMenu.Option
              key="delete-fields"
              isDanger
              isDisabled={!hasSelection}
              onClick={onBulkDeleteFields}
            >
              {I18n.t("js.carbon.fields.action.delete_fields")}
            </FloatingMenu.Option>
          )}

          {bulkActionAccess.includes("bulk-edit-field-name") && (
            <FloatingMenu.Option
              key="bulk-edit-field-name"
              isDisabled={!hasSelection}
              onClick={onBulkEditFieldNames}
            >
              Edit field names
            </FloatingMenu.Option>
          )}

          {bulkActionAccess.includes("expire") && (
            <FloatingMenu.Option
              key="expire-fields"
              isDanger
              isDisabled={!hasSelection}
              onClick={onBulkExpireFields}
            >
              Expire fields
            </FloatingMenu.Option>
          )}

          {bulkActionAccess.includes("unexpire") && (
            <FloatingMenu.Option
              key="unexpire-fields"
              isDisabled={!hasSelection}
              onClick={onBulkUnexpireFields}
            >
              Un-expire fields
            </FloatingMenu.Option>
          )}
        </FloatingMenu.Root>
      ) : null}

      <Button variant="secondary" size="small" onClick={onDownloadCSV}>
        {I18n.t("js.shared.export_to_csv")}
      </Button>
    </>
  );
}
