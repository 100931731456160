import countryCodes from "country-codes-list";
import { parsePhoneNumber } from "libphonenumber-js";
import sortBy from "lodash/sortBy";

import { Select } from "@ag/design-system/molecules";

/**
 * @param countryCode country code e.g DK
 * @returns prefix number for given country code - without `+` char
 */
export const getPhoneNumberPrefix = (countryCode: string) => {
  const phonePrefix = countryCodes
    .all()
    .find(countryCodeData => countryCodeData.countryCode === countryCode)
    ?.countryCallingCode;

  return phonePrefix;
};

export const getPhoneCountryCodeOptions = () => {
  const countryCodesList = sortBy(countryCodes.all(), countryCode =>
    Number(countryCode.countryCallingCode),
  );

  return countryCodesList.map(countryCodeData => {
    const countryCallingCode = `+${countryCodeData.countryCallingCode}`;

    return (
      <Select.Option
        key={countryCodeData.countryCode}
        value={countryCodeData.countryCode}
      >
        {countryCallingCode} {countryCodeData.flag}
      </Select.Option>
    );
  });
};

export const formatParsePhoneNumber = (phoneNumber: string) => {
  function formatPhoneNumber(phoneNumber: string) {
    if (phoneNumber.substring(0, 2) === "00") {
      return phoneNumber.replace("00", "+");
    } else if (phoneNumber.substring(0, 1) !== "+") {
      return `+${phoneNumber}`;
    } else return phoneNumber;
  }

  let parsedNumber = null;

  try {
    parsedNumber = parsePhoneNumber(formatPhoneNumber(phoneNumber));
  } catch (e) {
    console.error(e);
  }
  return parsedNumber;
};
