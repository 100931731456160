import get from "lodash/get";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import { Label } from "@ag/design-system/atoms";
import { ComboBox, Select } from "@ag/design-system/molecules";
import { box, stack } from "@ag/design-system/utils";
import { ComboBoxField, InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { transformInitialFilterValues } from "~components/filters";
import { CarbonCountry } from "~features/countries";
import {
  QualityAssuranceStatus,
  getAssuranceStatusLabel,
} from "~features/field";

import { QualityControlStatus } from "../entities/quality-control";
import { getQualityControlStatusLabel } from "../helpers/get-labels";
import { QualityControlFilterData } from "../types/filters";
import { FieldsCheckFilters } from "./fields-check-filters";

type Props = {
  values: QualityControlFilterData;
  countries: CarbonCountry[];
  onChange: (
    name: keyof QualityControlFilterData,
    value: QualityControlFilterData[keyof QualityControlFilterData],
  ) => void;
  onClear: (name?: keyof QualityControlFilterData) => void;
};

export const FieldsFilters = ({
  values,
  countries,
  onChange,
  onClear,
}: Props) => {
  /**
   * React hook form has a different way of working with array data, so we need to omit "flags" in this case.
   * Maybe later if/when we rework flag filters, we can use _their_ way instead of this little hack.
   */
  const form = useForm<QualityControlFilterData>({
    values: transformInitialFilterValues(values),
  });
  const { control, register } = form;

  useEffect(() => {
    const subscription = form.watch((newValues, options) => {
      const name = options.name as keyof QualityControlFilterData;

      if (!name) return;

      // react-hook-form parses the name with a dot to an object, but the stringified format is still in the values object
      if (name.includes(".")) {
        delete newValues[name];
      }

      const newValue = get(newValues, name);

      onChange(name, newValue as any);
    });

    return () => subscription.unsubscribe();
  }, [form, onChange]);

  return (
    <div className={stack({ gap: 16 })}>
      <InputField
        {...register("userId")}
        type="number"
        label={getQualityControlFilterLabel("userId")}
      />

      <InputField
        {...register("id")}
        label={getQualityControlFilterLabel("id")}
        type="number"
      />

      <Controller
        name="countryId"
        control={control}
        render={({ field, fieldState }) => (
          <ComboBoxField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={getQualityControlFilterLabel("countryId")}
            loadingText={I18n.t("js.shared.loading")}
            emptyText={I18n.t("js.shared.no_matching_results")}
          >
            {countries.map(country => (
              <ComboBox.Item key={country.id}>{country.name}</ComboBox.Item>
            ))}
          </ComboBoxField>
        )}
      />

      <Controller
        name="qaStatus"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={getQualityControlFilterLabel("qaStatus")}
          >
            <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

            {Object.entries(QualityAssuranceStatus)
              .filter(([_, value]) => value !== "non_compliance")
              .map(([key, value]) => (
                <Select.Option key={key} value={value}>
                  {getAssuranceStatusLabel(value)}
                </Select.Option>
              ))}

            <Select.Option key="none" value="none">
              {I18n.t("js.shared.none")}
            </Select.Option>
          </SelectField>
        )}
      />

      <Controller
        name="qcStatus"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={getQualityControlFilterLabel("qcStatus")}
          >
            <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>

            {Object.entries(QualityControlStatus).map(([key, value]) => (
              <Select.Option key={key} value={value}>
                {getQualityControlStatusLabel(value)}
              </Select.Option>
            ))}

            <Select.Option key="none" value="none">
              {I18n.t("js.shared.none")}
            </Select.Option>
          </SelectField>
        )}
      />

      <Controller
        name="fallow"
        control={control}
        render={({ field, fieldState }) => (
          <SelectField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={getQualityControlFilterLabel("fallow")}
          >
            <Select.OptionAll>{I18n.t("js.shared.all")}</Select.OptionAll>
            <Select.Option value="true">Yes</Select.Option>
            <Select.Option value="false">No</Select.Option>
          </SelectField>
        )}
      />

      <div className={stack({ gap: 4 })}>
        <Label>Size (ha)</Label>

        <div className={box()}>
          <InputField {...register("sizeHa.min")} label="Min" type="number" />
          <InputField {...register("sizeHa.max")} label="Max" type="number" />
        </div>
      </div>

      <hr style={{ width: "100%" }} />

      <FieldsCheckFilters
        form={form}
        filtersValues={values}
        onClear={onClear}
      />
    </div>
  );
};

export function getQualityControlFilterLabel(
  key: keyof QualityControlFilterData,
) {
  const lookup: Partial<Record<keyof QualityControlFilterData, string>> = {
    userId: I18n.t("js.admin.quality_control.filters.user_id"),
    id: I18n.t("js.admin.quality_control.filters.field_id"),
    qaStatus: "QA Status",
    qcStatus: I18n.t("js.admin.quality_control.filters.qc_status"),
    countryId: I18n.t("js.admin.quality_control.filters.country"),
    fallow: "Fallow",
    "sizeHa.min": "Size (ha) min",
    "sizeHa.max": "Size (ha) max",
  };

  return lookup[key] ?? key;
}

export function getQualityControlFilterValueLabel(
  key: keyof QualityControlFilterData,
  value: QualityControlFilterData[keyof QualityControlFilterData],
  options: {
    countries: CarbonCountry[];
  },
) {
  if (value == null) return null;

  if (key === "countryId") {
    const countryMatch = options.countries?.find(
      country => country.id === Number(value),
    );
    return countryMatch?.name ?? value;
  }

  if (key === "qcStatus") {
    return value === "none"
      ? I18n.t("js.shared.none") // special filter to show fields without any status.
      : getQualityControlStatusLabel(value as QualityControlStatus);
  }

  if (key === "fallow") {
    return value === "true" ? "Yes" : "No";
  }

  if (key === "qaStatus") {
    return value === "none"
      ? I18n.t("js.shared.none") // special filter to show fields without any status.
      : getAssuranceStatusLabel(value as QualityAssuranceStatus);
  }

  if (typeof value === "boolean") {
    return value ? I18n.t("js.shared.yes") : I18n.t("js.shared.no");
  }

  return value;
}
