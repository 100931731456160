import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config";

import {
  QualityControlAction,
  QualityControlStatus,
} from "../entities/quality-control";
import { generateFieldsQueryKey } from "./get-fields";

type PerformFieldActionArgs = {
  fieldIds: string[];
  payload: {
    newStatus: QualityControlStatus;
    description?: string;
  };
};

type FieldActionResult = {
  meta: {
    carbonFieldId: number;
  };
} & (
  | {
      success: QualityControlAction;
      error?: never;
    }
  | {
      success?: never;
      error: {
        message: string;
      };
    }
);

export const changeFieldsStatus = async ({
  fieldIds,
  payload,
}: PerformFieldActionArgs) => {
  const response = await axios.post<CommonResponse<FieldActionResult[]>>(
    `/api/v1/carbon-fields/carbon-quality-control-actions`,
    {
      carbonFieldIds: fieldIds,
      actionPayload: payload,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useChangeFieldsStatusMutation = () =>
  useMutation(changeFieldsStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateFieldsQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
