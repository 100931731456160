import { useState } from "react";

import { Icon } from "@ag/design-system/assets";
import {
  Button,
  FileRejection,
  IconButton,
  InfoBox,
  Tooltip,
} from "@ag/design-system/atoms";
import { DropzoneField } from "@ag/form-fields";

import {
  DocumentationFile,
  useImportIsoVcuStore,
  useRemoveDocumentationIsoVcuMutation,
  useUploadDocumentationIsoVcuMutation,
} from "~features/import-vcu";

type Props = {
  onGoNextStep: () => void;
  onGoPrevStep: () => void;
};

const UploadIsoDocumentation = ({ onGoNextStep, onGoPrevStep }: Props) => {
  const { setDocumentation, documentation, removeDocumentation, id } =
    useImportIsoVcuStore();
  const [filesError, setFilesError] = useState<string[]>([]);
  const uploadDocumentationIsoVcu = useUploadDocumentationIsoVcuMutation();
  const removeDocumentationIsoVcu = useRemoveDocumentationIsoVcuMutation();

  const handleFileDropped = (
    acceptedFiles: File[] | undefined,
    fileRejections: FileRejection[] | undefined,
  ) => {
    if (acceptedFiles?.length === 0 || fileRejections?.length === 0 || !id)
      return;
    setFilesError([]);

    if (fileRejections) {
      const errors = fileRejections.flatMap(fileRejection =>
        fileRejection.errors.map(error => {
          if (error.code === "file-too-large") {
            return "File is too large, please upload a file smaller than 500KB";
          }
          if (error.code === "file-invalid-type") {
            return "Invalid file type, please upload a pdf file";
          }

          return error.message;
        }),
      );

      setFilesError(currentErrors =>
        Array.from(new Set([...currentErrors, ...errors])),
      );
    }

    if (acceptedFiles) {
      const fileNames = new Set(documentation.map(doc => doc.filename));

      const files = acceptedFiles.filter(file => !fileNames.has(file.name));

      if (files.length > 0 && id) {
        uploadDocumentationIsoVcu.mutate(
          { id, files },
          {
            onSuccess: documentationFiles => {
              setDocumentation(documentationFiles);
            },
          },
        );
      }
    }
  };

  const deleteDocumentation = (file: DocumentationFile) => {
    if (!id) return;

    removeDocumentationIsoVcu.mutate(
      { id, fileId: file.id },
      {
        onSuccess: () => {
          removeDocumentation(file);
        },
      },
    );
  };

  return (
    <section className="flex w-full flex-auto flex-col gap-8 bg-grey-100 pt-14">
      <div className="flex w-full flex-col items-center justify-start gap-8">
        <h2 className="text-h2 font-bold">Select ISO project documentation</h2>
        {filesError?.map(fileError => (
          <InfoBox key={fileError} variant="danger" icon="triangle-exclamation">
            {fileError}
          </InfoBox>
        ))}

        <div className="min-h-[220px] w-[1000px]">
          <DropzoneField
            onChange={handleFileDropped}
            isMultiple
            // 5MB
            maxSize={5}
            acceptType={[".pdf"]}
            label=""
            value={undefined}
            dropText=""
          >
            <div className="flex flex-col items-center justify-center gap-4">
              <Icon name="file-pdf" className="text-h1 text-grey-700" />

              <div className="flex flex-col items-center justify-center gap-4">
                <h5 className="text-p1">
                  Drag and drop ISO project documentation{" "}
                </h5>
                <h6 className="text-p2 text-grey-700">
                  PDF format only
                  <Tooltip
                    content="These project documents are required, and will be accessible to the general public in the Public Registry to communicate project methodology."
                    variant="rich"
                  >
                    <Icon name="circle-info" className="pl-1" fontSize={14} />
                  </Tooltip>
                </h6>
              </div>

              <span className="text-p1">or</span>

              <Button
                type="button"
                size="small"
                variant="text"
                onClick={() => null}
              >
                Select from folder
              </Button>
            </div>
          </DropzoneField>
        </div>

        {documentation && documentation?.length > 0 && (
          <div>
            <h5 className="my-2 text-center text-p1">Uploaded files</h5>

            <div className="flex flex-col gap-4">
              {documentation.map(file => (
                <div
                  key={file.filename}
                  className="hover:bg-white flex flex-col items-center p-2 transition-colors duration-300 ease-in-out"
                >
                  <div className="flex w-[320px] justify-between gap-2">
                    <h5
                      key={file.filename}
                      className="max-w-[320px] overflow-hidden text-ellipsis whitespace-nowrap text-p2 text-grey-700"
                      data-testid="documentation-file-name"
                    >
                      {file.filename}
                    </h5>
                    <IconButton
                      type="button"
                      size="small"
                      variant="text"
                      onClick={() => deleteDocumentation(file)}
                      isDanger
                      icon="trash"
                      data-testid="documentation-delete-button"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <footer className="mt-auto flex h-[104px] w-full items-center p-8 shadow-[0_-4px_8px_rgba(0,0,0,0.05)]">
        <div className="mr-auto">
          <Button
            type="button"
            icon="chevron-left"
            iconPosition="before"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className="ml-auto">
          <Button
            type="button"
            icon="chevron-right"
            iconPosition="after"
            onClick={onGoNextStep}
            disabled={documentation.length === 0}
            isLoading={uploadDocumentationIsoVcu.isLoading}
          >
            Next
          </Button>
        </div>
      </footer>
    </section>
  );
};

export default UploadIsoDocumentation;
