import { useMutation } from "@tanstack/react-query";

import { ToastNotification } from "@ag/utils/services";

import { UpdateTranslationsArgs } from "./types";

/**
 * CUSTOM MUTATION FUNCTIONS
 */
const updateTranslations = async ({
  locales,
  translationsGithubToken,
}: UpdateTranslationsArgs) => {
  await fetch("https://api.github.com/repos/agreena-aps/web/dispatches", {
    method: "POST",
    body: JSON.stringify({
      event_type: "update-app-translations-demo",
      client_payload: {
        locales: locales.join(","),
      },
    }),
    headers: {
      Accept: "application/vnd.github.everest-preview+json",
      Authorization: `token ${translationsGithubToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

/**
 * MUTATION HOOKS
 */
export const useUpdateTranslationsMutation = () =>
  useMutation(updateTranslations, {
    onError: error => ToastNotification.error(error),
  });
