import { css } from "@emotion/core";

import { layers } from "@ag/design-system/tokens";

export const reactModalStyles = css`
  .ReactModal__Body--open {
    position: fixed;
    overflow: hidden;
  }

  .ReactModal__Overlay {
    z-index: ${layers.modal};
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.3) !important;
    overflow-y: auto;
  }
`;
