import { z } from "zod";

import { KeyMap } from "@ag/utils/types";

import {
  FertiliserDetailsDataSchema,
  FertiliserV2021DataSchema,
} from "./schemas";

export enum FertiliserAddingMode {
  ChemicalSpecification = "CHEMICAL_SPECIFICATION",
  List = "LIST",
}

export enum FertiliserAttribute {
  Fertilisers = "fertilisers",
  FertilisersCount = "fertilisersCount",
  FertilisersShowChemicalSpecification = "fertilisersShowChemicalSpecification",
}

export enum FertiliserDetailsAttribute {
  Id = "carbonFertiliserId",
  ApplicationRate = "applicationRate",
  ApplicationMethod = "applicationMode",
  EmissionsInhibitors = "emissionsInhibitors",
}

type ChemicalSpecificationDataError = {
  chemicalSpecification?: {
    [FertiliserDetailsAttribute.ApplicationMethod]: string | null;
  };
};

export type FertiliserDetailsDataError = ChemicalSpecificationDataError & {
  [FertiliserAttribute.Fertilisers]: KeyMap<string>[];
};

export type FertiliserDetailsData = z.infer<typeof FertiliserDetailsDataSchema>;

export type FertiliserV2021Data = z.infer<typeof FertiliserV2021DataSchema>;
