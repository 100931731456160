import * as z from "zod";

import { UserManagementAbility } from "../types/abilities";
import { UserManagementResourceClass } from "../types/resources";

export const UserManagementPermissionsSchema = z.record(
  z.nativeEnum(UserManagementAbility),
  z.array(z.nativeEnum(UserManagementResourceClass)),
);

export type UserManagementPermissions = z.infer<
  typeof UserManagementPermissionsSchema
>;
