import set from "lodash/set";

import { QualityControlFilterData } from "../types/filters";

/**
 * Transforms object with dot notation properties { "foo.bar" } into object based { foo: { bar } }
 */
function unflattenObject(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in obj) {
    set(result, key, obj[key]);
  }

  return result;
}

export const getFlagFilters = (filters: QualityControlFilterData) => {
  const objectFilters = unflattenObject(filters);

  const flagFilters = [];

  for (const key in objectFilters) {
    if (!objectFilters[key]) continue;

    const { flag } = objectFilters[key];

    if (flag) {
      flagFilters.push({
        type: key,
        value: flag,
      });
    }
  }

  return flagFilters;
};

export const getValueFilters = (filters: QualityControlFilterData) => {
  const objectFilters = unflattenObject(filters);

  const valueFilters = [];

  for (const key in objectFilters) {
    if (!objectFilters[key]) continue;

    let { value } = objectFilters[key];

    /**
     * value can be a single value or an object with { min, max }
s    * skip when it's undefined or all values in the object are undefined
     */
    if (value === undefined || isEmptyObjectDeep(value)) continue;

    if (key === "crop") {
      value.identifier = value.identifier ? [value.identifier] : [];
      value.category = value.category ? [value.category] : [];
    }

    if (key === "fertiliser") {
      value.category = value.category ? [value.category] : [];
    }

    if (
      key === "soil_disturbance" ||
      key === "residue_management" ||
      key === "cover_crop"
    ) {
      value = Array.isArray(value) ? value : [value];
    }

    valueFilters.push({
      type: key,
      value: value,
    });
  }

  return valueFilters;
};

function isEmptyObjectDeep(obj: unknown): boolean {
  if (!obj) return false;
  if (typeof obj !== "object") return false;

  return Object.values(obj).every(v => v === undefined || isEmptyObjectDeep(v));
}
