import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";
import { generateCertificateGroupsQueryKey } from "~features/certificate";

const distributeVcu = async (file: File) => {
  const form = new FormData();
  form.append("file", file);
  await axios.post("/api/v1/certificates/distribute", form, {
    apiSource: "node-markets",
  });
};

export const useDistributeVcuMutation = () =>
  useMutation(distributeVcu, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateCertificateGroupsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
