import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { Modal } from "@ag/design-system/organisms";
import { stack } from "@ag/design-system/utils";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import {
  NotSignedContractTerminationReason,
  SignedContractTerminationReason,
} from "..";

const signedContractReasonLabels = {
  [SignedContractTerminationReason.BREACH]: "Breach",
  [SignedContractTerminationReason.NON_PARTICIPATION]:
    "Non Participating Farmer",
  [SignedContractTerminationReason.NOVATED]: "Novated",
  [SignedContractTerminationReason.OTHER]: "Other",
  [SignedContractTerminationReason.TERMINATED_BY_FARMER]:
    "Terminated by farmer",
};

const contractReasonLabels = {
  [NotSignedContractTerminationReason.DECLINED]: "Farmer declined contract",
  [NotSignedContractTerminationReason.MISTAKEN]: "Mistaken",
  [NotSignedContractTerminationReason.NOT_SIGNED]: "Contract not signed",
  [NotSignedContractTerminationReason.OTHER]: "Other",
};

const VoidContractSchema = z.object({
  reasonComment: z.string().min(1, "A comment is required"),
  reason: z.union([
    z.nativeEnum(SignedContractTerminationReason, {
      required_error: "A reason is required",
    }),
    z.nativeEnum(NotSignedContractTerminationReason, {
      required_error: "A reason is required",
    }),
  ]),
});
type VoidContractData = z.infer<typeof VoidContractSchema>;

type VoidContractProps = {
  isOpen: boolean;
  isProcessing: boolean;
  isSignedContract: boolean;
  onRequestClose: () => void;
  onVoid: (data: VoidContractData) => void;
};

export const VoidContractModal = ({
  isOpen,
  isProcessing,
  isSignedContract,
  onVoid,
  onRequestClose,
}: VoidContractProps) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<VoidContractData>({
    resolver: zodResolver(VoidContractSchema),
  });

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onRequestClose}>
      <Modal.Header>
        <Modal.Title>Void contract</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <form className={stack()}>
          <>
            <Controller
              name="reason"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  {...fieldState}
                  label="Reason"
                  optionsClassName="z-modal"
                  error={errors.reason}
                >
                  {isSignedContract
                    ? Object.entries(SignedContractTerminationReason).map(
                        ([key, value]) => (
                          <Select.Option key={key} value={value}>
                            {signedContractReasonLabels[value]}
                          </Select.Option>
                        ),
                      )
                    : Object.entries(NotSignedContractTerminationReason).map(
                        ([key, value]) => (
                          <Select.Option key={key} value={value}>
                            {contractReasonLabels[value]}
                          </Select.Option>
                        ),
                      )}
                </SelectField>
              )}
            />
            <InputField
              {...register("reasonComment")}
              label="Comment"
              error={errors.reasonComment}
            />
          </>
        </form>
      </Modal.Content>

      <Modal.Footer
        render={onClose => (
          <>
            <Button variant="secondary" onClick={onClose}>
              {I18n.t("js.shared.cancel")}
            </Button>

            <Button
              isDanger
              isLoading={isProcessing}
              onClick={handleSubmit(onVoid)}
            >
              {I18n.t("js.shared.void")}
            </Button>
          </>
        )}
      />
    </Modal.Root>
  );
};
