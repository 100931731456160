import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { stringify } from "qs";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  FieldActualsStatus,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  AssuranceCategory,
  AssuranceCheckKey,
  AssuranceFlag,
  AssuranceStatus,
} from "../entities/assurance";
import { Field, FieldSchema } from "../entities/field";

type GetFieldsParams = MetaArgs & {
  harvestYear: number | undefined;
  filters?: Partial<{
    userId: number[] | string[];
    id: number[] | string[];
    carbonCountryId: number[] | string[];
    actualStatus: FieldActualsStatus;
    qaStatus: AssuranceStatus | "none";
    flag: {
      category?: AssuranceCategory;
      key?: AssuranceCheckKey;
      value: (AssuranceFlag | "none")[];
    };
    allGreenFlag: boolean;
    baselineVersion: number | string;
    definitionSubmitted: boolean;
    hummingbirdDataAvailability: (string | null)[];
    hasComments: boolean;
  }>;
  dynamicFilters?: {
    flag?: {
      type: string;
      value: AssuranceFlag[];
    }[];
    value?: {
      type: string;
      value: boolean | string | { min: number; max: number };
    }[];
  };
};

export const generateFieldsQueryKey = (params?: GetFieldsParams | undefined) =>
  ["assurance", "fields", params].filter(Boolean);

const getFields = async (params?: GetFieldsParams) => {
  const response = await axios.get<CommonPaginatedResponse<Field>>(
    "/api/v1/carbon-fields/carbon-quality-assurances",
    {
      params: {
        ...params,
        limit: params?.limit || DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(FieldSchema),
      apiSource: "node-carbon",
      paramsSerializer: params => {
        return stringify(params, { arrayFormat: "indices" });
      },
    },
  );

  return response.data.success!.data;
};

export const useFieldsQuery = (
  params?: GetFieldsParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(generateFieldsQueryKey(params), () => getFields(params), {
    keepPreviousData: true,
    ...options,
  });
