import { z } from "zod";

import { KeyMap, LabelValue } from "@ag/utils/types";

import { SoilCharacteristicsDataSchema } from "./schemas";

export enum SoilCharacteristicsAttribute {
  Som = "soilSomSpecific",
  Texture = "soilTexture",
  Ph = "soilPhRange",
  Moisture = "soilMoisture",
  Drainage = "soilDrainage",
}

export enum SoilCharacteristicsOptionalAttribute {
  Som = SoilCharacteristicsAttribute.Som,
}

export type SoilCharacteristicsData = z.infer<
  typeof SoilCharacteristicsDataSchema
>;

export type SoilCharacteristicsDataError = KeyMap<string>;

export type SoilCharacteristicsFieldPartial = {
  soilTexture: LabelValue<string>[];
  soilMoisture: LabelValue<string>[];
  soilDrainage: LabelValue<string>[];
  soilPhRange: LabelValue<string>[];
};
