
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/utils/pointer.css.ts", "@ag/design-system");
      import { style } from "@vanilla-extract/css";
export const pointer = style({
  ":hover": {
    cursor: "pointer"
  }
}, "pointer");
      endFileScope();
      
    