import omit from "lodash/omit";

import { Icon } from "~assets";
import { Tooltip } from "~atoms/Tooltip";

type CommonProps = React.PropsWithChildrenRequired<{
  tooltip?: string;
  onTooltipHover?: () => void;
}>;

// Declaration of common props to be omitted from the rest of the props in further parts of the code
const COMMON_PROPS = ["as", "children", "tooltip"];

export type LabelProps = CommonProps &
  React.LabelHTMLAttributes<HTMLLabelElement> & {
    as?: "label";
  };

type Props = LabelProps;

export const Label = ({ tooltip, onTooltipHover, ...props }: Props) => {
  return (
    <label
      className="inline-flex items-center text-p2 text-grey-700"
      {...omit(props, COMMON_PROPS)}
    >
      {props.children}
      {tooltip && (
        <Tooltip content={tooltip} onHover={onTooltipHover}>
          <Icon name="circle-info" className="ml-1 p-1 text-p2" />
        </Tooltip>
      )}
    </label>
  );
};
