import { z } from "zod";

const IpccProperties = z.object({
  net_gross: z.number(),
  show_residue: z.boolean(),
  gross_yield_min: z.number(),
  gross_yield_max: z.number(),
  kg_n_ha_min: z.number(),
  kg_n_ha_max: z.number(),
  methodology_version: z.number(),
  dry_matter_fraction: z.string(),
  slope: z.number(),
  intercept: z.number(),
  categories: z.array(z.string()),
});

const BiomassProperties = z.object({
  "n-fix": z.number(),
  "N content of above-ground residues (N AG(T))": z.number(),
  "N-AG uncertainty": z.string(),
  "N content of below-ground residues (N BG(T))": z.number(),
  "N-BG uncertainty": z.string(),
  "Ratio of above-ground residue dry matter to harvested yield (RAG (T))":
    z.number(),
  "Ratio of below-ground biomass to above-ground biomass (RS (T))": z.number(),
  "ratio uncertainty": z.string(),
  "Dry matter fraction of harvested product (DRY)": z.number(),
  "Slope (T)": z.number(),
  "slope ± 2 s.d. as % of mean": z.string(),
  "Intercept (T)": z.number(),
  "Intercept ± 2 s.d. as % of mean": z.string(),
  "R2 adj.": z.number(),
});

export const CropDetailsSchema = z.object({
  crop_id: z.string(),
  harvest_year: z.string(),
  ipcc_properties: IpccProperties,
  biomass_properties: BiomassProperties,
});

export type CropDetails = z.infer<typeof CropDetailsSchema>;
export type BiomassProperties = z.infer<typeof BiomassProperties>;
export type IpccProperties = z.infer<typeof IpccProperties>;
