import {
  addDays,
  addMonths,
  endOfDay,
  formatISO,
  startOfDay,
  subDays,
  subMonths,
} from "date-fns";
import formatRelative from "date-fns/formatRelative";
import { enGB } from "date-fns/locale";

import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { TimeRange } from "~constants/date";

export const formatRelativeWithoutTime = (date: Date, baseDate: Date) => {
  const formatRelativeLocale: Record<string, string> = {
    lastWeek: `'${I18n.t("js.admin.shared.date.last")}' eeee`,
    yesterday: `'${I18n.t("js.admin.shared.date.yesterday")}'`,
    today: `'${I18n.t("js.admin.shared.date.today")}'`,
  };

  const locale = {
    ...enGB,
    formatRelative: (token: string) =>
      formatRelativeLocale[token] || "dd.MM.yyyy",
  };

  return formatRelative(date, baseDate, { locale });
};

type TimeRangeOption = "past" | "today" | "future" | "none";

export const getTimeRangeOptions = (options?: TimeRangeOption[]) => {
  const pastRange = [
    {
      label: I18n.t("js.admin.users.since_yesterday"),
      value: TimeRange.SinceYesterday,
    },
    {
      label: I18n.t("js.admin.users.last_seven_days"),
      value: TimeRange.Last7Days,
    },
    {
      label: I18n.t("js.admin.users.last_fourteen_days"),
      value: TimeRange.Last14Days,
    },
    {
      label: I18n.t("js.admin.users.last_month"),
      value: TimeRange.LastMonth,
    },
    {
      label: I18n.t("js.admin.users.last_three_months"),
      value: TimeRange.Last3Months,
    },
    {
      label: I18n.t("js.admin.users.last_six_months"),
      value: TimeRange.Last6Months,
    },
  ];

  const futureRange = [
    {
      label: I18n.t("js.admin.users.until_tomorrow"),
      value: TimeRange.UntilTomorrow,
    },
    {
      label: I18n.t("js.admin.users.next_seven_days"),
      value: TimeRange.Next7Days,
    },
    {
      label: I18n.t("js.admin.users.next_fourteen_days"),
      value: TimeRange.Next14Days,
    },
    {
      label: I18n.t("js.admin.users.next_month"),
      value: TimeRange.NextMonth,
    },
    {
      label: I18n.t("js.admin.users.next_three_months"),
      value: TimeRange.Next3Months,
    },
    {
      label: I18n.t("js.admin.users.next_six_months"),
      value: TimeRange.Next6Months,
    },
  ];

  const todayRange = {
    label: I18n.t("js.admin.users.today"),
    value: TimeRange.Today,
  };

  const noneRange = {
    label: I18n.t("js.admin.users.none"),
    value: TimeRange.None,
  };

  const timeRange: LabelValue<TimeRange>[] = [];

  if (!options) {
    timeRange.push(noneRange, ...futureRange, todayRange, ...pastRange);
  } else {
    if (options.includes("none")) {
      timeRange.push(noneRange);
    }

    if (options.includes("future")) {
      timeRange.push(...futureRange);
    }

    if (options.includes("today")) {
      timeRange.push(todayRange);
    }

    if (options.includes("past")) {
      timeRange.push(...pastRange);
    }
  }

  return timeRange;
};

export const getTimeRangeValue = (timeRangeOptions: TimeRange) => {
  const startOfCurrentDate = formatISO(startOfDay(new Date()));
  const endOfCurrentDate = formatISO(endOfDay(new Date()));

  const timeRangeValue = {
    [TimeRange.None]: undefined,
    [TimeRange.UntilTomorrow]: {
      startDate: formatISO(endOfDay(addDays(new Date(), 1))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Overdue]: {
      startDate: startOfCurrentDate,
      endDate: endOfCurrentDate,
    },
    [TimeRange.Today]: {
      startDate: startOfCurrentDate,
      endDate: endOfCurrentDate,
    },
    [TimeRange.SinceYesterday]: {
      startDate: formatISO(startOfDay(subDays(new Date(), 1))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Last7Days]: {
      startDate: formatISO(startOfDay(subDays(new Date(), 7))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Last14Days]: {
      startDate: formatISO(startOfDay(subDays(new Date(), 14))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.LastMonth]: {
      startDate: formatISO(startOfDay(subMonths(new Date(), 1))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Last3Months]: {
      startDate: formatISO(startOfDay(subMonths(new Date(), 3))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Last6Months]: {
      startDate: formatISO(startOfDay(subMonths(new Date(), 6))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Next7Days]: {
      startDate: formatISO(endOfDay(addDays(new Date(), 7))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Next14Days]: {
      startDate: formatISO(endOfDay(addDays(new Date(), 14))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.NextMonth]: {
      startDate: formatISO(endOfDay(addMonths(new Date(), 1))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Next3Months]: {
      startDate: formatISO(endOfDay(addMonths(new Date(), 3))),
      endDate: endOfCurrentDate,
    },
    [TimeRange.Next6Months]: {
      startDate: formatISO(endOfDay(addMonths(new Date(), 6))),
      endDate: endOfCurrentDate,
    },
  };

  return timeRangeValue[timeRangeOptions];
};
