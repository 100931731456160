import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { InventoryFileUploadSchema } from "../entities/inventory-file-upload";

type Payload = {
  filename: string;
};

const createPresignedUrl = async (payload: Payload) => {
  const response = await axios.post<CommonResponse<InventoryFileUploadSchema>>(
    "/api/v1/inventory/imports/upload-url",
    payload,
    {
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useCreatePresignedUrlMutation = () =>
  useMutation(createPresignedUrl, {
    onError: error => ToastNotification.error(error),
  });
