const IconNavigationArrow = ({ color = "#662C09" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M14.3335 6.13811L3.33848 2.06248L3.32848 2.05936C3.1515 1.99842 2.96097 1.98838 2.77857 2.03038C2.59617 2.07237 2.42921 2.16472 2.29669 2.29691C2.16418 2.4291 2.07142 2.59583 2.02898 2.77813C1.98653 2.96043 1.99611 3.15098 2.05661 3.32811C2.05737 3.33152 2.05841 3.33487 2.05973 3.33811L6.13848 14.3331C6.20547 14.5292 6.33253 14.6991 6.50161 14.8188C6.67069 14.9385 6.8732 15.0019 7.08036 15H7.09911C7.31036 14.9984 7.51551 14.9289 7.6843 14.8019C7.85309 14.6748 7.9766 14.4969 8.03661 14.2944L9.50911 9.50936L14.2941 8.03686C14.4952 7.97499 14.6716 7.85129 14.7983 7.68336C14.925 7.51542 14.9955 7.31181 14.9998 7.10149C15.0041 6.89117 14.9419 6.68485 14.8222 6.51189C14.7025 6.33893 14.5312 6.20813 14.3329 6.13811H14.3335ZM14.0004 7.08123L9.21536 8.55374C9.0596 8.60172 8.91792 8.68705 8.80268 8.8023C8.68743 8.91755 8.6021 9.05922 8.55411 9.21499L7.08161 14L7.07786 13.9894L3.00036 2.99998L13.9891 7.07623L13.9991 7.07998L14.0004 7.08123Z"
      fill={color}
    />
  </svg>
);

export default IconNavigationArrow;
