export const gapVariants = {
  0: { gap: 0 },
  4: { gap: 4 },
  6: { gap: 6 },
  8: { gap: 8 },
  10: { gap: 10 },
  12: { gap: 12 },
  16: { gap: 16 },
  20: { gap: 20 },
  24: { gap: 24 },
  32: { gap: 32 },
};

export const rowGapVariants = {
  0: { rowGap: 0 },
  4: { rowGap: 4 },
  6: { rowGap: 6 },
  8: { rowGap: 8 },
  12: { rowGap: 12 },
  16: { rowGap: 16 },
  20: { rowGap: 20 },
  24: { rowGap: 24 },
  32: { rowGap: 32 },
};

export const alignVariants = {
  start: {
    alignItems: "flex-start",
  },
  end: {
    alignItems: "flex-end",
  },
  center: {
    alignItems: "center",
  },
  baseline: {
    alignItems: "baseline",
  },
};

export const justifyVariants = {
  start: {
    justifyContent: "flex-start",
  },
  end: {
    justifyContent: "flex-end",
  },
  center: {
    justifyContent: "center",
  },
  between: {
    justifyContent: "space-between",
  },
};
