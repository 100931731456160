import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { CropDetails } from "../entities/crop-details";
import { generateCropsQueryKey } from "./get-crops";

type Data = {
  name: string;
  legacy_agreena_crop_id?: string;
  crop_science_id?: number;
  cool_farm_name?: string;
};

export const createCrop = async (data: Data) => {
  const response = await axios.post<CropDetails>(`/v1/crops`, data, {
    apiSource: "crops-fertilisers",
  });

  return response.data;
};

export const useCreateCropMutation = () =>
  useMutation(createCrop, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateCropsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
