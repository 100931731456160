import { Icon, Logo } from "@ag/design-system/assets";

import CenteredLayout from "~layouts/centered-layout";

const ConsentApproved = () => {
  return (
    <CenteredLayout.Root>
      <div className="flex flex-col items-center space-y-8">
        <Logo className="w-36" />

        <div className="flex flex-col items-center space-y-2">
          <Icon name="check" className="text-h1 text-green-500" />

          <h2 className="text-h2">DocuSign consent approved</h2>
        </div>
      </div>
    </CenteredLayout.Root>
  );
};

export default ConsentApproved;
