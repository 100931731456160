import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { queryClient } from "~config/react-query";

import { TerminateReasons } from "../entities/resale-agreement";
import { generateResaleAgreementDetailsQueryKey } from "./get-resale-agreement-detail";
import { generateResaleAgreementsQueryKey } from "./get-resale-agreements";

const terminateResaleAgreement = async ({
  id,
  reasonComment,
}: {
  id: string;
  reasonComment: string;
}) => {
  await axios.post(
    `/api/v1/broker-contracts/${id}/terminate`,
    {
      reasonComment,
      reason: TerminateReasons.OTHER,
    },
    { apiSource: "node-carbon" },
  );
};

export const useTerminateResaleAgreementMutation = () =>
  useMutation(terminateResaleAgreement, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(generateResaleAgreementsQueryKey());
      queryClient.invalidateQueries(
        generateResaleAgreementDetailsQueryKey(variables.id),
      );
    },
  });
