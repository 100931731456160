import { z } from "zod";

import { BuyoutContractStatus } from "~features/buyout-agreements";
import { ResaleContractStatus } from "~features/resale-agreements";

export enum CertificaGroupStatus {
  IMPORTED = "imported",
  ALLOCATED = "allocated",
  ISSUED = "issued",
  SOLD = "sold",
}

export enum ContractType {
  Buyout = "buyout",
  Resale = "resale",
}
export const InventorySchema = z.object({
  id: z.string(),
  importIds: z.array(z.string()),
  status: z.nativeEnum(CertificaGroupStatus),
  fieldId: z.string(),
  countryCode: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  cropTypeId: z.string().nullable(),
  cropTypeName: z.string().nullable(),
  contractType: z.nativeEnum(ContractType).nullable(),
  contractId: z.number().nullable(),
  contractStatus: z
    .nativeEnum(BuyoutContractStatus)
    .or(z.nativeEnum(ResaleContractStatus)),
  contractUpdatedAt: z.string().nullable(),
  userId: z.string().nullable(),
  vintageYear: z.number().nullable(),
  idRange: z
    .object({
      minId: z.number(),
      maxId: z.number(),
    })
    .nullable(),
  isoEligible: z.boolean().nullable(),
  verraEligible: z.boolean().nullable(),
  footprint: z.number().nullable(),
  isoQuantityReductionNet: z.number().nullable(),
  isoQuantityRemovalNet: z.number().nullable(),
  isoQuantityReductionGross: z.number().nullable(),
  isoQuantityRemovalGross: z.number().nullable(),
  isoQuantityPremiumPoolReduction: z.number().nullable(),
  isoQuantityPremiumPoolRemoval: z.number().nullable(),
  isoQuantityBufferReduction: z.number().nullable(),
  isoQuantityBufferRemoval: z.number().nullable(),
  isoQuantityReductionFee: z.number().nullable(),
  isoQuantityRemovalFee: z.number().nullable(),
  verraQuantityReductionNet: z.number().nullable(),
  verraQuantityRemovalNet: z.number().nullable(),
  verraQuantityReductionGross: z.number().nullable(),
  verraQuantityRemovalGross: z.number().nullable(),
  verraQuantityPremiumPoolReduction: z.number().nullable(),
  verraQuantityPremiumPoolRemoval: z.number().nullable(),
  verraQuantityBufferReduction: z.number().nullable(),
  verraQuantityBufferRemoval: z.number().nullable(),
  verraQuantityReductionFee: z.number().nullable(),
  verraQuantityRemovalFee: z.number().nullable(),
  finalizedColumns: z.array(z.string()).nullable(),
});
export type Inventory = z.infer<typeof InventorySchema>;

export const InventoryFiltersSchema = z.object({
  importId: z.string().optional(),
  fieldId: z.string().optional(),
  status: z.nativeEnum(CertificaGroupStatus).or(z.literal("")).optional(),
  vintageYear: z.string().optional(),
  countryCode: z.string().optional(),
  isoEligible: z.string().optional(),
  verraEligible: z.string().optional(),
  contractType: z.nativeEnum(ContractType).or(z.literal("")).optional(),
  contractId: z.string().optional(),
  contractStatus: z
    .nativeEnum(BuyoutContractStatus)
    .or(z.nativeEnum(ResaleContractStatus))
    .or(z.literal(""))
    .optional(),
  userId: z.string().optional(),
});
export type InventoryFilters = z.infer<typeof InventoryFiltersSchema>;
