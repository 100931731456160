import { z } from "zod";

import {
  SoilCultivationPracticeDataSchema,
  SoilCultivationPracticeQuestionnaireDataSchema,
} from "./schemas";

export enum SoilCultivationPracticeQuestionnaireAttribute {
  CultivatedFieldInTheLastFiveYears = "cultivatedFieldInTheLastFiveYears",
  RotatedCropsInTheLastFiveYears = "rotatedCropsInTheLastFiveYears",
  TillageConventionalInAnyOfTheLastFiveYears = "tillageConventionalInAnyOfTheLastFiveYears",
  TillageReducedInAnyTheLastFiveYears = "tillageReducedInAnyTheLastFiveYears",
  TillageNoTillConsistentlyTheLastFiveYears = "tillageNoTillConsistentlyTheLastFiveYears",
  CropResidueBurnedInAnyOfTheLastFiveYears = "cropResidueBurnedInAnyOfTheLastFiveYears",
  CropResidueRemovedInAnyTheLastFiveYears = "cropResidueRemovedInAnyTheLastFiveYears",
  CropResidueMulchedConsistentlyTheLastFiveYears = "cropResidueMulchedConsistentlyTheLastFiveYears",
  NoCoverCropGrownInTheLastFiveYears = "noCoverCropGrownInTheLastFiveYears",
  CoverCropWinterConsistentlyTheLastFiveYears = "coverCropWinterConsistentlyTheLastFiveYears",
  CoverCropSpringConsistentlyTheLastFiveYears = "coverCropSpringConsistentlyTheLastFiveYears",
  CatchCropConsistentlyTheLastFiveYears = "catchCropConsistentlyTheLastFiveYears",
  FertilizersSyntheticNitrogenInAnyOfTheLastFiveYears = "fertilizersSyntheticNitrogenInAnyOfTheLastFiveYears",
  FertilizersMixedNitrogenInAnyOfTheLastFiveYears = "fertilizersMixedNitrogenInAnyOfTheLastFiveYears",
  FertilizersOrganicNitrogenConsistentlyTheLastFiveYears = "fertilizersOrganicNitrogenConsistentlyTheLastFiveYears",
  CropProtectionConsistentlyTheLastFiveYears = "cropProtectionConsistentlyTheLastFiveYears",
  NitrificationInhibitorsConsistentlyTheLastFiveYears = "nitrificationInhibitorsConsistentlyTheLastFiveYears",
}

export type SoilCultivationPracticeQuestionnaireData = z.infer<
  typeof SoilCultivationPracticeQuestionnaireDataSchema
>;

export enum SoilCultivationPracticeAttribute {
  Questionnaire = "questionnaire",
}

export type SoilCultivationPracticeData = z.infer<
  typeof SoilCultivationPracticeDataSchema
>;
