import { ChipVariant } from "@ag/design-system/atoms";
import { ChipCellValue } from "@ag/design-system/organisms";

import { ImportStatus } from "../entities/inventory-import";

export function getStatusChipVariant(status: ImportStatus) {
  const lookup: Record<ImportStatus, ChipVariant> = {
    [ImportStatus.PROCESSING]: "warning",
    [ImportStatus.PROCESSED]: "success",
    [ImportStatus.FAILED]: "danger",
    [ImportStatus.UPLOADING]: "info",
    [ImportStatus.UPLOADED]: "info",
    [ImportStatus.DISCARDED]: "neutral",
  };

  return lookup[status];
}

export function getStatusChipText(status: ImportStatus) {
  const lookup: Record<ImportStatus, string> = {
    [ImportStatus.PROCESSING]: "Processing",
    [ImportStatus.PROCESSED]: "Processed",
    [ImportStatus.FAILED]: "Failed",
    [ImportStatus.UPLOADING]: "Uploading",
    [ImportStatus.UPLOADED]: "Uploaded",
    [ImportStatus.DISCARDED]: "Discarded",
  };

  return lookup[status];
}

export const getStatusChip = (status: ImportStatus): ChipCellValue => ({
  variant: getStatusChipVariant(status),
  label: getStatusChipText(status),
});
