import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import { LinkCell, LinkCellValue } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { useTable } from "~components/table";
import { ApplicationSetting } from "~queries/application-settings";

type TableData = {
  id: LinkCellValue;
  name: string;
  value: string;
  type: string;
};

export const useApplicationSettingsTable = (
  data: ApplicationSetting[] | undefined,
) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => original.name,
    getCoreRowModel: getCoreRowModel(),
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: I18n.t("js.admin.id"),
      cell: LinkCell,
    }),
    columnHelper.accessor("name", {
      header: I18n.t("js.shared.name"),
    }),
    columnHelper.accessor("value", {
      header: I18n.t("js.shared.value"),
    }),
    columnHelper.accessor("type", {
      header: I18n.t("js.shared.type"),
    }),
  ];
}

function getRowData(settings: ApplicationSetting[] | undefined): TableData[] {
  if (!settings) return [];

  return settings.map(setting => ({
    id: {
      title: setting.id,
      url: `/settings/application/${setting.id}`,
    },
    name: setting.name,
    value: setting.value,
    type: setting.value,
  }));
}
