import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

const generateBuyoutAgreementPdfQueryKey = (url: string) =>
  ["buyout-contract", url].filter(Boolean);

const getBuyoutAgreementPdf = async (url: string) => {
  const response = await axios.get<CommonResponse<string>>(url, {
    apiSource: "node-carbon",
    headers: {
      "x-amz-acl": "public-read",
      "Content-Type": "application/pdf",
    },
  });

  return response.data.success!.data;
};

export const useBuyoutAgreementPdfQuery = (
  url: string,
  options?: { enabled?: boolean },
) =>
  useQuery(
    generateBuyoutAgreementPdfQueryKey(url),
    () => getBuyoutAgreementPdf(url),
    options,
  );
