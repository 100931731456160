import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { Row } from "react-table";

import { Chip, ChipVariant, Tooltip } from "@ag/design-system/atoms";
import {
  BooleanCell,
  ChipCell,
  ChipCellValue,
  DateCell,
  LinkCell,
  LinkCellConfig,
  LinkCellValue,
  TextCell,
  TextCellValue,
} from "@ag/design-system/organisms";

import { TableSortingState, useTable } from "~components/table";
import {
  BuyoutContractStatus,
  getContractStatusChip as getBuyoutContractStatusChip,
} from "~features/buyout-agreements";
import {
  ResaleContractStatus,
  getContractStatusChip as getResaleContractStatusChip,
} from "~features/resale-agreements";
import { getCountryWithFlag } from "~helpers/countries";

import { ContractType, Inventory } from "../entities/inventory";
import {
  getCertificaGroupStatusChip,
  getContractUrl,
} from "../helpers/certificate-group-statuses";
import {
  getContractTypeChip,
  getContractTypeFromChipLabel,
} from "../helpers/contract-types";

type TableData = {
  id: string;
  importId: string;
  fieldId: LinkCellConfig;
  countryCode: string;
  cropTypeName: string;
  vintageYear: string | null;
  status: ChipCellValue;
  idRange: string;
  isoEligible: boolean | null;
  verraEligible: boolean | null;
  contractType: ChipCellValue | null;
  contractId: LinkCellConfig | null;
  contractStatus: ChipCellValue | null;
  contractUpdatedAt: string | null;
  userId: LinkCellConfig | null;
  footprint: number | null;
  isoQuantityReductionNet: number | null;
  isoQuantityRemovalNet: number | null;
  isoQuantityReductionGross: number | null;
  isoQuantityRemovalGross: number | null;
  isoQuantityPremiumPoolReduction: number | null;
  isoQuantityPremiumPoolRemoval: number | null;
  isoQuantityBufferReduction: number | null;
  isoQuantityBufferRemoval: number | null;
  isoQuantityReductionFee: number | null;
  isoQuantityRemovalFee: number | null;
  verraQuantityReductionNet: number | null;
  verraQuantityRemovalNet: number | null;
  verraQuantityReductionGross: number | null;
  verraQuantityRemovalGross: number | null;
  verraQuantityPremiumPoolReduction: number | null;
  verraQuantityPremiumPoolRemoval: number | null;
  verraQuantityBufferReduction: number | null;
  verraQuantityBufferRemoval: number | null;
  verraQuantityReductionFee: number | null;
  verraQuantityRemovalFee: number | null;
  createdAt: string;
  finalizedColumns: string[];
};

export type ActionColumnProps = {
  row: Row<TableData>;
};

function formatIdRange(
  idRange: { minId?: number; maxId?: number } | undefined,
): string {
  if (idRange?.minId && idRange?.maxId) {
    return `${idRange.minId} - ${idRange.maxId}`;
  }
  return "-";
}

const columnHeaderConfig = {
  footprint: "Footprint",
  isoQuantityReductionNet: "ISO Reduction Net",
  isoQuantityRemovalNet: "ISO Removal Net",
  isoQuantityReductionGross: "ISO Reduction Gross",
  isoQuantityRemovalGross: "ISO Removal Gross",
  isoQuantityPremiumPoolReduction: "ISO Premium Pool Reduction",
  isoQuantityPremiumPoolRemoval: "ISO Premium Pool Removal",
  isoQuantityBufferReduction: "ISO Buffer Reduction",
  isoQuantityBufferRemoval: "ISO Buffer Removal",
  isoQuantityReductionFee: "ISO Reduction Fee",
  isoQuantityRemovalFee: "ISO Removal Fee",
  verraQuantityReductionNet: "Verra Reduction Net",
  verraQuantityRemovalNet: "Verra Removal Net",
  verraQuantityReductionGross: "Verra Reduction Gross",
  verraQuantityRemovalGross: "Verra Removal Gross",
  verraQuantityPremiumPoolReduction: "Verra Premium Pool Reduction",
  verraQuantityPremiumPoolRemoval: "Verra Premium Pool Removal",
  verraQuantityBufferReduction: "Verra Buffer Reduction",
  verraQuantityBufferRemoval: "Verra Buffer Removal",
  verraQuantityReductionFee: "Verra Reduction Fee",
  verraQuantityRemovalFee: "Verra Removal Fee",
};

const tooltipProperties = {
  final: {
    text: "Final value",
    variant: "success",
    iconColor: "text-accent-300",
  },
  estimated: {
    text: "Estimated value",
    variant: "warning",
    iconColor: "text-messaging-warning-700",
  },
};

const generateColumnConfig = (columnId: string) => ({
  header: columnHeaderConfig[columnId as keyof typeof columnHeaderConfig],
  cell: (props: CellContext<TableData, any>) => {
    const value = props.getValue();

    const isFinalValue = props.row.original.finalizedColumns.includes(columnId);
    const tooltipProps = isFinalValue
      ? tooltipProperties.final
      : tooltipProperties.estimated;

    return (
      <div className="flex flex-row-reverse items-center justify-end gap-4">
        <Tooltip content={tooltipProps.text} placement="bottom-center">
          <Chip variant={tooltipProps.variant as ChipVariant}>
            <span>{value ?? "-"}</span>
          </Chip>
        </Tooltip>
      </div>
    );
  },
  enableSorting: true,
});

const columnHelper = createColumnHelper<TableData>();

const dynamicColumns = Object.keys(columnHeaderConfig).map(columnId =>
  columnHelper.accessor(
    columnId as keyof TableData,
    generateColumnConfig(columnId),
  ),
);

export type UseInventoryTableParams = {
  data: Inventory[] | undefined;
  state: TableSortingState;
  hasFieldPermission: boolean;
  hasUserPermission: boolean;
  hasBuyoutPermission: boolean;
  hasResalePermission: boolean;
};

export const useInventoryTable = ({
  data,
  state,
  hasFieldPermission,
  hasUserPermission,
  hasBuyoutPermission,
  hasResalePermission,
}: UseInventoryTableParams) => {
  const columns = useMemo(
    () =>
      getStickyColumns({
        hasFieldPermission,
        hasUserPermission,
        hasBuyoutPermission,
        hasResalePermission,
      }),
    [
      hasFieldPermission,
      hasUserPermission,
      hasBuyoutPermission,
      hasResalePermission,
    ],
  );
  const rowData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: original => String(original.id),
    state: {
      sorting: state.sorting,
      columnPinning: {
        left: ["id", "fieldId"],
      },
    },
    onSortingChange: state.setSorting,
  });
};

function getStickyColumns({
  hasFieldPermission,
  hasUserPermission,
  hasBuyoutPermission,
  hasResalePermission,
}: Pick<
  UseInventoryTableParams,
  | "hasFieldPermission"
  | "hasUserPermission"
  | "hasBuyoutPermission"
  | "hasResalePermission"
>) {
  return [
    columnHelper.accessor("id", {
      header: "ID",
      cell: TextCell,
      enableSorting: true,
      size: 80,
    }),
    columnHelper.accessor("fieldId", {
      header: "Field ID",
      cell: hasFieldPermission ? LinkCell : TextCell,
      enableSorting: true,
      size: 120,
    }),
    columnHelper.accessor("importId", {
      header: "Import ID",
      cell: TextCell,
      enableSorting: false,
    }),
    columnHelper.accessor("countryCode", {
      header: "Country",
      cell: ({ getValue }) => {
        const country = getValue();
        if (!country) return null;
        const { flag, name } = getCountryWithFlag(country);
        return `${flag} ${name}`;
      },
    }),
    columnHelper.accessor("cropTypeName", {
      header: "Crop Type",
      cell: TextCell,
      enableSorting: false,
    }),
    columnHelper.accessor("vintageYear", {
      header: "Vintage Year",
      cell: TextCell,
      enableSorting: true,
    }),
    columnHelper.accessor("idRange", {
      header: "Range",
      cell: TextCell,
    }),
    columnHelper.accessor("status", {
      header: "Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("isoEligible", {
      header: "ISO Eligible",
      cell: BooleanCell,
    }),
    columnHelper.accessor("verraEligible", {
      header: "Verra Eligible",
      cell: BooleanCell,
    }),
    columnHelper.accessor("contractId", {
      header: "Contract ID",
      cell: props => {
        const value = props.getValue();
        if (!value) {
          return "-";
        }
        const contractType = getContractTypeFromChipLabel(
          props.row.original.contractType as { label: string },
        );

        if (
          (contractType === ContractType.Buyout && hasBuyoutPermission) ||
          (contractType === ContractType.Resale && hasResalePermission)
        ) {
          return (
            <LinkCell
              {...(props as unknown as CellContext<TableData, LinkCellValue>)}
            />
          );
        }

        return (
          <TextCell
            {...(props as unknown as CellContext<TableData, TextCellValue>)}
          />
        );
      },
      size: 120,
      enableSorting: true,
    }),
    columnHelper.accessor("contractType", {
      header: "Contract Type",
      cell: ChipCell,
      enableSorting: false,
    }),
    columnHelper.accessor("contractStatus", {
      header: "Contract Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("contractUpdatedAt", {
      header: "Contract Updated At",
      cell: DateCell,
      enableSorting: true,
    }),
    columnHelper.accessor("userId", {
      header: "User ID",
      cell: hasUserPermission ? LinkCell : TextCell,
      enableSorting: true,
    }),
    ...dynamicColumns,
    columnHelper.accessor("createdAt", {
      header: "Created At",
      cell: DateCell,
      enableSorting: true,
    }),
  ];
}

function getRowData(inventoryList: Inventory[] | undefined): TableData[] {
  if (!inventoryList) return [];

  return inventoryList.map(inventory => ({
    id: inventory.id,
    importId: inventory.importIds.join(", "),
    fieldId: {
      title: inventory.fieldId,
      url: `/carbon/fields/${inventory.fieldId}${
        inventory.vintageYear ? `?harvest-year=${inventory.vintageYear}` : ""
      }`,
    },
    countryCode: inventory.countryCode,
    cropTypeName: inventory.cropTypeName ?? "-",
    vintageYear: String(inventory.vintageYear),
    status: getCertificaGroupStatusChip(inventory.status),
    idRange: formatIdRange(inventory.idRange ?? undefined),
    isoEligible: inventory.isoEligible,
    verraEligible: inventory.verraEligible,
    footprint: inventory.footprint,
    contractId: inventory.contractId
      ? {
          title: inventory.contractId,
          url:
            getContractUrl(inventory.contractId, inventory.contractType) || "/",
        }
      : null,
    contractType: inventory.contractType
      ? getContractTypeChip(inventory.contractType)
      : null,
    contractStatus:
      inventory.contractType === ContractType.Resale
        ? getResaleContractStatusChip(
            inventory.contractStatus as ResaleContractStatus,
          )
        : inventory.contractType === ContractType.Buyout
          ? getBuyoutContractStatusChip(
              inventory.contractStatus as BuyoutContractStatus,
            )
          : inventory.contractStatus,
    contractUpdatedAt: inventory.contractUpdatedAt,
    userId: inventory.userId
      ? {
          title: inventory.userId,
          url: `/users/${inventory.userId}`,
        }
      : null,
    isoQuantityReductionNet: inventory.isoQuantityReductionNet,
    isoQuantityRemovalNet: inventory.isoQuantityRemovalNet,
    isoQuantityReductionGross: inventory.isoQuantityReductionGross,
    isoQuantityRemovalGross: inventory.isoQuantityRemovalGross,
    isoQuantityPremiumPoolReduction: inventory.isoQuantityPremiumPoolReduction,
    isoQuantityPremiumPoolRemoval: inventory.isoQuantityPremiumPoolRemoval,
    isoQuantityBufferReduction: inventory.isoQuantityBufferReduction,
    isoQuantityBufferRemoval: inventory.isoQuantityBufferRemoval,
    isoQuantityReductionFee: inventory.isoQuantityReductionFee,
    isoQuantityRemovalFee: inventory.isoQuantityRemovalFee,
    verraQuantityReductionNet: inventory.verraQuantityReductionNet,
    verraQuantityRemovalNet: inventory.verraQuantityRemovalNet,
    verraQuantityReductionGross: inventory.verraQuantityReductionGross,
    verraQuantityRemovalGross: inventory.verraQuantityRemovalGross,
    verraQuantityPremiumPoolReduction:
      inventory.verraQuantityPremiumPoolReduction,
    verraQuantityPremiumPoolRemoval: inventory.verraQuantityPremiumPoolRemoval,
    verraQuantityBufferReduction: inventory.verraQuantityBufferReduction,
    verraQuantityBufferRemoval: inventory.verraQuantityBufferRemoval,
    verraQuantityReductionFee: inventory.verraQuantityReductionFee,
    verraQuantityRemovalFee: inventory.verraQuantityRemovalFee,
    createdAt: inventory.createdAt,
    finalizedColumns: inventory.finalizedColumns ?? [],
  }));
}
