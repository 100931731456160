import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonPaginatedResponse, MetaArgs } from "@ag/utils/types";

import { AdminUserAssignment } from "../entities/admin";

type GetOwnAssignedUsersParams = MetaArgs & {
  sort?: {
    id?: "asc" | "desc";
  };
  filters?: Partial<{
    userId?: string;
    adminId?: string;
    search?: string;
  }>;
};

export const generateUsersQueryKey = (
  params?: GetOwnAssignedUsersParams | undefined,
) => {
  return ["ownAssignedUsers", params].filter(Boolean);
};

export const getOwnAssignedUsers = async (
  params: GetOwnAssignedUsersParams | undefined,
) => {
  const response = await axios.get<
    CommonPaginatedResponse<AdminUserAssignment>
  >("/api/v1/admins/user-assignments/users", {
    params: {
      ...params,
      includeMeta: true,
      sort: {
        id: "desc",
      },
    },
    apiSource: "node-user-mgt",
  });

  return response.data.success!.data;
};

export const useOwnAssignedUsersQuery = (params?: GetOwnAssignedUsersParams) =>
  useQuery(generateUsersQueryKey(params), () => getOwnAssignedUsers(params), {
    keepPreviousData: true,
  });
