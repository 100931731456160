import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { Admin, AdminSchema } from "../entities/admin";

type GetAdminsParams = MetaArgs & {
  filters?: Partial<{
    email: string;
    adminRoles: string[];
  }>;
};

export const generateAdminsQueryKey = (params?: GetAdminsParams | undefined) =>
  ["admins", params].filter(Boolean);

const getAdmins = async (params: GetAdminsParams | undefined) => {
  const response = await axios.get<CommonPaginatedResponse<Admin>>(
    "/api/v1/admins",
    {
      params: {
        ...params,
        limit: params?.limit || DEFAULT_LIMIT,
        sort: {
          id: "desc",
        },
      },
      schema: createPaginatedDataSchema(AdminSchema),
      apiSource: "node-auth",
    },
  );

  return response.data.success!.data;
};

export const useAdminsQuery = (params?: GetAdminsParams) =>
  useQuery(generateAdminsQueryKey(params), () => getAdmins(params), {
    keepPreviousData: true,
  });
