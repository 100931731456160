import clsx from "clsx";
import { Children, cloneElement, isValidElement } from "react";

import { Label } from "@ag/design-system/atoms";
import { createChildValidator } from "@ag/utils/helpers";

/* -------------------------------------------------------------------------------------------------
 * DataCard
 * -----------------------------------------------------------------------------------------------*/
const dataCardChildValidator = createChildValidator("DataCard", {
  Title: DataCardTitle,
  Row: DataCardRow,
});

type DataCardProps = {
  children:
    | React.ReactElement<typeof DataCardTitle | typeof DataCardRow>
    | React.ReactElement<typeof DataCardTitle | typeof DataCardRow>[];
  className?: string;
  isInner?: boolean;
};

function DataCard({ children, className, isInner }: DataCardProps) {
  const errorMsg = dataCardChildValidator(children);
  if (errorMsg) throw Error(errorMsg);

  return (
    <div
      className={clsx(
        "flex flex-col gap-3 rounded-sm bg-white-100 p-4 text-p2",
        isInner && "border border-grey-300",
        className,
      )}
    >
      {children}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * DataCardTitle
 * -----------------------------------------------------------------------------------------------*/
function DataCardTitle({ children }: React.PropsWithChildrenRequired) {
  return <h3 className="mb-1 text-h3">{children}</h3>;
}

/* -------------------------------------------------------------------------------------------------
 * DataCardRow
 * -----------------------------------------------------------------------------------------------*/
const dataCardRowChildValidator = createChildValidator("DataCard", {
  Label,
  Value: DataCardValue,
});

type DataCardRowProps = {
  children:
    | React.ReactElement<typeof Label | typeof DataCardValue>
    | React.ReactElement<typeof Label | typeof DataCardValue>[];
  highlight?: boolean;
};

function DataCardRow({ children, highlight }: DataCardRowProps) {
  const errorMsg = dataCardRowChildValidator(children);
  if (errorMsg) throw Error(errorMsg);

  return (
    <div className={clsx(highlight && "rounded bg-grey-100 p-2")}>
      {children}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * DataCardValue
 * -----------------------------------------------------------------------------------------------*/
function DataCardValue({
  children,
  className,
}: React.PropsWithChildrenRequired & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx("text-base group", className)}>
      {Children.map(children, child => {
        if (isValidElement(child) && child.type === "a") {
          return cloneElement(child, {
            className: clsx(
              child.props.className,
              "text-p2 text-accent-200 underline",
            ),
          } as React.HTMLAttributes<HTMLAnchorElement>);
        }
        return child;
      })}
    </div>
  );
}

const Root = DataCard;
const Title = DataCardTitle;
const Row = DataCardRow;
const Value = DataCardValue;

export default { Root, Title, Row, Label, Value };
