import { CellContext } from "@tanstack/react-table";
import has from "lodash/has";
import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { Tooltip } from "~atoms";
import { cn } from "~utils";

// Adjust when table pad changes
const CELL_INLINE_PADDING = 32;

/* -------------------------------------------------------------------------------------------------
 * TextCell
 * -----------------------------------------------------------------------------------------------*/
type TextCellConfig = {
  title?: string;
  subTitle: string;
  skipClamp?: boolean;
};

// Unkown is needed here, bc some of the tanstack types rely on unknown.
// This also means we have to do some extra type checking in the component.
export type TextCellValue =
  | unknown
  | string
  | TextCellConfig
  | null
  | undefined;

export function TextCell<TValue extends TextCellValue>({
  getValue,
  column,
  table,
}: CellContext<any, TValue>) {
  const value = getValue();
  const ref = useRef<HTMLSpanElement | null>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const texts = table.options.meta?.texts;

  if (!texts?.copiedToClipboard) {
    throw new Error("Please provide table texts when using TextCell");
  }

  const { maxSize, size } = column.columnDef;
  const currentSize = column.getSize();
  const maxWidth = size ? currentSize || maxSize : maxSize;

  // TODO: is this being run too often on a big table?
  useLayoutEffect(() => {
    if (!maxWidth) return;

    if (
      ref.current?.offsetWidth &&
      ref.current?.offsetWidth > maxWidth - CELL_INLINE_PADDING
    ) {
      setIsOverflowing(true);
    }
  }, [maxWidth]);

  if (!value) return <span>-</span>;

  const { title, subTitle, skipClamp } = parseValue(value);

  return maxWidth && !skipClamp && isOverflowing ? (
    <ClampedTextCell
      subTitle={subTitle}
      title={title}
      copiedToClipboardLabel={texts?.copiedToClipboard ?? ""}
    >
      {title ? <h5 className="mb-1 font-bold">{title}</h5> : null}
      {subTitle}
    </ClampedTextCell>
  ) : (
    <span ref={ref}>
      {title ? <h5 className="mb-1 font-bold">{title}</h5> : null}
      {subTitle}
    </span>
  );
}

type TextCellTooltipProps = React.PropsWithChildrenRequired<{
  children: ReactNode;
  subTitle: string;
  title?: string;
  copiedToClipboardLabel: string;
}>;

export function ClampedTextCell({
  children,
  title,
  subTitle,
  copiedToClipboardLabel,
}: TextCellTooltipProps) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = useCallback(() => {
    if (!navigator.clipboard) return;

    navigator.clipboard.writeText(title ? title + "\n" + subTitle : subTitle);
    setIsCopied(true);
  }, [subTitle, title]);

  const tooltipContent = isCopied
    ? copiedToClipboardLabel
    : [title ? `<strong>${title}</strong>` : "", subTitle]
        .filter(Boolean)
        .join("<br />");

  return (
    <Tooltip content={tooltipContent} onClose={() => setIsCopied(false)}>
      <span
        className={cn(
          "cursor block overflow-hidden text-ellipsis whitespace-nowrap",
          {
            "cursor-copy": isCopied,
          },
        )}
        onClick={handleCopy}
      >
        {children}
      </span>
    </Tooltip>
  );
}

function isTextCellTitle(value: unknown): value is TextCellConfig {
  return (
    typeof value === "object" && (has(value, "subTitle") || has(value, "title"))
  );
}

function parseValue(value: unknown): TextCellConfig {
  if (isTextCellTitle(value)) {
    return {
      title: value.title ?? "",
      subTitle: value.subTitle,
      skipClamp: value.skipClamp,
    };
  }

  if (Array.isArray(value)) {
    throw new Error(
      [
        "TextCell was provided with an Array value: ",
        JSON.stringify(value),
      ].join("\n"),
    );
  }

  if (typeof value === "object") {
    throw new Error(
      [
        "TextCell was provided with an object that didn't match { title, subTitle } contract.",
        JSON.stringify(value),
      ].join("\n"),
    );
  }

  return {
    subTitle: (value ?? "").toString(),
  };
}
