import { useState } from "react";

import { Button, Checkbox } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";

import { IssueSeverityFlagTypes } from "../entities/field-with-all-boundaries";
import { getIsFieldUnableToComputeResults } from "../helpers/get-is-field-unable-to-compute-results";
import { BoundaryMapField } from "../types/boundary-map-field";
import { CheckResultInfoCard } from "./check-result-info-card";

export type OverrideFlagsModalProps = {
  selectedField: BoundaryMapField;
  isOpen: boolean;
  onSubmitOverride: () => void;
  onRequestClose: () => void;
};

export function OverrideFlagsModal({
  selectedField,
  isOpen,
  onSubmitOverride,
  onRequestClose,
}: OverrideFlagsModalProps) {
  const [acknowledgedNoEditsRequired, setAcknowledgedNoEditsRequired] =
    useState(false);

  if (!selectedField.id) return null;

  return (
    <Modal.Root size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <Modal.Header>
        <Modal.Title>Submit boundary and clear flags</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <p className="text-p2">
          You are about to submit the active boundary as is, which will clear
          the following flags. This action cannot be undone.
        </p>

        {selectedField.boundaryStatus &&
          getIsFieldUnableToComputeResults(selectedField) && (
            <CheckResultInfoCard isUnableToComputeCheckResults={true} />
          )}

        {selectedField.checkResults?.iou?.flag &&
          selectedField.checkResults?.iou?.flag !==
            IssueSeverityFlagTypes.NONE && (
            <CheckResultInfoCard
              IOUCheckResult={selectedField.checkResults?.iou}
            />
          )}

        <div className="flex flex-row items-center gap-4">
          <Checkbox
            checked={acknowledgedNoEditsRequired}
            onChange={e => setAcknowledgedNoEditsRequired(e.target.checked)}
            size={16}
          />

          <p className="text-p2 italic">
            I have reviewed the active boundary and no further edits are
            required
          </p>
        </div>
      </Modal.Content>
      <Modal.Footer
        render={onClose => (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>

            <Button
              variant="primary"
              disabled={!acknowledgedNoEditsRequired}
              onClick={onSubmitOverride}
            >
              Submit and clear flags
            </Button>
          </>
        )}
      />
    </Modal.Root>
  );
}
