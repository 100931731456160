import {
  OnChangeFn,
  RowSelectionState,
  TableMeta,
  createColumnHelper,
} from "@tanstack/react-table";
import { isEmpty } from "lodash";
import { useMemo } from "react";

import { MethodologyVersion } from "@ag/carbon/types";
import {
  ActionsCell,
  ActionsCellValue,
  BooleanCell,
  BooleanCellValue,
  DateCell,
  DateCellValue,
  HeaderSelectionCell,
  LinkCell,
  LinkCellValue,
  SelectionCell,
  TableState,
  TextCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { RawDataCell } from "~components/RawDataCell";
import { CoverCropHbCell } from "~components/cover-crop-hb-cell";
import { useTable } from "~components/table";
import {
  CarbonAbility,
  CarbonPermissions,
  CarbonResourceClass,
} from "~features/permission";

import { Field } from "../entities/field";
import {
  QualityControl,
  QualityControlCategory,
  QualityControlCheck,
  QualityControlCheckKey,
  QualityControlStatus,
} from "../entities/quality-control";
import { getQualityControlStatusLabel } from "../helpers/get-labels";

export type TableData = {
  userId: LinkCellValue;
  fieldId: LinkCellValue;
  farmerName: TextCellValue;
  country: TextCellValue;
  farmName: TextCellValue;
  fieldName: TextCellValue;
  methodologyVersion: TextCellValue;
  hbPercentage: TextCellValue;
  sizeHa: TextCellValue;
  fallow: BooleanCellValue;

  /**
   * Checks/QualityControl columns
   *
   * Each column has corresponding meta value set on the row data,
   * used to set the cellBackground flag color.
   */

  // Fertiliser
  fertiliser: string | undefined;
  fertiliserQualityControl: QualityControl | undefined;

  fertiliserSyntheticNitrogenApplication: string | undefined;
  fertiliserSyntheticNitrogenApplicationCheck: QualityControlCheck | undefined;

  fertiliserOrganicNitrogenApplication: string | undefined;
  fertiliserOrganicNitrogenApplicationCheck: QualityControlCheck | undefined;

  fertiliserOrganicApplicationRates: string | undefined;
  fertiliserOrganicApplicationRatesCheck: QualityControlCheck | undefined;

  fertiliserMinMax: string | undefined;
  fertiliserMinMaxCheck: QualityControlCheck | undefined;

  // Crop
  crop: string | undefined;
  cropQualityControl: QualityControl | undefined;

  cropYield: string | undefined;
  cropYieldCheck: QualityControlCheck | undefined;

  // Cover crop
  coverCrop: string | undefined;
  coverCropQualityControl: QualityControl | undefined;

  coverCropHb: string | undefined;
  coverCropHbCheck: QualityControlCheck | undefined;

  coverCropHbData: string | undefined;
  coverCropHbDataCheck: QualityControlCheck | undefined;

  cropTypeCategory: string | undefined;
  cropTypeCategoryCheck: QualityControlCheck | undefined;

  // Residue management
  residueManagement: string | undefined;
  residueManagementQualityControl: QualityControl | undefined;

  // Soil disturbance
  soilDisturbance: string | undefined;
  soilDisturbanceQualityControl: QualityControl | undefined;

  soilDisturbanceDetectedTillage: string | undefined;
  soilDisturbanceDetectedTillageCheck: QualityControlCheck | undefined;

  // Fuel
  fuel: string | undefined;
  fuelQualityControl: QualityControl | undefined;

  fuelTillageCategory: string | undefined;
  fuelTillageCategoryCheck: QualityControlCheck | undefined;

  // ---- Checks end ----

  comment: BooleanCellValue;
  actualsEdited: DateCellValue;
  status: TextCellValue;
  qaStatus: TextCellValue;
  lastQcStatusChangedBy: TextCellValue;
  actualsSubmittedAt: DateCellValue;
  actions: ActionsCellValue;

  // We need to keep a reference to the field for bulk actions.
  field: Field;
};

type TableActions = {
  onRun: (params: { fieldId: number }) => void;
  onAddComment: (params: { fieldId: number }) => void;
  onChangeStatus: (params: {
    fieldId: number;
    status: QualityControlStatus;
  }) => void;
  onFieldActualsUpdate: (params: {
    fieldId: number;
    harvestYear: number;
  }) => void;
  onShowActualsChanges: (params: { fieldId: number }) => void;
};

type TableOptions = {
  carbonQualityControlYear: string | undefined;
  permissions: CarbonPermissions | undefined;
  errors: TableMeta<TableData>["errors"];
  state?: TableState<TableData>;
  onSelectionChange: OnChangeFn<RowSelectionState>;
} & TableActions;

export const INITIAL_QUALITY_CONTROL_TABLE_STATE: TableState<TableData> = {
  columnPinning: {
    left: ["select", "userId", "fieldId"],
    right: ["actions"],
  },
  rowSelection: {},
};

export const useFieldsTable = ({
  data,
  carbonQualityControlYear,
  permissions,
  errors,
  state,
  onSelectionChange,
  onRun,
  onAddComment,
  onChangeStatus,
  onFieldActualsUpdate,
  onShowActualsChanges,
}: {
  data: Field[] | undefined;
} & TableOptions) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(
    () =>
      getRowData(data, {
        carbonQualityControlYear,
        permissions,
        onAddComment,
        onChangeStatus,
        onFieldActualsUpdate,
        onRun,
        onShowActualsChanges,
      }),
    [
      carbonQualityControlYear,
      data,
      onAddComment,
      onChangeStatus,
      onFieldActualsUpdate,
      onRun,
      onShowActualsChanges,
      permissions,
    ],
  );

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => original.field.id.toString(),
    meta: {
      errors: errors,
      isUsingCellBackgrounds: true,
    },
    state: state ?? INITIAL_QUALITY_CONTROL_TABLE_STATE,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: onSelectionChange,
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.display({
      id: "select",
      header: HeaderSelectionCell,
      cell: SelectionCell,
      enablePinning: true,
      size: 48,
    }),

    columnHelper.accessor("userId", {
      cell: LinkCell,
      header: I18n.t("js.admin.quality_control.list.user_id"),
      enablePinning: true,
      size: 80,
    }),

    columnHelper.accessor("fieldId", {
      cell: LinkCell,
      header: I18n.t("js.admin.quality_control.list.field_id"),
      enablePinning: true,
      size: 80,
    }),

    columnHelper.accessor("farmerName", {
      header: I18n.t("js.admin.quality_control.list.farmer_name"),
    }),

    columnHelper.accessor("country", {
      header: I18n.t("js.admin.quality_control.list.country"),
    }),

    columnHelper.accessor("farmName", {
      header: I18n.t("js.admin.quality_control.list.farm_name"),
      enableResizing: true,
      size: 80,
    }),

    columnHelper.accessor("fieldName", {
      header: I18n.t("js.admin.quality_control.list.field_name"),
      maxSize: 200,
    }),

    columnHelper.accessor("methodologyVersion", {
      header: I18n.t("js.admin.quality_control.list.methodology"),
    }),

    columnHelper.accessor("hbPercentage", {
      header: I18n.t("js.admin.quality_control.list.hb_percentage"),
      enablePinning: true,
      size: 80,
    }),

    columnHelper.accessor("sizeHa", {
      header: "Size (ha)",
    }),

    columnHelper.accessor("fallow", {
      header: "Fallow",
      cell: BooleanCell,
    }),

    columnHelper.group({
      id: "fertiliser",

      columns: [
        columnHelper.accessor("fertiliser", {
          header: I18n.t("js.admin.quality_control.list.fertiliser"),
          cell: RawDataCell,
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.fertiliserQualityControl?.flag === "none"
                ? undefined
                : rowData.fertiliserQualityControl?.flag,
          },
        }),

        columnHelper.accessor("fertiliserSyntheticNitrogenApplication", {
          header: "Fertiliser Synthetic kg/N",
          cell: RawDataCell,
        }),

        columnHelper.accessor("fertiliserOrganicNitrogenApplication", {
          header: "Fertiliser Organic kg/N",
          cell: RawDataCell,
        }),

        columnHelper.accessor("fertiliserOrganicApplicationRates", {
          header: "Fertiliser Organic Rates",
          cell: RawDataCell,
          maxSize: 1200,
        }),

        columnHelper.accessor("fertiliserMinMax", {
          header: I18n.t("js.admin.quality_control.list.fertiliser_min_max"),
          cell: RawDataCell,
        }),
      ],
    }),

    columnHelper.group({
      id: "crop",

      columns: [
        columnHelper.accessor("crop", {
          header: I18n.t("js.admin.quality_control.list.crop"),
          cell: RawDataCell,
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.cropQualityControl?.flag === "none"
                ? undefined
                : rowData.cropQualityControl?.flag,
          },
          maxSize: 1200,
        }),

        columnHelper.accessor("cropYield", {
          header: I18n.t("js.admin.quality_control.list.crop_yield"),
          cell: RawDataCell,
        }),
      ],
    }),

    columnHelper.group({
      id: "coverCrop",

      columns: [
        columnHelper.accessor("coverCrop", {
          header: I18n.t("js.admin.quality_control.list.cover_crop"),
          cell: RawDataCell,
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.coverCropQualityControl?.flag === "none"
                ? undefined
                : rowData.coverCropQualityControl?.flag,
          },
        }),

        columnHelper.accessor("coverCropHb", {
          header: I18n.t("js.admin.quality_control.list.cover_crop_hb"),
          cell: RawDataCell,
        }),

        columnHelper.accessor("coverCropHbData", {
          header: I18n.t("js.admin.quality_control.list.cover_crop_hb_data"),
          cell: CoverCropHbCell,
          maxSize: 2000,
        }),

        columnHelper.accessor("cropTypeCategory", {
          header: I18n.t("js.admin.quality_control.list.crop_category_type"),
          cell: RawDataCell,
        }),
      ],
    }),

    columnHelper.accessor("residueManagement", {
      header: I18n.t("js.admin.quality_control.list.residue_management"),
      cell: RawDataCell,
      meta: {
        cellBackground: (_value, rowData) =>
          rowData.residueManagementQualityControl?.flag === "none"
            ? undefined
            : rowData.residueManagementQualityControl?.flag,
      },
    }),

    columnHelper.group({
      id: "soilDisturbance",

      columns: [
        columnHelper.accessor("soilDisturbance", {
          header: I18n.t("js.admin.quality_control.list.soil_disturbance"),
          cell: RawDataCell,
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.soilDisturbanceQualityControl?.flag === "none"
                ? undefined
                : rowData.soilDisturbanceQualityControl?.flag,
          },
        }),

        columnHelper.accessor("soilDisturbanceDetectedTillage", {
          header: "Soil disturbance detected",
          cell: RawDataCell,
        }),
      ],
    }),

    columnHelper.group({
      id: "fuel",

      columns: [
        columnHelper.accessor("fuel", {
          header: "Fuel",
          cell: RawDataCell,
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.fuelQualityControl?.flag === "none"
                ? undefined
                : rowData.fuelQualityControl?.flag,
          },
        }),

        columnHelper.accessor("fuelTillageCategory", {
          header: "Fuel tillage category",
          cell: RawDataCell,
        }),
      ],
    }),

    columnHelper.accessor("comment", {
      header: I18n.t("js.admin.quality_control.list.comment"),
    }),

    columnHelper.accessor("actualsEdited", {
      header: I18n.t("js.admin.quality_control.list.actuals_edited_at"),
      cell: DateCell,
    }),

    columnHelper.accessor("status", {
      header: "QC status",
      size: 160,
      enablePinning: true,
    }),

    columnHelper.accessor("qaStatus", {
      header: "QA status",
      size: 160,
      enablePinning: true,
    }),

    columnHelper.accessor("lastQcStatusChangedBy", {
      header: I18n.t("js.admin.quality_control.list.last_changed_by"),
      enableResizing: true,
      size: 120,
    }),

    columnHelper.accessor("actualsSubmittedAt", {
      header: I18n.t("js.admin.quality_control.list.actuals_submitted_at"),
      cell: DateCell,
    }),

    columnHelper.accessor("actions", {
      header: I18n.t("js.shared.actions"),
      enablePinning: true,
      cell: ActionsCell,
      size: 128,
    }),
  ];
}

function getRowData(
  fields: Field[] | undefined,
  options: Pick<TableOptions, "carbonQualityControlYear" | "permissions"> &
    TableActions,
): TableData[] {
  if (!fields) return [];

  return fields.map(field => {
    const { carbonFieldActual } = field;
    const { harvestYear } = carbonFieldActual;

    // Fertiliser
    const fertiliserQualityControl = field.carbonQualityControls.find(
      ({ category, year }) =>
        category === QualityControlCategory.Fertiliser && year === harvestYear,
    );

    const fertiliserSyntheticNitrogenApplicationCheck =
      fertiliserQualityControl?.checks.find(
        ({ key }) =>
          key === QualityControlCheckKey.FertiliserSyntheticNitrogenApplication,
      );

    const fertiliserOrganicNitrogenApplicationCheck =
      fertiliserQualityControl?.checks.find(
        ({ key }) =>
          key === QualityControlCheckKey.FertiliserOrganicNitrogenApplication,
      );

    const fertiliserOrganicApplicationRatesCheck =
      fertiliserQualityControl?.checks.find(
        ({ key }) =>
          key === QualityControlCheckKey.FertiliserOrganicApplicationRates,
      );

    const fertiliserMinMaxCheck = fertiliserQualityControl?.checks.find(
      ({ key }) => key === QualityControlCheckKey.FertiliserMinMax,
    );

    // Crop
    const cropQualityControl = field.carbonQualityControls.find(
      ({ category, year }) =>
        category === QualityControlCategory.Crop && year === harvestYear,
    );

    const cropYieldCheck = cropQualityControl?.checks.find(
      ({ key }) => key === QualityControlCheckKey.CropYield,
    );

    // Soil disturbance
    const soilDisturbanceQualityControl = field.carbonQualityControls.find(
      ({ category, year }) =>
        category === QualityControlCategory.SoilDisturbance &&
        year === harvestYear,
    );
    const soilDisturbanceDetectedTillageCheck =
      soilDisturbanceQualityControl?.checks.find(
        ({ key }) =>
          key === QualityControlCheckKey.SoilDisturbanceDetectedTillage,
      );

    // Residue management
    const residueManagementQualityControl = field.carbonQualityControls.find(
      ({ category, year }) =>
        category === QualityControlCategory.ResidueManagement &&
        year === harvestYear,
    );

    // Cover crop
    const coverCropQualityControl = field.carbonQualityControls.find(
      ({ category, year }) =>
        category === QualityControlCategory.CoverCrop && year === harvestYear,
    );
    const coverCropHbCheck = coverCropQualityControl?.checks.find(
      ({ key }) => key === QualityControlCheckKey.CoverCropHb,
    );
    const coverCropHbDataCheck = coverCropQualityControl?.checks.find(
      ({ key }) => key === QualityControlCheckKey.CoverCropHbData,
    );
    const cropTypeCategoryCheck = coverCropQualityControl?.checks.find(
      ({ key }) => key === QualityControlCheckKey.CropTypeCategory,
    );

    // Fuel
    const fuelQualityControl = field.carbonQualityControls.find(
      ({ category }) => category === QualityControlCategory.Fuel,
    );
    const fuelTillageCategoryCheck = fuelQualityControl?.checks.find(
      ({ key }) => key === QualityControlCheckKey.FuelTillageCategory,
    );

    const lastQualityControlAction = carbonFieldActual.latestCarbonQcAction;

    const isQualityControlYear =
      options.carbonQualityControlYear &&
      carbonFieldActual.harvestYear ===
        Number(options.carbonQualityControlYear);

    const hasEditFieldActualsAccess = options.permissions?.[
      CarbonAbility.CqcUpdate
    ]?.includes(CarbonResourceClass.CarbonFieldActual);

    // TODO: is there an easier way to check this?
    const canEditActuals =
      carbonFieldActual.qcStatus === QualityControlStatus.NonConformance &&
      carbonFieldActual.submittedAt &&
      carbonFieldActual.methodologyVersion !== MethodologyVersion.V1 &&
      isQualityControlYear &&
      hasEditFieldActualsAccess;

    return {
      userId: {
        url: `/users/${field.user.id}`,
        title: field.user.id,
      },
      fieldId: {
        url: `/carbon/fields/${field.id}`,
        title: field.id,
      },
      farmName: field.carbonFarm.name,
      fieldName: field.name,
      methodologyVersion: Number(field.carbonFieldActual.methodologyVersion),
      sizeHa: field.sizeHa,
      fallow: carbonFieldActual?.fallow,
      farmerName: field.user.name,
      hbPercentage: `${
        parseFloat(field.carbonFieldActual.hummingbirdCqcDataAvailability) * 100
      }%`,
      country: field.carbonCountry.name,
      status: getQualityControlStatusLabel(carbonFieldActual.qcStatus),
      qaStatus: {
        processing: "Processing",
        non_conformance: "Non conformance",
        non_compliance: "Non compliance",
        approved: "Approved",
      }[field.qaStatus],

      /* VERIFICATIONS */
      // fertiliser
      fertiliser: formatQualityControlValue(fertiliserQualityControl),
      fertiliserQualityControl,

      fertiliserSyntheticNitrogenApplication: formatQualityControlValue(
        fertiliserSyntheticNitrogenApplicationCheck,
      ),
      fertiliserSyntheticNitrogenApplicationCheck,

      fertiliserOrganicNitrogenApplication: formatQualityControlValue(
        fertiliserOrganicNitrogenApplicationCheck,
      ),
      fertiliserOrganicNitrogenApplicationCheck,

      fertiliserOrganicApplicationRates: formatQualityControlValue(
        fertiliserOrganicApplicationRatesCheck,
      ),
      fertiliserOrganicApplicationRatesCheck,

      fertiliserMinMax: formatQualityControlValue(fertiliserMinMaxCheck),
      fertiliserMinMaxCheck,

      // Crop
      crop: formatQualityControlValue(cropQualityControl),
      cropQualityControl,

      cropYield: formatQualityControlValue(cropYieldCheck),
      cropYieldCheck,

      // soil disturbance
      soilDisturbance: formatQualityControlValue(soilDisturbanceQualityControl),
      soilDisturbanceQualityControl,

      soilDisturbanceDetectedTillage: formatQualityControlValue(
        soilDisturbanceDetectedTillageCheck,
      ),
      soilDisturbanceDetectedTillageCheck,

      // Residue management
      residueManagement: formatQualityControlValue(
        residueManagementQualityControl,
      ),
      residueManagementQualityControl,

      // Cover crop
      coverCrop: formatQualityControlValue(coverCropQualityControl),
      coverCropQualityControl,

      coverCropHb: formatQualityControlValue(coverCropHbCheck),
      coverCropHbCheck,

      coverCropHbData: formatQualityControlValue(coverCropHbDataCheck),
      coverCropHbDataCheck,

      cropTypeCategory: formatQualityControlValue(cropTypeCategoryCheck),
      cropTypeCategoryCheck,

      // Fuel
      fuel: formatQualityControlValue(fuelQualityControl),
      fuelQualityControl,

      fuelTillageCategory: formatQualityControlValue(fuelTillageCategoryCheck),
      fuelTillageCategoryCheck,

      lastQcStatusChangedBy: lastQualityControlAction?.adminId,

      actualsSubmittedAt: carbonFieldActual.submittedAt,

      actualsEdited: carbonFieldActual.updatedAt,

      comment: !isEmpty(field.carbonFieldActual.carbonQualityControlComments),

      actions: {
        title: I18n.t("js.shared.actions"),
        items: [
          {
            children: I18n.t("js.admin.quality_control.list.run"),
            onClick: () => options.onRun({ fieldId: field.id }),
          },
          {
            children: I18n.t("js.admin.quality_control.list.add_comment"),
            onClick: () => options.onAddComment({ fieldId: field.id }),
          },
          {
            children: I18n.t(
              "js.admin.quality_control.list.show_actuals_changes",
            ),
            onClick: () => options.onShowActualsChanges({ fieldId: field.id }),
          },
          canEditActuals
            ? {
                children: I18n.t(
                  "js.admin.quality_control.list.edit_field_actuals",
                ),
                onClick: () =>
                  options.onFieldActualsUpdate({
                    fieldId: field.id,
                    harvestYear: carbonFieldActual.harvestYear,
                  }),
              }
            : null,
        ],
      },

      field,
    };
  });
}

function formatQualityControlValue(
  data:
    | {
        value: unknown;
        unit?: string | null;
      }
    | undefined,
): string | undefined {
  if (!data) return undefined;

  const { value, unit } = data;

  if (value == null) return undefined;

  const formattedValue = value
    .toString()
    .replace(/[,]\s?(20[\d]{2}\s)/g, ",\n$1");

  return unit ? `${formattedValue} ${unit}` : formattedValue;
}
