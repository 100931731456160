import { CellContext, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";

import { Icon } from "@ag/design-system/assets";
import {
  ActionsCell,
  ActionsCellValue,
  DateCell,
  LinkCell,
  LinkCellValue,
  TextCell,
  TextCellValue,
} from "@ag/design-system/organisms";

import {
  ACTIONS_CELL_WIDTH,
  TableSortingState,
  useTable,
} from "~components/table";

import { downloadClientDocument } from "../api/download-client-document";
import { Client } from "../entities/client";

type TableData = {
  id: string;
  adminId?: LinkCellValue | TextCellValue;
  name: string;
  createdAt: string;
  actions: ActionsCellValue | null;
  documents: ActionsCellValue | null;
};

export const useClientsTable = (
  data: Client[] | undefined,
  state: TableSortingState,
  onClientDelete: (args: { id: string; name: string }) => void,
  onClientUpdate: (args: { id: string }) => void,
  hasAdminAccess?: boolean,
  hasDeletePermission?: boolean,
  hasUpdatePermission?: boolean,
) => {
  const rowData = useMemo(
    () =>
      getRowData(
        data,
        onClientDelete,
        onClientUpdate,
        hasAdminAccess,
        hasDeletePermission,
        hasUpdatePermission,
      ),
    [
      data,
      onClientDelete,
      onClientUpdate,
      hasAdminAccess,
      hasDeletePermission,
      hasUpdatePermission,
    ],
  );
  return useTable<TableData>({
    columns: getColumns(),
    data: rowData,
    state,
    onSortingChange: state.setSorting,
  });

  function getColumns() {
    const columnHelper = createColumnHelper<TableData>();

    return [
      columnHelper.accessor("id", {
        header: "ID",
      }),
      columnHelper.accessor("name", {
        header: "Name",
        enableSorting: true,
      }),
      columnHelper.accessor("adminId", {
        header: "Created By",
        cell: hasAdminAccess ? LinkCell : TextCell,
      }),
      columnHelper.accessor("createdAt", {
        header: "Created At",
        cell: DateCell,
        enableSorting: true,
      }),
      columnHelper.accessor("actions", {
        header: "Actions",
        cell: (props: CellContext<TableData, ActionsCellValue | null>) => {
          const value = props.getValue();
          return value ? (
            <ActionsCell
              {...(props as CellContext<TableData, ActionsCellValue>)}
            />
          ) : null;
        },
        size: ACTIONS_CELL_WIDTH,
      }),
      columnHelper.accessor("documents", {
        header: "Documentation",
        cell: props => {
          const value = props.getValue();

          if (!value) {
            return "-";
          }

          if ("items" in value) {
            return (
              <ActionsCell
                {...(props as CellContext<TableData, ActionsCellValue>)}
              />
            );
          }
        },
      }),
    ];
  }

  function getRowData(
    data: Client[] | undefined,
    onClientDelete: (args: { id: string; name: string }) => void,
    onClientUpdate: (args: { id: string }) => void,
    hasAdminAccess?: boolean,
    hasDeletePermission?: boolean,
    hasUpdatePermission?: boolean,
  ): TableData[] {
    if (!data) return [];

    return data.map(client => {
      const actionsChildren = [
        hasDeletePermission
          ? {
              children: "Delete",
              isDanger: true,
              onClick: () =>
                onClientDelete({ id: client.id, name: client.name }),
            }
          : null,
        hasUpdatePermission
          ? {
              children: "Edit",
              onClick: () => onClientUpdate({ id: client.id }),
            }
          : null,
      ].filter(Boolean);

      return {
        id: client.id,
        name: client.name,
        adminId: client.adminId
          ? hasAdminAccess
            ? {
                title: client.adminId,
                url: `/admins/${client.adminId}`,
              }
            : client.adminId
          : undefined,
        createdAt: client.createdAt,
        actions:
          actionsChildren.length > 0
            ? {
                title: "Actions",
                items: actionsChildren,
              }
            : null,
        documents: getDocumentsCellValue({
          documents: client.documents,
        }),
      };
    });
  }
};

export function getDocumentsCellValue({
  documents,
}: {
  documents: Client["documents"];
}): ActionsCellValue | null {
  if (!documents || documents.length === 0) {
    return null;
  }

  const items = documents.map(document => ({
    children: (
      <div className="flex items-center gap-1">
        <span>{document.filename}</span>
        <Icon name="fileArrowDown" />
      </div>
    ),
    onClick: () =>
      downloadClientDocument({
        staticFileId: document.url.split("/").pop() ?? "",
        filename: document.filename,
      }),
  }));

  return {
    title: "Documents",
    variant: "default",
    triggerVariant: "text",
    placement: "end",
    items,
  };
}
