import * as z from "zod";

import { AuthAbility } from "../types/abilities";
import { AuthResourceClass } from "../types/resources";

export const AuthPermissionsSchema = z.record(
  z.nativeEnum(AuthAbility),
  z.array(z.nativeEnum(AuthResourceClass)),
);

export type AuthPermissions = z.infer<typeof AuthPermissionsSchema>;
