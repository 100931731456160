import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  ActionsCell,
  ActionsCellValue,
  ChipCell,
  ChipCellValue,
  LinkCell,
  LinkCellValue,
  TextCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { ACTIONS_CELL_WIDTH, useTable } from "~components/table";

import { Admin } from "../entities/admin";

type TableData = {
  actions: ActionsCellValue;
  id: LinkCellValue;
  email: TextCellValue;
  locale: string;
  roles: ChipCellValue;

  admin: Admin;
};

type AdminsTableConfig = {
  data: Admin[] | undefined;
  onAdminDelete: (args: { id: string; email: string }) => void;
};

export const useAdminsTable = ({ data, onAdminDelete }: AdminsTableConfig) => {
  const config = useMemo(
    () => ({ data, onAdminDelete }),
    [data, onAdminDelete],
  );
  const tableData = useMemo(() => getTableData(config), [config]);

  return useTable<TableData>({
    columns: getColumns(),
    data: tableData,
    getRowId: original => String(original.admin.id),
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("actions", {
      header: I18n.t("js.shared.actions"),
      cell: ActionsCell,
      size: ACTIONS_CELL_WIDTH,
    }),
    columnHelper.accessor("id", {
      header: I18n.t("js.admin.admins.id"),
      cell: LinkCell,
    }),
    columnHelper.accessor("email", {
      header: I18n.t("js.admin.admins.email"),
    }),
    columnHelper.accessor("locale", {
      header: I18n.t("js.admin.admins.locale"),
    }),
    columnHelper.accessor("roles", {
      header: I18n.t("js.admin.admins.roles"),
      cell: ChipCell,
    }),
  ];
}

function getTableData({ data, onAdminDelete }: AdminsTableConfig): TableData[] {
  if (!data) return [];

  return data.map(admin => ({
    actions: {
      title: "Actions",
      items: [
        {
          children: "Delete",
          isDanger: true,
          onClick: () => onAdminDelete({ id: admin.id, email: admin.email }),
        },
      ],
    },
    id: {
      url: `/admins/${admin.id}`,
      title: admin.id,
    },
    email: {
      subTitle: admin.email,
      skipClamp: true,
    },
    locale: admin.locale,
    roles: admin.adminRoles.map(role => role.name),

    admin,
  }));
}
