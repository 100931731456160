import omitBy from "lodash/omitBy";
import log from "loglevel";
import { useCallback, useRef, useState } from "react";

import { SoilCultivationPracticeData } from "@ag/carbon/forms/baseline/iso";
import { Button } from "@ag/design-system//atoms";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import CommentModal from "~pages/_legacy/carbon/pages/Fields/pages/EditField/CommentModal";
import { UpdateFieldDefinitionData } from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/types";
import {
  FieldDetails,
  useUpdateFieldDefinitionMutation,
} from "~queries/carbon/fields";

import FieldPartials from "./FieldPartials";
import FieldDefinitionContext, {
  FieldDefinitionPartialsFormAPI,
  SetPartialFormAPIArgs,
  initialFieldDefinitionContext,
} from "./context";
import { SFieldDefinitionTab, SPartialsGroupTitle } from "./styled";
import { isSoilCultivationPracticePartialFormApi } from "./types";

export type Props = {
  field: FieldDetails;
};

const FieldDefinition = ({ field }: Props) => {
  const [fieldDefinitionDataToSubmit, setFieldDefinitionDataToSubmit] =
    useState<UpdateFieldDefinitionData | undefined>();

  const formContentRef = useRef<HTMLDivElement | null>(null);

  const updateFieldDefinitionMutation = useUpdateFieldDefinitionMutation();

  const [partialsFormAPIs, setPartialFormAPI] =
    useState<FieldDefinitionPartialsFormAPI>(
      initialFieldDefinitionContext.partialsFormAPIs,
    );

  const handleSetPartialFormAPI = useCallback(
    ({ partialName, formAPI }: SetPartialFormAPIArgs) => {
      setPartialFormAPI(prevPartialsFormAPIs => ({
        ...prevPartialsFormAPIs,
        [partialName]: formAPI,
      }));
    },
    [],
  );

  const handleSaveAllPartialsClicked = () => {
    // submit all partials
    Object.values(partialsFormAPIs).forEach(
      partialsFormAPI => partialsFormAPI?.submit(),
    );

    // check if the validations errors exist in some partials
    const hasFormErrors = Object.values(partialsFormAPIs).some(
      partialsFormAPI => {
        const formState = partialsFormAPI?.getState();

        return Object.keys(formState?.errors || {}).length > 0;
      },
    );

    if (hasFormErrors) return;

    const formData = Object.values(partialsFormAPIs).reduce(
      (acc, partialsFormAPI) => {
        if (!partialsFormAPI) return acc;

        const formValues = partialsFormAPI.getState().values;

        if (isSoilCultivationPracticePartialFormApi(formValues)) {
          // Remove empty optional attributes that are not allowed by the API
          const questionnaire = omitBy(
            formValues,
            value => value === null,
          ) as SoilCultivationPracticeData;

          return {
            ...acc,
            questionnaire,
          };
        }

        return {
          ...acc,
          ...formValues,
        };
      },
      {} as UpdateFieldDefinitionData,
    );

    setFieldDefinitionDataToSubmit(formData);
  };

  const handleSubmitFieldData = (comment: string) => {
    if (!fieldDefinitionDataToSubmit) {
      log.error(
        "Some update field definition form attributes are empty",
        fieldDefinitionDataToSubmit,
      );

      return;
    }

    updateFieldDefinitionMutation.mutate(
      {
        fieldId: field.id,
        fieldDefinitionId: field.carbonFieldDefinition.id,
        fieldDefinitionData: {
          ...fieldDefinitionDataToSubmit,
          comment: {
            text: comment,
          },
        },
      },
      {
        onSuccess: () => {
          ToastNotification.success(
            I18n.t("js.admin.field_details.field_definition_updated_success"),
          );
        },
      },
    );

    setFieldDefinitionDataToSubmit(undefined);
  };

  const isCommentModalVisible = Boolean(fieldDefinitionDataToSubmit);

  return (
    <>
      <SFieldDefinitionTab ref={formContentRef}>
        <div className="flex flex-col justify-center">
          <SPartialsGroupTitle>
            {I18n.t("js.carbon.basic_data")}
          </SPartialsGroupTitle>

          <FieldDefinitionContext.Provider
            value={{
              partialsFormAPIs,
              setPartialFormAPI: handleSetPartialFormAPI,
            }}
          >
            <FieldPartials field={field} />
          </FieldDefinitionContext.Provider>
        </div>

        <div className="mb-6 mt-4 flex justify-end">
          <Button onClick={handleSaveAllPartialsClicked}>
            {I18n.t("js.shared.save_all")}
          </Button>
        </div>
      </SFieldDefinitionTab>

      <CommentModal
        isOpen={isCommentModalVisible}
        isSubmitting={updateFieldDefinitionMutation.isLoading}
        onClose={() => setFieldDefinitionDataToSubmit(undefined)}
        onSubmit={handleSubmitFieldData}
      />
    </>
  );
};

export default FieldDefinition;
