import { z } from "zod";

export enum BuyoutContractStatus {
  Created = "created",
  Sent = "sent",
  Signed = "signed",
  Withdrawn = "withdrawn",
  Terminated = "terminated",
}

export enum BuyoutPaymentStatus {
  Paid = "paid",
  Unpaid = "unpaid",
}

// TODO: extract this one into shared type for all admin usages
export enum DocusignStatus {
  Sent = "sent",
  Delivered = "delivered",
  Completed = "completed",
  Declined = "declined",
  Voided = "voided",
}

export enum TerminateReasons {
  OTHER = "other",
}

export const ContractDetails = z.object({
  benefitContractIds: z.array(z.string()),
  removalCertificatesQuantity: z.string(),
  reductionCertificatesQuantity: z.string(),
  paymentPricePerRemovalCertificate: z.string(),
  paymentPricePerReductionCertificate: z.string(),
  purchaseAmount: z.string(),
  withheldAmount: z.string(),
  totalPayableAmount: z.string(),
  originCountries: z.array(z.string()),
});

export const SignerDetails = z.object({
  title: z.string(),
  name: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
});

// Values are null if contract type is v2
export const v1BuyoutAgreementSchema = z.object({
  estimatedGhgCertificates: z.number().nullable(),
  pricePerCertificateCents: z.number().nullable(),
  totalPriceCents: z.number().nullable(),
  transferAgreementUrl: z.string().nullable(),
  transferAppendixUrl: z.string().nullable(),
});

// Values are null if contract type is v1
export const v2BuyoutAgreementSchema = z.object({
  saleAndPurchaseAgreementUrl: z.string().nullable(),
  contractDetails: ContractDetails.nullable(),
  signerDetails: SignerDetails.nullable(),
});

export const BuyoutAgreementSchema = z
  .object({
    id: z.string(),
    userId: z.string(),
    year: z.number(),
    companyName: z.string(),
    carbonCountry: z.string().nullable(),
    status: z.nativeEnum(BuyoutContractStatus),
    statusReason: z.string().nullable(),
    paymentStatus: z.nativeEnum(BuyoutPaymentStatus),
    contractVersion: z.string().nullable(),

    docusignEnvelope: z
      .object({
        deliveredAt: z.string().nullable(),
        docusignEnvelopeId: z.string().nullable(),
        docusignStatus: z.nativeEnum(DocusignStatus).nullable(),
        expiresAt: z.string().nullable(),
        id: z.string(),
        sentAt: z.string().nullable(),
        signedAt: z.string().nullable(),
      })
      .nullable(),
  })
  .merge(v1BuyoutAgreementSchema)
  .merge(v2BuyoutAgreementSchema);

export type BuyoutAgreement = z.infer<typeof BuyoutAgreementSchema>;
