import capitalize from "lodash/capitalize";
import { z } from "zod";

import { MethodologyVersion } from "@ag/carbon/types";
import { Locale } from "@ag/i18n";
import { RemapWithPrefix } from "@ag/utils/types";

type LocaleNames = RemapWithPrefix<
  Record<Locale, z.ZodNullable<z.ZodString>>,
  "name"
>;

const localeNames = Object.values(Locale).reduce(
  (acc, value) => ({
    ...acc,
    [`name${capitalize(value)}`]: z.string().nullable(),
  }),
  {} as LocaleNames,
);

export const FertiliserSchema = z
  .object({
    id: z.number(),
    biomass: z.boolean(),
    chemicalSpecification: z.boolean(),
    coolfarmIdentifier: z.number(),
    coolfarmName: z.string(),
    custom: z.boolean(),
    distributor: z.string(),
    name: z.string(),
    identifier: z.string(),
    nitrificationAdded: z.boolean(),
    nitrogenTotal: z.number(),
    organic: z.boolean(),
    organicCarbon: z.number(),
    tradeName: z.string(),
    harvestYear: z.number(),
    methodologyVersion: z.nativeEnum(MethodologyVersion),
    createdAt: z.string(),
    updatedAt: z.string(),
    readonly: z.boolean(),
    ammonium: z.number(),
    distributorCompanyCode: z.string(),
    magnesium: z.number(),
    nitrateNitrogen: z.number(),
    nitrogenUrea: z.number(),
    phosphorus: z.number(),
    phosphorusPentoxide: z.number(),
    potassium: z.number(),
    potassiumOxide: z.number(),
    sulfur: z.number(),
    nameEn: z.string(),
    descriptionEn: z.string(),
  })
  .merge(z.object(localeNames));

export type Fertiliser = z.infer<typeof FertiliserSchema>;

export const FertiliserFiltersSchema = z.object({
  matchFertiliserName: z.string().optional(),
  harvestYear: z.string().optional(),
  methodologyVersion: z.string().optional(),
  matchCoolfarmName: z.string().optional(),
  coolfarmIdentifier: z.string().optional(),
});

export type FertiliserFilters = z.infer<typeof FertiliserFiltersSchema>;
