import {
  RowData,
  TableOptions,
  getCoreRowModel as baseGetCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import { TextCell } from "../../cells/text-cell";

export type UseListOptions<T extends RowData> = Omit<
  TableOptions<T>,
  "getCoreRowModel" | "state" | "manualSorting"
> & {
  state?: NonNullable<TableOptions<T>["state"]>;
  getCoreRowModel?: TableOptions<T>["getCoreRowModel"];
};

const DEFAULT_MAX_COLUMN_SIZE = 340;

export type ListState<T extends RowData> = UseListOptions<T>["state"];

export const useList = <T>({
  getCoreRowModel = baseGetCoreRowModel(),
  defaultColumn,
  columns,
  state,
  ...rest
}: UseListOptions<T>) => {
  const instance = useReactTable<T>({
    // defaults
    defaultColumn: {
      cell: TextCell,
      size: 0,
      minSize: 0,
      enableResizing: false,
      maxSize: DEFAULT_MAX_COLUMN_SIZE,
      ...(defaultColumn ?? {}),
    },
    getCoreRowModel,
    enableRowSelection: false,
    enableMultiRowSelection: false,

    // state
    columns,
    state: {
      ...state,
    },

    // sorting
    manualSorting: true,

    // filtering
    manualFiltering: true,

    // rest
    ...rest,
  });

  const instanceHeaders = instance.getFlatHeaders();
  const instanceColumns = instanceHeaders.map(header => header.column);

  const sizeAboveMaxColumn = instanceColumns.find(column => {
    const { size, maxSize } = column.columnDef;
    return maxSize && size && size > maxSize;
  });

  if (sizeAboveMaxColumn) {
    throw new Error(
      [
        `column: "${sizeAboveMaxColumn.id}" is set up with size ${sizeAboveMaxColumn.columnDef.size}, ` +
          `which is above the maxSize value ${sizeAboveMaxColumn.columnDef.maxSize}`,
        "You want to: A) set maxSize instead of size, B) lower size OR c) raise/set maxSize.",
      ].join("\n"),
    );
  }

  return instance;
};
