export const USER_TYPE = {
  FARMER: "farmer",
  TRADING_HOUSE: "trading_house",
  MALTERY: "maltery",
  BROKER: "broker",
  FEEDMILL: "feedmill",
  OILMILL: "oilmill",
  MILL: "mill",
};

// TODO: Add more VAT numbers if needed
export enum VAT_AMOUNT {
  DK = 25,
}

export const COUNTRY_CODE = {
  POLAND: "PL",
  DENMARK: "DK",
  ROMANIA: "RO",
  HUNGARY: "HU",
  SLOVAKIA: "SK",
  LITHUANIA: "LT",
  UK: "GB",
} as const;

export const PRODUCTION_TYPE = {
  PIGS: "pigs",
  EGGS: "eggs",
  CHICKEN: "chicken",
  GRAIN: "grain",
  BEEF: "beef",
  DAIRY: "dairy",
};

export const SUBSCRIPTION_STATUS = {
  INCOMPLETE: "incomplete",
  INCOMPLETE_EXPIRED: "incomplete_expired",
  TRIALING: "trialing",
  ACTIVE: "active",
  PAST_DUE: "past_due",
  CANCELED: "canceled",
  UNPAID: "unpaid",
};

export const PARITY = {
  FOB: "fob",
  CIF: "cif",
};

export const LOCALE = {
  EN: "en",
};

export const GA_EVENT_CATEGORY = {
  CTA: "CTA",
};

export const TRADER_TYPE = {
  BUYER: "buyer",
  SELLER: "seller",
};

export const TRADING_TYPE = {
  BUY: "buy",
  SELL: "sell",
};

export const ADDRESS_TYPE = {
  DELIVERY: "delivery",
};

// https://ec.europa.eu/taxation_customs/vies/faqvies.do#item11
export const VAT_NUMBER_FORMAT = {
  AT: "U99999999",
  BE: "0999999999",
  BG: "999999999",
  CY: "99999999L",
  CZ: "99999999",
  DE: "999999999",
  DK: "99999999",
  EE: "999999999",
  EL: "999999999",
  ES: "X9999999X",
  FI: "99999999",
  FR: "XX999999999",
  GB: "999999999",
  HR: "99999999999",
  HU: "99999999",
  IE: "9S99999L",
  IT: "99999999999",
  LT: "999999999",
  LU: "99999999",
  LV: "99999999999",
  MT: "99999999",
  NL: "999999999B99",
  PL: "9999999999",
  PT: "999999999",
  RO: "999999999",
  SE: "999999999999",
  SI: "99999999",
  SK: "9999999999",
};

export const PAGE_SLICE_KEY = "PAGE";

export const EMPTY_ARRAY = [];

export const TEXT_INPUT_DEBOUNCE_TIME = 500;

export const DEFAULT_LIMIT = 10;

export const FREE_PLAN = "FREE";

/**
 * This list comes from API, in the future will be moved to initial resources
 * https://app.asana.com/0/1120148049704812/1200138272708054
 */
export const EUROPEAN_UNION_COUNTRY_CODES = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DE",
  "DK",
  "EE",
  "ES",
  "FI",
  "FR",
  "GR",
  "HR",
  "HU",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SE",
  "SI",
  "SK",
];
