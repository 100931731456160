import { format } from "date-fns";

import { FieldActualsStatus, FieldDefinitionStatus } from "@ag/carbon/types";
import I18n from "@ag/i18n";

type Status = FieldDefinitionStatus | FieldActualsStatus;

export const getStepStatusVariant = (status: Status | undefined) => {
  if (!status) return "neutral";

  const variant = {
    not_started: "neutral",
    in_progress: "warning",
    completed: "success",
    submitted: "success",
  } as const;

  return variant[status];
};

export const getStepStatusIcon = (status: Status | undefined) => {
  if (!status) return "dash";

  const icon = {
    not_started: "dash",
    in_progress: "loader",
    completed: "check",
    submitted: "lock",
  } as const;

  return icon[status];
};

export const getStepStatusText = (
  status: Status,
  submittedAt: string | null,
) => {
  const text = {
    not_started: I18n.t("js.carbon.field_details.submission_not_started"),
    in_progress: I18n.t("js.carbon.field_details.submission_in_progress"),
    completed: I18n.t("js.carbon.field_details.completed_not_submitted"),
    submitted: I18n.t("js.carbon.field_details.submitted_on", {
      date: submittedAt ? format(new Date(submittedAt), "dd.MM.yyyy") : "",
    }),
  } as const;

  return text[status];
};
