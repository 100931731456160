import { QuestionnaireQuestion } from "@ag/components/Questionnaire";
import { validateQuestionnaireValues } from "@ag/components/form";
import I18n from "@ag/i18n";
import { KeyMap } from "@ag/utils/types";

import {
  SoilCultivationPracticeAttribute,
  SoilCultivationPracticeQuestionnaireAttribute,
  SoilCultivationPracticeQuestionnaireData,
} from "./types";

export type SoilCultivationPracticeDataError = KeyMap<boolean>;

export const getQuestionnaireConfig = (
  isReadonly = false,
): QuestionnaireQuestion<SoilCultivationPracticeQuestionnaireAttribute>[] => {
  const yesNoAnswers = [
    {
      label: I18n.t("js.shared.yes"),
      value: true,
      disabled: isReadonly,
    },
    {
      label: I18n.t("js.shared.no"),
      value: false,
      disabled: isReadonly,
    },
  ];

  return [
    // Questions group
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.CultivatedFieldInTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.cultivated.title",
      ),
      answers: yesNoAnswers,
    },

    // Questions group
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.RotatedCropsInTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.rotated.title",
      ),
      answers: yesNoAnswers,
    },

    // Questions group
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.TillageConventionalInAnyOfTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.conventionally_tilled.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.conventionally_tilled.tooltip",
      ),
      answers: yesNoAnswers,
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.TillageReducedInAnyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.reduced_tillage.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.reduced_tillage.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.TillageConventionalInAnyOfTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.TillageConventionalInAnyOfTheLastFiveYears,
      },
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.TillageNoTillConsistentlyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.consistenly_no_tilled.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.consistenly_no_tilled.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.TillageReducedInAnyTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.TillageConventionalInAnyOfTheLastFiveYears,
      },
    },

    // Questions group
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.CropResidueBurnedInAnyOfTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.burned_crop_residue.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.burned_crop_residue.tooltip",
      ),
      answers: yesNoAnswers,
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.CropResidueRemovedInAnyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.removed_crop_residue.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.removed_crop_residue.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.CropResidueBurnedInAnyOfTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.CropResidueBurnedInAnyOfTheLastFiveYears,
      },
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.CropResidueMulchedConsistentlyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.mulched_crop_residue.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.mulched_crop_residue.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.CropResidueRemovedInAnyTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.CropResidueBurnedInAnyOfTheLastFiveYears,
      },
    },

    // Questions group
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.NoCoverCropGrownInTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.grown_cover_catch_crops.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.grown_cover_catch_crops.tooltip",
      ),
      answers: [
        {
          label: I18n.t("js.shared.yes"),
          value: false,
          disabled: isReadonly,
        },
        {
          label: I18n.t("js.shared.no"),
          value: true,
          disabled: isReadonly,
        },
      ],
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.CoverCropWinterConsistentlyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.cover_crops_winter.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.cover_crops_winter.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.NoCoverCropGrownInTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.NoCoverCropGrownInTheLastFiveYears,
      },
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.CoverCropSpringConsistentlyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.cover_crops_spring.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.cover_crops_spring.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.CoverCropWinterConsistentlyTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.NoCoverCropGrownInTheLastFiveYears,
      },
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.CatchCropConsistentlyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.catch_crops.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.catch_crops.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.CoverCropSpringConsistentlyTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.NoCoverCropGrownInTheLastFiveYears,
      },
    },

    // Questions group
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.FertilizersSyntheticNitrogenInAnyOfTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.synthetic_nitrogen_fertilizers.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.synthetic_nitrogen_fertilizers.tooltip",
      ),
      answers: yesNoAnswers,
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.FertilizersMixedNitrogenInAnyOfTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.mixed_nitrogen_fertilizers.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.mixed_nitrogen_fertilizers.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.FertilizersSyntheticNitrogenInAnyOfTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.FertilizersSyntheticNitrogenInAnyOfTheLastFiveYears,
      },
    },
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.FertilizersOrganicNitrogenConsistentlyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.organic_nitrogen_ferilizers.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.organic_nitrogen_ferilizers.tooltip",
      ),
      answers: yesNoAnswers,
      dependency: {
        key: SoilCultivationPracticeQuestionnaireAttribute.FertilizersMixedNitrogenInAnyOfTheLastFiveYears,
        value: false,
        rootQuestionKey:
          SoilCultivationPracticeQuestionnaireAttribute.FertilizersSyntheticNitrogenInAnyOfTheLastFiveYears,
      },
    },

    // Question group
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.NitrificationInhibitorsConsistentlyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.consitently_nitrifcation_inhibitors.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.consitently_nitrifcation_inhibitors.tooltip",
      ),
      answers: yesNoAnswers,
    },

    // Question group
    {
      key: SoilCultivationPracticeQuestionnaireAttribute.CropProtectionConsistentlyTheLastFiveYears,
      question: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.crop_protection.title",
      ),
      tooltip: I18n.t(
        "js.carbon.field_details.field_definition.questionnaire.crop_protection.tooltip",
      ),
      answers: yesNoAnswers,
    },
  ];
};

export const validateSoilCultivationPracticeData = (
  values: SoilCultivationPracticeQuestionnaireData,
) => {
  const config = getQuestionnaireConfig();
  const questionnaireErrors = validateQuestionnaireValues(config, values);
  const errors: Partial<Record<SoilCultivationPracticeAttribute, string>> = {};

  if (questionnaireErrors.length > 0) {
    questionnaireErrors.forEach(
      key =>
        (errors[key as unknown as SoilCultivationPracticeAttribute] =
          I18n.t("js.shared.required")),
    );
  }

  config.forEach(
    (
      question: QuestionnaireQuestion<SoilCultivationPracticeQuestionnaireAttribute>,
    ) => {
      const { dependency } = question;

      if (dependency) {
        const dependencyValue = values[dependency.key];

        // if dependency is not answered, skip validation question
        if (dependencyValue === undefined) return;

        // if dependency is answered, validate question
        if (dependencyValue === dependency.value) {
          const answer = values[question.key];

          // if question is not answered, add error
          if (answer === undefined) {
            errors[
              question.key as unknown as SoilCultivationPracticeAttribute
            ] = I18n.t("js.shared.required");
          }
        }

        // if dependency has rootQuestionKey, validate if any of dependency with same rootQuestionKey is answered with "TRUE"
        if (dependency.rootQuestionKey) {
          const questionsWithSameRootQuestion = config.filter(
            question =>
              question.dependency?.rootQuestionKey ===
              dependency.rootQuestionKey,
          );

          const areDependedQuestionsAnswered =
            questionsWithSameRootQuestion.every(
              question =>
                values[question.key] === false || values[question.key] === true,
            );

          if (areDependedQuestionsAnswered) {
            const isAnyTrueAnswer = questionsWithSameRootQuestion.some(
              question => values[question.key] === true,
            );

            if (!isAnyTrueAnswer) {
              questionsWithSameRootQuestion.forEach(
                question =>
                  (errors[
                    question.key as unknown as SoilCultivationPracticeAttribute
                  ] = I18n.t("js.shared.at_least_one_true")),
              );
            }
          }
        }
      }
    },
  );

  return errors;
};
