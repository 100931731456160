import axios from "axios";

import { AuthData, AuthDataSchema } from "@ag/utils/schemas";
import { CommonResponse } from "@ag/utils/types";

type SignInArgs = {
  email: string;
  password: string;
};

export const signIn = async (args: SignInArgs) => {
  const { data } = await axios.post<CommonResponse<AuthData>>(
    "/api/v1/auth",
    args,
    {
      schema: AuthDataSchema,
      apiSource: "node-auth",
    },
  );

  return data.success!.data;
};
