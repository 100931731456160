import { QueryCache, QueryClient } from "@tanstack/react-query";

import { ToastNotification } from "@ag/utils/services";

export const queryCache = new QueryCache({
  onError: error => ToastNotification.error(error),
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache,
});
