import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  KYCStatus,
  SalesOffer,
  SalesOfferSchema,
} from "../entities/sales-offer";

type SalesOffersParams = MetaArgs & {
  filters?: Partial<{
    userId: string;
    status: string;
    harvestYear: string;
    contacted: string;
    kycStatus: KYCStatus;
  }>;
  sort?: Partial<{
    id: SortingDirection;
  }>;
};

export const generateSalesOffersQueryKey = (keyVariables?: {
  params?: SalesOffersParams | undefined;
}) => ["sales-offers", keyVariables].filter(Boolean);

const getSalesOffers = async (params: SalesOffersParams | undefined) => {
  const response = await axios.get<CommonPaginatedResponse<SalesOffer>>(
    "/api/v1/offers",
    {
      params: {
        ...params,
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(SalesOfferSchema),
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useSalesOffersQuery = (params?: SalesOffersParams) =>
  useQuery(
    generateSalesOffersQueryKey({ params }),
    () => getSalesOffers(params),
    {
      keepPreviousData: true,
    },
  );
