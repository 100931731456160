import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { Field, FieldSchema } from "../entities/field";

type GetFieldParams = {
  actualHarvestYear?: number;
};

export const generateFieldQueryKey = (keyVariables?: {
  fieldId: number;
  params?: GetFieldParams | undefined;
}) => ["field", keyVariables].filter(Boolean);

export const getField = async (
  fieldId: number,
  params: GetFieldParams | undefined,
) => {
  const response = await axios.get<CommonResponse<Field>>(
    `/api/v1/carbon-fields/${fieldId}/details`,
    {
      params,
      schema: FieldSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useFieldQuery = (
  fieldId: number,
  params?: GetFieldParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateFieldQueryKey({ fieldId, params }),
    () => getField(fieldId, params),
    {
      keepPreviousData: true,
      ...options,
    },
  );
