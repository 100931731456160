import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  CertificateGroup,
  CertificateGroupSchema,
} from "../entities/certificate-group";
import {
  CertificateGroupStandard,
  CertificateGroupStatus,
  CertificateGroupType,
} from "../types/certificate-group";

type GetCertificatesGroupsParams = MetaArgs & {
  filters?: Partial<{
    certificateId: string;
    type: CertificateGroupType[];
    status: CertificateGroupStatus[];
    standard: CertificateGroupStandard[];
    country: string[];
    vintageYear: string[];
  }>;
};

export const generateCertificateGroupsQueryKey = (
  params?: GetCertificatesGroupsParams | undefined,
) => ["certificate-groups", params].filter(Boolean);

const getCertificateGroups = async (params?: GetCertificatesGroupsParams) => {
  const response = await axios.get<CommonPaginatedResponse<CertificateGroup>>(
    "/api/v1/certificates/groups",
    {
      params: {
        ...params,
        limit: params?.limit || DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(CertificateGroupSchema),
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useCertificateGroupsQuery = (
  params?: GetCertificatesGroupsParams,
) =>
  useQuery(
    generateCertificateGroupsQueryKey(params),
    () => getCertificateGroups(params),
    {
      keepPreviousData: true,
    },
  );
