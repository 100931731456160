import styled from "@emotion/styled";

type SPartialsGroupTitleProps = {
  marginTop?: boolean;
};

export const SPartialsGroupTitle = styled.p`
  margin-bottom: 8px;

  ${({ marginTop }: SPartialsGroupTitleProps): string => {
    let styles = ``;

    if (marginTop) {
      styles += `
        margin-top: 16px;
      `;
    }

    return styles;
  }}
`;

export const SFieldDefinitionTab = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 48em) {
    flex-direction: column-reverse;
    padding-bottom: 32px;
  }
`;
