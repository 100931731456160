import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { FormField } from "@ag/design-system/organisms";

import { FlagSelect, FlagValue } from "~components/flag-select";

type Props = {
  label: string;
  value: FlagValue[] | undefined;
  error?: FieldError;
  tooltip?: string;
  options?: FlagValue[];
  isDisabled?: FlagValue[];
  onChange: (value: FlagValue[]) => void;
};

export const FlagSelectField = forwardRef<HTMLElement, Props>(
  ({ label, value, error, options, isDisabled, tooltip, onChange }, _ref) => {
    return (
      <FormField.Root>
        <FormField.Label tooltip={tooltip}>{label}</FormField.Label>

        <FlagSelect
          value={value}
          options={options}
          isDisabled={isDisabled}
          isInvalid={Boolean(error)}
          onChange={value => onChange(value)}
        />

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

FlagSelectField.displayName = "FlagSelectField";
