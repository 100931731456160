import * as z from "zod";

import { CarbonAbility } from "../types/abilities";
import { CarbonResourceClass } from "../types/resources";

export const CarbonPermissionsSchema = z.record(
  z.nativeEnum(CarbonAbility),
  z.array(z.nativeEnum(CarbonResourceClass)),
);

export type CarbonPermissions = z.infer<typeof CarbonPermissionsSchema>;
