import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

const DocusignConsentSchema = z.object({ consentUrl: z.string() });
type DocusignConsent = z.infer<typeof DocusignConsentSchema>;

const getDocusignConsentUrl = async () => {
  const response = await axios.get<CommonResponse<DocusignConsent>>(
    "/api/v1/contracts/docusign/docusign-consent",
    {
      apiSource: "node-carbon",
      schema: DocusignConsentSchema,
    },
  );

  return response.data.success!.data.consentUrl;
};

export const useDocusignConsentQuery = (
  options?: Pick<UseQueryOptions, "enabled">,
) => useQuery(["docusign-consent"], getDocusignConsentUrl, options);
