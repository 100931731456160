import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import {
  ResaleAgreement,
  ResaleAgreementSchema,
} from "../entities/resale-agreement";

const getResaleAgreementDetails = async (id: string) => {
  const response = await axios.get<CommonResponse<ResaleAgreement>>(
    `/api/v1/broker-contracts/${id}`,
    {
      schema: ResaleAgreementSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const generateResaleAgreementDetailsQueryKey = (keyVariables?: string) =>
  ["resale-contract-details", keyVariables].filter(Boolean);

export const useResaleAgreementDetailsQuery = (
  id: string,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateResaleAgreementDetailsQueryKey(id),
    () => getResaleAgreementDetails(id),
    {
      keepPreviousData: true,
      ...options,
    },
  );
