import { useCallback, useMemo, useState } from "react";

import { Field } from "../entities/field";

// TODO: rewrite with useReducer
export const useFieldModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [field, setField] = useState<Field | null>(null);

  const close = useCallback(() => {
    setField(null);
    setIsOpen(false);
  }, []);

  const open = useCallback((field: Field | null) => {
    setField(field);
    setIsOpen(true);
  }, []);

  return useMemo(
    () => ({
      field,
      isOpen,

      open,
      close,
    }),
    [field, isOpen, open, close],
  );
};
