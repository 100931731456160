import { z } from "zod";

const AvailableProperties = z.object({
  biomass_parameters_available: z.boolean(),
  ipcc_parameters_availabile: z.boolean(),
  countries_available: z.array(z.string()),
});

export const CropAvailabilitySchema = z.object({
  2024: AvailableProperties.optional(),
  2023: AvailableProperties.optional(),
  2022: AvailableProperties.optional(),
  2021: AvailableProperties.optional(),
});

export type CropAvailability = z.infer<typeof CropAvailabilitySchema>;

export type HarvestYear = "2021" | "2022" | "2023" | "2024";
