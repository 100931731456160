
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/tokens/elevation.css.ts", "@ag/design-system");
      import { createGlobalTheme } from "@vanilla-extract/css";
export default createGlobalTheme(":root", {
  100: "0 4px 8px rgba(0,0,0,0.05)",
  200: "0 8px 8px rgba(0,0,0,0.1)"
});
      endFileScope();
      
    