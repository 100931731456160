import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { queryClient } from "~config/react-query";

import { generateUsersQueryKey } from "./get-users";

const validateUser = (userId: string) => {
  return axios.put(
    `/api/v1/admins/user-management/${userId}/validate-user`,
    undefined,
    {
      apiSource: "node-user-mgt",
    },
  );
};

export const useValidateUserMutation = () =>
  useMutation(validateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateUsersQueryKey());
    },
  });
