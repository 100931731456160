import { createContext, useCallback, useMemo, useState } from "react";

export type DialogContentProps = {
  hide: () => void;
};

export type DialogComponent = (props: DialogContentProps) => React.ReactElement;

type TDialogContext = {
  show: (key: string, dialog: DialogComponent) => void;
  hide: (key: string) => void;
};

export const DialogContext = createContext<TDialogContext | undefined>(
  undefined,
);

export const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialogs, setDialogs] = useState<Record<string, DialogComponent>>({});

  const show = useCallback((key: string, dialog: DialogComponent) => {
    setDialogs(prev => ({
      ...prev,
      [key]: dialog,
    }));
  }, []);

  const hide = useCallback((key: string) => {
    setDialogs(prev => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      show,
      hide,
    }),
    [hide, show],
  );

  return (
    <DialogContext.Provider value={contextValue}>
      {/* Regular React tree */}
      {children}

      {/* Render the dialogs */}
      {Object.keys(dialogs).map(key => {
        const Dialog = dialogs[key];

        return <Dialog key={key} hide={() => hide(key)} />;
      })}
    </DialogContext.Provider>
  );
};
