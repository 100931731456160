import { colors } from "@ag/design-system/tokens";

export const FARMER_REPORTED_OR_LEGACY_DETECTED_FIELD_POLYGON_OPTIONS: google.maps.PolygonOptions =
  {
    strokeColor: colors.data.blue[500],
    strokeWeight: 2,
    fillColor: colors.data.blue[500],
    fillOpacity: 0.3,
  };

export const MRV_REPORTED_FIELD_POLYGON_OPTIONS: google.maps.PolygonOptions = {
  strokeColor: colors.data.orange[500],
  strokeWeight: 2,
  fillColor: colors.data.orange[500],
  fillOpacity: 0.2,
};
