import { z } from "zod";

export const AuthDataSchema = z.object({
  id: z.string(),
  token: z.string(),
  email: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  expiresAt: z.string(),
  refreshToken: z.string(),
  refreshTokenExpiresAt: z.string(),
  adminId: z.string().nullable(),
});

export type AuthData = z.infer<typeof AuthDataSchema>;

export const CurrentUserSchema = z.object({
  validationState: z.string(),
  confirmedAt: z.string().nullish(),
  profile: z.object({
    firstName: z.string(),
    lastName: z.string(),
  }),
});

export type CurrentUser = z.infer<typeof CurrentUserSchema>;
