import { useParams } from "react-router-dom";

import { box } from "@ag/design-system/utils";
import I18n from "@ag/i18n";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import {
  UserEditCompanyForm,
  UserEditProfileForm,
  useUserQuery,
} from "~features/user";
import DetailsLayout from "~layouts/details-layout";

export default function UserEditPage() {
  const params = useParams<{ userId: string }>();

  const { data: user } = useUserQuery(params.userId);

  if (!user) return null;

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        {user && (
          <DetailsLayout.TopBarTitle>
            {I18n.t("js.users.details.user_email", {
              email: user.email,
            })}
          </DetailsLayout.TopBarTitle>
        )}
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div className={box({ align: "start" })}>
          <UserEditProfileForm user={user} />

          <UserEditCompanyForm user={user} />
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
}
