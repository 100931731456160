import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createEnumParam, withDefault } from "serialize-query-params";
import { StringParam, useQueryParams } from "use-query-params";

import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { InputField, SelectField } from "@ag/form-fields";
import { SIGN_IN_AS_PARAM } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";
import { usePagination } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";
import { UserValidationState } from "@ag/utils/types";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import { env } from "~config";
import { TimeRange } from "~constants/date";
import { Country, useCountries } from "~features/countries";
import { useSyncHubspotCarbonStatsMutation } from "~features/hubspot";
import { AuthorizedSidebar } from "~features/navigation";
import {
  DeleteUserConfirmation,
  TOTAL_HA_VALUES,
  TotalHaValue,
  getDateRangeFromTimeRange,
  useDeleteUserMutation,
  useSignInAsMutation,
  useUsersQuery,
  useUsersTable,
  useValidateUserMutation,
} from "~features/user";
import { MOST_USED_COUNTRY_CODES } from "~features/user/components/user-list-filters/constants";
import { UserFilters, UserFiltersSchema } from "~features/user/entities/user";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const ValidationStateParam = createEnumParam(
  Object.values(UserValidationState),
);
const TotalHaParam = createEnumParam(Object.values(TotalHaValue));

const Users = () => {
  const [userToDelete, setUserToDelete] = useState<{
    id: string;
    email: string;
  } | null>(null);

  const { data: countries } = useCountries();

  const countriesOptions = useMemo(() => {
    if (!countries) {
      return [];
    }
    const mostUsedCountries = MOST_USED_COUNTRY_CODES.map(countryCode =>
      countries.find(country => country.code === countryCode),
    ).filter(Boolean) as typeof countries;

    const remainingCountries = countries.filter(
      country => !mostUsedCountries.includes(country),
    );

    return [
      ...mostUsedCountries,
      {
        code: "separator",
        name: "-----",
      } as Country,
      ...remainingCountries,
    ];
  }, [countries]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const [query, setQuery] = useQueryParams({
    id: withDefault(StringParam, undefined),
    search: withDefault(StringParam, undefined),
    validationState: withDefault(ValidationStateParam, undefined),
    haUnderManagementRange: withDefault(TotalHaParam, undefined),
    countryCode: withDefault(StringParam, undefined),
    lastActivity: withDefault(StringParam, undefined),
  });

  const lastActivityAtRange =
    query.lastActivity && query.lastActivity !== "none"
      ? getDateRangeFromTimeRange(query.lastActivity as TimeRange)
      : undefined;

  const { data: usersData, isLoading } = useUsersQuery({
    ...pagination,
    filters: {
      ...query,
      validationStates: query.validationState
        ? [query.validationState]
        : undefined,
      countryCodes:
        !query.countryCode || query.countryCode === "separator"
          ? undefined
          : [query.countryCode],
      ids: query.id ? [Number(query.id)] : undefined,
      haUnderManagementRange: query.haUnderManagementRange
        ? TOTAL_HA_VALUES[query.haUnderManagementRange]
        : undefined,
      lastActivityAtRange,
    },
  });

  const { mutate: validateUser } = useValidateUserMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const { mutate: signInAs } = useSignInAsMutation();
  const { mutate: syncHubspotCarbonStats } =
    useSyncHubspotCarbonStatsMutation();

  const handleSignInAs = useCallback(
    (userId: string) => {
      signInAs(userId, {
        onSuccess: ({ ticket }) => {
          const params = getSearchParams({
            [SIGN_IN_AS_PARAM]: ticket,
          });

          window.open(env.REACT_APP_FARMER_URL + params);
        },
      });
    },
    [signInAs],
  );

  const handleValidate = useCallback(
    (userId: string) => {
      validateUser(userId, {
        onSuccess: () =>
          ToastNotification.success("User validated successfully"),
      });
    },
    [validateUser],
  );

  const handleSyncHubspotCarbonStats = useCallback(
    (userId: string) => {
      syncHubspotCarbonStats([userId], {
        onSuccess: () => {
          ToastNotification.success("Carbon Stats sync is initiated");
        },
      });
    },
    [syncHubspotCarbonStats],
  );

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(UserFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<UserFilters>(query, setQuery, resetPagination, () =>
    setIsFiltersOpen(false),
  );
  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  const table = useUsersTable({
    users: usersData?.items,
    onDelete: setUserToDelete,
    onValidate: handleValidate,
    onSignIn: handleSignInAs,
    onHubspotCarbonStatsSync: handleSyncHubspotCarbonStats,
  });

  const handleDeleteUser = useCallback(() => {
    if (!userToDelete) return;

    deleteUserMutation.mutate(userToDelete.id, {
      onSettled: () => setUserToDelete(null),
      onSuccess: () => ToastNotification.success("User deleted successfully"),
    });
  }, [userToDelete, deleteUserMutation]);

  const renderFilterBarItem = (
    key: keyof UserFilters,
    value: UserFilters[keyof UserFilters],
  ) => {
    const label = (
      {
        validationState: "Validation State",
        id: "ID",
        search: "Search",
        haUnderManagementRange: "HA Under Management Range",
        countryCode: "Country",
        lastActivity: "Last Activity",
      } as unknown as Record<keyof UserFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Users</ListLayout.TopBarTitle>

        <ListLayout.TopBarActions>
          <Button to="/users/add">New</Button>
        </ListLayout.TopBarActions>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField
            {...register("id")}
            label="ID"
            type="number"
            error={errors.id}
          />

          <InputField
            {...register("search")}
            label="Search"
            error={errors.id}
          />

          <Controller
            name="countryCode"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Country"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {countriesOptions.map(({ name, code }) => (
                  <Select.Option key={code} value={code}>
                    {name}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="validationState"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Validation status"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                <Select.Option
                  key={UserValidationState.NotValidated}
                  value={UserValidationState.NotValidated}
                >
                  Non validated
                </Select.Option>

                <Select.Option
                  key={UserValidationState.Validated}
                  value={UserValidationState.Validated}
                >
                  Validated
                </Select.Option>
              </SelectField>
            )}
          />

          <Controller
            name="haUnderManagementRange"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Ha under management"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.keys(TOTAL_HA_VALUES).map(label => (
                  <Select.Option key={label} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            name="lastActivity"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Last activity"
                optionsClassName="z-modal"
              >
                <Select.Option key="none" value={"none"}>
                  None
                </Select.Option>

                <Select.Option key={TimeRange.Today} value={TimeRange.Today}>
                  Today
                </Select.Option>

                <Select.Option
                  key={TimeRange.SinceYesterday}
                  value={TimeRange.SinceYesterday}
                >
                  Since yesterday
                </Select.Option>

                <Select.Option
                  key={TimeRange.Last7Days}
                  value={TimeRange.Last7Days}
                >
                  Last 7 days
                </Select.Option>

                <Select.Option
                  key={TimeRange.Last14Days}
                  value={TimeRange.Last14Days}
                >
                  Last 14 days
                </Select.Option>

                <Select.Option
                  key={TimeRange.LastMonth}
                  value={TimeRange.LastMonth}
                >
                  Last month
                </Select.Option>

                <Select.Option
                  key={TimeRange.Last3Months}
                  value={TimeRange.Last3Months}
                >
                  Last 3 months
                </Select.Option>

                <Select.Option
                  key={TimeRange.Last6Months}
                  value={TimeRange.Last6Months}
                >
                  Last 6 months
                </Select.Option>
              </SelectField>
            )}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={usersData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>

      <DeleteUserConfirmation
        userToDelete={userToDelete}
        isOpen={Boolean(userToDelete)}
        isDeleting={deleteUserMutation.isLoading}
        onHide={() => setUserToDelete(null)}
        onConfirm={handleDeleteUser}
      />
    </ListLayout.Root>
  );
};

export default Users;
