import isEqual from "lodash/isEqual";

export function isURLSearchParamsEntriesEqual(
  a: [string, string][],
  b: [string, string][],
  keysToSkip: string[] = [],
) {
  const aEntries = a.filter(([key]) => !keysToSkip.includes(key));
  const bEntries = b.filter(([key]) => !keysToSkip.includes(key));

  if (aEntries.length !== bEntries.length) return false;

  return isEqual(aEntries, bEntries);
}
