import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { CurrentAdmin, CurrentAdminSchema } from "../entities/current-admin";

export const getCurrentAdmin = async () => {
  const { data } = await axios.get<CommonResponse<CurrentAdmin>>(
    "/api/v1/admins/me",
    {
      schema: CurrentAdminSchema,
      apiSource: "node-auth",
    },
  );

  return data.success!.data;
};
