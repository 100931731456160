import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { usePreviousImmediate } from "rooks";

import { useAppHistoryStore } from "~features/navigation";

const STACK_SIZE_LIMIT = 100;

export const useAppHistory = () => {
  const { historyStack, addPage, shiftPage } = useAppHistoryStore();

  const { pathname, search } = useLocation();

  const previousPathname = usePreviousImmediate(pathname);
  const previousSearch = usePreviousImmediate(search);

  const isRouteChanging =
    pathname !== previousPathname || search !== previousSearch;

  useEffect(() => {
    if (isRouteChanging) {
      addPage(pathname + search);
    }
  }, [addPage, isRouteChanging, pathname, search]);

  // Keep the history stack at a given size
  useEffect(() => {
    if (historyStack.length > STACK_SIZE_LIMIT) {
      shiftPage();
    }
  }, [historyStack, shiftPage]);
};
