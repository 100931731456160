
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/utils/visibility.css.ts", "@ag/design-system");
      import { style } from "@vanilla-extract/css";
export const hidden = style({
  display: "none"
}, "hidden");
      endFileScope();
      
    