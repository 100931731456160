export enum Locale {
  Da = "da",
  En = "en",
  Ro = "ro",
  Lt = "lt",
  Pl = "pl",
  Sk = "sk",
  Fr = "fr",
  De = "de",
  Es = "es",
  Uk = "uk",
  Et = "et",
  Bg = "bg",
  Cs = "cs",
  Hu = "hu",
  Fi = "fi",
  Lv = "lv",
  Sv = "sv",
  Kk = "kk",
}

export type TranslationsLoader = (
  locale: Locale,
) => Promise<Record<string, object>>;

export type LocaleFile = {
  default: Record<string, object>;
};
