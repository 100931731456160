import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import "@ag/design-system/organisms";

export const generateCropCountriesQueryKey = () =>
  ["crop-countries"].filter(Boolean);

const getCropCountries = async () => {
  const response = await axios.get<string[]>("/v1/countries", {
    schema: z.array(z.string()),
    apiSource: "crops-fertilisers",
  });

  return response.data;
};

export const useCropCountriesQuery = () =>
  useQuery(generateCropCountriesQueryKey(), () => getCropCountries(), {
    keepPreviousData: true,
  });
