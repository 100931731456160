import I18n from "@ag/i18n";

import { FlagValue } from "~components/flag-select";

export enum CheckFilterKey {
  Deforestation = "deforestation",
  CoverCrop = "cover_crop",
  SoilDisturbance = "soil_disturbance",
  ReportedSize = "field_size_reported_size",
  Duplication = "duplication",
  // TODO: rename `intersection_sum` -> `intersection`
  Intersection = "intersection_sum",
}

type FilterConfig = {
  label: string;
} & (
  | {
      flag: false;
    }
  | {
      flag: true;
      disabledFlags?: FlagValue[];
    }
) &
  (
    | {
        type: "empty";
      }
    | {
        type: "range";
      }
    | {
        type: "boolean";
      }
    | {
        type: "select";
        options: {
          label: string;
          value: string;
        }[];
      }
  );

export const getFiltersConfig = (): Record<CheckFilterKey, FilterConfig> => {
  return {
    [CheckFilterKey.Deforestation]: {
      label: I18n.t("js.admin.assurance.category.deforestation"),
      flag: true,
      disabledFlags: ["blue", "yellow", "orange"],
      type: "boolean",
    },
    [CheckFilterKey.Duplication]: {
      label: I18n.t("js.admin.assurance.category.duplication"),
      flag: true,
      disabledFlags: ["blue"],
      type: "empty",
    },
    [CheckFilterKey.CoverCrop]: {
      label: I18n.t("js.admin.assurance.category.cover_crop"),
      flag: true,
      disabledFlags: ["red"],
      type: "select",
      options: [
        {
          label: I18n.t("js.admin.assurance.no_cover_crop"),
          value: "No cover crop",
        },
        {
          label: I18n.t("js.admin.assurance.cover_crop_spring"),
          value: "Cover crop spring",
        },
        {
          label: I18n.t("js.admin.assurance.cover_crop_winter"),
          value: "Cover crop winter",
        },
        {
          label: I18n.t("js.admin.assurance.catch_crop"),
          value: "Catch crop",
        },
      ],
    },
    [CheckFilterKey.SoilDisturbance]: {
      label: I18n.t("js.admin.assurance.category.soil_disturbance"),
      flag: true,
      disabledFlags: ["red", "orange"],
      type: "select",
      options: [
        {
          label: I18n.t("js.admin.carbon.tilling_practice.conventional"),
          value: "conventional",
        },
        {
          label: I18n.t("js.admin.carbon.tilling_practice.reduced_tillage"),
          value: "reduced",
        },
        {
          label: I18n.t("js.admin.carbon.tilling_practice.no_till"),
          value: "no-till",
        },
      ],
    },
    [CheckFilterKey.ReportedSize]: {
      label: I18n.t("js.admin.assurance.reported_size"),
      flag: true,
      disabledFlags: ["orange"],
      type: "range",
    },
    [CheckFilterKey.Intersection]: {
      label: I18n.t("js.admin.assurance.intersection"),
      flag: true,
      disabledFlags: ["blue"],
      type: "range",
    },
  };
};
