import { useMemo } from "react";

import { Icon } from "@ag/design-system/assets";
import { Button, Tooltip } from "@ag/design-system/atoms";

import {
  InventoryImportModal,
  useInventoryImportsQuery,
} from "~features/inventory";
import { ImportStatus } from "~features/inventory/entities/inventory-import";

import { useProgressPollingQuery } from "../api/progress-polling";

const ProgressLoader = ({ id }: { id?: string }) => {
  const progressPolling = useProgressPollingQuery(id as string, {
    enabled: !!id,
  });
  return !progressPolling?.data ? null : (
    <div>
      <span className="text-p3">
        Processing: {progressPolling.data.importId}
      </span>
      <span className="float-right text-p3">
        {" "}
        {progressPolling.data.percentage}%
      </span>
      <div className="bg-gray-200 dark:bg-gray-700 mt-1 h-3 w-52 rounded-full bg-grey-200">
        <div
          className="h-3 rounded-full bg-data-purple-700 transition-all duration-300 ease-linear"
          style={{ width: `${progressPolling.data.percentage}%` }}
        />
      </div>
    </div>
  );
};

const InventoryImportAction = ({
  isImportModalOpen,
  setImportModalOpen,
}: {
  isImportModalOpen: boolean;
  setImportModalOpen: (open: boolean) => void;
}) => {
  const processing = useInventoryImportsQuery({
    filters: {
      status: [ImportStatus.PROCESSING],
    },
  });

  const isProcessingImport = useMemo(
    () => processing.data && processing.data.meta.totalItems > 0,
    [processing.data],
  );

  return (
    <>
      {isProcessingImport && (
        <>
          {!isImportModalOpen && (
            <ProgressLoader id={processing.data?.items[0]?.id} />
          )}
          <Tooltip content="Cannot import a file when there is an import being processed">
            <Icon name="circle-info" className="text-messaging-warning-700" />
          </Tooltip>
        </>
      )}
      <Button
        disabled={isProcessingImport}
        onClick={() => setImportModalOpen(true)}
      >
        Start new import
      </Button>

      <InventoryImportModal
        isOpen={isImportModalOpen}
        onClose={() => setImportModalOpen(false)}
      />
    </>
  );
};

export default InventoryImportAction;
