import { z } from "zod";

export const FertiliserSchema = z.object({
  name: z.string(),
  legacy_identifier: z.string().nullable(),
  legacy_enum_value: z.string().nullable(),
  id: z.string(),
});

export type Fertiliser = z.infer<typeof FertiliserSchema>;

export const FertilisersAvailabilitySchema = z.record(
  z.number(),
  z.array(z.string()),
);

export type FertilisersAvailability = z.infer<
  typeof FertilisersAvailabilitySchema
>;

export const LegacyPropertiesSchema = z.object({
  coolfarm_identifier: z.number().nullable(),
  custom: z.boolean(),
  nitrification_added: z.boolean(),
  distributor: z.string().nullable(),
  distributor_company_code: z.string().nullable(),
  magnesium: z.number().nullable(),
  ammonium: z.number().nullable(),
  nitrate_nitrogen: z.number().nullable(),
  phosphorus: z.number().nullable(),
  nitrogen_total: z.number().nullable(),
  nitrogen_urea: z.number().nullable(),
  potassium: z.number().nullable(),
  sulfur: z.number().nullable(),
  biomass: z.boolean(),
  potassium_oxide: z.number().nullable(),
  phosphorus_pentoxide: z.number().nullable(),
  chemical_specification: z.boolean().nullable(),
  organic: z.boolean(),
  harvest_year: z.number().optional(),
  identifier: z.string(),
  methodology_version: z.number(),
  organic_carbon: z.number().nullable(),
  coolfarm_name: z.string().nullable(),
});

export const PropertiesSchema = z.object({
  "C% wet": z.number().nullable(),
  "C% dry": z.number().nullable(),
  "N% wet": z.number().nullable(),
  "N% dry": z.number().nullable(),
  "C/N": z.number().nullable(),
  "Moisture content %": z.union([z.number(), z.string()]).nullable(),
  "Name source": z.string().nullable(),
  "REF / Source": z.string().nullable(),
});

export const FertiliserHarvestYearSchema = z.object({
  fertiliser_id: z.string().uuid(),
  harvest_year: z.string(),
  properties: z
    .object({ legacy_properties: LegacyPropertiesSchema })
    .merge(PropertiesSchema),
});

export type FertiliserLegacyProperties = z.infer<typeof LegacyPropertiesSchema>;
export type FertiliserProperties = z.infer<typeof PropertiesSchema>;
export type FertiliserHarvestYear = z.infer<typeof FertiliserHarvestYearSchema>;
export type FertiliserPropertiesKeys =
  | keyof FertiliserLegacyProperties
  | keyof FertiliserProperties;
