import { z } from "zod";

import { SoilCultivationPracticeQuestionnaireDataSchema } from "@ag/carbon/forms/baseline/iso";
import { FieldDefinitionStatus } from "@ag/carbon/types";

// TODO: Move them to features types
import {
  BaselineVersion,
  CarbonCropTypeSchema,
  CropProtectionSchema,
  FertiliserSchema,
  PotentialSchema,
} from "~queries/carbon/types";

export const FieldDefinitionSchema = z.object({
  baseline: z.number().nullable(),
  baselineTotal: z.number().nullable(),
  baselineVersion: z.nativeEnum(BaselineVersion),
  carbonCropType: CarbonCropTypeSchema.nullable(),
  carbonCropTypeId: z.number().nullable(),
  carbonFieldId: z.string(),
  completedAt: z.string().nullable(),
  completionRate: z.number(),
  createdAt: z.string(),
  cropGrossYield: z.number().nullable(),
  cropLastHarvestYear: z.number().nullable(),
  cropNetYield: z.number().nullable(),
  carbonFieldDefinitionCropProtections: z.array(CropProtectionSchema),
  cropProtectionsCount: z.number().nullable(),
  cropProtectionUsed: z.boolean().nullable(),
  cropResidueManagement: z.string().nullable(),
  cropSeedAmount: z.number().nullable(),
  cropSeedTreatment: z.boolean().nullable(),
  cropType: z.number().nullable(),
  cropTypeOtherName: z.string().nullable(),
  energyConsumptionAmountHa: z.number().nullable(),
  energyConsumptionAmountUnit: z.string().nullable(),
  energyConsumptionEnergySource: z.string().nullable(),
  carbonFieldDefinitionFertilisers: z.array(FertiliserSchema),
  fertilisersCount: z.number().nullable(),
  fertilisersShowChemicalSpecification: z.boolean().nullable(),
  footprintTotal: z.number(),
  id: z.string(),
  irrigationNumberOfEvents: z.number().nullable(),
  isOrganic: z.boolean(),
  latestCarbonBaseline: z.object({
    baselinePerHa: z.string().optional(),
    baselineTotal: z.string().optional(),
    baselineVersion: z.nativeEnum(BaselineVersion),
    carbonBaselineEmissionId: z.number(),
    carbonBaselineEmissionType: z.string(),
    createdAt: z.string(),
  }),
  latestCarbonFootprint: z.object({
    carbonCftRequest: z.unknown(),
    carbonComponentCftRequest: z.null(),
    cftVersion: z.string().nullable(),
    footprintPerHa: z.string(),
    footprintTotal: z.string(),
  }),
  latestHarvestYear: z.number(),
  otherInformationComment: z.string().nullable(),
  potential: PotentialSchema,
  carbonFieldDefinitionQuestionnaire: z.object({
    completionRate: z.number(),
    type: z.string(),
    data: SoilCultivationPracticeQuestionnaireDataSchema,
  }),
  readonly: z.boolean(),
  simplified: z.boolean(),
  soilDataOrigination: z.string().nullable(),
  soilDrainage: z.string().nullable(),
  soilMgmtHistoricConversion: z.boolean(),
  soilMoisture: z.string().nullable(),
  soilPhRange: z.string().nullable(),
  soilSomCustom: z.boolean().nullable(),
  soilSomRange: z.string().nullable(),
  soilSomSpecific: z.number(),
  soilTexture: z.string(),
  status: z.nativeEnum(FieldDefinitionStatus),
  submittedAt: z.string().nullable(),
  updatedAt: z.string(),
});

export type FieldDefinition = z.infer<typeof FieldDefinitionSchema>;
