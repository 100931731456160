import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { Fertiliser, FertiliserSchema } from "../entities/fertiliser";

export const generateFertilisersQueryKey = () =>
  ["fertilisers"].filter(Boolean);

const getFertilisers = async () => {
  const response = await axios.get<Fertiliser[]>("/v1/fertilisers", {
    params: {},
    schema: z.array(FertiliserSchema),
    apiSource: "crops-fertilisers",
  });

  return response.data;
};

export const useFertilisersQuery = () =>
  useQuery(generateFertilisersQueryKey(), () => getFertilisers(), {
    keepPreviousData: true,
  });
