import pick from "lodash/pick";

import {
  SoilCharacteristicsAttribute,
  SoilCharacteristicsData,
} from "@ag/carbon/forms/baseline/iso";

import { FieldDefinitionDetails } from "~queries/carbon/fields";

export const usePartial = (
  fieldDefinition: FieldDefinitionDetails | undefined,
) => {
  const initialValues: SoilCharacteristicsData | undefined = fieldDefinition
    ? pick<FieldDefinitionDetails, SoilCharacteristicsAttribute>(
        fieldDefinition,
        Object.values(SoilCharacteristicsAttribute),
      )
    : undefined;

  return {
    initialValues,
  };
};
