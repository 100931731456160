import { useQuery } from "@tanstack/react-query";

import { APIResponse } from "@ag/utils/types";

import {
  ALL_AVAILABLE_ITEMS_NUMBER,
  DEFAULT_PAGE_SIZE,
} from "~constants/params";
import { getRequest } from "~lib/axios";

import {
  Fertiliser,
  FertilisersFilters,
  FertilisersListQueryKeyArgs,
  FertilisersResponse,
  FertilisersTypesArgs,
  UseFertilisersOptionsQueryOptions,
  UseFertilisersQueryArgs,
  UseFertilisersQueryOptions,
} from "./types";

export const fertilisersKeys = {
  all: ["fertilisers"] as const,

  listAll: () => [...fertilisersKeys.all, "list"] as const,
  list: ({ filters, metaArgs }: FertilisersListQueryKeyArgs) =>
    [...fertilisersKeys.listAll(), { filters, metaArgs }] as const,

  detailsAll: () => [...fertilisersKeys.all, "details"] as const,
  details: (fertiliserId: number) =>
    [...fertilisersKeys.detailsAll(), fertiliserId] as const,
};

/**
 * CUSTOM QUERY FUNCTIONS
 */
const fetchFertilisers = async ({
  filters,
  metaArgs,
}: FertilisersTypesArgs): Promise<FertilisersResponse> => {
  const response: APIResponse<Fertiliser[], true> = await getRequest({
    path: "/api/farmer/carbon/v1/admin/carbon_fertilisers",
    params: {
      page: metaArgs?.page || 1,
      limit: metaArgs?.limit || DEFAULT_PAGE_SIZE,
      includeMeta: true,
      responseType: "full",
      sort: {
        id: "desc",
      },
      filters,
    },
  });

  const { data, meta } = response.data.result;

  return {
    fertilisers: data,
    meta,
  };
};

export const fetchFertiliser = async (
  fertiliserId: number,
): Promise<Fertiliser> => {
  const response: APIResponse<Fertiliser> = await getRequest({
    path: `/api/farmer/carbon/v1/admin/carbon_fertilisers/${fertiliserId}`,
  });

  return response.data.result.data;
};

/**
 * QUERY HOOKS
 */
export const useFertiliserQuery = (fertiliserId: number) =>
  useQuery(fertilisersKeys.details(fertiliserId), () =>
    fetchFertiliser(fertiliserId),
  );

export const useFertilisersQuery = <T = FertilisersResponse>(
  { filters, metaArgs }: UseFertilisersQueryArgs,
  options?: UseFertilisersQueryOptions<T>,
) =>
  useQuery(
    fertilisersKeys.list({ filters, metaArgs }),
    () => fetchFertilisers({ filters, metaArgs }),
    {
      keepPreviousData: true,
      ...options,
    },
  );

export const useFertilisersOptions = (
  filters: FertilisersFilters,
  options: UseFertilisersOptionsQueryOptions,
) =>
  useFertilisersQuery(
    {
      filters,
      metaArgs: {
        page: 1,
        limit: ALL_AVAILABLE_ITEMS_NUMBER,
      },
    },
    {
      select: ({ fertilisers }: FertilisersResponse) =>
        fertilisers.map(fertiliser => ({
          label: fertiliser.name,
          value: fertiliser.id,
        })),
      ...options,
    },
  );
