import { ExportToCsv } from "@agreena-aps/export-to-csv";

import { Button } from "@ag/design-system/atoms";
import { box } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { useBatchFetch } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import { fetchUserFields } from "~queries/carbon/fields";

import { mapFieldsToCSVData } from "./helpers";

type Props = {
  userId: number;
};

export const GenerateCsvButton = ({ userId }: Props) => {
  const {
    data: fieldsCSVData,
    fetch: fetchFieldsCSVData,
    isLoading: isFieldsCSVDataLoading,
  } = useBatchFetch(meta => fetchUserFields(userId, meta), {
    itemsPerRequest: 100,
    onSettled: data => {
      if (!data) {
        ToastNotification.error(I18n.t("js.carbon.batch_fetch_fields_error"));

        return;
      }

      // TODO: revert this to current harvest year, when customer success is ready to handle 2023
      const dataForCSV = mapFieldsToCSVData(data, 2023);
      const csvExporter = new ExportToCsv({
        showLabels: true,
        useKeysAsHeaders: false,
        filename: `fields-userId-${userId}`,
      });

      csvExporter.generateCsv(dataForCSV);
    },
  });

  const { requested, total } = fieldsCSVData.meta || {};

  return (
    <Button
      variant="secondary"
      size="small"
      disabled={isFieldsCSVDataLoading}
      onClick={fetchFieldsCSVData}
    >
      {fieldsCSVData.meta && isFieldsCSVDataLoading ? (
        <div className={box({ align: "center", gap: 4 })}>
          {I18n.t("js.carbon.fetching_fields_of_total", { requested, total })}
        </div>
      ) : (
        I18n.t("js.shared.download")
      )}
    </Button>
  );
};
