import { css } from "@emotion/core";

import OverpassBoldWoff2 from "~assets/fonts/overpass/Overpass-Bold.woff2";
import OverpassMediumWoff2 from "~assets/fonts/overpass/Overpass-Medium.woff2";
import OverpassRegularWoff2 from "~assets/fonts/overpass/Overpass-Regular.woff2";
import OverpassVariableWoff2 from "~assets/fonts/overpass/Overpass-VariableFont_wght.woff2";

export const fontStyles = css`
  @font-face {
    font-family: "Overpass";
    src:
      url(${OverpassVariableWoff2}) format("woff2"),
      url(${OverpassVariableWoff2}) format("woff2-variations");
    font-weight: 100 1000;
    font-style: normal;
    font-display: swap;
  }

  @supports not (font-variation-settings: normal) {
    @font-face {
      font-family: "Overpass";
      src: url(${OverpassBoldWoff2}) format("woff2");
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: "Overpass";
      src: url(${OverpassMediumWoff2}) format("woff2");
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: "Overpass";
      src: url(${OverpassRegularWoff2}) format("woff2");
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  }
`;
