import { useMutation } from "@tanstack/react-query";
import axios from "axios";

type AdminData = Partial<{
  locale: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}>;

export const updateCurrentAdmin = async (admin: AdminData) => {
  await axios.put("/api/v1/admins/me", admin, {
    apiSource: "node-auth",
  });
};

export const useUpdateCurrentAdminMutation = () =>
  useMutation(updateCurrentAdmin);
