import { Modal, Table } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { useFieldDefinitionChangesQuery } from "~features/field";

import { Field } from "../entities/field";
import { useFieldDefinitionChangesTable } from "../hooks/use-field-definition-changes-table";

type Props = {
  field: Field | undefined;
  isOpen: boolean;
  onRequestClose: () => void;
};

// TODO: move to fields feature
export const FieldDefinitionChangesModal = ({
  field,
  isOpen,
  onRequestClose,
}: Props) => {
  const {
    data: fieldDefinitionChanges,
    isLoading: isFieldDefinitionChangesLoading,
  } = useFieldDefinitionChangesQuery(
    {
      fieldId: field?.id,
      fieldDefinitionId: field?.carbonFieldDefinition.id,
    },
    {
      enabled: Boolean(isOpen && field?.carbonFieldDefinition),
    },
  );

  const table = useFieldDefinitionChangesTable(fieldDefinitionChanges);

  return (
    <Modal.Root
      size="extra-large"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <Modal.Header>
        <Modal.Title>
          {I18n.t("js.admin.field_actuals_changes_modal.title")}
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <Table instance={table} isLoading={isFieldDefinitionChangesLoading} />
      </Modal.Content>
    </Modal.Root>
  );
};
