import { useMemo } from "react";

import { Table, useTable } from "@ag/design-system/organisms";

export type FieldSizeData = {
  activeBoundarySize: string | undefined;
  farmerBoundarySize: string | undefined;
  mrvBoundarySize: string | undefined;
};

export type FieldSizeTableProps = {
  fieldSizeData: FieldSizeData;
};

export function FieldSizeTable({ fieldSizeData }: FieldSizeTableProps) {
  const columns = useMemo(
    () => [
      {
        accessorKey: "boundaryType",
        header: "",
        cell: (info: any) => info.getValue(),
      },
      {
        accessorKey: "boundarySize",
        header: "",
        cell: (info: any) => info.getValue(),
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        boundaryType: "Active",
        boundarySize: fieldSizeData.activeBoundarySize
          ? `${fieldSizeData.activeBoundarySize} ha`
          : "no size available",
      },
      {
        boundaryType: "Farmer reported",
        boundarySize: fieldSizeData.farmerBoundarySize
          ? `${fieldSizeData.farmerBoundarySize} ha`
          : "no size available",
      },
      {
        boundaryType: "MRV",
        boundarySize: fieldSizeData.mrvBoundarySize
          ? `${fieldSizeData.mrvBoundarySize} ha`
          : "no size available",
      },
    ],
    [fieldSizeData],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  return <Table instance={tableInstance} className="-mt-4 w-full" />;
}
