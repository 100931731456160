import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { UserActivity, UserActivitySchema } from "../entities/user-activity";

type GetUserActivitiesParams = MetaArgs & {
  userId: string;
  key?: string;
};

export const generateUserActivitiesQueryKey = (
  params: GetUserActivitiesParams,
) => ["user-activities", params];

const getUserActivities = async (params: GetUserActivitiesParams) => {
  const { userId, ...restParams } = params;

  const response = await axios.get<CommonPaginatedResponse<UserActivity>>(
    `/api/v1/admins/user-management/${userId}/activities`,
    {
      params: {
        ...restParams,
      },
      schema: createPaginatedDataSchema(UserActivitySchema),
      apiSource: "node-user-mgt",
    },
  );

  return response.data.success!.data;
};

export const useUserActivitiesQuery = (
  params: GetUserActivitiesParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateUserActivitiesQueryKey(params),
    () => getUserActivities(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );
