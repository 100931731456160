import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  DateCell,
  DateCellConfig,
  DateCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { ChangesCell, ChangesCellValue } from "~components/changes-cell";
import { useTable } from "~components/table";
import { FieldDefinitionChange } from "~features/field";

export type FieldDefinitionChangesTableData = {
  id: string;
  itemType: string;
  changedBy: string;
  event: string;
  changes: ChangesCellValue;
  changedAt: DateCellValue;
};

export const useFieldDefinitionChangesTable = (
  data: FieldDefinitionChange[] | undefined,
) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(() => getRowData(data), [data]);

  const instance = useTable<FieldDefinitionChangesTableData>({
    columns,
    data: tableData,
    getRowId: val => val.id,
  });

  return instance;
};

function getColumns() {
  const columnHelper = createColumnHelper<FieldDefinitionChangesTableData>();

  return [
    columnHelper.accessor("changes", {
      header: I18n.t(
        "js.admin.field_definition_changes_modal.table_column.changes",
      ),
      maxSize: 360,
      cell: ChangesCell,
    }),
    columnHelper.accessor("changedBy", {
      header: I18n.t(
        "js.admin.field_definition_changes_modal.table_column.changed_by",
      ),
      maxSize: 200,
    }),
    columnHelper.accessor("event", {
      header: I18n.t(
        "js.admin.field_definition_changes_modal.table_column.event",
      ),
    }),
    columnHelper.accessor("itemType", {
      header: I18n.t(
        "js.admin.field_definition_changes_modal.table_column.item_type",
      ),
      maxSize: 120,
    }),
    columnHelper.accessor("changedAt", {
      header: I18n.t(
        "js.admin.field_definition_changes_modal.table_column.changed_at",
      ),
      cell: DateCell,
    }),
  ];
}

function getRowData(
  data: FieldDefinitionChange[] | undefined,
): FieldDefinitionChangesTableData[] {
  if (!data) return [];

  return data.map(change => ({
    // Well this is a bit weird, but API is not providing an unique id for each change.
    id: [change.createdAt, JSON.stringify(change.objectChanges)].join("-"),
    changedAt: {
      value: change.createdAt,
      format: "datetime",
    } as DateCellConfig,
    changedBy: getChangedByValue(change),
    event: change.event,
    changes: change.objectChanges,
    itemType: change.itemType,
  }));
}

function getChangedByValue(
  fieldDefinitionChange: FieldDefinitionChange,
): string {
  if (fieldDefinitionChange.admin) return fieldDefinitionChange.admin.email;

  if (fieldDefinitionChange.user) return fieldDefinitionChange.user.email;

  return fieldDefinitionChange.whodunnit || "console";
}
