import { QuestionnaireQuestion, QuestionnaireValue } from "~Questionnaire";

export const validateQuestionnaireValues = <T extends string>(
  questions: QuestionnaireQuestion<T>[],
  formValues: QuestionnaireValue,
): T[] => {
  const hasQuestionError: Partial<Record<T, boolean>> = {};

  questions.forEach(question => {
    const { key, dependency, isOptional } = question;
    const value = formValues[key];

    // Validate nested questions
    if (dependency) {
      const isDependencyFilled =
        formValues[dependency.key] === dependency.value;

      if (isDependencyFilled && !isOptional && value === null) {
        hasQuestionError[key] = true;
      }

      return;
    }

    // Validate basic questions
    if (!isOptional && value === null) {
      hasQuestionError[key] = true;
    }
  });

  return Object.keys(hasQuestionError).filter(
    key => hasQuestionError[key as T],
  ) as T[];
};
