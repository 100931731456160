
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/utils/box.css.ts", "@ag/design-system");
      import { recipe } from "@vanilla-extract/recipes";
import { DEFAULT_GAP } from "./constants";
import { alignVariants, gapVariants, justifyVariants } from "./flex-variants";
export const box = recipe({
  base: {
    display: "flex",
    alignItems: "center"
  },
  variants: {
    gap: gapVariants,
    align: alignVariants,
    justify: justifyVariants,
    center: {
      true: {
        justifyContent: "center"
      }
    },
    wrap: {
      true: {
        flexWrap: "wrap"
      }
    }
  },
  defaultVariants: {
    gap: DEFAULT_GAP
  }
}, "box");
      endFileScope();
      
    