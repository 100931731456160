import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";

import { useSearchParam } from "@ag/utils/hooks";

import { useFieldQuery } from "~features/field";
import {
  useCurrentHarvestYear,
  useYearsDataQuery,
} from "~features/initial-resources";

const HARVEST_YEAR_KEY = "harvest-year";

type FieldHarvestYearContext =
  | {
      harvestYearsOpen: number[] | undefined;
      selectedHarvestYear: number | undefined;
      handleSelectYear: (year: number) => void;
    }
  | undefined;

const FieldHarvestYearContext =
  createContext<FieldHarvestYearContext>(undefined);

export const FieldHarvestYearContextProvider = ({
  children,
}: React.PropsWithChildrenRequired) => {
  const [selectedHarvestYearParam, setSelectedHarvestYearParam] =
    useSearchParam(HARVEST_YEAR_KEY, {
      replace: true,
    });
  const params = useParams<{ fieldId: string }>();

  const { data: currentHarvestYear } = useCurrentHarvestYear();

  const fieldId = Number(params.fieldId);

  const { data: field } = useFieldQuery(fieldId, undefined, {
    enabled: Boolean(fieldId),
  });
  const { data: yearsData } = useYearsDataQuery();

  const handleSelectYear = useCallback(
    (year: number) => {
      setSelectedHarvestYearParam(String(year));
    },
    [setSelectedHarvestYearParam],
  );

  const harvestYearsOpen = useMemo(() => {
    if (!yearsData || !field) return [];

    const startYear = field.harvestYear;
    const endYear = yearsData.carbonCurrentHarvestYear;

    // Generate an array of years from the start year to the end year
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, i) => startYear + i,
    );
  }, [field, yearsData]);

  const value = useMemo(
    () => ({
      harvestYearsOpen,
      selectedHarvestYear: selectedHarvestYearParam
        ? Number(selectedHarvestYearParam)
        : undefined,
      handleSelectYear,
    }),
    [harvestYearsOpen, selectedHarvestYearParam, handleSelectYear],
  );

  // Select default harvest year (based on the field harvestYearsOpen) if not already selected
  useEffect(() => {
    if (!field || !currentHarvestYear) return;

    if (!selectedHarvestYearParam) {
      if (harvestYearsOpen.includes(currentHarvestYear)) {
        setSelectedHarvestYearParam(String(currentHarvestYear));
      } else {
        setSelectedHarvestYearParam(String(harvestYearsOpen[0]));
      }

      return;
    }

    if (!harvestYearsOpen.includes(Number(selectedHarvestYearParam))) {
      setSelectedHarvestYearParam(String(harvestYearsOpen[0]));
    }
  }, [
    field,
    currentHarvestYear,
    selectedHarvestYearParam,
    setSelectedHarvestYearParam,
    harvestYearsOpen,
  ]);

  return (
    <FieldHarvestYearContext.Provider value={value}>
      {children}
    </FieldHarvestYearContext.Provider>
  );
};

export const useFieldHarvestYearContext = () => {
  const context = useContext(FieldHarvestYearContext);

  if (context === undefined) {
    throw new Error(
      "useFieldHarvestYearContext must be used within a FieldHarvestYearContextProvider",
    );
  }

  return context;
};
