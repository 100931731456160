import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse, createPaginatedDataSchema } from "@ag/utils/types";

import {
  SalesOfferDetails,
  SalesOfferDetailsSchema,
} from "../entities/sales-offer-details";

const getSalesOfferDetails = async (id: string) => {
  const response = await axios.get<CommonResponse<SalesOfferDetails>>(
    `/api/v1/offers/${id}`,
    {
      schema: createPaginatedDataSchema(SalesOfferDetailsSchema),
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const generateSalesOfferDetailsQueryKey = (id?: string) =>
  ["sales-offer-details", id].filter(Boolean);

export const useSalesOfferDetailsQuery = (id: string) =>
  useQuery(
    generateSalesOfferDetailsQueryKey(id),
    () => getSalesOfferDetails(id),
    {
      keepPreviousData: true,
    },
  );
