import { forwardRef, useMemo } from "react";
import { Control, Controller } from "react-hook-form";

import { Input as DSInput } from "@ag/design-system/atoms";
import { Select as DSSelect } from "@ag/design-system/molecules";
import { FormField } from "@ag/design-system/organisms";

import * as styles from "./PhoneNumberInputField.css";
import { getPhoneCountryCodeOptions } from "./helpers";

type PhoneNumberFieldInputProps = {
  // @ts-ignore Recommended hack to get around TS specificity bug
  control: Control<any, object>;
  label: string;
  tooltip?: string;
  countryCodeInputName?: string;
  phoneNumberInputName?: string;
  countryCodePlaceholder?: string;
  phoneNumberPlaceholder?: string;
  optionsClassName?: string;
};

const PhoneNumberInputField: React.FC<PhoneNumberFieldInputProps> = forwardRef(
  (
    {
      control,
      label,
      tooltip,
      countryCodeInputName = "phoneCountryCode",
      phoneNumberInputName = "phoneNumber",
      countryCodePlaceholder,
      phoneNumberPlaceholder,
      optionsClassName,
    },
    _ref,
  ) => {
    const countriesOptions = useMemo(() => getPhoneCountryCodeOptions(), []);

    return (
      <FormField.Root>
        <FormField.Label tooltip={tooltip}>{label}</FormField.Label>
        <div className={styles.phoneNumberInputs}>
          <Controller
            name={countryCodeInputName}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <FormField.Root>
                <DSSelect.Root
                  className={styles.phoneCountryCode}
                  value={value}
                  isInvalid={Boolean(error)}
                  onChange={onChange}
                  placeholder={countryCodePlaceholder}
                  optionsClassName={optionsClassName}
                >
                  {countriesOptions}
                </DSSelect.Root>

                {error && <FormField.Error>{error.message}</FormField.Error>}
              </FormField.Root>
            )}
          />

          <Controller
            name={phoneNumberInputName}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormField.Root>
                <DSInput
                  {...field}
                  type="number"
                  isInvalid={Boolean(error)}
                  placeholder={phoneNumberPlaceholder}
                />

                {error && <FormField.Error>{error.message}</FormField.Error>}
              </FormField.Root>
            )}
          />
        </div>
      </FormField.Root>
    );
  },
);

PhoneNumberInputField.displayName = "PhoneNumberInput";

export { PhoneNumberInputField };
