import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateClientsQueryKey } from "./get-clients";

type Payload = {
  files: File[];
};

const createDocumentsClient = async (id: string, payload: Payload) => {
  const formData = new FormData();
  payload.files.forEach(file => {
    formData.append("files", file);
  });

  await axios.post(`/api/v1/clients/${id}/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    apiSource: "node-markets",
  });
};

export const useCreateDocumentsClientMutation = () =>
  useMutation(
    ({ id, payload }: { id: string; payload: Payload }) =>
      createDocumentsClient(id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(generateClientsQueryKey());
      },
      onError: error => ToastNotification.error(error),
    },
  );
