import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config/react-query";

import { applicationSettingsKeys } from "./queries";
import { ApplicationSetting } from "./types";

/**
 * CUSTOM MUTATION FUNCTIONS
 */
const updateApplicationSetting = async ({
  id,
  ...restData
}: Partial<ApplicationSetting>) => {
  const response = await axios.put<CommonResponse<ApplicationSetting>>(
    `/api/v1/admin/application-settings/${id}`,
    {
      value: restData.value,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

/**
 * MUTATIONS HOOKS
 */
export const useUpdateApplicationSettingMutation = () =>
  useMutation(updateApplicationSetting, {
    onSuccess: () => {
      queryClient.invalidateQueries(applicationSettingsKeys.all);
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
