import { Icon } from "@ag/design-system/assets";
import I18n from "@ag/i18n";

import { SBanner } from "./styled";

const Banner = () => (
  <SBanner>
    <Icon name="warning" variant="regular" />

    {I18n.t("js.carbon.field.edit_mode_banner")}
  </SBanner>
);

export default Banner;
