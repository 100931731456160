import {
  HistoryOfDeforestationAttribute,
  HistoryOfDeforestationData,
} from "./types";

type HistoryOfDeforestationDataError = {
  [HistoryOfDeforestationAttribute.SoilMgmtHistoricConversion]?: boolean;
};

export function validateHistoryOfDeforestationData(
  values: HistoryOfDeforestationData,
): HistoryOfDeforestationDataError {
  const errors: HistoryOfDeforestationDataError = {};

  if (!values) return errors;

  if (
    values[HistoryOfDeforestationAttribute.SoilMgmtHistoricConversion] == null
  ) {
    errors[HistoryOfDeforestationAttribute.SoilMgmtHistoricConversion] = true;
  }

  return errors;
}
