import styled from "@emotion/styled";

export const SApplicationWrapper = styled.div`
  & + & {
    margin-top: 32px;
  }
`;

export const SApplicationRoundTitle = styled.h5`
  margin: 0;
  line-height: 24px;
  font-size: 12px;
  text-transform: uppercase;
`;

export const SIntroText = styled.div`
  ul {
    padding-left: 18px;
    margin-top: 2px;
    margin-bottom: 8px;
  }
`;
