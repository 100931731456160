import { useCallback, useState } from "react";

import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { generateValidationRequestFieldsQueryKey } from "../api/get-validation-request-fields";
import { useResolveValidationRequestFieldsMutation } from "../api/resolve-validation-request-fields";
import { ValidationRequestField } from "../entities/validation-request-field";

export const useValidationRequestFieldsBulkActions = (
  validationRequestId: string,
) => {
  const [selectedFields, setSelectedFields] = useState<
    ValidationRequestField[]
  >([]);

  const resolveValidationRequestFieldsMutation =
    useResolveValidationRequestFieldsMutation();

  const handleBulkAction = useCallback(
    (action: "reject" | "validate") => {
      if (!selectedFields?.length) return;

      const selectedIds = selectedFields.map(field => field.id);

      // make sure the fields can be rejected
      for (const field of selectedFields) {
        if (field.validationStatus === "rejected") {
          ToastNotification.customError(
            I18n.t("js.admin.validation_request.fields.already_rejected"),
          );
          return;
        }

        if (field.validationStatus === "validated") {
          ToastNotification.customError(
            I18n.t("js.admin.validation_request.fields.already_validated"),
          );
          return;
        }
      }

      resolveValidationRequestFieldsMutation.mutate(
        {
          validationRequestId,
          rejectIds: action === "reject" ? selectedIds : undefined,
          approveIds: action === "validate" ? selectedIds : undefined,
        },
        {
          onSuccess: () => {
            const message =
              action === "reject"
                ? I18n.t("js.admin.validation_request.fields.reject_success", {
                    count: selectedFields.length,
                  })
                : I18n.t(
                    "js.admin.validation_request.fields.validate_success",
                    {
                      count: selectedFields.length,
                    },
                  );

            ToastNotification.success(message);

            setSelectedFields([]);

            queryClient.invalidateQueries(
              generateValidationRequestFieldsQueryKey(validationRequestId),
            );
          },
        },
      );
    },
    [
      selectedFields,
      validationRequestId,
      resolveValidationRequestFieldsMutation,
    ],
  );

  return {
    selectedFields,
    setSelectedFields,

    bulkAction: handleBulkAction,
  };
};
