import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

const signInAs = async (userId: string) => {
  const response = await axios.post<CommonResponse<{ ticket: string }>>(
    `/api/v1/admins/user-management/auth/${userId}/login-as`,
    {},
    {
      apiSource: "node-user-mgt",
      schema: z.object({
        ticket: z.string(),
      }),
    },
  );

  return response.data.success!.data;
};

const signInAsAssociatedUser = async (userId: string) => {
  const response = await axios.post<CommonResponse<{ ticket: string }>>(
    `/api/v1/admins/user-management/auth/${userId}/login-as-associated-user`,
    {},
    {
      apiSource: "node-user-mgt",
      schema: z.object({
        ticket: z.string(),
      }),
    },
  );

  return response.data.success!.data;
};

export const useSignInAsMutation = () =>
  useMutation(signInAs, {
    onError: error => {
      ToastNotification.error(error);
    },
  });

export const useSignInAsAssociatedUserMutation = () =>
  useMutation(signInAsAssociatedUser, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
