import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { stack } from "@ag/design-system/utils";
import { InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";
import {
  ApplicationSetting,
  useApplicationSettingQuery,
  useUpdateApplicationSettingMutation,
} from "~queries/application-settings";

const formSchema = z.object({
  name: z.string(),
  type: z.string(),
  value: z.string().min(1, I18n.t("js.shared.required")),
});

type FormValues = z.infer<typeof formSchema>;

type Props = {
  applicationSetting: ApplicationSetting;
};

const Form = ({ applicationSetting }: Props) => {
  const navigate = useNavigate();

  const updateApplicationSettingMutation =
    useUpdateApplicationSettingMutation();

  const {
    control,
    register,
    formState,
    handleSubmit: handleFormSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: applicationSetting.name,
      type: applicationSetting.settingType,
      value: applicationSetting.value,
    },
  });

  const handleSubmit = (values: FormValues): void => {
    updateApplicationSettingMutation.mutate(
      { id: applicationSetting.id, ...values },
      {
        onSuccess: () => {
          ToastNotification.success(
            I18n.t("js.admin.application_setting.setting_updated"),
          );
          navigate("/settings/application");
        },
      },
    );
  };

  return (
    <div className={stack()}>
      <InputField
        label={I18n.t("js.shared.name")}
        {...register("name")}
        error={formState.errors.name}
        isDisabled
      />

      <InputField
        label={I18n.t("js.shared.type")}
        {...register("type")}
        error={formState.errors.type}
        isDisabled
      />

      {applicationSetting.settingType === "Boolean" ? (
        <Controller
          name="value"
          control={control}
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              {...fieldState}
              value={field.value ?? ""}
              label={I18n.t("js.shared.value")}
            >
              <Select.Option key="true" value="true">
                {I18n.t("js.shared.yes")}
              </Select.Option>

              <Select.Option key="false" value="false">
                {I18n.t("js.shared.no")}
              </Select.Option>
            </SelectField>
          )}
        />
      ) : (
        <InputField
          label={I18n.t("js.shared.value")}
          {...register("value")}
          error={formState.errors.value}
        />
      )}

      <Button
        style={{ alignSelf: "end" }}
        isLoading={updateApplicationSettingMutation.isLoading}
        onClick={handleFormSubmit(handleSubmit)}
      >
        {I18n.t("js.shared.add")}
      </Button>
    </div>
  );
};

const ApplicationSettingDetails = () => {
  const { id: applicationId } = useParams();

  const { data: applicationSetting } = useApplicationSettingQuery(
    Number(applicationId!),
  );

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>
          {I18n.t("js.admin.edit_application_setting")}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        {applicationSetting && <Form applicationSetting={applicationSetting} />}
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default ApplicationSettingDetails;
