import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { useCreateFertiliserByHarvestYearQuery } from "../api/create-fertiliser-by-harvest-year";
import { useUpdateFertiliserByHarvestYearQuery } from "../api/update-fertiliser-by-harvest-year";
import { FertiliserHarvestYear } from "../entities/fertiliser";
import {
  mapDataToProperties,
  mapPropertiesToForm,
  processFormData,
} from "../helpers";

//TODO [CF-19]: add old properties when they are going to be migrated

const propertiesFormSchema = () =>
  z.object({
    cWet: z.number().nullable(),
    cDry: z.number().nullable(),
    nWet: z.number().nullable(),
    nDry: z.number().nullable(),
    cN: z.number().nullable(),
    moistureContent: z.union([z.number(), z.string()]).nullable(),
    nameSource: z.string().nullable(),
    refSource: z.string().nullable(),
  });

export type PropertiesFormData = z.infer<
  ReturnType<typeof propertiesFormSchema>
>;

const FertiliserPropertiesForm = ({
  data,
  activeTab,
}: {
  data: FertiliserHarvestYear | undefined | null;
  activeTab: string;
}) => {
  const hasNoProperties =
    !data?.properties || Object.keys(data.properties).length === 0;

  const { id } = useParams<{
    id: string;
  }>();

  const [isEditing, setIsEditing] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<PropertiesFormData>({
    resolver: zodResolver(propertiesFormSchema()),
    defaultValues: mapPropertiesToForm(data?.properties),
  });

  const updateFertiliserByHarvestYear = useUpdateFertiliserByHarvestYearQuery();
  const createFertiliserByHarvestYear = useCreateFertiliserByHarvestYearQuery();

  const handleCancel = () => {
    setIsEditing(false);
    reset();
  };

  const handleOnSuccess = () => {
    ToastNotification.success("Fertiliser properties updated");
    setIsEditing(false);
  };

  const onSubmit = (formData: PropertiesFormData) => {
    const processedData = processFormData(formData);
    const mappedData = mapDataToProperties(processedData);

    if (hasNoProperties) {
      createFertiliserByHarvestYear.mutate(
        {
          properties: mappedData,
          id: id!,
          harvestYear: data?.harvest_year ?? activeTab,
        },
        {
          onSuccess: handleOnSuccess,
        },
      );
    } else {
      updateFertiliserByHarvestYear.mutate(
        {
          properties: mappedData,
          id: data?.fertiliser_id,
          harvestYear: data.harvest_year,
        },
        {
          onSuccess: handleOnSuccess,
        },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-4">
        <InputField
          {...register("cWet", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          label="C% wet"
          step="any"
          type="number"
          error={errors.cWet}
          isDisabled={!isEditing}
        />
        <InputField
          {...register("cDry", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          label="C% dry"
          step="any"
          type="number"
          error={errors.cDry}
          isDisabled={!isEditing}
        />
        <InputField
          {...register("nWet", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          label="N% wet"
          step="any"
          type="number"
          error={errors.nWet}
          isDisabled={!isEditing}
        />
        <InputField
          {...register("nDry", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          label="N% dry"
          step="any"
          type="number"
          error={errors.nDry}
          isDisabled={!isEditing}
        />
        <InputField
          {...register("cN", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          label="C/N"
          step="any"
          type="number"
          error={errors.cN}
          isDisabled={!isEditing}
        />
        <InputField
          {...register("moistureContent")}
          label="Moisture content %"
          error={errors.moistureContent}
          isDisabled={!isEditing}
        />
        <InputField
          {...register("nameSource")}
          label="Name source"
          error={errors.nameSource}
          isDisabled={!isEditing}
        />
        <InputField
          {...register("refSource")}
          label="REF / Source"
          error={errors.refSource}
          isDisabled={!isEditing}
        />
      </div>
      <div className="mt-4 flex justify-start">
        {isEditing ? (
          <div className="flex gap-4">
            <Button type="submit">
              {hasNoProperties ? "Create" : "Update"}
            </Button>
            <Button type="button" variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        ) : (
          <Button type="button" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </div>
    </form>
  );
};
export default FertiliserPropertiesForm;
