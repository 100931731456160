import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  ResaleAgreement,
  ResaleAgreementSchema,
  ResaleContractStatus,
} from "../entities/resale-agreement";

type ResaleAgreementsParams = MetaArgs & {
  filters?: Partial<{
    userId: string;
    contractStatus: ResaleContractStatus[];
    year: string;
    signerName: string;
    country: string;
  }>;
  sort?: Partial<{
    id: SortingDirection;
    year: SortingDirection;
  }>;
};

const getResaleAgreements = async (
  params: ResaleAgreementsParams | undefined,
) => {
  const response = await axios.get<CommonPaginatedResponse<ResaleAgreement>>(
    "/api/v1/broker-contracts",
    {
      params: {
        ...params,
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(ResaleAgreementSchema),
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const generateResaleAgreementsQueryKey = (keyVariables?: {
  params?: ResaleAgreementsParams | undefined;
}) => ["resale-agreements", keyVariables].filter(Boolean);

export const useResaleAgreementsQuery = (params?: ResaleAgreementsParams) =>
  useQuery(
    generateResaleAgreementsQueryKey({ params }),
    () => getResaleAgreements(params),
    {
      keepPreviousData: true,
    },
  );
