import { FormApi } from "final-form";
import { useCallback, useEffect, useState } from "react";
import { useForm, useFormState } from "react-final-form";

import { Chip } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";

import FertiliserInputs from "./FertiliserInputs";
import { getInitialAddingMode } from "./helpers";
import { SIntroText } from "./styled";
import {
  FertiliserAddingMode,
  FertiliserAttribute,
  FertiliserV2021Data,
} from "./types";

type Props = {
  applicationModes: LabelValue<string>[];
  initialValues: FertiliserV2021Data | undefined;
  fertilisers: ISOFertiliser[];
  isReadonly?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<FertiliserV2021Data>) => void;
};

export const FertiliserForm = ({
  applicationModes,
  initialValues,
  fertilisers,
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<FertiliserV2021Data>();
  const { values } = useFormState<FertiliserV2021Data>();

  const initialAddingMode = getInitialAddingMode(initialValues);

  useEffect(() => {
    setAddingMode(initialAddingMode);
  }, [initialAddingMode]);

  useEffect(() => {
    form.registerField(
      FertiliserAttribute.FertilisersShowChemicalSpecification,
      () => null,
      {},
    );
  }, [form]);

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const [addingMode, setAddingMode] = useState<FertiliserAddingMode | null>(
    initialAddingMode,
  );

  const handleAddingModeChanged = useCallback(
    (addingMode: FertiliserAddingMode) => {
      setAddingMode(addingMode);
    },
    [setAddingMode],
  );

  const numberOfApplications = values[FertiliserAttribute.Fertilisers].length;
  const hasApplications = numberOfApplications > 0;

  return (
    <>
      {initialAddingMode === null && (
        <>
          <SIntroText
            className="text-p2"
            dangerouslySetInnerHTML={{
              __html: I18n.t("js.carbon.fertilisers_adding_mode_description"),
            }}
          />

          <Chip variant="warning">
            {I18n.t("js.carbon.organic_fertiliser_list_method")}
          </Chip>
        </>
      )}

      <FertiliserInputs
        applicationModes={applicationModes}
        addingMode={addingMode}
        fertilisers={fertilisers}
        hasApplications={hasApplications}
        isReadonly={isReadonly}
        isSubmitting={isSubmitting}
        onAddingModeChange={handleAddingModeChanged}
      />

      {isReadonly && !hasApplications && (
        <div>{I18n.t("js.carbon.no_fertilizers_added")}</div>
      )}
    </>
  );
};
