import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil.js";

import I18n from "@ag/i18n";

import { LabelValue } from "../types";

export const withEmptyPlaceholder = (
  data: string | number | null | undefined,
  dataForAdditionalCheck?: string | number | boolean | null,
): number | string => {
  let hasData = !isNil(data);

  if (dataForAdditionalCheck !== undefined) {
    hasData = Boolean(dataForAdditionalCheck) && dataForAdditionalCheck !== 0;
  }

  if (hasData) {
    return data as NonNullable<Parameters<typeof withEmptyPlaceholder>[0]>;
  }

  return "-";
};

export const getYesNo = (data: unknown): string => {
  const isDataPresent = data && !isEmpty(data);

  return isDataPresent ? I18n.t("js.shared.yes") : I18n.t("js.shared.no");
};

export const getYesNoOptions = (): LabelValue<boolean>[] => [
  {
    label: I18n.t("js.shared.yes"),
    value: true,
  },
  {
    label: I18n.t("js.shared.no"),
    value: false,
  },
];

export const getDecimalNumbers = (number: number | null | undefined) => {
  if (number == null || number === undefined) return null;

  const [_integer, decimal] = String(number).split(".");

  return decimal;
};
