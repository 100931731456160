import { FormApi } from "final-form";
import { useCallback, useContext } from "react";
import { withTypes } from "react-final-form";
import { Element } from "react-scroll";

import { PartialBox } from "@ag/carbon/components";
import {
  HistoryOfDeforestationData,
  HistoryOfDeforestationForm,
  validateHistoryOfDeforestationData,
} from "@ag/carbon/forms/baseline/iso";
import I18n from "@ag/i18n";

import {
  FieldDefinitionPartial,
  FieldPartialProps,
} from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/steps/FieldDefinition/FieldPartials/types";
import FieldDefinitionContext from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/steps/FieldDefinition/context";

import { usePartial } from "./hooks";

type Props = FieldPartialProps;

const { Form: FinalForm } = withTypes<HistoryOfDeforestationData>();

const HistoryOfDeforestation = ({ field }: Props) => {
  const fieldDefinition = field.carbonFieldDefinition;

  const { setPartialFormAPI } = useContext(FieldDefinitionContext);

  const { initialValues } = usePartial(fieldDefinition);

  const handleFormInitialized = useCallback(
    (form: FormApi<HistoryOfDeforestationData>) => {
      setPartialFormAPI({
        partialName: FieldDefinitionPartial.HistoryOfDeforestation,
        formAPI: form,
      });
    },
    [setPartialFormAPI],
  );

  if (!fieldDefinition) return null;

  return (
    <Element name={FieldDefinitionPartial.HistoryOfDeforestation}>
      <FinalForm
        initialValues={initialValues}
        validate={validateHistoryOfDeforestationData}
        render={() => (
          <PartialBox
            title={I18n.t("js.carbon.soil_management_practices")}
            headerTestTag="historyOfDeforestation-collapseBox"
            isOpen
          >
            <HistoryOfDeforestationForm
              // TODO: Update when API will be ready
              isSubmitting={false}
              isSubmitButtonVisible={false}
              onFormInitialize={handleFormInitialized}
            />
          </PartialBox>
        )}
        onSubmit={() => undefined}
      />
    </Element>
  );
};

export default HistoryOfDeforestation;
