import {
  FloatingMenu,
  FloatingMenuOptionProps,
  FloatingMenuProps,
} from "~molecules/FloatingMenu";

export type ActionsCellValue = {
  items: (FloatingMenuOptionProps | null)[] | null;
} & (
  | {
      title: string;
      variant?: "default";
    }
  | {
      title?: never;
      variant: "compact";
    }
) &
  Omit<FloatingMenuProps, "title" | "variant" | "children">;

type ActionsCellProps = {
  getValue: () => ActionsCellValue;
};

export const ActionsCell = ({ getValue }: ActionsCellProps) => {
  const value = getValue();

  if (!value.items) {
    return null;
  }

  return (
    <FloatingMenu.Root
      placement="start"
      triggerSize="x-small"
      triggerVariant="secondary"
      {...value}
    >
      {value.items.map((item, idx) => {
        if (!item) return null;

        return <FloatingMenu.Option key={idx} {...item} />;
      })}
    </FloatingMenu.Root>
  );
};
