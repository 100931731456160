import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Dropzone, DropzoneProps } from "@ag/design-system/atoms";
import { FormField } from "@ag/design-system/organisms";

type Props = {
  label: string;
  children: React.ReactNode;
  tooltip?: string;
  error?: FieldError;
  labelClassName?: string;
} & DropzoneProps;

export const DropzoneField = forwardRef<unknown, Props>(
  (
    {
      labelClassName,
      label,
      tooltip,
      children,
      error,
      isDisabled = false,
      ...rest
    },
    _ref,
  ) => {
    return (
      <FormField.Root isFullSize>
        <FormField.Label className={labelClassName} tooltip={tooltip}>
          {label}
        </FormField.Label>

        <Dropzone isDisabled={isDisabled} {...rest}>
          {children}
        </Dropzone>

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

DropzoneField.displayName = "DropzoneField";
