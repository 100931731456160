import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { Select } from "@ag/design-system/molecules";
import { parseSorting } from "@ag/design-system/organisms";
import { InputField, SelectField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import { useFertilisersQuery, useFertilisersTable } from "~features/fertiliser";
import {
  FertiliserFilters,
  FertiliserFiltersSchema,
} from "~features/fertiliser/entities/fertiliser";
import {
  METHODOLOGY_VERSIONS_OPTIONS,
  useOpenHarvestYearsOptions,
} from "~features/initial-resources";
import { AuthorizedSidebar } from "~features/navigation";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const Fertilisers = () => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: "id", desc: true },
  ]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const [query, setQuery] = useQueryParams({
    matchFertiliserName: withDefault(StringParam, undefined),
    harvestYear: withDefault(StringParam, undefined),
    methodologyVersion: withDefault(StringParam, undefined),
    matchCoolfarmName: withDefault(StringParam, undefined),
    coolfarmIdentifier: withDefault(StringParam, undefined),
  });

  const { data: openHarvestYearsOptions } = useOpenHarvestYearsOptions();

  const { data: fertilisersData, isLoading } = useFertilisersQuery({
    ...pagination,
    filters: {
      ...query,
    },
    sort: parseSorting(sorting),
  });

  const renderFilterBarItem = (
    key: keyof FertiliserFilters,
    value: FertiliserFilters[keyof FertiliserFilters],
  ) => {
    const label = (
      {
        matchFertiliserName: "Fertiliser Name",
        harvestYear: "Harvest year",
        methodologyVersion: "Methodology version",
        matchCoolfarmName: "Coolfarm name",
        coolfarmIdentifier: "Coolfarm ID",
      } as unknown as Record<keyof FertiliserFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FertiliserFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(FertiliserFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<FertiliserFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );
  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  const table = useFertilisersTable(fertilisersData?.data, {
    sorting,
    onSortingChange: setSorting,
  });

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Fertilisers</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField
            {...register("matchFertiliserName")}
            label="Fertiliser Name"
            error={errors.matchFertiliserName}
          />

          <Controller
            name="harvestYear"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                label="Harvest year"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {(openHarvestYearsOptions ?? []).map(harvestYear => {
                  return (
                    <Select.Option
                      key={harvestYear?.value}
                      value={harvestYear?.value.toString()}
                    >
                      {harvestYear?.label}
                    </Select.Option>
                  );
                })}
              </SelectField>
            )}
          />
          <Controller
            name="methodologyVersion"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                label="Methodology version"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>
                {METHODOLOGY_VERSIONS_OPTIONS.map(version => (
                  <Select.Option
                    key={version?.value}
                    value={version?.value.toString()}
                  >
                    {version?.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
          <InputField
            {...register("matchCoolfarmName")}
            label="Coolfarm name"
            error={errors.matchCoolfarmName}
          />
          <InputField
            {...register("coolfarmIdentifier")}
            label="Coolfarm ID"
            error={errors.coolfarmIdentifier}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={fertilisersData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Fertilisers;
