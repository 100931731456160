import { cva } from "class-variance-authority";

import { Icon } from "~assets";
import { cn } from "~utils";

import { ICONS_REGULAR } from "../../assets/Icon";

export type AssistiveElementVariant = "warning" | "neutral" | "info" | "danger";

export type AssistiveElementProps = React.PropsWithChildrenRequired<{
  variant?: AssistiveElementVariant;
  className?: string;
  isIconVisible?: boolean;
}>;

export const AssistiveElement = ({
  variant = "neutral",
  children,
  className,
  isIconVisible = true,
}: AssistiveElementProps) => {
  const icon: Record<AssistiveElementVariant, keyof typeof ICONS_REGULAR> = {
    warning: "circle-exclamation",
    neutral: "circle-info",
    info: "circle-info",
    danger: "triangle-exclamation",
  };

  const assistiveElementVariants = cva(undefined, {
    variants: {
      variant: {
        warning: "text-messaging-error-700",
        neutral: "text-grey-700",
        info: "text-grey-700",
        danger: "text-messaging-error-700",
      },
    },
  });

  return (
    <span
      className={cn(
        "inline-flex items-center gap-1 text-p3 text-grey-700",
        assistiveElementVariants({ variant, className }),
      )}
    >
      {isIconVisible && (
        <Icon
          className={cn("text-p2", {
            "mt-[-1px]": variant === "danger",
          })}
          name={icon[variant]}
        />
      )}

      {children}
    </span>
  );
};
