import { Fragment } from "react";

import { useCarbonCountries } from "~features/countries";
import { getCountryWithFlag } from "~helpers/countries";

import { UpdateRequestAction } from "../entities/update-request";
import { UpdateRequestSummary as TUpdateRequestSummary } from "../entities/update-request-summary";
import { UpdateRequestSummaryCard } from "./update-request-summary-card";

type Props = {
  summary: TUpdateRequestSummary;
  action: UpdateRequestAction;
};

export const UpdateRequestSummary = ({ summary, action }: Props) => {
  const { data: countries } = useCarbonCountries();

  return (
    <section className="flex flex-col gap-3 pt-3">
      <section className="flex justify-between">
        <h3 className="text-h3 font-bold">
          Total certificates to be{" "}
          {action === "transfer" ? "transferred" : "retired"}
        </h3>
        <h1 className="text-h1 font-bold" data-testid="total">
          {summary.quantity}
        </h1>
      </section>

      <div className="flex w-full items-start gap-2">
        <div className="flex flex-1 flex-col gap-2">
          <UpdateRequestSummaryCard title="Standard">
            <>
              <span>Verra</span>
              <span className="text-right font-bold">
                {summary.breakdown.standards.verra}
              </span>
            </>

            <>
              <span>ISO</span>
              <span className="text-right font-bold">
                {summary.breakdown.standards.iso}
              </span>
            </>
          </UpdateRequestSummaryCard>

          <UpdateRequestSummaryCard title="Type">
            <>
              <span>Removal</span>
              <span className="text-right font-bold">
                {summary.breakdown.types.removal}
              </span>
            </>

            <>
              <span>Reduction</span>
              <span className="text-right font-bold">
                {summary.breakdown.types.reduction}
              </span>
            </>
          </UpdateRequestSummaryCard>

          <UpdateRequestSummaryCard title="Vintage year">
            {Object.entries(summary.breakdown.vintageYears).map(
              ([year, count]) => (
                <Fragment key={year}>
                  <span>{year}</span>
                  <span className="text-right font-bold">{count}</span>
                </Fragment>
              ),
            )}
          </UpdateRequestSummaryCard>
        </div>

        <div className="flex-1">
          <UpdateRequestSummaryCard title="Country of origin">
            {Object.entries(summary.breakdown.countries).map(
              ([countryCode, count]) => {
                const certificateCountry = countries?.find(
                  country => country.code === countryCode,
                );

                const { flag, name } = getCountryWithFlag(
                  certificateCountry?.name,
                );

                return (
                  <Fragment key={countryCode}>
                    <span>{`${flag} ${name}`}</span>
                    <span
                      className="text-right font-bold"
                      data-testid={`summary-card-${countryCode}`}
                    >
                      {count}
                    </span>
                  </Fragment>
                );
              },
            )}
          </UpdateRequestSummaryCard>
        </div>
      </div>
    </section>
  );
};
