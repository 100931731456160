import capitalize from "lodash/capitalize";
import { useEffect, useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import { Controller, useForm } from "react-hook-form";

import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { grid, stack } from "@ag/design-system/utils";
import { InputField, SelectField } from "@ag/form-fields";
import I18n, { Locale, getLocales } from "@ag/i18n";
import { getYesNoOptions } from "@ag/utils/helpers";

import Card from "~components/card";
import {
  METHODOLOGY_VERSIONS_OPTIONS,
  useOpenHarvestYearsOptions,
} from "~features/initial-resources";

import {
  EditFertiliserFormAttribute,
  EditFertiliserFormData,
  EditFertiliserOptionalFormAttribute,
} from "../types";

const Form = ({ initialValues }: { initialValues: EditFertiliserFormData }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useForm<EditFertiliserFormData>({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const availableLocales = useMemo(
    () => getLocales().filter(locale => locale !== Locale.En),
    [],
  );

  const { data: openHarvestYearsOptions } = useOpenHarvestYearsOptions();

  const [isTranslationSectionVisible, setIsTranslationSectionVisible] =
    useState(false);

  useEffect(() => {
    if (errors.nameEn && !isTranslationSectionVisible) {
      setIsTranslationSectionVisible(true);
    }
  }, [errors, isTranslationSectionVisible]);

  return (
    <Card.Root>
      <div className={stack()}>
        <div className={grid()}>
          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserFormAttribute.NameEn, {
                required: true,
              })}
              label={`${I18n.t(
                "js.carbon.fertilisers.attribute.name",
              )} (${Locale.En.toUpperCase()})`}
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.DescriptionEn)}
              label={`${I18n.t(
                "js.carbon.fertilisers.attribute.description",
              )} (${Locale.En.toUpperCase()})`}
              isDisabled
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-basis-0 flex-grow px-2 md:basis-1/2">
            <InputField
              {...register(EditFertiliserFormAttribute.Identifier)}
              label={I18n.t("js.carbon.fertilisers.attribute.identifier")}
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2 md:basis-1/4">
            <InputField
              {...register(EditFertiliserFormAttribute.Id)}
              label={I18n.t("js.carbon.fertilisers.attribute.id")}
              isDisabled
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserFormAttribute.CoolfarmIdentifier, {
                required: true,
              })}
              label={I18n.t("js.carbon.fertilisers.attribute.coolfarm_id")}
              type="number"
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserFormAttribute.CoolfarmName, {
                required: true,
              })}
              label={I18n.t("js.carbon.fertilisers.attribute.coolfarm_name")}
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <Controller
              name={EditFertiliserFormAttribute.MethodologyVersion}
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t(
                    "js.carbon.fertilisers.attribute.methodology_version",
                  )}
                  isDisabled
                >
                  {Object.values(METHODOLOGY_VERSIONS_OPTIONS).map(
                    ({ value, label }) => (
                      <Select.Option key={value} value={value}>
                        {label}
                      </Select.Option>
                    ),
                  )}
                </SelectField>
              )}
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <Controller
              name={EditFertiliserFormAttribute.HarvestYear}
              control={control}
              render={({ field, fieldState }) => (
                //@ts-ignore
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.carbon.fertilisers.attribute.harvest_year")}
                  isDisabled
                >
                  {openHarvestYearsOptions?.map(({ value, label }) => (
                    //@ts-ignore
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.OrganicCarbon, {
                required: true,
              })}
              label={I18n.t("js.carbon.fertilisers.attribute.organic_carbon")}
              type="number"
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.NitrogenTotal, {
                required: true,
              })}
              label={I18n.t("js.carbon.fertilisers.attribute.nitrogen_total")}
              type="number"
              isDisabled
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.NitrogenUrea)}
              label={I18n.t("js.carbon.fertilisers.attribute.nitrogen_urea")}
              type="number"
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.NitrateNitrogen)}
              label={I18n.t("js.carbon.fertilisers.attribute.nitrate_nitrogen")}
              type="number"
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.Ammonium)}
              label={I18n.t("js.carbon.fertilisers.attribute.ammonium")}
              type="number"
              isDisabled
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.Phosphorus)}
              label={I18n.t("js.carbon.fertilisers.attribute.phosphorus")}
              type="number"
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(
                EditFertiliserOptionalFormAttribute.PhosphorusPentoxide,
              )}
              label={I18n.t(
                "js.carbon.fertilisers.attribute.phosphorus_pentoxide",
              )}
              type="number"
              isDisabled
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.Potassium)}
              label={I18n.t("js.carbon.fertilisers.attribute.potassium")}
              type="number"
              isDisabled
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <InputField
              {...register(EditFertiliserOptionalFormAttribute.PotassiumOxide)}
              label={I18n.t("js.carbon.fertilisers.attribute.potassium_oxide")}
              type="number"
              isDisabled
            />
          </div>
        </div>

        <div className="flex flex-wrap">
          <div className="flex-basis-0 flex-grow px-2 md:basis-1/4">
            <Controller
              name={EditFertiliserFormAttribute.Organic}
              control={control}
              render={({ field, fieldState }) => (
                //@ts-ignore
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.carbon.crop_type_attribute.show_residue")}
                  isDisabled
                >
                  {getYesNoOptions()?.map(({ value, label }) => (
                    <Select.Option
                      key={value as unknown as string}
                      value={value as unknown as string}
                    >
                      {label}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2 md:basis-1/4">
            <Controller
              name={EditFertiliserFormAttribute.NitrificationAdded}
              control={control}
              render={({ field, fieldState }) => (
                //@ts-ignore
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.carbon.crop_type_attribute.show_residue")}
                  isDisabled
                >
                  {getYesNoOptions()?.map(({ value, label }) => (
                    <Select.Option
                      key={value as unknown as string}
                      value={value as unknown as string}
                    >
                      {label}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          </div>

          <div className="flex-basis-0 flex-grow px-2 md:basis-1/4">
            <Controller
              name={EditFertiliserFormAttribute.ChemicalSpecification}
              control={control}
              render={({ field, fieldState }) => (
                //@ts-ignore
                <SelectField
                  {...field}
                  error={fieldState.error}
                  label={I18n.t("js.carbon.crop_type_attribute.show_residue")}
                  isDisabled
                >
                  {getYesNoOptions()?.map(({ value, label }) => (
                    <Select.Option
                      key={value as unknown as string}
                      value={value as unknown as string}
                    >
                      {label}
                    </Select.Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
        </div>

        <div className="flex justify-end">
          {/* @ts-ignore */}
          <Button
            className="my-4"
            variant="text"
            iconPosition="after"
            onClick={() =>
              setIsTranslationSectionVisible(!isTranslationSectionVisible)
            }
          >
            {isTranslationSectionVisible
              ? I18n.t("js.shared.hide_translations")
              : I18n.t("js.shared.show_translations")}
            <Icon
              name={isTranslationSectionVisible ? "chevron-up" : "chevron-down"}
              className="mx-2"
            />
          </Button>
        </div>

        <Collapse isOpened={isTranslationSectionVisible}>
          <div className="mt-4 flex flex-col">
            <div className="mb-4 flex flex-wrap">
              {availableLocales.map(locale => (
                <div className="flex-basis-0 flex-grow px-2" key={locale}>
                  <InputField
                    //@ts-ignore
                    {...register(`name${capitalize(locale)}`)}
                    label={`${I18n.t(
                      "js.carbon.crop_type_attribute.name",
                    )} (${locale})`}
                    isDisabled
                  />
                </div>
              ))}
            </div>
          </div>
        </Collapse>
      </div>
    </Card.Root>
  );
};

export default Form;
