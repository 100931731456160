
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/tokens/radius.css.ts", "@ag/design-system");
      import { createGlobalTheme, createGlobalThemeContract } from "@vanilla-extract/css";
const radiusThemeContract = createGlobalThemeContract({
  100: "",
  200: ""
}, (_, path) => `radius-${path.join("-")}`);
createGlobalTheme(":root", radiusThemeContract, {
  100: "4px",
  200: "8px"
});
export default radiusThemeContract;
      endFileScope();
      
    