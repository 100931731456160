import pick from "lodash/pick";

import { AdditionalInformationAttribute } from "@ag/carbon/forms/inputs-v2021";

import { FieldActualsV2022Input } from "~features/field";
import { FieldActualsInputV2022 } from "~queries/carbon/field-actuals";

export const getInitialValues = (fieldActualsInput: FieldActualsV2022Input) =>
  pick<FieldActualsInputV2022, AdditionalInformationAttribute>(
    fieldActualsInput,
    Object.values(AdditionalInformationAttribute),
  );
