import { z } from "zod";

import {
  CropProtectionAttribute,
  CropProtectionDetailsAttribute,
} from "./types";

export const CropProtectionDetailsDataSchema = z.object({
  [CropProtectionDetailsAttribute.Type]: z.string().nullable(),
  [CropProtectionDetailsAttribute.Category]: z.string().nullable(),
  [CropProtectionDetailsAttribute.NumberOfDoses]: z.number().nullable(),
});

export const CropProtectionV2021DataSchema = z.object({
  [CropProtectionAttribute.CropProtections]: z.array(
    CropProtectionDetailsDataSchema,
  ),
  [CropProtectionAttribute.CropProtectionsCount]: z.number(),
});
