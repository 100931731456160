import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateClientsQueryKey } from "./get-clients";

const deleteClient = (clientId: string) =>
  axios.delete(`/api/v1/clients/${clientId}`, {
    apiSource: "node-markets",
  });

export const useDeleteClientMutation = () =>
  useMutation(deleteClient, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateClientsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
