import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useParams } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";
import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField, InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { useCreateFertiliserByHarvestYearQuery } from "../api/create-fertiliser-by-harvest-year";
import { useUpdateFertiliserByHarvestYearQuery } from "../api/update-fertiliser-by-harvest-year";
import {
  FertiliserHarvestYear,
  FertiliserLegacyProperties,
  LegacyPropertiesSchema,
} from "../entities/fertiliser";

const FertiliserLegacyPropertiesForm = ({
  data,
}: {
  data: FertiliserHarvestYear | undefined | null;
}) => {
  const hasNoProperties =
    !data?.properties.legacy_properties ||
    Object.keys(data.properties.legacy_properties).length === 0;

  const { id } = useParams<{ id: string }>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");

  const [isEditing, setIsEditing] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FertiliserLegacyProperties>({
    resolver: zodResolver(LegacyPropertiesSchema),
    defaultValues: data?.properties.legacy_properties,
  });

  const updateFertiliserByHarvestYear = useUpdateFertiliserByHarvestYearQuery();
  const createFertiliserByHarvestYear = useCreateFertiliserByHarvestYearQuery();

  const handleCancel = () => {
    setIsEditing(false);
    reset();
  };

  const handleOnSuccess = () => {
    ToastNotification.success("Fertiliser properties updated");
    setIsEditing(false);
  };

  const onSubmit = (formData: FertiliserLegacyProperties) => {
    if (hasNoProperties) {
      createFertiliserByHarvestYear.mutate(
        {
          properties: { legacy_properties: formData },
          id: id!,
          harvestYear: data?.harvest_year ?? tab!,
        },
        {
          onSuccess: handleOnSuccess,
        },
      );
    } else {
      updateFertiliserByHarvestYear.mutate(
        {
          properties: { legacy_properties: formData },
          id: data.fertiliser_id,
          harvestYear: data.harvest_year,
        },
        { onSuccess: handleOnSuccess },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-2 gap-4">
        <Controller
          name="custom"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label="Custom"
              value={Boolean(field.value)}
              isDisabled={!isEditing}
              error={errors.custom}
            >
              <ButtonSelect.Option value={true}>Yes</ButtonSelect.Option>
              <ButtonSelect.Option value={false}>No</ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
        <Controller
          name="nitrification_added"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label="Nitrification Added"
              value={Boolean(field.value)}
              isDisabled={!isEditing}
              error={errors.nitrification_added}
            >
              <ButtonSelect.Option value={true}>Yes</ButtonSelect.Option>
              <ButtonSelect.Option value={false}>No</ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
        <InputField
          label="Distributor"
          {...register("distributor")}
          isDisabled={!isEditing}
          error={errors.distributor}
        />
        <InputField
          label="Distributor Company Code"
          {...register("distributor_company_code")}
          isDisabled={!isEditing}
          error={errors.distributor_company_code}
        />
        <InputField
          label="Magnesium"
          {...register("magnesium", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.magnesium}
        />
        <InputField
          label="Ammonium"
          {...register("ammonium", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.ammonium}
        />
        <InputField
          label="Nitrate Nitrogen"
          {...register("nitrate_nitrogen", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.nitrate_nitrogen}
        />
        <InputField
          label="Phosphorus"
          {...register("phosphorus", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.phosphorus}
        />
        <InputField
          label="Nitrogen Total"
          {...register("nitrogen_total", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.nitrogen_total}
        />
        <InputField
          label="Nitrogen Urea"
          {...register("nitrogen_urea", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.nitrogen_urea}
        />
        <InputField
          label="Potassium"
          {...register("potassium", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.potassium}
        />
        <InputField
          label="Sulfur"
          {...register("sulfur", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.sulfur}
        />
        <Controller
          name="biomass"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label="Biomass"
              value={Boolean(field.value)}
              isDisabled={!isEditing}
              error={errors.biomass}
            >
              <ButtonSelect.Option value={true}>Yes</ButtonSelect.Option>
              <ButtonSelect.Option value={false}>No</ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
        <InputField
          label="Potassium Oxide"
          {...register("potassium_oxide", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.potassium_oxide}
        />
        <InputField
          label="Phosphorus Pentoxide"
          {...register("phosphorus_pentoxide", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.phosphorus_pentoxide}
        />
        <Controller
          name="chemical_specification"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label="Chemical Specification"
              value={Boolean(field.value)}
              isDisabled={!isEditing}
              error={errors.chemical_specification}
            >
              <ButtonSelect.Option value={true}>Yes</ButtonSelect.Option>
              <ButtonSelect.Option value={false}>No</ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
        <Controller
          name="organic"
          control={control}
          render={({ field }) => (
            <ButtonSelectField
              {...field}
              label="Organic"
              value={Boolean(field.value)}
              isDisabled={!isEditing}
              error={errors.organic}
            >
              <ButtonSelect.Option value={true}>Yes</ButtonSelect.Option>
              <ButtonSelect.Option value={false}>No</ButtonSelect.Option>
            </ButtonSelectField>
          )}
        />
        <InputField
          label="Identifier"
          {...register("identifier")}
          isDisabled={!isEditing}
          error={errors.identifier}
        />
        <InputField
          label="Methodology Version"
          {...register("methodology_version", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.methodology_version}
        />
        <InputField
          label="Organic Carbon"
          {...register("organic_carbon", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          step="any"
          type="number"
          isDisabled={!isEditing}
          error={errors.organic_carbon}
        />
        <InputField
          label="Coolfarm Name"
          {...register("coolfarm_name")}
          isDisabled={!isEditing}
          error={errors.coolfarm_name}
        />
        <InputField
          label="Coolfarm Identifier"
          {...register("coolfarm_identifier", {
            setValueAs: v => (v === "" ? undefined : parseFloat(v)),
          })}
          isDisabled={!isEditing}
          error={errors.coolfarm_identifier}
          type="number"
        />
      </div>
      <div className="mt-4 flex justify-start">
        {isEditing ? (
          <div className="flex gap-4">
            <Button type="submit">
              {hasNoProperties ? "Create" : "Update"}
            </Button>
            <Button type="button" variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        ) : (
          <Button type="button" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </div>
    </form>
  );
};
export default FertiliserLegacyPropertiesForm;
