import { ChipCellValue } from "@ag/design-system/organisms";

import { OfferStatus } from "../types/offer-status";

export const getOfferStatusVariant = (status: OfferStatus): ChipCellValue => {
  const statusMapping: Record<OfferStatus, ChipCellValue> = {
    [OfferStatus.ContractCreated]: {
      label: "Contract Created",
      variant: "info",
    },
    [OfferStatus.Open]: {
      label: "Open",
      variant: "success",
    },
  };

  return statusMapping[status];
};
