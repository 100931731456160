import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

const cancelUpdateRequest = async (updateRequestId: string | undefined) => {
  await axios.patch(
    `/api/v1/certificates/groups/update-requests/${updateRequestId}/cancel`,
    {},
    {
      apiSource: "node-markets",
    },
  );
};

export const useCancelUpdateRequest = () =>
  useMutation(cancelUpdateRequest, {
    onError: error => ToastNotification.error(error),
  });
