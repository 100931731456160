/**
 * Transforms an object by replacing all falsy values with undefined.
 * This is useful for handling filters and filters chips in a Filter.Bar, where it needs to have strings in order to show filters and undefined in order to not show them.
 *
 * @param {Record<string, string | undefined>} obj - The object to transform.
 * @returns {Record<string, string | undefined>} A new object with the same keys as the input object,
 * but where all falsy values have been replaced with `undefined`.
 */

function replaceEntryWithUndefined(
  obj: Record<string, string | number | undefined>,
) {
  return Object.entries(obj).reduce(
    (acc, [key, value]) => {
      acc[key] = value == null || value === "" ? undefined : value;
      return acc;
    },
    {} as Record<string, string | number | undefined>,
  );
}

export default replaceEntryWithUndefined;
