import { z } from "zod";

export const BoundaryVerificationFarmSchema = z.object({
  userId: z.string(),
  companyName: z.string(),
  farmId: z.string(),
  farmName: z.string(),
  fieldCount: z.string(),
  lastUpdated: z.string(),
  levelOneFlagCount: z.string(),
  levelTwoFlagCount: z.string(),
});

export type BoundaryVerificationFarm = z.infer<
  typeof BoundaryVerificationFarmSchema
>;
