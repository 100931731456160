import I18n from "i18n-js";
import { useState } from "react";

import { ToastNotification } from "@ag/utils/services";

import { useDeleteFieldsMutation } from "~queries/carbon";

import { useUnlockFieldsActualsMutation } from "../api/unlock-fields-actuals";
import { useUnlockFieldsDefinitionsMutation } from "../api/unlock-fields-definitions";
import {
  CarbonFieldUpdate,
  useToggleFieldsExpiredStatusMutation,
  useUpdateFieldsDetailsMutation,
} from "../api/update-field-details";
import { FieldOverview } from "../entities/field-overview";
import {
  validateCanDeleteField,
  validateCanUnlockActuals,
  validateCanUnlockDefinition,
} from "../helpers/bulk-actions";

export const useFieldsBulkActions = () => {
  const [selectedFields, setSelectedFields] = useState<FieldOverview[]>([]);

  const unlockFieldsDefinitionsMutation = useUnlockFieldsDefinitionsMutation();
  const unlockFieldsActualsMutation = useUnlockFieldsActualsMutation();
  const deleteFieldsMutation = useDeleteFieldsMutation();
  const updateFieldsDetailsMutation = useUpdateFieldsDetailsMutation();
  const toggleFieldsExpiredStatusMutation =
    useToggleFieldsExpiredStatusMutation();

  const handleBulkUnlockDefinitions = () => {
    const payloads: { fieldId: string; fieldDefinitionId: string }[] = [];

    for (const field of selectedFields) {
      const { error } = validateCanUnlockDefinition(field);
      if (error) {
        ToastNotification.customError(error);
        return;
      }

      payloads.push({
        fieldId: field.id,
        fieldDefinitionId: String(field.carbonFieldDefinition.id),
      });
    }

    unlockFieldsDefinitionsMutation.mutate(payloads, {
      onSuccess: () => {
        ToastNotification.success(
          I18n.t("js.admin.fields.definitions_unlocked", {
            count: selectedFields.length,
          }),
        );

        setSelectedFields([]);
      },
    });
  };

  const handleBulkUnlockActuals = (bulkActionYear: number) => {
    const payloads: {
      fieldId: string;
      fieldActualsId: string;
    }[] = [];

    for (const field of selectedFields) {
      const { error } = validateCanUnlockActuals(field, bulkActionYear);
      if (error) {
        ToastNotification.customError(error);
        return;
      }

      const actuals = (field.carbonFieldActuals ?? [])
        .filter(actuals => actuals.submittedAt)
        .filter(actuals => actuals.harvestYear === bulkActionYear);

      payloads.push(
        ...actuals.flatMap(actuals => ({
          fieldId: field.id,
          fieldActualsId: actuals.id,
        })),
      );
    }

    unlockFieldsActualsMutation.mutate(payloads, {
      onSuccess: () => {
        ToastNotification.success(
          I18n.t("js.admin.fields.actuals_unlocked", {
            count: selectedFields.length,
          }),
        );

        setSelectedFields([]);
      },
    });
  };

  type BulkDeleteFieldsCallbacks = {
    onSuccess: () => void;
  };

  const handleBulkDeleteFields = ({ onSuccess }: BulkDeleteFieldsCallbacks) => {
    for (const field of selectedFields) {
      const { error } = validateCanDeleteField(field);
      if (error) {
        ToastNotification.customError(error);
        return;
      }
    }

    const fieldIds = selectedFields.map(field => field.id);

    deleteFieldsMutation.mutate(fieldIds, {
      onSuccess: response => {
        let fieldsDeleted = 0;

        // Ruby delete bulk field response includes the error in the success objects
        response.forEach(
          (fieldDeleteResponse: { id: string; errors: string }) => {
            if (fieldDeleteResponse.errors) {
              ToastNotification.error(
                `FieldId: ${fieldDeleteResponse.id} - ${fieldDeleteResponse.errors}`,
              );
            } else {
              fieldsDeleted++;
            }
          },
        );
        if (fieldsDeleted > 0) {
          ToastNotification.success(
            I18n.t("js.fields.delete_fields_success", {
              count: fieldsDeleted,
            }),
          );
        }

        setSelectedFields([]);

        onSuccess();
      },
    });
  };

  type BulkEditFieldNamesCallbacks = {
    onSuccess: () => void;
  };

  const handleBulkEditFieldNames = (
    carbonFields: CarbonFieldUpdate[],
    { onSuccess }: BulkEditFieldNamesCallbacks,
  ) => {
    updateFieldsDetailsMutation.mutate({
      carbonFields: carbonFields,
    });

    onSuccess();
    setSelectedFields([]);
  };

  const handleBulkExpireFields = () => {
    const fieldsToExpire: CarbonFieldUpdate[] = selectedFields.map(field => ({
      id: field.id.toString(),
      expired: true,
    }));

    toggleFieldsExpiredStatusMutation.mutate(
      { carbonFields: fieldsToExpire },
      {
        onSuccess: response => {
          if (response.success && response.success.length > 0) {
            ToastNotification.success(
              response.success.length !== 1
                ? `${response.success.length} fields expired`
                : `1 field expired`,
            );
          }
          if (response.errors?.length) {
            response.errors.forEach(err => {
              const errMessage = `FieldId ${err.payload.id}: ${err.message}`;
              ToastNotification.error(errMessage);
            });
          }
          setSelectedFields([]);
        },
      },
    );
  };

  const handleBulkUnexpireFields = () => {
    const fieldsToUnexpire: CarbonFieldUpdate[] = selectedFields.map(field => ({
      id: field.id.toString(),
      expired: false,
    }));

    toggleFieldsExpiredStatusMutation.mutate(
      { carbonFields: fieldsToUnexpire },
      {
        onSuccess: response => {
          if (response.success && response.success.length > 0) {
            ToastNotification.success(
              response.success.length !== 1
                ? `${response.success.length} fields unexpired`
                : `1 field unexpired`,
            );
          }
          if (response.errors?.length) {
            response.errors.forEach(err => {
              const errMessage = `FieldId ${err.payload.id}: ${err.message}`;
              ToastNotification.error(errMessage);
            });
          }
          setSelectedFields([]);
        },
      },
    );
  };

  return {
    selectedFields,
    setSelectedFields,

    bulkDeleteFields: handleBulkDeleteFields,
    bulkUnlockDefinitions: handleBulkUnlockDefinitions,
    bulkUnlockActuals: handleBulkUnlockActuals,
    bulkEditFieldnames: handleBulkEditFieldNames,
    bulkExpireFields: handleBulkExpireFields,
    bulkUnexpireFields: handleBulkUnexpireFields,
  };
};
