import { useQuery } from "@tanstack/react-query";

import { APIResponse } from "@ag/utils/types";

import { DEFAULT_PAGE_SIZE } from "~constants/params";
import { getRequest } from "~lib/axios";

import {
  CropType,
  CropTypesListQueryKeyArgs,
  CropTypesResponse,
  FetchCropTypesArgs,
  UseCropTypesQueryArgs,
  UseCropTypesQueryOptions,
} from "./types";

export const cropTypesKeys = {
  all: ["crop-types"] as const,

  listAll: () => [...cropTypesKeys.all, "list"] as const,
  list: ({ filters, metaArgs }: CropTypesListQueryKeyArgs) =>
    [...cropTypesKeys.listAll(), { filters, metaArgs }] as const,
  detailsAll: () => [...cropTypesKeys.all, "details"] as const,
  details: (cropTypeId: number) =>
    [...cropTypesKeys.detailsAll(), cropTypeId] as const,
};

/**
 * CUSTOM QUERY FUNCTIONS
 */
export const fetchCropType = async (cropTypeId: number) => {
  const response: APIResponse<CropType> = await getRequest({
    path: `/api/farmer/carbon/v1/admin/carbon_crop_types/${cropTypeId}`,
  });

  return response.data.result.data;
};

const fetchCropTypes = async ({
  filters,
  metaArgs,
}: FetchCropTypesArgs): Promise<CropTypesResponse> => {
  const response: APIResponse<CropType[], true> = await getRequest({
    path: "/api/farmer/carbon/v1/admin/carbon_crop_types",
    params: {
      page: metaArgs?.page || 1,
      limit: metaArgs?.limit || DEFAULT_PAGE_SIZE,
      includeMeta: true,
      sort: {
        id: "desc",
      },
      filters,
    },
  });

  const { data, meta } = response.data.result;

  return {
    cropTypes: data,
    meta,
  };
};

/**
 * QUERY HOOKS
 */
export const useCropTypeQuery = (cropTypeId: number) =>
  useQuery(cropTypesKeys.details(cropTypeId), () => fetchCropType(cropTypeId));

export const useCropTypesQuery = (
  { filters, metaArgs }: UseCropTypesQueryArgs,
  options?: UseCropTypesQueryOptions,
) =>
  useQuery(
    cropTypesKeys.list({ filters, metaArgs }),
    () => fetchCropTypes({ filters, metaArgs }),
    {
      keepPreviousData: true,
      ...options,
    },
  );
