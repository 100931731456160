import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";
import { generateAllFieldBoundariesQueryKey } from "~features/field/api/get-all-field-boundaries";

import { generateNearbyFieldBoundariesQueryKey } from "./get-nearby-field-boundaries";

const resetFieldBoundaryStatus = async ({
  carbonFieldId,
}: {
  carbonFieldId: string;
}) => {
  await axios.patch(
    `/api/v1/field-boundaries/status/${carbonFieldId}/reset`,
    {},
    {
      apiSource: "node-carbon",
    },
  );
};

export const useResetFieldBoundaryStatus = () =>
  useMutation(resetFieldBoundaryStatus, {
    onError: error => {
      ToastNotification.error(error);
    },
    onSuccess: (data, params) => {
      const { carbonFieldId } = params;
      queryClient.invalidateQueries(generateNearbyFieldBoundariesQueryKey());
      queryClient.invalidateQueries(
        generateAllFieldBoundariesQueryKey(carbonFieldId),
      );

      ToastNotification.success("Field boundary status has been reset");
    },
  });
