import { z } from "zod";

import { OfferStatus } from "../types/offer-status";
import {
  KYCStatus,
  OfferDetailsSchema,
  OfferKycQuestionnaireDataSchema,
  OfferLastModifiedSchema,
  OfferSignerDetailsSchema,
} from "./sales-offer";

export const SalesOfferDetailsSchema = z.object({
  id: z.string(),
  userId: z.string(),
  fieldsTotalSizeHa: z.string(),
  companyVatNumber: z.string(),
  harvestYear: z.string(),
  accepted: z.boolean(),
  contacted: z.boolean(),
  status: z.nativeEnum(OfferStatus),
  createdContractId: z.number().optional(),
  kycStatus: z.nativeEnum(KYCStatus),
  kycValid: z.boolean(),
  kycQuestionnaireData: OfferKycQuestionnaireDataSchema,
  offerDetails: OfferDetailsSchema,
  signerDetails: OfferSignerDetailsSchema,
  metadata: z.object({
    lastModified: z
      .object({
        accepted: OfferLastModifiedSchema.optional(),
        contacted: OfferLastModifiedSchema.optional(),
        offerDetails: OfferLastModifiedSchema.optional(),
        signerDetails: OfferLastModifiedSchema.optional(),
        "offerDetails.useKycRegistrationNumberAsContractVat":
          OfferLastModifiedSchema.optional(),
      })
      .optional(),
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type SalesOfferDetails = z.infer<typeof SalesOfferDetailsSchema>;

export type SignerDetails = SalesOfferDetails["signerDetails"];
export type KycQuestionnaireDetails = SalesOfferDetails["kycQuestionnaireData"];
