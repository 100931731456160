import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { ComboBox, MultiSelect } from "@ag/design-system/molecules";
import { Modal, Tabs } from "@ag/design-system/organisms";
import { InputField, MultiSelectField, ToggleField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { usePagination } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import BackButton from "~components/BackButton";
import Table from "~components/table";
import { queryClient } from "~config";
import {
  Admin,
  useAdminQuery,
  useAdminRolesQuery,
  useCreateAdminUserAssignmentsMutation,
  useDeleteAdminUserAssignmentsMutation,
  useUpdateAdminMutation,
} from "~features/admin";
import { AuthorizedSidebar } from "~features/navigation";
import {
  Page,
  UserManagementPermissions,
  UserManagementResourceClass,
  useUserManagementPermissions,
  withPageAccess,
} from "~features/permission";
import {
  User,
  useAssignedUsersTable,
  useUsersQuery,
  useUsersSearch,
} from "~features/user";
import { generateUsersQueryKey } from "~features/user/api/get-users";
import DetailsLayout from "~layouts/details-layout";

const formSchema = z
  .object({
    email: z.string().email(),
    password: z.string(),
    passwordConfirmation: z.string(),
    discarded: z.boolean(),
    roles: z.array(z.string()).nonempty(),
  })
  .superRefine(({ password, passwordConfirmation }, ctx) => {
    if (password && !passwordConfirmation) {
      ctx.addIssue({
        message: I18n.t("js.shared.required"),
        code: z.ZodIssueCode.custom,
        path: ["passwordConfirmation"],
      });

      return;
    }

    if (password && passwordConfirmation && password !== passwordConfirmation) {
      ctx.addIssue({
        message: I18n.t("js.shared.password_not_matching"),
        code: z.ZodIssueCode.custom,
        path: ["passwordConfirmation"],
      });

      return;
    }
  });

type FormValues = z.infer<typeof formSchema>;

type FormProps = {
  admin: Admin;
};

const Form = ({ admin }: FormProps) => {
  const navigate = useNavigate();

  const { data: adminRoles } = useAdminRolesQuery();
  const { mutate: updateAdmin, isLoading: isUpdating } =
    useUpdateAdminMutation();

  const {
    control,
    register,
    formState,
    handleSubmit: handleFormSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: admin.email,
      discarded: Boolean(admin.discardedAt),
      roles: admin.adminRoles.map(role => role.name),
    },
  });

  const handleSubmit = useCallback(
    (values: FormValues) => {
      if (!admin?.id) return;

      updateAdmin(
        {
          id: admin.id,
          ...values,
        },
        {
          onSuccess: () => {
            ToastNotification.success(I18n.t("js.admin.admins.admin_updated"));
            navigate("/admins");
          },
        },
      );
    },
    [updateAdmin, admin.id, navigate],
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="grid auto-cols-fr grid-flow-col gap-4">
          <InputField
            label={I18n.t("js.admin.admins.email")}
            {...register("email")}
            error={formState.errors.email}
          />

          <MultiSelectField
            control={control}
            name="roles"
            label={I18n.t("js.user_edit.profile.roles.label")}
            placeholder={I18n.t("js.shared.select")}
            renderSelection={selection =>
              I18n.t("js.shared.multi_select.selection", {
                count: selection.length,
              })
            }
            variant="full-width"
          >
            {adminRoles?.map(role => (
              <MultiSelect.Item key={role.name} textValue={role.name}>
                {role.name}
              </MultiSelect.Item>
            ))}
          </MultiSelectField>
        </div>

        <div className="grid auto-cols-fr grid-flow-col items-start gap-4">
          <InputField
            label={I18n.t("js.admin.admins.password")}
            type="password"
            {...register("password")}
            error={formState.errors.password}
          />
          <InputField
            label={I18n.t("js.admin.admins.password_confirmation")}
            type="password"
            {...register("passwordConfirmation")}
            error={formState.errors.passwordConfirmation}
          />
        </div>

        <ToggleField
          label={I18n.t("js.admin.admins.discarded")}
          {...register("discarded")}
          error={formState.errors.discarded}
        />
      </div>

      <Button
        style={{ alignSelf: "end" }}
        isLoading={isUpdating}
        onClick={handleFormSubmit(handleSubmit)}
      >
        Save
      </Button>
    </div>
  );
};

type AssignedUserProps = {
  admin: Admin;
  userManagementPermissions: UserManagementPermissions;
};

const AssignedUsers = ({
  admin,
  userManagementPermissions,
}: AssignedUserProps) => {
  const [pagination, updatePagination] = usePagination();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const usersParams = useMemo(
    () => ({
      ...pagination,
      filters: {
        assignedAdminId: admin.id,
      },
    }),
    [pagination, admin],
  );

  const { data, isLoading } = useUsersQuery(usersParams);

  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const { mutateAsync: createAdminUserAssignments } =
    useCreateAdminUserAssignmentsMutation();

  const { mutateAsync: deleteAdminUserAssignments } =
    useDeleteAdminUserAssignmentsMutation();

  const {
    data: searchUsers,
    isLoading: isSearchLoading,
    onSearch,
  } = useUsersSearch();

  const onAssignUser = useCallback(async () => {
    if (!admin || !selectedUser) return;

    setSelectedUser(null);

    await createAdminUserAssignments({
      adminId: admin.id,
      userIds: [selectedUser.id],
    });

    setIsModalOpen(false);
    queryClient.invalidateQueries(generateUsersQueryKey(usersParams));
  }, [admin, createAdminUserAssignments, selectedUser, usersParams]);

  const onDeleteUserAssignment = useCallback(
    async (userId: string) => {
      if (!admin) return;

      await deleteAdminUserAssignments({
        adminId: admin.id,
        userIds: [userId],
      });

      queryClient.invalidateQueries(generateUsersQueryKey(usersParams));
    },
    [admin, deleteAdminUserAssignments, usersParams],
  );

  const table = useAssignedUsersTable({
    users: data?.items,
    userManagementPermissions,
    onDeleteUserAssignment,
  });

  return (
    <>
      <div className="flex flex-col gap-4">
        <Button onClick={() => setIsModalOpen(true)} className="self-start">
          Assign new user
        </Button>

        <Table
          instance={table}
          meta={data?.meta}
          pagination={pagination}
          footerClassName="relative"
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </div>

      <Modal.Root
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>Pick a user to assign</Modal.Title>
        </Modal.Header>

        <Modal.Content>
          <div className="flex gap-4">
            <ComboBox.Root
              value={selectedUser?.id ?? ""}
              emptyText="No users found"
              loadingText="Loading users..."
              ariaLabel="Assign an admin user"
              isAsync
              isLoading={isSearchLoading}
              className="w-full"
              optionsClassName="z-modal"
              onChange={value => {
                const user = searchUsers?.find(
                  item => item.id.toString() === value,
                );
                if (user) setSelectedUser(user);
              }}
              onInput={onSearch}
            >
              {searchUsers?.length && (
                <ComboBox.Section title="Search users">
                  {searchUsers.map(item => (
                    <ComboBox.Item
                      key={item.id.toString()}
                      textValue={`${item.email} (ID: ${item.id})`}
                    >
                      {item.name} (ID: {item.id})
                    </ComboBox.Item>
                  ))}
                </ComboBox.Section>
              )}
            </ComboBox.Root>

            <Button disabled={!selectedUser} onClick={onAssignUser}>
              Add
            </Button>
          </div>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

const AdminDetails = () => {
  const [activeTab, setActiveTab] = useState<"assigned-users" | undefined>(
    undefined,
  );

  const { id: adminId } = useParams<{ id: string }>();
  const { data: userManagementPermissions } = useUserManagementPermissions();

  const { data: admin } = useAdminQuery(adminId!);

  const hasUserAssignmentAccess = useMemo(
    () =>
      userManagementPermissions?.list?.includes(
        UserManagementResourceClass.AdminUserAssignment,
      ),
    [userManagementPermissions],
  );

  useEffect(() => {
    if (hasUserAssignmentAccess && !activeTab) {
      setActiveTab("assigned-users");
    }
  }, [hasUserAssignmentAccess, activeTab]);

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>
          Admin - {admin?.id} - {admin?.email}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div className="flex flex-col gap-8">
          {admin && <Form admin={admin} />}

          <Tabs.Root
            value={activeTab}
            onChange={tab => setActiveTab(tab as typeof activeTab)}
          >
            <Tabs.List>
              {userManagementPermissions && (
                <Tabs.Trigger value="assigned-users">
                  <Tabs.TriggerTitle>Assigned users</Tabs.TriggerTitle>
                </Tabs.Trigger>
              )}
            </Tabs.List>

            <Tabs.Content value="assigned-users">
              {hasUserAssignmentAccess &&
                admin &&
                userManagementPermissions && (
                  <AssignedUsers
                    admin={admin}
                    userManagementPermissions={userManagementPermissions}
                  />
                )}
            </Tabs.Content>
          </Tabs.Root>
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default withPageAccess(Page.Admins)(AdminDetails);
