import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { stringify } from "qs";
import { z } from "zod";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import { CommonPaginatedResponse, MetaArgs } from "@ag/utils/types";

import { QualityAssuranceStatus } from "~features/field";

import { Field, FieldSchema } from "../entities/field";
import {
  QualityControlCategory,
  QualityControlCheckKey,
  QualityControlFlag,
  QualityControlStatus,
} from "../entities/quality-control";

type DynamicFilterCropValue = {
  identifier?: string[];
  range?: { min?: number; max?: number };
  category?: string[];
};

type DynamicFilterValue = string | DynamicFilterCropValue;

type GetFieldsParams = MetaArgs & {
  harvestYear: number | undefined;
  filters?: Partial<{
    userId: number[] | string[];
    id: number[] | string[];
    carbonCountryId: number[] | string[];
    qaStatus: QualityAssuranceStatus | "none";
    qcStatus: QualityControlStatus | "none";
    flag: {
      category?: QualityControlCategory;
      key?: QualityControlCheckKey;
      value: QualityControlFlag[];
    };
    sizeHa: {
      min?: string;
      max?: string;
    };
    isFallow: boolean | null;
  }>;
  dynamicFilters?: {
    flag?: {
      type: string;
      value: QualityControlFlag[];
    }[];
    value?: {
      type: string;
      value: DynamicFilterValue;
    }[];
  };
};

export const generateFieldsQueryKey = (params?: GetFieldsParams | undefined) =>
  ["quality-control", "fields", params].filter(Boolean);

export const getFields = async (params?: GetFieldsParams) => {
  const response = await axios.get<CommonPaginatedResponse<Field>>(
    "/api/v1/carbon-fields/carbon-quality-controls",
    {
      params: {
        ...params,
        limit: params?.limit || DEFAULT_LIMIT,
      },
      apiSource: "node-carbon",
      schema: z.array(FieldSchema),
      paramsSerializer: params => {
        return stringify(params, { arrayFormat: "indices" });
      },
    },
  );

  return response.data.success!.data;
};

export const useFieldsQuery = (
  params?: GetFieldsParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(generateFieldsQueryKey(params), () => getFields(params), {
    keepPreviousData: true,
    ...options,
  });
