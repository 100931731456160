import capitalize from "lodash/capitalize";
import { useMemo, useState } from "react";
import { Collapse } from "react-collapse";
import { Controller, useForm } from "react-hook-form";

import { Icon, Spinner } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { grid, stack } from "@ag/design-system/utils";
import { InputField, SelectField } from "@ag/form-fields";
import I18n, { Locale, getLocales } from "@ag/i18n";
import { getYesNoOptions } from "@ag/utils/helpers";

import Card from "~components/card";
import { CropType } from "~features/crop-type";
import {
  METHODOLOGY_VERSIONS_OPTIONS,
  useOpenHarvestYearsOptions,
} from "~features/initial-resources";
import { SGridContainer } from "~pages/_legacy/carbon/pages/CropTypes/styled";

import { CropTypeEditAttribute, CropTypeEditOptionalAttribute } from "../types";

export type AugmentedCropType = Omit<CropType, "Category"> & {
  categories: string;
  [key: `name${string}`]: string;
};

const Form = ({
  initialValues,
}: {
  initialValues: Partial<AugmentedCropType>;
}) => {
  const [isTranslationsVisible, setIsTranslationsVisible] = useState(false);

  const {
    data: openHarvestYearsOptions,
    isLoading: isOpenHarvestYearsOptionsLoading,
  } = useOpenHarvestYearsOptions();

  const availableLocales: string[] = useMemo(
    () => getLocales().filter(locale => locale !== Locale.En),
    [],
  );

  const handleTranslationsToggleClicked = () => {
    setIsTranslationsVisible(!isTranslationsVisible);
  };

  const { control, register } = useForm<AugmentedCropType>({
    defaultValues: initialValues,
  });

  if (isOpenHarvestYearsOptionsLoading) {
    return (
      <div className="flex justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <Card.Root>
      <div className={stack()}>
        <div className={grid()}>
          <InputField
            key={Locale.En}
            {...register(`name${capitalize(Locale.En)}`)}
            label={`${I18n.t("js.carbon.crop_type_attribute.name")} (${
              Locale.En
            })`}
            isDisabled
            required
          />

          <InputField
            {...register(CropTypeEditAttribute.Identifier)}
            label={I18n.t("js.carbon.crop_type_attribute.identifier")}
            isDisabled
          />

          <InputField
            {...register(CropTypeEditAttribute.ID)}
            label={I18n.t("js.carbon.crop_type_attribute.id")}
            type="number"
            isDisabled
          />
        </div>

        <div className={grid()}>
          <InputField
            {...register(CropTypeEditAttribute.CoolfarmIdentifier)}
            label={I18n.t("js.carbon.crop_type_attribute.coolfarm_identifier")}
            type="number"
            isDisabled
            required
          />

          <InputField
            {...register(CropTypeEditAttribute.CoolfarmName)}
            label={I18n.t("js.carbon.crop_type_attribute.coolfarm_name")}
            isDisabled
            required
          />
        </div>

        <div className={grid()}>
          <Controller
            name={CropTypeEditAttribute.MethodologyVersion}
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.shared.methodology_version")}
                isDisabled
              >
                <Select.Option key="EMPTY_OPTION" value="">
                  {I18n.t("js.shared.all")}
                </Select.Option>

                {Object.values(METHODOLOGY_VERSIONS_OPTIONS).map(
                  ({ value, label }) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ),
                )}
              </SelectField>
            )}
          />

          <Controller
            name={CropTypeEditAttribute.HarvestYear}
            control={control}
            render={({ field, fieldState }) => (
              //@ts-ignore
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.carbon.crop_type_attribute.harvest_year")}
                isDisabled
              >
                <Select.Option key="EMPTY_OPTION" value="">
                  All
                </Select.Option>

                {openHarvestYearsOptions?.map(({ value, label }) => (
                  <Select.Option key={value} value={value as unknown as string}>
                    {label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        </div>

        <div className={grid()}>
          <InputField
            {...register(CropTypeEditAttribute.NetGross)}
            label={I18n.t("js.carbon.crop_type_attribute.net_gross")}
            type="number"
            isDisabled
            required
          />

          <InputField
            {...register(CropTypeEditOptionalAttribute.GrossYieldMin)}
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.min_gross_yield",
            )}
            type="number"
            isDisabled
          />

          <InputField
            {...register(CropTypeEditAttribute.GrossYieldMax)}
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.max_gross_yield",
            )}
            type="number"
            isDisabled
            required
          />
        </div>

        <div className={grid()}>
          <InputField
            {...register(CropTypeEditAttribute.KgNHaMin)}
            type="number"
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.min_fertilizer_consumption",
            )}
            isDisabled
            required
          />

          <InputField
            {...register(CropTypeEditAttribute.KgNHaMax)}
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.max_fertilizer_consumption",
            )}
            type="number"
            isDisabled
            required
          />
        </div>

        <div className={grid()}>
          <InputField
            {...register(CropTypeEditAttribute.DryMatterFraction)}
            label={I18n.t(
              "js.admin.carbon.crop_type_attribute.dry_matter_fraction",
            )}
            type="number"
            isDisabled
            required
          />

          <Controller
            name={CropTypeEditAttribute.ShowResidue}
            control={control}
            render={({ field, fieldState }) => (
              //@ts-ignore
              <SelectField
                {...field}
                error={fieldState.error}
                label={I18n.t("js.carbon.crop_type_attribute.show_residue")}
                isDisabled
              >
                {getYesNoOptions()?.map(({ value, label }) => (
                  <Select.Option
                    key={value as unknown as string}
                    value={value as unknown as string}
                  >
                    {label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        </div>

        <div className={grid()}>
          <InputField
            {...register(CropTypeEditAttribute.Slope)}
            label={I18n.t("js.admin.carbon.crop_type_attribute.slope")}
            type="number"
            isDisabled
            required
          />

          <InputField
            {...register(CropTypeEditAttribute.Intercept)}
            label={I18n.t("js.admin.carbon.crop_type_attribute.intercept")}
            type="number"
            isDisabled
            required
          />

          <InputField
            {...register(CropTypeEditAttribute.Categories)}
            label={I18n.t("js.admin.carbon.crop_type_attribute.category")}
            isDisabled
            required
          />
        </div>

        <div className="flex text-accent-200">
          <Button
            variant="text"
            onClick={handleTranslationsToggleClicked}
            href=""
          >
            {isTranslationsVisible ? "Hide translations" : "Show translations"}
            <Icon
              name={isTranslationsVisible ? "chevron-up" : "chevron-down"}
              className="mx-2"
            />
          </Button>
        </div>

        <Collapse isOpened={isTranslationsVisible}>
          <div className="mt-4 flex flex-col">
            <div className="wrap mb-4 flex items-start">
              <SGridContainer>
                {availableLocales.map(locale => (
                  <InputField
                    {...register(`name${capitalize(locale)}`)}
                    key={locale}
                    label={`${I18n.t(
                      "js.carbon.crop_type_attribute.name",
                    )} (${locale})`}
                    isDisabled
                  />
                ))}
              </SGridContainer>
            </div>
          </div>
        </Collapse>
      </div>
    </Card.Root>
  );
};

export default Form;
