import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

type ImportVerraPayload = {
  year: number;
  resourceId?: string;
};

const importVerraVcu = async (payload: ImportVerraPayload) => {
  await axios.post("/api/v1/verified-credit-units/verra", payload, {
    apiSource: "node-markets",
  });
};

export const useImportVerraVcuMutation = () =>
  useMutation(importVerraVcu, {
    onError: error => ToastNotification.error(error),
  });
