import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { z } from "zod";

import {
  FertiliserHarvestYear,
  FertiliserHarvestYearSchema,
} from "../entities/fertiliser";

type FertiliserParam = {
  id: string;
  harvestYear: string | number;
};

export const generateFertiliserByHarvestYearQueryKey = (
  params: FertiliserParam,
) => ["fertiliser-by-harvest-year", params].filter(Boolean);

const getFertiliserByHarvestYear = async (params: FertiliserParam) => {
  const { id, harvestYear } = params;
  let response;
  try {
    response = await axios.get<FertiliserHarvestYear | null>(
      `/v1/fertilisers/${id}/harvest_year/${harvestYear}`,
      {
        schema: z.array(FertiliserHarvestYearSchema),
        apiSource: "crops-fertilisers",
      },
    );
  } catch (error) {
    if (
      (error as AxiosError).response?.data.app_exception !==
      "ResourceNotFoundError"
    ) {
      throw error;
    }
  }

  return response?.data || null;
};

export const useFertiliserByHarvestYearQuery = (
  params: FertiliserParam,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateFertiliserByHarvestYearQueryKey(params),
    () => getFertiliserByHarvestYear(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );
