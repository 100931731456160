import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { Fertiliser } from "../entities/fertiliser";
import { generateFertilisersQueryKey } from "./get-fertilisers";

type FertiliserParams = {
  name: string;
  legacy_identifier?: string;
  legacy_enum_value?: string;
};

export const createFertiliser = async (params: FertiliserParams) => {
  const response = await axios.post<Fertiliser>(`/v1/fertilisers/`, params, {
    apiSource: "crops-fertilisers",
  });

  return response.data;
};

export const useCreateFertiliserMutation = () =>
  useMutation(createFertiliser, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateFertilisersQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
