import { z } from "zod";

import { ISOFertiliserSchema } from "./carbon-fertiliser";

export const FertiliserSchema = z.object({
  applicationMode: z.string(),
  applicationRate: z.number(),
  emissionsInhibitors: z.string(),
  carbonFertiliserId: z.number(),
  weightOrUnit: z.string(),
  carbonFertiliser: z.optional(ISOFertiliserSchema),
});

export type Fertiliser = z.infer<typeof FertiliserSchema>;
