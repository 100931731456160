import { PropertiesFormData } from "./components/fertiliser-properties-form";
import { FertiliserProperties } from "./entities/fertiliser";

export const mapPropertiesToForm = (
  properties: Partial<FertiliserProperties> | undefined,
) => ({
  cWet: properties?.["C% wet"] ?? null,
  cDry: properties?.["C% dry"] ?? null,
  nWet: properties?.["N% wet"] ?? null,
  nDry: properties?.["N% dry"] ?? null,
  cN: properties?.["C/N"] ?? null,
  moistureContent: properties?.["Moisture content %"] ?? null,
  nameSource: properties?.["Name source"] ?? null,
  refSource: properties?.["REF / Source"] ?? null,
});

export const mapDataToProperties = (data: PropertiesFormData) => ({
  "C% wet": data.cWet,
  "C% dry": data.cDry,
  "N% wet": data.nWet,
  "N% dry": data.nDry,
  "C/N": data.cN,
  "Moisture content %": data.moistureContent,
  "Name source": data.nameSource,
  "REF / Source": data.refSource,
});

export const processFormData = (
  formData: PropertiesFormData,
): PropertiesFormData => {
  const processFormData = { ...formData };
  const moistureContent = formData.moistureContent;

  if (moistureContent === "") {
    processFormData.moistureContent = null;
  } else {
    processFormData.moistureContent = isNaN(Number(moistureContent))
      ? String(moistureContent)
      : Number(moistureContent);
  }

  return processFormData;
};
