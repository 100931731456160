import { z } from "zod";

import { Locale } from "@ag/i18n";

import { AdminRole } from "~features/admin";

export const CurrentAdminSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  locale: z.nativeEnum(Locale),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  discardedAt: z.string().datetime().nullable(),
  adminRoles: z.array(
    z.object({
      id: z.string(),
      name: z.nativeEnum(AdminRole),
    }),
  ),
});

export type CurrentAdmin = z.infer<typeof CurrentAdminSchema>;
