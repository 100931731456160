import { useCallback, useMemo, useReducer } from "react";
import { useEffectOnceWhen } from "rooks";

import { getErrorMessagesString } from "~helpers";
import { Meta, MetaArgs } from "~types/api";

import {
  Action,
  ActionType,
  State,
  batchFetchReducer,
  initialState,
} from "./reducer";
import { BatchFetchOptions } from "./types";

/**
 * Hook for fetching big number of resources in batches
 * @param fetchFn Function for fetching resources
 * @param options Options for the hook
 * @returns State of the batch fetching
 */
export const useBatchFetch = <T extends unknown[]>(
  fetchFn: (meta: MetaArgs) => Promise<{
    data: T;
    meta: Meta;
  }>,
  options: BatchFetchOptions<T> = {},
) => {
  const itemsPerRequest = useMemo(
    () => options.itemsPerRequest ?? 100,
    [options.itemsPerRequest],
  );
  const onBatchSuccess = useMemo(
    () => options.onBatchSuccess,
    [options.onBatchSuccess],
  );
  const onBatchError = useMemo(
    () => options.onBatchError,
    [options.onBatchError],
  );
  const onSettled = useMemo(() => options.onSettled, [options.onSettled]);

  const [state, dispatch] = useReducer(
    (state: State<T>, action: Action<T>) => batchFetchReducer<T>(state, action),
    initialState,
  );

  useEffectOnceWhen(
    () => {
      if (onSettled) {
        onSettled(state.items);
      }
    },
    Boolean(state.isSettled && onSettled),
  );

  const handleFetch = useCallback(async () => {
    dispatch({ type: ActionType.Process });

    // Make first request to fetch the meta only
    const metaResponse = await fetchFn({
      page: 1,
      limit: 1,
    });

    dispatch({
      type: ActionType.SetMeta,
      meta: {
        requested: 0,
        total: metaResponse.meta.totalItems,
      },
    });

    const pagesToFetch = Math.ceil(
      metaResponse.meta.totalItems / itemsPerRequest,
    );

    // Fetch all batches one-by-one
    for (let page = 1; page <= pagesToFetch; page++) {
      // Calculate number of items requested
      const requested =
        page * itemsPerRequest < metaResponse.meta.totalItems
          ? page * itemsPerRequest
          : metaResponse.meta.totalItems;

      dispatch({
        type: ActionType.SetMeta,
        meta: {
          requested,
          total: metaResponse.meta.totalItems,
        },
      });

      try {
        const batchResponse = await fetchFn({
          page,
          limit: itemsPerRequest,
        });

        dispatch({
          type: ActionType.AddItems,
          items: batchResponse.data,
        });

        if (onBatchSuccess) {
          onBatchSuccess(batchResponse.data);
        }
      } catch (error) {
        if (onBatchError) {
          onBatchError(getErrorMessagesString(error));
        }
      }
    }

    dispatch({ type: ActionType.Complete });
  }, [fetchFn, itemsPerRequest, onBatchError, onBatchSuccess]);

  const memoizedBatchFetch = useMemo(
    () => ({
      data: {
        items: state.items,
        meta: state.meta,
      },
      isLoading: state.isLoading,
      isSettled: state.isSettled,
      fetch: handleFetch,
    }),
    [handleFetch, state.isLoading, state.isSettled, state.items, state.meta],
  );

  return memoizedBatchFetch;
};
