import { ChipVariant } from "@ag/design-system/atoms";

import { ResaleContractStatus } from "../entities/resale-agreement";

export function getContractStatusChipVariant(status: ResaleContractStatus) {
  const lookup: Record<ResaleContractStatus, ChipVariant> = {
    [ResaleContractStatus.Sent]: "info",
    [ResaleContractStatus.Signed]: "success",
    [ResaleContractStatus.Terminated]: "neutral",
    [ResaleContractStatus.Withdrawn]: "neutral",
    [ResaleContractStatus.Declined]: "warning",
  };

  return lookup[status];
}

export function getContractStatusChipText(status: ResaleContractStatus) {
  const lookup: Record<ResaleContractStatus, string> = {
    [ResaleContractStatus.Sent]: "Sent",
    [ResaleContractStatus.Signed]: "Signed",
    [ResaleContractStatus.Terminated]: "Terminated",
    [ResaleContractStatus.Withdrawn]: "Withdrawn",
    [ResaleContractStatus.Declined]: "Declined",
  };

  return lookup[status];
}

export const getContractStatusChip = (
  status: ResaleContractStatus,
): { variant: ChipVariant; label: string } => ({
  variant: getContractStatusChipVariant(status),
  label: getContractStatusChipText(status),
});
