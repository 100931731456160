import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import { APIResult, MetaArgs } from "@ag/utils/types";

import { Fertiliser, FertiliserSchema } from "../entities/fertiliser";

type GetFertilisersParams = MetaArgs & {
  filters?: Partial<{
    harvestYear: string | number;
    methodologyVersion: string | number;
    matchCoolfarmName: string;
    coolfarmIdentifier: string;
    matchFertiliserName: string;
  }>;
  sort?: Partial<{
    id: SortingDirection;
  }>;
};

export const generateFertilisersQueryKey = (
  params?: GetFertilisersParams | undefined,
) => ["fertilisers", params].filter(Boolean);

const getFertilisers = async (params: GetFertilisersParams | undefined) => {
  const response = await axios.get<APIResult<Fertiliser[], true>>(
    `/api/farmer/carbon/v1/admin/carbon_fertilisers`,
    {
      params: {
        ...params,
        includeMeta: true,
        responseType: "full",
        limit: params?.limit || DEFAULT_LIMIT,
      },
      schema: z.array(FertiliserSchema),
    },
  );

  return response.data.result;
};

export const useFertilisersQuery = (
  params?: GetFertilisersParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(generateFertilisersQueryKey(params), () => getFertilisers(params), {
    keepPreviousData: true,
    ...options,
  });
