import { FieldDefinitionStatus, FieldDetails } from "./types";

export function getFieldDefinitionStatus(
  fieldDetails: FieldDetails | undefined,
) {
  const fieldDefinition = fieldDetails?.carbonFieldDefinition;

  if (!fieldDefinition || fieldDefinition.completionRate === 0) {
    return FieldDefinitionStatus.NotStarted;
  }

  if (fieldDefinition.completionRate < 1) {
    return FieldDefinitionStatus.InProgress;
  }

  if (fieldDefinition.completionRate === 1 && !fieldDefinition.readonly) {
    return FieldDefinitionStatus.Completed;
  }

  if (fieldDefinition.readonly) {
    return FieldDefinitionStatus.Submitted;
  }

  // Any other unknown case - show not started
  return FieldDefinitionStatus.NotStarted;
}
