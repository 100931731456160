import { format } from "date-fns";

import I18n from "@ag/i18n";
import { withEmptyPlaceholder } from "@ag/utils/helpers";

import {
  getCoverCropBasedOnAnswers,
  getFertiliserTypeBasedOnAnswers,
  getResidueManagementBasedOnAnswers,
  getTillagePracticeBasedOnAnswers,
} from "~pages/_legacy/carbon/helpers";
import {
  FieldDefinitionDetails,
  FieldOverview,
  UserFieldsOverviewsResponse,
} from "~queries/carbon/fields";

export type RowValue = string | number | undefined;

/**
 * Get baseline
 */
const getBaselineAndMethodology = (
  field: FieldOverview<FieldDefinitionDetails>,
) => {
  const baselineVersion = field.carbonFieldDefinition.baselineVersion || "-";
  const [carbonFieldActuals] = field.carbonFieldActuals ?? [];

  if (carbonFieldActuals) {
    return `${baselineVersion}.${carbonFieldActuals.methodologyVersion}`;
  }

  return baselineVersion;
};

/**
 * Map fields data from API to CSV structure
 */
export const mapFieldsToCSVData = (
  fields: UserFieldsOverviewsResponse[] | undefined,
  currentHarvestYear: number | undefined,
) => {
  if (!fields || !currentHarvestYear) return null;

  // All headers
  const headers = [
    I18n.t("js.shared.user_id"),
    I18n.t("js.carbon.carbon_user_id"),
    I18n.t("js.shared.company_name"),
    "Farm name",
    I18n.t("js.carbon.field_id"),
    I18n.t("js.carbon.field_name_or_identifier"),
    I18n.t("js.carbon.methodology"),
    I18n.t("js.carbon.field_definition_submitted_at"),
    I18n.t("js.admin.validation_status"),
    I18n.t("js.carbon.user_details.fields_list.csv.total_baseline"),
    I18n.t("js.carbon.field_size_in_ha"),
    I18n.t("js.carbon.latitude"),
    I18n.t("js.carbon.longitude"),
    I18n.t("js.carbon.som"),
    I18n.t("js.carbon.texture"),
    I18n.t("js.carbon.ph"),
    I18n.t("js.carbon.moisture"),
    I18n.t("js.carbon.drainage"),
    I18n.t("js.carbon.historic_conversion"),
    I18n.t("js.carbon.cultivated_field"),
    I18n.t("js.carbon.rotated_crops"),
    I18n.t("js.carbon.tillage_practice"),
    I18n.t("js.carbon.residue_management_attribute"),
    I18n.t("js.carbon.cover_crops"),
    I18n.t("js.carbon.fertiliser_type"),
    I18n.t("js.carbon.nitrification_inhibitors"),
    I18n.t("js.carbon.crop_protection"),
    I18n.t("js.carbon.show_chemical_specification"),
    I18n.t("js.carbon.other_information"),
  ];

  // Transform fields data
  const fieldsData: RowValue[][] = [];

  fields.forEach(field => {
    const { questionnaire } = field.carbonFieldDefinition;

    fieldsData.push([
      // Basic information
      field.user.id,
      field.carbonUserId,
      field.user.company.name,
      field.carbonFarm.name,
      field.id,
      field.name,
      getBaselineAndMethodology(field),

      // Field definition
      withEmptyPlaceholder(
        field.carbonFieldDefinition.submittedAt &&
          format(
            new Date(field.carbonFieldDefinition.submittedAt),
            "dd/MM/yyyy",
          ),
        field.carbonFieldDefinition.submittedAt,
      ),

      field.validationStatus,
      withEmptyPlaceholder(field.carbonFieldDefinition.baselineTotal),
      field.sizeHa,
      withEmptyPlaceholder(field.position?.lat),
      withEmptyPlaceholder(field.position?.lng),
      withEmptyPlaceholder(
        field.carbonFieldDefinition.soilSomSpecific ||
          field.carbonFieldDefinition.soilSomRange,
      ),

      //Field definition footprint
      withEmptyPlaceholder(field.carbonFieldDefinition.soilTexture),
      withEmptyPlaceholder(field.carbonFieldDefinition.soilPhRange),
      withEmptyPlaceholder(field.carbonFieldDefinition.soilMoisture),
      withEmptyPlaceholder(field.carbonFieldDefinition.soilDrainage),

      //History of deforestation
      withEmptyPlaceholder(
        field.carbonFieldDefinition.soilMgmtHistoricConversion
          ? I18n.t("js.shared.yes")
          : I18n.t("js.shared.no"),
        field.carbonFieldDefinition.soilMgmtHistoricConversion !== null,
      ),

      // Questionnaire
      questionnaire.cultivatedFieldInTheLastFiveYears
        ? I18n.t("js.shared.yes")
        : I18n.t("js.shared.no"),

      questionnaire.rotatedCropsInTheLastFiveYears
        ? I18n.t("js.shared.yes")
        : I18n.t("js.shared.no"),

      getTillagePracticeBasedOnAnswers(questionnaire),

      getResidueManagementBasedOnAnswers(questionnaire),

      getCoverCropBasedOnAnswers(questionnaire),

      getFertiliserTypeBasedOnAnswers(questionnaire),

      withEmptyPlaceholder(
        questionnaire.nitrificationInhibitorsConsistentlyTheLastFiveYears
          ? I18n.t("js.shared.yes")
          : I18n.t("js.shared.no"),
        questionnaire.nitrificationInhibitorsConsistentlyTheLastFiveYears !==
          null,
      ),

      questionnaire.cropProtectionConsistentlyTheLastFiveYears
        ? I18n.t("js.shared.yes")
        : I18n.t("js.shared.no"),

      withEmptyPlaceholder(
        field.carbonFieldDefinition.fertilisersShowChemicalSpecification
          ? I18n.t("js.shared.yes")
          : I18n.t("js.shared.no"),
        field.carbonFieldDefinition.fertilisersShowChemicalSpecification !==
          null,
      ),

      //Other information
      withEmptyPlaceholder(field.carbonFieldDefinition.otherInformationComment),
    ]);
  });

  return [[...headers], ...fieldsData];
};
