import * as z from "zod";

import { MarketsAbility } from "../types/abilities";
import { MarketsResourceClass } from "../types/resources";

export const MarketsPermissionsSchema = z.record(
  z.nativeEnum(MarketsAbility),
  z.array(z.nativeEnum(MarketsResourceClass)),
);

export type MarketsPermissions = z.infer<typeof MarketsPermissionsSchema>;
