import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";

import { Icon } from "@ag/design-system/assets";

import * as styles from "./side-sheet.css";

/* -------------------------------------------------------------------------------------------------
 * SideSheet
 * -----------------------------------------------------------------------------------------------*/
type SideSheetProps = React.PropsWithChildrenRequired<{
  side?: "left" | "right";
  isOpen?: boolean;
  onClose?: () => void;
}>;

const onCloseAtom = atom<(() => void) | undefined>(undefined);

function SideSheet({
  children,
  side = "left",
  isOpen = false,
  onClose = () => {},
}: SideSheetProps) {
  const [, setOnClose] = useAtom(onCloseAtom);
  const [isMounted, setIsMounted] = useState(isOpen);

  useEffect(() => {
    if (isOpen) setIsMounted(true);
  }, [isOpen]);

  useEffect(() => {
    setOnClose(() => onClose);
  }, [onClose, setOnClose]);

  if (!isMounted) return null;

  return (
    <aside
      className={styles.root({ side, isOpen })}
      onAnimationEnd={() => setIsMounted(isOpen)}
    >
      <div className={styles.wrapper({ side, isOpen })}>{children}</div>
    </aside>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Header
 * -----------------------------------------------------------------------------------------------*/
function SideSheetHeader({ children }: React.PropsWithChildrenRequired) {
  const [onClose] = useAtom(onCloseAtom);

  return (
    <div className={styles.header}>
      {children}

      <button className={styles.close} onClick={onClose}>
        <Icon name="close" />
      </button>
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/
function SideSheetContent({ children }: React.PropsWithChildrenRequired) {
  return <div className={styles.content}>{children}</div>;
}

/* -------------------------------------------------------------------------------------------------
 * Footer
 * -----------------------------------------------------------------------------------------------*/
function SideSheetFooter({ children }: React.PropsWithChildrenRequired) {
  return <div className={styles.footer}>{children}</div>;
}

const Root = SideSheet;
const Header = SideSheetHeader;
const Content = SideSheetContent;
const Footer = SideSheetFooter;

export { Root, Header, Content, Footer };
