import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateAdminsQueryKey } from "./get-admins";

type AdminUserAssignmentData = {
  adminId: string;
  userIds: string[];
};

const createAdminUserAssignments = async (payload: AdminUserAssignmentData) => {
  await axios.post("/api/v1/admins/user-assignments", payload, {
    apiSource: "node-user-mgt",
  });
};

export const useCreateAdminUserAssignmentsMutation = () =>
  useMutation(createAdminUserAssignments, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateAdminsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
