import { cva } from "class-variance-authority";

export const buttonRootVariants = cva(
  [
    "inline-grid grid-flow-col place-items-center justify-center disabled:cursor-not-allowed",
  ],
  {
    variants: {
      theme: {
        light: "",
        dark: "",
      },
      isDanger: {
        true: "",
        false: "",
      },
      size: {
        medium:
          "grid-cols-[24px_auto_24px] grid-rows-[8px_auto_8px] text-p1 font-medium",
        small:
          "grid-cols-[24px_auto_24px] grid-rows-[6px_auto_6px] text-p2 font-medium",
        "x-small":
          "grid-cols-[8px_auto_8px] grid-rows-[5px_auto_5px] text-p3 font-medium",
      },
      hasIcon: {
        true: "",
        false: "[grid-template-areas:'._._.''._content_.''._._.']",
      },
      isIconOnly: {
        true: "inline-flex",
        false: "",
      },
      iconPosition: {
        before: "",
        after: "",
      },
      variant: {
        primary: "rounded-lg border border-solid",
        secondary: "rounded-lg border border-solid",
        text: "grid-cols-[0_auto_0] grid-rows-[0_auto_0] px-0 underline",
      },
      isLoading: {
        true: "text-transparent hover:cursor-wait",
      },
    },
    compoundVariants: [
      {
        variant: "primary",
        isDanger: false,
        theme: "light",
        className: [
          "border-accent-200 bg-accent-200 text-grey-white",
          "active:border-sky-300 active:bg-sky-300",
          "hover:border-accent-100 hover:bg-accent-100 hover:text-grey-white hover:no-underline",
          "disabled:border-grey-300 disabled:bg-grey-300 disabled:text-grey-600",
        ],
      },
      {
        variant: "primary",
        isDanger: true,
        className: [
          "border-messaging-error-900 bg-messaging-error-900 text-grey-white",
          "active:border-messaging-error-500 active:bg-messaging-error-500",
          "hover:border-messaging-error-700 hover:bg-messaging-error-700",
          "disabled:border-grey-300 disabled:bg-grey-300 disabled:text-grey-600",
        ],
      },
      {
        variant: "primary",
        isDanger: false,
        theme: "dark",
        className: [
          "border-grey-white bg-grey-white text-satellite-200",
          "hover:border-grey-300 hover:bg-grey-300 hover:text-satellite-200 hover:no-underline",
          "active:border-grey-400 active:bg-grey-400",
          "disabled:border-grey-400 disabled:bg-grey-400",
        ],
      },

      {
        variant: "secondary",
        isDanger: false,
        theme: "light",
        className: [
          "border-accent-200 bg-transparent text-accent-200",
          "active:bg-sky-300",
          "hover:bg-sky-200 hover:no-underline",
          "disabled:border-grey-300 disabled:bg-transparent disabled:text-grey-600",
        ],
      },
      {
        variant: "secondary",
        isDanger: true,
        className: [
          "border-messaging-error-900 bg-transparent text-messaging-error-900",
          "active:bg-messaging-error-300",
          "hover:bg-messaging-error-100",
          "disabled:border-grey-300 disabled:bg-transparent disabled:text-grey-600",
        ],
      },
      {
        variant: "secondary",
        isDanger: false,
        theme: "dark",
        className: [
          "bg-transparent text-grey-white",
          "active:bg-grey-white active:bg-opacity-35 active:text-grey-white",
          "hover:border-grey-white hover:bg-grey-white hover:bg-opacity-20 hover:text-grey-white",
          "disabled:border-grey-300 disabled:bg-transparent disabled:text-grey-600",
        ],
      },

      {
        variant: "text",
        isDanger: false,
        theme: "light",
        className: [
          "bg-transparent text-accent-200",
          "active:transparent active:text-sky-300",
          "hover:bg-transparent hover:text-accent-100",
          "disabled:bg-transparent disabled:text-grey-600",
        ],
      },
      {
        variant: "text",
        isDanger: true,
        className: [
          "bg-transparent text-messaging-error-900",
          "active:transparent active:text-messaging-error-500",
          "hover:bg-transparent hover:text-messaging-error-700",
          "disabled:bg-transparent disabled:text-grey-600",
        ],
      },
      {
        variant: "text",
        isDanger: false,
        theme: "dark",
        className: [
          "bg-transparent text-grey-white",
          "active:transparent active:text-grey-400",
          "hover:bg-transparent hover:text-grey-300",
          "disabled:bg-transparent disabled:text-grey-600",
        ],
      },

      {
        size: "medium",
        hasIcon: true,
        className: "grid-cols-[24px_auto_8px_auto_24px]",
      },
      {
        size: "small",
        hasIcon: true,
        className: "grid-cols-[24px_auto_8px_auto_24px]",
      },
      {
        size: "x-small",
        hasIcon: true,
        className: "grid-cols-[8px_auto_8px_auto_8px]",
      },
      {
        variant: "text",
        size: "medium",
        hasIcon: true,
        className: "grid-cols-[0_auto_8px_auto_0]",
      },
      {
        variant: "text",
        size: "small",
        hasIcon: true,
        className: "grid-cols-[0_auto_8px_auto_0]",
      },
      {
        variant: "text",
        size: "x-small",
        hasIcon: true,
        className: "grid-cols-[0_auto_8px_auto_0]",
      },
      {
        iconPosition: "before",
        hasIcon: true,
        className:
          "[grid-template-areas:'._._._._.''._icon_._content_.''._._._._.']",
      },
      {
        iconPosition: "after",
        hasIcon: true,
        className:
          "[grid-template-areas:'._._._._.''._content_._icon_.''._._._._.']",
      },
      {
        variant: "primary",
        isLoading: true,
        className: "disabled:text-transparent",
      },
      {
        size: "medium",
        isIconOnly: true,
        className: "h-10 w-10",
      },
      {
        size: "small",
        isIconOnly: true,
        className: "h-8 w-8",
      },
      {
        size: "x-small",
        isIconOnly: true,
        className: "h-7 w-7",
      },
      {
        variant: "text",
        isIconOnly: true,
        className: "h-auto w-auto",
      },
    ],
    defaultVariants: {
      size: "medium",
      theme: "light",
      variant: "primary",
      iconPosition: "before",
      hasIcon: false,
      isDanger: false,
      isLoading: false,
    },
  },
);

export const spinnerVariants = cva("col-span-full row-span-full", {
  variants: {
    theme: {
      light: "text-grey-white",
      dark: "text-accent-200",
    },
    size: {
      medium: "h-6",
      small: "h-4",
      "x-small": "h-4",
    },
  },
});
