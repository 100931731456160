import isNil from "lodash/isNil";
import { Field, useField } from "react-final-form";
import { FieldArrayRenderProps } from "react-final-form-arrays";

import { Button } from "@ag/design-system/atoms";
import { ButtonSelect, ComboBox } from "@ag/design-system/molecules";
import { ButtonSelectField, ComboBoxField, InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { parseInputNumber } from "@ag/utils/helpers";
import { FieldStep } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";
import { FertiliserEmissionsInhibitors, FertiliserMode } from "~types";

import { EMPTY_FERTILISER } from "../constants";
import {
  getEmissionsInhibitorsOptions,
  getFertiliserTypeOptions,
} from "../helpers";
import {
  FertiliserData,
  FertiliserDataAttribute,
  FertiliserViewAttribute,
} from "../types";
import { SFertiliser } from "./styled";

type Props = {
  fields: any;
  props: FieldArrayRenderProps<FertiliserData, HTMLElement>;
  step: FieldStep.Actuals;
  fertilisers: ISOFertiliser[] | undefined;
  index: number;
  name: string;
  isReadonly: boolean;
};

const FertiliserRound = ({
  name,
  index,
  fields,
  fertilisers,
  step,
  props,
  isReadonly,
}: Props) => {
  const isFertilisersUsedField = useField<boolean>(
    FertiliserViewAttribute.IsFertilisersUsed,
  );

  const fertiliser: FertiliserData = fields.value[index];

  const isEmissionsInhibitorsVisible =
    fertiliser.mode === FertiliserMode.Organic ||
    (fertiliser.mode === FertiliserMode.Synthetic &&
      fertiliser.nitrogenApplicationRate);

  const handleRemoveFertiliser = (
    fieldArrayProps: FieldArrayRenderProps<FertiliserData, HTMLElement>,
    index: number,
  ) => {
    const numberOfFertilisers = fieldArrayProps.fields.value.length;

    fieldArrayProps.fields.remove(index);

    if (numberOfFertilisers === 1) {
      isFertilisersUsedField.input.onChange(false);
    }
  };

  const handleNitrogenApplicationRateChanged = (value: string) => {
    if (!value && fertiliser.mode === FertiliserMode.Synthetic) {
      fields.update(index, {
        ...fertiliser,
        nitrogenApplicationRate: parseInputNumber(value),
        nitrificationApplicationRate: null,
        emissionsInhibitors: null,
      });
    }
  };

  const handlePhosphorusApplicationRateChanged = (value: string) => {
    if (!value && fertiliser.mode === FertiliserMode.Synthetic) {
      fields.update(index, {
        ...fertiliser,
        phosphorusApplicationRate: parseInputNumber(value),
        phosphorusType: null,
      });
    }
  };

  const handlePotassiumApplicationRateChanged = (value: string) => {
    if (!value && fertiliser.mode === FertiliserMode.Synthetic) {
      fields.update(index, {
        ...fertiliser,
        potassiumApplicationRate: parseInputNumber(value),
        potassiumType: null,
      });
    }
  };

  const fertiliserTooltipByStep = {
    [FieldStep.Actuals]: I18n.t(
      "js.carbon.field.fertilisers.actuals.nitrogen_fertiliser_tooltip",
    ),
  };
  const fertiliserTooltip = fertiliserTooltipByStep[step];

  return (
    <SFertiliser key={name} className="mt-4 grid grid-cols-1 gap-2">
      <div className="flex items-center justify-between">
        <h3 className="text-h3">
          {I18n.t("js.carbon.fertiliser_title")} {index + 1}
        </h3>

        {!isReadonly && (
          <Button
            variant="secondary"
            isDanger
            size="x-small"
            onClick={() => handleRemoveFertiliser(props, index)}
          >
            {I18n.t("js.shared.delete")}
          </Button>
        )}
      </div>

      <Field
        name={`${name}.${FertiliserDataAttribute.Mode}`}
        render={({ input, meta }) => (
          <ButtonSelectField
            {...input}
            label={I18n.t("js.carbon.field.fertilisers.type_of_nitrogen")}
            error={
              meta.touched && meta.error ? { message: meta.error } : undefined
            }
            isDisabled={isReadonly}
            onChange={value => {
              input.onChange(value);

              fields.update(index, {
                ...EMPTY_FERTILISER,
                mode: value,
              });
            }}
          >
            {getFertiliserTypeOptions().map(option => (
              <ButtonSelect.Option key={option.value} value={option.value}>
                {option.label}
              </ButtonSelect.Option>
            ))}
          </ButtonSelectField>
        )}
      />

      {fertiliser.mode === FertiliserMode.Organic && (
        <div className="grid grid-cols-2 gap-4">
          <Field
            name={`${name}.${FertiliserDataAttribute.CarbonFertiliserId}`}
            render={({ input, meta }) => (
              <ComboBoxField
                {...input}
                data-testid="fertilisersPartial-fertiliserId"
                label={I18n.t(
                  "js.carbon.field.fertilisers.nitrogen_fertiliser",
                )}
                error={
                  meta.touched && meta.error
                    ? { message: meta.error }
                    : undefined
                }
                tooltip={fertiliserTooltip}
                emptyText=""
                loadingText=""
              >
                {fertilisers?.map(option => (
                  <ComboBox.Item key={option.id}>{option.name}</ComboBox.Item>
                ))}
              </ComboBoxField>
            )}
          />

          <Field
            name={`${name}.${FertiliserDataAttribute.ApplicationRate}`}
            render={({ input, meta }) => (
              <InputField
                {...input}
                type="number"
                label={I18n.t(
                  "js.carbon.field.fertilisers.fertiliser_application_rate",
                )}
                disabled={isReadonly}
                error={
                  meta.touched && meta.error
                    ? { message: meta.error }
                    : undefined
                }
                rightAddon={<span>{I18n.t("js.carbon.kg_ha")}</span>}
              />
            )}
            parse={parseInputNumber}
          />
        </div>
      )}

      {fertiliser.mode === FertiliserMode.Synthetic && (
        <div className="grid grid-cols-2 gap-4">
          <Field
            name={`${name}.${FertiliserDataAttribute.NitrogenApplicationRate}`}
            render={({ input, meta }) => (
              <InputField
                {...input}
                type="number"
                label={I18n.t(
                  "js.carbon.field.fertilisers.nitrogen_application_rate",
                )}
                disabled={isReadonly}
                error={
                  meta.touched && meta.error
                    ? { message: meta.error }
                    : undefined
                }
                rightAddon={<span>{I18n.t("js.carbon.kg_ha")}</span>}
                onChange={event => {
                  input.onChange(event.target.value);
                  handleNitrogenApplicationRateChanged(event.target.value);
                }}
              />
            )}
            parse={parseInputNumber}
          />
        </div>
      )}

      {isEmissionsInhibitorsVisible && (
        <div className="flex gap-4">
          <Field
            name={`${name}.${FertiliserDataAttribute.EmissionsInhibitors}`}
            render={({ input, meta }) => (
              <ButtonSelectField
                {...input}
                data-testid={`cropProtection-typeButtonGroup-${index}`}
                label={I18n.t(
                  "js.carbon.field.fertilisers.apply_nitrification",
                )}
                error={
                  meta.touched && meta.error
                    ? { message: meta.error }
                    : undefined
                }
                isDisabled={isReadonly}
                onChange={value => {
                  input.onChange(value);

                  if (value === FertiliserEmissionsInhibitors.None) {
                    fields.update(index, {
                      ...fertiliser,
                      emissionsInhibitors: value,
                      nitrificationApplicationRate: null,
                    });
                  }
                }}
              >
                {getEmissionsInhibitorsOptions().map(option => (
                  <ButtonSelect.Option key={option.value} value={option.value}>
                    {option.label}
                  </ButtonSelect.Option>
                ))}
              </ButtonSelectField>
            )}
          />

          {fertiliser.emissionsInhibitors ===
            FertiliserEmissionsInhibitors.Nitrification && (
            <Field
              name={`${name}.${FertiliserDataAttribute.NitrificationApplicationRate}`}
              render={({ input, meta }) => (
                <InputField
                  {...input}
                  type="number"
                  label={I18n.t(
                    "js.carbon.field.fertilisers.how_much_fertiliser_on",
                  )}
                  disabled={isReadonly}
                  error={
                    meta.touched && meta.error
                      ? { message: meta.error }
                      : undefined
                  }
                  rightAddon={<span>{I18n.t("js.carbon.kg_ha")}</span>}
                />
              )}
              parse={parseInputNumber}
            />
          )}
        </div>
      )}

      {fertiliser.mode === FertiliserMode.Synthetic && (
        <div className="grid gap-4">
          <div className="grid grid-cols-2 items-end gap-4">
            <Field
              name={`${name}.${FertiliserDataAttribute.PhosphorusApplicationRate}`}
              render={({ input, meta }) => (
                <InputField
                  {...input}
                  type="number"
                  label={I18n.t(
                    "js.carbon.field.fertilisers.p_application_rate",
                  )}
                  tooltip={I18n.t("js.carbon.field.fertilisers.tooltip.p")}
                  error={
                    meta.touched && meta.error
                      ? { message: meta.error }
                      : undefined
                  }
                  rightAddon={<span>{I18n.t("js.carbon.kg_ha")}</span>}
                  disabled={isReadonly}
                  onChange={event => {
                    input.onChange(event.target.value);
                    handlePhosphorusApplicationRateChanged(event.target.value);
                  }}
                />
              )}
              parse={parseInputNumber}
            />

            {!isNil(fertiliser.phosphorusApplicationRate) && (
              <Field
                name={`${name}.${FertiliserDataAttribute.PhosphorusType}`}
                render={({ input, meta }) => (
                  <ButtonSelectField
                    {...input}
                    label=""
                    error={
                      meta.touched && meta.error
                        ? { message: meta.error }
                        : undefined
                    }
                    isDisabled={
                      !fertiliser.phosphorusApplicationRate || isReadonly
                    }
                  >
                    <ButtonSelect.Option value="P">P</ButtonSelect.Option>
                    <ButtonSelect.Option value="P2O5">P2O5</ButtonSelect.Option>
                  </ButtonSelectField>
                )}
              />
            )}
          </div>

          <div className="grid grid-cols-2 items-end gap-4">
            <Field
              name={`${name}.${FertiliserDataAttribute.PotassiumApplicationRate}`}
              render={({ input, meta }) => (
                <InputField
                  {...input}
                  type="number"
                  label={I18n.t(
                    "js.carbon.field.fertilisers.k_application_rate",
                  )}
                  tooltip={I18n.t("js.carbon.field.fertilisers.tooltip.k")}
                  error={
                    meta.touched && meta.error
                      ? { message: meta.error }
                      : undefined
                  }
                  rightAddon={<span>{I18n.t("js.carbon.kg_ha")}</span>}
                  disabled={isReadonly}
                  onChange={event => {
                    input.onChange(event.target.value);
                    handlePotassiumApplicationRateChanged(event.target.value);
                  }}
                />
              )}
              parse={parseInputNumber}
            />

            {!isNil(fertiliser.potassiumApplicationRate) && (
              <Field
                name={`${name}.${FertiliserDataAttribute.PotassiumType}`}
                render={({ input, meta }) => (
                  <ButtonSelectField
                    {...input}
                    label=""
                    error={
                      meta.touched && meta.error
                        ? { message: meta.error }
                        : undefined
                    }
                    isDisabled={
                      !fertiliser.potassiumApplicationRate || isReadonly
                    }
                  >
                    <ButtonSelect.Option value="K">K</ButtonSelect.Option>
                    <ButtonSelect.Option value="K2O">K20</ButtonSelect.Option>
                  </ButtonSelectField>
                )}
              />
            )}
          </div>
        </div>
      )}
    </SFertiliser>
  );
};

export default FertiliserRound;
