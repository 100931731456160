import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { queryClient } from "~config/react-query";

import { TerminateReasons } from "../entities/buyout-agreement";
import { generateBuyoutAgreementQueryKey } from "./get-buyout-agreement-detail";
import { generateBuyoutAgreementsQueryKey } from "./get-buyout-agreements";

const terminateBuyoutAgreement = async ({
  id,
  reasonComment,
}: {
  id: string;
  reasonComment: string;
}) => {
  await axios.post(
    `/api/v1/carbon-sales-agreements/${id}/terminate`,
    {
      reasonComment,
      reason: TerminateReasons.OTHER,
    },
    { apiSource: "node-carbon" },
  );
};

export const useTerminateBuyoutAgreementMutation = () =>
  useMutation(terminateBuyoutAgreement, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(generateBuyoutAgreementsQueryKey());
      queryClient.invalidateQueries(
        generateBuyoutAgreementQueryKey(variables.id),
      );
    },
  });
