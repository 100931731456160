import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import {
  CropCountryDetails,
  CropCountryDetailsSchema,
} from "../entities/crop-country-details";

export const generateCropCountryDetailsByHarvestYearQueryKey = (
  id: string,
  harvestYear: string,
  country: string,
) =>
  ["crop-country-details-harvest-year", id, harvestYear, country].filter(
    Boolean,
  );

export const getCropCountryDetailsByHarvestYear = async (
  id: string,
  harvestYear: string,
  country: string,
): Promise<CropCountryDetails | null> => {
  let response;
  try {
    response = await axios.get<CropCountryDetails>(
      `/v1/crops/${id}/harvest_year/${harvestYear}/country/${country}`,
      {
        schema: CropCountryDetailsSchema,
        apiSource: "crops-fertilisers",
      },
    );
  } catch (error) {
    // If resource doesn't exist, don't throw an error
    if (error.response.data.app_exception !== "ResourceNotFoundError") {
      throw error;
    }
  }

  return response?.data || null;
};

export const useCropCountryDetailsByHarvestYearQuery = (
  id: string,
  harvestYear: string,
  country: string,
) =>
  useQuery(
    generateCropCountryDetailsByHarvestYearQueryKey(id, harvestYear, country),
    () => getCropCountryDetailsByHarvestYear(id, harvestYear, country),
  );
