import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onExitClick: () => void;
};

export const ProgressLostModal = ({ isOpen, onClose, onExitClick }: Props) => {
  return (
    <Modal.Root
      isOpen={isOpen}
      onRequestClose={onClose}
      className="bg-gray-700"
      size="small"
      isCentered
    >
      <Modal.Content>
        <div className="flex min-h-[340px] flex-col items-center justify-center gap-6 p-4">
          <div>
            <Icon name="warning" fontSize={56} className="text-red-700" />
          </div>
          <div className="text-center">
            <h3 className="text-h3 font-bold">Progress will be lost</h3>
            <p className="px-12 pt-4 text-p1">
              Exiting the current flow without submitting will result in the
              loss of any entered data. Are you sure you wish to proceed?
            </p>
          </div>
          <div className="flex flex-col items-center gap-6 font-bold">
            <Button
              isDanger
              className="font-inherit"
              variant="primary"
              onClick={onExitClick}
            >
              Exit
            </Button>
            <Button className="font-inherit" variant="text" onClick={onClose}>
              Continue submission
            </Button>
          </div>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
};
