import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import {
  CertificateGroupStandard,
  CertificateGroupStatus,
  CertificateGroupType,
} from "../types/certificate-group";

type ExportCertificateGroupsParams = {
  filters?: Partial<{
    certificateId: string;
    type: CertificateGroupType[];
    status: CertificateGroupStatus[];
    standard: CertificateGroupStandard[];
    country: string[];
    vintageYear: string[];
  }>;
};

export const generateCertificateGroupsQueryKey = (
  params?: ExportCertificateGroupsParams | undefined,
) => ["export-certificate-groups", params].filter(Boolean);

const exportCertificateGroups = async (
  params?: ExportCertificateGroupsParams,
) => {
  const response = await axios.get<string>(
    "/api/v1/certificates/groups/export",
    {
      params,
      apiSource: "node-markets",
    },
  );

  return response;
};

export const useExportCertificateGroupsQuery = (
  params?: ExportCertificateGroupsParams,
) =>
  useQuery(
    generateCertificateGroupsQueryKey(params),
    () => exportCertificateGroups(params),
    {
      // Disabled by default, as it has to be triggered manually
      enabled: false,
    },
  );
