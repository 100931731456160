import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import {
  FertiliserHarvestYear,
  FertiliserHarvestYearSchema,
} from "../entities/fertiliser";
import { generateFertiliserByHarvestYearQueryKey } from "./get-fertiliser-by-harvest-year";

type FertiliserParam = {
  id: string;
  harvestYear: string | number;
  properties: Pick<FertiliserHarvestYear, "properties"> | object;
};

const createFertiliserByHarvestYear = async (params: FertiliserParam) => {
  const { id, harvestYear, properties } = params;
  const response = await axios.post<FertiliserHarvestYear>(
    `/v1/fertilisers/${id}/harvest_year/${harvestYear}`,
    properties,
    {
      schema: z.array(FertiliserHarvestYearSchema),
      apiSource: "crops-fertilisers",
    },
  );

  return response.data;
};

export const useCreateFertiliserByHarvestYearQuery = () =>
  useMutation(createFertiliserByHarvestYear, {
    onSuccess: (_, params) => {
      queryClient.invalidateQueries(
        generateFertiliserByHarvestYearQueryKey(params),
      );
    },
    onError: error => ToastNotification.error(error),
  });
