import { SortDirection, SortingState } from "@tanstack/react-table";

import { IconConfig } from "~assets";

export function getSortIconProps(
  direction: SortDirection | false,
  options: {
    sortIndex: number;
    isMulti: boolean;
  },
): IconConfig<"icon", "iconVariant"> {
  let icon: IconConfig<"icon">["icon"] = "sort";
  if (direction === "asc") icon = "sort-up";
  if (direction === "desc") icon = "sort-down";

  return {
    icon,
    /**
     * For multisorting, we want to highlight the primary sorting column,
     * as otherwise it can be hard to see which order of importance we are sorting by.
     * Example: sorting by year, then sub sorting by most hectares
     */
    iconVariant:
      options.isMulti && options.sortIndex === 0 ? "regular" : "solid",
  };
}

/**
 * Our API doesn't support multisorting, so we need to parse the sorting state into a supported single format
 * @param sorting Tanstack Table sorting state
 * @returns Object with a single key of the column ID, and a value of "asc" or "desc"
 */
export const parseListSorting = (sorting: SortingState) => {
  if (sorting.length === 0) return {};

  const [{ id, desc }] = sorting;

  return {
    [id]: desc ? "desc" : "asc",
  };
};
