import { GoogleMap } from "@react-google-maps/api";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import Card from "~components/card";
import { FieldDetails, useUpdateFieldMutation } from "~queries/carbon";

import CommentModal from "../CommentModal";
import Marker from "./Marker";
import { MAP_OPTIONS } from "./constants";
import { EditFieldAttribute, EditFieldData } from "./types";

type Props = {
  field: FieldDetails;
};

const FieldDataBox = ({ field }: Props) => {
  const [isMapModalVisible, setIsMapModalVisible] = useState(false);
  const [fieldDataToSubmit, setFieldDataToSubmit] = useState<
    EditFieldData | undefined
  >();

  const initialValues: EditFieldData = useMemo(
    () => ({
      name: field.name,
      sizeHa: field.sizeHa,
      latitude: field.position.lat,
      longitude: field.position.lng,
    }),
    [field],
  );

  const { handleSubmit, register } = useForm<EditFieldData>({
    defaultValues: initialValues,
    mode: "onBlur",
  });

  const updateFieldMutation = useUpdateFieldMutation();

  const handleSubmitFieldData = (comment: string) => {
    if (!fieldDataToSubmit) return;

    updateFieldMutation.mutate(
      {
        fieldId: field.id,
        fieldData: {
          name: fieldDataToSubmit.name,
          sizeHa: fieldDataToSubmit.sizeHa,
          position: {
            lat: fieldDataToSubmit?.latitude,
            lng: fieldDataToSubmit?.longitude,
          },
          comment: {
            text: comment,
          },
        },
      },
      {
        onSuccess: async () => {
          ToastNotification.success(
            I18n.t("js.admin.field_details.field_updated_success"),
          );
        },
      },
    );

    setFieldDataToSubmit(undefined);
  };

  const isCommentModalVisible = Boolean(fieldDataToSubmit);

  return (
    <Card.Root>
      <form onSubmit={handleSubmit(values => setFieldDataToSubmit(values))}>
        <div className="flex flex-wrap items-start">
          <div className="flex-basis-0 md:flex-0 flex-grow px-2 md:basis-2/3">
            <div className="mb-4">
              <div className="mb-4 flex flex-wrap items-start">
                <div className="flex-basis-0 md:flex-0 flex-grow px-2 md:basis-2/3">
                  <InputField
                    {...register(EditFieldAttribute.Name)}
                    label={I18n.t("js.carbon.field_name")}
                  />
                </div>

                <div className="flex-basis-0 flex-grow px-2">
                  <InputField
                    {...register(EditFieldAttribute.SizeHa)}
                    label={I18n.t("js.carbon.field.size")}
                  />
                </div>
              </div>

              <div className="mb-4 flex flex-wrap items-start">
                <div className="flex-basis-0 flex-grow px-2">
                  <InputField
                    {...register(EditFieldAttribute.Latitude)}
                    label={I18n.t("js.carbon.latitude")}
                  />
                </div>

                <div className="flex-basis-0 flex-grow px-2">
                  <InputField
                    {...register(EditFieldAttribute.Longitude)}
                    label={I18n.t("js.carbon.longitude")}
                  />
                </div>
              </div>

              <div className="flex justify-end">
                <Button type="submit">{I18n.t("js.shared.save")}</Button>
              </div>
            </div>
          </div>

          <div className="flex-basis-0 flex-grow px-2">
            <div className="relative">
              <GoogleMap
                mapContainerClassName="field-map"
                zoom={15}
                center={{
                  lat: initialValues.latitude,
                  lng: initialValues.longitude,
                }}
                options={MAP_OPTIONS}
                onClick={() => setIsMapModalVisible(true)}
              >
                <Marker />
              </GoogleMap>

              <Modal.Root
                className="p-0"
                size="extra-large"
                isOpen={isMapModalVisible}
                onRequestClose={() => setIsMapModalVisible(false)}
              >
                <Modal.Content>
                  <div className="relative">
                    <GoogleMap
                      mapContainerClassName="field-map"
                      zoom={15}
                      center={{
                        lat: initialValues.latitude,
                        lng: initialValues.longitude,
                      }}
                      options={MAP_OPTIONS}
                      onClick={() => setIsMapModalVisible(true)}
                    >
                      <Marker />
                    </GoogleMap>
                  </div>
                </Modal.Content>
              </Modal.Root>
            </div>
          </div>
        </div>
      </form>

      <CommentModal
        isOpen={isCommentModalVisible}
        isSubmitting={updateFieldMutation.isLoading}
        onClose={() => setFieldDataToSubmit(undefined)}
        onSubmit={handleSubmitFieldData}
      />
    </Card.Root>
  );
};

export default FieldDataBox;
