import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { UpdateRequest } from "../entities/update-request";

type Payload = {
  updateRequestId: string;
  clientId: string;
};

type SubmitRequest = Required<Pick<UpdateRequest, "clientId">>;

const submitCertificates = async (payload: Payload) => {
  const { updateRequestId } = payload;

  const response = await axios.patch<CommonResponse<SubmitRequest>>(
    `/api/v1/certificates/groups/update-requests/${updateRequestId}/submit`,
    payload,
    {
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useSubmitCertificatesMutation = () =>
  useMutation(submitCertificates, {
    onError: error => ToastNotification.error(error),
  });
