import { useMemo } from "react";

import { Button } from "@ag/design-system/atoms";
import { Table, useTable } from "@ag/design-system/organisms";

import { useCountries } from "~features/countries/api/get-countries";

import { FieldBoundariesInfo } from "../entities/field-boundaries-info";

export type FieldInfoTableProps = {
  fieldInfoData: FieldBoundariesInfo;
};

export function FieldInfoTable({ fieldInfoData }: FieldInfoTableProps) {
  const countries = useCountries();
  const columns = useMemo(
    () => [
      {
        accessorKey: "attributeName",
        header: "",
        cell: (info: any) => info.getValue(),
      },
      {
        accessorKey: "value",
        header: "",
        cell: (info: any) => info.getValue(),
      },
    ],
    [],
  );

  const data = useMemo(
    () => [
      {
        attributeName: "User ID",
        value: fieldInfoData.userId ? (
          <Button
            variant="text"
            size="small"
            to={`/users/${fieldInfoData.userId}`}
            target="_blank"
          >
            {fieldInfoData.userId}
          </Button>
        ) : (
          "n/a"
        ),
      },
      {
        attributeName: "Field ID",
        value: fieldInfoData.fieldId ? (
          <Button
            variant="text"
            size="small"
            to={`/carbon/fields/${fieldInfoData.fieldId}`}
            target="_blank"
          >
            {fieldInfoData.fieldId}
          </Button>
        ) : (
          "n/a"
        ),
      },
      {
        attributeName: "Field name",
        value: fieldInfoData.fieldName ? fieldInfoData.fieldName : "n/a",
      },
      {
        attributeName: "Country",
        value: fieldInfoData.country
          ? countries.data?.find(
              country => country.code === fieldInfoData.country,
            )?.name
          : "n/a",
      },
      {
        attributeName: "Company",
        value: fieldInfoData.companyName ? fieldInfoData.companyName : "n/a",
      },
      {
        attributeName: "Farm name",
        value: fieldInfoData.farmName ? fieldInfoData.farmName : "n/a",
      },
      {
        attributeName: "Contract VAT number",
        value: fieldInfoData.contractVatNumber
          ? fieldInfoData.contractVatNumber
          : "n/a",
      },
    ],
    [fieldInfoData, countries.data],
  );

  const tableInstance = useTable({
    columns,
    data,
  });

  return <Table instance={tableInstance} className="-mt-4 w-full" />;
}
