import { z } from "zod";

export const ClientSchema = z.object({
  id: z.string(),
  name: z.string(),
  adminId: z.string().nullable(),
  documents: z
    .array(z.object({ filename: z.string(), url: z.string() }))
    .nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export type Client = z.infer<typeof ClientSchema>;

export const CreateClientSchema = z.object({
  name: z.string().min(1, "This field is required"),
  documents: z.array(z.string()).optional(),
});

export type CreateClient = z.infer<typeof CreateClientSchema>;

export const UpdateClientSchema = z.object({
  name: z.string().optional(),
  documents: z.array(z.string()).optional(),
});

export type UpdateClient = z.infer<typeof UpdateClientSchema>;
