import clsx from "clsx";

import { Button } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import * as styles from "./back-button.css";

type Props = {
  className?: string;
  onClick?: () => void;
};

const BackButton = ({ className, onClick }: Props) => {
  const handleOnClick = () => {
    if (typeof onClick === "function") {
      onClick();
    } else {
      history.back();
    }
  };
  return (
    <Button
      variant="text"
      size="small"
      className={clsx(className, styles.root)}
      icon="chevron-left"
      onClick={handleOnClick}
    >
      {I18n.t("js.layout.back")}
    </Button>
  );
};

export default BackButton;
