// Unkown is needed here, bc some of the tanstack types rely on unknown.
// This also means we have to do some extra type checking in the component.
export type CoverCropHbCellValue = unknown | string | null | undefined;

type CoverCropHbCellProps<TValue extends CoverCropHbCellValue> = {
  getValue: () => TValue;
};

export function CoverCropHbCell<TValue extends CoverCropHbCellValue>({
  getValue,
}: CoverCropHbCellProps<TValue>) {
  const value = getValue();

  if (typeof value !== "string") return null;

  return (
    <span
      className="whitespace-pre font-mono text-p2"
      dangerouslySetInnerHTML={{ __html: formatValue(value) }}
    />
  );
}

/**
 * Formats the value, so all NDVI values of below 0.33 are highlighted
 *
 * ---
 * example:
 *
 * _in_
 * `
 * 2022 (0.5659, 0.122, 0.0875, 0.0563, 0.8569) yes,
 * 2021 (0.5659, 0.122, 0.0875, 0.0563, 0.8569) yes,
 * 2020 (0.5659, 0.122, 0.0875, 0.0563, 0.8569) yes,
 * 2019 (0.5659, 0.122, 0.0875, 0.0563, 0.8569) yes,
 * 2018 (0.5659, 0.122, 0.0875, 0.0563, 0.8569) yes
 * `
 *
 * _out_
 * `
 * 2022 (0.5659, <em>0.122</em>, <em>0.0875</em>, <em>0.0563</em>, 0.8569) yes,
 * 2021 (0.5659, <em>0.122</em>, <em>0.0875</em>, <em>0.0563</em>, 0.8569) yes,
 * 2020 (0.5659, <em>0.122</em>, <em>0.0875</em>, <em>0.0563</em>, 0.8569) yes,
 * 2019 (0.5659, <em>0.122</em>, <em>0.0875</em>, <em>0.0563</em>, 0.8569) yes,
 * 2018 (0.5659, <em>0.122</em>, <em>0.0875</em>, <em>0.0563</em>, 0.8569) yes,
 * `
 */
function formatValue(value: string) {
  const withHighlights = value.replaceAll(
    // First we grab all the month values inside the parenthesis
    /\(([^)]+)\)/g,
    (fullStr, innerStr) => {
      if (typeof innerStr !== "string") return fullStr;

      // Then split the strings on comma, parse them, and wrap values below 0.33
      const monthsStr = innerStr.split(/,\s?/).map(monthStr => {
        const value = parseFloat(monthStr);

        if (value < 0.33)
          return `<span class="bg-red-700 text-white-100 font-normal">${monthStr}</span>`;

        return monthStr;
      });

      // Then re-wrap the whole thing in parenthesis
      return `(${monthsStr.join(", ")})`;
    },
  );

  return withHighlights;
}
