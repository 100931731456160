import { useState } from "react";

import { Spinner } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";

import { useCropByHarvestYearQuery } from "../api/get-crop-harvest-year";
import BiomassForm from "./biomass-form";
import CropCountriesPropertiesForm from "./crop-countries-properties-form";
import IpccForm from "./ipcc-form";

const CropPropertiesForm = ({
  id,
  harvestYear,
  hasProperties,
}: {
  id: string;
  harvestYear: string;
  hasProperties: boolean;
}) => {
  const [showCountryProperties, setShowCountryProperties] = useState(false);
  const { isFetching, data } = useCropByHarvestYearQuery(id, harvestYear, {
    enabled: hasProperties,
  });

  return isFetching ? (
    <div className="flex items-center justify-center">
      <Spinner className="h-5" />
    </div>
  ) : (
    <div className="flex flex-col gap-10">
      <BiomassForm
        id={id}
        harvestYear={harvestYear}
        properties={data?.biomass_properties || {}}
      />

      <IpccForm
        id={id}
        harvestYear={harvestYear}
        properties={data?.ipcc_properties || {}}
      />

      <div>
        {showCountryProperties && (
          <CropCountriesPropertiesForm id={id} harvestYear={harvestYear} />
        )}
        <Button
          className="self-start"
          variant="text"
          icon={showCountryProperties ? "minus" : "plus"}
          onClick={() => setShowCountryProperties(!showCountryProperties)}
        >
          {showCountryProperties ? "Hide" : "Show"} country properties
        </Button>
      </div>
    </div>
  );
};

export default CropPropertiesForm;
