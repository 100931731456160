import moment from "moment";

import { DateFormatter } from "../formatters/date";

export function hasValueChanged(orignalValue: unknown, newValue: unknown) {
  if (typeof orignalValue !== typeof newValue) {
    console.warn("Comapring different types");
  }

  return orignalValue !== newValue;
}

export function instanceOfModel(expectedModelName: string) {
  function validate(
    props: {
      [key: string]: string;
    },
    propName: string,
    componentName: string,
    location: string,
  ) {
    if (!props[propName]) return null;

    const checkedModelName = props[propName].constructor.name;
    if (checkedModelName === expectedModelName) return null;

    return new Error(
      `Invalid ${location} ${propName} of type ${checkedModelName} supplied to ${componentName}, expected ${expectedModelName}`,
    );
  }

  return validate.bind(null);
}

export function showDateIfExists(date?: Date) {
  if (date) return DateFormatter.dbTime(date);

  return "-";
}

export function generateTwoYearsAhead(isDateObject: Date) {
  const numberOfMonths = 24;
  const dates = [];

  for (let i = 0; i <= numberOfMonths; i++) {
    const momentDate = moment().set("date", 1).add(i, "months");

    if (isDateObject) {
      dates.push(momentDate.toDate());
    } else {
      dates.push(momentDate);
    }
  }

  return dates;
}

export function getMetaPageObj({ page = 1, limit = 10 }) {
  return {
    page,
    per_page: limit,
    include_meta: true,
  };
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const ctPrimaryCountries = [
  "DENMARK",
  "ROMANIA",
  "POLAND",
  "HUNGARY",
  "SLOVAKIA",
  "LITHUANIA",
];

export function getPathWithoutSearchParams(path: string) {
  const [pathWithoutSearch] = path.split("?");

  return pathWithoutSearch;
}

export const generateUniqueId = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);
