import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config/react-query";

import { Admin } from "../entities/admin";
import { generateAdminsQueryKey } from "./get-admins";

type AdminData = {
  id: string;
} & Partial<{
  email: string;
  roles: string[];
  password: string;
  passwordConfirmation: string;
}>;

const updateAdmin = async (admin: AdminData) => {
  const { id, ...adminData } = admin;

  const response = await axios.put<CommonResponse<Admin>>(
    `/api/v1/admins/${id}`,
    adminData,
    {
      apiSource: "node-auth",
    },
  );

  return response.data.success!.data;
};

export const useUpdateAdminMutation = () =>
  useMutation(updateAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateAdminsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
