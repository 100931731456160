import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useField, useForm } from "react-final-form";

import { CheckboxField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { SFallow, SUnsavedIndicator } from "./styled";
import { FallowAttribute, FallowV2022Data } from "./types";

type Props = {
  isFallow?: never;
  isReadonly: boolean;
  onFormInitialize?: (form: FormApi<FallowV2022Data>) => void;
};

export const FallowForm = ({ isReadonly, onFormInitialize }: Props) => {
  const form = useForm<FallowV2022Data>();
  const { meta: fallowMeta } = useField(FallowAttribute.Fallow);

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  return (
    <SFallow>
      <p className="text-p3 text-grey-500 font-bold uppercase">
        {I18n.t("js.carbon.field_details.set_aside")}
      </p>

      <Field
        name={FallowAttribute.Fallow}
        render={({ input, meta }) => (
          <CheckboxField
            {...input}
            type={undefined}
            label={I18n.t(
              "js.carbon.field_details.fallow_drought_biodiversity_grazing",
            )}
            error={
              meta.touched && meta.error ? { message: meta.error } : undefined
            }
            tooltip={I18n.t(
              "js.carbon.field_details.fallow_or_drought_tooltip",
            )}
            isDisabled={isReadonly}
          />
        )}
        disabled={isReadonly}
      />

      {fallowMeta.dirty && (
        <SUnsavedIndicator className="text-p3">
          {I18n.t("js.shared.unsaved")}
        </SUnsavedIndicator>
      )}
    </SFallow>
  );
};
