import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

import { CarbonCountry, CarbonCountrySchema } from "../entities/carbon-country";

export const generateCarbonCountriesKey = () => ["carbon-countries"];

const getCarbonCountries = async () => {
  const response = await axios.get<CommonResponse<CarbonCountry[]>>(
    "/api/v1/carbon-countries",
    {
      apiSource: "node-carbon",
      schema: z.array(CarbonCountrySchema),
    },
  );

  return response.data.success!.data;
};

export const useCarbonCountries = (
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(generateCarbonCountriesKey(), () => getCarbonCountries(), {
    keepPreviousData: true,
    ...options,
  });
