import { ValidationErrors } from "final-form";
import cloneDeep from "lodash/cloneDeep";

import I18n from "@ag/i18n";
import { KeyMap, LabelValue } from "@ag/utils/types";

import {
  SoilCharacteristicsAttribute,
  SoilCharacteristicsDataError,
} from "./types";

export function validateSoilCharacteristicsData(
  values: ValidationErrors,
): SoilCharacteristicsDataError {
  const errors: SoilCharacteristicsDataError = {};

  if (!values) return errors;

  if (!values[SoilCharacteristicsAttribute.Som]) {
    errors[SoilCharacteristicsAttribute.Som] = I18n.t(
      "js.carbon.select_som_value",
    );
  }

  if (!values[SoilCharacteristicsAttribute.Texture]) {
    errors[SoilCharacteristicsAttribute.Texture] = I18n.t(
      "js.carbon.select_texture_value",
    );
  }

  if (!values[SoilCharacteristicsAttribute.Ph]) {
    errors[SoilCharacteristicsAttribute.Ph] = I18n.t(
      "js.carbon.select_ph_value",
    );
  }

  if (!values[SoilCharacteristicsAttribute.Moisture]) {
    errors[SoilCharacteristicsAttribute.Moisture] = I18n.t(
      "js.carbon.select_moisture_value",
    );
  }

  if (!values[SoilCharacteristicsAttribute.Drainage]) {
    errors[SoilCharacteristicsAttribute.Drainage] = I18n.t(
      "js.carbon.select_drainage_value",
    );
  }

  return errors;
}

export function getSoilDetailsExplanation(countryCode: string | null): string {
  if (!countryCode) {
    return "";
  }

  /**
   * TODO: Fill out with text when we have final ones
   * ttps://app.asana.com/0/1119995484634406/1199610045245222
   */
  const texts: KeyMap<string> = {
    DK: "",
    RO: "",
  };

  return texts[countryCode] || "";
}

export const getSoilTextureOptionsReordered = (
  soilTexture: LabelValue<string>[],
): LabelValue<string>[] => {
  const soilTextureOptions: LabelValue<string>[] = cloneDeep(soilTexture);
  const itemsOrder = ["Silt", "Clay", "Sand"];

  soilTextureOptions.sort((a, b) => {
    if (itemsOrder.indexOf(a.value) === -1) {
      return 1;
    }

    return itemsOrder.indexOf(a.value) - itemsOrder.indexOf(b.value);
  });

  return soilTextureOptions;
};
