import ActionAgreementButton, {
  OnActionAgreement,
} from "./action-agreement-button";

type Props = {
  isWithdrawing: boolean;
  onWithdraw: OnActionAgreement;
};

const WithdrawAgreementButton = ({ isWithdrawing, onWithdraw }: Props) => (
  <ActionAgreementButton
    buttonActionText="Withdraw agreement"
    textAreaLabel="Enter reason for withdrawing agreement"
    buttonModalText="Add reason and withdraw"
    isLoading={isWithdrawing}
    onActionAgreement={onWithdraw}
    aria-label="Withdraw agreement"
  />
);

export default WithdrawAgreementButton;
