"use client";

import { useStepper } from "headless-stepper";
import { Children, cloneElement, useMemo } from "react";

import { findComponents } from "@ag/utils/helpers";

import { Icon } from "~assets";
import { cn } from "~utils";

import { StepperContext } from "./context";
import { getStepProps } from "./helpers";
import { StepperChildrenCallbackArgs } from "./types";

/* -------------------------------------------------------------------------------------------------
 * Stepper
 * -----------------------------------------------------------------------------------------------*/
type StepperProps = {
  initialStep?: number;
  children: React.ReactNode;
};

const Stepper = ({ children, initialStep = 0 }: StepperProps) => {
  const [ListElement] = findComponents<typeof StepperList>(
    children,
    StepperList,
  );

  const TriggerElements = ListElement?.props.children;

  const ContentElements = findComponents<typeof StepperContent>(
    children,
    StepperContent,
  );

  const steps = useMemo(
    () =>
      TriggerElements
        ? Children.map(TriggerElements, (child, index) => ({
            label: String(index),
            disabled: child.props.isDisabled,
          }))
        : [],
    [TriggerElements],
  );

  const { state, progressProps, stepsProps, prevStep, nextStep } = useStepper({
    steps,
    currentStep: initialStep,
  });

  const ContentElement = ContentElements.find(child => {
    const getTriggerValueById = (stepIndex: number) => {
      if (!TriggerElements) return;

      const values = Children.map(TriggerElements, el => el?.props?.value);

      return values[stepIndex];
    };

    return child.props.value === getTriggerValueById(state.currentStep);
  });

  const ContentWithProps = useMemo(() => {
    const ContentElementChildren = ContentElement?.props.children;

    if (!ContentElementChildren) return;

    return typeof ContentElementChildren === "function"
      ? ContentElementChildren({
          hasPrevStep: state.hasPreviousStep || false,
          hasNextStep: state.hasNextStep || false,
          goPrevStep: prevStep,
          goNextStep: nextStep,
        })
      : ContentElementChildren;
  }, [
    ContentElement,
    state.hasPreviousStep,
    state.hasNextStep,
    prevStep,
    nextStep,
  ]);

  if (!TriggerElements) return null;

  return (
    <StepperContext.Provider
      value={{
        hasPrevStep: state.hasPreviousStep || false,
        hasNextStep: state.hasNextStep || false,
        goPrevStep: prevStep,
        goNextStep: nextStep,
      }}
    >
      <header
        className={cn(
          "flex w-full items-center justify-center gap-3",
          ListElement.props.className,
        )}
      >
        {Children.map(TriggerElements, (child, index) => {
          const { isStepCompleted, isStepSelected, onClick } = getStepProps(
            stepsProps[index],
            progressProps,
          );

          return cloneElement(child, {
            isCompleted: isStepCompleted,
            isSelected: isStepSelected,
            onClick,
          });
        })}
      </header>

      <div className="flex h-full w-full flex-col">{ContentWithProps}</div>
    </StepperContext.Provider>
  );
};

/* -------------------------------------------------------------------------------------------------
 * StepperList
 * -----------------------------------------------------------------------------------------------*/
function StepperList({
  children,
  className,
}: React.HTMLAttributes<HTMLDivElement>) {
  return <div className={className}>{children}</div>;
}

/* -------------------------------------------------------------------------------------------------
 * StepperTrigger
 * -----------------------------------------------------------------------------------------------*/
type StepperTriggerProps = {
  value: string;
  title: string;
  subtitle?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  isCompleted?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

function StepperTrigger({
  title,
  subtitle,
  isSelected,
  isDisabled,
  isCompleted,
  onClick,
}: StepperTriggerProps) {
  return (
    <div
      className={cn(
        "group flex items-center gap-3",
        isDisabled && "pointer-events-none",
      )}
    >
      <div
        className={cn(
          "pointer-events-none h-0.5 w-14 rounded-full group-first:hidden",
          isCompleted || isSelected
            ? "h-0.5 bg-accent-200"
            : "h-px bg-grey-900",
        )}
      />

      <div className="flex cursor-pointer items-center gap-3" onClick={onClick}>
        <div
          className={cn(
            "flex h-8 w-8 items-center justify-center rounded-full",
            {
              "border-2 border-accent-200 bg-sky-100": isSelected,
              "border border-grey-900": !isCompleted || isDisabled,
              "bg-accent-200": isCompleted && !isSelected,
            },
          )}
        >
          {isCompleted && !isSelected && (
            <Icon name="check" className="text-[16px] text-grey-white" />
          )}
        </div>

        <div
          className={cn("focus:outline-none", {
            "text-accent-200": Boolean(isSelected),
            "text-grey-900": !isCompleted || isDisabled,
          })}
        >
          <h5 className="text-p1">{title}</h5>
          {subtitle && <p className="text-p2">{subtitle}</p>}
        </div>
      </div>
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * StepperContent
 * -----------------------------------------------------------------------------------------------*/
type StepperContentProps = {
  value: string;
  children:
    | ((props: StepperChildrenCallbackArgs) => React.ReactNode)
    | React.ReactNode;
  className?: string;
  hasPrevStep?: boolean;
  hasNextStep?: boolean;
  goPrevStep?: () => void;
  goNextStep?: () => void;
};

function StepperContent({
  children,
  className,
  hasPrevStep,
  hasNextStep,
  goPrevStep,
  goNextStep,
}: StepperContentProps) {
  if (!hasPrevStep || !hasNextStep || !goPrevStep || !goNextStep) return null;

  return (
    <div className={cn("flex grow", className)}>
      {typeof children === "function"
        ? children({
            hasPrevStep,
            hasNextStep,
            goPrevStep,
            goNextStep,
          })
        : children}
    </div>
  );
}

export const BasicStepper = {
  Root: Stepper,
  List: StepperList,
  Trigger: StepperTrigger,
  Content: StepperContent,
};
