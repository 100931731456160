import { useState } from "react";
import { Form } from "react-final-form";

import { FuelConsumptionForm } from "@ag/carbon/forms/inputs-v2022";
import { stack } from "@ag/design-system/utils";
import I18n from "@ag/i18n";

import CollapseBox from "~components/collapse-box";
import { FieldActualsV2022, FieldActualsV2022Input } from "~features/field";
import { getInitialResources } from "~helpers/initial-resources";

import AdminEditActualsComment, {
  ActualsData,
} from "../../edit-actuals-comment-modal";
import { getInitialValues } from "./helpers";

type Props = {
  fieldId: string;
  fieldActuals: FieldActualsV2022;
  isReadonly: boolean;
};

const FuelConsumption = ({ fieldId, fieldActuals, isReadonly }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitFormData, setSubmitFormData] = useState<ActualsData | null>(
    null,
  );

  const initialResources = getInitialResources();

  const handleSubmit = (values: Partial<FieldActualsV2022Input>) => {
    setSubmitFormData({ carbonFieldActualInput: values });
    setIsModalOpen(true);
  };

  return (
    <div className={stack()}>
      <Form
        initialValues={getInitialValues(fieldActuals.carbonFieldActualInput)}
        onSubmit={handleSubmit}
        render={() => (
          <CollapseBox.Root isInitiallyOpen>
            <CollapseBox.Title>
              {I18n.t("js.carbon.energy_consumption")}
            </CollapseBox.Title>
            <CollapseBox.Content>
              <FuelConsumptionForm
                energySource={
                  initialResources?.field.energyConsumptionEnergySource ?? []
                }
                isReadonly={isReadonly}
              />
            </CollapseBox.Content>
          </CollapseBox.Root>
        )}
      />

      <AdminEditActualsComment
        fieldId={fieldId}
        fieldActualsId={fieldActuals.id}
        actualsData={submitFormData}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default FuelConsumption;
