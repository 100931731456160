import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { BuyoutPaymentStatus } from "../entities/buyout-agreement";
import { generateBuyoutAgreementQueryKey } from "./get-buyout-agreement-detail";

const updateBuyoutAgreementPayment = async ({
  id,
  paymentStatus,
}: {
  id: string;
  paymentStatus: BuyoutPaymentStatus;
}) => {
  const response = await axios.put(
    `/api/v1/carbon-sales-agreements/${id}/payment-status`,
    { paymentStatus },
    { apiSource: "node-carbon" },
  );

  return response.data.success!;
};

export const useUpdateBuyoutAgreementPayment = () =>
  useMutation(updateBuyoutAgreementPayment, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        generateBuyoutAgreementQueryKey(variables.id),
      );
    },
    onError: error => ToastNotification.error(error),
  });
