import { ChangeEvent } from "react";
import { Controller, useForm } from "react-hook-form";

import { Icon } from "@ag/design-system/assets";
import { Button, Checkbox, Label } from "@ag/design-system/atoms";
import I18n, { getLocaleName, getLocales } from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import Card from "~components/card";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";
import { useApplicationSettingQueryByName } from "~queries/application-settings";
import { useUpdateTranslationsMutation } from "~queries/github";

import { AddTranslationsFormData } from "./types";

const Translations = () => {
  const { data: translationsGithubToken } = useApplicationSettingQueryByName(
    "translations_gh_token",
  );
  const updateTranslationsMutation = useUpdateTranslationsMutation();

  const onSubmitTranslations = (values: AddTranslationsFormData) => {
    const locales = Object.keys(values).filter(key => Boolean(values[key]));

    if (translationsGithubToken) {
      updateTranslationsMutation.mutate(
        {
          locales: Object.values(locales),
          translationsGithubToken,
        },
        {
          onSuccess: () => {
            ToastNotification.success(
              I18n.t("js.settings.translations.update_success_notification"),
            );
          },
        },
      );
    }
  };

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm<any>();
  const locales = getLocales();
  const values = watch();
  const isAllLocalesSelected = locales.every(locale => values[locale]);

  const handleSelectAllChanged = (event: ChangeEvent<HTMLInputElement>) => {
    const isSelected = event.target.checked;
    locales.forEach(locale => {
      setValue(locale, isSelected);
    });
  };

  return (
    <DetailsLayout.Root>
      <DetailsLayout.TopBar key="topbar">
        <DetailsLayout.TopBarTitle>
          {I18n.t("js.settings.translations.title")}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Sidebar key="sidebar">
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.Content key="content">
        <form onSubmit={handleSubmit(onSubmitTranslations)}>
          <Card.Root>
            <div className="mb-4 flex items-center justify-start gap-4">
              <Checkbox
                id="select-all"
                checked={isAllLocalesSelected}
                onChange={handleSelectAllChanged}
                name="select-all"
              />
              <Label htmlFor="select-all">Select All</Label>
            </div>

            <div className="grid w-full grid-cols-3 justify-between gap-2">
              {locales.map(locale => (
                <div
                  className="flex items-center justify-start gap-4"
                  key={locale}
                >
                  <Controller
                    name={locale}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <Checkbox
                        id={locale}
                        checked={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <Label htmlFor={locale}>{getLocaleName(locale)}</Label>
                </div>
              ))}
            </div>

            <div className="text-sm font-normal mt-2 flex items-center text-left text-red-700">
              <Icon name="warning" />
              <span className="ml-2">At least one translation is required</span>
            </div>
          </Card.Root>

          <div className="mt-6 flex justify-end">
            <Button isLoading={isSubmitting} type="submit">
              Update
            </Button>
          </div>
        </form>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default Translations;
