export enum TimeRange {
  None = "none",
  Overdue = "overdue",
  Today = "today",
  SinceYesterday = "since_yesterday",
  Last7Days = "last_7_days",
  Last14Days = "last_14_days",
  LastMonth = "last_month",
  Last3Months = "last_3_months",
  Last6Months = "last_6_months",
  UntilTomorrow = "until_tomorrow",
  Next7Days = "next_7_days",
  Next14Days = "next_14_days",
  NextMonth = "next_month",
  Next3Months = "next_3_months",
  Next6Months = "next_6_months",
}
