import { create } from "zustand";

interface AppHistoryState {
  historyStack: string[];

  addPage: (page: string) => void;
  shiftPage: () => void;
}

export const useAppHistoryStore = create<AppHistoryState>()(set => ({
  historyStack: [],

  addPage: page =>
    set(state => ({ historyStack: [...state.historyStack, page] })),
  shiftPage: () =>
    set(state => ({ historyStack: state.historyStack.slice(1) })),
}));
