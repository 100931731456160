import { Polygon as GMPolygon, PolygonProps } from "@react-google-maps/api";

import { colorsObject } from "@ag/design-system/tokens";

export const Polygon = ({ options, ...restPolygonProps }: PolygonProps) => {
  return (
    <GMPolygon
      {...restPolygonProps}
      options={{
        strokeColor: colorsObject.blue[500],
        strokeWeight: 1,
        fillColor: colorsObject.blue[500],
        fillOpacity: 0.2,
        ...options,
      }}
    />
  );
};
