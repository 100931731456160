import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { ActionButton } from "@ag/components/buttons";
import { FormButtonsGroup, FormInput } from "@ag/components/form";
import { P2, SCol, SParagraph } from "@ag/components/styled";
import I18n from "@ag/i18n";

import { SFormRow, SFormSubmitButtonContainer } from "~forms/styled";

import {
  getSoilDetailsExplanation,
  getSoilTextureOptionsReordered,
} from "./helpers";
import {
  SoilCharacteristicsAttribute,
  SoilCharacteristicsData,
  SoilCharacteristicsFieldPartial,
} from "./types";

type Props = {
  countryCode: string | null;
  fieldPartial: SoilCharacteristicsFieldPartial | undefined;
  isSubmitting: boolean;
  isReadonly?: boolean;
  isSubmitButtonVisible?: boolean;
  onFormInitialize: (form: FormApi<SoilCharacteristicsData>) => void;
};

export const SoilCharacteristicsForm = ({
  countryCode,
  fieldPartial,
  isSubmitButtonVisible = true,
  isReadonly = false,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<SoilCharacteristicsData>();
  const { pristine } = useFormState<SoilCharacteristicsData>();

  useEffect(() => {
    onFormInitialize(form);
  }, [form, onFormInitialize]);

  if (!fieldPartial) return null;

  const soilDetailsExplanation = getSoilDetailsExplanation(countryCode);
  const soilTexturesReordered = getSoilTextureOptionsReordered(
    fieldPartial.soilTexture,
  );

  return (
    <>
      <SFormRow>
        <SCol>
          <P2>{I18n.t("js.carbon.soil_characteristics_description")}</P2>
        </SCol>
      </SFormRow>

      <SFormRow>
        <SCol col={8} md={12}>
          <Field
            name={SoilCharacteristicsAttribute.Som}
            label={`${I18n.t("js.carbon.som")}`}
            component={FormInput}
            type="number"
            testTag="soilCharacteristics-somInput"
            description={I18n.t("js.carbon.soil_characteristics_instruction")}
            tooltip={I18n.t("js.carbon.som_tooltip")}
            disabled
          />
        </SCol>
      </SFormRow>

      {soilDetailsExplanation && (
        <SFormRow>
          <SCol>
            <SParagraph lineHeight={20} fontSize={13}>
              {soilDetailsExplanation}
            </SParagraph>
          </SCol>
        </SFormRow>
      )}

      <SFormRow>
        <SCol col={4}>
          <Field
            testTag={SoilCharacteristicsAttribute.Texture}
            name={SoilCharacteristicsAttribute.Texture}
            label={I18n.t("js.carbon.texture")}
            wrapperTestTag="soilCharacteristics-textureButtonGroup"
            component={FormButtonsGroup}
            buttons={soilTexturesReordered}
            tooltip={I18n.t("js.carbon.texture_tooltip")}
            disabled={isReadonly}
          />
        </SCol>

        <SCol>
          <Field
            testTag={SoilCharacteristicsAttribute.Moisture}
            name={SoilCharacteristicsAttribute.Moisture}
            label={I18n.t("js.carbon.moisture")}
            wrapperTestTag="soilCharacteristics-moistureButtonGroup"
            component={FormButtonsGroup}
            buttons={fieldPartial.soilMoisture}
            tooltip={I18n.t("js.carbon.moisture_tooltip")}
            disabled={isReadonly}
          />
        </SCol>
      </SFormRow>

      <SFormRow>
        <SCol col={4}>
          <Field
            testTag={SoilCharacteristicsAttribute.Drainage}
            name={SoilCharacteristicsAttribute.Drainage}
            label={I18n.t("js.carbon.drainage")}
            wrapperTestTag="soilCharacteristics-drainageButtonGroup"
            component={FormButtonsGroup}
            buttons={fieldPartial.soilDrainage}
            tooltip={I18n.t("js.carbon.drainage_tooltip")}
            disabled={isReadonly}
          />
        </SCol>

        <SCol>
          <Field
            testTag={SoilCharacteristicsAttribute.Ph}
            name={SoilCharacteristicsAttribute.Ph}
            label={I18n.t("js.carbon.ph")}
            wrapperTestTag="soilCharacteristics-phButtonGroup"
            component={FormButtonsGroup}
            buttons={fieldPartial.soilPhRange}
            tooltip={I18n.t("js.carbon.ph_tooltip")}
            disabled={isReadonly}
          />
        </SCol>
      </SFormRow>

      {isSubmitButtonVisible && !isReadonly && (
        <SFormSubmitButtonContainer>
          <ActionButton
            HTMLType="submit"
            testTag="soilCharacteristics-submitButton"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}
    </>
  );
};
