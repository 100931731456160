import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { BuyoutContractStatus } from "~features/buyout-agreements";
import {
  CertificaGroupStatus,
  ContractType,
} from "~features/inventory/entities/inventory";
import { ResaleContractStatus } from "~features/resale-agreements";

type ExportInventoryParams = {
  filters?: Partial<{
    importId?: string | undefined;
    fieldId?: string | undefined;
    status?: CertificaGroupStatus[] | undefined;
    vintageYear?: string[] | undefined;
    countryCode?: string[] | undefined;
    cropTypeName?: string[] | undefined;
    isoEligible?: string | undefined;
    verraEligible?: string | undefined;
    contractType?: ContractType | undefined;
    contractStatus?: BuyoutContractStatus | ResaleContractStatus | undefined;
    contractId?: string | undefined;
    userId?: string | undefined;
  }>;
};

export const generateExportInventoryQueryKey = (
  params?: ExportInventoryParams | undefined,
) => ["export-inventory", params].filter(Boolean);

const exportInventory = async (params?: ExportInventoryParams) => {
  return axios.get<string>("/api/v1/inventory/certificate-groups/export", {
    params,
    apiSource: "node-markets",
  });
};

export const useExportInventoryQuery = (params?: ExportInventoryParams) =>
  useQuery(
    generateExportInventoryQueryKey(params),
    () => exportInventory(params),
    {
      // Disabled by default, as it has to be triggered manually
      enabled: false,
    },
  );
