import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { queryClient } from "~config/react-query";

import { generateResaleAgreementDetailsQueryKey } from "./get-resale-agreement-detail";
import { generateResaleAgreementsQueryKey } from "./get-resale-agreements";

const withdrawResaleAgreement = async ({
  id,
  reason,
}: {
  id: string;
  reason: string;
}) => {
  await axios.delete(`/api/v1/broker-contracts/${id}`, {
    params: {
      reason,
    },
    apiSource: "node-carbon",
  });
};

export const useWithdrawResaleAgreementMutation = () =>
  useMutation(withdrawResaleAgreement, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(generateResaleAgreementsQueryKey());
      queryClient.invalidateQueries(
        generateResaleAgreementDetailsQueryKey(variables.id),
      );
    },
  });
