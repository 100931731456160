import {
  OnChangeFn,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo } from "react";

import {
  ChipCell,
  ChipCellValue,
  HeaderSelectionCell,
  LinkCell,
  LinkCellValue,
  SelectionCell,
  TextCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { useTable } from "~components/table";

import { FieldOverview } from "../entities/field-overview";

type TableData = {
  id: LinkCellValue;
  userId: LinkCellValue;
  farmId: TextCellValue;
  companyName: TextCellValue;
  fieldName: TextCellValue;
  fieldSize: TextCellValue;
  fieldDefinitionSubmitted: TextCellValue;
  validationStatus: ChipCellValue;
  createdActuals: ChipCellValue;
  submittedActuals: ChipCellValue;
  activeCarbonContract: LinkCellValue;
  expiredAt: TextCellValue;
  unlinkedCarbonContract: LinkCellValue;
  removalReason: TextCellValue;
  churnedAt: TextCellValue;
  hasContractAmendmentDocument: TextCellValue;

  // We need to keep a reference to the field for bulk actions.
  field: FieldOverview;
};

type UseFieldsTableOptions = Partial<{
  sorting: SortingState;
  selection: RowSelectionState;
  isSelectionEnabled: boolean;
  showContractAmendmentStatus: boolean;
  setSorting: OnChangeFn<SortingState>;
  setSelection: OnChangeFn<RowSelectionState>;
}>;

export const useFieldsTable = (
  data: FieldOverview[] | undefined,
  state?: UseFieldsTableOptions,
) => {
  const columns = useMemo(
    () =>
      getColumns({
        isSelectionEnabled: state?.isSelectionEnabled,
        isSortingEnabled: Boolean(state?.sorting),
        showContractAmendmentStatus: state?.showContractAmendmentStatus,
      }),
    [
      state?.isSelectionEnabled,
      state?.sorting,
      state?.showContractAmendmentStatus,
    ],
  );
  const tableData = useMemo(() => getRowData(data), [data]);

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => original.field.id.toString(),
    state: {
      rowSelection: state?.selection ?? {},
      sorting: state?.sorting ?? [],
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: state?.setSelection,
    onSortingChange: state?.setSorting,
  });
};

type ColumnsOptions = {
  isSelectionEnabled?: boolean;
  isSortingEnabled?: boolean;
  showContractAmendmentStatus?: boolean;
};

function getColumns({
  isSelectionEnabled,
  isSortingEnabled,
  showContractAmendmentStatus,
}: ColumnsOptions = {}) {
  const columnHelper = createColumnHelper<TableData>();

  return [
    ...(isSelectionEnabled
      ? [
          columnHelper.display({
            id: "select",
            header: HeaderSelectionCell,
            cell: SelectionCell,
          }),
        ]
      : []),
    columnHelper.accessor("id", {
      cell: LinkCell,
      enableSorting: isSortingEnabled,
      header: I18n.t("js.carbon.field_id"),
    }),
    columnHelper.accessor("userId", {
      cell: LinkCell,
      header: I18n.t("activerecord.attributes.company.user_id"),
    }),
    columnHelper.accessor("farmId", {
      header: "Farm ID",
    }),
    columnHelper.accessor("companyName", {
      header: I18n.t("activerecord.attributes.credable_search.company_name"),
    }),
    columnHelper.accessor("fieldName", {
      header: I18n.t("js.carbon.field_name_or_identifier"),
    }),
    columnHelper.accessor("fieldSize", {
      header: I18n.t("js.carbon.field_size_ha"),
    }),
    columnHelper.accessor("fieldDefinitionSubmitted", {
      header: I18n.t("js.carbon.report_requested"),
    }),
    columnHelper.accessor("validationStatus", {
      cell: ChipCell,
      header: I18n.t("js.carbon.validation_status_title"),
    }),

    columnHelper.accessor("createdActuals", {
      cell: ChipCell,
      header: I18n.t("js.admin.carbon.fields.created_actuals"),
    }),
    columnHelper.accessor("submittedActuals", {
      cell: ChipCell,
      header: I18n.t("js.admin.carbon.fields.submitted_actuals"),
    }),
    columnHelper.accessor("activeCarbonContract", {
      cell: LinkCell,
      header: I18n.t("js.admin.carbon.fields.active_carbon_contract"),
    }),
    columnHelper.accessor("expiredAt", {
      header: "Expired At",
    }),
    columnHelper.accessor("unlinkedCarbonContract", {
      cell: LinkCell,
      header: "Unlinked Carbon Contract",
    }),
    ...(showContractAmendmentStatus
      ? [
          columnHelper.accessor("removalReason", {
            header: "Removed from contract reason",
          }),
          columnHelper.accessor("churnedAt", {
            header: "Churned at",
          }),
          columnHelper.accessor("hasContractAmendmentDocument", {
            header: "Has Contract Amendment Document",
          }),
        ]
      : []),
  ];
}

function getRowData(fields: FieldOverview[] | undefined): TableData[] {
  if (!fields) return [];

  return fields.map(field => ({
    id: {
      url: `/carbon/fields/${field.id}`,
      title: field.id,
    },
    userId: {
      url: `/users/${field.user.id}`,
      title: field.user.id,
    },
    farmId: field.carbonFarmId,
    companyName: field.user.company.name,
    fieldName: field.name,
    fieldSize: field.sizeHa,
    // TODO: use boolean cell when added to DS
    fieldDefinitionSubmitted: Boolean(field.carbonFieldDefinition?.submittedAt),
    validationStatus: getValidationStatusChip(field.validationStatus),

    createdActuals: (field.carbonFieldActuals ?? [])
      .map(actuals => actuals.harvestYear)
      .sort(),
    submittedActuals: (field.carbonFieldActuals ?? [])
      .filter(actuals => actuals.submittedAt)
      .map(actuals => actuals.harvestYear)
      .sort(),
    activeCarbonContract: field.activeCarbonContractId
      ? {
          url: `/carbon/contracts/${field.activeCarbonContractId}`,
          title: String(field.activeCarbonContractId),
        }
      : null,
    expiredAt: field.expiredAt,
    unlinkedCarbonContract: field.uncontractedField
      ? {
          url: `/carbon/contracts/${field.uncontractedField.carbonContractId}`,
          title: String(field.uncontractedField.carbonContractId),
        }
      : null,
    removalReason: field.uncontractedField
      ? field.uncontractedField?.removalReason
      : null,
    churnedAt:
      field.uncontractedField && field.uncontractedField.churnedAt
        ? new Date(field.uncontractedField?.churnedAt)
            .toISOString()
            .split("T")[0]
        : null,
    hasContractAmendmentDocument: field.uncontractedField
      ? field.uncontractedField?.hasDocument.toString()
      : "not required",

    field,
  }));
}

function getValidationStatusChip(
  validationStatus: FieldOverview["validationStatus"],
) {
  const lookup: Record<typeof validationStatus, ChipCellValue> = {
    new: {
      variant: "info",
      label: I18n.t("js.carbon.validation_status.new"),
    },
    in_progress: {
      variant: "info",
      label: I18n.t("js.carbon.validation_status.in_progress"),
    },
    rejected: {
      variant: "danger",
      label: I18n.t("js.carbon.validation_status.rejected"),
    },
    validated: {
      variant: "success",
      label: I18n.t("js.carbon.validation_status.validated"),
    },
  };

  return lookup[validationStatus];
}
