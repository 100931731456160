import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { queryClient } from "~config/react-query";

import { generateBuyoutAgreementQueryKey } from "./get-buyout-agreement-detail";
import { generateBuyoutAgreementsQueryKey } from "./get-buyout-agreements";

const withdrawBuyoutAgreement = async (payload: {
  id: string;
  reason: string;
}) => {
  await axios.patch(
    `/api/v1/carbon-sales-agreements/${payload.id}/withdraw`,
    {
      reason: payload.reason,
    },
    {
      apiSource: "node-carbon",
    },
  );
};

export const useWithdrawBuyoutAgreementMutation = () =>
  useMutation(withdrawBuyoutAgreement, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(generateBuyoutAgreementsQueryKey());
      queryClient.invalidateQueries(
        generateBuyoutAgreementQueryKey(variables.id),
      );
    },
  });
