import { useCallback } from "react";
import { Location, useSearchParams } from "react-router-dom";

type UseSearchParamOptions = {
  replace?: boolean | undefined;
  state?: Location["state"];
};

export const useSearchParam = (
  key: string,
  options?: UseSearchParamOptions,
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchParamSet = useCallback(
    (value: string | undefined) => {
      if (value) {
        searchParams.set(key, encodeURIComponent(value));
      } else {
        searchParams.delete(key);
      }

      setSearchParams(searchParams, options);
    },
    [key, options, searchParams, setSearchParams],
  );

  const paramValue = searchParams.get(key);

  return [
    paramValue ? decodeURIComponent(paramValue) : undefined,
    handleSearchParamSet,
  ] as const;
};
