import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

const deleteDistributeVcu = async (id: string) => {
  await axios.delete(`/api/v1/verified-credit-units/${id}`, {
    apiSource: "node-markets",
  });
};

export const useDeleteDistributeVcuMutation = () =>
  useMutation(deleteDistributeVcu, {
    onError: error => ToastNotification.error(error),
  });
