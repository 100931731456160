import set from "lodash/set";

import { AssuranceFilterData } from "../types/filters";

/**
 * Transforms object with dot notation properties { "foo.bar" } into object based { foo: { bar } }
 */
function unflattenObject(obj: Record<string, any>): Record<string, any> {
  const result: Record<string, any> = {};

  for (const key in obj) {
    set(result, key, obj[key]);
  }

  return result;
}

export const getFlagFilters = (filters: AssuranceFilterData) => {
  const objectFilters = unflattenObject(filters);

  const flagFilters = [];

  for (const key in objectFilters) {
    if (!objectFilters[key]) continue;

    const { flag } = objectFilters[key];

    if (flag) {
      flagFilters.push({
        type: key,
        value: flag,
      });
    }
  }

  return flagFilters;
};

export const getValueFilters = (filters: AssuranceFilterData) => {
  const objectFilters = unflattenObject(filters);

  const valueFilters = [];

  for (const key in objectFilters) {
    if (!objectFilters[key]) continue;

    const { value } = objectFilters[key];

    /**
     * value can be a single value or an object with { min, max }
s    * skip when it's undefined or all values in the object are undefined
     */
    if (
      value === undefined ||
      (typeof value === "object" &&
        Object.values(value).every(v => v === undefined))
    ) {
      continue;
    }

    valueFilters.push({
      type: key,
      value: value,
    });
  }

  return valueFilters;
};
