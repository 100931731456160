import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import {
  InputField,
  PhoneNumberInputField,
  SelectField,
  formatParsePhoneNumber,
  getPhoneNumberPrefix,
} from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import Card from "~components/card";
import { useCountries } from "~features/countries";
import { User, useUpdateCompanyMutation } from "~features/user";

const formSchema = z.object({
  name: z.string().nonempty(),
  vatNumber: z
    .string()
    .nonempty()
    .regex(/^[A-Za-z0-9]*$/, "Invalid VAT number"),
  registrationNumber: z.string().optional(),
  address: z
    .object({
      address: z.string().nonempty(),
      city: z.string().nonempty(),
      zipCode: z.string().nonempty(),
      countryCode: z.string().nonempty(),
    })
    .required(),
  contractSignerFirstName: z.string().nonempty(),
  contractSignerLastName: z.string().nonempty(),
  contractSignerEmail: z.string().nonempty().email(),
  contractSignerPhoneNumber: z.string().nonempty(),
  contractSignerCountryCode: z.string().nonempty(),
});

export type FormValues = z.infer<typeof formSchema>;

type Props = {
  user: User;
};

export const UserEditCompanyForm = ({ user }: Props) => {
  const { data: countries } = useCountries();

  const updateCompanyMutation = useUpdateCompanyMutation();

  const parsedPhoneNumber = formatParsePhoneNumber(
    user.company?.contractSignerPhoneNumber ?? "",
  );

  const {
    control,
    register,
    formState,
    handleSubmit: handleFormSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: user.company?.name,
      vatNumber: user.company?.vatNumber,
      registrationNumber: user.company?.registrationNumber ?? undefined,
      address: {
        address: user.company?.address.address,
        city: user.company?.address.city,
        zipCode: user.company?.address.zipCode,
        countryCode: user.company?.address.countryCode,
      },
      contractSignerFirstName:
        user.company?.contractSignerFirstName ?? undefined,
      contractSignerLastName: user.company?.contractSignerLastName ?? undefined,
      contractSignerEmail: user.company?.contractSignerEmail ?? undefined,
      contractSignerPhoneNumber: parsedPhoneNumber?.nationalNumber,
      contractSignerCountryCode: parsedPhoneNumber?.country,
    },
  });

  const handleSubmit = (data: FormValues) => {
    const phonePrefix = getPhoneNumberPrefix(data.contractSignerCountryCode);

    updateCompanyMutation.mutate(
      {
        ...data,
        registrationNumber: data.registrationNumber
          ? data.registrationNumber
          : undefined,
        contractSignerPhoneNumber: `+${phonePrefix}${data.contractSignerPhoneNumber}`,
        userId: user.id,
      },
      {
        onSuccess: () => {
          ToastNotification.success("Company updated successfully");
        },
      },
    );
  };

  const countryOptions = useMemo(
    () =>
      countries?.map(({ code, name }) => ({
        label: name,
        value: code,
      })) ?? [],
    [countries],
  );

  const { errors } = formState;

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <div className="flex flex-col items-center justify-start gap-1">
        <Card.Root className="mb-4">
          <Card.Title>Company</Card.Title>

          <InputField
            {...register("name")}
            label="Company name"
            error={errors.name}
          />

          <InputField
            label="VAT number"
            {...register("vatNumber")}
            error={errors.vatNumber}
          />

          <InputField
            label="Registration number"
            {...register("registrationNumber")}
            error={errors.registrationNumber}
          />

          <Controller
            name="address.countryCode"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                {...fieldState}
                label="Country"
                error={errors.address?.countryCode}
              >
                {Object.entries(countryOptions).map(([key, value]) => (
                  <Select.Option key={key} value={value.value}>
                    {value.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <InputField
            label="Address"
            {...register("address.address")}
            error={errors.address?.address}
          />

          <InputField
            label="City"
            {...register("address.city")}
            error={errors.address?.city}
          />

          <InputField
            label="Zip code"
            {...register("address.zipCode")}
            error={errors.address?.zipCode}
          />

          <InputField
            label="Contract signer first name"
            {...register("contractSignerFirstName")}
            error={errors.contractSignerFirstName}
          />

          <InputField
            label="Contract signer last name"
            {...register("contractSignerLastName")}
            error={errors.contractSignerLastName}
          />

          <InputField
            label="Contract signer email"
            {...register("contractSignerEmail")}
            error={errors.contractSignerEmail}
          />

          <PhoneNumberInputField
            control={control}
            label="Contract signer phone number"
            countryCodeInputName="contractSignerCountryCode"
            phoneNumberInputName="contractSignerPhoneNumber"
            {...register("contractSignerPhoneNumber")}
          />
        </Card.Root>

        <Button
          type="submit"
          className="self-end"
          isLoading={updateCompanyMutation.isLoading}
        >
          Save
        </Button>
      </div>
    </form>
  );
};
