import { cn } from "@ag/design-system/utils";

export const FlagCountBadge = ({
  variant,
  countValue,
}: {
  variant: "yellow" | "red" | "follow-up";
  countValue: number;
}) => {
  return (
    <div
      className={cn(
        "rounded-full px-3 py-[3px] text-p3",
        variant === "red" && "bg-messaging-error-100 text-messaging-error-900",
        variant === "yellow" &&
          "bg-messaging-warning-100 text-messaging-warning-900",
        variant === "follow-up" && "bg-data-purple-200 text-data-purple-900",
      )}
    >
      {countValue}
    </div>
  );
};
