import * as Sentry from "@sentry/browser";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

import packageJson from "../../package.json";
import { env } from "./env";

export const addCurrentAdminToSentry = (currentAdmin: any) => {
  Sentry.configureScope(scope => {
    scope.setUser(currentAdmin);
  });
};

export const setupSentry = () => {
  if (!env.REACT_APP_SENTRY_DSN) return;

  Sentry.init({
    dsn: env.REACT_APP_SENTRY_DSN,

    // Don't report chunk loading errors as there is a custom error page for them
    ignoreErrors: ["ChunkLoadError"],

    /** Performance tracking */
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,

    /** Release errors tracking */
    release:
      env.REACT_APP_APP_ENVIRONMENT === "production"
        ? packageJson.version
        : undefined,

    environment: env.REACT_APP_APP_ENVIRONMENT,
  });
};
