import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { CertificatesValidation } from "../entities/certificates-validation";

type ValidatePayload = {
  certificateIds: string[];
  updateRequestId: string;
};

const validateCertificates = async (payload: ValidatePayload) => {
  const { certificateIds, updateRequestId } = payload;

  const csvBlob = new Blob([certificateIds.join(",")], { type: "text/csv" });
  const formData = new FormData();
  formData.append("file", csvBlob, "data.csv");

  return axios.post<CommonResponse<CertificatesValidation>>(
    `/api/v1/certificates/groups/update-requests/${updateRequestId}/validate`,
    formData,
    {
      apiSource: "node-markets",
    },
  );
};

export const useValidateCertificatesMutation = () =>
  useMutation(validateCertificates, {
    onError: error => ToastNotification.error(error),
  });
