import React from "react";

/**
 *
 * Using forwardRef in pair with generics can cause problems in react up to 17,
 * so we need to use this helper function to make it work.
 * This problem is fixed in react 18.
 */
export function fixedForwardRef<T, P = Record<string, never>>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement,
): (props: P & React.RefAttributes<T>) => React.ReactElement {
  return React.forwardRef(render) as any;
}
