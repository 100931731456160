import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateFieldQueryKey } from "./get-field";

export type UnlockFieldDefinitionPayload = {
  fieldId: string;
  fieldDefinitionId: string;
};

export const unlockFieldDefinition = async ({
  fieldId,
  fieldDefinitionId,
}: UnlockFieldDefinitionPayload) => {
  await axios.post(
    `/api/v1/carbon-fields/${fieldId}/definitions/${fieldDefinitionId}/unlock`,
    {},
    {
      apiSource: "node-carbon",
    },
  );
};

export const useUnlockFieldDefinitionMutation = () =>
  useMutation(unlockFieldDefinition, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateFieldQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
