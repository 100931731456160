import omit from "lodash/omit";

import { CropType } from "~queries/carbon/crop-types";

export const getInitialValues = (cropType: CropType | undefined) => {
  if (!cropType) return;

  // Omit values that shouldn't be sent back to API
  return omit(cropType, ["createdAt", "updatedAt"]);
};
