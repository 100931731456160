import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Select } from "@ag/design-system/molecules";
import { FormField } from "@ag/design-system/organisms";

type Props = {
  id?: string;
  value?: string | null;
  name?: string;
  children: React.ReactNode;
  optionsClassName?: string;
  label?: string;
  tooltip?: string;
  placeholder?: string;
  variant?: "default" | "full-width" | "compact";
  error?: Partial<FieldError>;
  isDisabled?: boolean;
  isRequired?: boolean;
  testId?: string;
  onChange?: (value: string) => void;
};

// forwardRef is used to allow spreading the field prop (that includes ref) from the form context in the consumer
export const SelectField = forwardRef<HTMLButtonElement, Props>(
  (
    {
      id,
      label,
      value,
      tooltip,
      placeholder,
      testId,
      children,
      error,
      isDisabled,
      isRequired,
      optionsClassName,
      onChange,
      ...rest
    },
    ref,
  ) => {
    const labelId = id || rest.name;
    return (
      <FormField.Root>
        {label && (
          <FormField.Label tooltip={tooltip} as="label" id={labelId}>
            {label}
            {isRequired && " *"}
          </FormField.Label>
        )}

        <Select.Root
          aria-labelledby={labelId}
          value={value}
          placeholder={placeholder}
          optionsClassName={optionsClassName}
          isDisabled={isDisabled}
          isInvalid={Boolean(error)}
          onChange={onChange}
          testId={testId}
          labelledBy={labelId}
          ref={ref}
          {...rest}
        >
          {children}
        </Select.Root>

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

SelectField.displayName = "SelectField";
