import { PartialsSkeleton } from "@ag/carbon/components";

import { useYearsDataQuery } from "~features/initial-resources";
import { FieldDetails } from "~queries/carbon/fields";

import AdditionalInformation from "./partials/AdditionalInformation";
import HistoryOfDeforestation from "./partials/HistoryOfDeforestation";
import SoilCharacteristics from "./partials/SoilCharacteristics";
import SoilCultivationPractice from "./partials/SoilCultivationPractice";
import { FieldDefinitionPartial } from "./types";

const partials = {
  [FieldDefinitionPartial.SoilCharacteristics]: SoilCharacteristics,
  [FieldDefinitionPartial.HistoryOfDeforestation]: HistoryOfDeforestation,
  [FieldDefinitionPartial.SoilCultivationPractice]: SoilCultivationPractice,
  [FieldDefinitionPartial.AdditionalInformation]: AdditionalInformation,
};

type Props = {
  field: FieldDetails;
};

const FieldPartials = ({ field }: Props) => {
  const { data: yearsData, isLoading: isYearsDataLoading } =
    useYearsDataQuery();

  const partialsNames = Object.values(FieldDefinitionPartial);

  if (isYearsDataLoading) {
    return <PartialsSkeleton numberOfPartials={partialsNames.length} />;
  }

  if (!yearsData) return null;

  return (
    <div>
      {partialsNames.map(partialName => {
        const PartialComponent = partials[partialName];

        return <PartialComponent key={partialName} field={field} />;
      })}
    </div>
  );
};

export default FieldPartials;
