import { colors } from "@ag/design-system/tokens";
import { MAP_STYLES } from "@ag/map/constants";

export const MAP_OPTIONS: google.maps.MapOptions = {
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: false,
  styles: [
    ...MAP_STYLES,
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: colors.white,
        },
      ],
    },
  ],
  gestureHandling: "greedy",
  controlSize: 18,
  mapTypeId: "hybrid",
};
