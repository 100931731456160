import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { APIResult } from "@ag/utils/types";

import {
  FieldActualsChange,
  FieldActualsChangeScema,
} from "../entities/field-actuals-change";

type GetFieldActualsChangesParams = {
  fieldId: number | string | undefined;
  fieldActualsId: number | string | undefined;
};

export const generateFieldActualsChangesQueryKey = (
  params?: GetFieldActualsChangesParams | undefined,
) => ["fields", "actuals-changes", params].filter(Boolean);

const getFieldActualsChanges = async ({
  fieldId,
  fieldActualsId,
}: GetFieldActualsChangesParams) => {
  const response = await axios.get<APIResult<FieldActualsChange[]>>(
    `/api/farmer/carbon/v1/admin/carbon_fields/${fieldId}/carbon_field_actuals/${fieldActualsId}/changes`,
    {
      apiSource: "ruby",
      schema: z.array(FieldActualsChangeScema),
    },
  );

  return response.data.result.data;
};

export const useFieldActualsChangesQuery = (
  params: GetFieldActualsChangesParams,
  options?: {
    enabled: boolean;
  },
) =>
  useQuery(
    generateFieldActualsChangesQueryKey(params),
    () => getFieldActualsChanges(params),
    {
      ...options,
    },
  );
