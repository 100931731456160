import log from "loglevel";
import { useCallback, useState } from "react";

export function useLocalStorage<T>(key: string, getInitialValue: () => T) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : getInitialValue();
    } catch (error) {
      log.error(error);

      return getInitialValue();
    }
  });

  const setValue = useCallback(
    (value: T) => {
      try {
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        log.error(error);
      }
    },
    [key, storedValue],
  );

  return [storedValue, setValue];
}
