import { colors } from "@ag/design-system/tokens";

import {
  DEFAULT_MAP_OPTIONS,
  DEFAULT_POLYGON_OPTIONS,
} from "~constants/google-maps";

export const DEFAULT_ZOOM = 14;

export const MAP_OPTIONS: google.maps.MapOptions = {
  ...DEFAULT_MAP_OPTIONS,
  zoomControl: true,
  controlSize: 24,
  maxZoom: 20,
  minZoom: 5,
};

export const POLYGON_OPTIONS = {
  ...DEFAULT_POLYGON_OPTIONS,
  editable: true,
};

export const DRAW_MANAGER_OPTIONS: google.maps.drawing.DrawingManagerOptions = {
  drawingControl: false,
  polygonOptions: {
    ...POLYGON_OPTIONS,
    fillColor: colors.red[300],
    strokeColor: colors.red[300],
  },
};

export const SELECTION_RECTANGLE_OPTIONS: google.maps.RectangleOptions = {
  fillOpacity: 0.1,
  strokeWeight: 1,
  clickable: false,
  editable: true,
  draggable: true,
  zIndex: 1,
};

export const RESHAPE_POLYLINE_OPTIONS: google.maps.PolylineOptions = {
  strokeWeight: 2,
  clickable: false,
  editable: true,
  draggable: true,
  zIndex: 1,
};
