import { TableMeta } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  UseListOptions,
  useList as useListDS,
} from "@ag/design-system/organisms";

export function useList<T>(options: UseListOptions<T>) {
  const texts: TableMeta<unknown>["texts"] = useMemo(
    () => ({
      close: "Close",
      copiedToClipboard: "Copied to clipboard",
      listOfAllValues: "List of all values",
      more: "More",
      yes: "Yes",
      no: "No",
    }),
    [],
  );

  return useListDS({
    ...options,
    meta: {
      ...options.meta,
      texts: options.meta?.texts ?? texts,
    },
  });
}
