import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import { CommonPaginatedResponse, MetaArgs } from "@ag/utils/types";

import { FieldValidationStatus } from "~features/field";

import {
  ValidationRequestField,
  ValidationRequestFieldSchema,
} from "../entities/validation-request-field";

type GetValidationRequestFieldsParams = MetaArgs & {
  filters?: Partial<{
    id: number | string;
    carbonFarmId: number | string;
    fieldName: string;
    validationStatus: FieldValidationStatus;
  }>;
};

export const generateValidationRequestFieldsQueryKey = (
  validationRequestId: string,
  params?: GetValidationRequestFieldsParams | undefined,
) => ["validation-request-fields", validationRequestId, params].filter(Boolean);

const getValidationRequestFields = async (
  validationRequestId: string,
  params: GetValidationRequestFieldsParams | undefined,
) => {
  const response = await axios.get<
    CommonPaginatedResponse<ValidationRequestField>
  >(`/api/v1/carbon-validation-requests/${validationRequestId}/carbon-fields`, {
    params: {
      ...params,
      limit: params?.limit || DEFAULT_LIMIT,
    },
    apiSource: "node-carbon",
    schema: z.array(ValidationRequestFieldSchema),
  });

  return response.data.success!.data;
};

export const useValidationRequestFieldsQuery = (
  validationRequestId: string,
  params?: GetValidationRequestFieldsParams,
) =>
  useQuery(
    generateValidationRequestFieldsQueryKey(validationRequestId, params),
    () => getValidationRequestFields(validationRequestId, params),
    {
      keepPreviousData: true,
    },
  );
