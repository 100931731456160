import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { DeepPartial } from "react-hook-form";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { SalesOfferDetails } from "../entities/sales-offer-details";
import { generateSalesOfferDetailsQueryKey } from "./get-sales-offer-details";

const updateSalesOfferDetails = async ({
  id,
  data,
}: {
  id: string;
  data: DeepPartial<SalesOfferDetails>;
}) => {
  await axios.patch(`/api/v1/offers/${id}`, data, {
    apiSource: "node-carbon",
  });
};

export const useUpdateSalesOfferDetailsMutation = () =>
  useMutation(updateSalesOfferDetails, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        generateSalesOfferDetailsQueryKey(variables.id),
      );
    },
    onError: error => ToastNotification.error(error),
  });
