import {
  BoundaryStatuses,
  FieldWithAllBoundaries,
} from "../entities/field-with-all-boundaries";
import { BoundaryMapField } from "../types/boundary-map-field";

export const getIsFieldUnableToComputeResults = (
  field: BoundaryMapField | FieldWithAllBoundaries,
): boolean => {
  if (
    field.boundaryStatus &&
    (field.boundaryStatus.status ===
      BoundaryStatuses.HAS_FARMER_BOUNDARY_ONLY ||
      field.boundaryStatus.status === BoundaryStatuses.INCOMPLETE_CHECK_RESULTS)
  ) {
    return true;
  }
  return false;
};
