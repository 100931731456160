import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useCallback } from "react";

import {
  CommonPaginatedResponse,
  CommonResponse,
  MetaArgs,
} from "@ag/utils/types";

import { ALL_AVAILABLE_ITEMS_NUMBER } from "~constants/params";

import { ApplicationSetting, ApplicationSettingsResponse } from "./types";

export const applicationSettingsKeys = {
  all: ["application-settings"] as const,

  listAll: () => [...applicationSettingsKeys.all, "list"] as const,
  list: (metaArgs: MetaArgs) =>
    [...applicationSettingsKeys.listAll(), { metaArgs }] as const,

  detailsAll: () => [...applicationSettingsKeys.all, "details"] as const,
  details: (applicationSettingId: number) =>
    [...applicationSettingsKeys.detailsAll(), applicationSettingId] as const,
};

/**
 * CUSTOM QUERY FUNCTIONS
 */
const fetchApplicationSettings = async (
  metaArgs: MetaArgs,
): Promise<ApplicationSettingsResponse> => {
  const response = await axios.get<CommonPaginatedResponse<ApplicationSetting>>(
    "/api/v1/admin/application-settings",
    {
      params: {
        page: metaArgs.page,
        limit: 100,
        sort: { id: "desc" },
        includeMeta: true,
      },
      apiSource: "node-carbon",
    },
  );

  const { items: applicationSettings, meta } = response.data.success!.data;

  return {
    applicationSettings,
    meta,
  };
};

const fetchApplicationSetting = async (applicationSettingId: number) => {
  const response = await axios.get<CommonResponse<ApplicationSetting>>(
    `/api/v1/admin/application-settings/${applicationSettingId}`,
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

/**
 * QUERY HOOKS
 */
export const useApplicationSettingsQuery = (metaArgs: MetaArgs) =>
  useQuery(
    applicationSettingsKeys.list(metaArgs),
    () => fetchApplicationSettings(metaArgs),
    {
      keepPreviousData: true,
    },
  );

export const useApplicationSettingQuery = (applicationSettingId: number) =>
  useQuery(applicationSettingsKeys.details(applicationSettingId), () =>
    fetchApplicationSetting(applicationSettingId),
  );

export const useApplicationSettingQueryByName = (settingName: string) => {
  const metaArgs = {
    page: 1,
    limit: ALL_AVAILABLE_ITEMS_NUMBER,
  };

  return useQuery(
    applicationSettingsKeys.list(metaArgs),
    () => fetchApplicationSettings(metaArgs),
    {
      select: useCallback(
        ({ applicationSettings }: ApplicationSettingsResponse) => {
          const applicationSetting = applicationSettings.find(
            applicationSetting => applicationSetting.name === settingName,
          );

          return applicationSetting?.value;
        },
        [settingName],
      ),
      keepPreviousData: true,
    },
  );
};
