import { z } from "zod";

import { HistoryOfDeforestationDataSchema } from "./schemas";

export enum HistoryOfDeforestationAttribute {
  SoilMgmtHistoricConversion = "soilMgmtHistoricConversion",
}

export type HistoryOfDeforestationData = z.infer<
  typeof HistoryOfDeforestationDataSchema
>;
