import { Chip } from "@ag/design-system/atoms";

type ContractStepProps = React.PropsWithChildrenRequired<{
  title: string;
  description: string;
  completed: boolean;
}>;

const ContractStep = ({
  title,
  description,
  children,
  completed,
}: ContractStepProps) => (
  <div className="flex items-end justify-between gap-2">
    <div className="">
      <div className="mb-1 flex items-center gap-1">
        <p className="text-p1 font-bold">{title}</p>

        {!completed && (
          <Chip size="small" variant="warning" data-testid="incomplete-chip">
            Incomplete
          </Chip>
        )}
      </div>

      <span className="text-p2 text-grey-700">{description}</span>
    </div>

    <div>{children}</div>
  </div>
);

export default ContractStep;
