import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

import { useSessionContext } from "~features/auth";

import {
  UserManagementPermissions,
  UserManagementPermissionsSchema,
} from "../entities/user-management-permissions";

const getUserManagementPermissions = async () => {
  const { data } = await axios.get<
    CommonResponse<{
      permissions: UserManagementPermissions;
    }>
  >("/api/v1/admins/permissions/me", {
    schema: z.object({
      permissions: UserManagementPermissionsSchema,
    }),
    apiSource: "node-user-mgt",
  });

  return data.success!.data.permissions;
};

export const useUserManagementPermissions = () => {
  const { isSignedIn } = useSessionContext();

  return useQuery(
    ["user-management-permissions"],
    getUserManagementPermissions,
    {
      enabled: isSignedIn,
      staleTime: Infinity,
      retry: false,
    },
  );
};
