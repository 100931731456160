import styled from "@emotion/styled";

import { Spinner } from "@ag/design-system/assets";

import StatusIcon from "./StatusIcon";

export const SFieldStepTabTitle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const STitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SStatusIcon = styled(StatusIcon)`
  margin-bottom: 3px;
`;

export const SSpinner = styled(Spinner)`
  width: 16px;
  margin-right: 8px;
`;
