import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

import {
  FieldWithAllBoundaries,
  FieldWithAllBoundariesSchema,
} from "../entities/field-with-all-boundaries";

type NearbyFieldBoundariesParams = {
  lonlat?: { lat: number; lng: number };
  carbonFieldId?: string;
  farmId?: string;
};

export const generateNearbyFieldBoundariesQueryKey = (
  params?: NearbyFieldBoundariesParams,
) => ["nearby-field-boundaries", params].filter(Boolean);

export const getNearbyFieldBoundaries = async (
  params: NearbyFieldBoundariesParams,
) => {
  const response = await axios.get<CommonResponse<FieldWithAllBoundaries[]>>(
    `/api/v1/field-boundaries/nearby`,
    {
      params,
      apiSource: "node-carbon",
      schema: z.array(FieldWithAllBoundariesSchema),
    },
  );
  return response.data.success!.data;
};

export const useNearbyFieldBoundariesQuery = (
  params: NearbyFieldBoundariesParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateNearbyFieldBoundariesQueryKey(params),
    () => getNearbyFieldBoundaries(params),
    options,
  );
