import { z } from "zod";

export const UserActivitySchema = z.object({
  id: z.string(),
  key: z.string(),
  parameters: z.string().nullable(),
  data: z.record(z.any()).nullable(),
  trackableType: z.string(),
  trackableId: z.string(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export type UserActivity = z.infer<typeof UserActivitySchema>;
