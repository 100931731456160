import { format } from "date-fns";

import { BiomassFormData } from "./components/biomass-form";
import { CropCountryPropertiesFormData } from "./components/crop-countries-properties-form";
import { CropCountryProperties } from "./entities/crop-country-details";
import { BiomassProperties } from "./entities/crop-details";

export const mapBiomassPropertiesToForm = (
  properties: Partial<BiomassProperties>,
) => ({
  nFix: properties["n-fix"],
  nContentAboveGround:
    properties["N content of above-ground residues (N AG(T))"],
  nAGUncertainty: properties["N-AG uncertainty"],
  nContentBelowGround:
    properties["N content of below-ground residues (N BG(T))"],
  nBGUncertainty: properties["N-BG uncertainty"],
  ragT: properties[
    "Ratio of above-ground residue dry matter to harvested yield (RAG (T))"
  ],
  rsT: properties[
    "Ratio of below-ground biomass to above-ground biomass (RS (T))"
  ],
  ratioUncertainty: properties["ratio uncertainty"],
  dry: properties["Dry matter fraction of harvested product (DRY)"],
  slopeT: properties["Slope (T)"],
  slopeSD: properties["slope ± 2 s.d. as % of mean"],
  interceptT: properties["Intercept (T)"],
  interceptSD: properties["Intercept ± 2 s.d. as % of mean"],
  r2: properties["R2 adj."],
});

export const mapBiomassDataToProperties = (data: BiomassFormData) => ({
  "n-fix": data.nFix,
  "N content of above-ground residues (N AG(T))": data.nContentAboveGround,
  "N-AG uncertainty": data.nAGUncertainty,
  "N content of below-ground residues (N BG(T))": data.nContentBelowGround,
  "N-BG uncertainty": data.nBGUncertainty,
  "Ratio of above-ground residue dry matter to harvested yield (RAG (T))":
    data.ragT,
  "Ratio of below-ground biomass to above-ground biomass (RS (T))": data.rsT,
  "ratio uncertainty": data.ratioUncertainty,
  "Dry matter fraction of harvested product (DRY)": data.dry,
  "Slope (T)": data.slopeT,
  "slope ± 2 s.d. as % of mean": data.slopeSD,
  "Intercept (T)": data.interceptT,
  "Intercept ± 2 s.d. as % of mean": data.interceptSD,
  "R2 adj.": data.r2,
});

export const DATE_FORMAT = "yyyy-MM-dd";
const TIME_APPENDAGE = "T00:00:00";

export const formatCropCountryProperties = (
  properties: CropCountryProperties,
) => ({
  seeding_period_start: format(
    new Date(properties.seeding_period_start),
    DATE_FORMAT,
  ),
  seeding_period_end: format(
    new Date(properties.seeding_period_end),
    DATE_FORMAT,
  ),
  harvest_period_start: format(
    new Date(properties.harvest_period_start),
    DATE_FORMAT,
  ),
  harvest_period_end: format(
    new Date(properties.harvest_period_end),
    DATE_FORMAT,
  ),
  yield_max: properties["yield max"],
  yield_min: properties["yield min"],
  yield_avg: properties["yield avg"],
});

export const reverseFormatCropCountryProperties = (
  properties: CropCountryPropertiesFormData,
) => ({
  seeding_period_start: properties.seeding_period_start + TIME_APPENDAGE,
  seeding_period_end: properties.seeding_period_end + TIME_APPENDAGE,
  harvest_period_start: properties.harvest_period_start + TIME_APPENDAGE,
  harvest_period_end: properties.harvest_period_end + TIME_APPENDAGE,
  "yield max": properties.yield_max,
  "yield min": properties.yield_min,
  "yield avg": properties.yield_avg,
});
