import { useNavigate } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import { Page, withPageAccess } from "~features/permission";
import ListLayout from "~layouts/list-layout";

const ImportVcu = () => {
  const navigate = useNavigate();

  const handleVcuTypeSelection = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    const vcuType = event.currentTarget.textContent?.toLocaleLowerCase();

    navigate(`/certificates-management/import/${vcuType}`);
  };

  return (
    <>
      <ListLayout.Root>
        <ListLayout.TopBar>
          <BackButton />

          <ListLayout.TopBarTitle>Import and distribute</ListLayout.TopBarTitle>
        </ListLayout.TopBar>

        <ListLayout.Sidebar>
          <AuthorizedSidebar />
        </ListLayout.Sidebar>

        <ListLayout.Content>
          <div className="flex w-full flex-auto flex-col gap-8 bg-grey-100 pt-8">
            <div className="flex flex-col items-center justify-center gap-12 pb-12">
              <h2 className="text-h2 font-bold">
                Select the type of VCUs to import
              </h2>

              <div className="flex h-[352px] w-[890px] flex-col items-center justify-center gap-4 rounded-md border border-dashed border-accent-200 bg-grey-100 p-12">
                <Button
                  variant="secondary"
                  className="max-h-[48px] w-full max-w-[200px]"
                  onClick={handleVcuTypeSelection}
                >
                  Verra
                </Button>
                <Button
                  variant="secondary"
                  className="max-h-[48px] w-full max-w-[200px]"
                  onClick={handleVcuTypeSelection}
                >
                  ISO
                </Button>
              </div>
            </div>
          </div>
        </ListLayout.Content>
      </ListLayout.Root>
    </>
  );
};

export default withPageAccess(Page.ImportVcu)(ImportVcu);
