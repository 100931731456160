import { RowSelectionState } from "@tanstack/react-table";
import { uniq } from "lodash";
import { useCallback, useMemo, useState } from "react";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  createEnumParam,
  useQueryParam,
} from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { Button, IconButton, InfoBox } from "@ag/design-system/atoms";
import { ButtonSelect, FloatingMenu } from "@ag/design-system/molecules";
import { TableRowError } from "@ag/design-system/organisms";
import { cluster, stack } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { getSearchParams } from "@ag/utils/helpers";
import { usePagination } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import BackButton from "~components/BackButton";
import { SideSheet } from "~components/SideSheet";
import {
  TableSettings,
  useStoredTableSettings,
} from "~components/TableSettings";
import { Filters } from "~components/filters";
import Table from "~components/table";
import { queryClient } from "~config";
import { useCarbonCountries } from "~features/countries";
import { QualityAssuranceStatus } from "~features/field";
import { useYearsDataQuery } from "~features/initial-resources";
import { AuthorizedSidebar } from "~features/navigation";
import { useCarbonPermissions } from "~features/permission";
import {
  FieldActualsChangesModal,
  FieldCommentsModal,
  FieldsFilters,
  INITIAL_QUALITY_CONTROL_TABLE_STATE,
  QualityControlCategory,
  QualityControlCheckKey,
  QualityControlFilterData,
  QualityControlStatus,
  QualityControlTableData,
  addFieldQualityControlComments,
  changeFieldsStatus,
  generateFieldsQueryKey,
  getFlagFilters,
  getQualityControlFilterLabel,
  getQualityControlFilterValueLabel,
  getValueFilters,
  runFieldsQualityControl,
  useFieldModal,
  useFieldsBulkActions,
  useFieldsBulkModal,
  useFieldsQuery,
  useFieldsTable,
} from "~features/quality-control";
import ListLayout from "~layouts/list-layout";
import { useApplicationSettingQueryByName } from "~queries/application-settings";

const filtersConfig = {
  userId: [StringParam, { isDebounced: true }],
  id: [StringParam, { isDebounced: true }],
  countryId: StringParam,
  qaStatus: createEnumParam([...Object.values(QualityAssuranceStatus), "none"]),
  qcStatus: createEnumParam([...Object.values(QualityControlStatus), "none"]),
  fallow: StringParam,
  "sizeHa.min": [StringParam, { isDebounced: true }],
  "sizeHa.max": [StringParam, { isDebounced: true }],

  // Fertiliser
  "fertiliser.flag": ArrayParam,
  "fertiliser.value.category": StringParam,
  "fertiliser.value.range.min": StringParam,
  "fertiliser.value.range.max": StringParam,

  // Crop
  "crop.flag": ArrayParam,
  "crop.value.category": StringParam,
  "crop.value.identifier": StringParam,
  "crop.value.range.min": StringParam,
  "crop.value.range.max": StringParam,

  // Soil disturbance
  "soil_disturbance.flag": ArrayParam,
  "soil_disturbance.value": StringParam,

  // Residue management
  "residue_management.flag": ArrayParam,
  "residue_management.value": StringParam,

  // Cover crop
  "cover_crop.flag": ArrayParam,
  "cover_crop.value": StringParam,

  // Fuel
  "fuel.flag": ArrayParam,
  "fuel.value.range.min": StringParam,
  "fuel.value.range.max": StringParam,
} as const;

// TODO: get this from settings
const FIELD_ACTUALS_YEAR = 2023;

const QualityControl = () => {
  const [pagination, updatePagination, resetPagination] = usePagination({
    defaultLimit: 100,
  });
  const { data: carbonPermissions } = useCarbonPermissions();
  const { data: carbonCountries } = useCarbonCountries();

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const filters = useFilters(filtersConfig);

  const commentsModal = useFieldsBulkModal();
  const actualsChangesModal = useFieldModal();

  const [tableState, setTableState] = useStoredTableSettings(
    "quality-control",
    INITIAL_QUALITY_CONTROL_TABLE_STATE,
  );

  const { data: carbonQualityControlYear } = useApplicationSettingQueryByName(
    "carbon_verification_year",
  );

  const { data: yearsData } = useYearsDataQuery();

  const actualsYears = useMemo(() => {
    const allYears = [
      ...(yearsData?.carbonActiveHarvestYears ?? []),
      FIELD_ACTUALS_YEAR,
    ];

    return uniq(allYears.sort());
  }, [yearsData?.carbonActiveHarvestYears]);

  const [harvestYear, setHarvestYear] = useQueryParam(
    "harvestYear",
    NumberParam,
  );

  const flagFilters = getFlagFilters(filters.values);
  const valueFilters = getValueFilters(filters.values);

  const store = useFieldsBulkActions();

  const { data: fieldsData, isLoading } = useFieldsQuery({
    ...pagination,
    harvestYear: harvestYear ?? FIELD_ACTUALS_YEAR,
    filters: {
      userId: filters.values.userId ? [filters.values.userId] : undefined,
      id: filters.values.id ? [filters.values.id] : undefined,
      carbonCountryId: filters.values.countryId
        ? [filters.values.countryId]
        : undefined,
      qaStatus: filters.values.qaStatus,
      qcStatus: filters.values.qcStatus,
      isFallow: filters.values.fallow
        ? filters.values.fallow === "true"
        : undefined,
      sizeHa: {
        min: filters.values["sizeHa.min"],
        max: filters.values["sizeHa.max"],
      },
    },
    dynamicFilters: {
      flag: flagFilters,
      value: valueFilters,
    },
  });

  const bulkActions = useFieldsBulkActions();

  const handleRun = useCallback(
    async (params: { fieldId: number }) => {
      try {
        await runFieldsQualityControl({
          year: harvestYear ?? FIELD_ACTUALS_YEAR,
          fieldIds: [params.fieldId],
        });

        queryClient.invalidateQueries(generateFieldsQueryKey());
        ToastNotification.success("Quality control has been run");
      } catch (err) {
        ToastNotification.error(err);
      }
    },
    [harvestYear],
  );

  const handleChangeStatus = useCallback(
    async (params: { fieldId: number; status: QualityControlStatus }) => {
      try {
        await changeFieldsStatus({
          fieldIds: [String(params.fieldId)],
          payload: {
            newStatus: params.status,
          },
        });
        queryClient.invalidateQueries(generateFieldsQueryKey());
        ToastNotification.success(`Status changed to: ${params.status}`);
      } catch (err) {
        ToastNotification.error(err);
      }
    },
    [],
  );

  const handleFieldActualsUpdate = useCallback(
    async (params: { fieldId: number; harvestYear: number }) => {
      const { fieldId } = params;

      const url =
        `/carbon/fields/${fieldId}` +
        getSearchParams({
          "harvest-year": params.harvestYear,
          step: "actuals",
        });

      window.open(url, "_blank");
    },
    [],
  );

  const handleAddCommentToSingleField = useCallback(
    (params: { fieldId: number }) => {
      const field = fieldsData?.items.find(({ id }) => id === params.fieldId);
      if (!field) return;

      commentsModal.open([field]);
    },
    [fieldsData?.items, commentsModal],
  );

  const handleAddCommentsToFields = useCallback(
    (params: { fieldIds: string[] }) => {
      if (!fieldsData?.items) return;

      const fields = fieldsData.items.filter(field =>
        params.fieldIds.includes(field.id.toString()),
      );

      if (fields.length === 0) return;

      commentsModal.open(fields);
    },
    [fieldsData?.items, commentsModal],
  );

  const handleShowActualsChanges = useCallback(
    (params: { fieldId: number }) => {
      const field = fieldsData?.items.find(({ id }) => id === params.fieldId);
      if (!field) return;

      actualsChangesModal.open(field);
    },
    [fieldsData?.items, actualsChangesModal],
  );

  const handleCommentAdded = useCallback(
    async (comment: {
      category: QualityControlCategory;
      checks?: QualityControlCheckKey;
      text: string;
    }) => {
      if (!commentsModal.fields) return;

      store.startAction();

      const carbonFieldIds: string[] = commentsModal.fields.map(field =>
        field.carbonFieldActual.id.toString(),
      );

      const results = await addFieldQualityControlComments({
        actionPayload: comment,
        carbonFieldActualIds: carbonFieldIds,
      });

      const errorResults = results.filter(result => result.error);
      const successResults = results.filter(result => result.success);

      if (errorResults.length) {
        store.addResult({
          type: "error",
          message: I18n.t("js.admin.quality_control.list.add_comment_failed", {
            count: errorResults.length,
          }),
          errors: errorResults.map(result => ({
            id: Number(result.meta.carbonFieldId),
            message: result.error?.message ?? "",
          })),
        });
      }

      if (successResults.length) {
        store.addResult({
          type: "success",
          message: I18n.t("js.admin.quality_control.list.add_comment_success", {
            count: successResults.length,
          }),
        });
      }

      store.finishAction();

      commentsModal.close();
      queryClient.invalidateQueries(generateFieldsQueryKey());
    },
    [commentsModal, store],
  );

  const handleSelectionChange = useCallback(
    (
      selectionOrUpdater:
        | RowSelectionState
        | ((state: RowSelectionState) => RowSelectionState),
    ) => {
      const values =
        typeof selectionOrUpdater === "function"
          ? selectionOrUpdater(bulkActions.selection)
          : selectionOrUpdater;

      bulkActions.updateSelection(values);
    },
    [bulkActions],
  );

  const renderFilterBarItem = (
    key: keyof QualityControlFilterData,
    value: QualityControlFilterData[keyof QualityControlFilterData],
  ) => {
    const filterLabel = getQualityControlFilterLabel(key);
    const filterValueLabel = getQualityControlFilterValueLabel(key, value, {
      countries: carbonCountries ?? [],
    });

    return `${filterLabel}: ${filterValueLabel}`;
  };

  const tableErrors = useMemo(() => {
    const lookup: Record<string, TableRowError<QualityControlTableData>[]> = {};

    for (const result of bulkActions.results) {
      for (const error of result.errors ?? []) {
        const tableRowError = new TableRowError(error.message, "fieldId");
        if (!lookup[error.id]) {
          lookup[error.id] = [tableRowError];
        } else {
          lookup[error.id].push(tableRowError);
        }
      }
    }

    return lookup;
  }, [bulkActions.results]);

  const state = useMemo(
    () => ({
      ...tableState,
      rowSelection: bulkActions.selection,
    }),
    [tableState, bulkActions.selection],
  );

  const table = useFieldsTable({
    data: fieldsData?.items,
    carbonQualityControlYear,
    state,
    errors: tableErrors,
    permissions: carbonPermissions,
    onRun: handleRun,
    onChangeStatus: handleChangeStatus,
    onFieldActualsUpdate: handleFieldActualsUpdate,
    onAddComment: handleAddCommentToSingleField,
    onShowActualsChanges: handleShowActualsChanges,
    onSelectionChange: handleSelectionChange,
  });

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle>Quality Control</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />

        <SideSheet.Root
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
        >
          <SideSheet.Header>Filters</SideSheet.Header>

          <SideSheet.Content>
            <FieldsFilters
              countries={
                carbonCountries?.sort((a, b) => (a.name > b.name ? 1 : -1)) ??
                []
              }
              values={filters.values}
              onChange={(...args) => {
                filters.onChange(...args);
                resetPagination();
              }}
              onClear={filters.onClear}
            />
          </SideSheet.Content>

          <SideSheet.Footer>
            <Button variant="secondary" onClick={() => filters.onClear()}>
              {I18n.t("js.shared.clear_all")}
            </Button>
          </SideSheet.Footer>
        </SideSheet.Root>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <Filters.Bar
            {...filters}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
          />

          {/* Micro adjustment of -3px, bc. we don't have "small" ButtonSelect yet */}
          <div
            className={cluster({ align: "center" })}
            style={{ marginTop: -3 }}
          >
            <FloatingMenu.Root
              triggerSize="small"
              title={
                Object.keys(bulkActions.selection).length
                  ? I18n.t("js.admin.quality_control.list.select_action", {
                      count: Object.keys(bulkActions.selection).length,
                    })
                  : I18n.t(
                      "js.admin.quality_control.list.select_action_disabled",
                    )
              }
              isDisabled={!Object.keys(bulkActions.selection).length}
            >
              <FloatingMenu.Option
                onClick={() => {
                  handleAddCommentsToFields({
                    fieldIds: Object.keys(bulkActions.selection).map(String),
                  });
                }}
              >
                Add comment
              </FloatingMenu.Option>
              <FloatingMenu.Option
                onClick={() =>
                  bulkActions.changeStatus(QualityControlStatus.Processing)
                }
              >
                {I18n.t(
                  "js.admin.quality_control.list.change_status_processing",
                )}
              </FloatingMenu.Option>

              <FloatingMenu.Option
                isDanger
                onClick={() =>
                  bulkActions.changeStatus(QualityControlStatus.NonConformance)
                }
              >
                {I18n.t(
                  "js.admin.quality_control.list.change_status_non_conformance",
                )}
              </FloatingMenu.Option>

              <FloatingMenu.Option
                isDanger
                onClick={() =>
                  bulkActions.changeStatus(QualityControlStatus.NonCompliance)
                }
              >
                {I18n.t(
                  "js.admin.quality_control.list.change_status_non_compliance",
                )}
              </FloatingMenu.Option>

              <FloatingMenu.Option
                onClick={() =>
                  bulkActions.changeStatus(QualityControlStatus.Approved)
                }
              >
                {I18n.t("js.admin.quality_control.list.change_status_approved")}
              </FloatingMenu.Option>

              <FloatingMenu.Option
                onClick={() =>
                  bulkActions.run(harvestYear ?? FIELD_ACTUALS_YEAR)
                }
              >
                Run quality control
              </FloatingMenu.Option>
            </FloatingMenu.Root>

            <IconButton
              icon="cog"
              variant="secondary"
              size="small"
              onClick={() => setIsSettingsOpen(val => !val)}
            />

            <ButtonSelect.Root
              value={(harvestYear ?? FIELD_ACTUALS_YEAR)?.toString()}
              onChange={value => setHarvestYear(parseInt(value))}
            >
              {actualsYears.map(year => (
                <ButtonSelect.Option key={year} value={year.toString()}>
                  {year}
                </ButtonSelect.Option>
              ))}
            </ButtonSelect.Root>
          </div>

          {bulkActions.results.length ? (
            <div className={stack({ gap: 8 })} style={{ gridColumn: "span 2" }}>
              {bulkActions.results.map(result => (
                <InfoBox
                  key={result.id}
                  variant={result.type === "success" ? "success" : "danger"}
                  icon={result.type === "success" ? "check" : "warning"}
                  onClose={() => bulkActions.removeResult(result.id)}
                >
                  {result.message}
                </InfoBox>
              ))}
            </div>
          ) : null}
        </ListLayout.Header>

        <Table
          instance={table}
          meta={fieldsData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />

        <FieldCommentsModal
          isOpen={commentsModal.isOpen}
          fields={commentsModal.fields}
          onAddComment={handleCommentAdded}
          onRequestClose={() => commentsModal.close()}
        />

        <FieldActualsChangesModal
          isOpen={actualsChangesModal.isOpen}
          field={actualsChangesModal?.field}
          onRequestClose={() => actualsChangesModal.close()}
        />

        <TableSettings
          instance={table}
          state={tableState}
          isOpen={isSettingsOpen}
          onStateChange={value => setTableState(value)}
          onClose={() => setIsSettingsOpen(false)}
          onReset={() => setTableState(INITIAL_QUALITY_CONTROL_TABLE_STATE)}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default QualityControl;
