import { z } from "zod";

import { FieldActualsInputType } from "@ag/carbon/types";

import { CoverCrops } from "~pages/_legacy/carbon/types";
// TODO: Move them to features types
import { CropTypeSchema } from "~queries/carbon/schemas";

import { FieldActualsBaseSchema } from "./field-actuals";

// Merge all partials schemas and add completion rates
const FieldActualsV2021InputSchema = z.object({
  // Cultivation strategy
  cropLastHarvestYear: z.number().optional(),
  carbonCropTypeId: z.number().nullable(),
  carbonCropType: CropTypeSchema.nullable(),
  cropTypeOtherName: z.string().nullable().optional(),
  cropGrossYield: z.number().nullable(),
  cropNetYield: z.number().nullable(),
  coverCropsAdoption: z.nativeEnum(CoverCrops).nullable(),
  cropResidueManagement: z.string().nullable(),
  tillingRate: z.number().nullable(),

  // Fertilisers
  fertilisersCount: z.number(),
  fertilisersShowChemicalSpecification: z.boolean().nullable(),
  fertilisers: z.array(
    z.object({
      carbonFertiliserId: z.number().nullable(),
      applicationRate: z.number().nullable(),
      applicationMode: z.string().nullable(),
      emissionsInhibitors: z.string().nullable(),
    }),
  ),
  chemicalSpecification: z
    .object({
      fertilisers: z.record(z.number(), z.number().nullable()),
      applicationMode: z.string().nullable(),
    })
    .optional(),

  // Crop protection
  cropProtectionsCount: z.number(),
  cropProtections: z.array(
    z.object({
      protectionType: z.string().nullable(),
      category: z.string().nullable(),
      numberOfDoses: z.number().nullable(),
    }),
  ),

  // Fuel consumption
  energyConsumptionEnergySource: z.string().nullable(),
  energyConsumptionAmountHa: z.number().nullable(),
  energyConsumptionAmountUnit: z.string().nullish(),

  // Additional information
  otherInformationComment: z.string().nullable(),
});

// Merge base schema with v2021 schema
export const FieldActualsV2021Schema = FieldActualsBaseSchema.merge(
  z.object({
    carbonFieldActualInputType: z.literal(FieldActualsInputType.V2021),
    carbonFieldActualInput: FieldActualsV2021InputSchema,
  }),
);

export type FieldActualsV2021 = z.infer<typeof FieldActualsV2021Schema>;
