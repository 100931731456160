import { FormApi } from "final-form";
import { createContext } from "react";

import {
  AdditionalInformationData,
  HistoryOfDeforestationData,
  SoilCharacteristicsData,
  SoilCultivationPracticeData,
} from "@ag/carbon/forms/baseline/iso";

import { FieldDefinitionPartial } from "./FieldPartials/types";

export type FieldDefinitionPartialsFormAPI = {
  [FieldDefinitionPartial.SoilCharacteristics]: FormApi<SoilCharacteristicsData> | null;
  [FieldDefinitionPartial.HistoryOfDeforestation]: FormApi<HistoryOfDeforestationData> | null;
  [FieldDefinitionPartial.SoilCultivationPractice]: FormApi<SoilCultivationPracticeData> | null;
  [FieldDefinitionPartial.AdditionalInformation]: FormApi<AdditionalInformationData> | null;
};

export type SetPartialFormAPIArgs =
  | {
      partialName: FieldDefinitionPartial.SoilCharacteristics;
      formAPI: FormApi<SoilCharacteristicsData>;
    }
  | {
      partialName: FieldDefinitionPartial.HistoryOfDeforestation;
      formAPI: FormApi<HistoryOfDeforestationData>;
    }
  | {
      partialName: FieldDefinitionPartial.SoilCultivationPractice;
      formAPI: FormApi<SoilCultivationPracticeData>;
    }
  | {
      partialName: FieldDefinitionPartial.AdditionalInformation;
      formAPI: FormApi<AdditionalInformationData>;
    };

export type FieldDefinitionContext = {
  partialsFormAPIs: FieldDefinitionPartialsFormAPI;
  setPartialFormAPI: (args: SetPartialFormAPIArgs) => void;
};

export const initialFieldDefinitionContext: FieldDefinitionContext = {
  partialsFormAPIs: {
    [FieldDefinitionPartial.SoilCharacteristics]: null,
    [FieldDefinitionPartial.HistoryOfDeforestation]: null,
    [FieldDefinitionPartial.SoilCultivationPractice]: null,
    [FieldDefinitionPartial.AdditionalInformation]: null,
  },
  setPartialFormAPI: () => {},
};

const FieldDefinitionContext = createContext<FieldDefinitionContext>(
  initialFieldDefinitionContext,
);

export default FieldDefinitionContext;
