import { ChipVariant } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import { FieldValidationStatus } from "~features/field";

import { ValidationRequestStatus } from "../types";

export const getValidationRequestStatusLabel = (
  status: ValidationRequestStatus,
) => {
  const label: Record<ValidationRequestStatus, string> = {
    new: I18n.t("js.carbon.validation_request_status.new"),
    resolved: I18n.t("js.carbon.validation_request_status.resolved"),
  };

  return label[status];
};

export const getValidationRequestStatusChip = (
  status: ValidationRequestStatus,
) => {
  const lookup: Record<ValidationRequestStatus, ChipVariant> = {
    new: "info",
    resolved: "success",
  };

  return lookup[status];
};

export const getValidationRequestFieldStatusChip = (
  status: FieldValidationStatus,
) => {
  const lookup: Record<FieldValidationStatus, ChipVariant> = {
    [FieldValidationStatus.New]: "info",
    [FieldValidationStatus.InProgress]: "info",
    [FieldValidationStatus.Rejected]: "danger",
    [FieldValidationStatus.Validated]: "success",
  };

  return lookup[status];
};

export const getValidationRequestFieldStatusLabel = (
  status: FieldValidationStatus,
) => {
  const label: Record<FieldValidationStatus, string> = {
    [FieldValidationStatus.New]: I18n.t(
      "js.carbon.validation_request_field_status.new",
    ),
    [FieldValidationStatus.InProgress]: I18n.t(
      "js.carbon.validation_request_field_status.in_progress",
    ),
    [FieldValidationStatus.Rejected]: I18n.t(
      "js.carbon.validation_request_field_status.rejected",
    ),
    [FieldValidationStatus.Validated]: I18n.t(
      "js.carbon.validation_request_field_status.validated",
    ),
  };

  return label[status];
};
