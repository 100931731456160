import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import { APIResult, MetaArgs } from "@ag/utils/types";

import { CropType, CropTypeSchema } from "../entities/crop-type";

type GetCropTypesParams = MetaArgs & {
  filters: Partial<{
    matchCoolfarmName: string;
    coolfarmIdentifier: string;
    matchCropTypeName: string;
    harvestYear: string | number;
    methodologyVersion: string | number;
  }>;
  sort?: Partial<{
    id: SortingDirection;
    name: SortingDirection;
  }>;
};

export const generateCropTypesQueryKey = (
  params?: GetCropTypesParams | undefined,
) => ["crop-types", params].filter(Boolean);

const getCropTypes = async (params: GetCropTypesParams | undefined) => {
  const response = await axios.get<APIResult<CropType[], true>>(
    "/api/farmer/carbon/v1/admin/carbon_crop_types",
    {
      params: {
        ...params,
        includeMeta: true,
        limit: params?.limit || DEFAULT_LIMIT,
      },
      schema: z.array(CropTypeSchema),
    },
  );

  return response.data.result;
};

export const useCropTypesQuery = (params?: GetCropTypesParams) =>
  useQuery(generateCropTypesQueryKey(params), () => getCropTypes(params), {
    keepPreviousData: true,
  });
