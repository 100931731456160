import { cva } from "class-variance-authority";
import { forwardRef } from "react";

import { Icon } from "~assets";
import { cn } from "~utils";

import { ICONS_REGULAR } from "../../assets/Icon";
import { ChipSize, ChipVariant } from "./types";

const chipVariants = cva(
  "inline-flex items-center justify-center gap-1 rounded px-2 font-bold",
  {
    variants: {
      variant: {
        success: "bg-messaging-success-100 text-messaging-success-900",
        warning: "bg-messaging-warning-300 text-messaging-warning-900",
        neutral: "bg-grey-100 text-grey-900",
        info: "bg-messaging-info-100 text-accent-200",
        danger: "bg-messaging-error-100 text-messaging-error-700",
      },
      size: {
        small: "text-p3",
        large: "py-0.5 text-p2",
      },
      isIconOnly: {
        true: "rounded-full p-0.5",
      },
    },
  },
);

const iconVariants = cva("", {
  variants: {
    variant: {
      success: "text-messaging-success-900",
      warning: "text-messaging-warning-900",
      neutral: "text-grey-900",
      info: "text-accent-200",
      danger: "text-messaging-error-700",
    },
    size: {
      small: "text-[10px]",
      large: "text-[14px]",
    },
  },
});

type BaseProps = {
  variant?: ChipVariant;
  size?: ChipSize;
  className?: string;
};

type Props = BaseProps &
  (
    | React.PropsWithChildrenRequired<{
        icon?: keyof typeof ICONS_REGULAR;
      }>
    | React.PropsWithChildren<{
        icon: keyof typeof ICONS_REGULAR;
      }>
  ) &
  React.HTMLAttributes<HTMLSpanElement>;

export const Chip = forwardRef(
  (
    {
      variant = "neutral",
      size = "small",
      icon,
      children,
      className,
      ...rest
    }: Props,
    ref: React.Ref<HTMLSpanElement>,
  ) => {
    const isIconOnly = !children && Boolean(icon);

    return (
      <span
        ref={ref}
        className={cn(
          chipVariants({
            variant,
            size,
            isIconOnly,
          }),
          className,
        )}
        {...rest}
      >
        {icon && (
          <Icon
            variant="regular"
            name={icon}
            className={iconVariants({ variant, size })}
          />
        )}

        {children}
      </span>
    );
  },
);

Chip.displayName = "Chip";
