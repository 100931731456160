import I18n from "@ag/i18n";
import { FieldActualsStatus } from "@ag/utils/types";

import {
  AssuranceCategory,
  AssuranceCheckKey,
  AssuranceFlag,
  AssuranceStatus,
} from "../entities/assurance";

export const getAssuranceCheckKeyLabel = (
  value: AssuranceCheckKey,
): string | null => {
  const lookup: Record<AssuranceCheckKey, string> = {
    field_location: I18n.t("js.admin.assurance.check_keys.field_location"),
    farm_size: I18n.t("js.admin.assurance.check_keys.farm_size"),
    soil_disturbance_hb: I18n.t(
      "js.admin.assurance.check_keys.soil_disturbance_hb",
    ),
    cover_crop_hb: I18n.t("js.admin.assurance.check_keys.cover_crop_hb"),
    intersection_hectares: I18n.t(
      "js.admin.assurance.check_keys.intersection_hectares",
    ),
    intersection_percentage: I18n.t(
      "js.admin.assurance.check_keys.intersection_percentage",
    ),
    intersection_sum: I18n.t("js.admin.assurance.check_keys.intersection_sum"),
    conflicting_fields: I18n.t(
      "js.admin.assurance.check_keys.conflicting_fields",
    ),
  };

  return lookup[value] ?? null;
};

export const getAssuranceCategoryLabel = (
  value: AssuranceCategory,
): string | null => {
  const lookup: Record<AssuranceCategory, string> = {
    duplication: I18n.t("js.admin.assurance.category.duplication"),
    field_size: I18n.t("js.admin.assurance.category.field_size"),
    intersection_over_union: I18n.t(
      "js.admin.assurance.category.intersection_over_union",
    ),
    deforestation: I18n.t("js.admin.assurance.category.deforestation"),
    soil_disturbance: I18n.t("js.admin.assurance.category.soil_disturbance"),
    cover_crop: I18n.t("js.admin.assurance.category.cover_crop"),
  };

  return lookup[value] ?? null;
};

export const getAssuranceStatusLabel = (
  value: AssuranceStatus | null,
): string | null => {
  if (!value) return null;

  const lookup: Record<AssuranceStatus, string> = {
    approved: I18n.t("js.admin.assurance.status.approved"),
    non_compliance: I18n.t("js.admin.assurance.status.non_compliance"),
    non_conformance: I18n.t("js.admin.assurance.status.non_conformance"),
    processing: I18n.t("js.admin.assurance.status.processing"),
  };

  return lookup[value] ?? null;
};

// TODO: extract to fields feature
export const getFieldActualsStatusLabel = (
  value: FieldActualsStatus,
): string | null => {
  const lookup: Record<FieldActualsStatus, string> = {
    not_started: I18n.t("js.admin.field.actuals_status.not_started"),
    in_progress: I18n.t("js.admin.field.actuals_status.in_progress"),
    completed: I18n.t("js.admin.field.actuals_status.completed"),
    submitted: I18n.t("js.admin.field.actuals_status.submitted"),
  };

  return lookup[value] ?? null;
};

export const getAssuranceFlagLabel = (
  value: AssuranceFlag | AssuranceFlag[],
): string | null => {
  const lookup: Record<AssuranceFlag | "none", string> = {
    blue: I18n.t("js.admin.assurance.flag.blue"),
    green: I18n.t("js.admin.assurance.flag.green"),
    red: I18n.t("js.admin.assurance.flag.red"),
    orange: I18n.t("js.admin.assurance.flag.orange"),
    yellow: I18n.t("js.admin.assurance.flag.yellow"),
    none: I18n.t("js.carbon.qa.flag.none"),
  };

  if (Array.isArray(value)) return value.map(getAssuranceFlagLabel).join(", ");

  return lookup[value] ?? null;
};
