import { useMutation } from "@tanstack/react-query";
import chunk from "lodash/chunk";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateFieldsQueryKey } from "./get-fields";
import {
  type UnlockFieldDefinitionPayload,
  unlockFieldDefinition,
} from "./unlock-field-definition";

export const useUnlockFieldsDefinitionsMutation = () => {
  return useMutation(
    async (payloads: UnlockFieldDefinitionPayload[]) => {
      const payloadChunks = chunk(payloads, 10);

      for await (const chunk of payloadChunks) {
        await Promise.all(chunk.map(payload => unlockFieldDefinition(payload)));
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(generateFieldsQueryKey());
      },
      onError: error => {
        ToastNotification.error(error);
      },
    },
  );
};
