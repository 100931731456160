/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 4
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/agreena/App%20-%20Production/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'appproduction';

export const ApiKey: Record<Environment, string> = {
  appproduction: '55156185d511f56de7d59c9ebc189af0'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '4',
    branch: 'main',
    source: 'web',
    versionId: '549c2d5b-99ad-4fde-8487-f1f98f09ad46'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  adminUser?: string;
  /**
   * The user's total size of fields in hectares
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  farmSize?: number;
  /**
   * Property assigned in hubspot to indicate what degree of CS support this user should receive
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | low-touch, high-touch |
   */
  hubspotCarbonExperience?: "low-touch" | "high-touch";
  isAdminMode?: boolean;
  isAdvisor?: boolean;
  /**
   * Set language in the app
   *
   * | Rule | Value |
   * |---|---|
   * | Min Length | 2 |
   * | Max Length | 2 |
   */
  locale?: string;
}

export interface AmplitudePageViewedProperties {
  "[Amplitude] Page Domain"?: string;
  "[Amplitude] Page Location"?: string;
  "[Amplitude] Page Path"?: string;
  "[Amplitude] Page Title"?: string;
  "[Amplitude] Page URL"?: string;
  fbclid?: string;
  gclid?: string;
  referrer?: string;
  referring_domain?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_id?: any;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  wbraid?: any;
}

export interface AccountCreatedProperties {
  acceptNewsletter?: boolean;
}

export interface ActualsDataTypeSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Farm Info And Inputs, Crops And Fields |
   */
  actualsDataType: "Farm Info And Inputs" | "Crops And Fields";
}

export interface ButtonClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Apply Previously Used Practices, Copy Majority Practices To All Previous Years, Add Another Crop Practice Set, Apply Crop Practice Set, Help Center, Add Field (Dashboard), Create A Farm, Get Help, Combine Another Column, Reset Columns, Want To Use Different Names, Add Fields Definition, Add Fields (Map), Start Over, Get started (Actuals), Review (Actuals), Open spreadsheet (Actuals), Save spreadsheet (Actuals), Close spreadsheet (Actuals), Exit (Actuals) |
   */
  buttonName:
    | "Apply Previously Used Practices"
    | "Copy Majority Practices To All Previous Years"
    | "Add Another Crop Practice Set"
    | "Apply Crop Practice Set"
    | "Help Center"
    | "Add Field (Dashboard)"
    | "Create A Farm"
    | "Get Help"
    | "Combine Another Column"
    | "Reset Columns"
    | "Want To Use Different Names"
    | "Add Fields Definition"
    | "Add Fields (Map)"
    | "Start Over"
    | "Get started (Actuals)"
    | "Review (Actuals)"
    | "Open spreadsheet (Actuals)"
    | "Save spreadsheet (Actuals)"
    | "Close spreadsheet (Actuals)"
    | "Exit (Actuals)";
}

export interface CropOverviewViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cropsCompletedPercent: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cropsCount: number;
}

export interface CropPracticesViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  cropPracticesCount?: number;
}

export interface CropsHistorySubmittedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | csv, flatfile |
   */
  source: "csv" | "flatfile";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Drag And Drop, Folder |
   */
  uploadMethod?: "Drag And Drop" | "Folder";
}

export interface FieldActualsSubmittedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  fieldId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  harvestYear?: number;
}

export interface FieldBoundariesSubmittedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Drag And Drop, Folder |
   */
  uploadMethod?: "Drag And Drop" | "Folder";
}

export interface FieldDataSavedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fieldsCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fieldsHectares?: number;
}

export interface FieldViewChangedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | list, map |
   */
  fieldViewMode: "list" | "map";
}

export interface FileDownloadedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "[Amplitude] Session Replay ID"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "File Name"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Crop History, Shape, Crop History Template, Practices Impact, Premium Pool, Programme Terms, AgreenaCarbon participants |
   */
  fileCategory?:
    | "Crop History"
    | "Shape"
    | "Crop History Template"
    | "Practices Impact"
    | "Premium Pool"
    | "Programme Terms"
    | "AgreenaCarbon participants";
}

export interface FileUploadFailedProperties {
  errorMessage?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Crop History, Shape, Crop History Template, Practices Impact, Premium Pool, Programme Terms, AgreenaCarbon participants |
   */
  fileCategory?:
    | "Crop History"
    | "Shape"
    | "Crop History Template"
    | "Practices Impact"
    | "Premium Pool"
    | "Programme Terms"
    | "AgreenaCarbon participants";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Drag And Drop, Folder |
   */
  uploadMethod?: "Drag And Drop" | "Folder";
}

export interface FilterSelectedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Farm, Year, Search, Pagination |
   */
  filterName: "Farm" | "Year" | "Search" | "Pagination";
  filterValue?: string;
}

export interface FormSubmitFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Missing field(s), Input validation failed, Data rejected, Result not found |
   */
  formSubmitFailedReason?: "Missing field(s)" | "Input validation failed" | "Data rejected" | "Result not found";
}

export interface InputFilledProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Country, Leased Size, Termination Size, Lime Dolomite Application, Frequency, Application Rate, Fuel Type, Liters Per Year, Harvested Yield, Cover Crops, Crop Residue, Fertiliser, N Fertiliser Rate, Phosphorous Rate, Potassium Rate, Nitrification Inhibitors, Crop Protection, Grow This Year, Column, Tillage Practice, Crop, Farm Hectares, Crop Hectares |
   */
  inputName:
    | "Country"
    | "Leased Size"
    | "Termination Size"
    | "Lime Dolomite Application"
    | "Frequency"
    | "Application Rate"
    | "Fuel Type"
    | "Liters Per Year"
    | "Harvested Yield"
    | "Cover Crops"
    | "Crop Residue"
    | "Fertiliser"
    | "N Fertiliser Rate"
    | "Phosphorous Rate"
    | "Potassium Rate"
    | "Nitrification Inhibitors"
    | "Crop Protection"
    | "Grow This Year"
    | "Column"
    | "Tillage Practice"
    | "Crop"
    | "Farm Hectares"
    | "Crop Hectares";
  inputValue?: string;
}

export interface ModalViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Company Details Lookup, Company Details Manual, Additionality Info Panel, Eligibility Info Panel, Estimate Calculation Info Panel, Baseline Info Panel, Register Panel |
   */
  modalName:
    | "Company Details Lookup"
    | "Company Details Manual"
    | "Additionality Info Panel"
    | "Eligibility Info Panel"
    | "Estimate Calculation Info Panel"
    | "Baseline Info Panel"
    | "Register Panel";
}

export interface ProgrammeContractRequestedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  contractId: number;
}

export interface TooltipShownProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Cover Crops, Crop Residue, Nitrification Inhibitors, Crop Protection, Harvested Yield, Harvest Year Added, Contracting Stage |
   */
  tooltipName:
    | "Cover Crops"
    | "Crop Residue"
    | "Nitrification Inhibitors"
    | "Crop Protection"
    | "Harvested Yield"
    | "Harvest Year Added"
    | "Contracting Stage";
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudePageViewed implements BaseEvent {
  event_type = '[Amplitude] Page Viewed';

  constructor(
    public event_properties?: AmplitudePageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreated implements BaseEvent {
  event_type = 'Account Created';

  constructor(
    public event_properties?: AccountCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActualsCropsAndFieldsItemClicked implements BaseEvent {
  event_type = 'Actuals Crops And Fields Item Clicked';
}

export class ActualsDataTypeSelected implements BaseEvent {
  event_type = 'Actuals Data Type Selected';

  constructor(
    public event_properties: ActualsDataTypeSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ActualsFarmInfoAndInputsItemClicked implements BaseEvent {
  event_type = 'Actuals Farm Info And Inputs Item Clicked';
}

export class ButtonClicked implements BaseEvent {
  event_type = 'Button Clicked';

  constructor(
    public event_properties: ButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CropChangesSubmitted implements BaseEvent {
  event_type = 'Crop Changes Submitted';
}

export class CropFertiliserAdded implements BaseEvent {
  event_type = 'Crop Fertiliser Added';
}

export class CropOverviewViewed implements BaseEvent {
  event_type = 'Crop Overview Viewed';

  constructor(
    public event_properties: CropOverviewViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CropPracticesViewed implements BaseEvent {
  event_type = 'Crop Practices Viewed';

  constructor(
    public event_properties?: CropPracticesViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CropSelected implements BaseEvent {
  event_type = 'Crop Selected';
}

export class CropYearSelected implements BaseEvent {
  event_type = 'Crop Year Selected';
}

export class CropsHistorySubmitted implements BaseEvent {
  event_type = 'Crops History Submitted';

  constructor(
    public event_properties: CropsHistorySubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EmailUpdated implements BaseEvent {
  event_type = 'Email Updated';
}

export class FarmAdded implements BaseEvent {
  event_type = 'Farm Added';
}

export class FieldActualsSubmitted implements BaseEvent {
  event_type = 'Field Actuals Submitted';

  constructor(
    public event_properties: FieldActualsSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FieldBoundariesSubmitted implements BaseEvent {
  event_type = 'Field Boundaries Submitted';

  constructor(
    public event_properties?: FieldBoundariesSubmittedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FieldDataSaved implements BaseEvent {
  event_type = 'Field Data Saved';

  constructor(
    public event_properties?: FieldDataSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FieldSelected implements BaseEvent {
  event_type = 'Field Selected';
}

export class FieldViewChanged implements BaseEvent {
  event_type = 'Field View Changed';

  constructor(
    public event_properties: FieldViewChangedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FileDownloaded implements BaseEvent {
  event_type = 'File Downloaded';

  constructor(
    public event_properties?: FileDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FileUploadFailed implements BaseEvent {
  event_type = 'File Upload Failed';

  constructor(
    public event_properties?: FileUploadFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterSelected implements BaseEvent {
  event_type = 'Filter Selected';

  constructor(
    public event_properties: FilterSelectedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FormCancelClicked implements BaseEvent {
  event_type = 'Form Cancel Clicked';
}

export class FormNextClicked implements BaseEvent {
  event_type = 'Form Next Clicked';
}

export class FormSubmitClicked implements BaseEvent {
  event_type = 'Form Submit Clicked';
}

export class FormSubmitFailed implements BaseEvent {
  event_type = 'Form Submit Failed';

  constructor(
    public event_properties?: FormSubmitFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InputFilled implements BaseEvent {
  event_type = 'Input Filled';

  constructor(
    public event_properties: InputFilledProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ModalViewed implements BaseEvent {
  event_type = 'Modal Viewed';

  constructor(
    public event_properties: ModalViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProgrammeContractRequested implements BaseEvent {
  event_type = 'Programme Contract Requested';

  constructor(
    public event_properties: ProgrammeContractRequestedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SessionEnd implements BaseEvent {
  event_type = 'session_end';
}

export class SessionStart implements BaseEvent {
  event_type = 'session_start';
}

export class SignedIn implements BaseEvent {
  event_type = 'Signed In';
}

export class SignedOut implements BaseEvent {
  event_type = 'Signed Out';
}

export class SignupAccountCreated implements BaseEvent {
  event_type = 'Signup Account Created';
}

export class TooltipShown implements BaseEvent {
  event_type = 'Tooltip Shown';

  constructor(
    public event_properties: TooltipShownProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ValidationRequested implements BaseEvent {
  event_type = 'Validation Requested';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Page Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/%5BAmplitude%5D%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Page Domain)
   * @param options Amplitude event options.
   */
  amplitudePageViewed(
    properties?: AmplitudePageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudePageViewed(properties), options);
  }

  /**
   * Account Created
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Account%20Created)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. acceptNewsletter)
   * @param options Amplitude event options.
   */
  accountCreated(
    properties?: AccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(properties), options);
  }

  /**
   * Actuals Crops And Fields Item Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Actuals%20Crops%20And%20Fields%20Item%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  actualsCropsAndFieldsItemClicked(
    options?: EventOptions,
  ) {
    return this.track(new ActualsCropsAndFieldsItemClicked(), options);
  }

  /**
   * Actuals Data Type Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Actuals%20Data%20Type%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. actualsDataType)
   * @param options Amplitude event options.
   */
  actualsDataTypeSelected(
    properties: ActualsDataTypeSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ActualsDataTypeSelected(properties), options);
  }

  /**
   * Actuals Farm Info And Inputs Item Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Actuals%20Farm%20Info%20And%20Inputs%20Item%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  actualsFarmInfoAndInputsItemClicked(
    options?: EventOptions,
  ) {
    return this.track(new ActualsFarmInfoAndInputsItemClicked(), options);
  }

  /**
   * Button Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Button%20Clicked)
   *
   * General event used to track when a button is clicked, for fine grained analytics of what the user is doing.
   *
   * If this action is critical for tracking a specific conversion or an integration, please create a separate named event instead.
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  buttonClicked(
    properties: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * Crop Changes Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Crop%20Changes%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  cropChangesSubmitted(
    options?: EventOptions,
  ) {
    return this.track(new CropChangesSubmitted(), options);
  }

  /**
   * Crop Fertiliser Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Crop%20Fertiliser%20Added)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  cropFertiliserAdded(
    options?: EventOptions,
  ) {
    return this.track(new CropFertiliserAdded(), options);
  }

  /**
   * Crop Overview Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Crop%20Overview%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cropsCompletedPercent)
   * @param options Amplitude event options.
   */
  cropOverviewViewed(
    properties: CropOverviewViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CropOverviewViewed(properties), options);
  }

  /**
   * Crop Practices Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Crop%20Practices%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. cropPracticesCount)
   * @param options Amplitude event options.
   */
  cropPracticesViewed(
    properties?: CropPracticesViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CropPracticesViewed(properties), options);
  }

  /**
   * Crop Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Crop%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  cropSelected(
    options?: EventOptions,
  ) {
    return this.track(new CropSelected(), options);
  }

  /**
   * Crop Year Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Crop%20Year%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  cropYearSelected(
    options?: EventOptions,
  ) {
    return this.track(new CropYearSelected(), options);
  }

  /**
   * Crops History Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Crops%20History%20Submitted)
   *
   * Event to track when a user submits their 5 year crops historical template
   *
   * @param properties The event's properties (e.g. source)
   * @param options Amplitude event options.
   */
  cropsHistorySubmitted(
    properties: CropsHistorySubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CropsHistorySubmitted(properties), options);
  }

  /**
   * Email Updated
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Email%20Updated)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  emailUpdated(
    options?: EventOptions,
  ) {
    return this.track(new EmailUpdated(), options);
  }

  /**
   * Farm Added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Farm%20Added)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  farmAdded(
    options?: EventOptions,
  ) {
    return this.track(new FarmAdded(), options);
  }

  /**
   * Field Actuals Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Field%20Actuals%20Submitted)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fieldId)
   * @param options Amplitude event options.
   */
  fieldActualsSubmitted(
    properties: FieldActualsSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FieldActualsSubmitted(properties), options);
  }

  /**
   * Field Boundaries Submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Field%20Boundaries%20Submitted)
   *
   * Event to track when the user submits their field boundaries either through drawing or shapefile upload
   *
   * @param properties The event's properties (e.g. uploadMethod)
   * @param options Amplitude event options.
   */
  fieldBoundariesSubmitted(
    properties?: FieldBoundariesSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FieldBoundariesSubmitted(properties), options);
  }

  /**
   * Field Data Saved
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Field%20Data%20Saved)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fieldsCount)
   * @param options Amplitude event options.
   */
  fieldDataSaved(
    properties?: FieldDataSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FieldDataSaved(properties), options);
  }

  /**
   * Field Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Field%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  fieldSelected(
    options?: EventOptions,
  ) {
    return this.track(new FieldSelected(), options);
  }

  /**
   * Field View Changed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Field%20View%20Changed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. fieldViewMode)
   * @param options Amplitude event options.
   */
  fieldViewChanged(
    properties: FieldViewChangedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FieldViewChanged(properties), options);
  }

  /**
   * File Downloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/File%20Downloaded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  fileDownloaded(
    properties?: FileDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FileDownloaded(properties), options);
  }

  /**
   * File Upload Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/File%20Upload%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. errorMessage)
   * @param options Amplitude event options.
   */
  fileUploadFailed(
    properties?: FileUploadFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FileUploadFailed(properties), options);
  }

  /**
   * Filter Selected
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Filter%20Selected)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. filterName)
   * @param options Amplitude event options.
   */
  filterSelected(
    properties: FilterSelectedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterSelected(properties), options);
  }

  /**
   * Form Cancel Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Form%20Cancel%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formCancelClicked(
    options?: EventOptions,
  ) {
    return this.track(new FormCancelClicked(), options);
  }

  /**
   * Form Next Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Form%20Next%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formNextClicked(
    options?: EventOptions,
  ) {
    return this.track(new FormNextClicked(), options);
  }

  /**
   * Form Submit Clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Form%20Submit%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  formSubmitClicked(
    options?: EventOptions,
  ) {
    return this.track(new FormSubmitClicked(), options);
  }

  /**
   * Form Submit Failed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Form%20Submit%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. formSubmitFailedReason)
   * @param options Amplitude event options.
   */
  formSubmitFailed(
    properties?: FormSubmitFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormSubmitFailed(properties), options);
  }

  /**
   * Input Filled
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Input%20Filled)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. inputName)
   * @param options Amplitude event options.
   */
  inputFilled(
    properties: InputFilledProperties,
    options?: EventOptions,
  ) {
    return this.track(new InputFilled(properties), options);
  }

  /**
   * Modal Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Modal%20Viewed)
   *
   * General event indicating that a user has viewed a modal on the platform.
   *
   * If this event is critical for a conversion or integration, please created a named event instead.
   *
   * @param properties The event's properties (e.g. modalName)
   * @param options Amplitude event options.
   */
  modalViewed(
    properties: ModalViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ModalViewed(properties), options);
  }

  /**
   * Programme Contract Requested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Programme%20Contract%20Requested)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. contractId)
   * @param options Amplitude event options.
   */
  programmeContractRequested(
    properties: ProgrammeContractRequestedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ProgrammeContractRequested(properties), options);
  }

  /**
   * session_end
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/session_end)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  sessionEnd(
    options?: EventOptions,
  ) {
    return this.track(new SessionEnd(), options);
  }

  /**
   * session_start
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/session_start)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  sessionStart(
    options?: EventOptions,
  ) {
    return this.track(new SessionStart(), options);
  }

  /**
   * Signed In
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Signed%20In)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signedIn(
    options?: EventOptions,
  ) {
    return this.track(new SignedIn(), options);
  }

  /**
   * Signed Out
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Signed%20Out)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signedOut(
    options?: EventOptions,
  ) {
    return this.track(new SignedOut(), options);
  }

  /**
   * Signup Account Created
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Signup%20Account%20Created)
   *
   * Event indicating that a user has successfully created an account as the last part of the signup flow.
   *
   * @param options Amplitude event options.
   */
  signupAccountCreated(
    options?: EventOptions,
  ) {
    return this.track(new SignupAccountCreated(), options);
  }

  /**
   * Tooltip Shown
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Tooltip%20Shown)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. tooltipName)
   * @param options Amplitude event options.
   */
  tooltipShown(
    properties: TooltipShownProperties,
    options?: EventOptions,
  ) {
    return this.track(new TooltipShown(properties), options);
  }

  /**
   * Validation Requested
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/agreena/App%20-%20Production/events/main/latest/Validation%20Requested)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  validationRequested(
    options?: EventOptions,
  ) {
    return this.track(new ValidationRequested(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
