import { Global as GlobalStyles } from "@emotion/core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";

import { DialogProvider } from "@ag/utils/hooks";

import App from "~app";
import { setupI18n, setupSentry } from "~config";
import { queryClient } from "~config/react-query";
import { SessionProvider } from "~features/auth";
import { AmplitudeProvider } from "~lib/ampli";
import { globalStyles } from "~style/global-styles";

import "./index.css";

setupI18n();
setupSentry();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <AmplitudeProvider>
        <QueryClientProvider client={queryClient}>
          <DialogProvider>
            <SessionProvider>
              <GlobalStyles styles={globalStyles} />

              <App />

              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-left"
              />
            </SessionProvider>
          </DialogProvider>
        </QueryClientProvider>
      </AmplitudeProvider>
    </QueryParamProvider>
  </BrowserRouter>,
);
