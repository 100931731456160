export const style = {
  color: {
    // Primary
    green: {
      100: "#CAE4CA",
      200: "#97C79C",
      300: "#65A781",
      400: "#32836C",
      500: "#005C59",
      600: "#005451",
      700: "#004A48",
      800: "#003F3E",
      900: "#003332",
    },

    // Secondary #1
    opal: {
      100: "#DEEFEB",
      200: "#CCE6E1",
      300: "#BCDDD7",
      400: "#ABD3CD",
      500: "#9AC0B8",
      600: "#89B3AA",
      700: "#779D92",
      800: "#65877B",
      900: "#537164",
    },

    // Secondary #2
    tan: {
      100: "#F9F3EB",
      200: "#F3E7D7",
      300: "#ECDBC4",
      400: "#E5CFB2",
      500: "#DDC3A0",
      600: "#C2A98B",
      700: "#A79076",
      800: "#8C7762",
      900: "#715E4E",
    },

    blue: {
      100: "#BEDAED",
      200: "#A4CCE6",
      300: "#8BBEDF",
      400: "#73B0D7",
      500: "#5AA2CF",
      600: "#4689B7",
      700: "#34709D",
      800: "#255881",
      900: "#183F62",
    },

    orange: {
      100: "#FFE0C5",
      200: "#FFD2A9",
      300: "#FFC58D",
      400: "#FFB974",
      500: "#FFAE5B",
      600: "#E28F4E",
      700: "#C67141",
      800: "#A95635",
      900: "#8C3E2A",
    },

    yellow: {
      100: "#FFFAD3",
    },

    red: {
      100: "#FFC5CB",
      200: "#FFA9AE",
      300: "#FF8D92",
      400: "#FF7477",
      500: "#FF5B5B",
      600: "#DB4851",
      700: "#C64157",
      800: "#A93553",
      900: "#8C2A4C",
    },

    grey: {
      100: "#F7F7F7",
      200: "#EFEFEF",
      300: "#D4D4D4",
      400: "#C2C2C2",
      500: "#B0B0B0",
      600: "#8D8D8D",
      700: "#6A6A6A",
      800: "#464646",
      900: "#232323",
    },

    white: {
      100: "#FFFFFF",
      200: "#F7F8F9",
    },

    agrovi: {
      600: "#5f9361",
    },
  },

  typography: {
    // Values are defined in pixels
    fontSize: {
      h1: 32,
      h2: 24,
      h3: 20,
      h4: 16,
      h5: 14,
      h6: 12,
      h7: 11,
      h8: 11,
      p1: 16,
      p2: 14,
      p3: 12,
    },

    // Values are defined in pixels
    lineHeight: {
      h1: 40,
      h2: 32,
      h3: 24,
      h4: 20,
      h5: 16,
      h6: 16,
      h7: 16,
      h8: 16,
      p1: 24,
      p2: 24,
      p3: 20,
    },

    fontWeight: {
      h1: 700,
      h2: 700,
      h3: 700,
      h4: 700,
      h5: 700,
      h6: 700,
      h7: 700,
      h8: 700,
      p1: 400,
      p2: 400,
      p3: 400,
    },
  },

  borderRadius: 4,

  // shadow styles
  elevation: {
    200: "0 8px 8px rgba(0, 0, 0, 0.05)",
  },
};
