import { z } from "zod";

import { FieldActualsInputType, MethodologyVersion } from "@ag/carbon/types";

import { CoverCrops } from "~pages/_legacy/carbon/types";
// TODO: Move them to features types
import { CropTypeSchema } from "~queries/carbon/schemas";
import {
  FertiliserEmissionsInhibitors,
  FertiliserMode,
} from "~queries/carbon/types";

import { FieldActualsBaseSchema } from "./field-actuals";

// Merge all partials schemas and add completion rates
const FieldActualsV2022InputSchema = z.object({
  // Cultivation strategy
  cropLastHarvestYear: z.number().optional(),
  carbonCropTypeId: z.number().nullable(),
  carbonCropType: CropTypeSchema.nullable(),
  cropTypeOtherName: z.string().nullable().optional(),
  cropGrossYield: z.number().nullable(),
  cropNetYield: z.number().nullable(),
  coverCropsAdoption: z.nativeEnum(CoverCrops).nullable(),
  cropResidueManagement: z.string().nullable(),
  tillingRate: z.number().nullable(),

  // Fertilisers
  fertilisers: z.array(
    z.object({
      mode: z.nativeEnum(FertiliserMode).nullable(),
      carbonFertiliserId: z.number().nullable(),
      applicationRate: z.number().nullable(),

      nitrogenApplicationRate: z.number().nullable(),
      nitrificationApplicationRate: z.number().nullable(),
      potassiumApplicationRate: z.number().nullable(),
      potassiumType: z.string().nullable(),
      phosphorusApplicationRate: z.number().nullable(),
      phosphorusType: z.string().nullable(),
      emissionsInhibitors: z
        .nativeEnum(FertiliserEmissionsInhibitors)
        .nullable(),
      carbonFertiliser: z.object({
        chemicalSpecification: z.boolean(),
        createdAt: z.string(),
        harvestYear: z.number(),
        id: z.number(),
        identifier: z.string(),
        methodologyVersion: z.nativeEnum(MethodologyVersion),
        name: z.string(),
        nitrificationAdded: z.boolean().nullable(),
        nitrogenTotal: z.number(),
        organicCarbon: z.number().nullable(),
        tradeName: z.string().nullable(),
        updatedAt: z.string(),
      }),
    }),
  ),
  fertilisersCount: z.number().nullable(),
  isFertilisersUsed: z.boolean().nullish(),

  // Crop protection
  cropProtectionsCount: z.number().nullable(),
  cropProtections: z.array(
    z.object({
      protectionType: z.string().nullable(),
      category: z.string().nullable(),
      applicationRate: z.number().nullable(),
      activeIngredient: z.number().nullable(),
    }),
  ),

  // Fuel consumption
  energyConsumptionEnergySource: z.string().nullable(),
  energyConsumptionAmountHa: z.number().nullable(),
  energyConsumptionAmountUnit: z.string().nullish(),

  // Additional information
  otherInformationComment: z.string().nullable(),
});
// Merge base schema with v2022 schema
export const FieldActualsV2022Schema = FieldActualsBaseSchema.merge(
  z.object({
    carbonFieldActualInputType: z.literal(FieldActualsInputType.V2022),
    carbonFieldActualInput: FieldActualsV2022InputSchema,
  }),
);

export const UpdateFieldActualsV2022Schema = FieldActualsBaseSchema.merge(
  z.object({
    carbonFieldActualInputType: z.literal(FieldActualsInputType.V2022),
    carbonFieldActualInput: FieldActualsV2022InputSchema.partial(),
  }),
);

export type FieldActualsV2022 = z.infer<typeof FieldActualsV2022Schema>;
export type UpdateFieldActualsV2022 = Partial<
  z.infer<typeof UpdateFieldActualsV2022Schema>
>;

export type FieldActualsV2022Input = z.infer<
  typeof FieldActualsV2022InputSchema
>;
