import { DrawingManager, OverlayView } from "@react-google-maps/api";
import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";

import { Polygon } from "@ag/map/components";
import { getGeojsonGeometryGoogleMapsLatLngCoordinates } from "@ag/map/helpers";
import { GeoJSONGeometry, GeometryType } from "@ag/map/types";

import { usePolygonPathsHistory } from "~hooks/use-polygon-paths-history";

import * as styles from "../boundaries-drawing-manager.css";
import {
  DRAW_MANAGER_OPTIONS,
  POLYGON_OPTIONS,
  RESHAPE_POLYLINE_OPTIONS,
  SELECTION_RECTANGLE_OPTIONS,
} from "../constants";
import { getActiveVertexPosition } from "../helpers";
import PolygonEditingToolbar from "./polygon-editing-toolbar/polygon-editing-toolbar";
import { usePolygonBoundariesStore } from "./polygonStore";
import { useDrawingManagerHandlers } from "./useDrawingManagerHandlers";
import { usePolygonHandlers } from "./usePolygonHandlers";

type Props = {
  boundaries: GeoJSONGeometry | undefined;
  polygonOptions?: google.maps.PolygonOptions;
  onError: (error: string) => void;
};

export const BoundariesDrawingPolygon = ({
  boundaries,
  polygonOptions,
  onError,
}: Props) => {
  const {
    activeVertex,
    activeVertices,
    selectMode,
    outerPath,
    innerPaths,
    editAction,
    setOuterPath,
    setInnerPaths,
  } = usePolygonBoundariesStore();

  useEffect(() => {
    const newCoordinates =
      getGeojsonGeometryGoogleMapsLatLngCoordinates(boundaries);

    const coordinates = (
      boundaries?.type === GeometryType.LineString
        ? [newCoordinates] // LineString has lower level of nesting than Polygon and MultiPolygon so we need to wrap it in an array for BoundariesDrawingManager
        : newCoordinates
    ) as google.maps.LatLng[][];

    if (!coordinates) return;

    const [outerPath, ...innerPaths] = coordinates;

    setOuterPath(outerPath);
    setInnerPaths(innerPaths);
  }, [boundaries, setInnerPaths, setOuterPath]);

  const history = usePolygonPathsHistory();

  const {
    handlePathUpdate: handlePolygonPathUpdate,
    handleMouseUp: handlePolygonMouseUp,
    handleLoad: handlePolygonLoad,
    handleUnmount: handlePolygonUnmount,
  } = usePolygonHandlers({
    history,
    onError,
  });

  const {
    handleLoad: handleDrawingManagerLoad,
    handlePolygonComplete: handleDrawingManagerPolygonComplete,
    handleRectangleComplete: handleDrawingManagerRectangleComplete,
    handlePolylineComplete: handleDrawingManagerPolylineComplete,
  } = useDrawingManagerHandlers({
    history,
    onError,
  });

  const getDrawingMode = () => {
    if (editAction === "cut") {
      return google.maps.drawing.OverlayType.POLYGON;
    }

    if (editAction === "reshape") {
      return google.maps.drawing.OverlayType.POLYLINE;
    }

    if (selectMode === "multi") {
      return google.maps.drawing.OverlayType.RECTANGLE;
    }

    return null;
  };

  if (isEmpty(outerPath)) return null;

  return (
    <>
      <PolygonEditingToolbar
        history={history}
        onVertexDelete={handlePolygonPathUpdate}
      />

      <Polygon
        options={{ ...POLYGON_OPTIONS, ...polygonOptions }}
        paths={[outerPath, ...(innerPaths || [])]}
        onLoad={handlePolygonLoad}
        onUnmount={handlePolygonUnmount}
        onMouseUp={handlePolygonMouseUp}
      />

      <DrawingManager
        options={{
          ...DRAW_MANAGER_OPTIONS,
          drawingMode: getDrawingMode(),
          rectangleOptions: SELECTION_RECTANGLE_OPTIONS,
          polylineOptions: RESHAPE_POLYLINE_OPTIONS,
        }}
        onLoad={handleDrawingManagerLoad}
        onPolygonComplete={handleDrawingManagerPolygonComplete}
        onRectangleComplete={handleDrawingManagerRectangleComplete}
        onPolylineComplete={line =>
          boundaries && handleDrawingManagerPolylineComplete(boundaries, line)
        }
      />

      {activeVertex && (
        <OverlayView
          position={getActiveVertexPosition(
            activeVertex,
            outerPath,
            innerPaths,
          )}
          mapPaneName={OverlayView.MAP_PANE}
          getPixelPositionOffset={() => ({
            x: -1 * (styles.size.activeVertex / 2),
            y: -1 * (styles.size.activeVertex / 2),
          })}
        >
          <div className={styles.activeVertex} />
        </OverlayView>
      )}

      {activeVertices &&
        activeVertices.length > 0 &&
        activeVertices.map((activeVertexItem, index) => (
          <OverlayView
            key={`${activeVertexItem.path}+${index}`}
            position={getActiveVertexPosition(
              activeVertexItem,
              outerPath,
              innerPaths,
            )}
            mapPaneName={OverlayView.MAP_PANE}
            getPixelPositionOffset={() => ({
              x: -1 * (styles.size.activeVertex / 2),
              y: -1 * (styles.size.activeVertex / 2),
            })}
          >
            <div className={styles.activeVertex} />
          </OverlayView>
        ))}
    </>
  );
};
