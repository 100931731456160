import I18n from "i18n-js";

import { CommentAttribute, CommentFormData } from "./types";

export const validate = (values: CommentFormData) => {
  const errors: Partial<Record<CommentAttribute, string>> = {};

  if (!values.comment) {
    errors.comment = I18n.t("js.shared.required");
  }

  return errors;
};
