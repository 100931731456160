import { zodResolver } from "@hookform/resolvers/zod";
import orderBy from "lodash/orderBy";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Select } from "@ag/design-system/molecules";
import { Modal } from "@ag/design-system/organisms";
import { SelectField, TextareaField } from "@ag/form-fields";
import I18n from "@ag/i18n";

import { formatRelativeWithoutTime } from "~helpers";

import { Field } from "../entities/field";
import {
  QualityControlCategory,
  QualityControlCheckKey,
  QualityControlComment,
} from "../entities/quality-control";
import { getQualityControlCheckKeys } from "../helpers/get-check-keys";
import {
  getQualityControlCategoryLabel,
  getQualityControlCheckKeyLabel,
} from "../helpers/get-labels";
import * as styles from "./field-comments-modal.css";

/* -------------------------------------------------------------------------------------------------
 * FieldsCommentsModal
 * -----------------------------------------------------------------------------------------------*/
export type FieldCommentsModalProps = {
  fields: Field[] | null;
  isOpen: boolean;
  onAddComment: (comment: {
    category: QualityControlCategory;
    checks?: QualityControlCheckKey;
    text: string;
  }) => void;
  onRequestClose: () => void;
};

export function FieldCommentsModal({
  fields,
  isOpen,
  onAddComment,
  onRequestClose,
}: FieldCommentsModalProps) {
  if (!fields) return null;

  return (
    <Modal.Root size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <Modal.Header>
        <Modal.Title>{I18n.t("js.admin.comments_modal.title")}</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <div className={styles.wrapper}>
          {/* Only show the  previous comments when a single field is selected */}
          {fields.length === 1 && (
            <Comments
              comments={
                fields[0].carbonFieldActual.carbonQualityControlComments
              }
            />
          )}

          <AddCommentForm onAddComment={onAddComment} />
        </div>
      </Modal.Content>
    </Modal.Root>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Comments
 * -----------------------------------------------------------------------------------------------*/
type CommentsProps = {
  comments: QualityControlComment[];
};

function Comments({ comments }: CommentsProps) {
  const sortedComments = useMemo(
    () =>
      orderBy(
        comments ?? [],
        [comment => new Date(comment.createdAt)],
        ["desc"],
      ),
    [comments],
  );

  if (!comments) {
    return null;
  }

  if (comments.length === 0) {
    return (
      <div className={styles.emptyComments}>
        {I18n.t("js.admin.comments_modal.empty_state")}
      </div>
    );
  }

  return (
    <div className={styles.comments}>
      {sortedComments.map(comment => (
        <Comment key={comment.id} comment={comment} />
      ))}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * CommentMessage
 * -----------------------------------------------------------------------------------------------*/
type CommentProps = {
  comment: QualityControlComment;
};

const Comment = ({ comment }: CommentProps) => (
  <div className={styles.comment}>
    <span className={styles.commentDate}>
      {formatRelativeWithoutTime(new Date(comment.createdAt), new Date())}
    </span>

    <span className={styles.commentPointer} />

    <span className={styles.commentMessage}>{comment.text}</span>

    <span className={styles.commentTag}>
      {comment.category}
      {comment.checks ? `: ${comment.checks}` : ""}
    </span>
  </div>
);

/* -------------------------------------------------------------------------------------------------
 * AddCommentForm
 * -----------------------------------------------------------------------------------------------*/
type AddCommentFormProps = {
  onAddComment: (comment: {
    category: QualityControlCategory;
    checks?: QualityControlCheckKey;
    text: string;
  }) => void;
};

const addCommentFormSchema = () =>
  z.object({
    category: z.nativeEnum(QualityControlCategory, {
      required_error: I18n.t("js.shared.required"),
    }),
    checks: z.nativeEnum(QualityControlCheckKey).optional(),
    text: z.string().min(1, I18n.t("js.shared.required")),
  });

type AddCommentFormData = z.infer<ReturnType<typeof addCommentFormSchema>>;

function AddCommentForm({ onAddComment }: AddCommentFormProps) {
  const { handleSubmit, control, reset, watch, resetField } =
    useForm<AddCommentFormData>({
      resolver: zodResolver(addCommentFormSchema()),
    });

  const category = watch("category");
  const checkKeys = useMemo(
    () => getQualityControlCheckKeys(category),
    [category],
  );

  useEffect(() => {
    resetField("checks");
  }, [checkKeys, resetField]);

  return (
    <form
      className={styles.form}
      onSubmit={handleSubmit(values => {
        onAddComment(values);
        reset();
      })}
    >
      <div className={styles.formSelects}>
        <Controller
          name="category"
          control={control}
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              {...fieldState}
              label={I18n.t("js.admin.comments_modal.category_label")}
              optionsClassName="z-modal"
            >
              {Object.entries(QualityControlCategory).map(([key, value]) => (
                <Select.Option key={key} value={value}>
                  {getQualityControlCategoryLabel(value)}
                </Select.Option>
              ))}
            </SelectField>
          )}
        />

        <Controller
          name="checks"
          control={control}
          render={({ field, fieldState }) => (
            <SelectField
              {...field}
              {...fieldState}
              value={field.value ?? ""}
              label={I18n.t("js.admin.comments_modal.check_key_label")}
              optionsClassName="z-modal"
              isDisabled={!checkKeys.length}
            >
              {checkKeys.map(key => (
                <Select.Option key={key} value={key}>
                  {getQualityControlCheckKeyLabel(key)}
                </Select.Option>
              ))}
            </SelectField>
          )}
        />
      </div>

      <Controller
        name="text"
        control={control}
        render={({ field, fieldState }) => (
          <TextareaField
            {...field}
            {...fieldState}
            value={field.value ?? ""}
            label={I18n.t("js.admin.comments_modal.comment_label")}
          />
        )}
      />

      <Button type="submit">
        {I18n.t("js.admin.comments_modal.add_comment")}
      </Button>
    </form>
  );
}
