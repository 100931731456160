/**
 * Trim spaces around separators (-,;,) and from the ends of the input
 */
export const trimAndDispatchInput = (
  input: string,
  onChange: (value: string) => void,
) => {
  const trimmedInput = input.replace(/\s*([-;,])\s*/g, (_, p1) => p1).trim();
  onChange(trimmedInput);
};
