const IconPolygon = ({ strokeColor = "#FFFFFF", fillColor = "#9281FF" }) => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.61533 3.53846L2.23071 14.3077L12.9999 22L23.7692 12.7692L17.8461 2L7.61533 3.53846Z"
      fill={fillColor}
      stroke={strokeColor}
      strokeWidth="2"
    />
  </svg>
);

export default IconPolygon;
