import I18n from "@ag/i18n";
import { usePagination } from "@ag/utils/hooks";

import Table from "~components/table";
import { useApplicationSettingsTable } from "~features/application-settings";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";
import { useApplicationSettingsQuery } from "~queries/application-settings";

const ApplicationSettingsList = () => {
  const [pagination, updatePagination] = usePagination();

  const { data, isLoading } = useApplicationSettingsQuery(pagination);

  const table = useApplicationSettingsTable(data?.applicationSettings);

  return (
    <ListLayout.Root>
      <ListLayout.TopBar key="topbar">
        <ListLayout.TopBarTitle>
          {I18n.t("js.admin.application_settings")}
        </ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar key="sidebar">
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content key="content">
        <Table
          instance={table}
          meta={data?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default ApplicationSettingsList;
