import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  UpdateRequest,
  UpdateRequestAction,
  UpdateRequestSchema,
  UpdateRequestStatus,
} from "../entities/update-request";

type GetUpdateRequestsParams = MetaArgs & {
  filters?: Partial<{
    approverAdminId: string;
    requesterAdminId: string;
    status: UpdateRequestStatus;
    action: UpdateRequestAction;
  }>;
};

export const generateUpdateRequestsQueryKey = (
  params?: GetUpdateRequestsParams | undefined,
) => ["update-requests", params].filter(Boolean);

const getUpdateRequests = async (params?: GetUpdateRequestsParams) => {
  const response = await axios.get<CommonPaginatedResponse<UpdateRequest>>(
    "/api/v1/certificates/groups/update-requests",
    {
      params: {
        ...params,
        limit: params?.limit || DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(UpdateRequestSchema),
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useUpdateRequestsQuery = (
  params?: GetUpdateRequestsParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateUpdateRequestsQueryKey(params),
    () => getUpdateRequests(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );
