import axios from "axios";

import { AuthData, AuthDataSchema } from "@ag/utils/schemas";
import { CommonResponse } from "@ag/utils/types";

export const refreshSession = async (refreshToken: string) => {
  const { data } = await axios.post<CommonResponse<AuthData>>(
    "/api/v1/auth/refresh",
    { refreshToken },
    {
      schema: AuthDataSchema,
      apiSource: "node-auth",
    },
  );

  return data.success!.data;
};
