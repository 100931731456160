import { FormApi } from "final-form";
import { useEffect, useMemo } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { ActionButton } from "@ag/components/buttons";
import { FormQuestionnaire } from "@ag/components/form";
import I18n from "@ag/i18n";

import { SFormSubmitButtonContainer } from "~forms/styled";

import { getQuestionnaireConfig } from "./helpers";
import { SoilCultivationPracticeData } from "./types";

type Props = {
  isReadonly?: boolean;
  isSubmitButtonVisible?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<SoilCultivationPracticeData>) => void;
};

export const SoilCultivationPracticeForm = ({
  isSubmitButtonVisible = true,
  isReadonly = false,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<SoilCultivationPracticeData>();
  const { pristine } = useFormState<SoilCultivationPracticeData>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const config = useMemo(
    () => getQuestionnaireConfig(isReadonly),
    [isReadonly],
  );

  return (
    <>
      <Field
        name="questionnaire"
        questions={config}
        component={FormQuestionnaire}
        testTag="questionnaire"
      />

      {isSubmitButtonVisible && !isReadonly && (
        <SFormSubmitButtonContainer>
          <ActionButton
            HTMLType="submit"
            testTag="soilCultivationPractices-submitButton"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </ActionButton>
        </SFormSubmitButtonContainer>
      )}
    </>
  );
};
