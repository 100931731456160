import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Label } from "@ag/design-system/atoms";

import {
  useSubmitCertificatesMutation,
  useUpdateRequestQuery,
  useUpdateRequestStore,
  useUpdateRequestSummaryQuery,
} from "~features/certificate";

type Props = {
  onGoPrevStep: () => void;
  unblockRoute: () => void;
};

const SubmitStep = ({ onGoPrevStep, unblockRoute }: Props) => {
  const navigate = useRef(useNavigate());

  const { client, setClient, setValidationResult, setSuccessModalAction } =
    useUpdateRequestStore();

  const { requestId } = useParams<{ requestId: string }>();

  const { data: updateRequest } = useUpdateRequestQuery(requestId);
  const { data: summary } = useUpdateRequestSummaryQuery(requestId);
  const submitCertificatesMutation = useSubmitCertificatesMutation();

  const handleSubmit = () => {
    if (!requestId || !updateRequest) throw new Error("Request Id is required");
    if (!client) throw new Error("Client is required");

    unblockRoute();

    submitCertificatesMutation.mutate(
      {
        updateRequestId: requestId,
        clientId: client.id,
      },
      {
        onSuccess: () => {
          setClient(null);
          setValidationResult(null);
          setSuccessModalAction(updateRequest.action);
          navigate.current("/certificates-management");
        },
      },
    );
  };

  return (
    <>
      <div className="mx-auto flex w-[460px] flex-auto flex-col gap-12">
        <div className="flex flex-col gap-3">
          <h2 className="pt-4 text-center text-h2">Review before submitting</h2>
          <p className="text-center text-p2">
            Are you sure you want to perform this action?
          </p>
        </div>

        <div className="bg-white shadow flex flex-col justify-center gap-4 rounded border border-grey-200 bg-white-100 p-8">
          <div className="text-center">
            <Label>Total certificates</Label>
            <h2 className="text-h2 font-bold">{summary?.quantity ?? 0}</h2>
          </div>

          <div className="text-center">
            <Label>
              {updateRequest?.action === "transfer" ? "Transferred" : "Retired"}{" "}
              from:
            </Label>
          </div>

          <div>
            <div className="grid grid-cols-[1fr_auto_1fr] items-center gap-4">
              <div className="flex flex-col gap-1">
                <div className="rounded bg-grey-200 p-2 text-left">Agreena</div>
              </div>

              <span>to</span>

              <div className="flex flex-col gap-1">
                <div className="rounded bg-green-100 p-2 text-left">
                  {client?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="flex h-[104px] items-center justify-between gap-12 px-8 shadow-[0_-4px_8px_rgba(0,0,0,0.05)]">
        <div className="flex flex-1 justify-start">
          <Button
            icon="chevron-left"
            variant="secondary"
            onClick={onGoPrevStep}
          >
            Previous
          </Button>
        </div>

        <div className="flex flex-1 justify-end">
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </footer>
    </>
  );
};

export default SubmitStep;
