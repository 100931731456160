import axios from "axios";

type FileDetails = {
  staticFileId: string;
  filename: string;
};

export const downloadClientDocument = async (fileDetails: FileDetails) => {
  try {
    const response = await axios.get<{
      success: { statusCode: number; data: string };
    }>(`/api/v1/clients/files/${fileDetails.staticFileId}`, {
      apiSource: "node-markets",
    });

    if (response.data.success.statusCode !== 200) {
      throw new Error("Failed to fetch file URL");
    }

    const fileUrl = response.data.success.data;

    const fileResponse = await axios.get<Blob>(fileUrl, {
      responseType: "blob",
    });

    const url = URL.createObjectURL(fileResponse.data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileDetails.filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Failed to download document:", error);
    throw new Error("Failed to download document");
  }
};
