import clsx from "clsx";

/* -------------------------------------------------------------------------------------------------
 * Card
 * -----------------------------------------------------------------------------------------------*/
function Card({ children, className }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        "flex flex-grow flex-col gap-3 rounded bg-white-100 p-4",
        className,
      )}
    >
      {children}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * CardTitle
 * -----------------------------------------------------------------------------------------------*/
function CardTitle({ children }: React.HTMLAttributes<HTMLHeadingElement>) {
  return <h4 className="mb-1 text-h4">{children}</h4>;
}

const Root = Card;
const Title = CardTitle;

export default { Root, Title };
