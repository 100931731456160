import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse, LabelValue } from "@ag/utils/types";

import { YearsData, YearsDataSchema } from "../entities/years-data";

const getYearsData = async () => {
  const response = await axios.get<CommonResponse<YearsData>>(
    "/api/v1/years-management/years-data",
    {
      schema: YearsDataSchema,
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

type UseYearsDataOptions<T> = {
  select?: (data: YearsData) => T;
};

export const useYearsDataQuery = <T = YearsData>(
  options?: UseYearsDataOptions<T>,
) =>
  useQuery(["years-data"], getYearsData, {
    staleTime: Infinity,
    ...options,
  });

export const useOpenHarvestYearsOptions = () =>
  useYearsDataQuery<LabelValue<number>[]>({
    select: data =>
      data.allHarvestYears.map(year => ({
        label: year.toString(),
        value: year,
      })),
  });

export const useCurrentHarvestYear = () =>
  useYearsDataQuery<number>({
    select: data => data.carbonCurrentHarvestYear,
  });

export const useActiveHarvestYears = () =>
  useYearsDataQuery<number[]>({
    select: data => data.carbonActiveHarvestYears,
  });
