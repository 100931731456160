import arrayMutators from "final-form-arrays";
import { useState } from "react";
import { Form } from "react-final-form";

import { FertiliserForm } from "@ag/carbon/forms/inputs-v2022";
import { stack } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { FieldStep } from "@ag/utils/types";

import CollapseBox from "~components/collapse-box";
import { useFertilisersQuery } from "~features/fertiliser";
import { FieldActualsV2022, FieldActualsV2022Input } from "~features/field";

import AdminEditActualsComment, {
  ActualsData,
} from "../../edit-actuals-comment-modal";
import { getInitialValues } from "./helpers";

type Props = {
  fieldId: string;
  fieldActuals: FieldActualsV2022;
  harvestYear: number;
  isReadonly: boolean;
};

const Fertiliser = ({
  fieldId,
  fieldActuals,
  harvestYear,
  isReadonly,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitFormData, setSubmitFormData] = useState<ActualsData | null>(
    null,
  );

  const { data: fertilisers } = useFertilisersQuery({
    limit: 100,
    filters: {
      harvestYear,
      methodologyVersion: fieldActuals?.methodologyVersion,
    },
  });

  const handleSubmit = (values: Partial<FieldActualsV2022Input>) => {
    setSubmitFormData({ carbonFieldActualInput: values });
    setIsModalOpen(true);
  };

  if (!fertilisers) return null;

  const initialValues = getInitialValues(fieldActuals.carbonFieldActualInput);

  return (
    <div className={stack()}>
      <Form
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        onSubmit={handleSubmit}
        render={() => (
          <>
            <CollapseBox.Root isInitiallyOpen>
              <CollapseBox.Title>
                {I18n.t("js.carbon.fertiliser_title")}
              </CollapseBox.Title>
              <CollapseBox.Content>
                <FertiliserForm
                  fertilisers={fertilisers.data}
                  step={FieldStep.Actuals}
                  isReadonly={isReadonly}
                />
              </CollapseBox.Content>
            </CollapseBox.Root>
          </>
        )}
      />

      <AdminEditActualsComment
        fieldId={fieldId}
        fieldActualsId={fieldActuals.id}
        actualsData={submitFormData}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Fertiliser;
