import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { MultiSelect } from "@ag/design-system/molecules";
import { grid, stack } from "@ag/design-system/utils";
import { InputField, MultiSelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import BackButton from "~components/BackButton";
import { useAdminRolesQuery, useCreateAdminMutation } from "~features/admin";
import { AuthorizedSidebar } from "~features/navigation";
import { Page, withPageAccess } from "~features/permission";
import DetailsLayout from "~layouts/details-layout";

const formSchema = z
  .object({
    email: z.string().email(),
    password: z.string(),
    passwordConfirmation: z.string(),
    roles: z.array(z.string()).nonempty(),
  })
  .superRefine(({ password, passwordConfirmation }, ctx) => {
    if (password && !passwordConfirmation) {
      ctx.addIssue({
        message: I18n.t("js.shared.required"),
        code: z.ZodIssueCode.custom,
        path: ["passwordConfirmation"],
      });

      return;
    }

    if (password && passwordConfirmation && password !== passwordConfirmation) {
      ctx.addIssue({
        message: I18n.t("js.shared.password_not_matching"),
        code: z.ZodIssueCode.custom,
        path: ["passwordConfirmation"],
      });

      return;
    }
  });

type FormValues = z.infer<typeof formSchema>;

// TODO: Refactor and place under ~pages/
const AddAdmin = () => {
  const navigate = useNavigate();

  const { data: adminRoles } = useAdminRolesQuery();
  const createAdminMutation = useCreateAdminMutation();

  const {
    control,
    register,
    formState,
    handleSubmit: handleFormSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
  });

  const handleSubmit = (values: FormValues) => {
    createAdminMutation.mutate(values, {
      onSuccess: () => {
        navigate("/admins");
        ToastNotification.success(I18n.t("js.admin.admins.add_admin.success"));
      },
    });
  };

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>
          {I18n.t("js.admin.admins.add_admin.title")}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div className={stack()}>
          <div className={grid({ align: "start" })}>
            <InputField
              label={I18n.t("js.admin.admins.email")}
              {...register("email")}
              error={formState.errors.email}
            />

            <MultiSelectField
              control={control}
              name="roles"
              label={I18n.t("js.user_edit.profile.roles.label")}
              placeholder={I18n.t("js.shared.select")}
              renderSelection={selection =>
                I18n.t("js.shared.multi_select.selection", {
                  count: selection.length,
                })
              }
              variant="full-width"
            >
              {adminRoles?.map(role => (
                <MultiSelect.Item key={role.name} textValue={role.name}>
                  {role.name}
                </MultiSelect.Item>
              ))}
            </MultiSelectField>
          </div>

          <div className={grid({ align: "start" })}>
            <InputField
              label={I18n.t("js.admin.admins.password")}
              type="password"
              {...register("password")}
              error={formState.errors.password}
            />

            <InputField
              label={I18n.t("js.admin.admins.password_confirmation")}
              type="password"
              {...register("passwordConfirmation")}
              error={formState.errors.passwordConfirmation}
            />
          </div>

          <Button
            style={{ alignSelf: "end" }}
            isLoading={createAdminMutation.isLoading}
            onClick={handleFormSubmit(handleSubmit)}
          >
            {I18n.t("js.shared.add")}
          </Button>
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default withPageAccess(Page.Admins)(AddAdmin);
