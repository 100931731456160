import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { FieldBoundaries } from "../entities/field-boundaries";

export const generateAllFieldBoundariesQueryKey = (carbonFieldId: string) => [
  "all-field-boundaries",
  carbonFieldId,
];

export const getAllFieldBoundaries = async (carbonFieldId: string) => {
  const response = await axios.get<CommonResponse<FieldBoundaries[]>>(
    `/api/v1/field-boundaries/${carbonFieldId}`,
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useAllFieldBoundariesQuery = (
  carbonFieldId: string,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateAllFieldBoundariesQueryKey(carbonFieldId),
    () => getAllFieldBoundaries(carbonFieldId),
    options,
  );
