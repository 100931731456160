import { FieldRenderProps } from "react-final-form";

import { ButtonsGroup, ButtonsGroupCommonProps } from "~buttons";

type Props = FieldRenderProps<string | number> & ButtonsGroupCommonProps;

export const FormButtonsGroup = ({
  input,
  meta,
  onChangeCustom,
  ...rest
}: Props) => (
  <ButtonsGroup
    {...rest}
    value={input.value}
    name={input.name}
    error={meta.touched ? meta.error : null}
    onChange={value => {
      input.onChange(value);
      onChangeCustom && onChangeCustom(value);
    }}
  />
);
