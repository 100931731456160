import {
  OnChangeFn,
  RowSelectionState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo } from "react";

import {
  ChipCell,
  ChipCellValue,
  HeaderSelectionCell,
  LinkCell,
  LinkCellValue,
  SelectionCell,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { useTable } from "~components/table";

import { ValidationRequestField } from "../entities/validation-request-field";
import {
  getValidationRequestFieldStatusChip,
  getValidationRequestFieldStatusLabel,
} from "../helpers/lookups";

type TableData = {
  fieldName: string;
  fieldId: LinkCellValue;
  farmName: string;
  fieldSize: number | null;
  status: ChipCellValue;

  field: ValidationRequestField;
};

export const useValidationRequestFieldsTable = ({
  farms,
  fields,
  setSelection,
  selection,
  isSelectionEnabled,
}: {
  fields: ValidationRequestField[];
  farms: { id: string; name: string }[];
  isSelectionEnabled?: boolean;
  selection: RowSelectionState;
  setSelection: OnChangeFn<RowSelectionState>;
}) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(
    () => getRowData({ farms, fields }),
    [farms, fields],
  );

  return useTable<TableData>({
    columns: columns,
    data: tableData,
    getRowId: original => String(original.field.id),
    state: {
      rowSelection: selection,
    },
    enableMultiRowSelection: isSelectionEnabled ?? true,
    enableRowSelection: isSelectionEnabled ?? true,
    onRowSelectionChange: setSelection,
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.display({
      id: "select",
      header: HeaderSelectionCell,
      cell: SelectionCell,
    }),
    columnHelper.accessor("fieldId", {
      header: I18n.t("js.admin.validation_request.fields.field_id"),
      cell: LinkCell,
    }),
    columnHelper.accessor("fieldName", {
      header: I18n.t("js.admin.validation_request.fields.field_name"),
    }),
    columnHelper.accessor("farmName", {
      header: I18n.t("js.admin.validation_request.fields.farm_name"),
    }),
    columnHelper.accessor("fieldSize", {
      header: I18n.t("js.admin.validation_request.fields.field_size"),
    }),
    columnHelper.accessor("status", {
      header: I18n.t(
        "js.admin.validation_request.fields.field_validation_status",
      ),
      cell: ChipCell,
    }),
  ];
}

type GetRowDataArgs = {
  fields: ValidationRequestField[];
  farms: { id: string; name: string }[];
};

function getRowData({ fields, farms }: GetRowDataArgs): TableData[] {
  if (!fields?.length) return [];

  return fields.map(field => {
    return {
      fieldName: field.name,
      fieldId: {
        url: `/carbon/fields/${field.id}`,
        title: field.id,
      },
      farmName: farms.find(farm => farm.id === field.carbonFarmId)?.name ?? "-",
      status: {
        variant: getValidationRequestFieldStatusChip(field.validationStatus),
        label: getValidationRequestFieldStatusLabel(field.validationStatus),
      },

      fieldSize: field.sizeHa,

      field,
    };
  });
}
