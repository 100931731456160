import uniq from "lodash/uniq";

import { useActiveHarvestYears } from "~features/initial-resources";
import { useApplicationSettingQueryByName } from "~queries/application-settings";

import { Field } from "../entities/field";

export const useIsFieldActualsUnlockable = (
  fieldActuals: Field["carbonFieldActual"][number] | undefined,
) => {
  const { data: activeHarvestYears } = useActiveHarvestYears();

  const { data: carbonFieldActualsSetupYears } =
    useApplicationSettingQueryByName("carbon_field_actuals_setup_years");

  const { data: carbonFieldActualsSubmitYear } =
    useApplicationSettingQueryByName("carbon_field_actuals_submit_year");

  if (
    !fieldActuals ||
    !activeHarvestYears ||
    !carbonFieldActualsSetupYears ||
    !carbonFieldActualsSubmitYear
  ) {
    return false;
  }

  const carbonFieldActualsEditYears = uniq([
    ...carbonFieldActualsSetupYears,
    carbonFieldActualsSubmitYear,
  ]);

  return Boolean(
    fieldActuals.submittedAt &&
      !fieldActuals.qcStatus &&
      carbonFieldActualsEditYears.includes(String(fieldActuals.harvestYear)),
  );
};
