import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { createEnumParam, withDefault } from "serialize-query-params";
import { StringParam, useQueryParams } from "use-query-params";

import { ComboBox, Select } from "@ag/design-system/molecules";
import { parseSorting } from "@ag/design-system/organisms";
import { ComboBoxField, SelectField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import { TimeRange } from "~constants/date";
import { FieldValidationStatus } from "~features/field";
import { AuthorizedSidebar } from "~features/navigation";
import { useUsersSearch } from "~features/user";
import {
  ValidationFilters,
  ValidationFiltersSchema,
  ValidationRequestStatus,
  useValidationRequestsQuery,
  useValidationRequestsTable,
} from "~features/validation-request";
import { getValidationRequestStatusLabel } from "~features/validation-request/helpers/lookups";
import { getTimeRangeValue, transformedLabelValue } from "~helpers";
import { getTimeRangeOptions } from "~helpers/date";
import ListLayout from "~layouts/list-layout";
import { getValidationStatusLabel } from "~pages/_legacy/carbon/helpers";

const ValidationRequestStatusParam = createEnumParam(
  Object.values(ValidationRequestStatus),
);
const FieldValidationStatusParam = createEnumParam(
  Object.values(FieldValidationStatus),
);
const CreatedInRangeParam = createEnumParam(Object.values(TimeRange));

const ValidationRequests = () => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const [query, setQuery] = useQueryParams({
    userId: withDefault(StringParam, undefined),
    status: withDefault(ValidationRequestStatusParam, undefined),
    carbonFieldValidationStatus: withDefault(
      FieldValidationStatusParam,
      undefined,
    ),
    createdInRange: withDefault(CreatedInRangeParam, undefined),
  });

  const createdInRangeValue =
    query.createdInRange && query.createdInRange !== "none"
      ? getTimeRangeValue(query.createdInRange as TimeRange)
      : undefined;

  const { data: validationRequestsData, isLoading } =
    useValidationRequestsQuery({
      ...pagination,
      filters: {
        ...query,
        createdInRange: createdInRangeValue,
      },
      sort: parseSorting(sorting),
    });

  const {
    data: users,
    isLoading: isUsersLoading,
    onSearch: onUsersSearch,
  } = useUsersSearch();

  const fieldValidationStatusLabel = getValidationStatusLabel();

  const sentAtOptions = getTimeRangeOptions(["today", "past"]);

  const { control, handleSubmit, reset } = useForm<ValidationFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(ValidationFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<ValidationFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );

  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  const table = useValidationRequestsTable(validationRequestsData?.items, {
    sorting,
    setSorting,
  });

  const renderFilterBarItem = (
    key: keyof ValidationFilters,
    value: ValidationFilters[keyof ValidationFilters],
  ) => {
    const label = (
      {
        userId: "User ID",
        status: "Status",
        carbonFieldValidationStatus: "Field Validation Status",
        createdInRange: "Created In Range",
      } as unknown as Record<keyof ValidationFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Validation requests</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <Controller
            control={control}
            name="userId"
            render={({ field, fieldState }) => (
              <ComboBoxField
                {...field}
                {...fieldState}
                label="User"
                value={field.value ?? ""}
                emptyText="No users found"
                loadingText="Loading users..."
                isAsync
                isLoading={isUsersLoading}
                onInput={onUsersSearch}
                optionsClassName="z-modal"
              >
                {users.map(user => (
                  <ComboBox.Item
                    key={user.id}
                    textValue={`${user.name} (ID: ${user.id})`}
                  >
                    {user.name} (ID: {user.id})
                  </ComboBox.Item>
                ))}
              </ComboBoxField>
            )}
          />

          <Controller
            control={control}
            name="status"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Validation status"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.values(ValidationRequestStatus).map(status => (
                  <Select.Option key={status} value={status}>
                    {getValidationRequestStatusLabel(status)}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            control={control}
            name="carbonFieldValidationStatus"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Field validation status"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {Object.values(FieldValidationStatus).map(status => (
                  <Select.Option key={status} value={status}>
                    {fieldValidationStatusLabel[status]}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />

          <Controller
            control={control}
            name="createdInRange"
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Sent at"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {sentAtOptions.map(range => (
                  <Select.Option key={range.value} value={range.value}>
                    {range.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={validationRequestsData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default ValidationRequests;
