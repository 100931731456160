import { usePagination } from "@ag/utils/hooks";
import { Meta } from "@ag/utils/types";

import Table, { TableSortingState } from "~components/table";

import { InventoryImport } from "../entities/inventory-import";
import { useDiscardedInventoryImportsTable } from "../hooks/use-discarded-inventory-imports-table";
import EmptyState from "./empty-state";

type Props = {
  items?: InventoryImport[];
  meta?: Meta;
  isLoading: boolean;
  hasAdminAccess?: boolean;
  sorting: TableSortingState;
};

const DiscardedInventoryImportsTable = ({
  items,
  sorting,
  meta,
  isLoading,
  hasAdminAccess,
}: Props) => {
  const [pagination, updatePagination] = usePagination();

  const discardedTable = useDiscardedInventoryImportsTable(
    items,
    sorting,
    Boolean(hasAdminAccess),
  );

  return !isLoading && items?.length === 0 ? (
    <EmptyState />
  ) : (
    <Table
      instance={discardedTable}
      isLoading={isLoading}
      meta={meta}
      pagination={pagination}
      onPaginationChange={updatePagination}
    />
  );
};

export default DiscardedInventoryImportsTable;
