export const DEFAULT_ZOOM = 14;

export const MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.business",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        saturation: -50,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#c7e6ed",
      },
      {
        visibility: "on",
      },
    ],
  },
];

export const MIN_ZOOM = 5;
export const MAX_ZOOM = 20;

export const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  mapTypeControl: true,
  mapTypeControlOptions: {
    position: 6,
  },
  fullscreenControl: false,
  streetViewControl: false,
  zoomControl: true,
  styles: [
    ...MAP_STYLES,
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
    },
  ],
  gestureHandling: "greedy",
  minZoom: MIN_ZOOM,
  maxZoom: MAX_ZOOM,
  controlSize: 24,
  mapTypeId: "hybrid",
};

export const MAP_OPTIONS: google.maps.MapOptions = {
  ...DEFAULT_MAP_OPTIONS,
  zoomControl: true,
  controlSize: 24,
  maxZoom: 20,
  minZoom: 5,
};
