import { Field, withTypes } from "react-final-form";

import { ActionButton } from "@ag/components/buttons";
import { FormTextarea } from "@ag/components/form";
import { SFlexDiv } from "@ag/components/styled";
import { Modal } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { validate } from "./helpers";
import { CommentAttribute, CommentFormData } from "./types";

const { Form } = withTypes<CommentFormData>();

type Props = {
  isOpen: boolean;
  isSubmitting: boolean;
  onSubmit: (comment: string) => void;
  onClose: () => void;
};

const CommentModal = ({ isOpen, isSubmitting, onClose, onSubmit }: Props) => (
  <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
    <Modal.Content>
      <Form
        validate={validate}
        render={({ handleSubmit }) => (
          <>
            <Field
              label={I18n.t("js.carbon.field.comment")}
              name={CommentAttribute.Comment}
              component={FormTextarea}
              rows={8}
            />

            <SFlexDiv justifyContent="end" marginTop={16}>
              <ActionButton type="text" onClick={onClose}>
                {I18n.t("js.shared.cancel")}
              </ActionButton>

              <ActionButton
                isLoading={isSubmitting}
                onClick={() => handleSubmit()}
              >
                {I18n.t("js.shared.save")}
              </ActionButton>
            </SFlexDiv>
          </>
        )}
        onSubmit={({ comment }) => onSubmit(comment)}
      />
    </Modal.Content>
  </Modal.Root>
);

export default CommentModal;
