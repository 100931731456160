import { z } from "zod";

import {
  SoilCultivationPracticeAttribute,
  SoilCultivationPracticeQuestionnaireAttribute,
} from "./types";

const QuestionnaireAnswer = z.boolean().nullable();

export const SoilCultivationPracticeQuestionnaireDataSchema = z.object({
  [SoilCultivationPracticeQuestionnaireAttribute.CatchCropConsistentlyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.CoverCropWinterConsistentlyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.CoverCropSpringConsistentlyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.CropResidueBurnedInAnyOfTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.CropResidueMulchedConsistentlyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.CropResidueRemovedInAnyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.CultivatedFieldInTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.FertilizersMixedNitrogenInAnyOfTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.FertilizersOrganicNitrogenConsistentlyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.FertilizersSyntheticNitrogenInAnyOfTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.NitrificationInhibitorsConsistentlyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.NoCoverCropGrownInTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.RotatedCropsInTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.TillageConventionalInAnyOfTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.TillageNoTillConsistentlyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.CropProtectionConsistentlyTheLastFiveYears]:
    QuestionnaireAnswer,
  [SoilCultivationPracticeQuestionnaireAttribute.TillageReducedInAnyTheLastFiveYears]:
    QuestionnaireAnswer,
});

export const SoilCultivationPracticeDataSchema = z.object({
  [SoilCultivationPracticeAttribute.Questionnaire]:
    SoilCultivationPracticeQuestionnaireDataSchema.partial(),
});
