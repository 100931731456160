import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

import { Checkbox } from "@ag/design-system/atoms";
import { FormField } from "@ag/design-system/organisms";

type Props = {
  label: string | React.ReactNode;
  type?: "hidden";
  tooltip?: string;
  error?: Partial<FieldError>;
  isDisabled?: boolean;
  variant?: "description";
};

export const CheckboxField = forwardRef<HTMLInputElement, Props>(
  (
    { label, tooltip, type, variant, error, isDisabled = false, ...rest },
    ref,
  ) => {
    return (
      <FormField.Root type={type === "hidden" ? "hidden" : undefined}>
        <FormField.InlineLabel tooltip={tooltip} variant={variant}>
          <Checkbox
            {...rest}
            ref={ref}
            disabled={isDisabled}
            isInvalid={Boolean(error)}
          />
          {/* Micro adjustment to align better with checkbox */}
          <div style={{ flex: 1 }} className="-mt-px">
            {label}
          </div>
        </FormField.InlineLabel>

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

CheckboxField.displayName = "CheckboxField";
