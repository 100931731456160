import { cn } from "~utils";

type Props = {
  children: React.ReactNode;
  className?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  testid?: string;
};
export const Card = ({
  className,
  children,
  isSelected,
  isDisabled,
  onClick,
  testid,
}: Props) => (
  <div
    onClick={() => {
      if (!isDisabled && onClick) {
        onClick();
      }
    }}
    className={cn(
      "inline-block rounded-lg border-[1px] border-white-100 bg-grey-white p-4",
      {
        "cursor-pointer shadow-100 hover:border-sky-200": Boolean(onClick),
        "border-accent-100 bg-accent-100/10 shadow-200 hover:border-accent-100":
          isSelected,
        "border-grey-300 bg-grey-300 hover:border-grey-300": isDisabled,
        "cursor-not-allowed": isDisabled && Boolean(onClick),
      },
      className,
    )}
    data-testid={testid}
  >
    {children}
  </div>
);
