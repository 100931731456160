import Skeleton from "react-loading-skeleton";

import { style } from "@ag/ui";

import { SFieldPartial } from "./styled";

type Props = {
  numberOfPartials: number;
};

export const PartialsSkeleton = ({ numberOfPartials }: Props) => {
  const placeholders = [...Array(numberOfPartials).keys()];

  return (
    <div className="flex flex-col">
      {placeholders.map(item => (
        <SFieldPartial key={item}>
          <Skeleton
            baseColor={style.color.grey[200]}
            width="100%"
            height="52px"
            borderRadius={`${style.borderRadius}px`}
          />
        </SFieldPartial>
      ))}
    </div>
  );
};
