import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";
import { Row } from "react-table";

import {
  ChipCell,
  ChipCellValue,
  LinkCell,
  LinkCellValue,
  TextCell,
} from "@ag/design-system/organisms";

import { TableSortingState, useTable } from "~components/table";
import { getCountryWithFlag } from "~helpers/countries";

import { BuyoutAgreement } from "../entities/buyout-agreement";
import {
  getContractStatusChip,
  getPaymentStatusChip,
} from "../helpers/buyout-agreement-statuses";

type TableData = {
  id: LinkCellValue;
  userId: LinkCellValue;
  country: string;
  status: ChipCellValue | null;
  year: number;
  signerName: string;
  company: string;
  paymentStatus: ChipCellValue | null;

  buyoutAgreement: BuyoutAgreement;
};

export type ActionColumnProps = {
  row: Row<TableData>;
};

export const useBuyoutAgreementsTable = (
  data: BuyoutAgreement[] | undefined,
  state: TableSortingState,
  hasUserPermissions: boolean,
) => {
  const columns = useMemo(
    () => getColumns(hasUserPermissions),
    [hasUserPermissions],
  );
  const rowData = useMemo(() => getRowData(data), [data]);
  return useTable<TableData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: original => String(original.buyoutAgreement.id),
    state: {
      sorting: state.sorting,
    },
    onSortingChange: state.setSorting,
  });
};

function getColumns(hasUserPermissions: boolean) {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("id", {
      header: "Buyout Agreement ID",
      cell: LinkCell,
      enableSorting: true,
    }),
    columnHelper.accessor("userId", {
      header: "User ID",
      cell: hasUserPermissions ? LinkCell : TextCell,
    }),
    columnHelper.accessor("signerName", {
      header: "Signer Name",
    }),
    columnHelper.accessor("company", {
      header: "Company Name",
    }),
    columnHelper.accessor("status", {
      header: "Contract Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("paymentStatus", {
      header: "Payment Status",
      cell: ChipCell,
    }),
    columnHelper.accessor("year", {
      header: "Harvest year",
    }),
    columnHelper.accessor("country", {
      header: "Country",
      cell: ({ getValue }) => {
        const country = getValue();
        if (!country) return null;
        const { flag, name } = getCountryWithFlag(country);
        return `${flag} ${name}`;
      },
    }),
  ];
}

function getRowData(
  buyoutAgreements: BuyoutAgreement[] | undefined,
): TableData[] {
  if (!buyoutAgreements) return [];

  return buyoutAgreements.map(buyoutAgreement => ({
    id: {
      title: buyoutAgreement.id,
      url: `/carbon/agreements/buyout/${buyoutAgreement.id}`,
    },
    userId: {
      title: buyoutAgreement.userId,
      url: `/users/${buyoutAgreement.userId}`,
    },
    signerName: buyoutAgreement.signerDetails?.name ?? "-",
    company: buyoutAgreement.companyName ?? "-",
    country: buyoutAgreement.carbonCountry ?? "-",
    status: buyoutAgreement.status
      ? getContractStatusChip(buyoutAgreement.status)
      : null,
    paymentStatus: getPaymentStatusChip(buyoutAgreement.paymentStatus),
    year: buyoutAgreement.year,

    buyoutAgreement,
  }));
}
