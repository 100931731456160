import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

type RequestPasswordResetData = {
  email: string;
};

const requestPasswordReset = async (data: RequestPasswordResetData) => {
  await axios.post("/api/v1/auth/reset-password-request", data, {
    apiSource: "node-auth",
  });
};

export const useRequestPasswordResetMutation = () =>
  useMutation(requestPasswordReset, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
