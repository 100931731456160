import pick from "lodash/pick";

import {
  SoilCultivationPracticeAttribute,
  SoilCultivationPracticeData,
  SoilCultivationPracticeQuestionnaireAttribute,
  getQuestionnaireConfig,
} from "@ag/carbon/forms/baseline/iso";
import { LabelValue } from "@ag/utils/types";

import { FieldDefinitionDetails } from "~queries/carbon/fields";

export type QuestionnaireQuestion<T extends string> = {
  key: T;
  question: string;
  answers: LabelValue<string | number | boolean>[];
  dependency?: {
    key: T;
    value: string | number | boolean;
    rootQuestionKey?: T;
  };
  tooltip?: string;
  isOptional?: boolean;
};

export type QuestionnaireContextValues = {
  questions: QuestionnaireQuestion<string>[];
  value: QuestionnaireValue;
  errors: Record<string, string> | null | undefined;
  onChange: (key: string, value: string | number | boolean) => void;
};

export type QuestionnaireValue = Record<
  string,
  string | number | boolean | null
>;

type CheckDependencyValueMatchArgs = {
  questions: QuestionnaireQuestion<string>[];
  value: QuestionnaireValue;
  onDependencyValueMismatch: (questionKey: string) => void;
};

export const checkDependencyValueMatch = ({
  questions,
  value,
  onDependencyValueMismatch,
}: CheckDependencyValueMatchArgs) => {
  if (!value) {
    return;
  }

  Object.keys(value).forEach(questionKey => {
    const question = questions.find(question => question.key === questionKey);

    if (question?.dependency) {
      const { dependency } = question;

      if (value[dependency.key] !== dependency.value) {
        if (onDependencyValueMismatch) {
          onDependencyValueMismatch(questionKey);
        }
      }
    }
  });
};

export const usePartial = (
  fieldDefinition: FieldDefinitionDetails | undefined,
) => {
  const initialValues: SoilCultivationPracticeData | undefined = fieldDefinition
    ? pick<FieldDefinitionDetails, SoilCultivationPracticeAttribute>(
        fieldDefinition,
        Object.values(SoilCultivationPracticeAttribute),
      )
    : undefined;

  if (initialValues) {
    checkDependencyValueMatch({
      questions: getQuestionnaireConfig(),
      value: initialValues[SoilCultivationPracticeAttribute.Questionnaire],
      onDependencyValueMismatch: questionKey => {
        initialValues[SoilCultivationPracticeAttribute.Questionnaire][
          questionKey as SoilCultivationPracticeQuestionnaireAttribute
        ] = null;
      },
    });
  }

  return {
    initialValues,
  };
};
