import { useMutation } from "@tanstack/react-query";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";
import { postRequest } from "~lib/axios";

import { FieldsFilter } from "../fields";
import { fieldKeys } from "../fields/queries";
import { UnlockFieldActualsArgs } from "./types";

/**
 * CUSTOM MUTATION FUNCTIONS
 */
const unlockFieldActuals = async ({
  fieldId,
  fieldActualsId,
}: UnlockFieldActualsArgs) => {
  await postRequest({
    path: `/api/farmer/carbon/v1/admin/carbon_fields/${fieldId}/carbon_field_actuals/${fieldActualsId}/unlock`,
    body: {},
  });
};

/**
 * MUTATIONS HOOKS
 */
export const useUnlockFieldActualsMutation = () =>
  useMutation(unlockFieldActuals, {
    onSuccess: async (_, variables) => {
      const { carbonUserId } = variables;

      if (carbonUserId) {
        // Fields re-load - TODO: rethink the solution for the flickering button (for the fields re-loading time)
        queryClient.invalidateQueries(
          fieldKeys.userFields({
            filters: {
              [FieldsFilter.CarbonUserId]: carbonUserId,
            },
          }),
        );
      }
    },
    onError: error => ToastNotification.error(error),
  });
