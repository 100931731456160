import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config";

import { generateContractQueryKey } from "./get-contract";

export type UploadContractAmendmentDocumentArgs = {
  carbonContractId: string;
  contractAmendmentDocument: File;
};

const uploadContractAmendmentDocument = async ({
  carbonContractId,
  contractAmendmentDocument,
}: UploadContractAmendmentDocumentArgs) => {
  const formData = new FormData();
  formData.append("file", contractAmendmentDocument);

  const response = await axios.post(
    `/api/v1/carbon-fields/upload-inactive-contract-document/${carbonContractId}`,
    formData,
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!;
};

export const useUploadContractAmendmentDocumentMutation = () =>
  useMutation(uploadContractAmendmentDocument, {
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries(
        generateContractQueryKey({
          contractId: Number(variables.carbonContractId),
        }),
      );
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
