import { BuyoutAgreement } from "../entities/buyout-agreement";

export function isV2BuyoutAgreement(agreement: BuyoutAgreement) {
  return agreement.contractVersion && agreement.contractVersion.startsWith("2");
}

export function isV1BuyoutAgreement(agreement: BuyoutAgreement) {
  return (
    agreement.contractVersion === null ||
    agreement.contractVersion.startsWith("1")
  );
}
