import { useParams } from "react-router-dom";

import { EmptyState } from "@ag/design-system/organisms";

import BackButton from "~components/BackButton";
import { PdfViewer } from "~components/pdf-viewer";
import {
  BuyoutAgreementHeader,
  BuyoutAgreementOverview,
  useBuyoutAgreementDetailsQuery,
  useBuyoutAgreementPdfQuery,
} from "~features/buyout-agreements";
import { AuthorizedSidebar } from "~features/navigation";
import OverviewLayout from "~layouts/overview-layout";

const BuyoutAgreementDetails = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("Buyout agreement ID is required");
  }

  const { data: buyoutAgreement } = useBuyoutAgreementDetailsQuery(id);

  const { data: pdfUrl, isFetching } = useBuyoutAgreementPdfQuery(
    buyoutAgreement?.transferAgreementUrl ||
      buyoutAgreement?.saleAndPurchaseAgreementUrl ||
      "",
    {
      enabled: Boolean(
        buyoutAgreement?.transferAgreementUrl ||
          buyoutAgreement?.saleAndPurchaseAgreementUrl,
      ),
    },
  );

  return (
    <OverviewLayout.Root>
      <OverviewLayout.Sidebar>
        <AuthorizedSidebar />
      </OverviewLayout.Sidebar>

      <OverviewLayout.TopBar>
        <OverviewLayout.TopBarTitle>
          Buyout Agreement
        </OverviewLayout.TopBarTitle>
        <BackButton />
      </OverviewLayout.TopBar>

      <OverviewLayout.Content>
        {buyoutAgreement && (
          <div className="flex flex-col gap-8">
            <BuyoutAgreementHeader {...buyoutAgreement} />

            <BuyoutAgreementOverview {...buyoutAgreement} />

            <PdfViewer
              title="Agreement"
              url={pdfUrl}
              isFetching={isFetching}
              errorState={
                <EmptyState.Root className="mx-auto box-content flex h-96 max-w-[300px] flex-col items-center justify-center gap-6 text-left">
                  <EmptyState.Image key="image" />

                  <EmptyState.Title key="title">
                    <span className="text-h3 text-grey-900">
                      Viewing the agreement is not possible
                    </span>
                  </EmptyState.Title>

                  <EmptyState.Description key="description">
                    <p className="pb-4">
                      The document might not be loading for the following
                      reasons:
                    </p>

                    <ul className="list-disc pb-4 pl-5">
                      <li>
                        It is a recently created agreement that is still being
                        processed.
                      </li>

                      <li>The document was manually signed.</li>

                      <li>There is a technical error.</li>
                    </ul>

                    <p>Please try again later.</p>
                  </EmptyState.Description>
                </EmptyState.Root>
              }
            />
          </div>
        )}
      </OverviewLayout.Content>
    </OverviewLayout.Root>
  );
};

export default BuyoutAgreementDetails;
