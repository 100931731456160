import { TableMeta } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  UseTableOptions,
  useTable as useTableDS,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

export function useTable<T>(options: UseTableOptions<T>) {
  const texts: TableMeta<unknown>["texts"] = useMemo(
    () => ({
      close: I18n.t("js.shared.close"),
      copiedToClipboard: I18n.t("js.shared.copied_to_clipboard"),
      listOfAllValues: I18n.t("js.shared.list_of_all_values"),
      more: I18n.t("js.shared.more"),
      no: I18n.t("js.shared.no"),
      yes: I18n.t("js.shared.yes"),
    }),
    [],
  );

  return useTableDS({
    ...options,
    meta: {
      ...options.meta,
      texts: options.meta?.texts ?? texts,
    },
  });
}
