import { z } from "zod";

import { FieldValidationStatus } from "~features/field";

export const ValidationRequestFieldSchema = z.object({
  id: z.string(),
  name: z.string(),
  sizeHa: z.number(),
  carbonFarmId: z.string(),
  validationStatus: z.nativeEnum(FieldValidationStatus),
});

export type ValidationRequestField = z.infer<
  typeof ValidationRequestFieldSchema
>;
