import styled from "@emotion/styled";

export const SApplicationWrapper = styled.div`
  & + & {
    margin-top: 8px;
  }
`;

export const SApplicationRoundTitle = styled.h5`
  margin: 0;
  line-height: 24px;
  font-size: 12px;
  text-transform: uppercase;
`;
