import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import { ChipCell, ChipCellValue } from "@ag/design-system/organisms";

import { useList } from "~components/list";
import {
  BuyoutAgreement,
  getContractStatusChip as getBuyoutStatusChip,
} from "~features/buyout-agreements";
import {
  ResaleAgreement,
  getContractStatusChip as getResaleStatusChip,
} from "~features/resale-agreements";
import { useUserQuery } from "~features/user";

import { formatCurrency } from "../helpers/formatters";
import { AgreementType } from "../types/agreement-type";

type ListData = {
  userId: string;
  id: string;
  userName: string;
  company: string;
  status: ChipCellValue;
  year: number;
  country: string;
  totalPurchaseAmount: string;
};

export const useGeneratedAgreementRow = (
  data: ResaleAgreement | BuyoutAgreement | undefined,
  type: AgreementType,
  totalPurchaseAmount: string | undefined,
) => {
  const user = useUserQuery(data?.userId);

  const columns = useMemo(() => getColumns(), []);
  const rowData = useMemo(
    () => getRowData(data, user.data?.name, type, totalPurchaseAmount),
    [data, user.data?.name, type, totalPurchaseAmount],
  );
  return useList<ListData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
  });

  function getColumns() {
    const columnHelper = createColumnHelper<ListData>();

    return [
      columnHelper.accessor("id", {
        header: "Agreement Id",
      }),
      columnHelper.accessor("userId", {
        header: "User Id",
      }),
      columnHelper.accessor("userName", {
        header: "User Name",
      }),
      columnHelper.accessor("company", {
        header: "Company",
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: ChipCell,
      }),
      columnHelper.accessor("year", {
        header: "Year",
      }),
      columnHelper.accessor("country", {
        header: "Country",
      }),
      columnHelper.accessor("totalPurchaseAmount", {
        header: "Tot. Pur. Amount",
      }),
    ];
  }

  function getRowData(
    agreement: ResaleAgreement | BuyoutAgreement | undefined,
    userName: string | undefined,
    type: AgreementType,
    totalPurchaseAmount: string | undefined,
  ): ListData[] {
    if (!agreement) return [];

    return [
      {
        id: agreement.id,
        userId: agreement.userId,
        userName: userName ?? "-",
        company: (agreement as ResaleAgreement).companyName ?? "-",
        status: agreement.status
          ? type === AgreementType.Resale
            ? getResaleStatusChip((agreement as ResaleAgreement).status)
            : getBuyoutStatusChip((agreement as BuyoutAgreement).status!)
          : "-",
        year: agreement.year,
        country:
          ((agreement as ResaleAgreement).countryName ||
            (agreement as BuyoutAgreement).carbonCountry) ??
          "-",
        totalPurchaseAmount: totalPurchaseAmount
          ? formatCurrency(totalPurchaseAmount)
          : "-",
      },
    ];
  }
};
