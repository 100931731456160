export enum FieldDefinitionStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
  Submitted = "submitted",
}

export enum FieldActualsStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
  Submitted = "submitted",
}

export enum FieldStep {
  Definition = "definition",
  Actuals = "actuals",
}

/**
 * GUARDS
 */
export const isFieldDefinitionStatus = (
  step: FieldStep,
  status: FieldDefinitionStatus | FieldActualsStatus,
): status is FieldDefinitionStatus => step === FieldStep.Definition;

export const isFieldActualsStatus = (
  step: FieldStep,
  status: FieldDefinitionStatus | FieldActualsStatus,
): status is FieldActualsStatus => step === FieldStep.Actuals;
