import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button, Chip, Label } from "@ag/design-system/atoms";
import { MultiSelect } from "@ag/design-system/molecules";
import { grid, stack } from "@ag/design-system/utils";
import { InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import {
  useSessionContext,
  useUpdateCurrentAdminMutation,
} from "~features/auth";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";

const formSchema = z
  .object({
    email: z.string().email(),
    password: z.string(),
    passwordConfirmation: z.string(),
  })
  .superRefine(({ password, passwordConfirmation }, ctx) => {
    if (password && !passwordConfirmation) {
      ctx.addIssue({
        message: I18n.t("js.shared.required"),
        code: z.ZodIssueCode.custom,
        path: ["passwordConfirmation"],
      });

      return;
    }

    if (password && passwordConfirmation && password !== passwordConfirmation) {
      ctx.addIssue({
        message: I18n.t("js.shared.password_not_matching"),
        code: z.ZodIssueCode.custom,
        path: ["passwordConfirmation"],
      });

      return;
    }
  });

type FormValues = z.infer<typeof formSchema>;

const Account = () => {
  const { currentAdmin } = useSessionContext();

  const updateCurrentAdminMutation = useUpdateCurrentAdminMutation();

  const {
    register,
    formState,
    handleSubmit: handleFormSubmit,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: currentAdmin?.email,
    },
  });

  const handleSubmit = (values: FormValues) => {
    updateCurrentAdminMutation.mutate(values, {
      onSuccess: () =>
        ToastNotification.success(
          I18n.t("js.admin.current_admin_profile.successfully_updated"),
        ),
      onError: error => ToastNotification.error(error),
    });
  };

  return (
    <DetailsLayout.Root>
      <DetailsLayout.TopBar>
        <DetailsLayout.TopBarTitle>
          {I18n.t("js.admin.current_admin_profile.title")}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.Content>
        <div className={stack()}>
          <div className={grid({ align: "start" })}>
            <InputField
              label={I18n.t("js.admin.admins.email")}
              {...register("email")}
              error={formState.errors.email}
            />

            <div className={stack({ gap: 8 })}>
              <Label>{I18n.t("js.user_edit.profile.roles.label")}</Label>

              <MultiSelect.Root
                ariaLabel={I18n.t("js.user_edit.profile.roles.label")}
                values={currentAdmin?.adminRoles?.map(roles => roles.name)}
                placeholder={I18n.t("js.shared.select")}
                renderSelection={selection =>
                  selection?.length > 0
                    ? selection.map(selectedOption => (
                        <Chip key={String(selectedOption?.key)} variant="info">
                          {selectedOption?.props.children}
                        </Chip>
                      ))
                    : null
                }
                variant="full-width"
                isDisabled
                onChange={() => {}}
              >
                {currentAdmin?.adminRoles?.map(role => (
                  <MultiSelect.Item
                    key={role.name}
                    textValue={String(role.name)}
                  >
                    {role.name}
                  </MultiSelect.Item>
                ))}
              </MultiSelect.Root>
            </div>
          </div>

          <div className={grid({ align: "start" })}>
            <InputField
              label={I18n.t("js.admin.admins.password")}
              type="password"
              {...register("password")}
              error={formState.errors.password}
            />

            <InputField
              label={I18n.t("js.admin.admins.password_confirmation")}
              type="password"
              {...register("passwordConfirmation")}
              error={formState.errors.passwordConfirmation}
            />
          </div>

          <Button
            style={{ alignSelf: "end" }}
            isLoading={updateCurrentAdminMutation.isLoading}
            onClick={handleFormSubmit(handleSubmit)}
          >
            {I18n.t("js.shared.update")}
          </Button>
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default Account;
