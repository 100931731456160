import { format } from "date-fns";

import { Chip, ChipLookup } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import DataCard from "~components/data-card";
import { AssuranceStatus } from "~features/assurance";
import {
  Field,
  FieldValidationStatus,
  getAssuranceStatusLabel,
  getValidationStatusLabel,
} from "~features/field";
import { useFieldHarvestYearContext } from "~features/harvest-year";
import { QualityControlStatus } from "~features/quality-control/entities/quality-control";
import { getQualityControlStatusLabel } from "~features/quality-control/helpers/get-labels";

import * as styles from "./field-overview.css";

type Props = {
  field: Field;
};

export const FieldOverview = ({ field }: Props) => {
  const { selectedHarvestYear } = useFieldHarvestYearContext();

  if (!selectedHarvestYear) return null;

  // Statuses
  const { qaStatus, validationStatus } = field;
  const qcStatus = field.carbonFieldActual?.[0]?.qcStatus;

  return (
    <section className={styles.root}>
      <DataCard.Root>
        <DataCard.Title>
          {I18n.t("js.carbon.field_details.user_details")}
        </DataCard.Title>

        <DataCard.Row highlight>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.user_id")}
          </DataCard.Label>
          <DataCard.Value>
            <a
              href={`/users/${field.user.id}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              {field.user.id}
            </a>
          </DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.farmer_name")}
          </DataCard.Label>
          <DataCard.Value>
            {field.user.firstName} {field.user.lastName}
          </DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.company")}
          </DataCard.Label>
          <DataCard.Value>{field.user.company.name}</DataCard.Value>
        </DataCard.Row>
      </DataCard.Root>

      <DataCard.Root>
        <DataCard.Title>
          {I18n.t("js.carbon.field_details.field_details")}
        </DataCard.Title>

        <DataCard.Row highlight>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.field_id")}
          </DataCard.Label>
          <DataCard.Value>{field.id}</DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.field_name")}
          </DataCard.Label>
          <DataCard.Value>{field.name}</DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.field_size")}
          </DataCard.Label>
          <DataCard.Value>{field.sizeHa}</DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.farm_name")}
          </DataCard.Label>
          <DataCard.Value>{field.carbonFarm.name}</DataCard.Value>
        </DataCard.Row>
      </DataCard.Root>

      <DataCard.Root>
        <DataCard.Title>
          {I18n.t("js.carbon.field_details.field_status")}
        </DataCard.Title>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.field_definition_submitted")}
          </DataCard.Label>
          <DataCard.Value>
            {field.carbonFieldDefinition.submittedAt ? (
              <Chip variant="info">
                {format(
                  new Date(field.carbonFieldDefinition.submittedAt),
                  "yyyy",
                )}
              </Chip>
            ) : (
              "-"
            )}
          </DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.validation_status")}
          </DataCard.Label>
          <DataCard.Value>
            <Chip
              variant={
                (
                  {
                    new: "neutral",
                    in_progress: "info",
                    validated: "success",
                    rejected: "danger",
                  } as ChipLookup<FieldValidationStatus>
                )[validationStatus]
              }
            >
              {getValidationStatusLabel(validationStatus)}
            </Chip>
          </DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.contract_id")}
          </DataCard.Label>
          <DataCard.Value>
            {field.activeCarbonContractId ? (
              <a
                href={`/carbon/contracts/${field.activeCarbonContractId}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                {field.activeCarbonContractId}
              </a>
            ) : (
              "-"
            )}
          </DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.contract_year")}
          </DataCard.Label>
          <DataCard.Value>
            {field.activeCarbonContractId ? (
              <Chip variant="info">{field.harvestYear}</Chip>
            ) : (
              "-"
            )}
          </DataCard.Value>
        </DataCard.Row>
      </DataCard.Root>

      <DataCard.Root>
        <DataCard.Title>{I18n.t("js.carbon.field_details.mrv")}</DataCard.Title>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.baseline_version")}
          </DataCard.Label>
          <DataCard.Value>
            {field.carbonFieldDefinition.baselineVersion}
          </DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.methodology_version")} (
            {selectedHarvestYear})
          </DataCard.Label>
          <DataCard.Value>
            {field.carbonFieldActual?.[0]
              ? field.carbonFieldActual[0].methodologyVersion
              : "-"}
          </DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.qa_status")}
          </DataCard.Label>
          <DataCard.Value>
            {qaStatus ? (
              <Chip
                variant={
                  (
                    {
                      approved: "success",
                      non_compliance: "danger",
                      non_conformance: "danger",
                      processing: "info",
                    } as ChipLookup<AssuranceStatus>
                  )[qaStatus]
                }
              >
                {getAssuranceStatusLabel(qaStatus)}
              </Chip>
            ) : (
              "-"
            )}
          </DataCard.Value>
        </DataCard.Row>

        <DataCard.Row>
          <DataCard.Label>
            {I18n.t("js.carbon.field_details.qc_status")} ({selectedHarvestYear}
            )
          </DataCard.Label>

          <DataCard.Value>
            {qcStatus ? (
              <Chip
                variant={
                  (
                    {
                      qc_approved: "success",
                      non_compliance: "danger",
                      non_conformance: "danger",
                      processing: "info",
                    } as ChipLookup<QualityControlStatus>
                  )[qcStatus]
                }
              >
                {getQualityControlStatusLabel(qcStatus)}
              </Chip>
            ) : (
              "-"
            )}
          </DataCard.Value>
        </DataCard.Row>
      </DataCard.Root>
    </section>
  );
};
