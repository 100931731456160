import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { FieldActualsInputType } from "@ag/carbon/types";
import { Tabs, useTabs } from "@ag/design-system/organisms";

import BackButton from "~components/BackButton";
import {
  CalculationsOverview,
  FieldActualsV2021Overview,
  FieldActualsV2022Overview,
  FieldDefinitionOverview,
  FieldOverview,
  getStepStatusIcon,
  getStepStatusVariant,
  useFieldQuery,
} from "~features/field";
import {
  HarvestYearsOptions,
  useFieldHarvestYearContext,
} from "~features/harvest-year";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";

const FieldDetails = () => {
  const { activeTab, onChange: onTabChange } = useTabs({
    initialValue: "definition",
    tabParamName: "step",
    replace: true,
  });

  const params = useParams<{ fieldId: string }>();
  const fieldId = Number(params.fieldId);

  const { selectedHarvestYear, harvestYearsOpen, handleSelectYear } =
    useFieldHarvestYearContext();

  const { data: field } = useFieldQuery(
    fieldId,
    { actualHarvestYear: selectedHarvestYear },
    {
      enabled: Boolean(selectedHarvestYear),
    },
  );

  const fieldDefinition = field?.carbonFieldDefinition;
  const [fieldActuals] = field?.carbonFieldActual ?? [];

  useEffect(() => {
    if (!field) return;

    if (activeTab === "actuals" && !fieldActuals) {
      onTabChange("definition");
    }
  }, [activeTab, field, fieldActuals, onTabChange]);

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>
          {field ? `Carbon - "${field.name}"` : "Carbon"}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div className="flex flex-col space-y-6">
          {harvestYearsOpen && (
            <HarvestYearsOptions
              years={harvestYearsOpen}
              selectedYear={selectedHarvestYear}
              onSelectedYearChange={handleSelectYear}
            />
          )}

          {field && (
            <>
              <FieldOverview field={field} />

              <Tabs.Root value={activeTab} onChange={onTabChange}>
                <Tabs.List>
                  <Tabs.Trigger value="definition">
                    <Tabs.TriggerChip
                      variant={getStepStatusVariant(fieldDefinition?.status)}
                      icon={getStepStatusIcon(fieldDefinition?.status)}
                    />
                    <Tabs.TriggerTitle>Field definition</Tabs.TriggerTitle>
                  </Tabs.Trigger>

                  <Tabs.Trigger value="actuals" isDisabled={!fieldActuals}>
                    <Tabs.TriggerChip
                      variant={getStepStatusVariant(fieldActuals?.status)}
                      icon={getStepStatusIcon(fieldActuals?.status)}
                    />
                    <Tabs.TriggerTitle>Field actuals</Tabs.TriggerTitle>
                  </Tabs.Trigger>

                  <Tabs.Trigger value="calculations">
                    <Tabs.TriggerTitle>Calculations</Tabs.TriggerTitle>
                  </Tabs.Trigger>
                </Tabs.List>

                <Tabs.Content value="definition">
                  <FieldDefinitionOverview field={field} />
                </Tabs.Content>

                <Tabs.Content value="actuals">
                  {fieldActuals?.carbonFieldActualInputType ===
                    FieldActualsInputType.V2021 && (
                    <FieldActualsV2021Overview field={field} />
                  )}

                  {fieldActuals?.carbonFieldActualInputType ===
                    FieldActualsInputType.V2022 && (
                    <FieldActualsV2022Overview field={field} />
                  )}
                </Tabs.Content>

                <Tabs.Content value="calculations">
                  <CalculationsOverview field={field} />
                </Tabs.Content>
              </Tabs.Root>
            </>
          )}
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default FieldDetails;
