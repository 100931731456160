import I18n from "@ag/i18n";
import { withEmptyPlaceholder } from "@ag/utils/helpers";
import { KeyMap } from "@ag/utils/types";

import { FieldOverview } from "../entities/field-overview";

export const mapFieldsToCSVData = (
  fields: FieldOverview[] | undefined,
): KeyMap<string | number | number[]>[] | null => {
  if (!fields) {
    return null;
  }

  const data = fields.map(field => {
    const completionRate = `${field.completionRate * 100}`;

    return {
      [I18n.t("js.carbon.field_id")]: field.id,
      [I18n.t("js.carbon.field_name_or_identifier")]: field.name,
      [I18n.t("js.shared.user_id")]: field.user.id,
      [I18n.t("js.carbon.carbon_user_id")]: field.carbonUserId,
      [I18n.t("js.shared.company_name")]: field.user.company.name,
      [I18n.t("js.carbon.completion_rate")]: completionRate,
      [I18n.t("js.carbon.report_requested")]: field.carbonFieldDefinition
        .submittedAt
        ? I18n.t("js.shared.yes")
        : I18n.t("js.shared.no"),
      [I18n.t("js.carbon.base_line")]: withEmptyPlaceholder(
        field.carbonFieldDefinition.baseline,
      ),
    };
  });

  return data;
};
