import styled from "@emotion/styled";

import { colors } from "@ag/design-system/tokens";

export const SBanner = styled.div`
  fontSize: 1rem,
  lineHeight: 1.5,
  fontWeight: 400,

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  padding: 8px;

  background: ${colors.data.orange[600]};
  color: ${colors.white[100]};
  border-radius: 4px;
`;
