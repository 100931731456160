type NilsToEmptyStrings<TValue extends object> = {
  [key in keyof TValue]: TValue[key] extends null | undefined
    ? ""
    : TValue[key];
};

function transformInitialFilterValues<TValues extends object>(values: TValues) {
  const lookup = { ...values } as NilsToEmptyStrings<TValues>;

  for (const key in lookup) {
    if (lookup[key] == null) {
      // Not ideal typings here, but it's really not easy to work with...
      lookup[key as keyof typeof lookup] =
        "" as (typeof lookup)[keyof typeof lookup];
    }
  }

  return lookup;
}

export default transformInitialFilterValues;
