import JsApiMakerI18n from "i18n-js";
import moment from "moment";

import { Locale, updateTranslations } from "@ag/i18n";
import { enTranslations } from "@ag/i18n/admin";

export const setupI18n = () => {
  const locale = document.querySelector("html")?.getAttribute("lang");

  if (locale) {
    moment.locale(locale);
  }

  // Pass the i18n instance used by js-api-maker, so it can get translations too.
  updateTranslations(Locale.En, enTranslations, JsApiMakerI18n);
};
