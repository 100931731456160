import { stringify } from "qs";

// import { ParsedQsValues } from "../types/vendors";

// type ParamValue = string | number | boolean;

type PrefixValue = "?" | "&" | null;

type SearchParamsOptions = {
  prefix?: PrefixValue;
};

export const getSearchParams = <T extends object | string | number | boolean>(
  searchParams: T,
  options?: SearchParamsOptions,
) => {
  if (!searchParams) {
    return "";
  }

  const { prefix = "?" } = options || {};
  const stringifiedSearchParams = stringify(searchParams, {
    arrayFormat: "repeat",
  });

  if (stringifiedSearchParams === "") {
    return "";
  }

  return `${prefix || ""}${stringifiedSearchParams}`;
};
