import I18n from "@ag/i18n";

import { QualityAssuranceStatus } from "../types/field-definitions";
import { FieldValidationStatus } from "../types/validation";

export const getAssuranceStatusLabel = (status: QualityAssuranceStatus) => {
  const lookup: Record<QualityAssuranceStatus, string> = {
    non_compliance: I18n.t("js.carbon.qa_status.non_compliance"),
    non_conformance: I18n.t("js.carbon.qa_status.non_conformance"),
    processing: I18n.t("js.carbon.qa_status.processing"),
    approved: I18n.t("js.carbon.qa_status.approved"),
  };

  return lookup[status] ?? null;
};

export const getValidationStatusLabel = (status: FieldValidationStatus) => {
  const lookup: Record<FieldValidationStatus, string> = {
    new: I18n.t("js.carbon.validation_status.new"),
    in_progress: I18n.t("js.carbon.validation_status.in_progress"),
    validated: I18n.t("js.carbon.validation_status.validated"),
    rejected: I18n.t("js.carbon.validation_status.rejected"),
  };

  return lookup[status] ?? null;
};
