export const FLAGS = [
  "blue",
  "green",
  "yellow",
  "red",
  "orange",
  "none",
] as const;

export type FlagValue = (typeof FLAGS)[number];
