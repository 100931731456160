import { FormApi } from "final-form";
import { useCallback, useContext } from "react";
import { withTypes } from "react-final-form";
import { Element } from "react-scroll";

import { PartialBox } from "@ag/carbon/components";
import {
  SoilCharacteristicsData,
  SoilCharacteristicsForm,
  validateSoilCharacteristicsData,
} from "@ag/carbon/forms/baseline/iso";
import I18n from "@ag/i18n";

import { getInitialResources } from "~helpers/initial-resources";
import {
  FieldDefinitionPartial,
  FieldPartialProps,
} from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/steps/FieldDefinition/FieldPartials/types";
import FieldDefinitionContext from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/steps/FieldDefinition/context";
import { useFarmQuery } from "~queries/carbon/farms";

import { usePartial } from "./hooks";

type Props = FieldPartialProps;

const { Form: FinalForm } = withTypes<SoilCharacteristicsData>();

const SoilCharacteristics = ({ field }: Props) => {
  const fieldDefinition = field?.carbonFieldDefinition;
  const { data: farm } = useFarmQuery(field.carbonFarmId);
  const initialResources = getInitialResources();

  const { setPartialFormAPI } = useContext(FieldDefinitionContext);

  const { initialValues } = usePartial(fieldDefinition);

  const handleFormInitialized = useCallback(
    (form: FormApi<SoilCharacteristicsData>) => {
      setPartialFormAPI({
        partialName: FieldDefinitionPartial.SoilCharacteristics,
        formAPI: form,
      });
    },
    [setPartialFormAPI],
  );

  const { countryCode } = farm?.carbonAddress || {};

  if (!fieldDefinition || !countryCode) return null;

  return (
    <Element name={FieldDefinitionPartial.SoilCharacteristics}>
      <FinalForm
        initialValues={initialValues}
        validate={validateSoilCharacteristicsData}
        render={(): React.ReactNode => (
          <PartialBox
            title={I18n.t("js.carbon.soil_characteristics")}
            headerTestTag="soilCharacteristics-collapseBox"
            isOpen
          >
            <SoilCharacteristicsForm
              countryCode={countryCode}
              fieldPartial={initialResources?.field}
              // TODO: Connect when API will be ready
              isSubmitting={false}
              isSubmitButtonVisible={false}
              onFormInitialize={handleFormInitialized}
            />
          </PartialBox>
        )}
        onSubmit={() => undefined}
      />
    </Element>
  );
};

export default SoilCharacteristics;
