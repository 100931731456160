import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";

import {
  useImportVerraVcuMutation,
  useImportVerraVcuStore,
} from "~features/import-vcu";

type Props = {
  onGoNextStep: () => void;
};

const validateVcuBatchImport = () =>
  z.object({
    vintage: z.string().nonempty({
      message: "Please select a year",
    }),
    verraProjectId: z.string(),
  });

export type ValidateVcuBatchImportFormValues = z.infer<
  ReturnType<typeof validateVcuBatchImport>
>;

const ImportBatch = ({ onGoNextStep }: Props) => {
  const { setVerraVcuDetails, verraVcuDetails, setIsFormValid } =
    useImportVerraVcuStore();
  const { vintageYear, verraProjectId } = verraVcuDetails;
  const importVerraMutation = useImportVerraVcuMutation();
  const {
    formState: { errors, isValid },
    register,
    handleSubmit,
  } = useForm<ValidateVcuBatchImportFormValues>({
    resolver: zodResolver(validateVcuBatchImport()),
    defaultValues: {
      vintage: vintageYear?.toString() || "2023",
      verraProjectId: verraProjectId || "",
    },
  });

  useEffect(() => {
    setIsFormValid(isValid);
  }, [isValid, setIsFormValid]);

  const onSubmit = (data: ValidateVcuBatchImportFormValues) => {
    const verraDetails = {
      vintageYear: Number(data.vintage),
      verraProjectId: data.verraProjectId,
    };

    setVerraVcuDetails(verraDetails);

    importVerraMutation.mutate(
      {
        year: verraDetails.vintageYear,
        resourceId: verraDetails.verraProjectId,
      },
      {
        onSuccess: () => {
          onGoNextStep();
        },
      },
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex w-full flex-auto flex-col gap-8 bg-grey-100 pt-8"
    >
      <div className="flex w-full flex-col items-center justify-start gap-8">
        <h2 className="text-h2 font-bold">
          Enter the vintage and Project ID from the Verra registry
        </h2>

        <div className="flex h-[320px] w-[1000px] items-center justify-center rounded-md border border-dashed border-accent-200 bg-grey-100 p-12">
          <div className="inline-flex h-[100px] gap-8">
            <InputField
              type="number"
              label="Vintage"
              placeholder="Select year"
              {...register("vintage")}
              error={errors.vintage}
            />

            <InputField
              type="number"
              label="Verra Project ID"
              placeholder="eg. 4022"
              {...register("verraProjectId")}
              error={errors.verraProjectId}
            />
          </div>
        </div>
      </div>
      <footer className="mt-auto flex h-[104px] w-full items-center p-8 shadow-[0_-4px_8px_rgba(0,0,0,0.05)]">
        <div className="mr-auto">
          <Button
            type="button"
            icon="chevron-left"
            iconPosition="before"
            variant="secondary"
            onClick={() => history.back()}
          >
            Previous
          </Button>
        </div>

        <div className="ml-auto">
          <Button
            type="submit"
            icon="chevron-right"
            iconPosition="after"
            isLoading={importVerraMutation.isLoading}
          >
            Next
          </Button>
        </div>
      </footer>
    </form>
  );
};

export default ImportBatch;
