import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config";

import { QualityControl } from "../entities/quality-control";
import { generateFieldsQueryKey as generateQualityControlQueryKey } from "./get-fields";

type RunFieldQualityControlParams = {
  year: number;
  fieldIds: number[] | string[];
};

type RunQualityControlResult = {
  meta: {
    carbonFieldId: number;
  };
} & (
  | {
      success: QualityControl;
      error?: never;
    }
  | {
      success?: never;
      error: {
        message: string;
      };
    }
);

export const runFieldsQualityControl = async ({
  year,
  fieldIds,
}: RunFieldQualityControlParams) => {
  const response = await axios.post<CommonResponse<RunQualityControlResult[]>>(
    "api/v1/carbon-fields/carbon-quality-controls/run",
    {
      year,
      fieldIds,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useRunFieldsQualityControlMutation = () =>
  useMutation(runFieldsQualityControl, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateQualityControlQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
