import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { Client, ClientSchema } from "../entities/client";

type ClientsParams = MetaArgs & {
  filters?: {
    name?: string;
  };
  sort?: {
    adminId?: SortingDirection;
    createdAt?: SortingDirection;
  };
};

export const generateClientsQueryKey = (keyVariables?: {
  params?: ClientsParams | undefined;
}) => ["clients", keyVariables].filter(Boolean);

const getClients = async (params: ClientsParams | undefined) => {
  const response = await axios.get<CommonPaginatedResponse<Client>>(
    "/api/v1/clients",
    {
      params: {
        ...params,
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(ClientSchema),
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useClientsQuery = (
  params?: ClientsParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(generateClientsQueryKey({ params }), () => getClients(params), {
    keepPreviousData: true,
    ...options,
  });
