import { FertiliserData } from "./types";

export const EMPTY_FERTILISER: FertiliserData = {
  applicationRate: null,
  carbonFertiliserId: null,
  emissionsInhibitors: null,
  nitrificationApplicationRate: null,
  nitrogenApplicationRate: null,
  mode: null,
  phosphorusApplicationRate: null,
  phosphorusType: null,
  potassiumApplicationRate: null,
  potassiumType: null,
};
