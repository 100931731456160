import axios, { AxiosRequestConfig } from "axios";
import qs from "qs";
import { ZodSchema } from "zod";

import I18n from "@ag/i18n";
import { ADMIN_AUTH_DATA_STORAGE_KEY } from "@ag/utils/constants";
import { AuthData } from "@ag/utils/schemas";
import { loadFromLocalStorage } from "@ag/utils/storage";

import { env } from "~config";
import { DEFAULT_MEDIA_VERSION } from "~constants/api";

import { handleAppReferer, validateResponseSchema } from "./helpers";

/* -------------------------------------------------------------------------------------------------
 * Defaults
 * -----------------------------------------------------------------------------------------------*/
axios.defaults.paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: "brackets" });
axios.defaults.timeout = 5 * 60 * 1000; // default timeout - 5 mins

/* -------------------------------------------------------------------------------------------------
 * Interceptors
 * -----------------------------------------------------------------------------------------------*/
axios.interceptors.request.use(
  config => {
    const { apiSource = "ruby", mediaVersion = DEFAULT_MEDIA_VERSION } = config;

    config.headers["Accept-Language"] = I18n.locale;
    config.headers["Media-Version"] = mediaVersion;

    // Set proper base URL based on the API service
    const baseUrl = {
      ruby: env.REACT_APP_API_RUBY_BASE_URL,
      "node-auth": env.REACT_APP_API_NODE_AUTH_BASE_URL,
      "node-auth-user": env.REACT_APP_API_NODE_AUTH_USER_BASE_URL,
      "node-user-mgt": env.REACT_APP_API_NODE_USER_MGT_BASE_URL,
      "node-carbon": env.REACT_APP_API_NODE_CARBON_BASE_URL,
      "node-markets": env.REACT_APP_API_NODE_MARKETS_BASE_URL,
      "crops-fertilisers": env.REACT_APP_API_CROPS_FERTILISERS_BASE_URL,
    };
    config.baseURL = baseUrl[apiSource];

    // Set access token
    const accessToken = {
      ruby: env.REACT_APP_API_RUBY_ACCESS_TOKEN,
      "node-auth": env.REACT_APP_API_NODE_AUTH_ACCESS_TOKEN,
      "node-auth-user": env.REACT_APP_API_NODE_AUTH_USER_ACCESS_TOKEN,
      "node-user-mgt": env.REACT_APP_API_NODE_USER_MGT_ACCESS_TOKEN,
      "node-carbon": env.REACT_APP_API_NODE_CARBON_ACCESS_TOKEN,
      "node-markets": env.REACT_APP_API_NODE_MARKETS_ACCESS_TOKEN,
      "crops-fertilisers": env.REACT_APP_API_CROPS_FERTILISERS_ACCESS_TOKEN,
    };
    config.headers["Api-Access-Token"] = accessToken[apiSource];

    // Set authorization token
    const authTokenHeaderKey = {
      ruby: "Admin-Authorization",
      "node-auth": "Authorization",
      "node-auth-user": "Authorization",
      "node-user-mgt": "Authorization",
      "node-carbon": "Authorization",
      "node-markets": "Authorization",
      "crops-fertilisers": "Authorization",
    };
    const adminAuthData = loadFromLocalStorage<AuthData>(
      ADMIN_AUTH_DATA_STORAGE_KEY,
    );

    config.headers[authTokenHeaderKey[apiSource]] =
      `Bearer ${adminAuthData?.token}`;

    // Set app referer header
    config = handleAppReferer(config);

    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => {
    const { schema, apiSource } = response.config;

    if (schema && apiSource && env.REACT_APP_APP_ENVIRONMENT !== "production") {
      validateResponseSchema(response, schema, apiSource);
    }

    // Make sure data is set when API returns no-content
    if (response.status === 204) {
      if (apiSource === "ruby") {
        response.data = null;
      } else {
        response.data = {
          data: {
            success: {
              statusCode: 204,
              data: null,
            },
            error: null,
          },
        };
      }
    }

    return response;
  },
  error => {
    const isInternalRequest = error.config.baseURL.includes("agreena.com");

    const isTokenRefreshing =
      loadFromLocalStorage<boolean>("session-refreshing");

    /**
     * Authenticated resources are protected by AuthorizedRoute component,
     * so the only case when 401 is returned is when the session has expired
     */
    if (
      isInternalRequest &&
      !isTokenRefreshing &&
      error.response?.status === 401
    ) {
      window.dispatchEvent(new Event("session-expired"));
    }

    return Promise.reject(error);
  },
);

/* -------------------------------------------------------------------------------------------------
 * DEPRECATED Request objects
 * -----------------------------------------------------------------------------------------------*/
type RequestConfig = AxiosRequestConfig & {
  path: string;
  body?: Record<string, unknown>;
};

/**
 * @deprecated Use native axios methods instead
 */
const request = async (
  { path, method, ...rest }: RequestConfig,
  schema?: ZodSchema,
) => {
  const response = await axios({
    method,
    url: path,
    ...rest,
  });

  if (schema && env.REACT_APP_APP_ENVIRONMENT !== "production") {
    validateResponseSchema(response, schema, "ruby");
  }

  return response;
};

/**
 * @deprecated Use native axios methods instead
 */
export function getRequest(
  { path, ...rest }: RequestConfig,
  schema?: ZodSchema,
) {
  return request({ method: "get", path, ...rest }, schema);
}

/**
 * @deprecated Use native axios methods instead
 */
export function postRequest({ path, body, ...rest }: RequestConfig) {
  return request({ method: "post", path, data: body, ...rest });
}

/**
 * @deprecated Use native axios methods instead
 */
export function putRequest({ path, body, ...rest }: RequestConfig) {
  return request({ method: "put", path, data: body, ...rest });
}

/**
 * @deprecated Use native axios methods instead
 */
export function deleteRequest({ path, body = {}, ...rest }: RequestConfig) {
  return request({ method: "delete", path, data: body, ...rest });
}
