import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { Button } from "@ag/design-system/atoms";
import { ButtonSelect, ComboBox } from "@ag/design-system/molecules";
import { ButtonSelectField, ComboBoxField, InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { FieldStep, LabelValue } from "@ag/utils/types";

import { CropType } from "~entities";
import { SFormSubmitButtonContainer } from "~forms/styled";

import {
  getCropTypeOptions,
  getInputVisibilityState,
  getResidueManagementOptions,
  getTillingCoverCropsOptions,
  getTillingPracticeOptions,
} from "./helpers";
import {
  CultivationStrategyAttribute,
  CultivationStrategyV2022Data,
} from "./types";

type Props = {
  step: FieldStep;
  premiumPoolHref: string | undefined;
  cropResidueManagementOptions: LabelValue<string>[];
  cropTypes: CropType[];
  isReadonly?: boolean;
  isSubmitting?: boolean;
  onFormInitialize?: (form: FormApi<CultivationStrategyV2022Data>) => void;
};

export const CultivationStrategyForm = ({
  cropResidueManagementOptions,
  cropTypes,
  isReadonly,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<CultivationStrategyV2022Data>();
  const { values, pristine } = useFormState<CultivationStrategyV2022Data>();

  useEffect(() => {
    if (onFormInitialize) {
      onFormInitialize(form);
    }
  }, [form, onFormInitialize]);

  const tillingCoverCropsOptions = getTillingCoverCropsOptions();
  const tillingPracticeOptions = getTillingPracticeOptions();
  const residueManagementOptions = getResidueManagementOptions(
    cropResidueManagementOptions,
  );

  const { isCustomCropTypeInputVisible, isResidueManagementInputVisible } =
    getInputVisibilityState({ values, cropTypes });

  return (
    <section className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <Field
          name={CultivationStrategyAttribute.CarbonCropTypeId}
          render={({ input, meta }) => (
            <ComboBoxField
              {...input}
              data-testid="cultivationStrategy-cropTypeSelect"
              label={I18n.t("js.carbon.crop_type")}
              placeholder={I18n.t("js.carbon.crop_type_select_placeholder")}
              error={
                meta.touched && meta.error ? { message: meta.error } : undefined
              }
              tooltip={I18n.t("js.carbon.crop_type_instruction")}
              emptyText=""
              loadingText=""
            >
              {getCropTypeOptions(cropTypes).map(option => (
                <ComboBox.Item key={option.value}>{option.label}</ComboBox.Item>
              ))}
            </ComboBoxField>
          )}
        />

        {isCustomCropTypeInputVisible && (
          <Field
            name={CultivationStrategyAttribute.CropTypeOtherName}
            render={({ input, meta }) => (
              <InputField
                {...input}
                data-testid="cultivationStrategy-cropGrossYieldInput"
                label={I18n.t("js.carbon.other_type")}
                isDisabled={isReadonly}
                error={
                  meta.touched && meta.error
                    ? { message: meta.error }
                    : undefined
                }
              />
            )}
          />
        )}

        <Field
          name={CultivationStrategyAttribute.TillingRate}
          render={({ input, meta }) => (
            <ButtonSelectField
              {...input}
              data-testid="cultivationStrategy-tillingRateButtonGroup"
              label={I18n.t("js.carbon.tilling_practice")}
              error={
                meta.touched && meta.error ? { message: meta.error } : undefined
              }
              tooltip={I18n.t(
                "js.farmer.carbon.field_details.tilling_practice_tooltip",
              )}
              isDisabled={isReadonly}
            >
              {tillingPracticeOptions.map(option => (
                <ButtonSelect.Option key={option.value} value={option.value}>
                  {option.label}
                </ButtonSelect.Option>
              ))}
            </ButtonSelectField>
          )}
        />

        <Field
          name={CultivationStrategyAttribute.CropGrossYield}
          render={({ input, meta }) => (
            <InputField
              {...input}
              type="number"
              label={I18n.t("js.carbon.gross_yield_unit")}
              tooltip={I18n.t("js.carbon.gross_yield_instruction")}
              isDisabled={isReadonly}
              error={
                meta.touched && meta.error ? { message: meta.error } : undefined
              }
            />
          )}
          parse={value => value && Number(value)}
        />

        {isResidueManagementInputVisible && (
          <Field
            name={CultivationStrategyAttribute.CropResidueManagement}
            render={({ input, meta }) => (
              <ButtonSelectField
                {...input}
                data-testid="cultivationStrategy-residueManagementButtonGroup"
                label={I18n.t("js.carbon.residue_management")}
                error={
                  meta.touched && meta.error
                    ? { message: meta.error }
                    : undefined
                }
                tooltip={I18n.t("js.carbon.residue_management_tooltip")}
                isDisabled={isReadonly}
              >
                {residueManagementOptions.map(option => (
                  <ButtonSelect.Option key={option.value} value={option.value}>
                    {option.label}
                  </ButtonSelect.Option>
                ))}
              </ButtonSelectField>
            )}
          />
        )}
      </div>

      <Field
        name={CultivationStrategyAttribute.CoverCropsAdoption}
        render={({ input, meta }) => (
          <ButtonSelectField
            {...input}
            data-testid="cultivationStrategy-coverCropsAdoptionButtonGroup"
            label={I18n.t("js.carbon.cover_and_catch_crops_adoption")}
            error={
              meta.touched && meta.error ? { message: meta.error } : undefined
            }
            tooltip={I18n.t("js.carbon.cover_and_catch_crops_adoption_tooltip")}
            isDisabled={isReadonly}
          >
            {tillingCoverCropsOptions.map(option => (
              <ButtonSelect.Option key={option.value} value={option.value}>
                {option.label}
              </ButtonSelect.Option>
            ))}
          </ButtonSelectField>
        )}
      />

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          <Button
            type="submit"
            data-testid="cultivationStrategy-submitButton"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </Button>
        </SFormSubmitButtonContainer>
      )}
    </section>
  );
};
