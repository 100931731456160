import {
  AdditionalInformationData,
  HistoryOfDeforestationData,
  SoilCharacteristicsData,
  SoilCultivationPracticeData,
  SoilCultivationPracticeQuestionnaireData,
} from "@ag/carbon/forms/baseline/iso";
import { LatLng } from "@ag/map/types";
import { FieldStep, Meta, MetaArgs } from "@ag/utils/types";

import { FieldActuals, FieldActualsExtended } from "../field-actuals";
import {
  BaselineVersion,
  CarbonCropType,
  CropProtection,
  Fertiliser,
  Potential,
} from "../types";

/**
 * ENTITIES
 */
export type FieldOverview<
  D extends DefinitionOverview | FieldDefinitionDetails = DefinitionOverview,
  A extends FieldActuals | FieldActualsExtended = FieldActuals,
> = {
  activeCarbonContractId: number | null;
  carbonFieldDefinition: D;
  carbonFieldActuals: A[];
  carbonUserId: number;
  completionRate: number;
  id: number;
  name: string;
  potential: Potential;
  validationStatus: FieldValidationStatus;
  sizeHa: number;
  addressId: number;
  carbonFarm: {
    id: string;
    name: string;
  };
  position: {
    lat: number;
    lng: number;
  };
  user: UserCoreData;
  dummy: boolean;
};

export type FieldDetails = {
  id: number;
  name: string;
  sizeHa: number;
  carbonUserId: number;
  carbonFarmId: number;
  activeCarbonContractId: number;
  position: {
    lat: number;
    lng: number;
  };
  carbonFieldDefinition: FieldDefinitionDetails;
  validationStatus: FieldValidationStatus;
  userId: number;
  coordinates?: LatLng[];
};

/**
 * RESPONSES
 */
export type FieldsOverviewsResponse = {
  fieldsOverviews: FieldOverview[];
  meta: Meta & {
    totalFieldsSize: number;
  };
};

export type UserFieldsOverviewsResponse = FieldOverview<
  FieldDefinitionDetails,
  FieldActualsExtended
>;

/**
 * QUERY KEY ARGS
 */
export type FieldsOverviewsQueryKeyArgs = {
  filters: FieldsFilters;
  metaArgs: MetaArgs;
};

export type UserFieldsQueryKeyArgs = {
  filters: UserFieldsFilters;
  metaArgs?: MetaArgs;
};

/**
 * QUERY ARGS
 */
export type UseFieldsOverviewsQueryArgs = {
  filters: FieldsFilters;
  metaArgs: MetaArgs;
};

export type UserSignInAsQueryArgs = {
  fieldId: number;
  step: FieldStep;
  harvestYear: number;
};

export type UserFieldsQueryArgs = {
  metaArgs: MetaArgs;
  carbonUserId: number;
};

/**
 * QUERY FUNCTION ARGS
 */
export type FetchFieldsOverviewsArgs = {
  filters?: FieldsFilters;
  includeFieldDetails?: boolean;
  metaArgs: MetaArgs;
};

export type SignInAsAndRedirectToFieldArgs = {
  fieldId: number;
  step: FieldStep;
  harvestYear: number;
  userId: number;
};

/**
 * MUTATION ARGS
 */

/**
 * MUTATION FUNCTION ARGS,
 */

/**
 * MUTATION FUNCTION ARGS
 */
export type UnlockFieldDefinitionArgs = {
  fieldId: number;
  fieldDefinitionId: number;
};

export type UpdateFieldDefinitionArgs = {
  fieldId: number;
  fieldDefinitionId: number;
  fieldDefinitionData: UpdateFieldDefinitionData;
};

export type UpdateFieldArgs = {
  fieldId: number;
  fieldData: UpdateFieldData;
};

/**
 * MUTATION ARGS
 */

/**
 * MISC
 */
export type CarbonFertiliser = {
  chemicalSpecification: boolean;
  createdAt: string;
  id: number;
  name: string;
  nationalIdentifier: string;
  nitrificationAdded: null;
  tradeName: string;
  updatedAt: string;
};

export type FieldDefinitionDetails = {
  baseline: number | null;
  baselineTotal: number | null;
  baselineVersion: BaselineVersion;
  carbonCropType: CarbonCropType;
  carbonCropTypeId: string | null;
  carbonFieldId: number;
  completedAt: string | null;
  completionRate: number;
  createdAt: number;
  cropGrossYield: number | null;
  cropLastHarvestYear: string;
  cropNetYield: number | null;
  cropProtections: CropProtection[];
  cropProtectionsCount: number;
  cropProtectionUsed: boolean;
  cropResidueManagement: string | null;
  cropSeedAmount: number | null;
  cropSeedTreatment: boolean | null;
  cropType: number | null;
  cropTypeOtherName: string | null;
  energyConsumptionAmountHa: number | null;
  energyConsumptionAmountUnit: string | null;
  energyConsumptionEnergySource: string | null;
  fertilisers: Fertiliser[];
  fertilisersCount: number;
  fertilisersShowChemicalSpecification: boolean | null;
  footprintTotal: number;
  id: number;
  irrigationNumberOfEvents: number | null;
  isOrganic: boolean;
  latestCarbonBaseline: {
    baselinePerHa: string;
    baselineTotal: string;
    baselineVersion: BaselineVersion;
    carbonBaselineEmissionId: number;
    carbonBaselineEmissionType: string;
    createdAt: string;
  };
  latestCarbonFootprint: {
    carbonCftRequest: unknown; // TODO: Add type when needed as this type is huge
    carbonComponentCftRequest: null;
    cftVersion: string;
    footprintPerHa: string;
    footprintTotal: string;
  };
  latestHarvestYear: number;
  otherInformationComment: string;
  potential: Potential;
  questionnaire: SoilCultivationPracticeQuestionnaireData;
  readonly: boolean;
  simplified: boolean;
  soilDataOrigination: string | null;
  soilDrainage: string | null;
  soilMgmtHistoricConversion: boolean;
  soilMoisture: string | null;
  soilPhRange: string | null;
  soilSomCustom: string | null;
  soilSomRange: string | null;
  soilSomSpecific: number;
  soilTexture: string;
  submittedAt: string | null;
  updatedAt: string;
};

export type UserCoreData = {
  company: {
    id: number;
    name: string;
  };
  email: string;
  id: number;
  name: string;
};

export type DefinitionOverview = {
  completedAt: string | null;
  completionRate: number;
  id: number;
  potential: Potential;
  readonly: boolean;
  submittedAt: string | null;
  baseline: number | null;
};

export enum FieldValidationStatus {
  New = "new",
  InProgress = "in_progress",
  Rejected = "rejected",
  Validated = "validated",
}

export enum FieldsFilter {
  ActualSubmitted = "actualSubmitted",
  BaseCompleted = "baseCompleted",
  CarbonUserId = "carbonUserId",
  FallowActualsYears = "fallowActualsYears",
  FieldName = "fieldName",
  Id = "id",
  MatchCountryCode = "matchCountryCode",
  ReportReceived = "reportReceived",
  DefinitionSubmitted = "definitionSubmitted",
  ReportSubmitted = "reportSubmitted",
  UserId = "userId",
  WithoutActiveContract = "withoutActiveContract",
}

export type UserFieldsFilters = {
  [FieldsFilter.CarbonUserId]?: number | null;
};

export type FieldsFilters = {
  [FieldsFilter.ActualSubmitted]?: number | null;
  [FieldsFilter.BaseCompleted]?: boolean | null;
  [FieldsFilter.CarbonUserId]?: (number | null)[] | null;
  [FieldsFilter.FallowActualsYears]?: (number | null)[] | null;
  [FieldsFilter.FieldName]?: string | null;
  [FieldsFilter.Id]?: (number | null)[] | null;
  [FieldsFilter.MatchCountryCode]?: (Locale | null)[] | null;
  [FieldsFilter.ReportReceived]?: boolean | null;
  [FieldsFilter.DefinitionSubmitted]?: boolean | null;
  [FieldsFilter.ReportSubmitted]?: boolean | null;
  [FieldsFilter.UserId]?: (number | null)[] | null;
  [FieldsFilter.WithoutActiveContract]?: boolean | null;
};

export enum FieldDefinitionStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
  Submitted = "submitted",
}

export type UseFieldDetailsQueryOptions<T> = {
  enabled?: boolean;
  select?: (fieldDefinition: FieldDetails | undefined) => T;
};

type Comment = {
  text: string;
};

export type UpdateFieldDefinitionData = HistoryOfDeforestationData &
  AdditionalInformationData &
  SoilCharacteristicsData & {
    questionnaire: SoilCultivationPracticeData;
  } & {
    comment: Comment;
  };

export type UpdateFieldData = {
  name?: string;
  sizeHa?: number;
  addressId?: number;
  coordinates?: LatLng[];
  position?: LatLng;
  comment: Comment;
};
