import { Controller, useForm } from "react-hook-form";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { RadioButtonField, TextareaField } from "@ag/form-fields";

import { FieldIneligibilityReason } from "../entities/field-ineligibility-reason";

export type MarkIneligibleModalProps = {
  fieldId: string;
  isOpen: boolean;
  onSubmitIneligible: (
    reason: FieldIneligibilityReason,
    comment?: string,
  ) => void;
  onRequestClose: () => void;
};

export function MarkIneligibleModal({
  fieldId,
  isOpen,
  onSubmitIneligible,
  onRequestClose,
}: MarkIneligibleModalProps) {
  const { control, handleSubmit, watch } = useForm();

  const reason = watch("reason");
  const comment = watch("comment");

  if (!fieldId) return null;

  return (
    <Modal.Root size="medium" isOpen={isOpen} onRequestClose={onRequestClose}>
      <Modal.Header>
        <Modal.Title>Mark field as ineligible for programme</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <div className="space-y-4">
          <Controller
            name="reason"
            control={control}
            render={({ field }) => (
              <RadioButtonField
                {...field}
                label="Please select a reason"
                options={[
                  {
                    label: "Field too small",
                    value: FieldIneligibilityReason.FIELD_TOO_SMALL,
                  },
                  {
                    label: "Duplicate field",
                    value: FieldIneligibilityReason.DUPLICATE_FIELD,
                  },
                  {
                    label: "Other reason",
                    value: FieldIneligibilityReason.OTHER_REASON,
                  },
                ]}
                onChange={async value => {
                  field.onChange(value);
                  return true;
                }}
                onBlur={async () => {
                  field.onBlur();
                  return true;
                }}
              />
            )}
          />

          {reason === FieldIneligibilityReason.OTHER_REASON && (
            <Controller
              name="comment"
              control={control}
              render={({ field }) => (
                <div className="!mt-0 ml-6">
                  <TextareaField
                    {...field}
                    label=""
                    maxLength={50}
                    placeholder="Please describe the reason"
                    rows={1}
                  />
                </div>
              )}
            />
          )}
        </div>
      </Modal.Content>

      <Modal.Footer
        render={onClose => (
          <>
            <Button variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={
                !reason ||
                (reason === FieldIneligibilityReason.OTHER_REASON && !comment)
              }
              onClick={handleSubmit(({ reason, comment }) => {
                onSubmitIneligible(reason, comment);
              })}
            >
              Submit
            </Button>
          </>
        )}
      />
    </Modal.Root>
  );
}
