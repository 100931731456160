import { has } from "lodash";
import { Children, isValidElement } from "react";

export const getElementType = (element: any) => {
  // If we are dealing with a forwarded ref, use inner type instead.
  if (element.type?.render) return element.type.render;

  return element.type;
};

/**
 * Creates a validator function based on a lookup of components, that can then valide any children passed to it.
 * @param scope The name of the parent component this will be used in, for error messages.
 * @param types The lookup of component types to check against.
 * @returns A function that takes children and return Error message if invalid or null if valid.
 */
export const createChildValidator = <TTypes, TChildren>(
  scope: string,
  types: Record<string, TTypes>,
) => {
  const targets = Object.values(types);

  return (children: TChildren) => {
    let errorMsg: string | null = null;

    if (!children || typeof children === "string") return;

    Children.forEach(children, child => {
      // empty children are allowed for easier conditionals in templates.
      if (!child || typeof child === "string") return;

      if (!isValidElement(child)) {
        errorMsg = `${scope}: the child element is an invalid react element`;
        return;
      }

      const childType = getElementType(child);

      // if this component is a wrapped storybook element, continue with no validation.
      if (has(childType, "parameters")) {
        return;
      }

      if (
        targets.every(
          target =>
            target !== childType &&
            (target as any).displayName !== childType.displayName,
        )
      ) {
        const typeNames = Object.keys(types).map(type => `${scope}.${type}`);

        if (typeNames.length === 1) {
          errorMsg = `${scope}: Only ${typeNames[0]} is allowed as children.`;
          return;
        }

        errorMsg =
          `${scope}: Only ${typeNames.slice(0, typeNames.length - 1)}` +
          ` and ${typeNames[typeNames.length - 1]} are allowed as children.`;

        return;
      }
    });

    return errorMsg;
  };
};

/**
 * @deprecated Use findComponents methods instead
 */
export const getElementsFromChildren = <P extends object, TTypes>(
  children: React.ReactElement<unknown>[],
  type: TTypes,
) => children.filter(child => type === child.type) as React.ReactElement<P>[];

export const findComponents = <T extends React.ComponentType<any>>(
  children: React.ReactNode,
  componentType: T,
): React.ReactElement<any>[] => {
  const foundComponents: React.ReactElement<any>[] = [];

  const searchChildren = (children: React.ReactNode) => {
    Children.forEach(children, child => {
      if (isValidElement(child)) {
        if (child.type === componentType) {
          foundComponents.push(child);
        }

        searchChildren(child.props.children);
      }
    });
  };

  searchChildren(children);

  return foundComponents;
};
