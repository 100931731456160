import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateAdminsQueryKey } from "./get-admins";

const deleteAdmin = (adminId: string) =>
  axios.delete(`/api/v1/admins/${adminId}`, {
    apiSource: "node-auth",
  });

export const useDeleteAdminMutation = () =>
  useMutation(deleteAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateAdminsQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
