import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { UserRole } from "@ag/utils/types";

import { queryClient } from "~config/react-query";

import { generateUsersQueryKey } from "./get-users";

type UpdateUserData = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  roles: UserRole[];
};

const updateUser = async (user: UpdateUserData) => {
  const { id, ...userData } = user;

  await axios.put(`/api/v1/admins/user-management/${id}`, userData, {
    apiSource: "node-user-mgt",
  });
};

export const useUpdateUserMutation = () =>
  useMutation(updateUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateUsersQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
