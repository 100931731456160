
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/PhoneNumberInputField/PhoneNumberInputField.css.ts", "@ag/form-fields");
      import { style } from "@vanilla-extract/css";
import { colors, radius, typography } from "@ag/design-system/tokens";
export const phoneNumberInputs = style({
  display: "grid",
  gridTemplateColumns: "124px 1fr",
  columnGap: 4,
  width: "100%"
}, "phoneNumberInputs");
export const phoneCountryCode = style({
  // Important bc. of race condition with tw locally...
  minWidth: "unset !important"
}, "phoneCountryCode");
export const placeholderInputLabel = style([typography.h8, {
  textTransform: "uppercase",
  color: colors.grey[600],
  marginBottom: 8
}], "placeholderInputLabel");
export const placeholderInputText = style([typography.h5, {
  background: colors.grey[200],
  borderRadius: radius[100],
  color: colors.grey[500],
  padding: "12px 16px"
}], "placeholderInputText");
      endFileScope();
      
    