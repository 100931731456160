import { z } from "zod";

import { CropType } from "~entities";

import { CultivationStrategyV2022DataSchema } from "./schemas";

export enum CultivationStrategyAttribute {
  CropLastHarvestYear = "cropLastHarvestYear",
  CarbonCropTypeId = "carbonCropTypeId",
  CarbonCropType = "carbonCropType",
  CropTypeOtherName = "cropTypeOtherName",
  CropGrossYield = "cropGrossYield",
  CoverCropsAdoption = "coverCropsAdoption",
  CropResidueManagement = "cropResidueManagement",
  TillingRate = "tillingRate",
}

export enum CultivationStrategyOptionalAttribute {
  CropTypeOtherName = CultivationStrategyAttribute.CropTypeOtherName,
  CropResidueManagement = CultivationStrategyAttribute.CropResidueManagement,
}

export type CultivationStrategyV2022Data = z.infer<
  typeof CultivationStrategyV2022DataSchema
>;

export type GetInputVisibilityStateArgs = {
  values: CultivationStrategyV2022Data;
  cropTypes: CropType[];
};

export type InputVisibility = {
  isResidueManagementInputVisible: boolean;
  isCustomCropTypeInputVisible: boolean;
};

export type ValidateArgs = {
  values: CultivationStrategyV2022Data;
  cropTypes: CropType[];
};
