import { useAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "@ag/design-system/assets";
import { Tabs, useTabs } from "@ag/design-system/organisms";

import {
  CropPropertiesForm,
  cropCountriesAtom,
  useCropAvailabilityQuery,
} from "~features/crops";
import { useCropCountriesQuery } from "~features/crops/api/get-crop-countries";
import { HarvestYear } from "~features/crops/entities/crop-availability";
import { useYearsDataQuery } from "~features/initial-resources";
import { AuthorizedSidebar } from "~features/navigation";
import { withFeatureFlagAccess } from "~hocs/withFeatureFlagAccess";
import DetailsLayout from "~layouts/details-layout";

const CropDetails = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { activeTab, onChange: onTabChange } = useTabs();

  if (!params.id) {
    navigate("/crops");
  }

  const [, setCropCountries] = useAtom(cropCountriesAtom);
  const cropCountries = useCropCountriesQuery();

  const { data, isLoading } = useCropAvailabilityQuery(params.id!);
  const { data: yearsData } = useYearsDataQuery();

  // Sort harvest years from most to least recent
  const harvestYears = useMemo(
    () =>
      yearsData
        ? (yearsData.allHarvestYears.reverse().map(String) as HarvestYear[])
        : [],
    [yearsData],
  );

  // Set crops counties to be used in harvet year forms
  useEffect(() => {
    if (cropCountries.data?.length) {
      setCropCountries(cropCountries.data);
    }
  }, [cropCountries, setCropCountries]);

  // Set the first tab as active when harvest years are defined
  useEffect(() => {
    if (!activeTab && harvestYears.length > 0) {
      onTabChange(harvestYears[0]);
    }
  }, [activeTab, harvestYears, onTabChange]);

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <DetailsLayout.TopBarTitle>Crop details</DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div>{/* TODO => Add crop name */}</div>
        {isLoading ? (
          <div className="flex h-5 items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <Tabs.Root value={activeTab} onChange={onTabChange}>
            <Tabs.List key="details">
              {harvestYears.map((harvestYear, index) => (
                <Tabs.Trigger value={harvestYear} key={`tab-${index}`}>
                  <Tabs.TriggerTitle>{harvestYear}</Tabs.TriggerTitle>
                </Tabs.Trigger>
              ))}
            </Tabs.List>

            {harvestYears.map((harvestYear, index) => (
              <Tabs.Content key={`tab-content-${index}`} value={harvestYear}>
                <CropPropertiesForm
                  id={params.id!}
                  harvestYear={harvestYear}
                  hasProperties={
                    Boolean(
                      data?.[harvestYear]?.biomass_parameters_available,
                    ) ||
                    Boolean(data?.[harvestYear]?.ipcc_parameters_availabile)
                  }
                />
              </Tabs.Content>
            ))}
          </Tabs.Root>
        )}
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default withFeatureFlagAccess("crops-v2", "on", {
  redirectPath: "/carbon/crop-types",
})(CropDetails);
