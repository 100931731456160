import "react-datepicker/dist/react-datepicker.css";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";

import FileUploader from "~components/file-uploader";

type UploadContractAmendmentModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  onUploadFile: (contractAmendmentFile: File) => void;
};

export const UploadContractAmendmentModal = ({
  isOpen,
  onRequestClose,
  onUploadFile,
}: UploadContractAmendmentModalProps) => {
  return (
    <Modal.Root
      isOpen={isOpen}
      onRequestClose={() => {
        onRequestClose();
      }}
    >
      <Modal.Header>
        <Modal.Title>Upload Benefits Contract Amendment</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <div>
          <FileUploader
            onFileDropSuccess={onUploadFile}
            fileDropHeaderText={"Drag and drop the contract amendment file"}
            fileDropInfoText={"It must be in PDF format"}
            acceptedFileType=".pdf"
          />
        </div>
      </Modal.Content>

      <Modal.Footer
        render={onClose => (
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        )}
      />
    </Modal.Root>
  );
};
