import { z } from "zod";

import I18n, { Locale } from "@ag/i18n";
import { VALIDATOR } from "@ag/utils/helpers";
import { UserRole } from "@ag/utils/types";
import { getIsZIPCodeValid } from "@ag/utils/validators";

export const createUserFormSchema = () =>
  z
    .object({
      email: z
        .string()
        .min(1, I18n.t("js.shared.email_required"))
        .refine(value => value.match(VALIDATOR.EMAIL), {
          message: I18n.t("js.shared.email_format"),
        }),
      password: z
        .string()
        .min(
          1,
          I18n.t("js.admin.create_user.form_validation.password_required"),
        )
        .refine(value => value.match(VALIDATOR.PASSWORD), {
          message: I18n.t(
            "js.admin.create_user.form_validation.password_length",
          ),
        }),
      confirmPassword: z
        .string()
        .min(
          1,
          I18n.t(
            "js.admin.create_user.form_validation.password_confirm_required",
          ),
        ),
      locale: z.nativeEnum(Locale),
      firstName: z.string().min(1, I18n.t("js.shared.required")),
      lastName: z.string().min(1, I18n.t("js.shared.required")),
      phoneNumber: z.string().refine(value => value.match(VALIDATOR.PHONE), {
        message: I18n.t(
          "js.admin.create_user.form_validation.phone_number_invalid",
        ),
      }),
      phoneCountryCode: z.string().min(1, I18n.t("js.shared.required")),
      preferredCurrency: z.string(),
      acceptedNewsletter: z.boolean(),
      acceptedTerms: z.boolean().refine(value => value === true, {
        message: I18n.t(
          "js.admin.create_user.form_validation.accept_terms_required",
        ),
      }),
      createCarbonUser: z.boolean(),
      company: z.object({
        roles: z.array(z.nativeEnum(UserRole)),
        name: z.string().min(1, I18n.t("js.shared.required")),
        vatNumber: z.string().min(1, I18n.t("js.shared.required")),
        address: z.object({
          address: z.string().min(1, I18n.t("js.shared.required")),
          city: z.string().min(1, I18n.t("js.shared.required")),
          countryCode: z.string().min(1, I18n.t("js.shared.required")),
          zipCode: z.string().min(1, I18n.t("js.shared.required")),
        }),
      }),
    })
    .refine(data => data.password === data.confirmPassword, {
      message: I18n.t(
        "js.admin.create_user.form_validation.password_dont_match",
      ),
      path: ["password"],
    })
    .refine(data => data.password === data.confirmPassword, {
      message: I18n.t(
        "js.admin.create_user.form_validation.password_dont_match",
      ),
      path: ["confirmPassword"],
    })
    .refine(
      data =>
        getIsZIPCodeValid({
          ZIPCode: data.company.address.zipCode,
          countryCode: data.company.address.countryCode,
        }),
      {
        message: I18n.t(
          "js.admin.create_user.form_validation.zip_code_invalid",
        ),
        path: ["company.address.zipCode"],
      },
    );
