import { Button } from "@ag/design-system/atoms";
import { box } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { SIGN_IN_AS_PARAM } from "@ag/utils/constants";
import { getSearchParams } from "@ag/utils/helpers";

import { env } from "~config";
import {
  UserManagementResourceClass,
  useUserManagementPermissions,
} from "~features/permission";

import { useSignInAsMutation } from "../api/sign-in-as";
import { User } from "../entities/user";
import { UserActionsSkeleton } from "./user-actions-skeleton";

type Props = {
  user: User | undefined;
  isLoading: boolean;
};

export const UserActions = ({ user, isLoading }: Props) => {
  const { data: userManagementPermissions } = useUserManagementPermissions();

  const signInAsMutation = useSignInAsMutation();

  const handleSignInAsClicked = () => {
    if (!user) return;

    signInAsMutation.mutate(user.id.toString(), {
      onSuccess: ({ ticket }) => {
        const params = getSearchParams({
          [SIGN_IN_AS_PARAM]: ticket,
        });

        window.open(env.REACT_APP_FARMER_URL + params);
      },
    });
  };

  const canEditUser = userManagementPermissions?.update?.includes(
    UserManagementResourceClass.User,
  );
  const canSignInAs = userManagementPermissions?.loginAsUser?.includes(
    UserManagementResourceClass.User,
  );
  // TODO: Uncomment when shipping functionality
  // const canResendConfirmation = permissions?.resendConfirmation?.includes(
  //   ResourceClass.User,
  // );

  if (isLoading) return <UserActionsSkeleton />;

  if (!user) return null;

  return (
    <div className={box({ gap: 4 })}>
      {canSignInAs && (
        <Button
          variant="primary"
          size="x-small"
          isLoading={signInAsMutation.isLoading}
          onClick={handleSignInAsClicked}
        >
          {I18n.t("js.admin.users.show.sign_in_as")}
        </Button>
      )}

      {canEditUser && (
        <Button
          variant="secondary"
          size="x-small"
          to={`/users/${user.id}/edit`}
        >
          {I18n.t("js.shared.edit")}
        </Button>
      )}

      {/* TODO: Uncomment when shipping functionality */}
      {/* {canResendConfirmation && !user.confirmedAt && (
        <Button variant="secondary" size="x-small">
          {I18n.t("js.admin.users.show.resend_confirmation_email")}
        </Button>
      )} */}
    </div>
  );
};
