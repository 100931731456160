import { create } from "zustand";

type VerraVcuDetails = {
  vintageYear: number;
  verraProjectId?: string;
};

type ImportVcuData = {
  verraVcuDetails: VerraVcuDetails;
  distributionFile: File | undefined;
  isFormValid: boolean;

  setVerraVcuDetails: (details: VerraVcuDetails) => void;
  setDistributionFile: (file: File | undefined) => void;
  setIsFormValid: (isValid: boolean) => void;
  resetState: () => void;
};

export const useImportVerraVcuStore = create<ImportVcuData>(set => ({
  verraVcuDetails: {
    vintageYear: 2023,
    verraProjectId: undefined,
  },
  distributionFile: undefined,
  isFormValid: false,

  setVerraVcuDetails: details => {
    set({ verraVcuDetails: details });
  },
  setDistributionFile: file => {
    set({ distributionFile: file });
  },
  setIsFormValid: isValid => {
    set({ isFormValid: isValid });
  },
  resetState: () => {
    set({
      verraVcuDetails: {
        vintageYear: 2023,
        verraProjectId: undefined,
      },
      distributionFile: undefined,
      isFormValid: false,
    });
  },
}));
