import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { StringParam, useQueryParams, withDefault } from "use-query-params";

import { Select } from "@ag/design-system/molecules";
import { parseSorting } from "@ag/design-system/organisms";
import { InputField, SelectField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import { useCropTypesQuery, useCropTypesTable } from "~features/crop-type";
import {
  CropTypeFilters,
  CropTypeFiltersSchema,
} from "~features/crop-type/entities/crop-type";
import {
  METHODOLOGY_VERSIONS_OPTIONS,
  useOpenHarvestYearsOptions,
} from "~features/initial-resources";
import { AuthorizedSidebar } from "~features/navigation";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const CropTypes = () => {
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "id",
      desc: true,
    },
  ]);

  const { data: openHarvestYearsOptions } = useOpenHarvestYearsOptions();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [pagination, updatePagination, resetPagination] = usePagination();

  const [query, setQuery] = useQueryParams({
    name: withDefault(StringParam, undefined),
    coolfarmName: withDefault(StringParam, undefined),
    coolfarmIdentifier: withDefault(StringParam, undefined),
    harvestYear: withDefault(StringParam, undefined),
    methodologyVersion: withDefault(StringParam, undefined),
  });

  const { data: cropTypesData, isLoading } = useCropTypesQuery({
    ...pagination,
    filters: {
      ...query,
    },
    sort: parseSorting(sorting),
  });

  const table = useCropTypesTable(cropTypesData?.data, {
    sorting,
    setSorting,
  });

  const renderFilterBarItem = (
    key: keyof CropTypeFilters,
    value: CropTypeFilters[keyof CropTypeFilters],
  ) => {
    const label = (
      {
        name: "Name",
        coolfarmName: "CoolFarm name",
        coolfarmIdentifier: "CoolFarm Identifier",
        harvestYear: "Harvest year",
        methodologyVersion: "Methodology version",
      } as unknown as Record<keyof CropTypeFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CropTypeFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(CropTypeFiltersSchema),
  });

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<CropTypeFilters>(
    query,
    setQuery,
    resetPagination,
    () => setIsFiltersOpen(false),
  );
  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  const handlePaginationChanged = (value: {
    limit?: number;
    page?: number;
  }) => {
    updatePagination(value);
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Crop types</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField {...register("name")} label="Name" error={errors.name} />
          <InputField
            {...register("coolfarmName")}
            label="CoolFarm name"
            error={errors.coolfarmName}
          />
          <InputField
            {...register("coolfarmIdentifier")}
            label="CoolFarm Identifier"
            error={errors.coolfarmIdentifier}
          />
          {openHarvestYearsOptions && (
            <Controller
              name="harvestYear"
              control={control}
              render={({ field, fieldState }) => (
                <SelectField
                  {...field}
                  {...fieldState}
                  error={fieldState.error}
                  label="Harvest year"
                  optionsClassName="z-modal"
                >
                  <>
                    <Select.OptionAll>All</Select.OptionAll>

                    {openHarvestYearsOptions.map(harvestYearOption => (
                      <Select.Option
                        key={harvestYearOption.value}
                        value={String(harvestYearOption.value)}
                      >
                        {harvestYearOption.label}
                      </Select.Option>
                    ))}
                  </>
                </SelectField>
              )}
            />
          )}
          <Controller
            name="methodologyVersion"
            control={control}
            render={({ field, fieldState }) => (
              <SelectField
                {...field}
                error={fieldState.error}
                label="Methodology version"
                optionsClassName="z-modal"
              >
                <Select.OptionAll>All</Select.OptionAll>

                {METHODOLOGY_VERSIONS_OPTIONS.map(methodologyVersionOption => (
                  <Select.Option
                    key={methodologyVersionOption.value}
                    value={String(methodologyVersionOption.value)}
                  >
                    {methodologyVersionOption.label}
                  </Select.Option>
                ))}
              </SelectField>
            )}
          />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={cropTypesData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={handlePaginationChanged}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default CropTypes;
