import { omit } from "lodash";
import { useMemo } from "react";
import { Form } from "react-final-form";
import ReactJson from "react-json-view";

import {
  AdditionalInformationForm,
  SoilCultivationPracticeForm,
} from "@ag/carbon/forms/baseline/iso";
import { Button, InfoBox } from "@ag/design-system/atoms";
import { typography } from "@ag/design-system/tokens";
import { box, stack } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import CollapseBox from "~components/collapse-box";
import DataCard from "~components/data-card";
import { useSessionContext } from "~features/auth";
import {
  Field,
  getStepStatusIcon,
  getStepStatusText,
  getStepStatusVariant,
  useAllFieldBoundariesQuery,
  useUnlockFieldDefinitionMutation,
} from "~features/field";
import { FieldBoundariesTypes } from "~features/field/entities/field-boundaries";
import { useFieldHarvestYearContext } from "~features/harvest-year";
import {
  CarbonResourceClass,
  UserManagementResourceClass,
  useCarbonPermissions,
  useUserManagementPermissions,
} from "~features/permission";
import { useSignInAsMutation } from "~features/user";
// TODO: Move to feature?
import {
  getCoverCropBasedOnAnswers,
  getFertiliserTypeBasedOnAnswers,
  getIsCarbonFieldDefinitionUnlockable,
  getResidueManagementBasedOnAnswers,
  getTillagePracticeBasedOnAnswers,
  redirectToAppFarmerFieldDetailsPage,
} from "~pages/_legacy/carbon/helpers";
import { FieldStepTabId } from "~pages/_legacy/carbon/types";

import { FieldMap } from "../field-map";
import FieldMapLegend from "../field-map-legend";
import * as styles from "./field-definition-overview.css";

type Props = {
  field: Field;
};

export const FieldDefinitionOverview = ({ field }: Props) => {
  const { selectedHarvestYear } = useFieldHarvestYearContext();

  const { currentAdmin } = useSessionContext();

  const { carbonFieldDefinition: fieldDefinition } = field;
  const { data: questionnaire } =
    fieldDefinition.carbonFieldDefinitionQuestionnaire;

  const { data: carbonPermissions } = useCarbonPermissions();
  const { data: userManagementPermissions } = useUserManagementPermissions();

  const hasMRVDataPermissions = useMemo(() => {
    const validRoles = new Set([
      "cs_carbon",
      "programme_mrv",
      "programme_agronomist",
      "key_account_manager",
    ]);
    return Boolean(
      currentAdmin?.adminRoles.some(role => validRoles.has(role.name)),
    );
  }, [currentAdmin]);

  const { data: allFieldBoundaries } = useAllFieldBoundariesQuery(
    field.id.toString(),
    { enabled: !!hasMRVDataPermissions },
  );

  const unlockFieldDefinitionMutation = useUnlockFieldDefinitionMutation();
  const signInAsMutation = useSignInAsMutation();

  const handleUnlockDefinition = () => {
    unlockFieldDefinitionMutation.mutate(
      {
        fieldId: field.id,
        fieldDefinitionId: fieldDefinition.id,
      },
      {
        onSuccess: () => {
          ToastNotification.success(
            I18n.t("js.admin.field_details.definition_unlocked"),
          );
        },
      },
    );
  };

  const handleSignInAs = (userId: string) => {
    signInAsMutation.mutate(userId, {
      onSuccess: ({ ticket }) => {
        redirectToAppFarmerFieldDetailsPage({
          ticket,
          fieldId: field.id,
          harvestYear: selectedHarvestYear,
          activeFieldDetailsTab: FieldStepTabId.FieldDefinition,
        });
      },
    });
  };

  const formValues = {
    ...omit(fieldDefinition, "questionnaire"),
    ...questionnaire, // Questionnaire form values needs to be on the root level
  };

  const isDefinitionUnlockable = getIsCarbonFieldDefinitionUnlockable({
    activeCarbonContractId: field.activeCarbonContractId,
    fieldDefinitionSubmittedAt: fieldDefinition.submittedAt,
    validationStatus: field.validationStatus,
  });

  return (
    <section className={styles.root}>
      <InfoBox
        variant={getStepStatusVariant(fieldDefinition.status)}
        icon={getStepStatusIcon(fieldDefinition.status)}
      >
        {getStepStatusText(fieldDefinition.status, fieldDefinition.submittedAt)}
      </InfoBox>

      <div className={box({ gap: 8 })}>
        {carbonPermissions?.unlock?.includes(
          CarbonResourceClass.CarbonFieldDefinition,
        ) ? (
          <Button
            disabled={!isDefinitionUnlockable}
            onClick={handleUnlockDefinition}
          >
            {I18n.t("js.shared.unlock")}
          </Button>
        ) : null}

        {userManagementPermissions?.loginAsUser?.includes(
          UserManagementResourceClass.User,
        ) ? (
          <Button onClick={() => handleSignInAs(field.user.id)}>
            {I18n.t("js.shared.sign_in")}
          </Button>
        ) : null}
      </div>

      <section className={styles.summary}>
        {/* Map */}
        <DataCard.Root>
          <DataCard.Row>
            <DataCard.Value>
              <InfoBox icon="info-circle" variant="info">
                For fields created in harvest year 2021, 2022 and 2023, Farmer
                boundaries (shown in blue) were detected using legacy remote
                sensing capabilities.
              </InfoBox>
              <div className={styles.map}>
                <FieldMapLegend
                  showMRVBoundaries={
                    hasMRVDataPermissions &&
                    allFieldBoundaries?.some(
                      boundary => boundary.type === FieldBoundariesTypes.MRV,
                    )
                  }
                />

                <FieldMap
                  field={field}
                  fieldMRVBoundaries={
                    allFieldBoundaries?.find(
                      boundary => boundary.type === FieldBoundariesTypes.MRV,
                    )?.boundaries ?? undefined
                  }
                  className={styles.map}
                />
              </div>
            </DataCard.Value>
          </DataCard.Row>
        </DataCard.Root>
      </section>

      <section className={styles.data}>
        {/* Field setup */}
        <DataCard.Root>
          <DataCard.Title>
            {I18n.t("js.carbon.field_details.field_setup")}
          </DataCard.Title>
          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.carbon.latitude")}</DataCard.Label>
            <DataCard.Value>{field.position.lat}</DataCard.Value>
          </DataCard.Row>
          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.carbon.longitude")}</DataCard.Label>
            <DataCard.Value>{field.position.lng}</DataCard.Value>
          </DataCard.Row>
        </DataCard.Root>

        {/* Soil characteristics */}
        <DataCard.Root>
          <DataCard.Title>
            {I18n.t("js.carbon.soil_characteristics")}
          </DataCard.Title>
          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.carbon.som")}</DataCard.Label>
            <DataCard.Value>{fieldDefinition.soilSomSpecific}</DataCard.Value>
          </DataCard.Row>
          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.carbon.texture")}</DataCard.Label>
            <DataCard.Value>{fieldDefinition.soilTexture}</DataCard.Value>
          </DataCard.Row>
          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.carbon.ph")}</DataCard.Label>
            <DataCard.Value>
              {fieldDefinition.soilPhRange ?? "-"}
            </DataCard.Value>
          </DataCard.Row>
          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.carbon.moisture")}</DataCard.Label>
            <DataCard.Value>
              {fieldDefinition.soilMoisture ?? "-"}
            </DataCard.Value>
          </DataCard.Row>
          <DataCard.Row>
            <DataCard.Label>{I18n.t("js.carbon.drainage")}</DataCard.Label>
            <DataCard.Value>
              {fieldDefinition.soilDrainage ?? "-"}
            </DataCard.Value>
          </DataCard.Row>
        </DataCard.Root>

        {/* History of deforestation */}
        <DataCard.Root>
          <DataCard.Title>
            {I18n.t("js.carbon.history_of_deforestation")}
          </DataCard.Title>

          <DataCard.Row>
            <DataCard.Label>
              {I18n.t("js.carbon.historic_conversion")}
            </DataCard.Label>
            <DataCard.Value>
              {fieldDefinition.soilMgmtHistoricConversion
                ? I18n.t("js.shared.yes")
                : I18n.t("js.shared.no")}
            </DataCard.Value>
          </DataCard.Row>
        </DataCard.Root>
      </section>

      {/* Soil cultivation practice & additional information */}
      <Form
        initialValues={formValues}
        onSubmit={() => {}}
        render={() => (
          <>
            <CollapseBox.Root isInitiallyOpen>
              <CollapseBox.Title>
                {I18n.t("js.carbon.tilling_history")}
              </CollapseBox.Title>

              <CollapseBox.Content>
                <div className={stack({ gap: 24 })}>
                  <div className={stack({ gap: 8 })}>
                    <h5 className={typography.p2}>
                      {I18n.t("js.carbon.field_details.answer_summary")}
                    </h5>

                    <DataCard.Root className={styles.answerSummary} isInner>
                      <DataCard.Row>
                        <DataCard.Label>
                          {I18n.t("js.carbon.cultivated_field")}
                        </DataCard.Label>
                        <DataCard.Value>
                          {questionnaire.cultivatedFieldInTheLastFiveYears
                            ? I18n.t("js.shared.yes")
                            : I18n.t("js.shared.no")}
                        </DataCard.Value>
                      </DataCard.Row>

                      <DataCard.Row>
                        <DataCard.Label>
                          {I18n.t("js.carbon.tillage_practice")}
                        </DataCard.Label>
                        <DataCard.Value>
                          {getTillagePracticeBasedOnAnswers(questionnaire)}
                        </DataCard.Value>
                      </DataCard.Row>

                      <DataCard.Row>
                        <DataCard.Label>
                          {I18n.t("js.carbon.cover_crops")}
                        </DataCard.Label>
                        <DataCard.Value>
                          {getCoverCropBasedOnAnswers(questionnaire)}
                        </DataCard.Value>
                      </DataCard.Row>

                      <DataCard.Row>
                        <DataCard.Label>
                          {I18n.t("js.carbon.nitrification_inhibitors")}
                        </DataCard.Label>
                        <DataCard.Value>
                          {questionnaire.nitrificationInhibitorsConsistentlyTheLastFiveYears
                            ? I18n.t("js.shared.yes")
                            : I18n.t("js.shared.no")}
                        </DataCard.Value>
                      </DataCard.Row>

                      <DataCard.Row>
                        <DataCard.Label>
                          {I18n.t("js.carbon.rotated_crops")}
                        </DataCard.Label>
                        <DataCard.Value>
                          {questionnaire.rotatedCropsInTheLastFiveYears
                            ? I18n.t("js.shared.yes")
                            : I18n.t("js.shared.no")}
                        </DataCard.Value>
                      </DataCard.Row>

                      <DataCard.Row>
                        <DataCard.Label>
                          {I18n.t("js.carbon.residue_management_attribute")}
                        </DataCard.Label>
                        <DataCard.Value>
                          {getResidueManagementBasedOnAnswers(questionnaire)}
                        </DataCard.Value>
                      </DataCard.Row>

                      <DataCard.Row>
                        <DataCard.Label>
                          {I18n.t("js.carbon.fertiliser_type")}
                        </DataCard.Label>
                        <DataCard.Value>
                          {getFertiliserTypeBasedOnAnswers(questionnaire)}
                        </DataCard.Value>
                      </DataCard.Row>

                      <DataCard.Row>
                        <DataCard.Label>
                          {I18n.t("js.carbon.crop_protection")}
                        </DataCard.Label>
                        <DataCard.Value>
                          {questionnaire.cropProtectionConsistentlyTheLastFiveYears
                            ? I18n.t("js.shared.yes")
                            : I18n.t("js.shared.no")}
                        </DataCard.Value>
                      </DataCard.Row>
                    </DataCard.Root>
                  </div>

                  <div className={stack({ gap: 8 })}>
                    <h5 className={typography.p2}>
                      {I18n.t("js.carbon.field_details.farmers_answers")}
                    </h5>

                    <div>
                      <SoilCultivationPracticeForm isReadonly />
                    </div>
                  </div>
                </div>
              </CollapseBox.Content>
            </CollapseBox.Root>

            <CollapseBox.Root isInitiallyOpen>
              <CollapseBox.Title>
                {I18n.t("js.carbon.additional_information")}
              </CollapseBox.Title>
              <CollapseBox.Content>
                <AdditionalInformationForm isReadonly />
              </CollapseBox.Content>
            </CollapseBox.Root>
          </>
        )}
      />

      <CollapseBox.Root>
        <CollapseBox.Title>
          {I18n.t("js.carbon.field_details.sent_to_cft")}
        </CollapseBox.Title>
        <CollapseBox.Content>
          <ReactJson
            src={fieldDefinition.latestCarbonFootprint}
            displayDataTypes={false}
            shouldCollapse={key => key.name !== "root"}
          />
        </CollapseBox.Content>
      </CollapseBox.Root>
    </section>
  );
};
