import { FormApi } from "final-form";
import { useEffect } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { Button } from "@ag/design-system/atoms";
import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { getYesNoOptions } from "@ag/utils/helpers";

import { SFormSubmitButtonContainer } from "~forms/styled";

import {
  HistoryOfDeforestationAttribute,
  HistoryOfDeforestationData,
} from "./types";

type Props = {
  isSubmitting: boolean;
  isReadonly?: boolean | undefined;
  isSubmitButtonVisible?: boolean;
  onFormInitialize: (form: FormApi<HistoryOfDeforestationData>) => void;
};

export const HistoryOfDeforestationForm = ({
  isSubmitButtonVisible = true,
  isReadonly = false,
  isSubmitting,
  onFormInitialize,
}: Props) => {
  const form = useForm<HistoryOfDeforestationData>();
  const { pristine } = useFormState<HistoryOfDeforestationData>();

  useEffect(() => {
    onFormInitialize(form);
  }, [form, onFormInitialize]);

  return (
    <>
      <div className="space-y-4">
        <p className="text-p2">
          {I18n.t("js.carbon.soil_management_practices_description")}
        </p>

        <Field
          name={HistoryOfDeforestationAttribute.SoilMgmtHistoricConversion}
          render={({ input, meta }) => (
            <ButtonSelectField
              {...input}
              error={
                meta.touched && meta.error ? { message: meta.error } : undefined
              }
              label={I18n.t(
                "js.carbon.has_field_been_converted_in_the_last_20_years",
              )}
              tooltip={I18n.t(
                "js.carbon.soil_mgmt_historic_conversion_checkbox_instruction",
              )}
              testId="historyOfDeforestation-soilMgmtHistoricConversionButtonGroup"
              isDisabled={isReadonly}
            >
              {getYesNoOptions().map(option => (
                <ButtonSelect.Option
                  key={String(option.value)}
                  value={option.value}
                >
                  {option.label}
                </ButtonSelect.Option>
              ))}
            </ButtonSelectField>
          )}
        />
      </div>

      {isSubmitButtonVisible && !isReadonly && (
        <SFormSubmitButtonContainer>
          <Button
            data-testid="historyOfDeforestation-submitButton"
            type="submit"
            disabled={pristine}
            isLoading={isSubmitting}
            onClick={form.submit}
          >
            {I18n.t("js.shared.save")}
          </Button>
        </SFormSubmitButtonContainer>
      )}
    </>
  );
};
