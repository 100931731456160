import I18n from "@ag/i18n";

import {
  QualityControlCategory,
  QualityControlCheckKey,
  QualityControlFlag,
  QualityControlStatus,
} from "../entities/quality-control";

export const getQualityControlCheckKeyLabel = (
  value: QualityControlCheckKey,
): string | null => {
  const lookup: Record<QualityControlCheckKey, string> = {
    crop_yield: "Crop yield",
    cover_crop_hb: "Cover crop hb",
    cover_crop_hb_data: "Cover crop hb data",
    crop_type_category: "Crop type category",
    synthetic_nitrogen_application: "Fertiliser synthetic nitrogen",
    organic_nitrogen_application: "Fertiliser organic nitrogen",
    organic_application_rates: "Fertiliser organic rates",
    fertiliser_min_max: "Fertiliser min/max",
    detected_tillage: "Soil disturbance detected",
    tillage_category_fuel: "Fuel tillage category",
  };

  return lookup[value] ?? null;
};

export const getQualityControlCategoryLabel = (
  value: QualityControlCategory,
): string | null => {
  const lookup: Record<QualityControlCategory, string> = {
    cover_crop: "cover crop",
    crop: "crop",
    fertiliser: "fertiliser",
    residue_management: "residue management",
    soil_disturbance: "soil disturbance",
    fuel: "fuel",
  };

  return lookup[value] ?? null;
};

export const getQualityControlStatusLabel = (
  value: QualityControlStatus | null,
): string | null => {
  if (!value) return null;

  const lookup: Record<QualityControlStatus, string> = {
    qc_approved: "Approved",
    non_compliance: "Non compliance",
    non_conformance: "Non conformance",
    processing: "Processing",
  };

  return lookup[value] ?? null;
};

export const getQualityControlFlagLabel = (
  value: QualityControlFlag | QualityControlFlag[],
): string | null => {
  const lookup: Record<QualityControlFlag, string> = {
    blue: I18n.t("js.admin.flag.blue"),
    green: I18n.t("js.admin.flag.green"),
    red: I18n.t("js.admin.flag.red"),
    orange: I18n.t("js.admin.flag.orange"),
    yellow: I18n.t("js.admin.flag.yellow"),
    none: I18n.t("js.admin.flag.none"),
  };

  if (Array.isArray(value))
    return value.map(getQualityControlFlagLabel).join(", ");

  return lookup[value] ?? null;
};
