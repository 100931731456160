import { ValidationErrors } from "final-form";
import pickBy from "lodash/pickBy";

import { QuestionnaireQuestion, QuestionnaireValue } from "~Questionnaire";

type UseMapFormQuestionnaireStateArgs = {
  questions: QuestionnaireQuestion<string>[];
  formValues: QuestionnaireValue;
  formErrors: ValidationErrors;
  dirtyFieldsSinceLastSubmit: Record<string, boolean>;
};

export const useMapFormQuestionnaireState = ({
  questions,
  formValues,
  formErrors,
  dirtyFieldsSinceLastSubmit,
}: UseMapFormQuestionnaireStateArgs): [
  QuestionnaireValue,
  ValidationErrors,
] => {
  const isDefinedInConfig = (
    _value: string | number | boolean | null,
    formValueKey: string | number,
  ) => questions.some(question => question.key === formValueKey);

  //  Form values that keys are defined in the questionnaire config
  const questionnaireValue = pickBy<string | number | boolean | null>(
    formValues,
    isDefinedInConfig,
  );

  /**
   * Form errors whose keys are defined in the questionnaire config
   * and its dependencies weren’t changed since last submit
   * (prevents showing errors for nested question once the parent was answered)
   */
  const questionnaireErrors = pickBy<string>(
    formErrors,
    (value: string | number, formValueKey: string | number) => {
      if (!isDefinedInConfig(value, formValueKey)) {
        return false;
      }

      const dependencyKey = questions.find(
        question => question.key === formValueKey,
      )?.dependency?.key;

      if (dependencyKey) {
        return !dirtyFieldsSinceLastSubmit[dependencyKey];
      }

      return true;
    },
  );

  return [questionnaireValue, questionnaireErrors];
};
