import I18n from "@ag/i18n";
import { UserRole, UserValidationState } from "@ag/utils/types";

export const getUserRoleLabel = (role: UserRole | undefined) => {
  if (!role) return "";

  const labelByUserRole = {
    [UserRole.Farmer]: I18n.t("js.user.user_type.farmer"),
    [UserRole.InsightsCustomer]: I18n.t("js.user.user_type.insights_customer"),
  };

  return labelByUserRole[role] || "";
};

export const getUserValidationStateLabel = (
  validationState: UserValidationState,
) => {
  const labelByUserValidationState = {
    [UserValidationState.Validated]: I18n.t(
      "js.user.user_validation_state.validated",
    ),
    [UserValidationState.NotValidated]: I18n.t(
      "js.user.user_validation_state.non_validated",
    ),
    [UserValidationState.Revalidate]: I18n.t(
      "js.user.user_validation_state.revalidated",
    ),
    [UserValidationState.Waiting]: I18n.t(
      "js.user.user_validation_state.waiting",
    ),
  };

  return labelByUserValidationState[validationState] || "";
};

export function transformEnumToLabels(enumObj: Record<string, string>) {
  return Object.fromEntries(
    Object.entries(enumObj).map(([_, value]) => [
      value,
      value
        .replace(/[-_]/g, " ")
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" "),
    ]),
  );
}

export const transformedLabelValue = (value: string | number | undefined) => {
  if (value === undefined) {
    return "";
  }

  return String(value)
    .replace(/[-_]/g, " ")
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
