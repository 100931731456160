import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const TAB_PARAM = "tab";

type TabsArgs = {
  initialValue?: string;
  tabParamName?: string;
  replace?: boolean;
};

const useTabs = (args?: TabsArgs) => {
  const {
    initialValue,
    tabParamName = TAB_PARAM,
    replace = false,
  } = args || {};

  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string | undefined>(
    searchParams.get(tabParamName) || initialValue || undefined,
  );

  const onChange = useCallback(
    (tab: string) => {
      // Update query params
      searchParams.set(tabParamName, tab);
      setSearchParams(searchParams, {
        replace,
      });

      // Update state
      setActiveTab(tab);
    },
    [searchParams, tabParamName, setSearchParams, replace],
  );

  return {
    activeTab,
    onChange,
  };
};

export { useTabs };
