import usePagination from "@mui/material/usePagination";
import { useCallback, useMemo } from "react";

import { Meta } from "@ag/utils/types";

import { Icon, IconConfig, IconProps } from "~assets";
import { Select } from "~molecules";
import { cn } from "~utils";

export type PaginationValue = Meta;

const LIMIT = [10, 20, 50, 100];

/* -------------------------------------------------------------------------------------------------
 * Arrow button
 * -----------------------------------------------------------------------------------------------*/
type ArrowButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  IconConfig<"icon", "iconVariant"> & {
    disabled: boolean;
  };

const ArrowButton = ({ icon, ...props }: ArrowButtonProps) => (
  <button {...props} className="flex h-8 w-8 items-center justify-center">
    <Icon
      {...({ name: icon } as IconProps)}
      className={cn(props.disabled ? "text-grey-600" : "text-accent-200")}
    />
  </button>
);

/* -------------------------------------------------------------------------------------------------
 * Pagination
 * -----------------------------------------------------------------------------------------------*/
type PaginationProps = {
  infoText: (options: {
    itemsRange: [number, number];
    total: number;
  }) => string;
  perPageText: string;
  value: Meta | null;
  limit?: number;
  className?: string;
  onPageChange: (page: number) => void;
  onPerPageChange: (value: number) => void;
};

export const Pagination = ({
  infoText,
  perPageText,
  value,
  limit = LIMIT[0],
  className,
  onPageChange,
  onPerPageChange,
}: PaginationProps) => {
  const { items } = usePagination({
    page: value?.currentPage,
    count: value?.totalPages,
    showFirstButton: true,
    showLastButton: true,
  });

  const itemsRange: [number, number] = useMemo(
    () =>
      value?.totalItems
        ? [
            (value.currentPage - 1) * limit + 1,
            Math.min(value.currentPage * limit, value.totalItems),
          ]
        : [0, 0],
    [value, limit],
  );

  const setPage = useCallback(
    (page: number) => {
      if (!value) return;

      const target = Math.max(1, Math.min(page, value.totalPages));
      onPageChange(target);
    },
    [value, onPageChange],
  );

  const firstPreviousElements = useMemo(() => {
    if (!value?.totalPages) return [];

    return items.filter(({ type }) => ["first", "previous"].includes(type));
  }, [items, value?.totalPages]);
  const pagesElements = useMemo(
    () =>
      items.filter(({ type }) =>
        ["page", "start-ellipsis", "end-ellipsis"].includes(type),
      ),
    [items],
  );
  const nextLastElements = useMemo(() => {
    if (!value?.totalPages) return [];

    return items.filter(({ type }) => ["next", "last"].includes(type));
  }, [items, value?.totalPages]);

  if (!value) return null;

  return (
    <footer
      className={cn("flex items-center gap-4 text-p3 text-grey-900", className)}
    >
      <div className="flex flex-grow items-center gap-2 text-p2">
        <Select.Root
          value={limit.toString()}
          variant="compact"
          onChange={newPerPage => onPerPageChange(Number(newPerPage))}
        >
          {LIMIT.map(size => (
            <Select.Option key={size} value={size.toString()}>
              {size}
            </Select.Option>
          ))}
        </Select.Root>

        <label className="mb-0">{perPageText}</label>
      </div>

      <p
        className="text-p2"
        dangerouslySetInnerHTML={{
          __html: infoText({
            itemsRange,
            total: value.totalItems,
          }),
        }}
      />

      {/* First & previous */}
      {firstPreviousElements.length > 0 && (
        <div className="flex items-center gap-2">
          {firstPreviousElements.map(({ type }, index) => {
            const isDisabled = value.currentPage === 1;

            if (type === "first") {
              return (
                <ArrowButton
                  key={index}
                  aria-label="go to first page"
                  icon="chevrons-left"
                  disabled={isDisabled}
                  onClick={() => setPage(1)}
                />
              );
            }

            if (type === "previous") {
              return (
                <ArrowButton
                  key={index}
                  aria-label="go to previous page"
                  icon="chevron-left"
                  disabled={isDisabled}
                  onClick={() => setPage(value.currentPage - 1)}
                />
              );
            }
          })}
        </div>
      )}

      {/* Pages */}
      {pagesElements.length > 0 && (
        <div className="flex items-center gap-2">
          {pagesElements.map(({ page, type }, index) => {
            if (
              type === "start-ellipsis" ||
              type === "end-ellipsis" ||
              page === null
            ) {
              return (
                <div
                  key={index}
                  aria-label="show more"
                  className="flex h-10 w-10 items-center justify-center"
                >
                  <Icon name="ellipsis" className="text-grey-700" />
                </div>
              );
            }

            if (type === "page") {
              return (
                <button
                  key={index}
                  className={cn(
                    "flex h-10 w-10 items-center justify-center rounded focus:outline-none",
                    page === value.currentPage && "bg-grey-200",
                    page > 999 && "w-12",
                  )}
                  onClick={() => setPage(page)}
                >
                  {page}
                </button>
              );
            }

            return null;
          })}
        </div>
      )}

      {/* Next & last */}
      {nextLastElements.length > 0 && (
        <div className="flex items-center gap-2">
          {nextLastElements.map(({ type }, index) => {
            const isDisabled = value.currentPage >= value.totalPages;

            if (type === "next") {
              return (
                <ArrowButton
                  key={index}
                  aria-label="go to next page"
                  icon="chevron-right"
                  disabled={isDisabled}
                  onClick={() => setPage(value.currentPage + 1)}
                />
              );
            }

            if (type === "last") {
              return (
                <ArrowButton
                  key={index}
                  aria-label="go to last page"
                  icon="chevrons-right"
                  disabled={isDisabled}
                  onClick={() => setPage(value.totalPages)}
                />
              );
            }
          })}
        </div>
      )}
    </footer>
  );
};
