import { BatchMeta } from "./types";

export enum ActionType {
  Process = "PROCESS",
  SetMeta = "SET_META",
  AddItems = "ADD_ITEMS",
  Complete = "COMPLETE",
}

export type State<T> = {
  items: T | undefined;
  meta: BatchMeta | undefined;
  isLoading: boolean;
  isSettled: boolean;
};

export type Action<T> =
  | {
      type: ActionType.Process;
    }
  | {
      type: ActionType.SetMeta;
      meta: BatchMeta;
    }
  | {
      type: ActionType.AddItems;
      items: T;
    }
  | {
      type: ActionType.Complete;
    };

export const initialState = {
  items: undefined,
  meta: undefined,
  isSettled: false,
  isLoading: false,
};

export const batchFetchReducer = <T extends unknown[]>(
  state: State<T>,
  action: Action<T>,
): State<T> => {
  switch (action.type) {
    case ActionType.Process:
      return {
        ...initialState,
        isLoading: true,
      };

    case ActionType.SetMeta:
      return {
        ...state,
        meta: action.meta,
      };

    case ActionType.AddItems:
      return {
        ...state,
        items: state.items
          ? ([...state.items, ...action.items] as T)
          : action.items,
      };

    case ActionType.Complete:
      return {
        ...state,
        isLoading: false,
        isSettled: true,
      };

    default:
      return state;
  }
};
