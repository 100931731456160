import { create } from "zustand";

type UpdateClientState = {
  documents: File[];
  fileErrors: string[] | undefined;
  markedForRemoval: Set<number>;
  setDocuments: (documents: File[]) => void;
  setFileErrors: (fileErrors: string[] | undefined) => void;
  setMarkedForRemoval: (markedForRemoval: Set<number>) => void;
  reset: () => void;
};

export const useUpdateClientStore = create<UpdateClientState>(set => ({
  documents: [],
  fileErrors: undefined,
  markedForRemoval: new Set(),
  setDocuments: documents => set({ documents }),
  setFileErrors: fileErrors => set({ fileErrors }),
  setMarkedForRemoval: markedForRemoval => set({ markedForRemoval }),
  reset: () =>
    set({ documents: [], fileErrors: undefined, markedForRemoval: new Set() }),
}));
