import { SerializedStyles, css } from "@emotion/core";
import type { Interpolation } from "@emotion/serialize";

type MediaArg = (TemplateStringsArray | string | number)[];

type MediaType = {
  [name: string]: (...args: MediaArg) => SerializedStyles;
};

export const breakpoint: Record<string, number> = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 576,
  xxs: 350,
};

export const media: MediaType = Object.keys(breakpoint).reduce(
  (accumulator: MediaType, label) => {
    const emSize = breakpoint[label] / 16;

    if (label === "xl") {
      accumulator[label] = (...args: unknown[]): SerializedStyles => css`
        @media (min-width: ${emSize}em) {
          ${css(...(args as Interpolation<undefined>[]))};
        }
      `;
    } else {
      accumulator[label] = (...args: unknown[]): SerializedStyles => css`
        @media (max-width: ${emSize}em) {
          ${css(...(args as Interpolation<undefined>[]))};
        }
      `;
    }
    return accumulator;
  },
  {},
);

export const mediaUp: MediaType = Object.keys(breakpoint).reduce(
  (accumulator: MediaType, label) => {
    const emSize = breakpoint[label] / 16;

    accumulator[label] = (...args: unknown[]): SerializedStyles => css`
      @media (min-width: ${emSize}em) {
        ${css(...(args as Interpolation<undefined>[]))};
      }
    `;

    return accumulator;
  },
  {},
);
