import { useParams } from "react-router-dom";

import I18n from "@ag/i18n";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";
import { useFieldDetailsQuery } from "~queries/carbon/fields";

import Banner from "./Banner";
import FieldContent from "./FieldContent";
import FieldDataBox from "./FieldDataBox";

const EditField = () => {
  const params = useParams();
  const fieldId = Number(params.id);

  const { data: field } = useFieldDetailsQuery(fieldId);

  if (!field) return null;

  return (
    <DetailsLayout.Root>
      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>
          {I18n.t("js.carbon.admin_carbon_fields_title")}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.Content>
        <Banner />

        {field && <FieldDataBox field={field} />}

        <FieldContent field={field} />
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default EditField;
