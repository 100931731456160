import { css } from "@emotion/core";

import { style } from "./styles";

export const H1Typography = css`
  font-size: ${style.typography.fontSize.h1}px;
  line-height: ${style.typography.lineHeight.h1}px;
  font-weight: ${style.typography.fontWeight.h1};
`;

export const H2Typography = css`
  font-size: ${style.typography.fontSize.h2}px;
  line-height: ${style.typography.lineHeight.h2}px;
  font-weight: ${style.typography.fontWeight.h2};
`;

export const H3Typography = css`
  font-size: ${style.typography.fontSize.h3}px;
  line-height: ${style.typography.lineHeight.h3}px;
  font-weight: ${style.typography.fontWeight.h3};
`;

export const H4Typography = css`
  font-size: ${style.typography.fontSize.h4}px;
  line-height: ${style.typography.lineHeight.h4}px;
  font-weight: ${style.typography.fontWeight.h4};
`;

export const H5Typography = css`
  font-size: ${style.typography.fontSize.h5}px;
  line-height: ${style.typography.lineHeight.h5}px;
  font-weight: ${style.typography.fontWeight.h5};
`;

export const H6Typography = css`
  font-size: ${style.typography.fontSize.h6}px;
  line-height: ${style.typography.lineHeight.h6}px;
  font-weight: ${style.typography.fontWeight.h6};
`;

export const H7Typography = css`
  font-size: ${style.typography.fontSize.h7}px;
  line-height: ${style.typography.lineHeight.h7}px;
  font-weight: ${style.typography.fontWeight.h7};
`;

export const H8Typography = css`
  font-size: ${style.typography.fontSize.h8}px;
  line-height: ${style.typography.lineHeight.h8}px;
  font-weight: ${style.typography.fontWeight.h8};
`;

export const P1Typography = css`
  font-size: ${style.typography.fontSize.p1}px;
  line-height: ${style.typography.lineHeight.p1}px;
  font-weight: ${style.typography.fontWeight.p1};
`;

export const P2Typography = css`
  font-size: ${style.typography.fontSize.p2}px;
  line-height: ${style.typography.lineHeight.p2}px;
  font-weight: ${style.typography.fontWeight.p2};
`;

export const P3Typography = css`
  font-size: ${style.typography.fontSize.p3}px;
  line-height: ${style.typography.lineHeight.p3}px;
  font-weight: ${style.typography.fontWeight.p3};
`;
