import { useCallback, useState } from "react";

type HistoryItem = {
  outerPath: google.maps.LatLng[];
  innerPaths: google.maps.LatLng[][];
};

export type HistoryStack = {
  itemsAmount: number;
  pop: () => HistoryItem | undefined;
  push: (item: HistoryItem) => void;
  reset: () => void;
};

const MAX_HISTORY_ITEMS_SIZE = 10;

export const usePolygonPathsHistory = (): HistoryStack => {
  const [historyStack, setHistoryStack] = useState<HistoryItem[]>([]);

  const resetStack = useCallback(() => setHistoryStack([]), []);

  const pop = useCallback(() => {
    if (!historyStack.length) {
      console.error("HistoryStack pop(), no history items in the stack");

      return;
    }

    const previousHistoryItem = historyStack[historyStack.length - 1];

    setHistoryStack(previousStack => {
      const previousStackCopy = [...previousStack];
      previousStackCopy.splice(-1);

      return previousStackCopy;
    });

    return previousHistoryItem;
  }, [historyStack]);

  const push = useCallback(
    (item: HistoryItem) => {
      setHistoryStack(previousStack => {
        const previousStackCopy = [...previousStack];

        if (historyStack.length === MAX_HISTORY_ITEMS_SIZE) {
          previousStackCopy.shift();
        }

        return [...previousStackCopy, item];
      });
    },
    [historyStack],
  );

  return {
    itemsAmount: historyStack.length,
    pop,
    push,
    reset: resetStack,
  };
};
