import { RowSelectionState } from "@tanstack/react-table";
import { uniq } from "lodash";
import { useCallback, useMemo, useState } from "react";
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  createEnumParam,
  useQueryParam,
} from "use-query-params";

import { useFilters } from "@ag/components/Filters";
import { Button, IconButton, InfoBox } from "@ag/design-system/atoms";
import { ButtonSelect, FloatingMenu } from "@ag/design-system/molecules";
import { TableRowError } from "@ag/design-system/organisms";
import { cluster, stack } from "@ag/design-system/utils";
import I18n from "@ag/i18n";
import { usePagination } from "@ag/utils/hooks";
import { FieldActualsStatus } from "@ag/utils/types";

import BackButton from "~components/BackButton";
import { SideSheet } from "~components/SideSheet";
import {
  TableSettings,
  useStoredTableSettings,
} from "~components/TableSettings";
import { Filters } from "~components/filters";
import Table from "~components/table";
import { queryClient } from "~config";
import {
  AssuranceCategory,
  AssuranceCheckKey,
  AssuranceFilterData,
  AssuranceStatus,
  AssuranceTableData,
  Field,
  FieldCommentsModal,
  FieldDefinitionChangesModal,
  FieldsFilters,
  INITIAL_ASSURANCE_TABLE_STATE,
  addFieldsComment,
  generateFieldsQueryKey,
  getAssuranceFilterLabel,
  getAssuranceFilterValueLabel,
  getFlagFilters,
  getValueFilters,
  useFieldsBulkActions,
  useFieldsQuery,
  useFieldsTable,
} from "~features/assurance";
import { useCarbonCountries } from "~features/countries";
import { useYearsDataQuery } from "~features/initial-resources";
import { AuthorizedSidebar } from "~features/navigation";
import { useCarbonPermissions } from "~features/permission";
import ListLayout from "~layouts/list-layout";

const filtersConfig = {
  userId: [StringParam, { isDebounced: true }],
  id: [StringParam, { isDebounced: true }],
  countryId: StringParam,
  actualStatus: createEnumParam(Object.values(FieldActualsStatus)),
  qaStatus: createEnumParam([...Object.values(AssuranceStatus), "none"]),
  baselineVersion: StringParam,
  hummingbirdDataAvailability: ArrayParam,
  allGreenFlag: BooleanParam,
  hasComments: BooleanParam,

  // Deforestation
  "deforestation.value": BooleanParam,
  "deforestation.flag": ArrayParam,

  // Duplication
  "duplication.flag": ArrayParam,

  // Cover crop
  "cover_crop.value": StringParam,
  "cover_crop.flag": ArrayParam,

  // Soil disturbance
  "soil_disturbance.value": StringParam,
  "soil_disturbance.flag": ArrayParam,

  // Reported size
  "field_size_reported_size.value.min": [StringParam, { isDebounced: true }],
  "field_size_reported_size.value.max": [StringParam, { isDebounced: true }],
  "field_size_reported_size.flag": ArrayParam,

  // Intersection
  // TODO: rename `intersection_sum` -> `intersection`
  "intersection_sum.value.min": [StringParam, { isDebounced: true }],
  "intersection_sum.value.max": [StringParam, { isDebounced: true }],
  "intersection_sum.flag": ArrayParam,
} as const;

// TODO: get this from settings
const FIELD_ACTUALS_YEAR = 2023;

const Assurance = () => {
  const [pagination, updatePagination, resetPagination] = usePagination({
    defaultLimit: 100,
  });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const filters = useFilters(filtersConfig);

  const [commentsModal, setCommentsModal] = useState<{
    fields: Field[];
  } | null>(null);

  const [fieldDefinitionChangesModal, setFieldDefinitionChangesModal] =
    useState<{
      field: Field;
    } | null>(null);

  const { data: countries, isLoading: isCountriesLoading } =
    useCarbonCountries();

  const { data: carbonPermissions } = useCarbonPermissions();

  const [tableState, setTableState] = useStoredTableSettings(
    "assurance",
    INITIAL_ASSURANCE_TABLE_STATE,
  );

  const { data: yearsData } = useYearsDataQuery();

  const actualsYears = useMemo(() => {
    const allYears = [
      ...(yearsData?.carbonActiveHarvestYears ?? []),
      FIELD_ACTUALS_YEAR,
    ];

    return uniq(allYears.sort());
  }, [yearsData?.carbonActiveHarvestYears]);

  const [harvestYear, setHarvestYear] = useQueryParam(
    "harvestYear",
    NumberParam,
  );

  const flagFilters = getFlagFilters(filters.values);
  const valueFilters = getValueFilters(filters.values);

  const { data: fieldsData, isLoading: isLoading } = useFieldsQuery({
    ...pagination,
    harvestYear: harvestYear ?? FIELD_ACTUALS_YEAR,
    filters: {
      userId: filters.values.userId ? [filters.values.userId] : undefined,
      id: filters.values.id ? [filters.values.id] : undefined,
      carbonCountryId: filters.values.countryId
        ? [filters.values.countryId]
        : undefined,
      actualStatus: filters.values.actualStatus,
      qaStatus: filters.values.qaStatus,
      baselineVersion: filters.values.baselineVersion,
      hummingbirdDataAvailability: filters.values.hummingbirdDataAvailability,
      allGreenFlag: filters.values.allGreenFlag,
      hasComments: filters.values.hasComments,
    },
    dynamicFilters: {
      flag: flagFilters,
      value: valueFilters,
    },
  });

  const bulkActions = useFieldsBulkActions();

  const handleCommentModalOpened = useCallback(
    (params: { selection: Record<string, boolean> }) => {
      const fields = fieldsData?.items.filter(({ id }) => params.selection[id]);

      if (!fields?.length) return;

      setCommentsModal({ fields });
    },
    [fieldsData?.items],
  );

  const handleFieldDefitionChangeModalOpened = useCallback(
    (params: { fieldId: string }) => {
      const field = fieldsData?.items.find(({ id }) => id === params.fieldId);
      if (!field) return;

      setFieldDefinitionChangesModal({
        field,
      });
    },
    [fieldsData?.items],
  );

  const handleCommentAdded = useCallback(
    async (comment: {
      category: AssuranceCategory;
      checks?: AssuranceCheckKey;
      text: string;
    }) => {
      const fields = commentsModal?.fields;
      if (!fields?.length) return;

      const hasSelection = Object.keys(bulkActions.selection).length;

      if (hasSelection) {
        await bulkActions.addComment(comment);
      } else {
        await addFieldsComment({
          fieldIds: [fields[0].id],
          payload: comment,
        });

        await queryClient.invalidateQueries(generateFieldsQueryKey());
      }

      setCommentsModal({ fields: [] });
    },
    [commentsModal, bulkActions],
  );

  const handleOnSelectionChange = useCallback(
    (
      selectionOrUpdater:
        | RowSelectionState
        | ((old: RowSelectionState) => RowSelectionState),
    ) => {
      const values =
        typeof selectionOrUpdater === "function"
          ? selectionOrUpdater(bulkActions.selection)
          : selectionOrUpdater;

      bulkActions.updateSelection(values);
    },
    [bulkActions],
  );

  const renderFilterBarItem = (
    key: keyof AssuranceFilterData,
    value: AssuranceFilterData[keyof AssuranceFilterData],
  ) => {
    const filterLabel = getAssuranceFilterLabel(key);
    const filterValueLabel = getAssuranceFilterValueLabel(key, value, {
      countries,
    });

    return `${filterLabel}: ${filterValueLabel}`;
  };

  const state = useMemo(
    () => ({
      ...tableState,
      rowSelection: bulkActions.selection,
    }),
    [bulkActions.selection, tableState],
  );

  const tableErrors = useMemo(() => {
    const lookup: Record<string, TableRowError<AssuranceTableData>[]> = {};

    for (const result of bulkActions.results) {
      for (const error of result.errors ?? []) {
        const tableRowError = new TableRowError(error.message, "fieldId");
        if (!lookup[error.carbonFieldId]) {
          lookup[error.carbonFieldId] = [tableRowError];
        } else {
          lookup[error.carbonFieldId].push(tableRowError);
        }
      }
    }

    return lookup;
  }, [bulkActions.results]);

  const table = useFieldsTable({
    data: fieldsData?.items,
    permissions: carbonPermissions,
    harvestYear: harvestYear ?? FIELD_ACTUALS_YEAR,
    state,
    errors: tableErrors,
    onShowComments: handleCommentModalOpened,
    onShowDefinitionChanges: handleFieldDefitionChangeModalOpened,
    onSelectionChange: handleOnSelectionChange,
  });

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle>Assurance</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />

        <SideSheet.Root
          isOpen={isFiltersOpen}
          onClose={() => setIsFiltersOpen(false)}
        >
          <SideSheet.Header>Filters</SideSheet.Header>

          <SideSheet.Content>
            <FieldsFilters
              countries={
                countries?.sort((a, b) => (a.name > b.name ? 1 : -1)) ?? []
              }
              values={filters.values}
              onChange={(...args) => {
                filters.onChange(...args);
                resetPagination();
                bulkActions.clearSelection();
              }}
              onClear={filters.onClear}
            />
          </SideSheet.Content>

          <SideSheet.Footer>
            <Button variant="secondary" onClick={() => filters.onClear()}>
              {I18n.t("js.shared.clear_all")}
            </Button>
          </SideSheet.Footer>
        </SideSheet.Root>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <Filters.Bar
            {...filters}
            renderItem={renderFilterBarItem}
            onChange={(...args) => {
              bulkActions.clearSelection();
              filters.onChange(...args);
            }}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
          />

          <div className={cluster({ align: "center" })}>
            <FloatingMenu.Root
              triggerSize="small"
              title={
                Object.keys(bulkActions.selection).length
                  ? I18n.t("js.admin.assurance.list.select_action", {
                      count: Object.keys(bulkActions.selection).length,
                    })
                  : I18n.t("js.admin.assurance.list.select_action_disabled")
              }
              isDisabled={!Object.keys(bulkActions.selection).length}
            >
              <FloatingMenu.Option
                onClick={() =>
                  handleCommentModalOpened({
                    selection: bulkActions.selection,
                  })
                }
              >
                {I18n.t("js.admin.assurance.list.add_comment")}
              </FloatingMenu.Option>

              <FloatingMenu.Option
                onClick={() =>
                  bulkActions.changeStatus(AssuranceStatus.Processing)
                }
              >
                {I18n.t("js.admin.assurance.list.change_status_processing")}
              </FloatingMenu.Option>

              <FloatingMenu.Option
                isDanger
                onClick={() =>
                  bulkActions.changeStatus(AssuranceStatus.NonConformance)
                }
              >
                {I18n.t(
                  "js.admin.assurance.list.change_status_non_conformance",
                )}
              </FloatingMenu.Option>

              <FloatingMenu.Option
                isDanger
                onClick={() =>
                  bulkActions.changeStatus(AssuranceStatus.NonCompliance)
                }
              >
                {I18n.t("js.admin.assurance.list.change_status_non_compliance")}
              </FloatingMenu.Option>

              <FloatingMenu.Option
                onClick={() =>
                  bulkActions.changeStatus(AssuranceStatus.Approved)
                }
              >
                {I18n.t("js.admin.assurance.list.change_status_approved")}
              </FloatingMenu.Option>
            </FloatingMenu.Root>

            <IconButton
              icon="cog"
              variant="secondary"
              size="small"
              onClick={() => setIsSettingsOpen(val => !val)}
            />

            {!isCountriesLoading ? (
              <ButtonSelect.Root
                value={(harvestYear ?? FIELD_ACTUALS_YEAR)?.toString()}
                onChange={value => setHarvestYear(parseInt(value))}
                size="small"
              >
                {actualsYears.map(year => (
                  <ButtonSelect.Option key={year} value={year.toString()}>
                    {year}
                  </ButtonSelect.Option>
                ))}
              </ButtonSelect.Root>
            ) : null}
          </div>

          {bulkActions.results.length ? (
            <div className={stack({ gap: 8 })} style={{ gridColumn: "span 2" }}>
              {bulkActions.results.map(result => (
                <InfoBox
                  key={result.id}
                  variant={result.type === "success" ? "success" : "danger"}
                  icon={result.type === "success" ? "check" : "warning"}
                  onClose={() => bulkActions.removeResult(result.id)}
                >
                  {result.message}
                </InfoBox>
              ))}
            </div>
          ) : null}
        </ListLayout.Header>

        <Table
          instance={table}
          meta={fieldsData?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={(...args) => {
            bulkActions.clearSelection();
            updatePagination(...args);
          }}
        />

        <FieldCommentsModal
          isOpen={Boolean(commentsModal)}
          fields={commentsModal?.fields ?? []}
          onAddComment={handleCommentAdded}
          onRequestClose={() => setCommentsModal(null)}
        />

        <FieldDefinitionChangesModal
          isOpen={Boolean(fieldDefinitionChangesModal)}
          field={fieldDefinitionChangesModal?.field}
          onRequestClose={() => setFieldDefinitionChangesModal(null)}
        />

        <TableSettings
          instance={table}
          state={tableState}
          isOpen={isSettingsOpen}
          onStateChange={value => setTableState(value)}
          onClose={() => setIsSettingsOpen(false)}
          onReset={() => setTableState(INITIAL_ASSURANCE_TABLE_STATE)}
        />
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Assurance;
