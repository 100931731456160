import { HeaderContext, RowData } from "@tanstack/react-table";

import { Checkbox } from "~atoms";
import { stack } from "~utils";

type Props<TData extends RowData> = HeaderContext<TData, unknown>;

export const HeaderSelectionCell = <TData extends RowData>({
  table,
}: Props<TData>) => {
  const canSelect = table
    .getCoreRowModel()
    .flatRows.some(row => row.getCanSelect());

  return (
    <div className={stack({})}>
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={
          table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
        }
        disabled={!canSelect}
        onChange={() => {
          // Remove selection if only some and not all are selected.
          // (according to UI, that aligns better with the "-" symbol)
          if (table.getIsSomeRowsSelected()) {
            table.toggleAllRowsSelected(false);
            return;
          }

          table.toggleAllRowsSelected();
        }}
      />
    </div>
  );
};
