"use client";

import { createRef, forwardRef, useEffect, useLayoutEffect } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn } from "~utils";

type OwnProps = {
  rightAddon?: React.ReactNode;
  leftAddon?: React.ReactNode;
  isInvalid?: boolean;
};

type Props = OwnProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "dangerouslySetInnerHTML">;

export const Input = forwardRef<HTMLInputElement, Props>(
  ({ rightAddon, leftAddon, isInvalid, ...rest }, ref) => {
    const inputRef = createRef<HTMLInputElement>();
    const rightAddonRef = createRef<HTMLDivElement>();
    const leftAddonRef = createRef<HTMLDivElement>();

    useEffect(() => {
      inputRef.current?.setCustomValidity(isInvalid ? "error" : "");
    }, [inputRef, isInvalid]);

    useLayoutEffect(() => {
      if (rightAddonRef.current && inputRef.current) {
        inputRef.current.style.paddingRight = `${
          rightAddonRef.current.offsetWidth + 16
        }px`;
      }
    }, [inputRef, rightAddonRef]);

    useLayoutEffect(() => {
      if (leftAddonRef.current && inputRef.current) {
        inputRef.current.style.paddingLeft = `${
          leftAddonRef.current.offsetWidth + 16
        }px`;
      }
    }, [inputRef, leftAddonRef]);

    return (
      <div className="relative inline-flex">
        {leftAddon && (
          <div
            ref={leftAddonRef}
            className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-4 text-grey-700"
          >
            {leftAddon}
          </div>
        )}

        <input
          ref={mergeRefs([ref, inputRef])}
          className={cn([
            "w-full text-ellipsis whitespace-nowrap rounded border border-solid border-grey-700 px-4 py-2.5 text-p2 placeholder-grey-500 focus:border-accent-200 focus:outline-none active:border-accent-200 data-[placeholder]:text-grey-500",
            {
              "border-messaging-error-700": isInvalid,
              "pointer-events-none border-grey-300 bg-grey-300": rest.disabled,
            },
          ])}
          {...rest}
        />

        {rightAddon && (
          <div
            ref={rightAddonRef}
            className="absolute inset-y-0 right-0 flex items-center pr-4 text-grey-700"
          >
            {rightAddon}
          </div>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";
