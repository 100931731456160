import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  BuyoutAgreement,
  BuyoutAgreementSchema,
} from "../entities/buyout-agreement";

type BuyoutAgreementsParams = MetaArgs & {
  filters?: Partial<{
    userId: string;
    status: string;
    year: string;
  }>;
  sort?: Partial<{
    id: SortingDirection;
  }>;
};

export const generateBuyoutAgreementsQueryKey = (keyVariables?: {
  params?: BuyoutAgreementsParams | undefined;
}) => ["buyout-contracts", keyVariables].filter(Boolean);

const getBuyoutAgreements = async (
  params: BuyoutAgreementsParams | undefined,
) => {
  const response = await axios.get<CommonPaginatedResponse<BuyoutAgreement>>(
    "/api/v1/carbon-sales-agreements",
    {
      params: {
        ...params,
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(BuyoutAgreementSchema),
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useBuyoutAgreementsQuery = (params?: BuyoutAgreementsParams) =>
  useQuery(
    generateBuyoutAgreementsQueryKey({ params }),
    () => getBuyoutAgreements(params),
    {
      keepPreviousData: true,
    },
  );
