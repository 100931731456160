import { format, parseISO } from "date-fns";

const LastModifiedInfo = ({
  date,
  owner,
}: {
  date?: string;
  owner?: string;
}) => {
  if (!date || !owner) return null;
  const formattedDate = format(parseISO(date), "dd/MM/yyyy");

  return (
    <div className="flex w-full px-1 py-0.5">
      <span className="ml-auto mt-1 text-p3 text-grey-600">
        Last modified on {formattedDate} by {owner}
      </span>
    </div>
  );
};

export default LastModifiedInfo;
