import I18n from "i18n-js";

import { addPluralization } from "./pluralization";
import { Locale } from "./types";

declare global {
  interface Window {
    __I18n__: typeof I18n;
  }
}

/**
 * Need to set it up like this to properly share the instance across
 * both apps and packages.
 */
if (typeof window !== "undefined") {
  window.__I18n__ = window.__I18n__ || I18n;
}
export const instance =
  typeof window !== "undefined" ? window.__I18n__ || I18n : I18n;

instance.defaultLocale = "en";
instance.fallbacks = true;
addPluralization(instance);

export const updateTranslations = (
  locale: Locale,
  translations: object,
  /**
   * Use this if you need to patch an external instance, eg. _not_ in this monorepo.
   * (fx. js-api-maker)
   */
  externalInstance?: typeof I18n,
) => {
  instance.locale = locale;

  if (instance.translations[locale]) return;

  instance.translations = {
    // Keep "en", as it is the fallback language
    [Locale.En]: instance.translations[Locale.En],
    ...translations,
  };

  // sync changes with external instance
  if (externalInstance) {
    externalInstance.locale = locale;
    externalInstance.translations = instance.translations;
  }
};
