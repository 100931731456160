import {
  AdditionalInformationData,
  HistoryOfDeforestationData,
  SoilCharacteristicsData,
  SoilCultivationPracticeData,
} from "@ag/carbon/forms/baseline/iso";

/**
 * GUARDS
 */
export const isSoilCultivationPracticePartialFormApi = (
  partialFormApi:
    | SoilCharacteristicsData
    | HistoryOfDeforestationData
    | SoilCultivationPracticeData
    | AdditionalInformationData,
): partialFormApi is SoilCultivationPracticeData =>
  "cultivatedFieldInTheLastFiveYears" in partialFormApi &&
  "rotatedCropsInTheLastFiveYears" in partialFormApi;
