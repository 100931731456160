export const validateCertificateIds = (
  value: string | undefined,
  delimiter: string | RegExp = ",",
): boolean => {
  // If input is empty, is valid
  if (value === undefined || value.trim() === "") {
    return true;
  }

  // value is split into parts using delimiter. Each part is trimmed of whitespace.
  const parts = value.split(delimiter).map(part => part.trim());

  for (const part of parts) {
    // Each part is checked against a regular expression to ensure it's either a single number or a number range (e.g., "1-5").
    if (!/^(\d+|\d+-\d+)$/.test(part)) {
      return false;
    }

    // If a part is a range, it checks that the start of the range is less than the end (e.g., 2-1 not allowed) otherwise it returns false.
    if (part.includes("-")) {
      const [start, end] = part.split("-").map(num => BigInt(num));
      if (start > end) {
        return false;
      }
    }

    // Each part is checked to ensure all numbers are within range and by allowing bigInt (0 to 9223372036854775807), otherwise it returns false.
    const numbers = part.split("-").map(num => BigInt(num));
    const maxBigInt = BigInt("9223372036854775807");

    if (numbers.some(number => number < 0 || number > maxBigInt)) {
      return false;
    }
  }

  return true;
};
