import {
  QualityControlCategory,
  QualityControlCheckKey,
} from "../entities/quality-control";

export function getQualityControlCheckKeys(category?: QualityControlCategory) {
  if (!category) return [];

  const lookup: Record<QualityControlCategory, QualityControlCheckKey[]> = {
    cover_crop: [
      QualityControlCheckKey.CoverCropHb,
      QualityControlCheckKey.CoverCropHbData,
      QualityControlCheckKey.CropTypeCategory,
    ],
    crop: [QualityControlCheckKey.CropYield],
    fertiliser: [
      QualityControlCheckKey.FertiliserSyntheticNitrogenApplication,
      QualityControlCheckKey.FertiliserOrganicNitrogenApplication,
      QualityControlCheckKey.FertiliserOrganicApplicationRates,
      QualityControlCheckKey.FertiliserMinMax,
    ],
    residue_management: [],
    soil_disturbance: [QualityControlCheckKey.SoilDisturbanceDetectedTillage],
    fuel: [QualityControlCheckKey.FuelTillageCategory],
  };

  return lookup[category];
}
