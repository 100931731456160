import { isEqual } from "lodash";

import { getPathType } from "@ag/map/helpers";

import { ActiveVertex } from "./boundaries-drawing-polygon/polygonStore";

export const isCoordinatesEqual = (
  a: google.maps.LatLng[] | google.maps.LatLng[][],
  b: google.maps.LatLng[] | google.maps.LatLng[][],
) => isEqual(a, b);

export const getActiveVertexPosition = (
  activeVertex: ActiveVertex | undefined,
  outerPaths: google.maps.LatLng[],
  innerPaths: google.maps.LatLng[][],
) => {
  if (!activeVertex) return;

  const activeVertexPath = getActiveVertexPath(
    activeVertex,
    outerPaths,
    innerPaths,
  );

  return activeVertexPath?.[activeVertex.vertex];
};

export const getActiveVertexPath = (
  activeVertex: ActiveVertex | undefined,
  outerPaths: google.maps.LatLng[],
  innerPaths: google.maps.LatLng[][],
) => {
  if (!activeVertex) return;

  const { path } = activeVertex;

  const pathPlacement = getPathType(path);

  if (pathPlacement === "outer") {
    return outerPaths;
  }

  if (pathPlacement === "inner") {
    return innerPaths?.[path - 1];
  }
};
