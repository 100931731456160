import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";
import { ToastNotification } from "@ag/utils/services";

import {
  TerminateAgreementButton,
  WithdrawAgreementButton,
} from "~components/action-agreement";
import { AdminRole } from "~features/admin";
import useHasAdminRole from "~hooks/useHasAdminRole";

import { useTerminateBuyoutAgreementMutation } from "../api/terminate-buyout-agreement";
import { useWithdrawBuyoutAgreementMutation } from "../api/withdraw-buyout-agreement";
import {
  BuyoutAgreement,
  BuyoutContractStatus,
} from "../entities/buyout-agreement";

export const BuyoutAgreementHeader = ({
  id,
  status,
  transferAgreementUrl,
  transferAppendixUrl,
  saleAndPurchaseAgreementUrl,
}: BuyoutAgreement) => {
  const isCSCarbonAdmin = useHasAdminRole(AdminRole.CSCarbon);
  const isWithdrawable =
    status === BuyoutContractStatus.Sent && isCSCarbonAdmin;
  const isDataEditorAdmin = useHasAdminRole(AdminRole.DataEditor);
  const canTerminateContract =
    status === BuyoutContractStatus.Signed && isDataEditorAdmin;
  const navigate = useNavigate();
  const withdrawBuyoutAgreementMutation = useWithdrawBuyoutAgreementMutation();
  const terminateBuyoutAgreementMutation =
    useTerminateBuyoutAgreementMutation();

  // v1 Agreement url
  const agreementRouteParams = new URLSearchParams({
    url: transferAgreementUrl || "",
    apiSource: "node-carbon",
  });

  // v2 Agreement url
  const saleAndPurchaseAgreementRouteParams = new URLSearchParams({
    url: saleAndPurchaseAgreementUrl || "",
    apiSource: "node-carbon",
  });

  const appendixRouteParams = new URLSearchParams({
    url: transferAppendixUrl || "",
    apiSource: "node-carbon",
  });

  const handleWithdraw = useCallback(
    (data: { comment: string }, options: { onSuccess: () => void }) => {
      withdrawBuyoutAgreementMutation.mutate(
        {
          id,
          reason: data.comment,
        },
        {
          onSuccess: () => {
            options.onSuccess();

            ToastNotification.info(`Buyout agreement ${id} withdrawn`);

            navigate("/carbon/agreements/buyout");
          },
          onError: () => {
            ToastNotification.error(
              "There was an error withdrawing buyout agreement",
            );
          },
        },
      );
    },
    [navigate, id, withdrawBuyoutAgreementMutation],
  );

  const handleTerminate = useCallback(
    (data: { comment: string }, options: { onSuccess: () => void }) => {
      terminateBuyoutAgreementMutation.mutate(
        { id: id, reasonComment: data.comment },
        {
          onSuccess: () => {
            options.onSuccess();
            ToastNotification.info(`Buyout agreement ${id} terminated`);

            navigate("/carbon/agreements/buyout");
          },
          onError: () => {
            ToastNotification.error(
              "There was an error terminating buyout agreement",
            );
          },
        },
      );
    },
    [navigate, id, terminateBuyoutAgreementMutation],
  );

  return (
    <section className="flex items-center justify-between">
      <h3 className="flex gap-4 text-h3">
        <span className="text-grey-700">Buyout Agreement ID</span>

        <span className="text-black-900">{id}</span>
      </h3>

      <div className="flex gap-2">
        {transferAgreementUrl && (
          <Button
            variant="secondary"
            size="x-small"
            href={`/file?${agreementRouteParams}`}
            icon="fileArrowDown"
          >
            Download buyout agreement
          </Button>
        )}

        {saleAndPurchaseAgreementUrl && (
          <Button
            variant="secondary"
            size="x-small"
            href={`/file?${saleAndPurchaseAgreementRouteParams}`}
            icon="fileArrowDown"
            data-testid="download-buyout-agreement-v2"
          >
            Download buyout agreement
          </Button>
        )}

        {transferAppendixUrl && (
          <Button
            variant="secondary"
            size="x-small"
            href={`/file?${appendixRouteParams}`}
          >
            Download appendix
          </Button>
        )}

        {isWithdrawable && (
          <WithdrawAgreementButton
            isWithdrawing={withdrawBuyoutAgreementMutation.isLoading}
            onWithdraw={handleWithdraw}
          />
        )}

        {canTerminateContract && (
          <TerminateAgreementButton
            isTerminating={terminateBuyoutAgreementMutation.isLoading}
            onTerminate={handleTerminate}
          />
        )}
      </div>
    </section>
  );
};
