export enum CropResidueManagementValue {
  Burned = "burned",
  Removed = "removed",
  Mulched = "mulched",
  DontKnow = "unknown",
}

// Values are coming from API
export enum CoverCrops {
  None = "none",
  Catch = "catch",
  Spring = "spring",
  Winter = "winter",
}

export enum TillingPracticeValue {
  NoTill = 0,
  ReducedTillage = 0.5,
  Conventional = 1,
  DontKnow = -1,
}

export enum FieldStepTabId {
  FieldDefinition = "fieldDefinition",
  FieldActuals = "fieldActuals",
}
