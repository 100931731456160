import {
  Column,
  RowData,
  SortDirection,
  SortingState,
} from "@tanstack/react-table";

import { IconConfig } from "~assets";

export function getGroupedState<TData extends RowData>(column: Column<TData>) {
  const columns = column.parent?.columns ?? [];
  const visibleColumns = columns.filter(groupedColumn =>
    groupedColumn.getIsVisible(),
  );

  const isGrouped = Boolean(visibleColumns.length);

  const isFirstInGroup = visibleColumns[0] === column;
  const isLastInGroup = visibleColumns[visibleColumns.length - 1] === column;

  const isGroupOpen = visibleColumns.slice(1).some(col => !col.getIsGrouped());

  return {
    columns,
    visibleColumns,
    isGrouped,
    isFirstInGroup,
    isLastInGroup,
    isGroupOpen,
  };
}

export function getSortIconProps(
  direction: SortDirection | false,
  options: {
    sortIndex: number;
    isMulti: boolean;
  },
): IconConfig<"icon", "iconVariant"> {
  let icon: IconConfig<"icon">["icon"] = "sort";
  if (direction === "asc") icon = "sort-up";
  if (direction === "desc") icon = "sort-down";

  return {
    icon,
    /**
     * For multisorting, we want to highlight the primary sorting column,
     * as otherwise it can be hard to see which order of importance we are sorting by.
     * Example: sorting by year, then sub sorting by most hectares
     */
    iconVariant:
      options.isMulti && options.sortIndex === 0 ? "solid" : "regular",
  };
}

/**
 * Our API doesn't support multisorting, so we need to parse the sorting state into a supported single format
 * @param sorting Tanstack Table sorting state
 * @returns Object with a single key of the column ID, and a value of "asc" or "desc"
 */
export const parseSorting = (sorting: SortingState) => {
  if (sorting.length === 0) return {};

  const [{ id, desc }] = sorting;

  return {
    [id]: desc ? "desc" : "asc",
  };
};
