import capitalize from "lodash/capitalize";

import I18n, { Locale } from "@ag/i18n";

import {
  Fertiliser,
  FertiliserTranslations,
} from "~queries/carbon/fertilisers";

import {
  EditFertiliserFormAttribute,
  EditFertiliserFormData,
  EditFertiliserOptionalFormAttribute,
} from "./types";

export const getInitialValues = (
  fertiliser: Fertiliser | undefined,
): EditFertiliserFormData => {
  if (!fertiliser) return {};

  const translations: Partial<FertiliserTranslations> = {};

  for (const locale of Object.values(Locale)) {
    const nameKey = `name${capitalize(locale)}` as keyof FertiliserTranslations;

    translations[nameKey] = fertiliser[nameKey];
  }

  return {
    nameEn: fertiliser.nameEn,
    descriptionEn: fertiliser.descriptionEn,
    id: fertiliser.id,
    identifier: fertiliser.identifier,
    chemicalSpecification: fertiliser.chemicalSpecification,
    coolfarmIdentifier: fertiliser.coolfarmIdentifier,
    coolfarmName: fertiliser.coolfarmName,
    organicCarbon: fertiliser.organicCarbon,
    nitrogenTotal: fertiliser.nitrogenTotal,
    nitrogenUrea: fertiliser.nitrogenUrea,
    nitrateNitrogen: fertiliser.nitrateNitrogen,
    ammonium: fertiliser.ammonium,
    phosphorus: fertiliser.phosphorus,
    phosphorusPentoxide: fertiliser.phosphorusPentoxide,
    potassium: fertiliser.potassium,
    potassiumOxide: fertiliser.potassiumOxide,
    organic: fertiliser.organic,
    nitrificationAdded: fertiliser.nitrificationAdded,
    harvestYear: fertiliser.harvestYear,
    methodologyVersion: fertiliser.methodologyVersion,
    ...translations,
  };
};

export const validate = (formData: EditFertiliserFormData) => {
  const errors: Partial<
    Record<EditFertiliserFormAttribute, string> &
      Record<EditFertiliserOptionalFormAttribute, string>
  > = {};

  if (!formData.nameEn) {
    errors.nameEn = I18n.t("js.carbon.fertilisers.name_required");
  }

  if (!formData.coolfarmIdentifier) {
    errors.coolfarmIdentifier = I18n.t(
      "js.carbon.fertilisers.coolfarm_identifier_required",
    );
  }

  if (!formData.coolfarmName) {
    errors.coolfarmName = I18n.t(
      "js.carbon.fertilisers.coolfarm_name_required",
    );
  }

  if (!formData.organicCarbon) {
    errors.organicCarbon = I18n.t(
      "js.carbon.fertilisers.organic_carbon_required",
    );
  }

  if (!formData.nitrogenTotal) {
    errors.nitrogenTotal = I18n.t(
      "js.carbon.fertilisers.nitrogen_total_required",
    );
  }

  if (formData.organic === undefined) {
    errors.organic = I18n.t("js.carbon.fertilisers.organic_required");
  }

  if (formData.nitrificationAdded === undefined) {
    errors.nitrificationAdded = I18n.t(
      "js.carbon.fertilisers.nitrification_added",
    );
  }

  return errors;
};
