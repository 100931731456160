import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateInventoryImportsQueryKey } from "./get-inventory-imports";

const discardInventoryImport = async (id: string) => {
  await axios.delete(`/api/v1/inventory/imports/${id}`, {
    apiSource: "node-markets",
  });
};

export const useDiscardInventoryImportMutation = () =>
  useMutation(discardInventoryImport, {
    onError: error => ToastNotification.error(error),
    onSuccess: () => {
      queryClient.invalidateQueries(generateInventoryImportsQueryKey());
    },
  });
