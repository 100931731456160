import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { DocumentationIsoVcuSchema } from "../entities/documentation-iso-vcu";

type Payload = {
  id: string;
  fileId: string;
};

const removeDocumentationIsoVcu = async ({ id, fileId }: Payload) => {
  await axios.delete<CommonResponse<Array<DocumentationIsoVcuSchema>>>(
    `/api/v1/verified-credit-units/${id}/documents/${fileId}`,
    {
      apiSource: "node-markets",
    },
  );
};

export const useRemoveDocumentationIsoVcuMutation = () =>
  useMutation(removeDocumentationIsoVcu, {
    onSuccess: () => ToastNotification.success("File removed"),
    onError: error => ToastNotification.error(error),
  });
