import { useParams } from "react-router-dom";

import { Spinner } from "@ag/design-system/assets";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import {
  ContactDetailsCard,
  ContractGenerationForm,
  useSalesOfferDetailsQuery,
} from "~features/sales-offers";
import OverviewLayout from "~layouts/overview-layout";

const SalesOfferDetails = () => {
  const params = useParams<{ salesOfferId: string }>();

  if (!params.salesOfferId) {
    throw new Error("Missing sales offer ID");
  }

  const { data, isLoading } = useSalesOfferDetailsQuery(params.salesOfferId);

  return (
    <OverviewLayout.Root>
      <OverviewLayout.Sidebar>
        <AuthorizedSidebar />
      </OverviewLayout.Sidebar>

      <OverviewLayout.TopBar>
        <BackButton />

        <OverviewLayout.TopBarTitle>Offer details</OverviewLayout.TopBarTitle>
      </OverviewLayout.TopBar>

      <OverviewLayout.Content>
        {!data || isLoading ? (
          <div className="flex h-full items-center justify-center">
            <Spinner className="h-20" />
          </div>
        ) : (
          <>
            <div className="mb-6">
              <h3 className="mb-4 flex gap-4 text-h3">
                <span className="text-grey-700">Offer</span>

                <span>{params.salesOfferId}</span>
              </h3>

              <div>
                <ContactDetailsCard {...data} />
              </div>
            </div>

            <ContractGenerationForm {...data} />
          </>
        )}
      </OverviewLayout.Content>
    </OverviewLayout.Root>
  );
};

export default SalesOfferDetails;
