import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { queryClient } from "~config/react-query";
import { postRequest, putRequest } from "~lib/axios";

import { fieldKeys } from "./queries";
import {
  UnlockFieldDefinitionArgs,
  UpdateFieldArgs,
  UpdateFieldDefinitionArgs,
} from "./types";

/**
 * CUSTOM MUTATION FUNCTIONS
 */
const unlockFieldDefinition = async ({
  fieldId,
  fieldDefinitionId,
}: UnlockFieldDefinitionArgs): Promise<number> => {
  await postRequest({
    path: `/api/farmer/carbon/v1/admin/carbon_fields/${fieldId}/carbon_field_definitions/${fieldDefinitionId}/unlock`,
    body: {},
  });

  return fieldId;
};

const updateFieldDefinition = async ({
  fieldId,
  fieldDefinitionId,
  fieldDefinitionData,
}: UpdateFieldDefinitionArgs): Promise<number> => {
  await putRequest({
    path: `/api/farmer/carbon/v1/admin/carbon_fields/carbon_quality_assurances/carbon_fields/${fieldId}/carbon_field_definitions/${fieldDefinitionId}`,
    body: {
      carbonFieldDefinition: fieldDefinitionData,
    },
  });

  return fieldId;
};

const updateField = async ({
  fieldId,
  fieldData,
}: UpdateFieldArgs): Promise<number> => {
  await putRequest({
    path: `/api/farmer/carbon/v1/admin/carbon_fields/carbon_quality_assurances/carbon_fields/${fieldId}`,
    body: {
      carbonField: fieldData,
    },
  });

  return fieldId;
};

const deleteFields = async (fieldIds: string[]) => {
  type BulkDeleteResponse = {
    id: string;
    errors: string;
  }[];

  const response = await axios.post<CommonResponse<BulkDeleteResponse>>(
    "/api/v1/carbon-fields/remove/bulk",
    {
      fieldIds,
    },
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

/**
 * MUTATION HOOKS
 */
export const useUnlockFieldDefinitionMutation = () =>
  useMutation(unlockFieldDefinition, {
    onSuccess: async (fieldId: number) => {
      queryClient.invalidateQueries(fieldKeys.details(fieldId));
    },
  });

export const useUpdateFieldDefinitionMutation = () =>
  useMutation(updateFieldDefinition, {
    onSuccess: async (fieldId: number) => {
      queryClient.invalidateQueries(fieldKeys.details(fieldId));
    },
    onError: error => ToastNotification.error(error),
  });

export const useUpdateFieldMutation = () =>
  useMutation(updateField, {
    onSuccess: async (fieldId: number) => {
      queryClient.invalidateQueries(fieldKeys.details(fieldId));
    },
    onError: error => ToastNotification.error(error),
  });

export const useDeleteFieldsMutation = () =>
  useMutation(deleteFields, {
    onSuccess: async () => {
      queryClient.invalidateQueries(fieldKeys.all);
    },
    onError: error => ToastNotification.error(error),
  });
