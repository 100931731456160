import { useParams } from "react-router-dom";

import I18n from "@ag/i18n";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import DetailsLayout from "~layouts/details-layout";
import { useFertiliserQuery } from "~queries/carbon/fertilisers";

import Form from "./Form";
import { getInitialValues } from "./helpers";

const EditFertiliser = () => {
  const { id: fertiliserIdParam } = useParams();

  const fertiliserId = Number(fertiliserIdParam);

  const { data: fertiliser } = useFertiliserQuery(fertiliserId);

  const initialValues = getInitialValues(fertiliser);

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>
          {I18n.t("js.carbon.fertilisers.edit.title")}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        {fertiliser && <Form initialValues={initialValues} />}
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default EditFertiliser;
