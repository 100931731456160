import { createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";

import { Button, IconButton } from "@ag/design-system/atoms";
import { TableRowError } from "@ag/design-system/organisms";
import { formatNumber, generateUniqueId } from "@ag/utils/helpers";

import { useTable } from "~components/table";

import { CertificatesValidation } from "../entities/certificates-validation";

type TableData = {
  displayIndex: number;
  id: string;
  rangeStart: string;
  rangeEnd: string;
  certificateCount: string;
  index: number;
};

type Handlers = {
  handleDeleteAll: () => void;
  handleDeleteRange: (index: number) => void;
};

type RangeErrors = Record<string, TableRowError<TableData>[]>;

/**
 * Map the certificate ranges to add a unique ID for every certificate query.
 * We need a unique IDs in order to not have buggy table behavior when adding/deleting rows,
 * and to map errors to specific rows.
 */
function useMapTableData(
  data: CertificatesValidation["certificateIds"],
): [TableData[], RangeErrors] {
  return useMemo(() => {
    if (!data) return [[], {}];

    const uniqueIdIndex = data.map(() => generateUniqueId());

    const mappedData = data.map((certificateValidation, index) => {
      const [rangeStart, rangeEnd] = certificateValidation.query.split("-");
      const certificateCount = certificateValidation.valid
        ? formatNumber(Number(rangeEnd) - Number(rangeStart) + 1) ?? ""
        : "error";

      return {
        displayIndex: index + 1,
        id: uniqueIdIndex[index],
        index,
        rangeStart,
        rangeEnd,
        certificateCount,
      };
    });

    const errors = data.reduce(
      (acc, row, index) => {
        if (!row.valid) {
          const id = uniqueIdIndex[index];
          acc[id] = [new TableRowError(row.reason, "certificateCount")];
        }

        return acc;
      },
      {} as Record<string, TableRowError<TableData>[]>,
    );

    return [mappedData, errors];
  }, [data]);
}

export const useCertificatesValidationTable = (
  data: CertificatesValidation["certificateIds"] | undefined,
  handlers: Handlers,
) => {
  const [mappedData, errors] = useMapTableData(data ?? []);

  const columns = useMemo(() => getColumns(handlers), [handlers]);

  return useTable<TableData>({
    columns,
    data: mappedData,
    getRowId: row => row.id,
    meta: {
      errors,
    },
  });
};

function getColumns({ handleDeleteAll, handleDeleteRange }: Handlers) {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.accessor("displayIndex", {
      header: "Row number",
    }),
    columnHelper.accessor("rangeStart", {
      header: "Beginning of range",
    }),
    columnHelper.accessor("rangeEnd", {
      header: "End of range",
    }),
    columnHelper.accessor("certificateCount", {
      header: "Certificate count",
    }),
    columnHelper.accessor("index", {
      cell: ({ getValue }) => (
        <div className="flex items-center justify-center">
          <IconButton
            variant="text"
            iconVariant="regular"
            icon="trash-can"
            isDanger
            theme="light"
            onClick={() => handleDeleteRange(getValue())}
          />
        </div>
      ),
      header: () => (
        <div className="flex w-full justify-center">
          <Button variant="text" isDanger onClick={handleDeleteAll}>
            Delete all
          </Button>
        </div>
      ),
    }),
  ];
}
