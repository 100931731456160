
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/tokens/colors.css.ts", "@ag/design-system");
      import { createGlobalTheme, createGlobalThemeContract } from "@vanilla-extract/css";
import { colorsObject } from "./colors";
const colorPaletteRange = {
  100: null,
  200: null,
  300: null,
  400: null,
  500: null,
  600: null,
  700: null,
  800: null,
  900: null
};
const messagingColorPaletteRange = {
  100: null,
  300: null,
  500: null,
  700: null,
  900: null
};
const colorsThemeContract = createGlobalThemeContract({
  // Legacy
  green: colorPaletteRange,
  opal: colorPaletteRange,
  tan: colorPaletteRange,
  blue: colorPaletteRange,
  orange: colorPaletteRange,
  yellow: colorPaletteRange,
  red: colorPaletteRange,
  white: {
    100: null
  },
  black: {
    900: null
  },
  // End of legacy

  satellite: {
    100: null,
    200: null,
    300: null
  },
  sky: {
    100: null,
    200: null,
    300: null
  },
  accent: {
    100: null,
    200: null,
    300: null
  },
  grey: {
    100: null,
    200: null,
    300: null,
    400: null,
    500: null,
    600: null,
    700: null,
    800: null,
    900: null,
    white: null,
    black: null
  },
  data: {
    purple: colorPaletteRange,
    orange: colorPaletteRange,
    green: colorPaletteRange,
    pink: colorPaletteRange,
    blue: colorPaletteRange
  },
  messaging: {
    error: messagingColorPaletteRange,
    warning: messagingColorPaletteRange,
    info: messagingColorPaletteRange,
    success: messagingColorPaletteRange
  }
}, (_, path) => `color-${path.join("-")}`);
createGlobalTheme(":root", colorsThemeContract, colorsObject);
export default colorsThemeContract;
      endFileScope();
      
    