import { z } from "zod";

import { validateCertificateIds } from "../helpers/validate-certificate-ids";
import {
  CertificateGroupStandard,
  CertificateGroupStatus,
  CertificateGroupType,
} from "../types/certificate-group";

export const CertificateGroupsFiltersSchema = z
  .object({
    certificateId: z.string().optional().refine(validateCertificateIds, {
      message:
        "Please provide the ID in a correct format (single value or a range)",
    }),
    type: z.nativeEnum(CertificateGroupType).or(z.literal("")),
    status: z.nativeEnum(CertificateGroupStatus).or(z.literal("")),
    standard: z.nativeEnum(CertificateGroupStandard).or(z.literal("")),
    country: z.string(),
    vintageYear: z
      .string()
      .refine(value => value === "" || /^\d{4}$/.test(value), {
        message: "Please provide a correct vintage year",
      }),
  })
  .partial();

export type CertificateGroupsFilters = z.infer<
  typeof CertificateGroupsFiltersSchema
>;
