/**
 * Based on Josh W. Comeau's blog post:
 * https://www.joshwcomeau.com/snippets/react-hooks/use-timeout/
 */
import { useEffect, useRef } from "react";

/**
 * A handy hook for a React-safe setTimeout, with dynamic delay and cancel!
 * @param callback The function to execute.
 * @param delay The amount of time in ms to wait until executing callback, can be null to cancel.
 */
export function useTimeout(callback: () => void, delay: number | null) {
  const timeoutRef = useRef<number | null>(null);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay !== "number") return;

    const tick = () => savedCallback.current();

    timeoutRef.current = window.setTimeout(tick, delay);

    return () => {
      if (timeoutRef.current) window.clearTimeout(timeoutRef.current);
    };
  }, [delay]);

  return timeoutRef;
}
