import { formatDuration } from "date-fns";
import { useEffect, useState } from "react";
import { useIntervalWhen } from "rooks";

import ImgAgreenaLogo from "~assets/images/logo/agreena.svg";

import ImgRocket from "../assets/icon-pest.svg";

const ChunkLoadError = () => {
  const [secondsToRefresh, setSecondsToRefresh] = useState(10);

  useEffect(() => {
    if (secondsToRefresh === 1) {
      window.location.reload();
    }
  }, [secondsToRefresh]);

  // Show error page for 10 seconds and reload the page
  useIntervalWhen(
    () => {
      setSecondsToRefresh(prevState => prevState - 1);
    },
    1000, // run every second
    secondsToRefresh > 1, // run only if the number of seconds is greater than 1
  );

  return (
    <section className="mx-auto flex h-screen w-96 flex-col items-center justify-center">
      <img className="w-[120px]" src={ImgAgreenaLogo} />

      <img className="w-22 my-8 inline-block" src={ImgRocket} />

      <h2 className="mb-4 text-h2">We are getting better!</h2>

      <p className="mb-4 text-center">
        Agreena platform has been updated. We have to refresh the page for you
        to continue. It will happen automatically in
        <span className="ml-1 text-p1">
          {formatDuration({
            seconds: secondsToRefresh,
          })}
          ...
        </span>
      </p>

      <p className="mb-4 text-p1">Thank you for your patience!</p>
    </section>
  );
};

export default ChunkLoadError;
