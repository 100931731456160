import React from "react";

import AgreenaCarbonLogoSatellite from "./assets/agreena-carbon-logo-satellite.svg";
import AgreenaCarbonLogoWhite from "./assets/agreena-carbon-logo-white.svg";
import AgreenaLogoSatellite from "./assets/agreena-logo-satellite.svg";
import AgreenaLogoWhite from "./assets/agreena-logo-white.svg";

const logos = {
  carbon: {
    satellite: AgreenaCarbonLogoSatellite,
    white: AgreenaCarbonLogoWhite,
  },
  default: {
    satellite: AgreenaLogoSatellite,
    white: AgreenaLogoWhite,
  },
};

type Props = {
  variant?: "carbon" | "default";
  color?: "satellite" | "white";
} & React.HTMLAttributes<SVGElement>;

export const Logo = ({
  variant = "default",
  color = "satellite",
  ...rest
}: Props) => {
  const LogoComponent = logos[variant][color];

  return <LogoComponent {...rest} />;
};
