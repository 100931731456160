import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import {
  InputField,
  PhoneNumberInputField,
  formatParsePhoneNumber,
  getPhoneNumberPrefix,
} from "@ag/form-fields";
import { VALIDATOR } from "@ag/utils/helpers";

import { SignerDetails } from "../entities/sales-offer-details";

type SignerDetailsItemProps = {
  label: string;
  value?: string;
};

const SignerDetailsItem = ({ label, value }: SignerDetailsItemProps) => (
  <div className="grid-row-[auto,1fr] grid gap-1">
    <span className="text-p2 text-grey-800">{label}</span>
    <span className="rounded text-p2 text-grey-900">
      {value?.length ? value : "-"}
    </span>
  </div>
);

type Props = SignerDetails & {
  editingDisabled: boolean;
  onSubmit: (values: Omit<SignerDetailsValues, "phoneCountryCode">) => void;
};

const signerDetailsSchema = z.object({
  title: z.string().min(1, "Must include a title name"),
  name: z.string().min(1, "Must include a name"),
  email: z.string().refine(value => value.match(VALIDATOR.EMAIL), {
    message: "Invalid email address",
  }),
  phoneNumber: z.string().refine(value => value.match(VALIDATOR.PHONE), {
    message: "Invalid phone number",
  }),
  phoneCountryCode: z.string().nonempty(),
});

type SignerDetailsValues = z.infer<typeof signerDetailsSchema>;

const SignerDetailsForm = ({
  title,
  name,
  email,
  phoneNumber,
  editingDisabled,
  onSubmit,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const parsedNumber = formatParsePhoneNumber(phoneNumber);

  const { handleSubmit, control, register, formState } =
    useForm<SignerDetailsValues>({
      resolver: zodResolver(signerDetailsSchema),
      defaultValues: {
        title,
        email,
        name,
        phoneNumber: parsedNumber?.nationalNumber,
        phoneCountryCode: parsedNumber?.country,
      },
    });

  const handleUpdateSignerDetails = ({
    phoneCountryCode,
    phoneNumber,
    ...values
  }: SignerDetailsValues) => {
    const phonePrefix = getPhoneNumberPrefix(phoneCountryCode);

    try {
      onSubmit({ ...values, phoneNumber: `+${phonePrefix}${phoneNumber}` });
      setShowModal(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="mt-4 grid gap-4 border-t border-grey-300 pt-4 md:grid-cols-[1fr,1fr,1fr,1fr,0fr]">
        <SignerDetailsItem label="Job title" value={title} />

        <SignerDetailsItem label="Name" value={name} />

        <SignerDetailsItem label="Email" value={email} />

        <SignerDetailsItem label="Phone number" value={phoneNumber} />

        <Button
          className="ml-2"
          icon="pen-to-square"
          variant="text"
          size="x-small"
          disabled={editingDisabled}
          onClick={() => setShowModal(true)}
          aria-role="button"
          aria-label="Open edit signer details modal"
        >
          Edit
        </Button>
      </div>

      <Modal.Root
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        className="flex gap-6 p-10"
      >
        <Modal.Header align="center">
          <Modal.Title>Update Signer details</Modal.Title>

          <Modal.SubTitle>
            Add the correct signer details that will be added into the contract.
          </Modal.SubTitle>
        </Modal.Header>

        <Modal.Content>
          <form
            className="flex flex-col gap-6"
            role="form"
            aria-label="Signer details form"
          >
            <InputField
              {...register("title")}
              label="Signer Job title"
              error={formState.errors.title}
              role="input"
              aria-label="Signer Job title"
            />
            <InputField
              {...register("name")}
              label="Signer name"
              error={formState.errors.name}
            />

            <InputField
              {...register("email")}
              label="Signer email"
              error={formState.errors.email}
            />

            <PhoneNumberInputField
              control={control}
              {...register("phoneNumber")}
              label="Signer phone number"
              optionsClassName="z-modal"
            />
          </form>
        </Modal.Content>

        <Modal.Footer
          render={onClose => (
            <div className="flex gap-6">
              <Button variant="text" size="x-small" onClick={onClose}>
                Close
              </Button>
              <Button
                disabled={!formState.isDirty}
                size="x-small"
                onClick={handleSubmit(handleUpdateSignerDetails)}
                aria-role="button"
                aria-label="Save & Update"
              >
                Save & Update
              </Button>
            </div>
          )}
        />
      </Modal.Root>
    </>
  );
};

export default SignerDetailsForm;
