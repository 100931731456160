import { RowData } from "@tanstack/react-table";

import { TableState } from "@ag/design-system/organisms";
import { useLocalStorage } from "@ag/utils/hooks";

export const useStoredTableSettings = <TData extends RowData>(
  tableKey: string,
  initialState?: TableState<TData>,
) => {
  const [tableState, setTableState] = useLocalStorage(
    `table-settings--${tableKey}`,
    () => (initialState ?? {}) as TableState<TData>,
  );

  return [tableState, setTableState];
};
