import { EmptyState } from "@ag/design-system/organisms";

const EmptyInventoryList = () => {
  return (
    <EmptyState.Root className="">
      <EmptyState.Image />

      <EmptyState.Title>No imports found</EmptyState.Title>

      <EmptyState.Description>
        Import inventory file to populate the list.
      </EmptyState.Description>
    </EmptyState.Root>
  );
};

export default EmptyInventoryList;
