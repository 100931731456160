import { z } from "zod";

import { CropTypeSchema } from "~entities";
import { CoverCrops } from "~types";

import { CultivationStrategyAttribute } from "./types";

export const CultivationStrategyV2021DataSchema = z.object({
  [CultivationStrategyAttribute.CropLastHarvestYear]: z.number(),
  [CultivationStrategyAttribute.CarbonCropTypeId]: z.string(),
  [CultivationStrategyAttribute.CarbonCropType]: CropTypeSchema,
  [CultivationStrategyAttribute.CropTypeOtherName]: z.string().nullish(),
  [CultivationStrategyAttribute.CropGrossYield]: z.number(),
  [CultivationStrategyAttribute.CoverCropsAdoption]: z
    .nativeEnum(CoverCrops)
    .nullable(),
  [CultivationStrategyAttribute.CropResidueManagement]: z.string(),
  [CultivationStrategyAttribute.CropSeedTreatment]: z.boolean(),
  [CultivationStrategyAttribute.TillingRate]: z.number(),
});
