import { z } from "zod";

import { COUNTRY_CODE } from "~constants/misc";
import { UserRole } from "~types";

export const LookupCompanyDataSchema = z.object({
  vatNumber: z.string(),
  countryCode: z.nativeEnum(COUNTRY_CODE),
});

export type LookupCompanyData = z.infer<typeof LookupCompanyDataSchema>;

export const CreateCompanyDataSchema = z.object({
  name: z.string(),
  vatNumber: z.string(),
  roles: z.array(z.nativeEnum(UserRole)),
  registrationNumber: z.string().optional(),
  address: z.object({
    address: z.string(),
    city: z.string(),
    countryCode: z.nativeEnum(COUNTRY_CODE),
    zipCode: z.string(),
  }),
});

export type CreateCompanyData = z.infer<typeof CreateCompanyDataSchema>;

export const CompanySchema = z.object({
  id: z.string(),
  name: z.string(),
  vatNumber: z.string(),
  vatValidatedAt: z.string(),
  userTypeIdentifier: z.string(),
  address: z.object({
    id: z.string(),
    address: z.string(),
    checksum: z.string(),
    city: z.string(),
    name: z.string(),
    countryCode: z.string(),
    position: z.object({ lng: z.number(), lat: z.number() }),
    zipCode: z.string(),
  }),
});

export const CompanyLookupSchema = z.object({
  address: z.string(),
  city: z.string(),
  name: z.string(),
  vatNumber: z.string(),
  zipCode: z.string(),
  valid: z.boolean(),
});

export type Company = z.infer<typeof CompanySchema>;

export type CompanyLookup = z.infer<typeof CompanyLookupSchema>;
