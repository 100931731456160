export const getStepProps = (
  stepProps: React.HTMLAttributes<HTMLElement>,
  progressProps: React.HTMLAttributes<HTMLElement>,
) => {
  const {
    "aria-posinset": stepvalue,
    "aria-selected": isStepSelected,
    onClick,
  } = stepProps;

  const { "aria-valuenow": currentlySelectedStep } = progressProps;
  const isStepCompleted = Boolean(
    currentlySelectedStep !== undefined &&
      stepvalue !== undefined &&
      stepvalue <= currentlySelectedStep + 1,
  ); // +1 because progress is count from 0 and trigger value is count from 1

  return {
    isStepSelected: Boolean(isStepSelected), // casting is needed as library returns `Booleanish | undefined` type
    isStepCompleted,
    onClick,
  };
};
