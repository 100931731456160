import getFormattedNumber from "format-number";
import isNil from "lodash/isNil.js";

import I18n from "@ag/i18n";

export type Options = {
  negativeType: "right" | "left" | "brackets" | "none";
  negativeLeftSymbol: string;
  negativeRightSymbol: string;
  negativeLeftOut: boolean;
  negativeRightOut: boolean;
  prefix: string;
  suffix: string;
  integerSeparator: string;
  decimalsSeparator: string;
  decimal: string;
  padLeft: number;
  padRight: number;
  round: number;
  separator: boolean;
};

/**
 * By default, adds thousands separators to a given number
 */
export const formatNumber = (
  number: number | null | undefined,
  options?: Partial<Options>,
): string | undefined => {
  if (isNil(number)) {
    return;
  }

  const formatOptions = {
    integerSeparator:
      options?.separator === false
        ? ""
        : I18n.t("js.shared.format.number.integer_separator"),
    ...options,
  };

  return getFormattedNumber(formatOptions)(number);
};
