import omit from "lodash/omit";

import { Icon } from "~assets";
import {
  AssistiveElement,
  AssistiveElementProps,
  LabelProps,
  Label as PrimitiveLabel,
  Tooltip,
} from "~atoms";
import { cn } from "~utils";

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
type RootProps = React.PropsWithChildrenRequired<{
  isFullSize?: boolean;
  isDisabled?: boolean;
  type?: Extract<React.HTMLInputTypeAttribute, "hidden">;
}>;

export function Root({ children, type, isFullSize }: RootProps) {
  const isHidden = type === "hidden";

  return (
    <div
      className={cn(
        "form-field-root flex flex-col gap-2",
        {
          "h-full w-full": isFullSize,
        },
        isHidden && "hidden",
      )}
    >
      {children}
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Label
 * -----------------------------------------------------------------------------------------------*/
export function Label(props: LabelProps) {
  return (
    <PrimitiveLabel {...omit(props, "children")}>
      {props.children}
    </PrimitiveLabel>
  );
}

/* -------------------------------------------------------------------------------------------------
 * InlineLabel
 * -----------------------------------------------------------------------------------------------*/
type InlineLabelProps = React.PropsWithChildrenRequired<{
  variant?: "description";
  className?: string;
  tooltip?: string;
}>;

export function InlineLabel({
  variant,
  tooltip,
  className,
  children,
  ...rest
}: InlineLabelProps) {
  return (
    <>
      <label
        className={cn(
          "mb-0 inline-flex cursor-pointer items-center gap-2 text-p2 text-grey-700",
          variant === "description" && "flex-wrap text-p2",
          className,
        )}
        {...rest}
      >
        {children}
        {tooltip && (
          <>
            <Tooltip content={tooltip}>
              <Icon name="circle-info" className="p-[5px]" />
            </Tooltip>
          </>
        )}
      </label>
    </>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Error
 * -----------------------------------------------------------------------------------------------*/
export function Error(props: Omit<AssistiveElementProps, "variant">) {
  return (
    <AssistiveElement
      variant="danger"
      className={cn("basis-full", props.className)}
      {...omit(props, "variant", "children", "className")}
    >
      {props.children}
    </AssistiveElement>
  );
}

/* -------------------------------------------------------------------------------------------------
 * Description
 * -----------------------------------------------------------------------------------------------*/
export function Description(props: Omit<AssistiveElementProps, "variant">) {
  return (
    <AssistiveElement
      variant="info"
      className={cn("basis-full", props.className)}
      {...omit(props, "variant", "children", "className")}
    >
      {props.children}
    </AssistiveElement>
  );
}
