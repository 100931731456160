import { FertiliserTranslations } from "~queries/carbon/fertilisers";
import { MethodologyVersion } from "~queries/carbon/types";

export enum EditFertiliserFormAttribute {
  Id = "id",
  NameEn = "nameEn",
  Identifier = "identifier",
  ChemicalSpecification = "chemicalSpecification",
  HarvestYear = "harvestYear",
  MethodologyVersion = "methodologyVersion",
  CoolfarmIdentifier = "coolfarmIdentifier",
  CoolfarmName = "coolfarmName",
  Organic = "organic",
  NitrificationAdded = "nitrificationAdded",
}

export enum EditFertiliserOptionalFormAttribute {
  OrganicCarbon = "organicCarbon",
  NitrogenTotal = "nitrogenTotal",
  NitrogenUrea = "nitrogenUrea",
  NitrateNitrogen = "nitrateNitrogen",
  Ammonium = "ammonium",
  Phosphorus = "phosphorus",
  PhosphorusPentoxide = "phosphorusPentoxide",
  Potassium = "potassium",
  PotassiumOxide = "potassiumOxide",
  DescriptionEn = "descriptionEn",
}

export type EditFertiliserFormData = Partial<
  {
    [EditFertiliserFormAttribute.NameEn]: string;
    [EditFertiliserFormAttribute.Id]: number;
    [EditFertiliserFormAttribute.Identifier]: string;
    [EditFertiliserFormAttribute.ChemicalSpecification]: boolean;
    [EditFertiliserFormAttribute.HarvestYear]: number;
    [EditFertiliserFormAttribute.MethodologyVersion]: MethodologyVersion;
    [EditFertiliserFormAttribute.CoolfarmIdentifier]: number;
    [EditFertiliserFormAttribute.CoolfarmName]: string;
    [EditFertiliserFormAttribute.Organic]: boolean;
    [EditFertiliserFormAttribute.NitrificationAdded]: boolean;
    [EditFertiliserOptionalFormAttribute.OrganicCarbon]: number;
    [EditFertiliserOptionalFormAttribute.NitrogenTotal]: number;
    [EditFertiliserOptionalFormAttribute.NitrogenUrea]: number | undefined;
    [EditFertiliserOptionalFormAttribute.NitrateNitrogen]: number;
    [EditFertiliserOptionalFormAttribute.Ammonium]: number;
    [EditFertiliserOptionalFormAttribute.Phosphorus]: number;
    [EditFertiliserOptionalFormAttribute.PhosphorusPentoxide]: number;
    [EditFertiliserOptionalFormAttribute.Potassium]: number;
    [EditFertiliserOptionalFormAttribute.PotassiumOxide]: number;
    [EditFertiliserOptionalFormAttribute.DescriptionEn]: string;
  } & FertiliserTranslations
>;
