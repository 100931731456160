import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { generateClientsQueryKey } from "./get-clients";

const deleteClientDocument = (clientId: string, docId: string) =>
  axios.delete(`/api/v1/clients/${clientId}/documents/${docId}`, {
    apiSource: "node-markets",
  });

export const useDeleteClientDocumentMutation = () =>
  useMutation(
    ({ clientId, docId }: { clientId: string; docId: string }) =>
      deleteClientDocument(clientId, docId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(generateClientsQueryKey());
      },
      onError: error => ToastNotification.error(error),
    },
  );
