import isNil from "lodash/isNil";

import I18n from "@ag/i18n";

import { FertiliserEmissionsInhibitors, FertiliserMode } from "~types";

import {
  FertiliserAttribute,
  FertiliserDataAttribute,
  FertiliserV2022Data,
  FertiliserViewAttribute,
} from "./types";

type ValidationErrors = {
  [FertiliserViewAttribute.IsFertilisersUsed]?: string;
  [FertiliserAttribute.Fertilisers]: Partial<
    Record<FertiliserDataAttribute, string>
  >[];
};

export const validate = (values: FertiliserV2022Data): ValidationErrors => {
  const errors: ValidationErrors = {
    fertilisers: [],
  };

  const { isFertilisersUsed, fertilisers } = values;

  // Are any fertilisers used
  if (isFertilisersUsed === null) {
    errors.isFertilisersUsed = I18n.t("js.shared.required");
  }

  if (isFertilisersUsed === false) return errors;

  /**
   * Validate each fertiliser
   */
  fertilisers.forEach((fertiliser, index) => {
    // Type of fertiliser
    if (isNil(fertiliser.mode)) {
      errors.fertilisers[index] = {
        ...errors.fertilisers[index],
        mode: I18n.t("js.carbon.field.fertilisers.errors.choose_type"),
      };
    }

    /**
     * Validation for the organic type inputs
     */
    if (fertiliser.mode === FertiliserMode.Organic) {
      // Fertiliser
      if (!fertiliser.carbonFertiliserId) {
        errors.fertilisers[index] = {
          ...errors.fertilisers[index],
          carbonFertiliserId: I18n.t(
            "js.carbon.field.fertilisers.errors.choose_fertiliser",
          ),
        };
      }

      // Application rate
      if (!fertiliser.applicationRate) {
        errors.fertilisers[index] = {
          ...errors.fertilisers[index],
          applicationRate: I18n.t(
            "js.carbon.field.fertilisers.errors.enter_application_rate",
          ),
        };
      }
    }

    /**
     * Validation for the synthetic type inputs
     */
    if (fertiliser.mode === FertiliserMode.Synthetic) {
      // Nitrogen application rate
      if (
        !fertiliser.nitrogenApplicationRate &&
        !fertiliser.phosphorusApplicationRate &&
        !fertiliser.potassiumApplicationRate
      ) {
        errors.fertilisers[index] = {
          ...errors.fertilisers[index],
          nitrogenApplicationRate: I18n.t(
            "js.carbon.field.fertilisers.errors.at_least_one_application_rate_is_required",
          ),
          phosphorusApplicationRate: I18n.t(
            "js.carbon.field.fertilisers.errors.at_least_one_application_rate_is_required",
          ),
          potassiumApplicationRate: I18n.t(
            "js.carbon.field.fertilisers.errors.at_least_one_application_rate_is_required",
          ),
        };
      }

      if (fertiliser.phosphorusApplicationRate && !fertiliser.phosphorusType) {
        errors.fertilisers[index] = {
          ...errors.fertilisers[index],
          phosphorusType: I18n.t(
            "js.carbon.field.fertilisers.errors.choose_phosphorus_type",
          ),
        };
      }

      if (fertiliser.potassiumApplicationRate && !fertiliser.potassiumType) {
        errors.fertilisers[index] = {
          ...errors.fertilisers[index],
          potassiumType: I18n.t(
            "js.carbon.field.fertilisers.errors.choose_potassium_type",
          ),
        };
      }
    }

    // Nitrification inhibitors
    if (
      !isNil(fertiliser.mode) &&
      fertiliser.nitrogenApplicationRate &&
      !fertiliser.emissionsInhibitors
    ) {
      errors.fertilisers[index] = {
        ...errors.fertilisers[index],
        emissionsInhibitors: I18n.t(
          "js.carbon.field.fertilisers.errors.choose_option",
        ),
      };
    }

    if (
      fertiliser.emissionsInhibitors ===
      FertiliserEmissionsInhibitors.Nitrification
    ) {
      /**
       * Validation for nitrification inhibitors
       */
      // Nitrification application rate
      if (!fertiliser.nitrificationApplicationRate) {
        errors.fertilisers[index] = {
          ...errors.fertilisers[index],
          nitrificationApplicationRate: I18n.t(
            "js.carbon.field.fertilisers.errors.enter_nitrification_application_rate",
          ),
        };
      }

      // Check if fertiliser application rate is not exceeded by nitrification - organic
      if (
        fertiliser.mode === FertiliserMode.Organic &&
        fertiliser.nitrificationApplicationRate &&
        fertiliser.applicationRate &&
        fertiliser.nitrificationApplicationRate > fertiliser.applicationRate
      ) {
        errors.fertilisers[index] = {
          ...errors.fertilisers[index],
          nitrificationApplicationRate: I18n.t(
            "js.carbon.field.fertilisers.errors.cannot_exceed_fertiliser_product",
          ),
        };
      }

      // Check if fertiliser application rate is not exceeded by nitrification - synthetic
      if (
        fertiliser.mode === FertiliserMode.Synthetic &&
        fertiliser.nitrificationApplicationRate &&
        fertiliser.nitrogenApplicationRate &&
        fertiliser.nitrificationApplicationRate >
          fertiliser.nitrogenApplicationRate
      ) {
        errors.fertilisers[index] = {
          ...errors.fertilisers[index],
          nitrificationApplicationRate: I18n.t(
            "js.carbon.field.fertilisers.errors.cannot_exceed_nitrogen_fertiliser",
          ),
        };
      }
    }

    if (
      fertiliser.mode === FertiliserMode.Organic &&
      fertiliser.emissionsInhibitors === null
    ) {
      errors.fertilisers[index] = {
        ...errors.fertilisers[index],
        emissionsInhibitors: I18n.t(
          "js.carbon.field.fertilisers.errors.emissions_inhibitors",
        ),
      };
    }
  });

  return errors;
};

export const getFertiliserTypeOptions = () => [
  {
    label: I18n.t("js.carbon.field.fertilisers.organic"),
    value: FertiliserMode.Organic,
  },
  {
    label: I18n.t("js.carbon.field.fertilisers.synthetic"),
    value: FertiliserMode.Synthetic,
  },
];

export const getEmissionsInhibitorsOptions = () => [
  {
    label: I18n.t("js.shared.yes"),
    value: FertiliserEmissionsInhibitors.Nitrification,
  },
  {
    label: I18n.t("js.shared.no"),
    value: FertiliserEmissionsInhibitors.None,
  },
];
