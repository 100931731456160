import { zodResolver } from "@hookform/resolvers/zod";
import { SortingState } from "@tanstack/react-table";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { parseSorting } from "@ag/design-system/organisms";
import { InputField } from "@ag/form-fields";
import { usePagination } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import {
  Filters,
  transformInitialFilterValues,
  useSearchParamForm,
} from "~components/filters";
import Table from "~components/table";
import {
  AddClientModal,
  DeleteClientConfirmation,
  useClientsQuery,
  useDeleteClientMutation,
} from "~features/clients";
import { UpdateClientModal } from "~features/clients/components/update-client-modal";
import { useClientsTable } from "~features/clients/hooks/use-clients-table";
import { AuthorizedSidebar } from "~features/navigation";
import {
  AuthResourceClass,
  MarketsResourceClass,
  useAuthPermissions,
  useMarketsPermissions,
} from "~features/permission";
import { transformedLabelValue } from "~helpers";
import ListLayout from "~layouts/list-layout";

const ClientsFiltersSchema = z.object({
  name: z.string().optional(),
});
type ClientsFilters = z.infer<typeof ClientsFiltersSchema>;

const Clients = () => {
  const [pagination, updatePagination, resetPagination] = usePagination();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [query, setQuery] = useQueryParams({
    name: withDefault(StringParam, undefined),
  });

  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);

  const [clientToDelete, setClientToDelete] = useState<{
    id: string | null;
    name: string | null;
  }>({
    id: null,
    name: null,
  });

  const [clientToUpdate, setClientToUpdate] = useState<string | null>(null);

  const {
    handleClearFiltersBar,
    handleClearFiltersDrawer,
    handleSubmitFilters,
  } = useSearchParamForm<ClientsFilters>(query, setQuery, resetPagination, () =>
    setIsFiltersOpen(false),
  );

  const { data: authPermissions } = useAuthPermissions();
  const { data: marketsPermissions } = useMarketsPermissions();

  const hasAdminAccess = authPermissions?.read?.includes(
    AuthResourceClass.Admin,
  );

  const hasDeletePermission = marketsPermissions?.delete?.includes(
    MarketsResourceClass.Client,
  );

  const hasUpdatePermission = marketsPermissions?.update?.includes(
    MarketsResourceClass.Client,
  );

  const hasDocumentsPermission = marketsPermissions?.create?.includes(
    MarketsResourceClass.ClientDocument,
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ClientsFilters>({
    values: transformInitialFilterValues(query),
    resolver: zodResolver(ClientsFiltersSchema),
  });

  const { data, isLoading } = useClientsQuery({
    ...pagination,
    filters: {
      ...query,
    },
    sort: parseSorting(sorting),
  });

  const deleteClientMutation = useDeleteClientMutation();

  const renderFilterBarItem = (
    key: keyof ClientsFilters,
    value: ClientsFilters[keyof ClientsFilters],
  ) => {
    const label = (
      {
        name: "Name",
      } as Record<keyof ClientsFilters, string>
    )[key];

    return `${label}: ${transformedLabelValue(value)}`;
  };

  const handleDeleteClientClicked = useCallback(
    ({ id, name }: { id: string; name: string }) => {
      setClientToDelete({ id, name });
    },
    [],
  );

  const handleClientDeletionConfirmed = () => {
    if (!clientToDelete.id) return;

    deleteClientMutation.mutate(clientToDelete.id, {
      onSuccess: () => {
        ToastNotification.success("The client account has been deleted");
        setClientToDelete({ id: null, name: null });
      },
    });
  };

  const handleUpdateClientClicked = useCallback(({ id }: { id: string }) => {
    setClientToUpdate(id);
  }, []);

  const table = useClientsTable(
    data?.items,
    {
      sorting,
      setSorting,
    },
    handleDeleteClientClicked,
    handleUpdateClientClicked,
    hasAdminAccess,
    hasUpdatePermission,
    hasDeletePermission,
  );

  const handleFormFiltersClear = () => {
    reset();
    handleClearFiltersDrawer();
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <ListLayout.TopBarTitle>Clients</ListLayout.TopBarTitle>

        <ListLayout.TopBarActions>
          {hasDocumentsPermission && (
            <Button onClick={() => setIsAddClientModalOpen(true)}>New</Button>
          )}
        </ListLayout.TopBarActions>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
        <Filters.Drawer
          isOpen={isFiltersOpen}
          onSubmit={handleSubmit(handleSubmitFilters)}
          onClose={() => setIsFiltersOpen(false)}
          onClear={handleFormFiltersClear}
        >
          <InputField {...register("name")} label="Name" error={errors.name} />
        </Filters.Drawer>
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header>
          <Filters.Bar
            values={query}
            renderItem={renderFilterBarItem}
            onToggleOpen={() => setIsFiltersOpen(value => !value)}
            onClear={handleClearFiltersBar}
          />
        </ListLayout.Header>

        <Table
          instance={table}
          meta={data?.meta}
          pagination={pagination}
          isLoading={isLoading}
          onPaginationChange={updatePagination}
        />

        <DeleteClientConfirmation
          isOpen={Boolean(clientToDelete.id)}
          clientName={clientToDelete.name}
          isDeleting={deleteClientMutation.isLoading}
          onHide={() => setClientToDelete({ id: null, name: null })}
          onConfirm={handleClientDeletionConfirmed}
        />

        <AddClientModal
          isOpen={isAddClientModalOpen}
          onRequestClose={() => setIsAddClientModalOpen(false)}
        />

        {clientToUpdate && (
          <UpdateClientModal
            isOpen={Boolean(clientToUpdate)}
            onRequestClose={() => setClientToUpdate(null)}
            clientId={clientToUpdate}
          />
        )}
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default Clients;
