import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import ImageLogo from "~assets/images/logo/logo-agreena-admin-dark.svg";
import { useRequestPasswordResetMutation } from "~features/auth";
import CenteredLayout from "~layouts/centered-layout";

const resetPasswordSchema = () =>
  z.object({
    email: z.string().min(1, "Email is required"),
  });

type ResetPasswordFormValues = z.infer<ReturnType<typeof resetPasswordSchema>>;

const ResetPassword = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    resolver: zodResolver(resetPasswordSchema()),
    defaultValues: {
      email: "",
    },
  });

  const requestPasswordResetMutation = useRequestPasswordResetMutation();

  const handleRequestPasswordReset = (data: ResetPasswordFormValues) => {
    requestPasswordResetMutation.mutate(data, {
      onSuccess: () => {
        ToastNotification.success("We have sent instructions to your email");
      },
    });
  };

  return (
    <CenteredLayout.Root>
      <div className="grid grid-cols-[minmax(auto,300px)] gap-8">
        <img src={ImageLogo} className="place-self-center" width="160" />

        <p className="text-p2">
          Type in your email below, and we will send you instructions for
          resetting your password.
        </p>

        <form
          onSubmit={handleSubmit(handleRequestPasswordReset)}
          className="flex flex-col space-y-4"
        >
          <InputField
            {...register("email")}
            type="email"
            autoComplete="email"
            label="Email"
            error={errors.email}
          />

          <div className="flex flex-col items-end space-y-4">
            <Button to="/login" variant="text">
              Back to sign in
            </Button>

            <Button isLoading={requestPasswordResetMutation.isLoading}>
              Submit
            </Button>
          </div>
        </form>
      </div>
    </CenteredLayout.Root>
  );
};

export default ResetPassword;
