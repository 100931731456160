import { z } from "zod";

export enum ImportStatus {
  UPLOADING = "uploading",
  UPLOADED = "uploaded",
  FAILED = "failed",
  PROCESSING = "processing",
  PROCESSED = "processed",
  DISCARDED = "discarded",
}

export const InventoryImportSchema = z.object({
  id: z.string(),
  adminId: z.string(),
  filename: z.string().nullable().optional(),
  status: z.nativeEnum(ImportStatus),
  fileDownloadUrl: z.string().nullable().optional(),
  startedProcessingAt: z.string().nullable(),
  processedAt: z.string().nullable().optional(),
  createdAt: z.string(),
  signedUrlExpiresAt: z.string(),
  updatedAt: z.string(),
  errors: z.array(z.string()),
});

export type InventoryImport = z.infer<typeof InventoryImportSchema>;
