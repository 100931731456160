import { ChipCellValue } from "@ag/design-system/organisms";

import { KYCStatus } from "../entities/sales-offer";

export const getKycStatusVariant = (kycStatus: KYCStatus): ChipCellValue => {
  const statusMapping: Record<KYCStatus, ChipCellValue> = {
    [KYCStatus.Approved]: {
      label: "Approved",
      variant: "success",
    },
    [KYCStatus.Pending]: {
      label: "Pending",
      variant: "neutral",
    },
  };

  return statusMapping[kycStatus];
};
