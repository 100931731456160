import { z } from "zod";

export enum GeometryType {
  Polygon = "Polygon",
  MultiPolygon = "MultiPolygon",
  LineString = "LineString",
  Point = "Point",
}

export const GeoJSONGeometrySchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal(GeometryType.Point),
    coordinates: z.array(z.number()),
  }),
  z.object({
    type: z.literal(GeometryType.LineString),
    coordinates: z.array(z.array(z.number())),
  }),
  z.object({
    type: z.literal(GeometryType.Polygon),
    coordinates: z.array(z.array(z.array(z.number()))),
  }),
  z.object({
    type: z.literal(GeometryType.MultiPolygon),
    coordinates: z.array(z.array(z.array(z.array(z.number())))),
  }),
]);

export const GeoJSONSchema = z.object({
  id: z.string(),
  type: z.nativeEnum(GeometryType),
  properties: z.record(z.unknown()),
  geometry: GeoJSONGeometrySchema,
  bbox: z.tuple([z.number(), z.number(), z.number(), z.number()]),
});

export const GeoJSONWithoutPropertiesSchema = z.object({
  type: z.nativeEnum(GeometryType),
  coordinates: z.union([
    z.array(z.array(z.array(z.array(z.number())))),
    z.array(z.array(z.array(z.number()))),
    z.array(z.array(z.number())),
  ]),
  bbox: z.tuple([z.number(), z.number(), z.number(), z.number()]),
});

export type GeoJSONGeometry = z.infer<typeof GeoJSONGeometrySchema>;

export type GeoJSON = z.infer<typeof GeoJSONSchema>;

export type GeoJSONWithoutProperties = z.infer<
  typeof GeoJSONWithoutPropertiesSchema
>;
