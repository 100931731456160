import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { GeoJSONGeometry, GeometryType } from "@ag/map/types";
import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";
import { generateAllFieldBoundariesQueryKey } from "~features/field/api/get-all-field-boundaries";

import { generateNearbyFieldBoundariesQueryKey } from "./get-nearby-field-boundaries";

const saveEditedFieldBoundary = async ({
  carbonFieldId,
  boundaries,
}: {
  carbonFieldId: string;
  boundaries: {
    type: GeometryType;
    coordinates: GeoJSONGeometry["coordinates"];
  };
}) => {
  await axios.patch(
    `/api/v1/field-boundaries/${carbonFieldId}`,
    {
      boundaries,
    },
    {
      apiSource: "node-carbon",
    },
  );
};

export const useSaveEditedFieldBoundary = () =>
  useMutation(saveEditedFieldBoundary, {
    onError: error => {
      ToastNotification.error(error);
    },
    onSuccess: (data, params) => {
      const { carbonFieldId } = params;
      queryClient.invalidateQueries(generateNearbyFieldBoundariesQueryKey());
      queryClient.invalidateQueries(
        generateAllFieldBoundariesQueryKey(carbonFieldId),
      );

      ToastNotification.success("Field boundary saved");
    },
  });
