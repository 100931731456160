import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { FieldValidationStatus } from "~features/field";

import {
  ValidationRequest,
  ValidationRequestSchema,
} from "../entities/validation-request";
import { ValidationRequestStatus } from "../types";

type GetValidationRequestsParams = MetaArgs & {
  filters?: Partial<{
    userId: string;
    carbonUserId: string;
    matchUserEmail: string;
    status: ValidationRequestStatus;
    carbonFieldValidationStatus: FieldValidationStatus;
    createdInRange: {
      startDate: string;
      endDate: string;
    };
  }>;
  sort?: Partial<{
    id: SortingDirection;
  }>;
};

export const generateValidationRequestsQueryKey = (
  params?: GetValidationRequestsParams | undefined,
) => ["validation-requests", params].filter(Boolean);

const getValidationRequests = async (
  params: GetValidationRequestsParams | undefined,
) => {
  const response = await axios.get<CommonPaginatedResponse<ValidationRequest>>(
    "/api/v1/carbon-validation-requests",
    {
      params: {
        ...params,
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(ValidationRequestSchema),
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useValidationRequestsQuery = (
  params?: GetValidationRequestsParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateValidationRequestsQueryKey(params),
    () => getValidationRequests(params),
    {
      keepPreviousData: true,
      ...options,
    },
  );
