import { parse } from "qs";
import { useLocation } from "react-router-dom";

import { LIST_QUERY_PARAM } from "~constants/params";

type PrefixValue = "?" | "&" | null;

type SearchParamsOptions = {
  prefix?: PrefixValue;
};

/**
 * Get all query params for the list page (under the list_q key)
 * and return search params stringified
 */
export const useStringifiedListQueryParam = (
  options?: SearchParamsOptions,
): string => {
  const { prefix = "?" } = options || {};

  const location = useLocation();

  const allQueryParams = parse(location.search.substr(1));
  const listQueryParams = allQueryParams[LIST_QUERY_PARAM] || "";

  return `${prefix || ""}${listQueryParams}`;
};
