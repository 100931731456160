import { z } from "zod";

import { AssuranceStatus } from "~features/assurance";
import { FieldValidationStatus } from "~features/field";

import {
  QualityControlActionSchema,
  QualityControlCommentSchema,
  QualityControlSchema,
  QualityControlStatus,
} from "./quality-control";

/**
 * Limited set of properties, only for quality control usages,
 * since the existing types from "field" feature does not match with API return.
 */
const QualityControlActualsSchema = z.object({
  id: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  submittedAt: z.string().datetime().nullable(),

  harvestYear: z.number(),
  methodologyVersion: z.string(),
  fallow: z.boolean(),

  qcStatus: z.nativeEnum(QualityControlStatus),

  hummingbirdCqcDataAvailability: z.string(),

  latestCarbonQcAction: QualityControlActionSchema,

  carbonQualityControlComments: z.array(QualityControlCommentSchema),
  carbonQualityControlEditComments: z.array(
    z.object({
      id: z.number(),
      createdAt: z.string().datetime(),
      updatedAt: z.string().datetime(),

      adminId: z.number(),

      text: z.string(),
    }),
  ),
});

export const FieldSchema = z.object({
  id: z.number(),
  sizeHa: z.number(),
  carbonUserId: z.number(),
  completionRate: z.number(),
  name: z.string(),
  validationStatus: z.nativeEnum(FieldValidationStatus),
  createdAt: z.string(),
  updatedAt: z.string(),
  qaStatus: z.nativeEnum(AssuranceStatus),
  hummingbirdDataAvailability: z.number(),
  user: z.object({
    id: z.number(),
    email: z.string(),
    name: z.string(),
    hubspotContactUrl: z.string(),
    company: z.object({
      id: z.number(),
      name: z.string(),
      vatNumber: z.string(),
      vatValidatedAt: z.string().nullable(),
    }),
  }),
  carbonFieldActual: QualityControlActualsSchema,
  carbonFarm: z.object({
    id: z.number(),
    name: z.string(),
  }),
  carbonCountry: z.object({
    id: z.number(),
    code: z.string(),
    name: z.string(),
  }),
  carbonQualityControls: z.array(QualityControlSchema),
});

export type Field = z.infer<typeof FieldSchema>;
