import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { UpdateRequest, UpdateRequestSchema } from "../entities/update-request";

export const generateUpdateRequestQueryKey = (
  requestId: string | undefined,
) => ["update-requests", requestId];

const getUpdateRequest = async (requestId: string | undefined) => {
  const response = await axios.get<CommonResponse<UpdateRequest>>(
    `/api/v1/certificates/groups/update-requests/${requestId}`,
    {
      schema: UpdateRequestSchema,
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useUpdateRequestQuery = (requestId: string | undefined) =>
  useQuery(generateUpdateRequestQueryKey(requestId), () =>
    getUpdateRequest(requestId),
  );
