import * as TabsPrimitives from "@radix-ui/react-tabs";
import { useState } from "react";

import { ICONS_REGULAR } from "~assets";
import { Badge, Chip, ChipVariant } from "~atoms";
import { cn } from "~utils";

/* -------------------------------------------------------------------------------------------------
 * Tabs
 * -----------------------------------------------------------------------------------------------*/
type TabsProps =
  | React.PropsWithChildrenRequired<{
      value: string | undefined;
      defaultValue?: never;
      onChange: (tab: string) => void;
    }>
  | React.PropsWithChildrenRequired<{
      defaultValue: string;
      value?: never;
      onChange?: never;
    }>;

const Tabs = ({ children, defaultValue, value, onChange }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(defaultValue);

  const handleChange = (value: string) => {
    // If the consumer is controlling the value, call the onChange callback
    if (onChange) {
      onChange(value);

      return;
    }

    // Otherwise, update the internal state
    setActiveTab(value);
  };

  return (
    <TabsPrimitives.Root
      value={value || activeTab}
      onValueChange={handleChange}
    >
      <div className="flex h-full w-full flex-col">{children}</div>
    </TabsPrimitives.Root>
  );
};

/* -------------------------------------------------------------------------------------------------
 * TabsList
 * -----------------------------------------------------------------------------------------------*/
function TabsList({ children }: React.PropsWithChildrenRequired) {
  return (
    <TabsPrimitives.List className="mb-4 mt-0.5 flex w-full shrink-0 gap-6 border-0 border-b border-solid border-grey-300">
      {children}
    </TabsPrimitives.List>
  );
}

/* -------------------------------------------------------------------------------------------------
 * TabsTrigger
 * -----------------------------------------------------------------------------------------------*/
type TabsTriggerProps = React.PropsWithChildrenRequired<{
  value: string;
  isDisabled?: boolean;
  testId?: string;
  onClick?: React.MouseEventHandler;
}>;

function TabsTrigger({
  value,
  children,
  isDisabled,
  testId,
  onClick,
}: TabsTriggerProps) {
  return (
    <TabsPrimitives.Trigger
      value={value}
      className={cn(
        "relative flex cursor-pointer items-start gap-2 border-none bg-none pb-3 outline-none",
        "data-[state='active']:text-accent-100  data-[state='active']:after:absolute data-[state='active']:after:bottom-[-1px] data-[state='active']:after:left-0 data-[state='active']:after:block data-[state='active']:after:h-[3px] data-[state='active']:after:w-full data-[state='active']:after:rounded-lg data-[state='active']:after:bg-accent-100 data-[state='active']:after:content-['']",
        "focus:outline-none",
        !isDisabled && "data-[state='inactive']:text-grey-900",
        isDisabled && "group text-grey-600",
      )}
      disabled={isDisabled}
      data-testid={testId}
      onClick={onClick}
    >
      {children}
    </TabsPrimitives.Trigger>
  );
}

/* -------------------------------------------------------------------------------------------------
 * TabsTriggerTitle
 * -----------------------------------------------------------------------------------------------*/
function TabsTriggerTitle({ children }: React.PropsWithChildrenRequired) {
  return (
    <div className="text-p1 font-medium">
      <h4 className="flex flex-col items-start gap-0">{children}</h4>
    </div>
  );
}

/* -------------------------------------------------------------------------------------------------
 * TabTriggerSubTitle
 * -----------------------------------------------------------------------------------------------*/
function TabsTriggerSubTitle({ children }: React.PropsWithChildrenRequired) {
  return (
    <p className="text-p3 text-grey-700 group-disabled:text-grey-600">
      {children}
    </p>
  );
}

/* -------------------------------------------------------------------------------------------------
 * TabTriggerIcon
 * -----------------------------------------------------------------------------------------------*/
type TabsTriggerChipProps = {
  variant: ChipVariant;
  icon: keyof typeof ICONS_REGULAR;
};

function TabsTriggerChip({ variant, icon }: TabsTriggerChipProps) {
  return (
    <Chip variant={variant} size="large" icon={icon} className="self-center" />
  );
}

/* -------------------------------------------------------------------------------------------------
 * TabsContent
 * -----------------------------------------------------------------------------------------------*/
type TabsContentProps = React.PropsWithChildrenRequired<{
  value: string;
}>;

export function TabsContent({ value, children }: TabsContentProps) {
  return (
    <TabsPrimitives.Content value={value} className="flex-grow">
      {children}
    </TabsPrimitives.Content>
  );
}

const Root = Tabs;
const List = TabsList;
const Trigger = TabsTrigger;
const TriggerTitle = TabsTriggerTitle;
const TriggerSubTitle = TabsTriggerSubTitle;
const TriggerChip = TabsTriggerChip;
const TriggerBadge = Badge;
const Content = TabsContent;

export {
  Root,
  List,
  Trigger,
  TriggerTitle,
  TriggerSubTitle,
  TriggerChip,
  TriggerBadge,
  Content,
};
export type { TabsProps };
