import I18n from "@ag/i18n";
import { EMPTY_ARRAY } from "@ag/utils/constants";
import { LabelValue } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";

import { FERTILISER_ID_KEY } from "./constants";
import {
  FertiliserAddingMode,
  FertiliserAttribute,
  FertiliserDetailsAttribute,
  FertiliserV2021Data,
} from "./types";

export const generateEmissionInhibitorOptions = (
  selectedFertiliser: ISOFertiliser | null,
): LabelValue<string>[] => {
  const emissionsInhibitorsOptions = [
    {
      label: I18n.t("js.shared.yes"),
      value: "Nitrification",
      disabled: Boolean(!selectedFertiliser?.nitrificationAdded),
    },
    {
      label: I18n.t("js.shared.no"),
      value: "None",
      disabled: Boolean(!selectedFertiliser?.nitrificationAdded),
    },
  ];

  return emissionsInhibitorsOptions;
};

export const mapFertiliserTypeToOptions = (
  fertilisers: ISOFertiliser[],
): LabelValue<number>[] => {
  if (!fertilisers || fertilisers.length === 0) {
    return EMPTY_ARRAY;
  }

  const mappedFertilisers = fertilisers.map(fertiliser => ({
    label: fertiliser.tradeName
      ? `${fertiliser.tradeName} (${fertiliser.name})`
      : fertiliser.name,
    value: fertiliser.id,
  }));

  return mappedFertilisers;
};

export const filterFertiliserTypesByName = (
  fertiliserOptions: LabelValue<number>[],
  name: string,
): Promise<LabelValue<number>[]> =>
  new Promise(resolve => {
    const filteredOptions = fertiliserOptions.filter(
      (fertiliser: LabelValue<number>) => {
        const fertiliserOptionsLabel = fertiliser.label.toLowerCase();
        const fertiliserSearchName = name.toLowerCase();

        return fertiliserOptionsLabel.includes(fertiliserSearchName);
      },
    );

    resolve(filteredOptions);
  });

export const getInitialAddingMode = (
  initialValues: FertiliserV2021Data | undefined,
): FertiliserAddingMode | null => {
  if (
    !initialValues ||
    initialValues[FertiliserAttribute.FertilisersShowChemicalSpecification] ===
      null
  ) {
    return null;
  }

  if (initialValues[FertiliserAttribute.FertilisersShowChemicalSpecification]) {
    return FertiliserAddingMode.ChemicalSpecification;
  }

  return FertiliserAddingMode.List;
};

export const getPreparedDataForSubmission = (
  values: FertiliserV2021Data,
): FertiliserV2021Data => {
  const data = {
    ...values,
  };

  // Parse chemical specification if entered
  if (
    data[FertiliserAttribute.FertilisersShowChemicalSpecification] &&
    data.chemicalSpecification
  ) {
    const fertilisers =
      data.chemicalSpecification[FertiliserAttribute.Fertilisers];
    const applicationMethod =
      data.chemicalSpecification[FertiliserDetailsAttribute.ApplicationMethod];

    data.fertilisers = [];

    for (const [fertiliserKey, applicationRate] of Object.entries(
      fertilisers,
    )) {
      const fertiliserId = fertiliserKey.slice(FERTILISER_ID_KEY.length);

      if (!applicationRate) {
        continue;
      }

      data.fertilisers.push({
        [FertiliserDetailsAttribute.Id]: Number(fertiliserId),
        [FertiliserDetailsAttribute.ApplicationRate]: applicationRate,
        [FertiliserDetailsAttribute.ApplicationMethod]: applicationMethod,
        [FertiliserDetailsAttribute.EmissionsInhibitors]: "None",
      });
    }
  }

  // Set fertilisers count based on the rounds count
  data[FertiliserAttribute.FertilisersCount] =
    data[FertiliserAttribute.Fertilisers].length;

  // Remove extra property used only for storing additional data
  delete data.chemicalSpecification;

  return data;
};

/**
 * Parse fertilisers rounds into an object containing:
 * - "fertiliser ID" - "application rate" pairs
 * - additional application method information
 */
export const getFertilisersInitialData = (
  data: FertiliserV2021Data | undefined,
): FertiliserV2021Data | undefined => {
  if (!data) return;

  const chemicalSpecificationFertilisers: Record<string, number | null> = {};
  let chemicalSpecificationApplicationMethod: string | null = null;
  const hasChemicalSpecification =
    data[FertiliserAttribute.FertilisersShowChemicalSpecification] === true;

  if (hasChemicalSpecification) {
    data.fertilisers.forEach(fertiliser => {
      const id = fertiliser[FertiliserDetailsAttribute.Id];
      const applicationRate =
        fertiliser[FertiliserDetailsAttribute.ApplicationRate];
      const applicationMethod =
        fertiliser[FertiliserDetailsAttribute.ApplicationMethod];

      if (!id) {
        return;
      }

      chemicalSpecificationFertilisers[`${FERTILISER_ID_KEY}${id}`] =
        applicationRate;

      if (!chemicalSpecificationApplicationMethod) {
        chemicalSpecificationApplicationMethod = applicationMethod;
      }
    });
  }

  return {
    ...data,
    chemicalSpecification: {
      [FertiliserAttribute.Fertilisers]: chemicalSpecificationFertilisers,
      [FertiliserDetailsAttribute.ApplicationMethod]:
        chemicalSpecificationApplicationMethod,
    },
  };
};
