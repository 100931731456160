import { cn } from "~utils";

export type Props = {
  // Instead of passing null | undefined, please prefer to hide the component.
  value: number | null | undefined;
  variant?: "amount" | "notification";
  maxValue?: number;
  className?: string;
};

export const Badge = ({
  value,
  variant = "amount",
  maxValue = 999,
  className,
  ...rest
}: Props) => {
  const isAboveMax = typeof value === "number" && value > maxValue;
  const variantClasses = {
    amount: "bg-grey-200 text-grey-900",
    notification: "bg-accent-200 text-grey-white",
  };

  return (
    <span
      className={cn(
        "inline-grid h-6 min-w-6 place-content-center rounded-xl px-2 text-p3",
        variantClasses[variant],
        className,
      )}
      {...rest}
    >
      {isAboveMax ? `${maxValue}+` : value ?? "-"}
    </span>
  );
};
