import { z } from "zod";

import { FieldValidationStatus } from "~features/field";

import { ValidationRequestStatus } from "../types";

export const ValidationRequestSchema = z.object({
  id: z.string(),
  harvestYear: z.number(),
  status: z.nativeEnum(ValidationRequestStatus),
  createdAt: z.string(),
  haUnderValidation: z.number(),
  carbonFields: z.array(
    z.object({
      id: z.string(),
      validationStatus: z.nativeEnum(FieldValidationStatus),
      carbonFarm: z.object({
        id: z.string(),
        name: z.string(),
      }),
    }),
  ),
  user: z.object({
    id: z.string(),
    email: z.string(),
  }),
});

export type ValidationRequest = z.infer<typeof ValidationRequestSchema>;

export const ValidationFiltersSchema = z.object({
  userId: z.string().optional(),
  status: z.nativeEnum(ValidationRequestStatus).optional().or(z.literal("")),
  carbonFieldValidationStatus: z
    .nativeEnum(FieldValidationStatus)
    .optional()
    .or(z.literal("")),
  createdInRange: z.string().optional(),
});

export type ValidationFilters = z.infer<typeof ValidationFiltersSchema>;

export const ValidationFieldFiltersSchema = z.object({
  carbonFarmId: z.string().optional(),
  fieldName: z.string().optional(),
  id: z.string().optional(),
  validationStatus: z
    .nativeEnum(FieldValidationStatus)
    .optional()
    .or(z.literal("")),
});

export type ValidationFieldFilters = z.infer<
  typeof ValidationFieldFiltersSchema
>;
