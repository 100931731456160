import { forwardRef } from "react";

import { Icon, IconConfig, IconProps } from "~assets";
import { cn } from "~utils";

import { buttonRootVariants } from "../helpers";
import { ActionButtonProps, ButtonSize, ButtonVariant } from "../types";

type BaseIconButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  theme?: "light" | "dark";
  isDanger?: boolean;
  isLoading?: boolean;
} & IconConfig<"icon", "iconVariant">;

export type IconButtonProps = BaseIconButtonProps & ActionButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      icon,
      iconVariant = "regular",
      variant = "primary",
      size = "medium",
      theme = "light",
      isDanger = false,
      isLoading = false,
      ...rest
    },
    ref,
  ) => {
    const rootClass = cn(
      buttonRootVariants({
        size,
        theme,
        variant,
        isDanger,
        isLoading,
        isIconOnly: true,
      }),
      className,
    );

    return (
      <button
        ref={ref}
        {...rest}
        disabled={rest.disabled ?? isLoading}
        className={rootClass}
      >
        {/* the union type is lost from props to assigning, so have to use "as" here */}
        <Icon {...({ name: icon, variant: iconVariant } as IconProps)} />
      </button>
    );
  },
);

IconButton.displayName = "IconButton";
