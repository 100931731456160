import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import mergeWith from "lodash/mergeWith";
import { z } from "zod";

import { APIResult, CommonResponse } from "@ag/utils/types";

import { useSessionContext } from "~features/auth";

import {
  CarbonPermissions,
  CarbonPermissionsSchema,
} from "../entities/carbon-permissions";

const getCarbonPermissions = async () => {
  const rubyPermissionsRequest = axios.get<APIResult<CarbonPermissions>>(
    "/api/v1/admin/admins/current_admin/permissions",
    {
      schema: CarbonPermissionsSchema,
    },
  );

  const nodeCarbonPermissionsRequest = axios.get<
    CommonResponse<{ permissions: CarbonPermissions }>
  >("/api/v1/admins/me/permissions", {
    schema: z.object({ permissions: CarbonPermissionsSchema }),
    apiSource: "node-carbon",
  });

  // Each API service has it's own permissions config, so we need to merge them together
  const [rubyPermissions, carbonPermissions] = await Promise.all([
    rubyPermissionsRequest,
    nodeCarbonPermissionsRequest,
  ]);

  const mergedPermissions = mergeWith(
    carbonPermissions.data.success!.data.permissions,
    rubyPermissions.data.result.data,
    (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    },
  );

  return mergedPermissions;
};

export const useCarbonPermissions = () => {
  const { isSignedIn } = useSessionContext();

  return useQuery(["carbon-permissions"], getCarbonPermissions, {
    enabled: isSignedIn,
    staleTime: Infinity,
    retry: false,
  });
};
