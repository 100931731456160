import { endOfDay, startOfDay, subDays, subMonths } from "date-fns";

import { TimeRange } from "~constants/date";

export function getDateRangeFromTimeRange(timeRange: TimeRange) {
  const now = Date.now();

  if (timeRange === TimeRange.Today) {
    return { startDate: startOfDay(now), endDate: endOfDay(now) };
  }

  if (timeRange === TimeRange.SinceYesterday) {
    return { startDate: startOfDay(subDays(now, 1)), endDate: endOfDay(now) };
  }

  if (timeRange === TimeRange.Last7Days) {
    return { startDate: startOfDay(subDays(now, 7)), endDate: endOfDay(now) };
  }

  if (timeRange === TimeRange.Last14Days) {
    return { startDate: startOfDay(subDays(now, 14)), endDate: endOfDay(now) };
  }

  if (timeRange === TimeRange.LastMonth) {
    return { startDate: startOfDay(subMonths(now, 1)), endDate: endOfDay(now) };
  }

  if (timeRange === TimeRange.Last3Months) {
    return { startDate: startOfDay(subMonths(now, 3)), endDate: endOfDay(now) };
  }

  if (timeRange === TimeRange.Last6Months) {
    return { startDate: startOfDay(subMonths(now, 6)), endDate: endOfDay(now) };
  }

  throw new Error("Invalid time range");
}
