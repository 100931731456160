import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { CommonResponse } from "@ag/utils/types";

import { useSessionContext } from "~features/auth";

import {
  AuthPermissions,
  AuthPermissionsSchema,
} from "../entities/auth-permissions";

export const generateAuthPermissionsQueryKey = () => ["auth-permissions"];

const getAuthPermissions = async () => {
  const { data } = await axios.get<
    CommonResponse<{
      permissions: AuthPermissions;
    }>
  >("/api/v1/admins/me/permissions", {
    schema: z.object({
      permissions: AuthPermissionsSchema,
    }),
    apiSource: "node-auth",
  });

  return data.success!.data.permissions;
};

export const useAuthPermissions = () => {
  const { isSignedIn } = useSessionContext();

  return useQuery(generateAuthPermissionsQueryKey(), getAuthPermissions, {
    enabled: isSignedIn,
    staleTime: Infinity,
    retry: false,
  });
};
