import { pageAccess } from "../constants/page-access";
import { AuthPermissions } from "../entities/auth-permissions";
import { CarbonPermissions } from "../entities/carbon-permissions";
import { MarketsPermissions } from "../entities/markets-permissions";
import { UserManagementPermissions } from "../entities/user-management-permissions";
import { Page } from "../types/pages";

type GetHasPageAccessArgs = {
  page: Page;
  carbonPermissions?: CarbonPermissions | undefined;
  authPermissions?: AuthPermissions | undefined;
  userManagementPermissions?: UserManagementPermissions | undefined;
  marketsPermissions?: MarketsPermissions | undefined;
};

export const getHasPageAccess = ({
  page,
  carbonPermissions,
  authPermissions,
  userManagementPermissions,
  marketsPermissions,
}: GetHasPageAccessArgs) => {
  const pagePermissions = pageAccess[page];

  let hasAllCarbonPermissions = true;
  let hasAllAuthPermissions = true;
  let hasAllUserManagementPermissions = true;
  let hasAllMarketsPermissions = true;

  if (pagePermissions.carbon) {
    hasAllCarbonPermissions = pagePermissions.carbon.every(
      ({ ability, resource }) => {
        return carbonPermissions?.[ability]?.includes(resource);
      },
    );
  }

  if (pagePermissions.auth) {
    hasAllAuthPermissions = pagePermissions.auth.every(
      ({ ability, resource }) => {
        return authPermissions?.[ability]?.includes(resource);
      },
    );
  }

  if (pagePermissions.userManagement) {
    hasAllUserManagementPermissions = pagePermissions.userManagement.every(
      ({ ability, resource }) => {
        return userManagementPermissions?.[ability]?.includes(resource);
      },
    );
  }

  if (pagePermissions.markets) {
    hasAllMarketsPermissions = pagePermissions.markets.every(
      ({ ability, resource }) => {
        return marketsPermissions?.[ability]?.includes(resource);
      },
    );
  }

  return (
    hasAllCarbonPermissions &&
    hasAllAuthPermissions &&
    hasAllUserManagementPermissions &&
    hasAllMarketsPermissions
  );
};
