import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

type ResetPasswordData = {
  resetPasswordToken: string;
  password: string;
  passwordConfirmation: string;
};

const resetPassword = async (data: ResetPasswordData) => {
  await axios.post("/api/v1/auth/reset-password", data, {
    apiSource: "node-auth",
  });
};

export const useResetPasswordMutation = () =>
  useMutation(resetPassword, {
    onError: error => {
      ToastNotification.error(error);
    },
  });
