
      
      import { setFileScope, endFileScope } from "@vanilla-extract/css/fileScope";
      setFileScope("src/components/boundaries-drawing-manager/boundaries-drawing-manager.css.ts", "@ag/carbon");
      import { globalStyle, style } from "@vanilla-extract/css";
import { colors } from "@ag/design-system/tokens";
export const size = {
  activeVertex: 32
};
export const root = style({
  display: "flex",
  flexDirection: "column",
  height: "100%"
}, "root");
globalStyle(`${root} .field-map`, {
  width: "100%",
  height: "100%"
});
export const activeVertex = style({
  position: "relative",
  width: size.activeVertex,
  height: size.activeVertex,
  borderRadius: "50%",
  backgroundColor: colors.red[500],
  opacity: 0.8
}, "activeVertex");
      endFileScope();
      
    