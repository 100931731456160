import { forwardRef } from "react";
import { FieldError, UseFormRegisterReturn } from "react-hook-form";

import { RadioButton } from "@ag/design-system/atoms";
import { FormField } from "@ag/design-system/organisms";
import { generateUniqueId } from "@ag/utils/helpers";

type OwnProps = {
  options: { label: string; value: string }[];
  label: string;
  type?: "hidden";
  tooltip?: string;
  error?: FieldError;
  isDisabled?: boolean;
  variant?: "description";
};

type Props = OwnProps &
  UseFormRegisterReturn &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">;

export const RadioButtonField = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      label,
      tooltip,
      options,
      type,
      error,
      isDisabled = false,
      variant,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormField.Root type={type === "hidden" ? "hidden" : undefined}>
        <p className="text-p2">{label}</p>

        {options.map(({ label, value }) => {
          const radioId = id || generateUniqueId();

          return (
            <div className="flex items-center gap-2" key={value}>
              <RadioButton
                {...rest}
                id={radioId}
                value={value}
                ref={ref}
                disabled={isDisabled}
                isInvalid={Boolean(error)}
              />

              <FormField.InlineLabel
                htmlFor={radioId}
                tooltip={tooltip}
                variant={variant}
              >
                {label}
              </FormField.InlineLabel>
            </div>
          );
        })}

        {error && <FormField.Error>{error.message}</FormField.Error>}
      </FormField.Root>
    );
  },
);

RadioButtonField.displayName = "RadioButtonField";
