import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import {
  CropAvailability,
  CropAvailabilitySchema,
} from "../entities/crop-availability";

export const generateCropAvailabilityQueryKey = (id: string) =>
  ["crop-availability", id].filter(Boolean);

export const getCropAvailability = async (id: string) => {
  const response = await axios.get<CropAvailability>(
    `/v1/crops/${id}/availability`,
    {
      schema: CropAvailabilitySchema,
      apiSource: "crops-fertilisers",
    },
  );

  return response.data;
};

export const useCropAvailabilityQuery = (id: string) =>
  useQuery(generateCropAvailabilityQueryKey(id), () => getCropAvailability(id));
