import { MarkerClusterer } from "@react-google-maps/api";
import { ReactElement } from "react";

import { colorsObject } from "@ag/design-system/tokens";

import transparentBg from "../assets/transparent.png";
import { ExtendedMarker } from "./flag-marker";
import "./flag-marker-clusterer.css";

interface Props {
  children: (markerClusterer: unknown) => ReactElement;
}

const clusterStyles = [
  {
    height: 40,
    width: 40,
    url: transparentBg,
  },
];

export const FlagMarkerClusterer = ({ children }: Props) => {
  return (
    <>
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        styles={clusterStyles}
        calculator={(markers: ExtendedMarker[]) => {
          const hasRedFlag = markers.some(
            marker => marker.customFlagColour === "red",
          );

          const clusterColor = hasRedFlag
            ? colorsObject.messaging.error
            : colorsObject.messaging.warning;

          const html = `
            <div style="
              background-color: ${clusterColor[300]};
              border: 2px solid ${clusterColor[900]};
              border-radius: 50%;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              box-shadow: 0 4px 4px 0 rgba(0,0,0,0.25);
            ">
              <span style="color: ${clusterColor[900]};">${markers.length}</span>
            </div>
          `;

          return {
            text: String(markers.length),
            index: 0,
            html: html,
          };
        }}
      >
        {children}
      </MarkerClusterer>
    </>
  );
};
