import { format as formatDate } from "date-fns";

// TODO: port "relative" from admin when needed
export type DateCellFormat = "datetime" | "date" | string;

export type DateCellConfig = {
  format: DateCellFormat;
  value: string | number | null | undefined;
};

export type DateCellValue =
  | string
  | number
  | null
  | undefined
  | DateCellConfig
  | Date;

type DateCellProps<TValue extends DateCellValue> = {
  getValue: () => TValue;
};

export const DateCell = <TValue extends DateCellValue>({
  getValue,
}: DateCellProps<TValue>) => {
  const { date, format } = parseValue(getValue()) ?? {};

  if (!date) return "-";
  if (!(date instanceof Date)) {
    throw new Error(`[DateCell] Invalid value: ${getValue()}`);
  }

  return <span>{formatValue(date, format)}</span>;
};

function parseValue(
  value: unknown,
): { date: Date; format: DateCellFormat } | null {
  if (value == null) return null;

  if (typeof value === "string" || typeof value === "number") {
    return {
      date: new Date(value),
      format: "date",
    };
  }

  if (typeof value === "object") {
    if (value instanceof Date) {
      return {
        date: value,
        format: "date",
      };
    }

    if (!isDateCellConfig(value)) return null;

    const { date } = parseValue(value.value) ?? {};

    if (!date || !(date instanceof Date)) return null;

    return {
      date,
      format: value.format || "date",
    };
  }

  return null;
}

function formatValue(value: Date, format: DateCellFormat = "date") {
  if (format === "date") return formatDate(value, "dd-MM-yyyy");
  if (format === "datetime") return formatDate(value, "dd-MM-yyyy HH:mm");
  return formatDate(value, format);
}

function isDateCellConfig(value: object): value is DateCellConfig {
  return Boolean("value" in value && "format" in value);
}
