import styled from "@emotion/styled";

import { Icon } from "@ag/design-system/assets";
import { style } from "@ag/ui";
import { FieldActualsStatus, FieldDefinitionStatus } from "@ag/utils/types";

import IconInProgress from "~assets/icon-in-progress.svg";

export const SWrapper = styled.div`
  display: flex;
`;

type SIconProps = {
  backgroundColor: string;
};

export const SIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  font-size: 9px;
  border-radius: 50%;
  margin-right: 8px;
  color: ${style.color.white[100]};
  background-color: ${({ backgroundColor }: SIconProps) => backgroundColor};

  ${({ backgroundColor }: SIconProps) => {
    let styles = `
      background-color: ${backgroundColor};
    `;

    if (backgroundColor === "transparent") {
      styles += `
        border: 1px solid ${style.color.grey[400]};
      `;
    }

    return styles;
  }}
`;

type SFieldStatusFontAwesomeIconProps = {
  status: FieldDefinitionStatus | FieldActualsStatus;
  isCheckIcon?: boolean; // Hack for vertical alignment and size of check icon
  isLockIcon?: boolean; // Hack for vertical alignment and size of check icon
};

export const SFontAwesomeFieldStatusIcon = styled(Icon)`
  font-size: 9px;

  ${({
    isCheckIcon,
    isLockIcon,
    status,
  }: SFieldStatusFontAwesomeIconProps): string => {
    let styles = ``;

    if (isCheckIcon) {
      styles += `
        font-size: 10px;
      `;
    }

    if (isLockIcon) {
      styles += `
        font-size: 9px;
        margin-top: -1px;
      `;
    }

    if (
      [
        FieldDefinitionStatus.Completed,
        FieldActualsStatus.Completed,
        FieldDefinitionStatus.Submitted,
        FieldActualsStatus.Submitted,
      ].includes(status)
    ) {
      styles += `
        color: ${style.color.green[400]};
      `;
    }

    return styles;
  }}
`;

export const SInProgressIcon = styled(IconInProgress)`
  width: 16px;
  height: 16px;
`;
