import { useEffect, useState } from "react";

import { Button, Chip, InfoBox } from "@ag/design-system/atoms";
import { FloatingMenu, Select } from "@ag/design-system/molecules";
import { Tabs, useTabs } from "@ag/design-system/organisms";
import { GeoJSONGeometry } from "@ag/map/types";

import { useAllFieldBoundariesQuery } from "~features/field";
import { FieldBoundariesTypes } from "~features/field/entities/field-boundaries";

import { useFieldBoundariesInfoQuery } from "../api/get-field-boundaries-info";
import { useResetFieldBoundaryStatus } from "../api/reset-field-boundary-status";
import { useUpdateFieldBoundaryStatus } from "../api/update-field-bounary-status";
import {
  BoundaryStatuses,
  IssueSeverityFlagTypes,
} from "../entities/field-with-all-boundaries";
import { getIsFieldUnableToComputeResults } from "../helpers/get-is-field-unable-to-compute-results";
import { BoundaryMapField } from "../types/boundary-map-field";
import { CheckResultInfoCard } from "./check-result-info-card";
import { FarmerFollowUpInfoCard } from "./farmer-follow-up-info-card";
import { FieldInfoTable } from "./field-info-table";
import { FieldSizeData, FieldSizeTable } from "./field-size-table";
import { MarkForFollowUpModal } from "./mark-for-follow-up-modal";
import { MarkIneligibleModal } from "./mark-ineligible-modal";
import { OverrideFlagsModal } from "./override-flags-modal";

export const FieldInfoPanel = ({
  selectedField,
  isEditModeOn,
  isLoading,
  onMRVBoundariesVisibilityChange,
  onFarmerBoundariesVisibilityChange,
  onBoundaryEdit,
  onBoundarySave,
  onEditDiscard,
  onSetMrvAsActive,
  onMapCentre,
  onBackClick,
  onSelectOverlappingField,
}: {
  selectedField: BoundaryMapField;
  isEditModeOn: boolean;
  isLoading: boolean;
  onMRVBoundariesVisibilityChange: (visible: boolean) => void;
  onFarmerBoundariesVisibilityChange: (visibile: boolean) => void;
  onBoundaryEdit: () => void;
  onBoundarySave: () => void;
  onEditDiscard: () => void;
  onSetMrvAsActive: (mrvBoundary: GeoJSONGeometry) => void;
  onMapCentre: () => void;
  onBackClick: () => void;
  onSelectOverlappingField: (field: string) => void;
}) => {
  const [fieldSizeData, setFieldSizeData] = useState<FieldSizeData>({
    activeBoundarySize: undefined,
    farmerBoundarySize: undefined,
    mrvBoundarySize: undefined,
  });

  const { data: allBoundariesForField } = useAllFieldBoundariesQuery(
    selectedField.id,
  );

  const { data: fieldBoundariesInfo } = useFieldBoundariesInfoQuery(
    selectedField.id,
  );

  const updateFieldBoundaryStatus = useUpdateFieldBoundaryStatus();
  const resetFieldBoundaryStatus = useResetFieldBoundaryStatus();

  const mrvBoundary = allBoundariesForField?.find(
    boundary => boundary.type === FieldBoundariesTypes.MRV,
  );

  const fieldHasNoOverlapFlags =
    !selectedField.checkResults?.overlap?.result ||
    selectedField.checkResults.overlap.result.every(
      overlapCheckResult =>
        overlapCheckResult.flag === IssueSeverityFlagTypes.NONE,
    );
  const fieldHasIOUFlag =
    selectedField.checkResults?.iou?.flag !== IssueSeverityFlagTypes.NONE;

  const { activeTab, onChange: onTabChange } = useTabs({
    initialValue: "size",
  });

  const [isMarkForFollowUpModalOpen, setIsMarkForFollowUpModalOpen] =
    useState(false);
  const [isMarkIneligibleModalOpen, setIsMarkIneligibleModalOpen] =
    useState(false);

  const [isOverrideFlagsModalOpen, setIsOverrideFlagsModalOpen] =
    useState(false);

  const [visibleBoundaries, setVisibleBoundaries] = useState<
    "activeOnly" | "activeAndFarmer" | "activeAndMrv"
  >("activeOnly");

  useEffect(() => {
    if (visibleBoundaries === "activeOnly") {
      onMRVBoundariesVisibilityChange(false);
      onFarmerBoundariesVisibilityChange(false);
    } else if (visibleBoundaries === "activeAndFarmer") {
      onMRVBoundariesVisibilityChange(false);
      onFarmerBoundariesVisibilityChange(true);
    } else if (visibleBoundaries === "activeAndMrv") {
      onMRVBoundariesVisibilityChange(true);
      onFarmerBoundariesVisibilityChange(false);
    }
  }, [
    visibleBoundaries,
    onMRVBoundariesVisibilityChange,
    onFarmerBoundariesVisibilityChange,
  ]);

  useEffect(() => {
    if (allBoundariesForField) {
      const activeBoundary = allBoundariesForField.find(
        boundary => boundary.isActive,
      );
      const farmerBoundary = allBoundariesForField.find(
        boundary => boundary.type === FieldBoundariesTypes.FARMER,
      );
      const mrvBoundary = allBoundariesForField.find(
        boundary => boundary.type === FieldBoundariesTypes.MRV,
      );

      setFieldSizeData({
        activeBoundarySize: activeBoundary?.sizeHa,
        farmerBoundarySize: farmerBoundary?.sizeHa,
        mrvBoundarySize: mrvBoundary?.sizeHa,
      });
    }
  }, [allBoundariesForField]);

  return (
    <div className="flex h-full w-[360px] flex-col rounded-lg bg-white-100">
      {/* Scrollable content */}
      <div className="flex-grow overflow-y-auto p-4">
        <div className="flex flex-col items-start gap-2">
          <Button
            onClick={onBackClick}
            variant="text"
            icon="chevron-left"
            size="small"
          >
            Back to overview
          </Button>

          <p className="text-h3">Field id: {selectedField.id}</p>

          <Chip variant="neutral"> HY {selectedField.harvestYear}</Chip>

          <Button
            onClick={onMapCentre}
            variant="text"
            icon="crosshairs"
            size="small"
          >
            Centre on map
          </Button>

          <Select.Root
            className="w-full"
            value={visibleBoundaries}
            onChange={value => {
              setVisibleBoundaries(
                value as "activeOnly" | "activeAndFarmer" | "activeAndMrv",
              );
            }}
          >
            <Select.Option value="activeOnly">Active boundary</Select.Option>
            <Select.Option value="activeAndMrv">
              Active + MRV boundary
            </Select.Option>
            <Select.Option value="activeAndFarmer">
              Active + Farmer boundary
            </Select.Option>
          </Select.Root>

          {/* Checkresults & follow ups */}
          <div className="flex w-full flex-col gap-2">
            {selectedField.boundaryStatus?.status ===
              BoundaryStatuses.REQUIRES_FARMER_FOLLOW_UP && (
              <FarmerFollowUpInfoCard
                boundaryStatus={selectedField.boundaryStatus}
                onFollowUpResolve={() =>
                  resetFieldBoundaryStatus.mutate({
                    carbonFieldId: selectedField.id,
                  })
                }
              />
            )}

            {selectedField.checkResults &&
              selectedField.checkResults.overlap &&
              selectedField.checkResults.overlap?.result.length > 0 &&
              selectedField.checkResults.overlap?.result
                .filter(
                  overlapCheckResult =>
                    overlapCheckResult.flag !== IssueSeverityFlagTypes.NONE,
                )
                .map(overlapCheckResult => (
                  <CheckResultInfoCard
                    overlapCheckResult={overlapCheckResult}
                    key={`check-result-${selectedField.id}-${overlapCheckResult.overlappingFieldId}`}
                    onSelectField={onSelectOverlappingField}
                  />
                ))}

            {selectedField.checkResults?.iou &&
              selectedField.sizeHa > 4 &&
              selectedField.boundaryStatus?.status !==
                BoundaryStatuses.FLAG_DETECTED_BUT_OVERRIDDEN &&
              (selectedField.checkResults.iou.flag ===
                IssueSeverityFlagTypes.RED ||
                selectedField.checkResults.iou.flag ===
                  IssueSeverityFlagTypes.YELLOW) && (
                <CheckResultInfoCard
                  IOUCheckResult={selectedField.checkResults.iou}
                  key={`check-result-${selectedField.id}-${selectedField.checkResults.iou.carbonFieldId}`}
                />
              )}

            {selectedField.boundaryStatus &&
              getIsFieldUnableToComputeResults(selectedField) &&
              selectedField.sizeHa > 4 && (
                <CheckResultInfoCard isUnableToComputeCheckResults={true} />
              )}
          </div>

          {/* Details tabs */}
          <div className="w-full">
            <Tabs.Root value={activeTab} onChange={onTabChange}>
              <Tabs.List key="details">
                <Tabs.Trigger value="size">
                  <Tabs.TriggerTitle>Boundaries</Tabs.TriggerTitle>
                </Tabs.Trigger>

                <Tabs.Trigger value="info">
                  <Tabs.TriggerTitle>Info</Tabs.TriggerTitle>
                </Tabs.Trigger>
              </Tabs.List>

              <Tabs.Content key="size" value="size">
                <FieldSizeTable fieldSizeData={fieldSizeData} />
              </Tabs.Content>

              <Tabs.Content key="info" value="info">
                {fieldBoundariesInfo && (
                  <FieldInfoTable fieldInfoData={fieldBoundariesInfo} />
                )}
              </Tabs.Content>

              <Tabs.Content key="cover" value="cover">
                <div className="rounded-lg bg-white-100 p-6">
                  Cover crops content
                </div>
              </Tabs.Content>
            </Tabs.Root>
          </div>
        </div>
      </div>

      {/* Actions  */}
      <div className="shadow-md sticky bottom-0 flex w-full flex-col gap-2 bg-white-100 p-4">
        {isEditModeOn ? (
          <>
            <Button
              onClick={onEditDiscard}
              variant="secondary"
              className="w-full"
              isDanger
            >
              Discard changes
            </Button>
            {mrvBoundary && mrvBoundary.boundaries && (
              <Button
                onClick={() =>
                  mrvBoundary.boundaries &&
                  onSetMrvAsActive(mrvBoundary.boundaries)
                }
                variant="secondary"
                className="w-full"
              >
                Set MRV as active
              </Button>
            )}

            <Button
              onClick={onBoundarySave}
              variant="primary"
              className="w-full"
              isLoading={isLoading}
            >
              Save and run check
            </Button>
          </>
        ) : (
          <>
            {selectedField.editable ? (
              <>
                <Button
                  onClick={onBoundaryEdit}
                  variant="primary"
                  size="small"
                  className="w-full"
                  disabled={!selectedField.editable}
                >
                  Edit active boundary
                </Button>

                <FloatingMenu.Root
                  title="Other actions"
                  triggerVariant="secondary"
                  triggerSize="small"
                >
                  <FloatingMenu.Option
                    key="mark-followup"
                    onClick={() => setIsMarkForFollowUpModalOpen(true)}
                    isDisabled={
                      selectedField.boundaryStatus?.status ===
                      BoundaryStatuses.REQUIRES_FARMER_FOLLOW_UP
                    }
                  >
                    {selectedField.boundaryStatus &&
                    selectedField.boundaryStatus.status ===
                      BoundaryStatuses.REQUIRES_FARMER_FOLLOW_UP
                      ? "Follow up added"
                      : "Add follow up"}
                  </FloatingMenu.Option>

                  <FloatingMenu.Option
                    key="mark-ineligible"
                    onClick={() => setIsMarkIneligibleModalOpen(true)}
                  >
                    Mark as ineligible for programme
                  </FloatingMenu.Option>

                  {((fieldHasNoOverlapFlags && fieldHasIOUFlag) ||
                    (fieldHasNoOverlapFlags &&
                      selectedField.boundaryStatus &&
                      getIsFieldUnableToComputeResults(selectedField))) && (
                    <FloatingMenu.Option
                      key="override"
                      onClick={() => setIsOverrideFlagsModalOpen(true)}
                    >
                      Submit boundary and clear flags
                    </FloatingMenu.Option>
                  )}
                </FloatingMenu.Root>
              </>
            ) : (
              <InfoBox variant="neutral">
                This field has been approved for issuance and cannot be edited.
              </InfoBox>
            )}
          </>
        )}
      </div>

      <MarkForFollowUpModal
        isOpen={isMarkForFollowUpModalOpen}
        fieldId={selectedField.id}
        onRequestClose={() => setIsMarkForFollowUpModalOpen(false)}
        onSubmitFollowUp={comment => {
          updateFieldBoundaryStatus.mutate(
            {
              carbonFieldId: selectedField.id,
              newStatus: BoundaryStatuses.REQUIRES_FARMER_FOLLOW_UP,
              comment: comment,
            },
            { onSuccess: () => setIsMarkForFollowUpModalOpen(false) },
          );
        }}
      />

      <MarkIneligibleModal
        isOpen={isMarkIneligibleModalOpen}
        fieldId={selectedField.id}
        onRequestClose={() => setIsMarkIneligibleModalOpen(false)}
        onSubmitIneligible={(reason, comment) => {
          updateFieldBoundaryStatus.mutate(
            {
              carbonFieldId: selectedField.id,
              newStatus: BoundaryStatuses.FIELD_INELIGIBLE,
              reason: reason,
              comment: comment,
            },
            { onSuccess: () => setIsMarkIneligibleModalOpen(false) },
          );
        }}
      />

      <OverrideFlagsModal
        isOpen={isOverrideFlagsModalOpen}
        selectedField={selectedField}
        onRequestClose={() => setIsOverrideFlagsModalOpen(false)}
        onSubmitOverride={() => {
          updateFieldBoundaryStatus.mutate(
            {
              carbonFieldId: selectedField.id,
              newStatus: BoundaryStatuses.FLAG_DETECTED_BUT_OVERRIDDEN,
            },
            { onSuccess: () => setIsOverrideFlagsModalOpen(false) },
          );
        }}
      />
    </div>
  );
};
