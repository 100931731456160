import { z } from "zod";

import { ContractType } from "~features/contract";
import { FieldValidationStatus } from "~features/field/types/validation";

import {
  AssuranceActionSchema,
  AssuranceCommentSchema,
  AssuranceSchema,
  AssuranceStatus,
} from "./assurance";

export const FieldSchema = z.object({
  id: z.string(),
  hummingbirdDataAvailability: z.string(),
  sizeHa: z.number(),
  activeCarbonContractId: z.string(),
  carbonUserId: z.string(),
  completionRate: z.number(),
  contractType: z.nativeEnum(ContractType),
  dummy: z.boolean(),
  position: z.object({
    lng: z.number(),
    lat: z.number(),
  }),
  name: z.string(),
  validationStatus: z.nativeEnum(FieldValidationStatus),
  createdAt: z.string(),
  updatedAt: z.string(),
  qaStatus: z.nativeEnum(AssuranceStatus).nullable(),
  carbonFarm: z.object({
    id: z.string(),
    name: z.string(),
  }),
  carbonCountry: z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
  }),
  carbonFieldDefinition: z.object({
    id: z.string(),
    baselineVersion: z.string(),
  }),
  carbonQualityAssurances: z.array(AssuranceSchema),
  carbonQualityAssuranceActions: z.array(AssuranceActionSchema),
  carbonQualityAssuranceComments: z.array(AssuranceCommentSchema),
  user: z.object({
    id: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    hubspotContactUrl: z.string(),
  }),
});

export type Field = z.infer<typeof FieldSchema>;
