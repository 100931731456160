import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { useCreateCropPropertiesMutation } from "../api/create-crop-properties";
import { useUpdateCropPropertiesMutation } from "../api/update-crop-properties";
import { BiomassProperties } from "../entities/crop-details";
import {
  mapBiomassDataToProperties,
  mapBiomassPropertiesToForm,
} from "../helpers";

const biomassFormSchema = () =>
  z.object({
    nFix: z.number(),
    nContentAboveGround: z.number(),
    nAGUncertainty: z.string(),
    nContentBelowGround: z.number(),
    nBGUncertainty: z.string(),
    ragT: z.number(),
    rsT: z.number(),
    ratioUncertainty: z.string(),
    dry: z.number(),
    slopeT: z.number(),
    slopeSD: z.string(),
    interceptT: z.number(),
    interceptSD: z.string(),
    r2: z.number(),
  });

export type BiomassFormData = z.infer<ReturnType<typeof biomassFormSchema>>;

const BiomassForm = ({
  id,
  harvestYear,
  properties,
}: {
  id: string;
  harvestYear: string;
  properties: Partial<BiomassProperties>;
}) => {
  const [editing, setEditing] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<BiomassFormData>({
    resolver: zodResolver(biomassFormSchema()),
    defaultValues: mapBiomassPropertiesToForm(properties),
  });
  const updateCropProperties = useUpdateCropPropertiesMutation();
  const createCropProperties = useCreateCropPropertiesMutation();

  const enableEditing = () => setEditing(true);

  const handleCancelEdit = () => {
    setEditing(false);
    reset(mapBiomassPropertiesToForm(properties));
  };

  const handleEditSuccess = () => {
    ToastNotification.success("Biomass properties updated");
    setEditing(false);
  };

  const postBiomassFormData = (data: BiomassFormData) => {
    if (Object.keys(properties).length === 0) {
      createCropProperties.mutate(
        {
          id,
          harvestYear,
          methodology: "biomass",
          props: mapBiomassDataToProperties(data),
        },
        { onSuccess: handleEditSuccess },
      );
    } else {
      updateCropProperties.mutate(
        {
          id,
          harvestYear,
          methodology: "biomass",
          props: mapBiomassDataToProperties(data),
        },
        { onSuccess: handleEditSuccess },
      );
    }
  };

  return (
    <div>
      <h2 className="mb-4 text-h2">Biomass Properties</h2>
      <form onSubmit={handleSubmit(postBiomassFormData)}>
        <section className="mb-4 grid grid-cols-2 items-end gap-x-8 gap-y-4">
          <InputField
            {...register("nFix", {
              setValueAs: v => (v === "" ? undefined : Number(v)),
            })}
            label="N-fix"
            type="number"
            isDisabled={!editing}
            error={errors.nFix}
          />

          <InputField
            {...register("nContentAboveGround", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            label="N content of above-ground residues (N AG(T))"
            type="number"
            step="any"
            isDisabled={!editing}
            error={errors.nContentAboveGround}
          />

          <InputField
            {...register("nAGUncertainty")}
            label="N-AG uncertainty"
            type="string"
            isDisabled={!editing}
            error={errors.nAGUncertainty}
          />

          <InputField
            {...register("nContentBelowGround", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="any"
            label="N content of below-ground residues (N BG(T))"
            type="number"
            isDisabled={!editing}
            error={errors.nContentBelowGround}
          />

          <InputField
            {...register("nBGUncertainty")}
            label="N-BG uncertainty"
            type="string"
            isDisabled={!editing}
            error={errors.nBGUncertainty}
          />

          <InputField
            {...register("ragT", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="any"
            label="Ratio of above-ground residue dry matter to harvested yield (RAG (T))"
            type="number"
            isDisabled={!editing}
            error={errors.ragT}
          />

          <InputField
            {...register("rsT", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="any"
            label="Ratio of below-ground biomass to above-ground biomass (RS (T))"
            type="number"
            isDisabled={!editing}
            error={errors.rsT}
          />

          <InputField
            {...register("ratioUncertainty")}
            label="Ratio Uncertainty"
            type="string"
            isDisabled={!editing}
            error={errors.ratioUncertainty}
          />

          <InputField
            {...register("dry", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="any"
            label="Dry matter fraction of harvested product (DRY)"
            type="number"
            isDisabled={!editing}
            error={errors.dry}
          />

          <InputField
            {...register("slopeT", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="any"
            label="Slope (T)"
            type="number"
            isDisabled={!editing}
            error={errors.slopeT}
          />

          <InputField
            {...register("slopeSD")}
            label="Slope ± 2 s.d. as % of mean"
            type="string"
            isDisabled={!editing}
            error={errors.slopeSD}
          />

          <InputField
            {...register("interceptT", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="any"
            label="Intercept (T)"
            type="number"
            isDisabled={!editing}
            error={errors.interceptT}
          />

          <InputField
            {...register("interceptSD")}
            label="Intercept ± 2 s.d. as % of mean"
            type="string"
            isDisabled={!editing}
            error={errors.interceptSD}
          />

          <InputField
            {...register("r2", {
              setValueAs: v => (v === "" ? undefined : parseFloat(v)),
            })}
            step="any"
            label="R2 adj."
            type="number"
            isDisabled={!editing}
            error={errors.r2}
          />
        </section>

        {editing && (
          <div className="flex gap-2">
            <Button type="submit">Update</Button>
            <Button
              type="button"
              variant="secondary"
              onClick={handleCancelEdit}
            >
              Cancel
            </Button>
          </div>
        )}
      </form>
      {!editing && <Button onClick={enableEditing}>Edit</Button>}
    </div>
  );
};

export default BiomassForm;
