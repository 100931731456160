import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import { APIResult } from "@ag/utils/types";

import {
  FieldDefinitionChange,
  FieldDefinitionChangeScema,
} from "../entities/field-definition-change";

type GetFieldDefinitionChangesParams = {
  fieldId: number | string | undefined;
  fieldDefinitionId: number | string | undefined;
};

export const generateFieldDefinitionChangesQueryKey = (
  params?: GetFieldDefinitionChangesParams | undefined,
) => ["fields", "definition-changes", params].filter(Boolean);

const getFieldDefinitionChanges = async ({
  fieldId,
  fieldDefinitionId,
}: GetFieldDefinitionChangesParams) => {
  const response = await axios.get<APIResult<FieldDefinitionChange[]>>(
    `/api/farmer/carbon/v1/admin/carbon_fields/${fieldId}/carbon_field_definitions/${fieldDefinitionId}/changes`,
    {
      apiSource: "ruby",
      schema: z.array(FieldDefinitionChangeScema),
    },
  );

  return response.data.result.data;
};

export const useFieldDefinitionChangesQuery = (
  params: GetFieldDefinitionChangesParams,
  options?: {
    enabled: boolean;
  },
) =>
  useQuery(
    generateFieldDefinitionChangesQueryKey(params),
    () => getFieldDefinitionChanges(params),
    {
      ...options,
    },
  );
