import log from "loglevel";
import { ToastContent, ToastOptions, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getErrorMessage } from "./helpers";
import { ToastNotificationType } from "./types";

class ToastNotification {
  static info(text: string, options?: ToastOptions): void {
    toast(text, {
      ...options,
      type: ToastNotificationType.Info,
    });
  }

  static error(
    error: unknown,
    options?: ToastOptions,
    errorTitle?: string,
  ): void {
    const text = getErrorMessage(error, errorTitle);

    log.error(error);

    toast(text, {
      ...options,
      type: ToastNotificationType.Error,
    });
  }

  static warning(text: string, options?: ToastOptions): void {
    toast(text, {
      ...options,
      type: ToastNotificationType.Warning,
    });
  }

  static success(text: string | React.ReactNode, options?: ToastOptions): void {
    toast(text, {
      ...options,
      type: ToastNotificationType.Success,
    });
  }

  static default(text: string, options?: ToastOptions): void {
    toast(text, {
      ...options,
      type: ToastNotificationType.Default,
    });
  }

  static customError(error: unknown, options?: ToastOptions): void {
    toast(error as ToastContent, {
      ...options,
      type: ToastNotificationType.Error,
    });
  }
}

export default ToastNotification;
