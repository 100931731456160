import { ButtonSelect } from "@ag/design-system/molecules";

type Props = {
  years: number[];
  selectedYear: number | undefined;
  activeYears?: number[];
  onSelectedYearChange: (year: number) => void;
};

export const HarvestYearsOptions = ({
  years,
  activeYears,
  selectedYear,
  onSelectedYearChange,
}: Props) => (
  <ButtonSelect.Root value={selectedYear} onChange={onSelectedYearChange}>
    {years.map(year => (
      <ButtonSelect.Option
        key={year}
        value={year}
        isDisabled={!(activeYears || years).includes(year)}
      >
        {year}
      </ButtonSelect.Option>
    ))}
  </ButtonSelect.Root>
);
