import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import I18n from "@ag/i18n";
import { CommonResponse } from "@ag/utils/types";

import { CompanyLookup } from "../entities/company-lookup";

export type LookUpCompanyParams = {
  vatNumber: string | null;
  countryCode: string | null;
};

export const generateLookupQueryKey = (params?: LookUpCompanyParams) => {
  return ["lookup", params].filter(Boolean);
};

/**
 * CUSTOM QUERY FUNCTIONS
 */
const lookUpCompany = async (
  params: LookUpCompanyParams,
): Promise<CompanyLookup> => {
  const response = await axios.post<CommonResponse<CompanyLookup>>(
    "/api/v1/vat/validate",
    params,
    {
      apiSource: "node-user-mgt",
    },
  );

  const company = response.data.success!.data;

  if (!company.valid) {
    throw I18n.t("js.admin.company_lookup.no_company_found");
  }

  return company;
};

/**
 * QUERY HOOKS
 */
export const useCompanyLookupQuery = (
  params: LookUpCompanyParams,
  options?: Pick<
    UseQueryOptions<CompanyLookup>,
    "onSuccess" | "enabled" | "cacheTime"
  >,
) =>
  useQuery(
    generateLookupQueryKey(params),
    () => lookUpCompany(params),
    options,
  );
