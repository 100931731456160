import { Link } from "react-router-dom";

import { cn } from "@ag/design-system/utils";

type Props = {
  alignment?: "1/2" | "1/3" | "2/3";
  rows: {
    label: string;
    value: string | null | undefined;
    href?: string;
  }[];
};

const SalesOfferDetailsTable = ({ rows, alignment = "2/3" }: Props) => (
  <table className="w-full rounded bg-white-100">
    <tbody>
      {rows.map(({ label, value, href }, index) => (
        <tr key={index} className="my-2 flex items-end">
          <td
            className={cn(
              "border-b border-grey-300 py-1.5 text-p2 text-grey-800",
              {
                "w-1/2": alignment === "1/2",
                "w-1/3": alignment === "1/3",
                "w-2/3": alignment === "2/3",
              },
            )}
          >
            {label}
          </td>
          <td
            className={cn(
              "overflow-auto break-words border-b border-grey-300 text-right",
              {
                "text-p3 text-satellite-200 underline": Boolean(href),
                "text-p2 text-grey-900": !href,
                "w-1/2": alignment === "1/2",
                "w-1/3": alignment === "1/3",
                "w-2/3": alignment === "2/3",
              },
            )}
          >
            {!href ? value ?? "N/A" : <Link to={href}>{value ?? "N/A"}</Link>}{" "}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default SalesOfferDetailsTable;
