import I18n from "@ag/i18n";

import { getErrorMessagesString } from "../../helpers";

export const getErrorMessage = (error: unknown, toastTitle?: string) => {
  const defaultErrorMessage =
    toastTitle ?? I18n.t("js.shared.please_contact_support");

  const errorDetails = getErrorMessagesString(error);

  return errorDetails
    ? defaultErrorMessage + ` (${errorDetails})`
    : defaultErrorMessage;
};
