import styled from "@emotion/styled";

import { colorsObject } from "@ag/design-system/tokens";

export const SSeparatorSection = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0 16px;
`;

export const SSeparator = styled.div`
  flex: 1;
  height: 1px;
  background: ${colorsObject.grey[200]};
`;

export const SSeparatorText = styled.span`
  margin: 0 28px;

  text-transform: uppercase;
`;
