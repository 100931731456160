import { useMemo } from "react";
import { Field, useForm, useFormState } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";

import { Button, InfoBox } from "@ag/design-system/atoms";
import { ButtonSelect, Select } from "@ag/design-system/molecules";
import { ButtonSelectField, InputField, SelectField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";
import { SFormSubmitButtonContainer } from "~forms/styled";
import { FertiliserAddingMode } from "~types";

import {
  generateEmissionInhibitorOptions,
  mapFertiliserTypeToOptions,
} from "../../helpers";
import { SApplicationRoundTitle, SApplicationWrapper } from "../../styled";
import {
  FertiliserAttribute,
  FertiliserDetailsAttribute,
  FertiliserDetailsData,
} from "../../types";
import { SSeparator, SSeparatorSection, SSeparatorText } from "../styled";

type Props = {
  fertilisers: ISOFertiliser[];
  applicationModes: LabelValue<string>[];
  hasApplications: boolean;
  isReadonly: boolean | undefined;
  isSubmitting: boolean | undefined;
  onAddingModeButtonClick: (addingMode: FertiliserAddingMode) => void;
};

const FertiliserList = ({
  applicationModes,
  fertilisers,
  hasApplications,
  isReadonly,
  isSubmitting,
  onAddingModeButtonClick,
}: Props) => {
  const { initialValues, pristine } = useFormState();
  const { submit } = useForm();

  const isSubmissionOverwriting =
    initialValues[FertiliserAttribute.FertilisersShowChemicalSpecification] ===
    true;

  const fertiliserTypeOptions = useMemo(
    () => mapFertiliserTypeToOptions(fertilisers),
    [fertilisers],
  );

  const handleAddApplicationClicked = (fields: {
    push: (value: FertiliserDetailsData) => void;
  }) => {
    const emptyFertiliserData = {
      [FertiliserDetailsAttribute.Id]: null,
      [FertiliserDetailsAttribute.ApplicationMethod]: null,
      [FertiliserDetailsAttribute.EmissionsInhibitors]: null,
      [FertiliserDetailsAttribute.ApplicationRate]: null,
    };

    fields.push(emptyFertiliserData);
  };

  return (
    <>
      <FieldArray name={FertiliserAttribute.Fertilisers}>
        {({ fields }): React.ReactNode => (
          <div>
            {fields.map((name, index) => {
              const attributesGroup = fields.value[index];
              const selectedFertiliserId =
                attributesGroup &&
                attributesGroup[FertiliserDetailsAttribute.Id];
              const selectedFertiliser = fertilisers
                ? fertilisers[selectedFertiliserId]
                : null;
              const emissionsInhibitorsOptions =
                generateEmissionInhibitorOptions(selectedFertiliser);
              const hasAnyEmissionInhibitorsOptionAvailable =
                emissionsInhibitorsOptions.some(
                  (option: LabelValue<string>) => !option.disabled,
                );
              const isCarbonNitrificationVisible = !(
                !selectedFertiliserId ||
                !hasAnyEmissionInhibitorsOptionAvailable
              );

              return (
                <SApplicationWrapper key={name}>
                  <div className="mb-1 flex justify-between">
                    <SApplicationRoundTitle
                      data-testid={`fertiliser-applicationRoundTitle-${index}`}
                    >
                      {I18n.t("js.carbon.application_round")} {index + 1}
                    </SApplicationRoundTitle>

                    {!isReadonly && (
                      <Button
                        data-testid={`fertiliser-deleteBtn-${index}`}
                        variant="secondary"
                        isDanger
                        size="x-small"
                        onClick={() => {
                          fields.remove(index);
                        }}
                      >
                        {I18n.t("js.shared.delete")}
                      </Button>
                    )}
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <Field
                      name={`${name}.${FertiliserDetailsAttribute.Id}`}
                      render={({ input, meta }) => (
                        <SelectField
                          {...input}
                          data-testid={`fertiliser-idSelect-${index}`}
                          label={I18n.t("js.carbon.fertiliser_type")}
                          error={
                            meta.touched && meta.error
                              ? { message: meta.error }
                              : undefined
                          }
                          tooltip={I18n.t("js.carbon.application_method_hint")}
                          isDisabled={!selectedFertiliserId || isReadonly}
                        >
                          {fertiliserTypeOptions.map(option => (
                            <Select.Option
                              key={option.value}
                              value={String(option.value)}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </SelectField>
                      )}
                    />

                    {isCarbonNitrificationVisible && (
                      <Field
                        name={`${name}.${FertiliserDetailsAttribute.EmissionsInhibitors}`}
                        render={({ input, meta }) => (
                          <ButtonSelectField
                            {...input}
                            data-testid={`fertiliser-emissionInhibitorsBtnGroup-${index}`}
                            label={I18n.t("js.carbon.nitrification")}
                            error={
                              meta.touched && meta.error
                                ? { message: meta.error }
                                : undefined
                            }
                            tooltip={I18n.t("js.carbon.nitrification_tooltip")}
                            isDisabled={isReadonly}
                          >
                            {emissionsInhibitorsOptions.map(option => (
                              <ButtonSelect.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </ButtonSelect.Option>
                            ))}
                          </ButtonSelectField>
                        )}
                      />
                    )}

                    <Field
                      name={`${name}.${FertiliserDetailsAttribute.ApplicationRate}`}
                      render={({ input, meta }) => (
                        <InputField
                          {...input}
                          data-testid={`fertiliser-applicationRateInput-${index}`}
                          type="number"
                          label={I18n.t("js.carbon.application_rate")}
                          tooltip={I18n.t("js.carbon.application_rate_tooltip")}
                          isDisabled={isReadonly}
                          error={
                            meta.touched && meta.error
                              ? { message: meta.error }
                              : undefined
                          }
                        />
                      )}
                    />
                  </div>

                  <div className="mt-4">
                    <Field
                      name={`${name}.${FertiliserDetailsAttribute.ApplicationMethod}`}
                      render={({ input, meta }) => (
                        <ButtonSelectField
                          {...input}
                          data-testid={`fertiliser-applicationMethodButtonGroup-${index}`}
                          label={I18n.t("js.carbon.application_method")}
                          error={
                            meta.touched && meta.error
                              ? { message: meta.error }
                              : undefined
                          }
                          tooltip={I18n.t("js.carbon.application_method_hint")}
                          isDisabled={!selectedFertiliserId || isReadonly}
                        >
                          {applicationModes.map(option => (
                            <ButtonSelect.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </ButtonSelect.Option>
                          ))}
                        </ButtonSelectField>
                      )}
                    />
                  </div>
                </SApplicationWrapper>
              );
            })}

            {!isReadonly && (
              <Button
                data-testid="fertiliser-addApplicationRoundButton"
                variant="text"
                onClick={() => {
                  handleAddApplicationClicked(fields);
                }}
              >
                + {I18n.t("js.carbon.add_application_round")}
              </Button>
            )}
          </div>
        )}
      </FieldArray>

      {isSubmissionOverwriting && (
        <InfoBox type="warning">
          {I18n.t("js.carbon.saving_list_overwrite_specification")}
        </InfoBox>
      )}

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          {!hasApplications && (
            <Button
              data-testid="fertiliser-notUsingFertiliserBtn"
              type="submit"
              variant="text"
              isLoading={isSubmitting}
              onClick={submit}
            >
              {I18n.t("js.carbon.not_using_fertiliser")}
            </Button>
          )}

          <Button
            data-testid="fertiliser-submitButton"
            type="submit"
            isLoading={hasApplications && isSubmitting}
            disabled={!hasApplications || pristine}
            onClick={submit}
          >
            {I18n.t("js.shared.save")}
          </Button>
        </SFormSubmitButtonContainer>
      )}

      {!isReadonly && (
        <>
          <SSeparatorSection>
            <SSeparator />

            <SSeparatorText>{I18n.t("js.shared.or")}</SSeparatorText>

            <SSeparator />
          </SSeparatorSection>

          <Button
            variant="secondary"
            onClick={(): void => {
              onAddingModeButtonClick(
                FertiliserAddingMode.ChemicalSpecification,
              );
            }}
          >
            {I18n.t("js.carbon.enter_chemical_specifications")}
          </Button>
        </>
      )}
    </>
  );
};

export default FertiliserList;
