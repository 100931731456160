import { useState } from "react";
import { Form } from "react-final-form";

import { FallowForm } from "@ag/carbon/forms/inputs-v2022";
import { Button } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import { FieldActualsV2022 } from "~features/field";

import AdminEditActualsComment, {
  ActualsData,
} from "../../edit-actuals-comment-modal";
import * as styles from "./fallow.css";

type Props = {
  fieldId: string;
  fieldActuals: FieldActualsV2022;
  isReadonly: boolean;
};

const Fallow = ({ fieldId, fieldActuals, isReadonly }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitFormData, setSubmitFormData] = useState<ActualsData | null>(
    null,
  );

  const handleFallowSubmit = (values: { fallow: boolean }) => {
    setSubmitFormData(values);
    setIsModalOpen(true);
  };

  return (
    <div>
      <Form
        initialValues={{
          fallow: fieldActuals.fallow,
        }}
        render={form => (
          <div className={styles.root}>
            <FallowForm isReadonly={isReadonly} />

            <div>
              <Button
                onClick={form.form.submit}
                disabled={isReadonly || form.pristine}
              >
                {I18n.t("js.shared.save")}
              </Button>
            </div>
          </div>
        )}
        onSubmit={handleFallowSubmit}
      />

      <AdminEditActualsComment
        fieldId={fieldId}
        fieldActualsId={fieldActuals.id}
        actualsData={submitFormData}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default Fallow;
