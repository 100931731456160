import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { stringify } from "qs";
import { z } from "zod";

import { SortingDirection } from "@ag/design-system/organisms";
import { DEFAULT_LIMIT } from "@ag/utils/constants";
import { CommonPaginatedResponse, MetaArgs } from "@ag/utils/types";

import { FieldOverview, FieldOverviewSchema } from "../entities/field-overview";
import { FieldValidationStatus } from "../types/validation";

export type GetFieldsParams = MetaArgs & {
  actualHarvestYear?: number;
  includeFieldDetails?: boolean;
  filters?: Partial<{
    actualSubmitted: string | number;
    baseCompleted: boolean;
    carbonFarmId: number;
    carbonUserId: string[] | number[];
    fallowActualsYears: string[] | number[];
    fieldName: string;
    id: string[] | number[];
    matchCountryCode: Locale[];
    reportReceived: boolean;
    definitionSubmitted: boolean;
    userId: number;
    validationStatus: FieldValidationStatus[];
    withoutActiveContract: boolean;
    expired: boolean;
  }>;
  sort?: Partial<{
    id: SortingDirection;
  }>;
};

export const generateFieldsQueryKey = (params?: GetFieldsParams | undefined) =>
  ["fields", params].filter(Boolean);

export const getFields = async (params: GetFieldsParams | undefined) => {
  const response = await axios.get<CommonPaginatedResponse<FieldOverview>>(
    `/api/v1/carbon-fields`,
    {
      params: {
        ...params,
        includeMeta: true,

        // Limit can be set to 0 to get meta only
        limit: params?.limit ?? DEFAULT_LIMIT,
      },
      schema: z.array(FieldOverviewSchema),
      apiSource: "node-carbon",
      paramsSerializer: params => {
        return stringify(params, { arrayFormat: "comma" });
      },
    },
  );

  return response.data.success!.data;
};

export const useFieldsQuery = (
  params?: GetFieldsParams,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(generateFieldsQueryKey(params), () => getFields(params), {
    keepPreviousData: true,
    ...options,
  });
