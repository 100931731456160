import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@ag/design-system/atoms";
import { Tabs } from "@ag/design-system/organisms";
import { ToastNotification } from "@ag/utils/services";

import BackButton from "~components/BackButton";
import { queryClient } from "~config";
import {
  ContractFields,
  ContractOverview,
  ContractStatus,
  NotSignedContractTerminationReason,
  RemovalReason,
  RemoveFieldsFromContractModal,
  SignedContractTerminationReason,
  UploadContractAmendmentModal,
  VoidContractModal,
  generateContractQueryKey,
  getContract,
  useContractQuery,
  useUnlinkFieldsFromContractMutation,
  useUploadContractAmendmentDocumentMutation,
  useVoidNotSignedContractMutation,
  useVoidSignedContractMutation,
} from "~features/contract";
import { FieldOverview } from "~features/field/entities/field-overview";
import { AuthorizedSidebar } from "~features/navigation";
import {
  CarbonResourceClass,
  useCarbonPermissions,
} from "~features/permission";
import DetailsLayout from "~layouts/details-layout";

const ContractDetails = () => {
  const [isVoidContractConfirmationOpen, setIsVoidContractConfirmationOpen] =
    useState(false);

  const [
    isRemoveFieldsFromContractModalOpen,
    setIsRemoveFieldsFromContractModalOpen,
  ] = useState(false);

  const [
    isUploadContractAmendmentModalOpen,
    setIsUploadContractAmendmentModalOpen,
  ] = useState(false);

  const [selectedFields, setSelectedFields] = useState<FieldOverview[]>([]);

  const voidNotSignedContract = useVoidNotSignedContractMutation();
  const voidSignedContract = useVoidSignedContractMutation();
  const unlinkFieldsFromContract = useUnlinkFieldsFromContractMutation();
  const uploadContractAmendmentDocument =
    useUploadContractAmendmentDocumentMutation();

  const params = useParams<{ contractId: string }>();
  const contractId = Number(params.contractId);

  const { data: contract, isLoading } = useContractQuery(contractId);

  const [activeTab, setActiveTab] = useState<
    "unlinked-fields" | "contract-fields" | undefined
  >(undefined);

  useEffect(() => {
    if (contract) {
      const initialTabValue =
        contract.uncontractedFields && contract.uncontractedFields.length > 0
          ? "unlinked-fields"
          : "contract-fields";
      setActiveTab(initialTabValue);
    }
  }, [contract]);

  const refreshStatus = async () => {
    await getContract(contractId, { forceRefresh: true });
    queryClient.invalidateQueries(generateContractQueryKey({ contractId }));
  };

  const { data: carbonPermissions } = useCarbonPermissions();

  const hasVoidPermission =
    carbonPermissions?.void?.includes(CarbonResourceClass.CarbonContract) ||
    carbonPermissions?.withdraw?.includes(CarbonResourceClass.CarbonContract);

  const hasRemoveFieldsFromContractPermission =
    carbonPermissions?.update?.includes(CarbonResourceClass.CarbonContract) &&
    carbonPermissions?.unassign?.includes(CarbonResourceClass.CarbonContract);

  const isSignedContract = useMemo(
    () => contract?.status === ContractStatus.Signed,
    [contract],
  );

  const handleRemoveFieldsFromContract = (
    carbonFieldIds: string[],
    reasonForRemoval: RemovalReason,
    churnedAt?: string,
  ) => {
    unlinkFieldsFromContract.mutate(
      {
        carbonContractId: contractId.toString(),
        carbonFields: carbonFieldIds.map(id => ({
          id,
          removalReason: reasonForRemoval,
          churnedAt,
        })),
      },
      {
        onSuccess: () => {
          ToastNotification.success("Fields removed from contract");
          setActiveTab("unlinked-fields");
          setSelectedFields([]);
          setIsRemoveFieldsFromContractModalOpen(false);
        },
      },
    );
  };

  const handleUploadContractAmendment = (file: File) => {
    uploadContractAmendmentDocument.mutate(
      {
        carbonContractId: contractId.toString(),
        contractAmendmentDocument: file,
      },
      {
        onSuccess: () => {
          ToastNotification.success("Contract amendment uploaded");
          setIsUploadContractAmendmentModalOpen(false);
        },
      },
    );
  };

  const handleContractVoid = ({
    reason,
    reasonComment,
  }: {
    reasonComment: string;
    reason:
      | SignedContractTerminationReason
      | NotSignedContractTerminationReason;
  }) => {
    if (isSignedContract) {
      voidSignedContract.mutate(
        {
          reasonComment,
          reason: reason as SignedContractTerminationReason,
          contractId: contract!.id,
        },
        {
          onSettled: () => setIsVoidContractConfirmationOpen(false),
        },
      );
    } else {
      voidNotSignedContract.mutate(
        {
          reason: reason as NotSignedContractTerminationReason,
          contractId: contract!.id,
        },
        {
          onSettled: () => setIsVoidContractConfirmationOpen(false),
        },
      );
    }
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as "contract-fields" | "unlinked-fields");
  };

  return (
    <DetailsLayout.Root>
      <DetailsLayout.Sidebar>
        <AuthorizedSidebar />
      </DetailsLayout.Sidebar>

      <DetailsLayout.TopBar>
        <BackButton />

        <DetailsLayout.TopBarTitle>
          {contract ? `Carbon - "${contract.name}"` : "Carbon"}
        </DetailsLayout.TopBarTitle>
      </DetailsLayout.TopBar>

      <DetailsLayout.Content>
        <div className="flex flex-col space-y-6">
          {contract && (
            <>
              <ContractOverview contract={contract} />

              <section className="flex flex-wrap space-x-4">
                {contract.contractUrl ? (
                  <Button
                    variant="secondary"
                    to={`/file?url=${contract.contractUrl}&apiSource=node-carbon`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Open contract
                  </Button>
                ) : null}

                {contract.termsUrl ? (
                  <Button
                    variant="secondary"
                    to={`/file?url=${contract.termsUrl}&apiSource=node-carbon`}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Open terms
                  </Button>
                ) : null}

                <Button isLoading={isLoading} onClick={refreshStatus}>
                  Refresh status
                </Button>

                {contract.status !== "withdrawn" &&
                contract.status !== "terminated" ? (
                  <Button
                    isDanger
                    disabled={!hasVoidPermission}
                    isLoading={isLoading}
                    onClick={() => setIsVoidContractConfirmationOpen(true)}
                  >
                    {isSignedContract ? "Void contract" : "Withdraw contract"}
                  </Button>
                ) : null}

                {hasRemoveFieldsFromContractPermission &&
                contract.carbonFields.length > 0 &&
                (contract.status == "signed" || contract.status == "sent") ? (
                  <Button
                    isDanger
                    disabled={selectedFields.length === 0}
                    isLoading={isLoading}
                    onClick={() => {
                      setIsRemoveFieldsFromContractModalOpen(true);
                    }}
                  >
                    Remove from contract
                  </Button>
                ) : null}

                {hasRemoveFieldsFromContractPermission &&
                contract.uncontractedFields &&
                contract.uncontractedFields.some(
                  field =>
                    field.hasDocument === false &&
                    field.removalReason === RemovalReason.Churn,
                ) ? (
                  <Button
                    isDanger
                    isLoading={isLoading}
                    onClick={() => {
                      setIsUploadContractAmendmentModalOpen(true);
                    }}
                  >
                    Upload Contract Amendment
                  </Button>
                ) : null}
              </section>

              <Tabs.Root value={activeTab} onChange={handleTabChange}>
                <Tabs.List>
                  {contract.carbonFields &&
                    contract.carbonFields.length > 0 && (
                      <Tabs.Trigger value="contract-fields">
                        <Tabs.TriggerTitle>Contract Fields</Tabs.TriggerTitle>
                      </Tabs.Trigger>
                    )}

                  {contract.uncontractedFields &&
                    contract.uncontractedFields.length > 0 && (
                      <Tabs.Trigger value="unlinked-fields">
                        <Tabs.TriggerTitle>
                          Uncontracted Fields
                        </Tabs.TriggerTitle>
                      </Tabs.Trigger>
                    )}

                  <></>
                </Tabs.List>

                {contract.carbonFields && contract.carbonFields.length > 0 && (
                  <Tabs.Content value="contract-fields">
                    <ContractFields
                      contract={contract}
                      fieldsToDisplay="contract-fields"
                      onSelectedFieldsUpdate={setSelectedFields}
                    />
                  </Tabs.Content>
                )}

                {contract.uncontractedFields &&
                  contract.uncontractedFields.length > 0 && (
                    <Tabs.Content value="unlinked-fields">
                      <ContractFields
                        contract={contract}
                        fieldsToDisplay="unlinked-fields"
                      />
                    </Tabs.Content>
                  )}
              </Tabs.Root>

              <VoidContractModal
                isOpen={isVoidContractConfirmationOpen}
                isProcessing={
                  voidNotSignedContract.isLoading ||
                  voidSignedContract.isLoading
                }
                isSignedContract={isSignedContract}
                onRequestClose={() => setIsVoidContractConfirmationOpen(false)}
                onVoid={handleContractVoid}
              />

              <RemoveFieldsFromContractModal
                isOpen={isRemoveFieldsFromContractModalOpen}
                selectedFields={selectedFields}
                onRequestClose={() => {
                  setIsRemoveFieldsFromContractModalOpen(false);
                }}
                onRemoveFields={handleRemoveFieldsFromContract}
              />

              <UploadContractAmendmentModal
                isOpen={isUploadContractAmendmentModalOpen}
                onRequestClose={() => {
                  setIsUploadContractAmendmentModalOpen(false);
                }}
                onUploadFile={handleUploadContractAmendment}
              />
            </>
          )}
        </div>
      </DetailsLayout.Content>
    </DetailsLayout.Root>
  );
};

export default ContractDetails;
