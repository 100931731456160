import { Control, FieldValues, Path, useController } from "react-hook-form";

import { MultiSelect, Selection } from "@ag/design-system/molecules";
import { FormField } from "@ag/design-system/organisms";
import { useId } from "@ag/utils/hooks";

type Props<FormValues extends FieldValues> = {
  children: React.ReactNode;
  control: Control<FormValues>;
  name: Path<FormValues>;
  label: string;
  renderSelection: (selection: Selection) => React.ReactNode;
  placeholder?: string;
  tooltip?: string;
  variant?: "default" | "full-width";
  disabled?: boolean;
};

export const MultiSelectField = <FormValues extends FieldValues>({
  children,
  control,
  name,
  label,
  renderSelection,
  tooltip,
  placeholder,
  variant,
  disabled,
}: Props<FormValues>) => {
  const { field, fieldState } = useController<FormValues>({
    name,
    control,
  });

  const id = useId();

  return (
    <FormField.Root>
      {label && (
        <FormField.Label id={id} tooltip={tooltip}>
          {label}
        </FormField.Label>
      )}

      <MultiSelect.Root
        ariaLabelledby={id}
        placeholder={placeholder}
        renderSelection={renderSelection}
        values={field.value}
        variant={variant}
        isDisabled={disabled}
        isInvalid={Boolean(fieldState.error)}
        onChange={(value: unknown[] | null) => field.onChange(value)}
      >
        {children}
      </MultiSelect.Root>

      {fieldState.error && (
        <FormField.Error>{fieldState.error.message}</FormField.Error>
      )}
    </FormField.Root>
  );
};
