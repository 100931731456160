import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { CROP_TYPE_OTHER_OPTION_ID } from "~constants";
import {
  CoverCrops,
  CropResidueManagement,
  TillingPracticeValue,
} from "~types";

import {
  CultivationStrategyAttribute,
  GetInputVisibilityStateArgs,
  InputVisibility,
} from "./types";

export const getTillingCoverCropsOptions = (): LabelValue<CoverCrops>[] => [
  {
    label: I18n.t("js.shared.no"),
    value: CoverCrops.None,
  },
  {
    label: I18n.t("js.carbon.up_until_nov"),
    value: CoverCrops.Winter,
  },
  {
    label: I18n.t("js.carbon.up_until_sowing"),
    value: CoverCrops.Spring,
  },
];

export const getTillingPracticeOptions = (): LabelValue<number>[] => [
  {
    label: I18n.t("js.carbon.tilled"),
    value: TillingPracticeValue.Conventional,
    warning: I18n.t("js.carbon.tilling_against_policy"),
  },
  {
    label: I18n.t("js.carbon.reduced_tillage"),
    value: TillingPracticeValue.ReducedTillage,
  },
  {
    label: I18n.t("js.carbon.no-till"),
    value: TillingPracticeValue.NoTill,
  },
];

export const getResidueManagementOptions = (
  initialOptions: LabelValue<string>[],
): LabelValue<string>[] =>
  initialOptions.map(option => {
    if (option.value === CropResidueManagement.Burned) {
      return {
        ...option,
        warning: I18n.t("js.carbon.burning_against_policy"),
      };
    }

    return option;
  });

export const getInputVisibilityState = ({
  values,
  cropTypes,
}: GetInputVisibilityStateArgs): InputVisibility => {
  const selectedCropTypeOption = cropTypes.find(
    (cropType): boolean =>
      cropType.id === values[CultivationStrategyAttribute.CarbonCropTypeId],
  );

  const isResidueManagementInputVisible = Boolean(
    selectedCropTypeOption?.showResidue,
  );
  const isCustomCropTypeInputVisible =
    selectedCropTypeOption?.identifier === CROP_TYPE_OTHER_OPTION_ID;

  return {
    isResidueManagementInputVisible,
    isCustomCropTypeInputVisible,
  };
};
