import * as styles from "./raw-data-cell.css";

/* -------------------------------------------------------------------------------------------------
 * RawDataCell
 * -----------------------------------------------------------------------------------------------*/

// Unkown is needed here, bc some of the tanstack types rely on unknown.
// This also means we have to do some extra type checking in the component.
export type RawDataCellValue =
  | unknown
  | string
  | number
  | null
  | object
  | undefined;

type RawDataCellProps<TValue extends RawDataCellValue> = {
  getValue: () => TValue;
};

export function RawDataCell<TValue extends RawDataCellValue>({
  getValue,
}: RawDataCellProps<TValue>) {
  return <span className={styles.root}>{formatValue(getValue())}</span>;
}

function formatValue(value: unknown) {
  if (typeof value === "string") return value;
  if (typeof value === "object") return JSON.stringify(value, undefined, 2);
  if (typeof value === "number") return value.toString();
  return "-";
}
