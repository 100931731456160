import { CellContext, RowData } from "@tanstack/react-table";

import { Checkbox } from "~atoms";
import { stack } from "~utils";

type Props<TData extends RowData> = CellContext<TData, unknown>;

export const SelectionCell = <TData extends RowData>({
  row,
  table,
}: Props<TData>) => (
  <div
    className={stack()}
    onClick={event => {
      event.stopPropagation();
    }}
  >
    <Checkbox
      isInvalid={Boolean(table.options.meta?.errors?.[row.id]?.length)}
      checked={row.getIsSelected()}
      disabled={!row.getCanSelect()}
      onChange={() => {
        row.toggleSelected();
      }}
    />
  </div>
);
