import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { TextareaField } from "@ag/form-fields";

const commentFormSchema = () =>
  z.object({
    comment: z.string().min(1, "This field is required"),
  });

export type CommentFormData = z.infer<ReturnType<typeof commentFormSchema>>;
export type OnActionAgreement = (
  data: CommentFormData,
  options: {
    onSuccess: () => void;
  },
) => void;

type Props = {
  textAreaLabel: string;
  buttonActionText: string;
  buttonModalText: string;
  isLoading: boolean;
  onActionAgreement: OnActionAgreement;
};

const ActionAgreementButton = ({
  textAreaLabel,
  buttonActionText,
  buttonModalText,
  isLoading,
  onActionAgreement,
}: Props) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const { handleSubmit, control } = useForm<CommentFormData>({
    resolver: zodResolver(commentFormSchema()),
  });

  const handleActionAgreement = (data: CommentFormData) =>
    onActionAgreement(data, {
      onSuccess: () => setIsConfirmationOpen(false),
    });

  return (
    <>
      <Button
        isDanger
        size="x-small"
        onClick={() => setIsConfirmationOpen(true)}
        role="button"
      >
        {buttonActionText}
      </Button>

      <Modal.Root
        isOpen={isConfirmationOpen}
        size="small"
        onRequestClose={() => setIsConfirmationOpen(false)}
      >
        <Modal.Content>
          <form
            onSubmit={handleSubmit(handleActionAgreement)}
            className="flex flex-col gap-4"
          >
            <Controller
              control={control}
              name="comment"
              render={({ field, fieldState }) => (
                <TextareaField
                  {...field}
                  error={fieldState.error}
                  label={textAreaLabel}
                  cols={40}
                  maxLength={40}
                />
              )}
            />

            <Button type="submit" isLoading={isLoading}>
              {buttonModalText}
            </Button>
          </form>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default ActionAgreementButton;
