import { Button } from "@ag/design-system/atoms";
import I18n from "@ag/i18n";

import { ValidationRequestField } from "../entities/validation-request-field";

type Props = {
  selectedFields: ValidationRequestField[];
  onBulkValidate: () => void;
  onBulkReject: () => void;
};

export const ValidationRequestFieldsActions = ({
  selectedFields,
  onBulkReject,
  onBulkValidate,
}: Props) => {
  return (
    <>
      <Button
        disabled={!selectedFields?.length}
        size="small"
        onClick={() => {
          if (!selectedFields.length) return;
          onBulkValidate();
        }}
        data-testid="validate-button"
      >
        {I18n.t("js.admin.validation_request.fields.validate")}
      </Button>

      <Button
        disabled={!selectedFields?.length}
        size="small"
        isDanger
        onClick={() => {
          if (!selectedFields.length) return;
          onBulkReject();
        }}
        data-testid="reject-button"
      >
        {I18n.t("js.admin.validation_request.fields.reject")}
      </Button>
    </>
  );
};
