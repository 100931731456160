import { z } from "zod";

import {
  FieldActualsExtendedSchema,
  FieldActualsInputSchema,
  FieldActualsInputV2021Schema,
  FieldActualsInputV2022Schema,
  FieldActualsSchema,
  FieldActualsV2021ExtendedSchema,
  FieldActualsV2022ExtendedSchema,
} from "./schemas";

/**
 * ENTITIES
 */
export type FieldActuals = z.infer<typeof FieldActualsSchema>;

export type FieldActualsV2021Extended = z.infer<
  typeof FieldActualsV2021ExtendedSchema
>;

export type FieldActualsV2022Extended = z.infer<
  typeof FieldActualsV2022ExtendedSchema
>;

export type FieldActualsExtended = z.infer<typeof FieldActualsExtendedSchema>;

export type FieldActualsInputV2021 = z.infer<
  typeof FieldActualsInputV2021Schema
>;
export type FieldActualsInputV2022 = z.infer<
  typeof FieldActualsInputV2022Schema
>;

export type FieldActualsInput = z.infer<typeof FieldActualsInputSchema>;

/**
 * QUERY ARGS
 */
export type UseFieldActualsOverviewsQueryArgs<T> = {
  fieldId: number;
  select?: (actualsOverviews: FieldActuals[]) => T;
};

export type UseFieldActualsOverviewQueryArgs = {
  fieldId: number;
  harvestYear: number | null;
};

export type UseFieldActualsDetailsQueryArgs = {
  fieldId: number;
  fieldActualsId: number;
};

/**
 * QUERY FUNCTION ARGS
 */
export type FetchFieldActualsDetailsArgs = {
  fieldId: number;
  fieldActualsId: number;
};

export type UnlockFieldActualsArgs = {
  fieldId: number;
  fieldActualsId: number;
  carbonUserId?: number;
};

/**
 * QUERY FUNCTIONS DATA
 */

/**
 * MUTATION ARGS
 */

/**
 * MUTATION FUNCTIONS DATA
 */

/**
 * MISC
 */
export enum FieldActualsInputType {
  V2021 = "CarbonFieldActualV2021Input",
  V2022 = "CarbonFieldActualV2022Input",
}

export enum FieldActualsStatus {
  NotStarted = "not_started",
  InProgress = "in_progress",
  Completed = "completed",
  Submitted = "submitted",
}
