import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";

import { Icon } from "@ag/design-system/assets";
import { Button, FileRejection, InfoBox } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { DropzoneField, InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { useCreateClientMutation } from "../api/create-client";
import { useCreateDocumentsClientMutation } from "../api/create-documents-client";
import { useDeleteClientMutation } from "../api/delete-client";
import { CreateClient, CreateClientSchema } from "../entities/client";

export const AddClientModal = ({
  isOpen,
  onRequestClose,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
}) => {
  const [fileErrors, setFileErrors] = useState<string[] | undefined>(undefined);
  const [documents, setDocuments] = useState<File[]>([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateClient>({
    resolver: zodResolver(CreateClientSchema),
  });

  const createClientMutation = useCreateClientMutation();
  const createDocumentsClientMutation = useCreateDocumentsClientMutation();
  const deleteClientMutation = useDeleteClientMutation();

  const handleFileDropped = (
    acceptedFiles: File[] | undefined,
    fileRejections: FileRejection[] | undefined,
  ) => {
    setFileErrors(undefined);

    const errors: string[] = [];

    if (fileRejections) {
      fileRejections.forEach(rejection => {
        rejection.errors.forEach(error => {
          if (error.code === "file-too-large") {
            errors.push(
              "File is too large, please upload a file smaller than 5MB",
            );
          } else if (error.code === "file-invalid-type") {
            errors.push("Invalid file type, please upload a valid document");
          } else {
            errors.push(error.message);
          }
        });
      });

      if (errors.length > 0) {
        setFileErrors(errors);
        return;
      }
    }

    const newFiles: File[] = [];
    const existingFileNames = new Set(documents.map(doc => doc.name));

    acceptedFiles?.forEach(file => {
      if (
        !existingFileNames.has(file.name) &&
        documents.length + newFiles.length < 10
      ) {
        newFiles.push(file);
      }
    });

    if (documents.length + newFiles.length > 10) {
      setFileErrors(["You can upload a maximum of 10 documents"]);
      return;
    }

    setDocuments(prevDocuments => [...prevDocuments, ...newFiles]);
  };

  const handleRemoveDocument = (index: number) => {
    setDocuments(prevDocuments => prevDocuments.filter((_, i) => i !== index));
  };

  const onSubmit = async (data: CreateClient) => {
    try {
      // Create the client
      const clientResponse = await createClientMutation.mutateAsync(data);

      if (!clientResponse || !clientResponse.id) {
        throw new Error("Failed to create client: invalid response");
      }

      const clientId = clientResponse.id;

      if (documents.length > 0) {
        try {
          await createDocumentsClientMutation.mutateAsync({
            id: clientId,
            payload: { files: documents },
          });
        } catch (error) {
          // If document upload fails, delete the created client
          await deleteClientMutation.mutateAsync(clientId);
          throw new Error("Failed to create client: error uploading documents");
        }
      }

      ToastNotification.success("Client successfully added");
      onRequestClose();
      setDocuments([]);
      setFileErrors(undefined);
      reset();
    } catch (error) {
      return error;
    }
  };

  const handleOnRequestClose = () => {
    setDocuments([]);
    setFileErrors(undefined);
    reset();
    onRequestClose();
  };

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={handleOnRequestClose}>
      <Modal.Header>
        <Modal.Title>Add new client</Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <InputField
            {...register("name")}
            label="Client Name"
            isRequired
            error={errors.name}
          />

          {fileErrors?.map(fileError => (
            <InfoBox
              key={fileError}
              variant="danger"
              icon="triangle-exclamation"
            >
              {fileError}
            </InfoBox>
          ))}

          <div>
            <DropzoneField
              labelClassName="text-p2 text-grey-700"
              label="Documents"
              onChange={handleFileDropped}
              acceptType={[".pdf", ".doc", ".docx", ".xlsx", ".xls", ".csv"]}
              maxSize={5}
              isMultiple
              value={documents}
              dropText=""
              isDisabled={documents.length >= 10}
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <Icon name="chart-network" className="text-[32px]" />

                <div className="text-center">
                  <span className="text-p2">Drag and drop documents</span>
                  <span className="block font-medium text-grey-800">
                    .pdf, .doc, .docx, .xls, .xlsx
                  </span>
                  <span className="font-bold text-messaging-warning-700">
                    Max 10 documents - 5MB each
                  </span>
                </div>

                <span>or</span>

                <Button
                  type="button"
                  size="small"
                  variant="text"
                  onClick={() => null}
                  disabled={documents.length >= 10}
                >
                  Select from folder
                </Button>
              </div>
            </DropzoneField>

            {documents.length > 0 && (
              <ul className="mt-4">
                {documents.map((doc, index) => (
                  <li key={index} className="flex items-center gap-2">
                    <Icon
                      name="check"
                      className="text-p2 text-messaging-success-700"
                    />
                    <span className="text-p2 font-medium text-grey-700">
                      {doc.name}
                    </span>

                    <Button
                      type="button"
                      size="x-small"
                      variant="text"
                      onClick={() => handleRemoveDocument(index)}
                      isDanger
                    >
                      Remove
                    </Button>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="flex justify-between">
            <Button onClick={handleOnRequestClose} variant="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={(fileErrors?.length ?? 0) > 0 || documents.length > 10}
            >
              Create
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
