import { RowData, Table as TTable } from "@tanstack/react-table";
import clsx from "clsx";

import { Table as DSTable, Pagination } from "@ag/design-system/organisms";
import I18n from "@ag/i18n";
import { Meta } from "@ag/utils/types";

import EmptyTableState from "./empty-table-state";
import * as styles from "./table.css";

type Props<TData extends RowData> = {
  instance: TTable<TData>;
  pagination?: {
    page?: number;
    limit?: number;
  };
  meta: Meta | undefined;
  footerClassName?: string;
  isLoading?: boolean;
  onPaginationChange?: (value: { limit?: number; page?: number }) => void;
};

const Table = <TData extends RowData>({
  instance,
  meta,
  pagination,
  footerClassName,
  isLoading,
  onPaginationChange,
}: Props<TData>) => (
  <>
    <section className={styles.tableWrapper}>
      <DSTable
        instance={instance}
        className={styles.table}
        emptyState={<EmptyTableState />}
        isLoading={isLoading}
      />
    </section>

    {meta && pagination && onPaginationChange && (
      <div className={clsx(styles.footer, footerClassName)}>
        <Pagination
          value={meta}
          limit={pagination.limit}
          infoText={({ itemsRange, total }) =>
            I18n.t("js.shared.items_per_page_total", {
              itemsRange: itemsRange.join(" - "),
              total,
            })
          }
          perPageText={I18n.t("js.shared.items_per_page")}
          onPageChange={page => onPaginationChange({ ...pagination, page })}
          onPerPageChange={limit =>
            onPaginationChange({ ...pagination, limit })
          }
        />
      </div>
    )}
  </>
);

export default Table;
