import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse } from "@ag/utils/types";

import { DocumentationIsoVcuSchema } from "../entities/documentation-iso-vcu";

type Payload = {
  id: string;
  files: File[];
};

const uploadDocumentationIsoVcu = async (payload: Payload) => {
  const { id, files } = payload;

  const form = new FormData();
  files.forEach(file => {
    form.append("file", file);
  });

  const response = await axios.post<
    CommonResponse<Array<DocumentationIsoVcuSchema>>
  >(`/api/v1/verified-credit-units/${id}/upload`, form, {
    apiSource: "node-markets",
  });

  return response.data.success!.data;
};

export const useUploadDocumentationIsoVcuMutation = () =>
  useMutation(uploadDocumentationIsoVcu, {
    onError: error => ToastNotification.error(error),
  });
