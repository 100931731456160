import { z } from "zod";

import { SoilCharacteristicsAttribute } from "./types";

export const SoilCharacteristicsDataSchema = z.object({
  [SoilCharacteristicsAttribute.Som]: z.number().nullable(),
  [SoilCharacteristicsAttribute.Texture]: z.string().nullable(),
  [SoilCharacteristicsAttribute.Ph]: z.string().nullable(),
  [SoilCharacteristicsAttribute.Moisture]: z.string().nullable(),
  [SoilCharacteristicsAttribute.Drainage]: z.string().nullable(),
});
