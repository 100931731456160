import { useCallback, useEffect } from "react";
import { useKey } from "rooks";

import { Icon } from "@ag/design-system/assets";
import { colorsObject } from "@ag/design-system/tokens";
import { cn } from "@ag/design-system/utils";

import IconCut from "~assets/icon-cut";
import IconNavigationArrow from "~assets/icon-navigation-arrow";
import IconPolygon from "~assets/icon-polygon";
import IconSelectionPlus from "~assets/icon-selection-plus";
import { HistoryStack } from "~hooks/use-polygon-paths-history";

import { getActiveVertexPath, getActiveVertexPosition } from "../../helpers";
import { usePolygonBoundariesStore } from "../polygonStore";

type Props = {
  history: HistoryStack;
  onVertexDelete: (path: number) => void;
};

type ButtonProps = {
  icon: JSX.Element;
  text: string;
  variant: "primary" | "secondary" | "text";
  isActive?: boolean;
  onClick?: () => void;
};

const Button = ({
  icon,
  text,
  variant,
  isActive = false,
  onClick,
}: ButtonProps) => {
  const variants = {
    primary: {
      active:
        "bg-messaging-warning-700 text-white-100 border border-solid border-messaging-warning-900",
      inActive:
        "text-messaging-warning-900 bg-[#FDD777] border border-solid border-messaging-warning-900 hover:bg-[#FFE5A4]",
    },
    secondary:
      "bg-messaging-warning-500 text-messaging-error-900 border border-solid border-messaging-error-900 hover:bg-[#FFE5A4]",
    text: "text-messaging-warning-900 bg-messaging-warning-500 hover:bg-[#FFE5A4]",
  };

  const className = cn(
    "text-h5 flex items-center gap-1 rounded px-2 py-1.5",
    typeof variants[variant] === "string"
      ? variants[variant]
      : isActive
        ? (variants[variant] as { active: string; inActive: string }).active
        : (variants[variant] as { active: string; inActive: string }).inActive,
  );

  return (
    <button onClick={onClick} className={className}>
      {icon}
      {text}
    </button>
  );
};

const PolygonEditingToolbar = ({ history, onVertexDelete }: Props) => {
  const {
    innerPaths,
    outerPath,
    drawingManagerInstance,
    editAction,
    activeVertex,
    activeVertices,
    polygonInstance,
    selectMode,
    setOuterPath,
    setInnerPaths,
    setActiveVertex,
    setActiveVertices,
    setEditAction,
    setSelectMode,
  } = usePolygonBoundariesStore();

  const activeVertexPath = outerPath
    ? getActiveVertexPath(activeVertex, outerPath, innerPaths)
    : null;

  const activeVertexPosition = outerPath
    ? getActiveVertexPosition(activeVertex, outerPath, innerPaths)
    : null;

  const isDeleteVertexAvailable = Boolean(
    (activeVertexPosition &&
      activeVertexPath?.length &&
      activeVertexPath.length > 3) ||
      activeVertices.length,
  );

  const handleSelectModeChange = useCallback(() => {
    setActiveVertices([]);
    setActiveVertex(undefined);
    setEditAction("edit");

    const newMode = selectMode === "single" ? "multi" : "single";

    setSelectMode(newMode);
  }, [
    selectMode,
    setEditAction,
    setActiveVertices,
    setActiveVertex,
    setSelectMode,
  ]);

  const handleRightClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      handleSelectModeChange();
    },
    [handleSelectModeChange],
  );

  useEffect(() => {
    document.addEventListener("contextmenu", handleRightClick);

    return () => {
      document.removeEventListener("contextmenu", handleRightClick);
    };
  }, [handleRightClick]);

  const handleCutModeToggleClicked = () => {
    if (editAction === "edit") {
      drawingManagerInstance?.setDrawingMode(
        google.maps.drawing.OverlayType.POLYGON,
      );
      setEditAction("cut");
      setActiveVertex(undefined);
      setActiveVertices([]);
    } else {
      drawingManagerInstance?.setDrawingMode(null);
      setSelectMode("single");
      setEditAction("edit");
    }
  };

  const handleUndoClicked = () => {
    const previousChange = history.pop();

    if (previousChange) {
      const { outerPath, innerPaths } = previousChange;

      setOuterPath(outerPath);
      setInnerPaths(innerPaths);
      setActiveVertex(undefined);
    }
  };

  useKey(["Delete", "Backspace"], () => {
    isDeleteVertexAvailable && handleDeleteVertices();
  });

  const handleDeleteVertices = () => {
    if (!polygonInstance) return;

    if (activeVertex) {
      const { path, vertex } = activeVertex;

      polygonInstance.getPaths().getAt(path).removeAt(vertex);

      onVertexDelete(path);

      setActiveVertex(undefined);
    } else if (activeVertices) {
      // Save the current state before overwriting
      history.push({
        outerPath: [...outerPath],
        innerPaths: innerPaths.map(path => [...path]),
      });

      const updatedOuterPath = outerPath.filter(
        (_, index) =>
          !activeVertices.some(v => v.path === 0 && v.vertex === index),
      );

      const updatedInnerPaths = innerPaths
        .map((path, pathIndex) =>
          path.filter(
            (_, vertexIndex) =>
              !activeVertices.some(
                v => v.path === pathIndex + 1 && v.vertex === vertexIndex,
              ),
          ),
        )
        .filter(path => path.length > 0); // User could delete all vertices in a path

      setOuterPath(updatedOuterPath);
      setInnerPaths(updatedInnerPaths.length > 0 ? updatedInnerPaths : []);
      setActiveVertices([]);
    }
  };

  const handleReshapeModeToggleClicked = () => {
    if (editAction === "edit" || editAction === "cut") {
      drawingManagerInstance?.setDrawingMode(
        google.maps.drawing.OverlayType.POLYLINE,
      );
      setEditAction("reshape");
      setActiveVertex(undefined);
      setActiveVertices([]);
    } else {
      drawingManagerInstance?.setDrawingMode(null);
      setSelectMode("single");
      setEditAction("edit");
    }
  };

  if (!outerPath) return null;

  return (
    <div className="bg-messaging-warning-500 text-messaging-warning-900 absolute flex w-full flex-row justify-between p-1.5">
      <div className="flex gap-2">
        <Button
          icon={
            <IconNavigationArrow
              color={
                selectMode === "single" && editAction === "edit"
                  ? colorsObject.white[100]
                  : colorsObject.messaging.warning[900]
              }
            />
          }
          text="Single select"
          variant="primary"
          isActive={selectMode === "single" && editAction === "edit"}
          onClick={handleSelectModeChange}
        />
        <Button
          icon={
            <IconSelectionPlus
              color={
                selectMode === "multi" && editAction === "edit"
                  ? colorsObject.white[100]
                  : colorsObject.messaging.warning[900]
              }
            />
          }
          text="Multi select"
          variant="primary"
          isActive={selectMode === "multi" && editAction === "edit"}
          onClick={handleSelectModeChange}
        />

        <Button
          icon={
            <IconPolygon
              color={
                editAction === "reshape"
                  ? colorsObject.white[100]
                  : colorsObject.messaging.warning[900]
              }
            />
          }
          text="Draw"
          variant="primary"
          isActive={editAction === "reshape"}
          onClick={handleReshapeModeToggleClicked}
        />

        <Button
          icon={
            <IconCut
              color={
                editAction === "cut"
                  ? colorsObject.white[100]
                  : colorsObject.messaging.warning[900]
              }
            />
          }
          text="Cut"
          isActive={editAction === "cut"}
          variant="primary"
          onClick={handleCutModeToggleClicked}
        />
      </div>
      <div className="flex flex-row gap-2">
        <Button
          icon={
            <Icon
              color={colorsObject.messaging.warning[900]}
              name="arrow-rotate-back"
            />
          }
          text="Undo"
          variant="text"
          onClick={handleUndoClicked}
        />

        <Button
          icon={
            <Icon color={colorsObject.messaging.error[900]} name="trash-can" />
          }
          text="Delete"
          variant="secondary"
          onClick={handleDeleteVertices}
        />
      </div>
    </div>
  );
};

export default PolygonEditingToolbar;
