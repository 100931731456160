import clsx from "clsx";
import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

import { Icon } from "@ag/design-system/assets";

import ImageLogoAgreenaAdmin from "~assets/images/logo/logo-agreena-admin.svg";

import * as styles from "./sidebar.css";

/* -------------------------------------------------------------------------------------------------
 * Sidebar
 * -----------------------------------------------------------------------------------------------*/
type SidebarProps = React.PropsWithChildren<{
  className?: string;
}>;

export const sidebarOpenAtom = atomWithStorage("sidebar_open", true);

const Sidebar = ({ children, className }: SidebarProps) => {
  const [isOpen, setIsOpen] = useAtom(sidebarOpenAtom);

  return (
    <aside className={clsx(styles.root({ isOpen }), className)}>
      <img
        src={ImageLogoAgreenaAdmin}
        width="140"
        className={styles.logo({ isOpen })}
      />

      <button onClick={() => setIsOpen(val => !val)} className={styles.toggle}>
        <Icon name={isOpen ? "chevron-left" : "bars"} />
      </button>

      {children}
    </aside>
  );
};

/* -------------------------------------------------------------------------------------------------
 * SidebarNav
 * -----------------------------------------------------------------------------------------------*/
function SidebarNav({ children }: React.PropsWithChildrenOnly) {
  const [isOpen] = useAtom(sidebarOpenAtom);

  return <nav className={styles.nav({ isOpen })}>{children}</nav>;
}

/* -------------------------------------------------------------------------------------------------
 * SidebarSection
 * -----------------------------------------------------------------------------------------------*/
type SidebarNavSectionProps = React.PropsWithChildren<{
  title: string;
}>;

function SidebarNavSection({ title, children }: SidebarNavSectionProps) {
  return (
    <section className={styles.section}>
      <h3 className={styles.sectionTitle}>{title}</h3>
      <div className={styles.navLinks}>{children}</div>
    </section>
  );
}

/* -------------------------------------------------------------------------------------------------
 * SidebarNavLink
 * -----------------------------------------------------------------------------------------------*/
type SidebarNavLinkProps = (
  | {
      to: RouterLinkProps["to"];
      href?: never;
    }
  | {
      href: string;
      to?: never;
    }
  | {
      href?: never;
      to?: never;
      onClick: () => void;
    }
) &
  React.PropsWithChildrenRequired;

function SidebarNavLink(props: SidebarNavLinkProps) {
  const active =
    Boolean(props.to) &&
    window.location.pathname.startsWith(props.to as string);

  if (props.to) {
    return <RouterLink {...props} className={styles.navLink({ active })} />;
  }

  if (props.href) {
    return (
      <a {...props} className={styles.navLink({ active })} target="_blank" />
    );
  }

  return <button {...props} className={styles.navLink({ active })} />;
}

/* -------------------------------------------------------------------------------------------------
 * SidebarFooter
 * -----------------------------------------------------------------------------------------------*/
function SidebarFooter({ children }: React.PropsWithChildrenOnly) {
  const [isOpen] = useAtom(sidebarOpenAtom);

  return <section className={styles.footer({ isOpen })}>{children}</section>;
}

/* -------------------------------------------------------------------------------------------------
 * SidebarFooterLink
 * -----------------------------------------------------------------------------------------------*/
type SidebarFooterLinkProps = (
  | {
      to: RouterLinkProps["to"];
      href?: never;
      onClick?: never;
    }
  | {
      href: string;
      to?: never;
      onClick?: never;
    }
  | {
      onClick: () => void;
      href?: never;
      to?: never;
    }
) &
  React.PropsWithChildrenRequired;

function SidebarFooterLink(props: SidebarFooterLinkProps) {
  if (props.onClick) {
    return <button {...props} className={styles.footerLink} />;
  }

  if (props.to) {
    return <RouterLink {...props} className={styles.footerLink} />;
  }

  return <a {...props} target="_blank" className={styles.footerLink} />;
}

const Root = Sidebar;
const Nav = SidebarNav;
const NavSection = SidebarNavSection;
const NavLink = SidebarNavLink;
const Footer = SidebarFooter;
const FooterLink = SidebarFooterLink;

export { Root, Nav, NavSection, NavLink, Footer, FooterLink };
