import I18n from "@ag/i18n";
import { ToastNotification } from "@ag/utils/services";

import {
  FieldActualsV2022Input,
  useUpdateFieldActualsMutation,
} from "~features/field";

import CommentModal from "./coment-modal";

export type ActualsData = {
  carbonFieldActualInput?: Partial<FieldActualsV2022Input>;
  fallow?: boolean;
};

type Props = {
  fieldId: string;
  fieldActualsId: string;
  actualsData: ActualsData | null;
  isOpen: boolean;
  onClose: () => void;
};

const EditActualsComment = ({
  fieldId,
  fieldActualsId,
  actualsData,
  isOpen,
  onClose,
}: Props) => {
  const updateFieldActualsMutation = useUpdateFieldActualsMutation();

  const handleAdminSavePartialsWithComment = async (comment: string) => {
    if (!actualsData) return;

    updateFieldActualsMutation.mutate(
      {
        fieldId,
        fieldActualsId,
        carbonFieldActualData: {
          comment: {
            text: comment,
          },
          ...actualsData,
        },
      },
      {
        onSuccess: () => {
          ToastNotification.success(
            I18n.t("js.admin.field_details.field_actuals_updated"),
          );

          onClose();
        },
      },
    );
  };

  return (
    <CommentModal
      isOpen={isOpen}
      isSubmitting={updateFieldActualsMutation.isLoading}
      onClose={onClose}
      onSubmit={handleAdminSavePartialsWithComment}
    />
  );
};

export default EditActualsComment;
