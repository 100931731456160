import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

const generateBuyoutOfferAgreement = async ({ id }: { id: string }) => {
  const response = await axios.post(
    "/api/v1/carbon-sales-agreements",
    { offerId: id },
    { apiSource: "node-carbon" },
  );

  return response.data.success!;
};

export const useGenerateBuyoutOfferAgreement = () =>
  useMutation(generateBuyoutOfferAgreement, {
    onError: error => ToastNotification.error(error),
  });
