import { FormApi } from "final-form";
import omit from "lodash/omit";
import { useCallback, useContext } from "react";
import { withTypes } from "react-final-form";
import { Element } from "react-scroll";

import { PartialBox } from "@ag/carbon/components";
import {
  SoilCultivationPracticeData,
  SoilCultivationPracticeForm,
  SoilCultivationPracticeQuestionnaireData,
  validateSoilCultivationPracticeData,
} from "@ag/carbon/forms/baseline/iso";
import I18n from "@ag/i18n";

import {
  FieldDefinitionPartial,
  FieldPartialProps,
} from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/steps/FieldDefinition/FieldPartials/types";
import FieldDefinitionContext from "~pages/_legacy/carbon/pages/Fields/pages/EditField/FieldContent/steps/FieldDefinition/context";

import { usePartial } from "./hooks";

type Props = FieldPartialProps;

const { Form: FinalForm } =
  withTypes<SoilCultivationPracticeQuestionnaireData>();

const SoilCultivationPractice = ({ field }: Props) => {
  const fieldDefinition = field?.carbonFieldDefinition;

  const { setPartialFormAPI } = useContext(FieldDefinitionContext);

  const { initialValues } = usePartial(fieldDefinition);

  const handleFormInitialized = useCallback(
    (form: FormApi<SoilCultivationPracticeData>) => {
      setPartialFormAPI({
        partialName: FieldDefinitionPartial.SoilCultivationPractice,
        formAPI: form,
      });
    },
    [setPartialFormAPI],
  );

  return (
    <Element name={FieldDefinitionPartial.SoilCultivationPractice}>
      <FinalForm
        initialValues={omit(initialValues?.questionnaire, "version")}
        validate={validateSoilCultivationPracticeData}
        render={() => (
          <PartialBox
            title={I18n.t("js.carbon.field_cultivation_practice")}
            headerTestTag="soilCultivationPractices-collapseBox"
            isOpen
          >
            <SoilCultivationPracticeForm
              isSubmitButtonVisible={false}
              onFormInitialize={handleFormInitialized}
            />
          </PartialBox>
        )}
        onSubmit={() => undefined}
      />
    </Element>
  );
};

export default SoilCultivationPractice;
