import { AgreementType } from "../types/agreement-type";

export const getRecommendedContractNames = (
  contracts: AgreementType[] | undefined,
) => {
  if (!contracts || !contracts.length) {
    return "-";
  }

  return contracts
    .map(
      contract =>
        ({
          [AgreementType.Resale]: "Resale",
          [AgreementType.Buyout]: "Buyout",
        })[contract],
    )
    .join(" - ");
};

const formatNumber = (value: string | null | undefined, unit: string) => {
  const numValue = Number(value);

  if (!numValue || isNaN(numValue)) {
    return "N/A";
  }

  return `${numValue} ${unit}`;
};

export const formatCurrency = (value: string | null | undefined) =>
  formatNumber(value, "€");

export const formatEstimationsValue = (value: string | null | undefined) =>
  formatNumber(value, "t/CO₂e");
