import { CellContext } from "@tanstack/react-table";
import { useState } from "react";

import { Button, Chip } from "~atoms";
import { Modal } from "~organisms";

type ChipConfig = {
  label: string | number;
  variant?: "success" | "warning" | "neutral" | "info" | "danger";
};

export type ChipCellValue =
  | string
  | number
  | ChipConfig
  | (string | number | ChipConfig)[]
  | null
  | undefined;

export const ChipCell = <TValue extends ChipCellValue>({
  getValue,
  table,
}: CellContext<any, TValue>) => {
  const [isAllValuesModalOpen, setIsAllValuesModalOpen] = useState(false);

  const value = getValue();
  const texts = table.options.meta?.texts;

  if (!texts?.listOfAllValues || !texts?.more || !texts?.close) {
    throw new Error("Please provide table texts when using ChipCell");
  }

  if (value === null || value === undefined) return "-";

  const DEFAULT_VARIANT = "info";

  // Always wrap value to have an array to work with
  const chips = (Array.isArray(value) ? value : [value]).filter(Boolean) as (
    | string
    | number
    | ChipConfig
  )[];

  if (!chips.length) return "-";

  const hasMore = chips.length > 3;

  return (
    <>
      <div className="flex flex-col items-start gap-2">
        <div className="flex gap-2">
          {chips.slice(0, 3).map(chip => {
            if (typeof chip === "string" || typeof chip === "number") {
              return (
                <Chip key={chip} variant={DEFAULT_VARIANT}>
                  {chip}
                </Chip>
              );
            }

            return (
              <Chip key={chip.label} variant={chip.variant || DEFAULT_VARIANT}>
                {chip.label}
              </Chip>
            );
          })}
        </div>

        {hasMore && (
          <Button
            size="x-small"
            variant="text"
            onClick={() => setIsAllValuesModalOpen(true)}
          >
            +{chips.length - 3} {texts?.more}
          </Button>
        )}
      </div>

      <Modal.Root
        isOpen={isAllValuesModalOpen}
        onRequestClose={() => setIsAllValuesModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>{texts?.listOfAllValues}</Modal.Title>
        </Modal.Header>

        <Modal.Content>
          <div className="flex flex-wrap gap-2">
            {chips.map(chip => {
              if (typeof chip === "string" || typeof chip === "number") {
                return (
                  <Chip key={chip} variant={DEFAULT_VARIANT}>
                    {chip}
                  </Chip>
                );
              }

              return (
                <Chip
                  key={chip.label}
                  variant={chip.variant || DEFAULT_VARIANT}
                >
                  {chip.label}
                </Chip>
              );
            })}
          </div>
        </Modal.Content>
        <Modal.Footer
          render={onClose => (
            <Button variant="secondary" onClick={onClose}>
              {texts?.close}
            </Button>
          )}
        />
      </Modal.Root>
    </>
  );
};
