import { FieldRenderProps } from "react-final-form";

import { Textarea, TextareaCommonProps } from "~Textarea";

type Props = FieldRenderProps<string | number> & TextareaCommonProps;

export const FormTextarea = ({ input, meta, ...rest }: Props) => (
  <Textarea
    value={input.value}
    error={meta.touched ? meta.error : null}
    onChange={input.onChange}
    {...rest}
  />
);
