import I18n from "@ag/i18n";

import {
  ContractStatus,
  ContractType,
  LockInPeriod,
  Payouts,
  Sanctions,
} from "../entities/contract";

export const getContractTypeLabel = (contractType: ContractType) => {
  const lookup: Record<ContractType, string> = {
    vesting: I18n.t("js.admin.contracts.contract_type.vesting"),
    fixed: I18n.t("js.admin.contracts.contract_type.fixed"),
    v2022: I18n.t("js.admin.contracts.contract_type.v2022"),
    "novation-v2022": I18n.t("js.admin.contracts.contract_type.novation_v2022"),
  };

  return lookup[contractType] ?? contractType.toString();
};

export const getContractStatusLabel = (contractStatus: ContractStatus) => {
  const lookup: Record<ContractStatus, string> = {
    signed: I18n.t("js.admin.contracts.status.signed"),
    sent: I18n.t("js.admin.contracts.status.sent"),
    withdrawn: I18n.t("js.admin.contracts.status.withdrawn"),
    expired: I18n.t("js.contracts.status.expired"),
    terminated: I18n.t("js.contracts.status.terminated"),
  };

  return lookup[contractStatus] ?? contractStatus.toString();
};

export const getLockInPeriodLabel = (lockInPeriod: LockInPeriod) => {
  const lookup: Record<LockInPeriod, string> = {
    year_to_year: I18n.t("js.admin.contracts.lock_in_period.year_to_year"),
  };

  return lookup[lockInPeriod] ?? lockInPeriod.toString();
};

export const getPayoutsLabel = (payouts: Payouts) => {
  const lookup: Record<Payouts, string> = {
    vesting_5_years__10_15_20_25_30: I18n.t(
      "js.admin.contracts.payouts.vesting_5_years__10_15_20_25_30",
    ),
    year_to_year_100_percent: I18n.t(
      "js.admin.contracts.payouts.year_to_year_100_percent",
    ),
  };

  return lookup[payouts] ?? payouts.toString();
};

export const getSanctionsLabel = (sanctions: Sanctions) => {
  const lookup: Record<Sanctions, string> = {
    repayment_of_up_to_three_years: I18n.t(
      "js.admin.contracts.sanctions.repayment_of_up_to_three_years",
    ),
    we_retain_future_payouts_for_all_years: I18n.t(
      "js.admin.contracts.sanctions.we_retain_future_payouts_for_all_years",
    ),
  };

  return lookup[sanctions] ?? sanctions.toString();
};
