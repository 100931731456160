import { useCallback } from "react";

import {
  getIsPathPointsInsidePolygon,
  getIsPolygonIntersectingOtherPolygons,
  isAnyOfPathPointsInsidePolygon,
  isPolygonSelfIntersecting,
} from "@ag/map/helpers";

import { HistoryStack } from "~hooks/use-multi-polygon-paths-history";

import { useMultiPolygonBoundariesStore } from "./multiPolygonStore";

type MultiPolygonDrawingManagerHandlersArgs = {
  history: HistoryStack;
  onError: (error: string) => void;
};

export const useMultiPolygonDrawingManagerHandlers = ({
  history,
  onError,
}: MultiPolygonDrawingManagerHandlersArgs) => {
  const {
    drawingManagerInstance,
    outerPath,
    innerPaths,
    addDrawingManagerInstance,
    setInnerPaths,
    setEditMode,
  } = useMultiPolygonBoundariesStore();

  const handleLoad = useCallback(
    (polygonId: number, drawingManager: google.maps.drawing.DrawingManager) => {
      addDrawingManagerInstance(polygonId, drawingManager);
    },
    [addDrawingManagerInstance],
  );

  const handlePolygonComplete = useCallback(
    (polygonId: number, polygon: google.maps.Polygon) => {
      if (!drawingManagerInstance[polygonId] || !outerPath) return;

      const newPolygonPath: google.maps.LatLng[] = polygon.getPath().getArray();

      // Check if new polygon is inside outer polygon
      const isNewPolygonPathsPointsInsideOuterPolygon =
        getIsPathPointsInsidePolygon(
          newPolygonPath,
          new google.maps.Polygon({
            paths: outerPath[polygonId],
          }),
        );

      if (!isNewPolygonPathsPointsInsideOuterPolygon) {
        onError("New polygon must be inside outer polygon");

        // Remove polygon from map
        polygon.setMap(null);

        return;
      }

      // Check if new polygon isn't intersecting with other inner polygons
      const isNewPolygonPathsPointsInsideOtherInnerPolygons = innerPaths[
        polygonId
      ]?.every(
        innerPath =>
          !isAnyOfPathPointsInsidePolygon(
            newPolygonPath,
            new google.maps.Polygon({
              paths: innerPath,
            }),
          ),
      );

      if (!isNewPolygonPathsPointsInsideOtherInnerPolygons) {
        onError("New polygon must not intersect with other inner polygons");

        // Remove polygon from map
        polygon.setMap(null);

        return;
      }

      // Check if new polygon isn't self intersecting
      const isNewPolygonSelfIntersecting = isPolygonSelfIntersecting(polygon);

      if (isNewPolygonSelfIntersecting) {
        onError("New polygon must not self intersect");

        // Remove polygon from map
        polygon.setMap(null);

        return;
      }

      // Check if new polygon isn't intersecting others polygons
      const isNewPolygonIntersectingOthers =
        getIsPolygonIntersectingOtherPolygons(
          polygon,
          innerPaths[polygonId].map(
            innerPath =>
              new google.maps.Polygon({
                paths: innerPath,
              }),
          ),
        );

      if (isNewPolygonIntersectingOthers) {
        onError("New polygon must not intersect other polygons");

        // Remove polygon from map
        polygon.setMap(null);

        return;
      }

      // Signed area may be used to determine the orientation of the path
      const outerPolygonSignedArea =
        google.maps.geometry.spherical.computeSignedArea(outerPath[polygonId]);
      const isOuterPolygonClockwise = outerPolygonSignedArea > 0;

      // Signed area may be used to determine the orientation of the path
      const newPolygonSignedArea =
        google.maps.geometry.spherical.computeSignedArea(newPolygonPath);
      const isNewPolygonPathClockwise = newPolygonSignedArea > 0;

      // Path has to be in reversed order to outer polygon
      const reversedNewPolygonPath =
        isOuterPolygonClockwise === isNewPolygonPathClockwise
          ? newPolygonPath.reverse()
          : newPolygonPath;

      const newInnerPaths = [...innerPaths];
      newInnerPaths[polygonId] = [
        ...newInnerPaths[polygonId],
        reversedNewPolygonPath,
      ];

      setInnerPaths(newInnerPaths);

      history.push({
        polygonId,
        outerPath,
        innerPaths,
      });

      // Remove polygon from map after adding it to inner coordinates
      polygon.setMap(null);

      // Return to default mode
      drawingManagerInstance[polygonId].setDrawingMode(null);
      setEditMode("editing");
    },
    [
      drawingManagerInstance,
      history,
      innerPaths,
      outerPath,
      onError,
      setEditMode,
      setInnerPaths,
    ],
  );

  return {
    handleLoad,
    handlePolygonComplete,
  };
};
