import { createColumnHelper, getCoreRowModel } from "@tanstack/react-table";
import { useMemo } from "react";

import {
  ButtonCell,
  ButtonCellValue,
  ChipCell,
  ChipCellValue,
  DateCell,
  DateCellConfig,
  LinkCell,
  LinkCellValue,
  TableRowError,
  TextCell,
  TextCellValue,
} from "@ag/design-system/organisms";

import { useList } from "~components/list";
import { TableSortingState } from "~components/table";

import { InventoryImport } from "../entities/inventory-import";
import { getStatusChip } from "../helpers/import-statuses";

type ListData = {
  id: string;
  adminId?: LinkCellValue | TextCellValue;
  filename: string | null | undefined;
  status: ChipCellValue;
  createdAt: DateCellConfig | null;
  processedAt: DateCellConfig | null;
  startedProcessingAt: DateCellConfig | null;
  downloadLink: ButtonCellValue;

  import: InventoryImport;
};

export const useDiscardedInventoryImportsTable = (
  data: InventoryImport[] | undefined,
  state: TableSortingState,
  hasAdminAccess: boolean,
) => {
  const columns = useMemo(() => getColumns(hasAdminAccess), [hasAdminAccess]);
  const rowData = useMemo(
    () => getRowData(data, hasAdminAccess),
    [data, hasAdminAccess],
  );

  const errors = data?.reduce(
    (acc, item) => {
      if (item.errors.length > 0) {
        acc[item.id] = item.errors.map(error => new TableRowError(error));
      }
      return acc;
    },
    {} as Record<string, TableRowError<ListData>[]>,
  );

  return useList<ListData>({
    columns,
    data: rowData,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id,
    meta: {
      errors,
    },
    state,
    onSortingChange: state.setSorting,
  });

  function getColumns(hasAdminAccess: boolean) {
    const columnHelper = createColumnHelper<ListData>();

    return [
      columnHelper.accessor("id", {
        header: "Import ID",
      }),
      columnHelper.accessor("adminId", {
        header: "Admin ID",
        cell: hasAdminAccess ? LinkCell : TextCell,
      }),
      columnHelper.accessor("filename", {
        header: "File name",
        maxSize: 200,
      }),

      columnHelper.accessor("status", {
        header: "Status",
        cell: ChipCell,
      }),
      columnHelper.accessor("createdAt", {
        header: "Created",
        cell: DateCell,
        enableSorting: true,
      }),
      columnHelper.accessor("startedProcessingAt", {
        header: "Started processing",
        cell: DateCell,
      }),
      columnHelper.accessor("processedAt", {
        header: "Processed",
        cell: DateCell,
      }),
      columnHelper.accessor("downloadLink", {
        header: "Download",
        cell: ButtonCell,
        maxSize: 300,
      }),
    ];
  }

  function getRowData(
    data: InventoryImport[] | undefined,
    hasAdminAccess: boolean,
  ): ListData[] {
    if (!data) return [];

    return data.map(importData => ({
      id: importData.id,
      filename: importData.filename,
      adminId: hasAdminAccess
        ? {
            title: importData.adminId,
            url: `/admins/${importData.adminId}`,
          }
        : importData.adminId,
      status: importData.status ? getStatusChip(importData.status) : null,
      createdAt: { value: importData.createdAt, format: "datetime" },
      processedAt: { value: importData.processedAt, format: "datetime" },
      startedProcessingAt: {
        value: importData.startedProcessingAt,
        format: "datetime",
      },
      downloadLink: {
        children: "Download file",
        iconPosition: "before",
        icon: "download",
        href: `/file?${new URLSearchParams({
          url: importData.fileDownloadUrl || "",
          apiSource: "node-markets",
        })}`,
      },

      import: importData,
    }));
  }
};
