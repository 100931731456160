import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { z } from "zod";

import "@ag/design-system/organisms";

import { Crop, CropSchema } from "../entities/crop";

export const generateCropsQueryKey = () => ["crop-types"].filter(Boolean);

const getCrops = async () => {
  const response = await axios.get<Crop[]>("/v1/crops", {
    schema: z.array(CropSchema),
    apiSource: "crops-fertilisers",
  });

  return response.data;
};

export const useCropsQuery = () =>
  useQuery(generateCropsQueryKey(), () => getCrops(), {
    keepPreviousData: true,
  });
