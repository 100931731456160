import pick from "lodash/pick";

import { CultivationStrategyAttribute } from "@ag/carbon/forms/inputs-v2022";

import { FieldActualsV2022Input } from "~features/field";
import { FieldActualsInputV2022 } from "~queries/carbon/field-actuals";

export const getInitialValues = (fieldActualsInput: FieldActualsV2022Input) => {
  const cultivationStrategyData = pick<
    FieldActualsInputV2022,
    CultivationStrategyAttribute
  >(fieldActualsInput, Object.values(CultivationStrategyAttribute));

  return {
    ...cultivationStrategyData,
    carbonCropTypeId: String(cultivationStrategyData.carbonCropTypeId),
    tillingRate: String(cultivationStrategyData.tillingRate),
  };
};
