import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { useCreateFertiliserMutation } from "../api/create-fertiliser";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const NewFertiliserSchema = z.object({
  name: z.string().min(1, "Fertiliser name is required"),
  legacyIdentifier: z.string().optional(),
  legacyEnumValue: z.string().optional(),
});

type NewFertiliser = z.infer<typeof NewFertiliserSchema>;

const AddNewFertiliserModal = ({ isOpen, onClose }: Props) => {
  const createFertiliser = useCreateFertiliserMutation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<NewFertiliser>({
    resolver: zodResolver(NewFertiliserSchema),
  });

  const handleNewFertiliserSubmit = (formData: NewFertiliser) => {
    createFertiliser.mutate(
      {
        name: formData.name,
        legacy_identifier: formData.legacyIdentifier,
        legacy_enum_value: formData.legacyEnumValue,
      },
      {
        onSuccess: () => {
          ToastNotification.success(`New fertiliser ${formData.name} added`);
          onClose();
        },
      },
    );
  };

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={onClose}>
      <Modal.Header>
        <Modal.Title>Add a new fertiliser:</Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <form
          onSubmit={handleSubmit(handleNewFertiliserSubmit)}
          className="flex flex-col gap-4"
        >
          <InputField
            {...register("name")}
            label="Fertiliser Name"
            error={errors.name}
            isRequired
          />
          <InputField
            {...register("legacyIdentifier")}
            label="Legacy Identifier (optional)"
            error={errors.legacyIdentifier}
          />
          <InputField
            {...register("legacyEnumValue")}
            label="Legacy Enum Value (optional)"
            error={errors.legacyEnumValue}
          />
          <Button type="submit" className="self-end">
            Add fertiliser
          </Button>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};

export default AddNewFertiliserModal;
