import { z } from "zod";

const CropCountryPropertiesSchema = z.object({
  seeding_period_start: z.string(),
  seeding_period_end: z.string(),
  "yield avg": z.number(),
  "yield max": z.number(),
  "yield min": z.number(),
  harvest_period_start: z.string(),
  harvest_period_end: z.string(),
});
export const CropCountryDetailsSchema = z.object({
  crop_id: z.string(),
  harvest_year: z.string(),
  country: z.string(),
  properties: CropCountryPropertiesSchema,
});

export type CropCountryDetails = z.infer<typeof CropCountryDetailsSchema>;
export type CropCountryProperties = z.infer<typeof CropCountryPropertiesSchema>;
