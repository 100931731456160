import { create } from "zustand";

import { CertificateGroupMethodology } from "~features/certificate";

export type DocumentationFile = {
  id: string;
  filename: string;
};

type IsoVcuDetails = {
  quantity: number | undefined;
  vintageYear: number;
  methodology: string;
};

type ImportVcuData = {
  id: string | undefined;
  isoVcuDetails: IsoVcuDetails;
  distributionFile: File | undefined;
  documentation: DocumentationFile[];
  isFormValid: boolean;

  setId: (id: string | undefined) => void;
  setIsoVcuDetails: (details: IsoVcuDetails) => void;
  setDistributionFile: (file: File | undefined) => void;
  setIsFormValid: (isValid: boolean) => void;
  setDocumentation: (files: DocumentationFile[]) => void;
  removeDocumentation: (fileToRemove: DocumentationFile) => void;
  resetState: () => void;
};

export const useImportIsoVcuStore = create<ImportVcuData>(set => ({
  id: undefined,
  isoVcuDetails: {
    quantity: undefined,
    vintageYear: 2023,
    methodology: CertificateGroupMethodology.ISO140642,
  },
  distributionFile: undefined,
  documentation: [],
  isFormValid: false,

  setId: id => {
    set({ id });
  },
  setIsoVcuDetails: details => {
    set({ isoVcuDetails: details });
  },
  setDistributionFile: file => {
    set({ distributionFile: file });
  },
  setIsFormValid: isValid => {
    set({ isFormValid: isValid });
  },
  setDocumentation: (files: DocumentationFile[] = []) => {
    set(state => ({
      ...state,
      documentation: files.length > 0 ? [...state.documentation, ...files] : [],
    }));
  },
  removeDocumentation: fileToRemove => {
    set(state => ({
      documentation: state.documentation.filter(file => file !== fileToRemove),
    }));
  },
  resetState: () => {
    set({
      id: undefined,
      isoVcuDetails: {
        quantity: undefined,
        vintageYear: 2023,
        methodology: CertificateGroupMethodology.ISO140642,
      },
      distributionFile: undefined,
      documentation: [],
      isFormValid: false,
    });
  },
}));
