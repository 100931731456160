import {
  OnChangeFn,
  RowSelectionState,
  TableMeta,
  createColumnHelper,
} from "@tanstack/react-table";
import { isEmpty, last } from "lodash";
import { useMemo } from "react";

import {
  ActionsCell,
  ActionsCellValue,
  BooleanCellValue,
  HeaderSelectionCell,
  LinkCell,
  LinkCellValue,
  SelectionCell,
  TableState,
  TextCellValue,
} from "@ag/design-system/organisms";
import I18n from "@ag/i18n";

import { RawDataCell } from "~components/RawDataCell";
import { CoverCropHbCell } from "~components/cover-crop-hb-cell";
import { useTable } from "~components/table";
import {
  CarbonAbility,
  CarbonPermissions,
  CarbonResourceClass,
} from "~features/permission";

import {
  Assurance,
  AssuranceCategory,
  AssuranceCheck,
  AssuranceCheckKey,
} from "../entities/assurance";
import { Field } from "../entities/field";
import { getAssuranceStatusLabel } from "../helpers/get-labels";

export type TableData = {
  farmerName: TextCellValue;
  fieldId: LinkCellValue;
  hbPercentage: TextCellValue;
  country: TextCellValue;
  userId: LinkCellValue;
  farmName: TextCellValue;
  baseLine: TextCellValue;
  status: TextCellValue;
  lastQaStatusChangedBy: TextCellValue;
  comment: BooleanCellValue;
  actions: ActionsCellValue;

  /**
   * Checks/Assurance columns
   *
   * Each column has corresponding meta value set on the row data,
   * used to set the cellBackground flag color.
   */
  duplication: string | undefined;
  duplicationAssurance: Assurance | undefined;

  fieldSize: string | undefined;
  fieldSizeAssurance: Assurance | undefined;

  farmSize: string | undefined;
  farmSizeCheck: AssuranceCheck | undefined;

  deforestation: string | undefined;
  deforestationAssurance: Assurance | undefined;

  soilDisturbance: string | undefined;
  soilDisturbanceAssurance: Assurance | undefined;

  soilDisturbanceHb: string | undefined;
  soilDisturbanceHbCheck: AssuranceCheck | undefined;

  coverCrop: string | undefined;
  coverCropAssurance: Assurance | undefined;

  coverCropHb: string | undefined;
  coverCropHbCheck: AssuranceCheck | undefined;

  intersection: string | undefined;
  intersectionAssurance: Assurance | undefined;

  intersectionHectares: string | undefined;
  intersectionHectaresCheck: AssuranceCheck | undefined;

  intersectionSum: string | undefined;
  intersectionSumCheck: AssuranceCheck | undefined;

  conflictingFields: LinkCellValue | undefined;
  conflictingFieldsCheck: AssuranceCheck | undefined;

  // We need to keep a reference to the field for bulk actions.
  field: Field;
};

type TableActions = {
  onShowDefinitionChanges: (params: { fieldId: string }) => void;
  onShowComments: (params: { selection: Record<string, boolean> }) => void;
};

type TableOptions = {
  harvestYear: number | undefined;
  permissions: CarbonPermissions | undefined;
  errors: TableMeta<TableData>["errors"];
  state?: TableState<TableData>;
  onSelectionChange: OnChangeFn<RowSelectionState>;
} & TableActions;

export const INITIAL_ASSURANCE_TABLE_STATE: TableState<TableData> = {
  columnPinning: {
    left: ["select", "userId", "fieldId"],
    right: ["actions"],
  },
  rowSelection: {},
};

export const useFieldsTable = ({
  data,
  permissions,
  harvestYear,
  state,
  errors,
  onShowComments,
  onShowDefinitionChanges,
  onSelectionChange,
}: {
  data: Field[] | undefined;
} & TableOptions) => {
  const columns = useMemo(() => getColumns(), []);
  const tableData = useMemo(
    () =>
      getRowData(data, {
        permissions,
        harvestYear,
        onShowComments,
        onShowDefinitionChanges,
      }),
    [data, harvestYear, onShowComments, onShowDefinitionChanges, permissions],
  );

  return useTable<TableData>({
    columns,
    data: tableData,
    getRowId: original => original.field.id.toString(),
    meta: {
      errors,
      isUsingCellBackgrounds: true,
    },
    state: state ?? INITIAL_ASSURANCE_TABLE_STATE,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onRowSelectionChange: onSelectionChange,
  });
};

function getColumns() {
  const columnHelper = createColumnHelper<TableData>();

  return [
    columnHelper.display({
      id: "select",
      header: HeaderSelectionCell,
      cell: SelectionCell,
      enablePinning: true,
      size: 48,
    }),

    columnHelper.accessor("userId", {
      cell: LinkCell,
      header: I18n.t("js.admin.assurance.list.user_id"),
      enablePinning: true,
      size: 80,
    }),

    columnHelper.accessor("fieldId", {
      cell: LinkCell,
      header: I18n.t("js.admin.assurance.list.field_id"),
      enablePinning: true,
      size: 80,
    }),

    columnHelper.accessor("country", {
      header: I18n.t("js.admin.assurance.list.country"),
    }),

    columnHelper.accessor("farmerName", {
      header: I18n.t("js.admin.assurance.list.farmer_name"),
    }),

    columnHelper.accessor("farmName", {
      header: I18n.t("js.admin.assurance.list.farm_name"),
      enableResizing: true,
      size: 80,
    }),

    columnHelper.accessor("baseLine", {
      header: I18n.t("js.admin.assurance.list.baseline"),
    }),

    columnHelper.accessor("hbPercentage", {
      header: I18n.t("js.admin.assurance.list.hb_percentage"),
      enablePinning: true,
      size: 80,
    }),

    columnHelper.accessor("duplication", {
      header: I18n.t("js.admin.assurance.list.duplication"),
      enableResizing: true,
      meta: {
        cellBackground: (_value, rowData) => rowData.duplicationAssurance?.flag,
      },
      size: 120,
    }),

    columnHelper.group({
      id: "fieldSize",

      columns: [
        columnHelper.accessor("fieldSize", {
          header: I18n.t("js.admin.assurance.list.field_size"),
          cell: RawDataCell,
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.fieldSizeAssurance?.flag,
          },
        }),

        columnHelper.accessor("farmSize", {
          header: I18n.t("js.admin.assurance.list.farm_size"),
          cell: RawDataCell,
          meta: {
            cellBackground: (_value, rowData) => rowData.farmSizeCheck?.flag,
          },
        }),
      ],
    }),

    columnHelper.group({
      id: "IoU",

      columns: [
        columnHelper.accessor("intersection", {
          header: I18n.t("js.admin.assurance.list.intersection_percentage"),
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.intersectionAssurance?.flag,
          },
        }),
        columnHelper.accessor("intersectionHectares", {
          header: I18n.t("js.admin.assurance.list.intersection_hectares"),
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.intersectionHectaresCheck?.flag,
          },
        }),
        columnHelper.accessor("intersectionSum", {
          header: I18n.t("js.admin.assurance.list.intersection_sum"),
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.intersectionSumCheck?.flag,
          },
        }),
        columnHelper.accessor("conflictingFields", {
          header: I18n.t("js.admin.assurance.list.conflicting_fields"),
          cell: LinkCell,
        }),
      ],
    }),

    columnHelper.accessor("deforestation", {
      header: I18n.t("js.admin.assurance.list.deforestation"),
      meta: {
        cellBackground: (_value, rowData) =>
          rowData.deforestationAssurance?.flag,
      },
    }),

    columnHelper.group({
      id: "soilDisturbance",
      columns: [
        columnHelper.accessor("soilDisturbance", {
          header: I18n.t("js.admin.assurance.list.soil_disturbance"),
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.soilDisturbanceAssurance?.flag,
          },
        }),

        columnHelper.accessor("soilDisturbanceHb", {
          header: I18n.t("js.admin.assurance.list.soil_disturbance_hb"),
          cell: RawDataCell,
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.soilDisturbanceHbCheck?.flag,
          },
        }),
      ],
    }),

    columnHelper.group({
      id: "coverCrop",
      columns: [
        columnHelper.accessor("coverCrop", {
          header: I18n.t("js.admin.assurance.list.cover_crop"),
          meta: {
            cellBackground: (_value, rowData) =>
              rowData.coverCropAssurance?.flag,
          },
        }),

        columnHelper.accessor("coverCropHb", {
          header: I18n.t("js.admin.assurance.list.cover_crop_hb"),
          cell: CoverCropHbCell,
          meta: {
            cellBackground: (_value, rowData) => rowData.coverCropHbCheck?.flag,
          },
          maxSize: 2000,
        }),
      ],
    }),

    columnHelper.accessor("status", {
      header: I18n.t("js.admin.assurance.list.status"),
      enablePinning: true,
      size: 160,
    }),

    columnHelper.accessor("lastQaStatusChangedBy", {
      header: I18n.t("js.admin.assurance.list.last_changed_by"),
      enableResizing: true,
      size: 120,
    }),

    columnHelper.accessor("comment", {
      header: I18n.t("js.admin.assurance.list.comment"),
    }),

    columnHelper.accessor("actions", {
      header: I18n.t("js.shared.actions"),
      enablePinning: true,
      cell: ActionsCell,
      size: 128,
    }),
  ];
}

function getRowData(
  fields: Field[] | undefined,
  options: Pick<
    TableOptions,
    "harvestYear" | "permissions" | "onShowComments" | "onShowDefinitionChanges"
  >,
): TableData[] {
  if (!fields) return [];

  return fields.map(field => {
    const duplicationAssurance = field.carbonQualityAssurances.find(
      ({ category, year }) =>
        category === AssuranceCategory.Duplication &&
        year === options.harvestYear,
    );
    const fieldLocationCheck = duplicationAssurance?.checks.find(
      ({ key }) => key === AssuranceCheckKey.FieldLocation,
    );

    const fieldSizeAssurance = field.carbonQualityAssurances.find(
      ({ category, year }) =>
        category === AssuranceCategory.FieldSize &&
        year === options.harvestYear,
    );

    const farmSizeCheck = fieldSizeAssurance?.checks.find(
      ({ key }) => key === AssuranceCheckKey.FarmSize,
    );

    // Deforestation verification
    const deforestationAssurance = field.carbonQualityAssurances.find(
      ({ category, year }) =>
        category === AssuranceCategory.Deforestation &&
        year === options.harvestYear,
    );

    const lastAssuranceAction = last(field.carbonQualityAssuranceActions);

    // Intersection over union verification
    const intersectionAssurance = field.carbonQualityAssurances.find(
      ({ category, year }) =>
        category === AssuranceCategory.IntersectionOverUnion &&
        year === options.harvestYear,
    );

    const intersectionHectaresCheck = intersectionAssurance?.checks.find(
      ({ key }) => key === AssuranceCheckKey.IntersectionHectares,
    );

    const intersectionSumCheck = intersectionAssurance?.checks.find(
      ({ key }) => key === AssuranceCheckKey.IntersectionSum,
    );

    const conflictingFieldsCheck = intersectionAssurance?.checks.find(
      ({ key }) => key === AssuranceCheckKey.ConflictingFields,
    );

    // Soil disturbance
    const soilDisturbanceAssurance = field.carbonQualityAssurances.find(
      ({ category, year }) =>
        category === AssuranceCategory.SoilDisturbance &&
        year === options.harvestYear,
    );
    const soilDisturbanceHBCheck = soilDisturbanceAssurance?.checks.find(
      ({ key }) => key === AssuranceCheckKey.SoilDisturbanceHB,
    );

    // Cover crop
    const coverCropAssurance = field.carbonQualityAssurances.find(
      ({ category, year }) =>
        category === AssuranceCategory.CoverCrop &&
        year === options.harvestYear,
    );
    const coverCropHBCheck = coverCropAssurance?.checks.find(
      ({ key }) => key === AssuranceCheckKey.CoverCropHB,
    );

    return {
      userId: {
        url: `/users/${field.user.id}`,
        title: field.user.id,
      },
      fieldId: {
        url: `/carbon/fields/${field.id}`,
        title: field.id,
      },
      farmName: field.carbonFarm.name,
      baseLine: Number(field.carbonFieldDefinition.baselineVersion),
      farmerName: `${field.user.firstName} ${field.user.lastName}`,
      hbPercentage: `${Number(field.hummingbirdDataAvailability) * 100}%`,
      country: field.carbonCountry.name,
      status: getAssuranceStatusLabel(field.qaStatus),

      /* VERIFICATIONS */
      duplication: formatAssuranceValue(duplicationAssurance?.value),
      duplicationAssurance: duplicationAssurance,

      fieldLocation: formatAssuranceValue(fieldLocationCheck?.value),
      fieldLocationCheck: fieldLocationCheck,

      fieldSize: formatFieldSize(fieldSizeAssurance?.valueJson),
      fieldSizeAssurance: fieldSizeAssurance,

      farmSize: formatFarmSize(farmSizeCheck?.valueJson),
      farmSizeCheck: farmSizeCheck,

      deforestation: formatAssuranceValue(deforestationAssurance?.value),
      deforestationAssurance: deforestationAssurance,

      soilDisturbance: formatAssuranceValue(soilDisturbanceAssurance?.value),
      soilDisturbanceAssurance: soilDisturbanceAssurance,

      soilDisturbanceHb: formatAssuranceValue(soilDisturbanceHBCheck?.value),
      soilDisturbanceHbCheck: soilDisturbanceHBCheck,

      coverCrop: formatAssuranceValue(coverCropAssurance?.value),
      coverCropAssurance: coverCropAssurance,

      coverCropHb: formatAssuranceValue(coverCropHBCheck?.value),
      coverCropHbCheck: coverCropHBCheck,

      intersection: formatAssuranceValue(intersectionAssurance?.value),
      intersectionAssurance,

      intersectionHectares: formatAssuranceValue(
        intersectionHectaresCheck?.value,
      ),
      intersectionHectaresCheck,

      intersectionSum: formatAssuranceValue(intersectionSumCheck?.value),
      intersectionSumCheck,

      conflictingFields: conflictingFieldsCheck?.value
        ? (JSON.parse(conflictingFieldsCheck.value as string) as number[]).map(
            id => ({
              url: `/carbon/fields/${id}`,
              title: id,
            }),
          )
        : [],
      conflictingFieldsCheck,

      lastQaStatusChangedBy: lastAssuranceAction?.admin.email,

      comment: !isEmpty(field.carbonQualityAssuranceComments),

      actions: {
        items: [
          {
            children: I18n.t(
              "js.admin.assurance.list.show_field_definition_changes",
            ),
            onClick: () =>
              options.onShowDefinitionChanges({ fieldId: field.id }),
          },
          {
            children: I18n.t("js.admin.assurance.list.show_comments"),
            onClick: () =>
              options.onShowComments({ selection: { [field.id]: true } }),
          },
          {
            children: I18n.t("js.admin.assurance.list.hubspot_contact"),
            href: field.user.hubspotContactUrl,
          },

          field.qaStatus === "non_conformance" &&
          options.permissions?.[CarbonAbility.Update]?.includes(
            CarbonResourceClass.User,
          )
            ? {
                children: I18n.t("js.carbon.qa.admin_edit"),
                to: `/carbon/fields/${field.id}/edit`,
                target: "_blank",
              }
            : null,
        ],
        title: I18n.t("js.shared.actions"),
      },

      field,
    };
  });
}

function formatAssuranceValue(
  value: Assurance["value"] | AssuranceCheck["value"] | undefined,
) {
  if (typeof value === "number") return value.toString();
  if (typeof value !== "string") return undefined;

  return value.replace(/[,]\s?(20[\d]{2}\s)/g, ",\n$1");
}

function formatFieldSize(value: Assurance["valueJson"] | undefined) {
  if (!value) return undefined;

  const { percentageDifference, reportedFieldSize, detectedFieldSize } =
    JSON.parse(value);

  const values = [
    addUnit(percentageDifference, "%"),
    addUnit(reportedFieldSize, "ha"),
    addUnit(detectedFieldSize, "ha"),
  ];

  return values.join("\n");
}

function formatFarmSize(value: AssuranceCheck["valueJson"] | undefined) {
  if (!value) return undefined;

  const { percentageDifference, reportedFarmSize, detectedFarmSize } =
    JSON.parse(value);

  const values = [
    addUnit(percentageDifference, "%"),
    addUnit(reportedFarmSize, "ha"),
    addUnit(detectedFarmSize, "ha"),
  ];

  return values.join("\n");
}

function addUnit(value: string | null | undefined, unit: string) {
  // Return a default value if empty.
  if (value === null || value === undefined) return "-";

  // If the value cannot be parsed to a number, return it as-is.
  if (isNaN(Number(value))) return value;

  return `${value} ${unit}`;
}
