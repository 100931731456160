import { CellContext } from "@tanstack/react-table";
import isNil from "lodash/isNil";

import { Chip, ChipVariant } from "~atoms";

export type BooleanCellConfig = {
  value: boolean | null | undefined;
  theme: "default" | "inverted" | "neutral";
};

export type BooleanCellValue = boolean | BooleanCellConfig | null | undefined;

export const BooleanCell = <TValue extends BooleanCellValue>({
  getValue,
  table,
}: CellContext<any, TValue>) => {
  const valueOrConfig = getValue();

  let value: BooleanCellConfig["value"];
  let theme: BooleanCellConfig["theme"];

  const texts = table.options.meta?.texts;

  if (!texts?.yes || !texts?.no) {
    throw new Error("Please provide table texts when using BooleanCell");
  }

  if (valueOrConfig && typeof valueOrConfig === "object") {
    value = valueOrConfig.value;
    theme = valueOrConfig.theme;
  } else {
    value = valueOrConfig;
    theme = "default";
  }

  if (isNil(value)) return <span>-</span>;

  const variant = getVariant(value, theme);

  const text = value ? texts.yes : texts.no;

  return <Chip variant={variant}>{text}</Chip>;
};

function getVariant(
  value: boolean,
  theme: BooleanCellConfig["theme"],
): ChipVariant {
  if (theme === "inverted") {
    return value ? "danger" : "success";
  }

  if (theme === "neutral") {
    return "neutral";
  }

  return value ? "success" : "danger";
}
