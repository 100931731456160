import { LinkProps as RouterLinkProps } from "react-router-dom";

import { IconConfig } from "~assets";

export type ButtonSize = "x-small" | "small" | "medium";

export type ButtonVariant = "primary" | "secondary" | "text";

export type LinkProps = RouterLinkProps;

export type ExternalLinkProps = {
  href: string | undefined;
} & React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type ButtonIconProps = IconConfig<
  "icon",
  "iconVariant",
  {
    iconPosition?: "before" | "after";
  }
>;

export type ActionButtonProps = {
  // not sure why "type" is acting up, and not the rest, 🤷
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & Omit<React.HTMLProps<HTMLButtonElement>, "size">;

type IdentificationProps = {
  to?: LinkProps["to"];
  href?: ExternalLinkProps["href"];
};

export const isLinkProps = (props: IdentificationProps): props is LinkProps => {
  return Boolean(props.to);
};

export const isExternalLinkProps = (
  props: IdentificationProps,
): props is ExternalLinkProps => {
  return Boolean(props.href);
};
