import clsx from "clsx";

import { Spinner } from "@ag/design-system/assets";

type Props = {
  isVisible: boolean;
};

const GlobalLoader = ({ isVisible }: Props) => (
  <div
    className={clsx(
      "fixed bottom-80 left-1/2 z-notifications flex -translate-x-1/2 transform items-center rounded bg-grey-900 px-3 py-2 text-p3 uppercase text-white-100",
      {
        visible: isVisible,
        invisible: !isVisible,
      },
    )}
  >
    <Spinner className="mr-4 w-6" />
    Loading...
  </div>
);

export default GlobalLoader;
