import { useMutation } from "@tanstack/react-query";

import { ToastNotification } from "@ag/utils/services";

type Payload = {
  file: File;
  signedUrl: string;
};

const uploadInventoryFile = async ({ file, signedUrl }: Payload) => {
  await fetch(signedUrl, {
    method: "PUT",
    body: file,
  })
    .then(response => {
      if (!response.ok) {
        throw new Error("Failed to upload file");
      }
    })
    .catch(() => {
      throw new Error("Failed to upload file");
    });
};

export const useUploadInventoryFileMutation = () =>
  useMutation(uploadInventoryFile, {
    onError: error => ToastNotification.error(error),
  });
