import styled from "@emotion/styled";

import { colorsObject } from "@ag/design-system/tokens";

export const SFallow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
`;

export const SUnsavedIndicator = styled.span`
  text-transform: uppercase;
  margin-left: 16px;
  color: ${colorsObject.grey[400]};
`;
