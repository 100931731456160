import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import { Icon } from "@ag/design-system/assets";
import { Button, FileRejection, InfoBox } from "@ag/design-system/atoms";
import { Modal } from "@ag/design-system/organisms";
import { DropzoneField, InputField } from "@ag/form-fields";
import { ToastNotification } from "@ag/utils/services";

import { useCreateDocumentsClientMutation } from "../api/create-documents-client";
import { useDeleteClientDocumentMutation } from "../api/delete-client-document";
import { useClientQuery } from "../api/get-client";
import { useUpdateClientMutation } from "../api/update-client";
import {
  CreateClient,
  CreateClientSchema,
  UpdateClient,
} from "../entities/client";
import { useUpdateClientStore } from "../store/update-client-store";

export const UpdateClientModal = ({
  isOpen,
  onRequestClose,
  clientId,
}: {
  isOpen: boolean;
  onRequestClose: () => void;
  clientId: string;
}) => {
  const {
    documents,
    fileErrors,
    markedForRemoval,
    setDocuments,
    setFileErrors,
    setMarkedForRemoval,
    reset: resetStore,
  } = useUpdateClientStore();
  const { data: client } = useClientQuery(clientId);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreateClient>({
    resolver: zodResolver(CreateClientSchema),
    defaultValues: {
      name: client?.name,
    },
  });

  const updateClientMutation = useUpdateClientMutation();
  const createDocumentsClientMutation = useCreateDocumentsClientMutation();
  const deleteClientDocumentMutation = useDeleteClientDocumentMutation();

  useEffect(() => {
    reset({ name: client?.name });
  }, [reset, client?.name]);

  const handleFileDropped = (
    acceptedFiles: File[] | undefined,
    fileRejections: FileRejection[] | undefined,
  ) => {
    setFileErrors(undefined);

    const errors: string[] = [];

    if (fileRejections) {
      fileRejections.forEach(rejection => {
        rejection.errors.forEach(error => {
          if (error.code === "file-too-large") {
            errors.push(
              "File is too large, please upload a file smaller than 5MB",
            );
          } else if (error.code === "file-invalid-type") {
            errors.push("Invalid file type, please upload a valid document");
          } else {
            errors.push(error.message);
          }
        });
      });

      if (errors.length > 0) {
        setFileErrors(errors);
        return;
      }
    }

    const newFiles: File[] = [];
    const existingFileNames = new Set([
      ...(client?.documents?.map(doc => doc.filename) || []),
      ...documents.map(doc => doc.name),
    ]);

    acceptedFiles?.forEach(file => {
      if (
        !existingFileNames.has(file.name) &&
        !newFiles.some(newFile => newFile.name === file.name) &&
        documents.length + newFiles.length < 10
      ) {
        newFiles.push(file);
      }
    });

    if (documents.length + newFiles.length > 10) {
      setFileErrors(["You can upload a maximum of 10 documents"]);
      return;
    }

    setDocuments([...documents, ...newFiles]);
  };

  const handleRemoveDocument = (index: number) => {
    setDocuments(documents.filter((_, i) => i !== index));
  };

  const handleRemoveExistingDocument = (index: number) => {
    setMarkedForRemoval(new Set(markedForRemoval).add(index));
  };

  const handleUndoRemoveExistingDocument = (index: number) => {
    const newSet = new Set(markedForRemoval);
    newSet.delete(index);
    setMarkedForRemoval(newSet);
  };

  const handleOnRequestClose = () => {
    resetStore();
    reset();
    onRequestClose();
  };

  const onSubmit = async (data: UpdateClient) => {
    try {
      if (!client?.id) return;

      const clientId = client.id;

      const documentsToDelete = client?.documents?.filter((_, index) =>
        markedForRemoval.has(index),
      );

      if (documentsToDelete) {
        for (const doc of documentsToDelete) {
          try {
            const docId = doc.url.split("/").pop();
            if (!docId) throw new Error("Invalid document id");
            await deleteClientDocumentMutation.mutateAsync({
              clientId,
              docId,
            });
          } catch (error) {
            return error;
          }
        }
      }

      if (documents.length > 0) {
        try {
          await createDocumentsClientMutation.mutateAsync({
            id: clientId,
            payload: { files: documents },
          });
        } catch (error) {
          return error;
        }
      }

      if (data.name !== client?.name && data.name) {
        await updateClientMutation.mutateAsync({
          id: clientId,
          name: data.name,
        });
      }

      ToastNotification.success("Client successfully updated");
      onRequestClose();
      setDocuments([]);
      setFileErrors(undefined);
      setMarkedForRemoval(new Set());
      reset();
    } catch (error) {
      ToastNotification.error(error.message);
    }
  };

  return (
    <Modal.Root isOpen={isOpen} onRequestClose={handleOnRequestClose}>
      <Modal.Header>
        <Modal.Title>{`Update: ${client?.name || ""}`}</Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <InputField
            {...register("name")}
            label="Client Name"
            isRequired
            error={errors.name}
          />

          {fileErrors?.map(fileError => (
            <InfoBox
              key={fileError}
              variant="danger"
              icon="triangle-exclamation"
            >
              {fileError}
            </InfoBox>
          ))}

          <div>
            <DropzoneField
              labelClassName="text-p2 text-grey-700"
              label="Documents"
              onChange={handleFileDropped}
              acceptType={[".pdf", ".doc", ".docx", ".xlsx", ".xls", ".csv"]}
              maxSize={5}
              isMultiple
              value={documents}
              dropText=""
              isDisabled={
                documents.length + (client?.documents?.length ?? 0) >= 10
              }
            >
              <div className="flex flex-col items-center justify-center gap-2">
                <Icon name="chart-network" className="text-[32px]" />

                <div className="text-center">
                  <span className="text-p2">Drag and drop documents</span>
                  <span className="block font-medium text-grey-800">
                    .pdf, .doc, .docx, .xls, .xlsx
                  </span>
                  <span className="font-bold text-messaging-warning-700">
                    Max 10 documents - 5MB each
                  </span>
                </div>

                <span>or</span>

                <Button
                  type="button"
                  size="small"
                  variant="text"
                  onClick={() => null}
                  disabled={
                    documents.length + (client?.documents?.length ?? 0) >= 10
                  }
                >
                  Select from folder
                </Button>
              </div>
            </DropzoneField>

            {client?.documents && client.documents.length > 0 && (
              <div className="mt-4">
                <h3 className="text-h3 font-medium text-grey-700">
                  Documents already uploaded:
                </h3>
                <ul className="px-2 py-1">
                  {client?.documents?.map((doc, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <Icon
                        name="check"
                        className="text-p2 text-messaging-success-700"
                      />
                      <span
                        className={`text-p2 font-medium text-grey-700  ${
                          markedForRemoval.has(index) ? "line-through" : ""
                        }`}
                      >
                        {doc.filename}
                      </span>

                      {markedForRemoval.has(index) ? (
                        <Button
                          type="button"
                          size="x-small"
                          variant="text"
                          onClick={() =>
                            handleUndoRemoveExistingDocument(index)
                          }
                        >
                          Undo
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          size="x-small"
                          variant="text"
                          onClick={() => handleRemoveExistingDocument(index)}
                          isDanger
                        >
                          Remove
                        </Button>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {documents.length > 0 && (
              <div className="mt-4">
                <h3 className="text-h3 font-medium text-grey-700">
                  New documents:
                </h3>
                <ul className="px-1 py-2">
                  {documents.map((doc, index) => (
                    <li key={index} className="flex items-center gap-2">
                      <Icon
                        name="check"
                        className="text-p2 text-messaging-success-700"
                      />
                      <span className="text-p2 font-medium text-grey-700">
                        {doc.name}
                      </span>

                      <Button
                        type="button"
                        size="x-small"
                        variant="text"
                        onClick={() => handleRemoveDocument(index)}
                        isDanger
                      >
                        Remove
                      </Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <div className="flex justify-between">
            <Button onClick={handleOnRequestClose} variant="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={
                (fileErrors?.length ?? 0) > 0 ||
                documents.length + (client?.documents?.length ?? 0) > 10
              }
            >
              Update
            </Button>
          </div>
        </form>
      </Modal.Content>
    </Modal.Root>
  );
};
