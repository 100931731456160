import { debounce } from "lodash";
import { useMemo } from "react";
import { create } from "zustand";

import { getUsers } from "../api/get-users";
import { User } from "../entities/user";

type UsersSearchStore = {
  data: User[];
  isLoading: boolean;

  onSearchStart: () => void;
  onSearchEnd: (users: User[]) => void;
};

export const useUsersSearchStore = create<UsersSearchStore>(set => ({
  data: [],
  isLoading: false,

  onSearchStart: () => {
    set({ isLoading: true });
  },
  onSearchEnd: data => {
    set({ data, isLoading: false });
  },
}));

export const useUsersSearch = () => {
  const { data, isLoading, onSearchStart, onSearchEnd } = useUsersSearchStore();

  const onSearch = debounce(async (inputValue: string) => {
    onSearchStart();

    const response = await getUsers({
      filters: {
        search: inputValue,
      },
    });

    onSearchEnd(response.items);
  }, 500);

  return useMemo(
    () => ({
      data,
      isLoading,
      onSearch,
    }),
    [data, isLoading, onSearch],
  );
};
