import { z } from "zod";

import { FertiliserEmissionsInhibitors, FertiliserMode } from "~types";

import {
  FertiliserAttribute,
  FertiliserDataAttribute,
  FertiliserViewAttribute,
} from "./types";

export const FertiliserDataSchema = z.object({
  [FertiliserDataAttribute.Mode]: z.nativeEnum(FertiliserMode).nullable(),
  [FertiliserDataAttribute.CarbonFertiliserId]: z.number().nullable(),
  [FertiliserDataAttribute.ApplicationRate]: z.number().nullable(),
  [FertiliserDataAttribute.EmissionsInhibitors]: z
    .nativeEnum(FertiliserEmissionsInhibitors)
    .nullable(),
  [FertiliserDataAttribute.NitrogenApplicationRate]: z.number().nullable(),
  [FertiliserDataAttribute.NitrificationApplicationRate]: z.number().nullable(),
  [FertiliserDataAttribute.PotassiumApplicationRate]: z.number().nullable(),
  [FertiliserDataAttribute.PotassiumType]: z.string().nullable(),
  [FertiliserDataAttribute.PhosphorusApplicationRate]: z.number().nullable(),
  [FertiliserDataAttribute.PhosphorusType]: z.string().nullable(),
});

export const FertiliserV2022DataSchema = z.object({
  [FertiliserAttribute.Fertilisers]: z.array(FertiliserDataSchema),
  [FertiliserAttribute.FertilisersCount]: z.number().nullable(),

  [FertiliserViewAttribute.IsFertilisersUsed]: z.boolean().nullish(),
});
