import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BasicStepper } from "@ag/design-system/organisms";
import { usePrompt } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import BackButton from "~components/BackButton";
import {
  ProgressLostModal,
  useCancelUpdateRequest,
  useUpdateRequestQuery,
  useUpdateRequestStore,
} from "~features/certificate";
import { AuthorizedSidebar } from "~features/navigation";
import { Page, withPageAccess } from "~features/permission";
import ListLayout from "~layouts/list-layout";

import ClientsStep from "./steps/clients";
import EnterIdsStep from "./steps/enter-ids";
import ReviewStep from "./steps/review";
import SubmitStep from "./steps/submit";

const UpdateRequest = () => {
  const navigate = useNavigate();
  const [isShowProgressLostModal, setIsShowProgressLostModal] = useState(false);
  const { validationResult, client, setValidationResult, setClient } =
    useUpdateRequestStore();

  const { requestId } = useParams<{ requestId: string }>();
  const { data: updateRequest } = useUpdateRequestQuery(requestId);

  const { blockedRoute, unblockRoute } = usePrompt(
    true,
    setIsShowProgressLostModal,
  );

  const cancelUpdateRequest = useCancelUpdateRequest();

  const handleExitClick = () => {
    unblockRoute();
    const navigationPath = blockedRoute?.search
      ? "/certificates-management"
      : blockedRoute;
    try {
      cancelUpdateRequest.mutate(requestId, {
        onSuccess: () => {
          if (blockedRoute && navigationPath) {
            navigate(navigationPath);
          } else {
            history.back();
          }
          setValidationResult(null);
          setClient(null);
        },
      });
    } catch (error) {
      ToastNotification.error(error);
    }
  };

  const handleCancel = () => {
    setIsShowProgressLostModal(false);
  };

  const title =
    updateRequest?.action === "transfer"
      ? "Transfer certificates"
      : "Retire certificates";
  const isCertificateValid = !!validationResult?.valid;

  return (
    <ListLayout.Root>
      <ProgressLostModal
        isOpen={isShowProgressLostModal}
        onClose={handleCancel}
        onExitClick={handleExitClick}
      />
      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.TopBar>
        <BackButton onClick={() => navigate("/certificates-management")} />

        <ListLayout.TopBarTitle>{title}</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Content>
        <div className="flex flex-1 flex-col gap-8 bg-grey-100 pt-8">
          <BasicStepper.Root>
            <BasicStepper.List>
              <BasicStepper.Trigger title="Enter IDs" value="ids" />
              <BasicStepper.Trigger
                title="Review selection"
                value="review"
                isDisabled={!isCertificateValid}
              />
              <BasicStepper.Trigger
                title="Select Clients"
                value="clients"
                isDisabled={!isCertificateValid}
              />
              <BasicStepper.Trigger
                title="Submit"
                value="submit"
                isDisabled={!client}
              />
            </BasicStepper.List>

            <BasicStepper.Content value="ids">
              {({ goNextStep }) => <EnterIdsStep onGoNextStep={goNextStep} />}
            </BasicStepper.Content>

            <BasicStepper.Content value="review">
              {({ goPrevStep, goNextStep }) => (
                <ReviewStep
                  onGoPrevStep={goPrevStep}
                  onGoNextStep={goNextStep}
                />
              )}
            </BasicStepper.Content>

            <BasicStepper.Content value="clients">
              {({ goPrevStep, goNextStep }) => (
                <ClientsStep
                  onGoPrevStep={goPrevStep}
                  onGoNextStep={goNextStep}
                />
              )}
            </BasicStepper.Content>

            <BasicStepper.Content value="submit">
              {({ goPrevStep }) => (
                <SubmitStep
                  unblockRoute={unblockRoute}
                  onGoPrevStep={goPrevStep}
                />
              )}
            </BasicStepper.Content>
          </BasicStepper.Root>
        </div>
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default withPageAccess(Page.UpdateRequest)(UpdateRequest);
