import { useMemo } from "react";
import { Field, useForm, useFormState } from "react-final-form";

import { Button, InfoBox } from "@ag/design-system/atoms";
import { ButtonSelect } from "@ag/design-system/molecules";
import { ButtonSelectField, InputField } from "@ag/form-fields";
import I18n from "@ag/i18n";
import { LabelValue } from "@ag/utils/types";

import { ISOFertiliser } from "~entities";
import { SFormSubmitButtonContainer } from "~forms/styled";

import { FERTILISER_ID_KEY } from "../../constants";
import {
  FertiliserAddingMode,
  FertiliserAttribute,
  FertiliserDetailsAttribute,
} from "../../types";
import { SSeparator, SSeparatorSection, SSeparatorText } from "../styled";
import { getChemicalSpecificationFertilisers } from "./helpers";
import {
  SApplicationMethodWrapper,
  SChemicalSpecification,
  SFertiliserRow,
  SInputWrapper,
} from "./styled";

type Props = {
  applicationModes: LabelValue<string>[];
  fertilisers: ISOFertiliser[];
  isReadonly: boolean | undefined;
  isSubmitting: boolean | undefined;
  onAddingModeButtonClick: (addingMode: FertiliserAddingMode) => void;
};

const ChemicalSpecification = ({
  applicationModes,
  fertilisers,
  isReadonly,
  isSubmitting,
  onAddingModeButtonClick,
}: Props) => {
  const { submit, mutators } = useForm();
  const { values, initialValues, pristine } = useFormState();

  const isSubmissionOverwriting =
    initialValues[FertiliserAttribute.FertilisersShowChemicalSpecification] ===
    false;
  const chemicalSpecificationAmounts = values.chemicalSpecification?.fertilisers
    ? Object.values(values.chemicalSpecification.fertilisers)
    : [];
  const isChemicalSpecificationEntered =
    chemicalSpecificationAmounts.filter(amount => amount).length > 0;

  const chemicalSpecificationFertilisers = useMemo(
    () => getChemicalSpecificationFertilisers(fertilisers),
    [fertilisers],
  );

  const onNotUsingFertiliserButtonClicked = () => {
    mutators.resetFertilisers();
    submit();
  };

  return (
    <SChemicalSpecification data-testid="fertiliser-chemicalSpecification">
      {chemicalSpecificationFertilisers.map(fertiliser => (
        <SFertiliserRow key={fertiliser.id}>
          <p className="text-p1">{fertiliser.name}</p>

          <SInputWrapper>
            <Field
              name={`chemicalSpecification.${FertiliserAttribute.Fertilisers}.${FERTILISER_ID_KEY}${fertiliser.id}`}
              render={({ input, meta }) => (
                <InputField
                  {...input}
                  type="number"
                  placeholder="0"
                  label={I18n.t("js.carbon.kg_ha")}
                  isDisabled={isReadonly}
                  error={
                    meta.touched && meta.error
                      ? { message: meta.error }
                      : undefined
                  }
                />
              )}
              parse={value => Number(value)}
            />
          </SInputWrapper>
        </SFertiliserRow>
      ))}

      <SApplicationMethodWrapper>
        <Field
          name={`chemicalSpecification.${FertiliserDetailsAttribute.ApplicationMethod}`}
          render={({ input, meta }) => (
            <ButtonSelectField
              {...input}
              label={I18n.t("js.carbon.application_method")}
              error={
                meta.touched && meta.error ? { message: meta.error } : undefined
              }
              tooltip={I18n.t("js.carbon.application_method_hint")}
              isDisabled={isReadonly}
            >
              {applicationModes.map(option => (
                <ButtonSelect.Option key={option.value} value={option.value}>
                  {option.label}
                </ButtonSelect.Option>
              ))}
            </ButtonSelectField>
          )}
        />
      </SApplicationMethodWrapper>

      {isSubmissionOverwriting && (
        <InfoBox variant="warning">
          {I18n.t("js.carbon.saving_specification_overwrite_list")}
        </InfoBox>
      )}

      {!isReadonly && (
        <SFormSubmitButtonContainer>
          {!isChemicalSpecificationEntered && (
            <Button
              data-testid="fertiliser-notUsingFertiliserBtn"
              type="submit"
              variant="text"
              isLoading={isSubmitting}
              onClick={onNotUsingFertiliserButtonClicked}
            >
              {I18n.t("js.carbon.not_using_fertiliser")}
            </Button>
          )}

          <Button
            data-testid="fertiliser-submitButton"
            type="submit"
            disabled={!isChemicalSpecificationEntered || pristine}
            isLoading={isSubmitting}
            onClick={submit}
          >
            {I18n.t("js.shared.save")}
          </Button>
        </SFormSubmitButtonContainer>
      )}

      {!isReadonly && (
        <>
          <SSeparatorSection>
            <SSeparator />

            <SSeparatorText className="text-p3">
              {I18n.t("js.shared.or")}
            </SSeparatorText>

            <SSeparator />
          </SSeparatorSection>

          <Button
            data-testid="fertilisersPartial-chooseFromList"
            variant="secondary"
            onClick={() => {
              onAddingModeButtonClick(FertiliserAddingMode.List);
            }}
          >
            {I18n.t("js.carbon.choose_from_fertiliser_list")}
          </Button>
        </>
      )}
    </SChemicalSpecification>
  );
};

export default ChemicalSpecification;
