import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Icon } from "@ag/design-system/assets";
import { Button } from "@ag/design-system/atoms";
import { InputField } from "@ag/form-fields";

import BackButton from "~components/BackButton";
import { AuthorizedSidebar } from "~features/navigation";
import ListLayout from "~layouts/list-layout";

const BoundaryVerification = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      farmId: "",
      carbonFieldId: "",
    },
  });
  const navigate = useNavigate();

  const onSubmitFarm = (data: { farmId: string }) => {
    navigate(`/carbon/boundary-verification/map/farm/${data.farmId}`);
  };

  const onSubmitField = (data: { carbonFieldId: string }) => {
    navigate(`/carbon/boundary-verification/map/field/${data.carbonFieldId}`);
  };

  return (
    <ListLayout.Root>
      <ListLayout.TopBar>
        <BackButton />

        <ListLayout.TopBarTitle>Boundary verification</ListLayout.TopBarTitle>
      </ListLayout.TopBar>

      <ListLayout.Sidebar>
        <AuthorizedSidebar />
      </ListLayout.Sidebar>

      <ListLayout.Content>
        <ListLayout.Header variant="slim">
          <div className="flex w-full flex-col gap-8">
            <form
              onSubmit={handleSubmit(onSubmitFarm)}
              className="flex w-full items-center gap-2"
            >
              <h2>Farm search</h2>
              <Controller
                name="farmId"
                control={control}
                render={({ field }) => (
                  <InputField
                    {...field}
                    placeholder="Search for a FarmId"
                    leftAddon={<Icon name="search" />}
                  />
                )}
              />
              <Button type="submit">Map view</Button>
            </form>

            <form
              onSubmit={handleSubmit(onSubmitField)}
              className="flex w-full items-center gap-2"
            >
              <h2>Field search</h2>
              <Controller
                name="carbonFieldId"
                control={control}
                render={({ field }) => (
                  <InputField
                    {...field}
                    placeholder="Search for a FieldId"
                    leftAddon={<Icon name="search" />}
                  />
                )}
              />
              <Button type="submit">Map view</Button>
            </form>
          </div>
        </ListLayout.Header>
      </ListLayout.Content>
    </ListLayout.Root>
  );
};

export default BoundaryVerification;
