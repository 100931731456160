import { startCase } from "lodash";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import I18n from "@ag/i18n";

import { queryClient } from "~config";
import {
  AssuranceCategory,
  AssuranceCheckKey,
  AssuranceStatus,
  addFieldsComment,
  changeFieldsStatus,
  generateFieldsQueryKey,
} from "~features/assurance";

type BulkError = {
  carbonFieldId: string;
  message: string;
};

type Result = {
  id: string;
  message: string;
  type: "success" | "error";
  errors?: BulkError[];
};

interface FieldsBulkActionState {
  results: Result[];
  selection: Record<string, boolean>;

  updateSelection: (selection: Record<string, boolean>) => void;
  clearSelection: () => void;

  startAction: () => void;
  finishAction: () => void;

  addResult: (result: Omit<Result, "id">) => void;
  removeResult: (id: string) => void;
}

export const useFieldsBulkActionsStore = create<FieldsBulkActionState>()(
  immer(set => ({
    results: [] as Result[],
    selection: {},

    startAction: () =>
      set({
        results: [],
      }),
    finishAction: () => {
      set({ selection: {} });
    },

    updateSelection: selection => set({ selection }),
    clearSelection: () => set({ selection: {} }),

    addResult: result =>
      set(state => {
        state.results.push({ ...result, id: uuidv4() });
      }),
    removeResult: resultId =>
      set(state => {
        state.results = state.results.filter(result => result.id !== resultId);
      }),
  })),
);

export const useFieldsBulkActions = () => {
  const store = useFieldsBulkActionsStore();

  // TODO: implement API when ready
  const handleRun = useCallback(async () => {
    store.startAction();

    alert("not implemented");

    store.finishAction();
  }, [store]);

  const handleChangeStatus = useCallback(
    async (status: AssuranceStatus) => {
      store.startAction();

      const results = await changeFieldsStatus({
        fieldIds: getSelectedIds(store.selection),
        payload: {
          newStatus: status,
        },
      });

      const errorResults = results.filter(item => item.error);
      const successResults = results.filter(item => item.success);

      if (errorResults.length) {
        store.addResult({
          type: "error",
          message: I18n.t("js.admin.assurance.list.change_status_failed", {
            count: errorResults.length,
          }),
          errors: errorResults.map(error => ({
            carbonFieldId: error.meta.carbonFieldId,
            message: error.error?.message ?? "",
          })),
        });
      }

      if (successResults.length) {
        store.addResult({
          type: "success",
          message: I18n.t("js.admin.assurance.list.change_status_success", {
            count: successResults.length,
            status: startCase(status),
          }),
        });
      }

      queryClient.invalidateQueries(generateFieldsQueryKey());
      store.finishAction();
    },
    [store],
  );

  const handleCommentAdded = useCallback(
    async (comment: {
      category: AssuranceCategory;
      checks?: AssuranceCheckKey;
      text: string;
    }) => {
      store.startAction();

      const results = await addFieldsComment({
        fieldIds: getSelectedIds(store.selection),
        payload: comment,
      });

      const errorResults = results.filter(item => item.error);
      const successResults = results.filter(item => item.success);

      if (errorResults.length) {
        store.addResult({
          type: "error",
          message: I18n.t("js.admin.assurance.list.add_comment_failed", {
            count: errorResults.length,
          }),
          errors: errorResults.map(result => ({
            carbonFieldId: result.meta.carbonFieldId,
            message: result.error?.message ?? "",
          })),
        });
      }

      if (successResults.length) {
        store.addResult({
          type: "success",
          message: I18n.t("js.admin.assurance.list.add_comment_success", {
            count: successResults.length,
          }),
        });
      }

      queryClient.invalidateQueries(generateFieldsQueryKey());
      store.finishAction();
    },
    [store],
  );

  return {
    ...store,

    run: handleRun,
    changeStatus: handleChangeStatus,
    addComment: handleCommentAdded,
  };
};

function getSelectedIds(selection: Record<string, boolean>) {
  return Object.entries(selection)
    .filter(([, value]) => value)
    .map(([key]) => key);
}
