import * as styles from "./centered-layout.css";

/* -------------------------------------------------------------------------------------------------
 * CenteredLayout
 * -----------------------------------------------------------------------------------------------*/
type CenteredLayoutProps = React.PropsWithChildren<unknown>;

function CenteredLayout({ children }: CenteredLayoutProps) {
  return <div className={styles.root}>{children}</div>;
}

const Root = CenteredLayout;

export { Root };
