import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { FieldBoundariesInfo } from "../entities/field-boundaries-info";

export const generateFieldBoundariesInfoQueryKey = (carbonFieldId: string) => [
  "field-boundaries-info",
  carbonFieldId,
];

export const getFieldBoundariesInfo = async (carbonFieldId: string) => {
  const response = await axios.get<CommonResponse<FieldBoundariesInfo>>(
    `/api/v1/field-boundaries/${carbonFieldId}/info`,
    {
      apiSource: "node-carbon",
    },
  );

  return response.data.success!.data;
};

export const useFieldBoundariesInfoQuery = (
  carbonFieldId: string,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(
    generateFieldBoundariesInfoQueryKey(carbonFieldId),
    () => getFieldBoundariesInfo(carbonFieldId),
    options,
  );
