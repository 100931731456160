import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ToastNotification } from "@ag/utils/services";

import { queryClient } from "~config/react-query";

import { UpdateFieldActualsV2022 } from "../entities/field-actuals-v2022";
import { generateFieldQueryKey } from "./get-field";

export type UpdateFieldActualsData = {
  fallow?: boolean;
  comment: {
    text: string;
  };
} & UpdateFieldActualsV2022;

export type UpdateFieldActualsPayload = {
  fieldId: string;
  fieldActualsId: string;
  carbonFieldActualData: UpdateFieldActualsData;
};

export const updateFieldActuals = async ({
  fieldId,
  fieldActualsId,
  carbonFieldActualData,
}: UpdateFieldActualsPayload) => {
  await axios.put(
    `/api/farmer/carbon/v1/admin/carbon_fields/carbon_quality_controls/carbon_fields/${fieldId}/carbon_field_actuals/${fieldActualsId}`,
    {
      carbonFieldActual: carbonFieldActualData,
    },
  );
};

export const useUpdateFieldActualsMutation = () =>
  useMutation(updateFieldActuals, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateFieldQueryKey());
    },
    onError: error => {
      ToastNotification.error(error);
    },
  });
