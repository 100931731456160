import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import {
  CertificateGroup,
  CertificateGroupSchema,
} from "../entities/certificate-group";

type GetUpdateRequestGroupsParams = MetaArgs & {
  updateRequestId: string | undefined;
};

export const generateUpdateRequestGroupsQueryKey = (
  params: GetUpdateRequestGroupsParams | undefined,
) => ["update-request-groups", params].filter(Boolean);

const getUpdateRequestGroups = async (params: GetUpdateRequestGroupsParams) => {
  const { updateRequestId, ...restParams } = params;

  const response = await axios.get<CommonPaginatedResponse<CertificateGroup>>(
    `/api/v1/certificates/groups/update-requests/${updateRequestId}/preview`,
    {
      params: {
        ...restParams,
        limit: restParams?.limit || DEFAULT_LIMIT,
      },
      schema: createPaginatedDataSchema(CertificateGroupSchema),
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useUpdateRequestGroupsQuery = (
  params: GetUpdateRequestGroupsParams,
) =>
  useQuery(
    generateUpdateRequestGroupsQueryKey(params),
    () => getUpdateRequestGroups(params),
    {
      keepPreviousData: true,
    },
  );
