import { Icon } from "@ag/design-system/assets";
import I18n from "@ag/i18n";

import { Sidebar } from "~components/sidebar";
import { AdminRole } from "~features/admin";
import { useSessionContext } from "~features/auth";
import {
  Page,
  getHasPageAccess,
  useAuthPermissions,
  useCarbonPermissions,
  useMarketsPermissions,
  useUserManagementPermissions,
} from "~features/permission";
import useHasAdminRole from "~hooks/useHasAdminRole";
import { useIsFlagActive } from "~lib/ampli";

import { useDocusignConsentQuery } from "../api/get-docusign-consent";
import {
  buildAPIUrl,
  getNavigationSections,
} from "../helpers/navigation-links";

type Props = {
  className?: string;
};

export const AuthorizedSidebar = ({ className }: Props) => {
  const { currentAdmin, signOut } = useSessionContext();
  const isCropsV2 = useIsFlagActive("crops-v2", "on");
  const isFertiliserV2 = useIsFlagActive("fertilisers-v2", "on");

  const { refetch: fetchDocusignConsent } = useDocusignConsentQuery({
    enabled: false,
  });

  const isSystemAdmin = useHasAdminRole(AdminRole.SystemAdmin);
  const { data: carbonPermissions } = useCarbonPermissions();
  const { data: authPermissions } = useAuthPermissions();
  const { data: userManagementPermissions } = useUserManagementPermissions();
  const { data: marketsPermissions } = useMarketsPermissions();

  const handleDocusignRedirect = async () => {
    const { data } = await fetchDocusignConsent();
    window.open(data, "_blank");
  };

  const sections = getNavigationSections({ isCropsV2, isFertiliserV2 });

  return (
    <Sidebar.Root className={className}>
      <Sidebar.Nav>
        {sections.map(section => {
          if (section.isSystemAdminOnly && !isSystemAdmin) return;

          const hasSectionAccess = Object.keys(section.links).some(page =>
            getHasPageAccess({
              page: page as Page,
              carbonPermissions,
              authPermissions,
              userManagementPermissions,
              marketsPermissions,
            }),
          );

          if (!hasSectionAccess) return;

          return (
            <Sidebar.NavSection key={section.title} title={section.title}>
              {Object.entries(section.links).map(([page, link]) => {
                const hasPageAccess = getHasPageAccess({
                  page: page as Page,
                  carbonPermissions,
                  authPermissions,
                  userManagementPermissions,
                  marketsPermissions,
                });

                if (
                  (link.isSystemAdminOnly && !isSystemAdmin) ||
                  !hasPageAccess
                ) {
                  return;
                }

                if (link.to) {
                  return (
                    <Sidebar.NavLink
                      key={page}
                      to={link.to}
                      data-testid={`menu-${link.title}`}
                    >
                      {link.title}
                    </Sidebar.NavLink>
                  );
                }

                if (link.href) {
                  return (
                    <Sidebar.NavLink key={page} href={link.href}>
                      {link.title}
                    </Sidebar.NavLink>
                  );
                }
              })}
            </Sidebar.NavSection>
          );
        })}

        {isSystemAdmin && (
          <Sidebar.NavSection title="External">
            <Sidebar.NavLink href={buildAPIUrl("/admin/flipper")}>
              Features
            </Sidebar.NavLink>

            <Sidebar.NavLink onClick={handleDocusignRedirect}>
              DocuSign
            </Sidebar.NavLink>

            <Sidebar.NavLink href={buildAPIUrl("/admin/sidekiq")}>
              Sidekiq
            </Sidebar.NavLink>
          </Sidebar.NavSection>
        )}
      </Sidebar.Nav>

      <Sidebar.Footer>
        <Sidebar.FooterLink to="/account">
          <Icon name="user" variant="solid" />
          {currentAdmin?.email}
        </Sidebar.FooterLink>

        <Sidebar.FooterLink data-testid="sidebar-signOut" onClick={signOut}>
          <Icon name="power-off" />
          {I18n.t("js.admin.layout.sign_out")}
        </Sidebar.FooterLink>
      </Sidebar.Footer>
    </Sidebar.Root>
  );
};
