import { z } from "zod";

export const InitialResourcesSchema = z.object({
  defaultBankRates: z.record(z.string()),
  defaultCurrency: z.object({
    id: z.string(),
    isoCode: z.string(),
    isoNumeric: z.string(),
    name: z.string(),
    smallestDenomination: z.number(),
    subunit: z.string(),
    subunitToUnit: z.number(),
    symbol: z.string(),
    symbolFirst: z.boolean(),
  }),
  currencies: z.array(z.record(z.string())),
});

export type InitialResources = z.infer<typeof InitialResourcesSchema>;
