import { AssuranceCategory, AssuranceCheckKey } from "../entities/assurance";

export function getAssuranceCheckKeys(category?: AssuranceCategory) {
  if (!category) return [];

  const lookup: Record<AssuranceCategory, AssuranceCheckKey[]> = {
    cover_crop: [AssuranceCheckKey.CoverCropHB],
    soil_disturbance: [AssuranceCheckKey.SoilDisturbanceHB],
    deforestation: [],
    field_size: [AssuranceCheckKey.FarmSize],
    duplication: [AssuranceCheckKey.FieldLocation],
    intersection_over_union: [AssuranceCheckKey.IntersectionSum],
  };

  return lookup[category];
}
