import { Marker } from "@react-google-maps/api";
import { useRef } from "react";

import { LatLng } from "@ag/map/types";

import redFlagIcon from "../assets/red-flag.png";
import yellowFlagIcon from "../assets/yellow-flag.png";

export interface ExtendedMarker extends google.maps.Marker {
  customFlagColour?: "yellow" | "red";
}

export const FlagMarker = ({
  fieldId,
  position,
  flagColour,
  clusterer,
  onClick,
}: {
  fieldId: string;
  position: LatLng;
  flagColour: "yellow" | "red";
  clusterer?: any;
  onClick?: (fieldId: string) => void;
}) => {
  const markerRef = useRef<ExtendedMarker>();

  const onLoad = (marker: ExtendedMarker) => {
    markerRef.current = marker;
    // Attach custom property to marker instance
    // used for styling clusters according to the types of markers that they contain
    marker.customFlagColour = flagColour;
  };

  return (
    <Marker
      position={position}
      icon={flagColour == "red" ? redFlagIcon : yellowFlagIcon}
      onClick={() => onClick && onClick(fieldId)}
      clusterer={clusterer}
      onLoad={onLoad}
    />
  );
};
