import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import axios from "axios";

import { CommonResponse } from "@ag/utils/types";

import { Client, ClientSchema } from "../entities/client";

export const generateClientQueryKey = (clientId: string) => [
  "client",
  clientId,
];

const getClient = async (clientId: string) => {
  const response = await axios.get<CommonResponse<Client>>(
    `/api/v1/clients/${clientId}`,
    {
      schema: ClientSchema,
      apiSource: "node-markets",
    },
  );

  return response.data.success!.data;
};

export const useClientQuery = (
  clientId: string,
  options?: Pick<UseQueryOptions, "enabled">,
) =>
  useQuery(generateClientQueryKey(clientId), () => getClient(clientId), {
    keepPreviousData: true,
    ...options,
  });
