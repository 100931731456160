import has from "lodash/has";
import omit from "lodash/omit";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { mergeRefs, useLayer } from "react-laag";
import { Link, To } from "react-router-dom";
import { useKey } from "rooks";

import { Icon, Spinner } from "~assets";
import { Button, ButtonSize, ButtonVariant } from "~atoms";
import { cn } from "~utils";

const FloatingMenuContext = createContext<{
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}>({
  isOpen: false,
  setIsOpen: () => {},
});

/* -------------------------------------------------------------------------------------------------
 * FloatingMenu
 * -----------------------------------------------------------------------------------------------*/
export type FloatingMenuProps = React.PropsWithChildrenRequired<{
  placement?: "start" | "end";
  layer?: "low" | "banner" | "drawer" | "modal";
  triggerVariant?: ButtonVariant;
  triggerSize?: ButtonSize;
  className?: string;
  wrapperClassName?: string;
  isDisabled?: boolean;
}> &
  (
    | { variant: "compact"; title?: never }
    | { variant?: "default"; title: string }
  );

const FloatingMenu = ({
  children,
  className,
  wrapperClassName,
  title,
  placement = "start",
  triggerSize,
  triggerVariant,
  variant,
  isDisabled,
}: FloatingMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLElement>();

  // Close on escape
  useKey(["Escape"], () => setIsOpen(false), { when: isOpen });

  const { triggerProps, layerProps, renderLayer } = useLayer({
    container: () => document.body,
    auto: true,
    placement: placement === "end" ? "bottom-end" : "bottom-start",
    triggerOffset: 8,
    isOpen,
    onOutsideClick: () => setIsOpen(false),
  });

  const handleTriggerClicked = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();

      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  const dropdownMinWidth = triggerRef.current
    ? triggerRef.current.offsetWidth + "px"
    : "0px";

  return (
    <FloatingMenuContext.Provider value={{ isOpen, setIsOpen }}>
      {variant === "compact" ? (
        <button
          {...omit(triggerProps, "ref")}
          ref={mergeRefs(triggerRef, triggerProps.ref)}
          className={cn(
            "rounded border-none p-[3px] leading-[.9] outline-[0]",
            "hover:cursor-pointer hover:bg-sky-200",
            "disabled:bg-grey-200",
            isOpen && "bg-sky-200",
          )}
          disabled={isDisabled}
          onClick={handleTriggerClicked}
        >
          <Icon name="ellipsis" className="text-[16px]" />
        </button>
      ) : (
        <Button
          {...omit(triggerProps, "ref")}
          ref={mergeRefs(triggerRef, triggerProps.ref)}
          icon={isOpen ? "chevron-up" : "chevron-down"}
          iconPosition={placement === "start" ? "before" : "after"}
          variant={triggerVariant}
          className={className}
          size={triggerSize}
          disabled={isDisabled}
          onClick={handleTriggerClicked}
        >
          {title}
        </Button>
      )}

      {isOpen &&
        renderLayer(
          <div
            {...layerProps}
            className={cn("z-modal", wrapperClassName)}
            data-testid="floating-menu-items"
          >
            <aside
              className={cn(
                "grid gap-px rounded border border-solid border-grey-700 bg-grey-white py-3 text-p2 shadow-200",
                "min-w-[var(--dropdown-width)]",
              )}
              style={
                {
                  "--dropdown-width": dropdownMinWidth,
                } as React.CSSProperties
              }
            >
              {children}
            </aside>
          </div>,
        )}
    </FloatingMenuContext.Provider>
  );
};

/* -------------------------------------------------------------------------------------------------
 * FloatingMenuOption
 * -----------------------------------------------------------------------------------------------*/
type BaseOption = {
  children: ReactNode;
  isDisabled?: boolean;
  isDanger?: boolean;
};

type LinkOption = { to: To; target?: "_blank" };

type ExternalLinkOption = { href: string | null | undefined };

type ActionOption = {
  onClick: () => void | Promise<void>;
};

export type FloatingMenuOptionProps = BaseOption &
  (LinkOption | ExternalLinkOption | ActionOption);

function FloatingMenuOption({
  children,
  isDanger,
  isDisabled,
  ...rest
}: FloatingMenuOptionProps) {
  const { setIsOpen } = useContext(FloatingMenuContext);
  const [isLoading, setIsLoading] = useState(false);

  // Needed so that our async click handler can early exit if component is unmounted before it resolves
  const isMounted = useRef(true);

  const className = cn(
    "flex items-center justify-between gap-1 px-4 py-1.5 text-grey-900",
    "border-none bg-none no-underline outline-none", // resets for both link and buttons
    "hover:no-underline hover:cursor-pointer hover:bg-grey-100",
    {
      "text-messaging-error-700": isDanger,
      "hover:text-grey-800": !isDanger,
      "text-grey-500 hover:cursor-not-allowed hover:text-grey-500":
        isDisabled || isOptionEmpty(rest),
    },
  );

  const handleLinkClicked: React.MouseEventHandler<
    HTMLAnchorElement
  > = event => {
    if (isDisabled || isOptionEmpty(rest)) {
      event.preventDefault();
      return;
    }

    setIsOpen(false);
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLinkOption(rest)) {
    return (
      <Link
        to={rest.to}
        className={className}
        onClick={handleLinkClicked}
        target={rest.target}
      >
        {children}
      </Link>
    );
  }

  if (isExternalLinkOption(rest)) {
    return (
      <a
        href={rest.href ?? ""}
        rel="noreferrer"
        target="_blank"
        className={className}
        onClick={handleLinkClicked}
      >
        {children}
        {isLoading && <Spinner className="h-4" />}
      </a>
    );
  }

  const handleButtonClicked = async (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.stopPropagation();

    setIsLoading(true);
    await rest.onClick();

    if (!isMounted.current) return;

    setIsOpen(false);
    setIsLoading(false);
  };

  return (
    <button
      className={className}
      disabled={isDisabled}
      onClick={handleButtonClicked}
    >
      {children}
      {isLoading && <Spinner className="h-4" />}
    </button>
  );
}

const Root = FloatingMenu;
const Option = FloatingMenuOption;

export { Root, Option };

function isLinkOption(props: object): props is LinkOption {
  return has(props, "to");
}

function isExternalLinkOption(props: object): props is ExternalLinkOption {
  return has(props, "href");
}

function isActionOption(props: object): props is ActionOption {
  return has(props, "onClick");
}

function isOptionEmpty(props: object) {
  if (isLinkOption(props) && !props.to) return true;
  if (isExternalLinkOption(props) && !props.href) return true;
  if (isActionOption(props) && !props.onClick) return true;

  return false;
}
