import { useEffect } from "react";

export const useOnLoad = (
  action: () => void,
  loadConditionArg?: unknown,
): void => {
  useEffect(() => {
    if (!loadConditionArg) {
      action();
    }
  }, [loadConditionArg, action]);
};
