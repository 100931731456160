import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { DEFAULT_LIMIT } from "@ag/utils/constants";
import {
  CommonPaginatedResponse,
  MetaArgs,
  UserRole,
  UserValidationState,
  createPaginatedDataSchema,
} from "@ag/utils/types";

import { User, UserSchema } from "../entities/user";

type GetUsersParams = MetaArgs & {
  sort?: {
    id?: "asc" | "desc";
  };
  filters?: Partial<{
    ids: number[];
    emails: string[];
    companyNames: string[];
    countryCodes: string[];
    vatNumbers: string[];
    roles: UserRole[];
    visibleInCarbonRegistry: boolean;
    validationStates: UserValidationState[];
    search: string;
    haUnderManagementRange?: {
      operator: string;
      value: number;
    }[];
    lastActivityAtRange?: {
      startDate: string | Date;
      endDate: string | Date;
    };
    assignedAdminId?: string;
  }>;
};

export const generateUsersQueryKey = (params?: GetUsersParams | undefined) => {
  return ["users", params].filter(Boolean);
};

export const getUsers = async (params: GetUsersParams | undefined) => {
  const response = await axios.get<CommonPaginatedResponse<User>>(
    "/api/v1/admins/user-management",
    {
      params: {
        ...params,
        includeMeta: true,
        limit: params?.limit || DEFAULT_LIMIT,
        sort: {
          id: "desc",
        },
      },
      schema: createPaginatedDataSchema(UserSchema),
      apiSource: "node-user-mgt",
    },
  );

  return response.data.success!.data;
};

export const useUsersQuery = (params?: GetUsersParams) =>
  useQuery(generateUsersQueryKey(params), () => getUsers(params), {
    keepPreviousData: true,
  });
