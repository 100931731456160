import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button } from "@ag/design-system/atoms";
import { stack } from "@ag/design-system/utils";
import { InputField } from "@ag/form-fields";
import { useSearchParam } from "@ag/utils/hooks";
import { ToastNotification } from "@ag/utils/services";

import ImageLogo from "~assets/images/logo/logo-agreena-admin-dark.svg";
import { useResetPasswordMutation } from "~features/auth";
import CenteredLayout from "~layouts/centered-layout";

const resetPasswordSchema = () =>
  z
    .object({
      password: z.string().min(1, "Password is required"),
      passwordConfirmation: z
        .string()
        .min(1, "Password confirmation is required"),
    })
    .refine(
      ({ password, passwordConfirmation }) => password === passwordConfirmation,
      {
        message: "Entered passwords don't match",
        path: ["passwordConfirmation"],
      },
    );

type ResetPasswordFormValues = z.infer<ReturnType<typeof resetPasswordSchema>>;

const ResetPasswordProcess = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetPasswordFormValues>({
    resolver: zodResolver(resetPasswordSchema()),
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
  });

  const [resetPasswordToken] = useSearchParam("token");

  const navigate = useNavigate();

  const resetPasswordMutation = useResetPasswordMutation();

  const handleResetPassword = (data: ResetPasswordFormValues) => {
    if (!resetPasswordToken) return;

    resetPasswordMutation.mutate(
      {
        ...data,
        resetPasswordToken,
      },
      {
        onSuccess: () => {
          ToastNotification.success("Your password has been changed");

          navigate("/login");
        },
      },
    );
  };

  return (
    <CenteredLayout.Root>
      <div className="grid grid-cols-[minmax(auto,300px)] gap-8">
        <img src={ImageLogo} className="place-self-center" />

        <form onSubmit={handleSubmit(handleResetPassword)} className={stack()}>
          <InputField
            {...register("password")}
            type="password"
            label="Password"
            error={errors.password}
          />

          <InputField
            {...register("passwordConfirmation")}
            type="password"
            label="Password confirm"
            error={errors.passwordConfirmation}
          />

          <div className="flex self-end">
            <Button isLoading={resetPasswordMutation.isLoading}>Submit</Button>
          </div>
        </form>
      </div>
    </CenteredLayout.Root>
  );
};

export default ResetPasswordProcess;
