import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import * as z from "zod";

import { ToastNotification } from "@ag/utils/services";
import { CommonResponse, UserRole } from "@ag/utils/types";

import { queryClient } from "~config/react-query";

import { generateUsersQueryKey } from "./get-users";

const createUserResponseSchema = z.object({
  userId: z.number(),
});

type CreateUserResponse = z.infer<typeof createUserResponseSchema>;

type UserData = {
  email: string;
  password: string;
  locale: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  preferredCurrency: string;
  acceptedNewsletter: boolean;
  acceptedTerms: boolean;
  company: {
    name: string;
    vatNumber: string;
    address: {
      address: string;
      city: string;
      countryCode: string;
      zipCode: string;
    };
    roles: UserRole[];
  };
};

const createUser = async (user: UserData) => {
  const response = await axios.post<CommonResponse<CreateUserResponse>>(
    "/api/v1/admins/user-management",
    user,
    {
      apiSource: "node-auth-user",
    },
  );

  return response.data.success!.data;
};

export const useCreateUserMutation = () =>
  useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(generateUsersQueryKey());
    },
    onError: error => ToastNotification.error(error),
  });
