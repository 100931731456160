import { z } from "zod";

import { AdditionalInformationDataSchema } from "./schemas";

export enum AdditionalInformationAttribute {
  Comment = "otherInformationComment",
}

export type AdditionalInformationData = z.infer<
  typeof AdditionalInformationDataSchema
>;

export enum AdditionalInformationOptionalAttribute {
  Comment = AdditionalInformationAttribute.Comment,
}
